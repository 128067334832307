<div class="sidebar-header noMobile">
  <h1>
    <ion-icon (click)="goBack()" class="custom-arrow-left"></ion-icon>Add Member
    <ion-icon (click)="closeDialog()" class="custom-close"></ion-icon>
  </h1>
</div>
<ion-header class="noDesktop mobile-header-white">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button (click)="goBack()" text="" icon="arrow-back-outline"></ion-back-button>
    </ion-buttons>
    <h4 class="heading">Add Member</h4>
    <ion-icon  (click)="goBack()" class="custom-close closeIcon"></ion-icon>
  </ion-toolbar>
</ion-header>
<ng-container *ngIf="!showOTPForm || !beneficiaryModel?.mobile_number">
  <form [formGroup]='basicDetForm' autocomplete="off" (ngSubmit)='onSubmit()'>
    <div class="sidebar-content inputFields">
      <ion-grid class="progressHeader">
        <ion-row>
          <ion-col size="3">
            <div class="progress--circle progress--25">
              <div class="progress__number">1/4</div>
            </div>
          </ion-col>
          <ion-col size="9">
            <h1>Registration</h1>
            <span>Next: Identification</span>
          </ion-col>
        </ion-row>
      </ion-grid>

      <ion-grid class="sidebar-inner-content">
        <div>
          <ion-row>
            <ion-col size-md="12" class="ion-no-padding">
              <app-ion-custom-datepicker style="width: 100%;" label="Date of Birth" formKey='dob'
                [parentForm]="basicDetForm" [class]="'noclass'" placeholder="DD/MM/YYYY" [minDate]="minDob"
                [maxDate]="maxDob" (dateValue)="getBeneficiaryTypeonDoB($event)">
              </app-ion-custom-datepicker>
            </ion-col>
          </ion-row>

          <app-beneficiary-category-fields [parentForm]="basicDetForm" [isFormSubmitted]="formSubmitted"
            [yearOfBirth]="birthYear" [sessionDetails]="beneficiaryData" [keyFromNewMember]="'categoryFields'"></app-beneficiary-category-fields>

          <ion-row>
            <ion-col size-md="12">
              <ion-label class="required">Mobile Number</ion-label>
              <ion-item class="item-border ion-no-padding" lines="none">
                <ion-input formControlName="mobile_number" placeholder="+91" appIntegerInput
                  [appRange]="[1000000000, 9999999999]" [appMin]="1000000000" type="tel" maxlength="10"
                  appMobileNumber="true" [appMax]="9999999999" errorText=" " ></ion-input>
              </ion-item>
              <!-- <ion-label *ngIf="formSubmitted && !basicDetForm.controls.mobile_number.valid  && (basicDetForm.controls.mobile_number.hasError('required'))" class="error-validation ">
                                <p>Please Enter Mobile Number</p>
                            </ion-label> -->
              <!-- <ion-label *ngIf="!basicDetForm.controls.mobile_number.valid  && (basicDetForm.controls.mobile_number.hasError('range'))" class="error-validation ">
                                <p>Must be 10 digits</p>
                            </ion-label> -->
              <!-- <ion-label *ngIf="!basicDetForm.controls.mobile_number.hasError('range') && basicDetForm.controls.mobile_number.hasError('pattern')" class="error-validation ">
                                <p>Please Enter a Valid 10 digit Number</p>
                            </ion-label> -->
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size-md="12" class="ion-text-end">
              <ion-button class="primaryBtn mobileBtn" [disabled]="inProgress" shape="round" type="submit">
                <ion-spinner *ngIf="inProgress" name="lines-small"></ion-spinner>Continue
                <ion-icon name="chevron-forward-outline"></ion-icon>
              </ion-button>
            </ion-col>
          </ion-row>
        </div>
      </ion-grid>
    </div>
    <!-- <div class="sidebar-footer ion-text-center">
            <ion-row>
                <ion-col size-md="12">

                    <ion-button class="primaryBtn" [disabled]="inProgress" expand="full" shape="round" type="submit">
                        <ion-spinner *ngIf="inProgress" name="lines-small"></ion-spinner>Continue
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                    </ion-button>
                </ion-col>
            </ion-row>
        </div> -->
  </form>
</ng-container>

<ng-container *ngIf="showOTPForm && beneficiaryModel?.mobile_number">
  <form autocomplete="off" [formGroup]='otpForm' (ngSubmit)='verifyOTP()'>
    <div class="sidebar-content inputFields">
      <ion-grid class="progressHeader">
        <ion-row>
          <ion-col size="3">
            <div class="progress--circle progress--25">
              <div class="progress__number">1/4</div>
            </div>
          </ion-col>
          <ion-col size="9">
            <h1>Registration</h1>
            <span>Next: Identification</span>
          </ion-col>
        </ion-row>
      </ion-grid>



      <ion-grid class="existingMmeberPanel" *ngIf="existingMembers.length > 0 ">
        <ng-container>
          <ion-card class="ion-no-padding">
            <ion-card-content class="ion-no-padding">
              <ion-accordion-group value="members">
                <ion-accordion value="members">
                  <ion-item slot="header">
                    <h3 class="pageSubTitle" *ngIf="!isTypeInfant()">Existing Members</h3>
                    <h3 class="pageSubTitle" *ngIf="isTypeInfant()">Select Primary Guardian</h3>
                  </ion-item>

                  <ion-list slot="content">
                    <ng-container *ngIf="!isTypeInfant()">
                      <ion-row *ngFor="let mem of existingMembers">
                        <ion-col size-md="5" class="ion-no-padding">
                          <ion-text>{{mem?.ben_name}}</ion-text>
                        </ion-col>
                        <ion-col size-md="7" class="ion-text-end ion-no-padding">
                          <h5>Ref: {{util.maskForLastChars(mem?.beneficiary_reference_id | benfNumberFormat)}}</h5>
                        </ion-col>
                      </ion-row>
                    </ng-container>
                    <ng-container *ngIf="isTypeInfant()">
                      <ion-row>
                        <ion-radio-group class="guardianList" formControlName="guardian_ben_id" name="guardian_ben_id">
                          <ion-item lines="none" *ngFor="let mem of existingMembers">
                            <ion-radio slot="start" [value]="mem.beneficiary_id"></ion-radio>
                            <ion-label class="valueLbl">
                              {{mem?.ben_name}}
                            </ion-label>
                            <ion-label class="refValue">
                              Ref: {{util.maskForLastChars(mem?.beneficiary_reference_id | benfNumberFormat)}}
                            </ion-label>
                          </ion-item>
                        </ion-radio-group>
                      </ion-row>
                    </ng-container>
                  </ion-list>
                </ion-accordion>
              </ion-accordion-group>
            </ion-card-content>
          </ion-card>

        </ng-container>
        <ion-row *ngIf="this.UWINBeneficiaries.length == dataService.getConfigValue('ben_limit')" class="ion-margin-top">
          <ion-col size-md="12" class="ion-no-padding">
            <ion-label class="error-validation ">
              <p class="ion-no-margin ion-padding-left">Already registered or cannot register more than {{dataService.getConfigValue('ben_limit')}} individuals
                with one mobile number.</p>
            </ion-label>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-grid class="sidebar-inner-content" *ngIf="this.UWINBeneficiaries.length < dataService.getConfigValue('ben_limit')">
        <ion-row>
          <ion-col size-md="12">
            <ion-label class="required">6 Digit Secure OTP</ion-label>
            <ion-item class="item-border ion-no-padding" lines="none">
              <ion-input autocomplete="off" formControlName='otp' type='tel' minlength="6" maxlength="6" appIntegerInput
                placeholder="- - - - - -" errorText=" " ></ion-input>
              <ion-button (click)="onSubmit()" [disabled]="timer" class="linkBtn" expand="full" fill="outline">
                Resend
              </ion-button>
            </ion-item>
            <ion-row>
              <!-- <ion-col size-md="6">
                              <ion-label
                                *ngIf="otpformSubmitted && !otpForm.controls.otp.valid  && (otpForm.controls.otp.hasError('required'))"
                                class="error-validation ">
                                <p class="ion-no-margin">Please Enter OTP</p>
                              </ion-label>
                              <ion-label
                              *ngIf="!otpForm.controls.otp.valid  && (otpForm.controls.otp.hasError('minlength') || otpForm.controls.otp.hasError('maxlength'))"
                                class="error-validation ">
                                <p class="ion-no-margin">Must be 6 digits</p>
                              </ion-label>
                            </ion-col> -->
              <ion-col size-md="6">
                <ion-label *ngIf="skipTimer">{{skipTimer + ' sec '}} for Skip OTP</ion-label>
                <ion-button *ngIf="!skipTimer" class="linkBtn skipotpBtn" fill="outline" (click)="SkipOtp()">Skip OTP
                </ion-button>
              </ion-col>
              <!-- <ion-col size-md="6" class="ion-text-end">
                                              <ion-label>{{timer >0 ? timer + ' sec ' : ''}}</ion-label>
                                          </ion-col> -->
            </ion-row>





          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size-md="12" class="ion-text-end">

            <ion-button [disabled]="inProgress" class="primaryBtn mobileBtn" shape="round" type="submit">
              <ion-spinner *ngIf="inProgress" name="lines-small"></ion-spinner>Continue
              <ion-icon name="chevron-forward-outline"></ion-icon>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>

    </div>
    <!-- <div class="sidebar-footer ion-text-center" *ngIf="this.existingMembers.length < 6">
            <ion-row>
                <ion-col size-md="12">

                    <ion-button [disabled]="inProgress" class="primaryBtn" expand="full" shape="round" type="submit">
                        <ion-spinner *ngIf="inProgress" name="lines-small"></ion-spinner>Continue
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                    </ion-button>
                </ion-col>
            </ion-row>
        </div> -->
  </form>
</ng-container>
