import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { timer } from 'rxjs';
import { takeWhile, tap } from 'rxjs/operators';
import { AadhaarConcentComponent } from 'src/app/modules/shared/components/aadhaar-concent/aadhaar-concent.component';
import { EncDecService } from 'src/app/modules/shared/services/encryption-decryption.service';
import { UtilsService } from 'src/app/modules/shared/services/utils.service';
import { AadharService } from 'src/app/screens/aadhar-verification/services/aadhar.service';
import { AddRecipientService } from 'src/app/screens/add-recipient/services/add-recipient.service';
import { BeneficiaryService } from 'src/app/screens/beneficiary/services/beneficiary.service';
import { ConfigurationsPageModule } from 'src/app/screens/configurations/configurations.module';
import * as config from 'src/environments/config';

@Component({
  selector: 'app-abha',
  templateUrl: './abha.component.html',
  styleUrls: ['./abha.component.scss'],
})
export class AbhaComponent implements OnInit {
  abhaForm: FormGroup;
  otpForm: FormGroup;
  abhaAddressForm: FormGroup;
  // data: any;
  otpNumber;
  token;
  txnId = null;
  timer: number;
  currentStage = 1;
  formSubmitted = false;
  benData;
  isSubmitted = false;
  aadharMobile;
  abhaID;
  header;
  idType;
  userData;
  isFromRegistration;
  isFromBenPage;
  createFromModal
  benDataFromCowin: any;
  cowinAddedData: any;
  verificationInprogress: boolean = false;
  constructor(public utilsService: UtilsService,
    public translate: TranslateService,
    private encDecService: EncDecService,
    private bottomSheet: MatBottomSheet,
    private aadharService: AadharService,
    @Inject(MAT_DIALOG_DATA) public data,
    private benficiaryService: BeneficiaryService,
    private dialogRef: MatDialogRef<AbhaComponent>,
    private addRecipientService: AddRecipientService,
    private modalController: ModalController,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.benData = data;
    // if(this.benData.ben_data.health_id) {
    //   this.currentStage = 4;
    //    this.token = this.benData.ben_data.token;
    // }
    this.abhaAddressForm = new FormGroup({
      name: new FormControl('', Validators.required)
    });
    // this.setHeader();
  }

  ngOnInit() {
    this.initBeneficiaryForm();
    if (this.createFromModal == true) {
      this.addBenTOUWIN(this.userData)
    } else if (this.benData.createFromModal) {
      this.addBenTOUWIN(this.benData.ben_data)
    }
    this.setHeader();
  }
  addBenTOUWIN(benficiaryData) {
    const payload = {
      beneficiary_id: benficiaryData.beneficiary_id
    }
    this.addRecipientService.addToUWIN(payload).subscribe(res => {
      this.benDataFromCowin = res[0];

    })
  }

  initBeneficiaryForm() {
    this.abhaForm = new FormGroup({
      photo_id_number: new FormControl('', Validators.required),
      aadhar_concent: new FormControl(null)
    });
    this.otpForm = new FormGroup({
      otp: new FormControl('', Validators.required),
    });
  }

  setHeader() {
    switch (this.currentStage) {
      case 1: (this.benData?.ben_data?.health_id && !this.benData?.ben_data?.hasOwnProperty('phr_address')) || (this.userData?.health_id && !this.userData?.hasOwnProperty('phr_address')) ? this.header = 'Create your ABHA Address that is easy to remember' : this.header = 'Generate ABHA Number'; break;
      case 2: (this.benData?.ben_data?.health_id && !this.benData?.ben_data?.hasOwnProperty('phr_address')) || (this.userData?.health_id && !this.userData?.hasOwnProperty('phr_address')) ? this.header = 'Create your ABHA Address that is easy to remember' : this.header = 'Generate ABHA Number'; break;
      case 3: this.header = 'ABHA Number Generated Successfully'; break;
      case 4: this.header = 'Create your ABHA Address that is easy to remember'; break;
      case 5: this.header = 'ABHA Address Generated Successfully'; break;
    }
  }



  restrictUserToTypeMoreThanLength(event, length, variable?) {
    const inputValue = event.srcElement.value;
    if (inputValue.length > length) {
      event.srcElement.value = inputValue.substring(0, length);
      if (variable === 'otpNumber') {
        this.otpNumber = inputValue.substring(0, length);
      }
      event.stopPropagation();
      return false;
    }
  }

  async generateAadhaarOTP() {
    this.verificationInprogress = true;
    const aadharNo = this.abhaForm.value.photo_id_number;
    this.aadharService.sendVaccinatorOtpToRegisteredAadharMobileNo(aadharNo)
      .subscribe(res => {
        if (res && res.errorCode) {
          this.utilsService.presentToastError(res.error);
          this.verificationInprogress = false;
          this.abhaForm.controls.photo_id_number.setValue(null);
          // this.abhaForm.controls.concent.setValue(null);
          return;
        }
        // setTimeout(()=>{

        // },5000)
        this.currentStage = 2;
        this.setHeader();
        this.verificationInprogress = false;

        this.utilsService.presentToastSuccess('Aadhaar OTP Sent To Registered Mobile Number');
        if (res && res?.txnId) {
          this.txnId = res?.txnId;
          this.aadharMobile = res.mobileNumber;
        }
        this.startTimer();
      }, err => {
        this.verificationInprogress = false;
      });
  }
  beneficiaryReferenceId() {
    if (this.createFromModal == true || this.benData.createFromModal == true) {
      return this.benDataFromCowin.beneficiary_reference_id
    }
    else if (this.isFromRegistration == true) {
      return this.userData.beneficiaryDetails.beneficiary_reference_id
    }
    else if (this.benData.isFromRegistration == true) {
      return this.benData.ben_data.beneficiaryDetails.beneficiary_reference_id
    }
    else if (this.isFromBenPage == true) {
      return this.userData.beneficiary_reference_id

    }
    else {
      return this.benData.ben_data.beneficiary_reference_id
    }
  }

  async verifyVaccinatorOTP() {
    this.verificationInprogress = true;

    if (!this.otpForm.valid) {
      this.verificationInprogress = false;
      this.utilsService.presentToastError("Please Enter OTP")
      return;
    }
    const formData = this.otpForm.value;
    // let beneficiaryReference_id = (this.createFromModal==true|| this.benData.createFromModal==true) ? this.benDataFromCowin.beneficiary_reference_id:(this.isFromRegistration==true )? this.userData.beneficiary_reference_id :this.benData.isFromRegistration==true ? this.benData.ben_data.beneficiary_reference_id:this.benData.ben_data.beneficiary_reference_id
    let beneficiaryReference_id = this.beneficiaryReferenceId();
    let payload = { ...formData, ...{ txnId: this.txnId, beneficiary_reference_id: beneficiaryReference_id } };
    this.aadharService.verifyVaccinatorOTP(payload)
      .subscribe(res => {
        if (res && res.errorCode) {
          this.utilsService.presentToastError(res.error);
          this.otpForm.controls.otp.setValue(null);
          this.verificationInprogress = false;
          return;
        }
        this.utilsService.presentToastSuccess('Aadhaar OTP verified successfully');
        this.token = res.token;
        this.abhaID = res.healthIdNumber;
        this.idType = 'ABHA Number';
        // setTimeout(()=>{

        // },5000)
        if ((this.benData?.ben_data?.health_id && !this.benData?.ben_data?.hasOwnProperty('phr_address')) || (this.userData?.health_id && !this.userData?.hasOwnProperty('phr_address'))) {
          this.currentStage = 4
        } else {
          this.currentStage = 3;
        }
        this.setHeader();
        this.verificationInprogress = false;
        if (this.createFromModal == true || this.isFromBenPage == true || this.isFromRegistration == true) {
          this.userData['health_id'] = res.healthIdNumber
          this.benficiaryService.beneficiaryDataSubject.next({ ...this.userData })
        }
        else {
          this.benData.ben_data['health_id'] = res.healthIdNumber;
          this.benficiaryService.beneficiaryDataSubject.next({ ...this.benData.ben_data })
        }
      }, err => {
        this.utilsService.presentToastError(err?.error?.error)
        this.onClose()
        // if(err){
        this.verificationInprogress = false;
        // }
      });
  }

  openBottomSheet() {
    this.bottomSheet.open(AadhaarConcentComponent);
  }

  startTimer() {
    let counter = config.ENV.otpTimer;
    this.timer = config.ENV.otpTimer;
    timer(1000, 1000) //Initial delay 1 seconds and interval countdown also 1 second
      .pipe(
        takeWhile(() => counter > 0),
        tap(() => counter--)
      )
      .subscribe(() => { this.timer = counter; });
  }

  removeWhiteSpace(event?) {
    const inputValue: any = event.srcElement.value;
    event.srcElement.value = inputValue.replace(/ /g, '');
  }

  async submitABHAAddress() {
    this.verificationInprogress = true;
    const loading = await this.utilsService.presentLoading();
    await loading.present();
    let payLoadFofToken = {
      beneficiary_reference_id: this.beneficiaryReferenceId(),
      // beneficiary_reference_id :(this.createFromModal==true || this.benData.createFromModal==true) ? this.benDataFromCowin.beneficiary_reference_id:(this.isFromRegistration==true|| this.benData.isFromRegistration==true)? this.userData.beneficiary_reference_id : this.benData.ben_data.beneficiary_reference_id,
      token: this.token,
      ABHAAddress: this.abhaAddressForm.value.name
    };
    this.aadharService.linkABHAAddress(payLoadFofToken).subscribe(res => {
      if (res && res.errorCode) {
        this.utilsService.presentToastError(res.error);
        this.abhaAddressForm.controls.name.setValue(null);
        this.verificationInprogress = false
        return;
      }
      this.utilsService.presentToastSuccess('ABHA address added');
      this.idType = 'ABHA Address';
      this.abhaID = res.ABHAAddress;
      this.currentStage = 5;
      this.setHeader();
      this.verificationInprogress = false;
      this.abhaID = this.abhaAddressForm.value.name;
      if (this.createFromModal == true || this.isFromBenPage == true || this.isFromRegistration == true) {
        this.userData['phr_address'] = this.abhaID + '@Abdm'
        this.benficiaryService.beneficiaryDataSubject.next({ ...this.userData });

      } else {
        this.benData.ben_data['phr_address'] = this.abhaID + '@Abdm';
        this.benficiaryService.beneficiaryDataSubject.next({ ...this.benData.ben_data });
      }

    }, err => {
      if (err?.error.errorCode == "ADHA002") {
        setTimeout(() => {
        }, 3000);
        loading.onDidDismiss();
        this.otpForm.reset();
      }
      this.verificationInprogress = false;
    });
  }

  setAbhaAddress() {
    this.currentStage = 4;
    this.setHeader();
  }

  onClose() {
    if (this.createFromModal == true || this.isFromRegistration == true || this.isFromBenPage == true) {
      if (this.currentStage >= 3) {
        this.modalController.dismiss({
          'refresh': true
        });
      }
      this.modalController.dismiss({ 'dismis': true });
    }
    else {
      this.dialogRef.close({ event: 'refresh' });
    }
  }

  backbutton() {
    if (this.activatedRoute?.snapshot['_routerState']['url'].includes('new-beneficiary')) {
      if (this.activatedRoute?.snapshot['_routerState']['url'].includes('session')) {
        let sessionID = this.activatedRoute?.snapshot['_routerState']['url'].split('/').pop()
        this.router.navigate([`/session/${sessionID}`]);
      } else {
        this.router.navigateByUrl('/dashboard')
      }
    }
    if (this.currentStage === 1 && (this.benData.isFromBenPage == true || this.benData.createFromModal == true || this.benData.isFromRegistration == true)) {
      this.dialogRef.close();
    } else if (this.currentStage === 1 && (this.isFromBenPage == true || this.createFromModal == true || this.isFromRegistration == true)) {
      this.modalController.dismiss();
    }
    else {
      this.currentStage = this.currentStage - 1;
      this.setHeader();
    }
  }


}
