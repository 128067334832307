import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalStorageService } from 'src/app/modules/core/services/local-storage.service';
import { DataService } from 'src/app/modules/shared/services';
import { EncDecService } from 'src/app/modules/shared/services/encryption-decryption.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PregnancyService {

  private medicalCondtionSubject: BehaviorSubject<any>;
  public medicalCondtionData: Observable<any>;

  disable = new ReplaySubject(1);
  disableDeliveryMaternal = new ReplaySubject(1);
  editPrgDetails = new ReplaySubject(1);
  benficiaryData = new ReplaySubject(1);
  masterTablePregnancyData: any = {}
  maxPregnancy = new ReplaySubject(1);
  goToNextTab = new ReplaySubject(1);
  enableNewBornTab = new ReplaySubject(1);
  refreshPregancyDetails = new ReplaySubject(1);
  isFromNewBornPage = new ReplaySubject(1);
  constructor(private encDecService: EncDecService, private http: HttpClient,private dataService: DataService,private localStorage:LocalStorageService) {
    this.medicalCondtionSubject = new BehaviorSubject<any>(null);
    this.medicalCondtionData = this.medicalCondtionSubject.asObservable();
  }
  public userDetails = this.dataService.userDetails;
  set medicalCondtionsbeneficary(befMedicalDetails) {
    this.medicalCondtionSubject.next(befMedicalDetails)
  }

  getPregancyDetalsById(beneficiary_id): Observable<any> {
    const encyptedBenId = this.encDecService.set(`${beneficiary_id}`);
    const postData = { beneficiary_id: encyptedBenId };
    return this.http.post(`${environment.vaccinator_url}/getpregnancydetailsById`, postData)
  }

  addChildDetails(payload) {
    payload['beneficiary_id'] = this.encDecService.set(`${payload.beneficiary_id}`);
    payload['pregnancy_outcome_id'] = this.encDecService.set(`${payload.pregnancy_outcome_id}`);
    if(this.dataService.userDetails.role == environment.static_ids.BothVaccinatorAndDeliveryManager){
      payload['sub_center_id'] = this.localStorage.getItem('sub_center_id')
    }
    return this.http.post(`${environment.vaccinator_url}/addprgchilddetails`, payload)
  }

  pregnancyOutComePost(payload): Observable<any> {
    payload['session_site_id']= this.encDecService.setWithSpace(this.userDetails.session_site_id);
    payload['session_site_name']= this.userDetails.session_site_name;
    payload['beneficiary_id'] = this.encDecService.set(`${payload.beneficiary_id}`)
    if (payload.hasOwnProperty("pregnancy_outcome_id")) {
      payload['pregnancy_outcome_id'] = this.encDecService.set(`${payload.pregnancy_outcome_id}`)
    }
    return this.http.post(`${environment.vaccinator_url}/pregnancyoutcome`, payload)
  }

  pregnancyMedicalConditions(payload): Observable<any> {
    payload['beneficiary_id'] = this.encDecService.set(`${payload.beneficiary_id}`);
    if(this.dataService.userDetails.role == environment.static_ids.BothVaccinatorAndDeliveryManager)
     payload['sub_center_id'] = this.localStorage.getItem('sub_center_id')
    return this.http.post(`${environment.vaccinator_url}/addpregnancydetails`, payload)
  }

  updatePregnancyMedicalConditions(payload): Observable<any> {
    payload['beneficiary_id'] = this.encDecService.set(`${payload.beneficiary_id}`);
    if(this.dataService.userDetails.role == environment.static_ids.BothVaccinatorAndDeliveryManager)
     payload['sub_center_id'] = this.localStorage.getItem('sub_center_id')
    return this.http.post(`${environment.vaccinator_url}/updatePregnancyDetails`, payload)
  }

  getPregnancyDetailsBySpecificId(payload) {
    payload['beneficiary_id'] = this.encDecService.set(`${payload.beneficiary_id}`);
    return this.http.post(`${environment.vaccinator_url}/getpregnancydetailsByPrgId`, payload).pipe(map(r => {
      if (r) {
        return r[0];
      }
    }))
  }

  ancFormDetails(payload) {
    payload['beneficiary_id'] = this.encDecService.set(`${payload.beneficiary_id}`);
    return this.http.post(`${environment.vaccinator_url}/ancdetails`, payload)
  }

  updatePregnancyOutComeDetails(payload) {
    payload['session_site_id']= this.userDetails.session_site_id;
    payload['session_site_name']= this.userDetails.session_site_name;
    payload['beneficiary_id'] = this.encDecService.set(`${payload.beneficiary_id}`);
    payload['pregnancy_outcome_id'] = this.encDecService.set(`${payload.pregnancy_outcome_id}`);
    return this.http.post(`${environment.vaccinator_url}/updatepregnancyoutcomedetails`, payload)
  }
  updateAbortionDetails(payload){
    payload['session_site_id']= this.userDetails.session_site_id;
    payload['session_site_name']= this.userDetails.session_site_name;
    payload['beneficiary_id'] = this.encDecService.set(`${payload.beneficiary_id}`);
    payload['pregnancy_outcome_id'] = this.encDecService.set(`${payload.pregnancy_outcome_id}`);
    return this.http.post(`${environment.vaccinator_url}/updateAbortionDetail`, payload) 
  }

  updateANCDetails(payload) {
    payload['beneficiary_id'] = this.encDecService.set(`${payload.beneficiary_id}`);
    return this.http.post(`${environment.vaccinator_url}/updateAncDetails`, payload)
  }


}
