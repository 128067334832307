<ng-container  *ngIf="util.getPlatformWidth() < 575">
    <ion-content class="ion-page">
      <ng-container *ngTemplateOutlet="tpl"></ng-container>
    </ion-content>
    </ng-container>
    <ng-container  *ngIf="util.getPlatformWidth() >= 575">
        <ng-container *ngTemplateOutlet="tpl"></ng-container>
    </ng-container>
  <ng-template #tpl>
<!-- {{benfData |json}} hello -->
<!-- <app-add-recipient-vaccination-info 
*ngIf="(!benfData?.session_type_name || benfData?.session_type_name !='UIP')"
[activeStepIndex]="'VACCINATIONINFO'"  (onClose)="closeDialog()" (onStepChange)="onStepChange($event)">
</app-add-recipient-vaccination-info> -->
<!--*ngIf="benfData?.session_type_name =='UIP'"-->
<app-select-multi-vaccination-uip  *ngIf="isDataLoaded"
[activeStepIndex]="'MULTIVACCUIP'" (onClose)="closeDialog()" 
(onStepChange)="onStepChange($event)">
</app-select-multi-vaccination-uip>
</ng-template>