import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { LocalStorageService } from "../services/local-storage.service";

@Injectable()
export class IsAuthenticatedGuard implements CanActivate {
  // here you can inject your auth service to check that user is signed in or not
  constructor(private localStorage: LocalStorageService, private router: Router) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!!this.localStorage.getItem('userToken')) {
      this.router.navigate(["/"]); // or home
      return false;
    }

    return true;
  }
}
