import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lastFourDigits'
})
export class LastFourDigitsPipe implements PipeTransform {

  transform(value: string): string {
    // Check if the value is valid and has at least 5 characters
    if (!value || value.length < 4) {
      return value; // Return the value as is if it's invalid or less than 5 characters
    }

    // Extract the last five characters from the input value
    const lastFiveDigits = value.slice(-4);

    // Replace all characters except the last five with asterisks
    const obscuredValue = value.slice(0, -4).replace(/./g, '*') + lastFiveDigits;

    return obscuredValue;
  }

}
