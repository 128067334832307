import { ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-aadhaar-concent',
  templateUrl: './aadhaar-concent.component.html',
  styleUrls: ['./aadhaar-concent.component.scss'],
})
export class AadhaarConcentComponent implements OnInit {
  data: any ={};
  constructor(private _bottomSheetRef: MatBottomSheetRef<AadhaarConcentComponent>) { }

  ngOnInit() {}

  dismissModal() {
    this._bottomSheetRef.dismiss();
  }

}
