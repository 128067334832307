<ion-grid class="unlinkHpr">
  <ion-icon class="custom-close" (click)="cancel()"></ion-icon>
  <ion-row>
    <ion-col size="12">
      <h1>{{'Unlink HPR ID' | translate}}</h1>
    </ion-col>
    <ion-col size="12">
      <h3>{{'Are you sure you want to proceed with unlinking of your HPR ID' | translate}} {{HprId}} ?</h3>
    </ion-col>
    <ion-col size="12">
      <p>{{'Please note that once you have unlinked HPR ID, you would have to authenticate again with your HPR ID after logging in to the application.' | translate}}</p>
    </ion-col>
    <ion-col size="12" class="ion-text-end">
      <ion-button type="button" class="btnOuline" fill="outline" (click)="cancel()">{{'Cancel' | translate}}</ion-button>
      <ion-button (click)="unlink()" type="button" class="btnOuline" color="danger">{{'Unlink' | translate}}</ion-button>
    </ion-col>
  </ion-row>
</ion-grid>