import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return value;
    if (!value) {
      return '';
    }

    // Remove all non-numeric characters from the input string
    const cleaned = value.toString().replace(/\D/g, '');

    // Match the input string against the phone number pattern
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    // If the input string matches the pattern, format the phone number
    if (match) {
      const asterisks = '*'.repeat(6); // Create a string of six asterisks
      return `${asterisks}${match[3]}`;
    }

    // Otherwise, return the original input string
    return value;
  }
}
