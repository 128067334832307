import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { from, Observable, of } from 'rxjs';
import { tap, map, take, filter, switchMap, catchError } from 'rxjs/operators';
import { DataService } from '../../shared/services';
import { getUserDetails } from '../store/selectors/user.selectors';
import { selectIsAuthenticated } from '../core.module';
import { GetProfile } from '../store/actions/user.actions';


@Injectable({
  providedIn: 'root'
})
export class UserGuard implements CanActivate {
  constructor(private store$: Store,
              private router: Router) {
  }

  userStatePromise(): Observable<any> {
    return this.store$.select(getUserDetails).pipe(
      tap((user: any) => {
        if (!user) {
          this.store$.dispatch(GetProfile());
        }
      }),
      filter((user: any) => !!user),
      take(1));
  }

  
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot):| Observable<boolean | UrlTree> {
    const rolesArray: string[] = next.data.role;
    return this.userStatePromise().pipe( 
      switchMap(() => of(true)),
      catchError(()=> of(false))
    );
  }

}
