import { Location } from '@angular/common';
import { Component, Output, EventEmitter, OnInit, SimpleChanges, Input, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/modules/shared/services';
import { UtilsService } from 'src/app/modules/shared/services/utils.service';
import { SessionService } from 'src/app/screens/session/services/session.service';
import { AddRecipientService } from '../../services/add-recipient.service';

@Component({
  selector: 'app-add-recipient-member-verified',
  templateUrl: './member-verified.page.html',
  styleUrls: ['./member-verified.page.scss'],
})
export class MemberVerifiedPageComponent {
  benfData = {};
  benId;
  sessionId;
  benSuccessSubscription:Subscription;
  constructor(
    public dataService: DataService,
    private router: Router,
    private loc: Location,
    private addRecipientService: AddRecipientService,
    public util: UtilsService,
    public actRoute:ActivatedRoute
  ) {
    this.benId = this.actRoute.snapshot.params.benID
    this.sessionId = this.actRoute.snapshot.params.sessionID;
  }
  ngOnInit(){
    this.benSuccessSubscription = this.addRecipientService.benSuccessData.subscribe(res=>{
      this.benId = res['beneficiary_id'];
      this.sessionId = res['session_id'];
      this.benfData =  res;
    })
    const stateData: any = this.loc.getState();
    if(stateData.navigationId == 1){
      this.router.navigate([`/session/${this.sessionId}`]);
    }
  }

  onStepChange(val: any) {
    if (val === 'next') {
      if(!this.benfData['session_id']){
        this.router.navigate(['/new-member/member-vaccination-info']);
      }
      if(this.benfData['session_id']){
        if(this.benfData['session_type_name']== 'UIP') {
          if(this.benId && this.sessionId){
            this.router.navigate([`/member-vaccination-info/${this.benId}/${this.sessionId}`]);
          }else{
            this.router.navigate(['/new-member/member-vaccination-info']);
          }
        }else{
          this.router.navigate(['/new-member/vaccinate-member']);
        }
      }
    } else {
      this.loc.back();
    }
  }

  closeDialog() {
  }
  onDestroy(){
    this.benSuccessSubscription.unsubscribe();
  }

}
