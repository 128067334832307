import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BeneficiaryService } from '../../services/beneficiary.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BeneficiaryPage } from '../../beneficiary.page';
import { UtilsService } from 'src/app/modules/shared/services/utils.service';
import moment from 'moment';

@Component({
  selector: 'app-declare-death',
  templateUrl: './declare-death.component.html',
  styleUrls: ['./declare-death.component.scss'],
})
export class DeclareDeathComponent implements OnInit {
  deathForm: FormGroup;
  maxDate = new Date()
  minDate: any
  benData: any
  declaredDeath: any
  lastVaccinationDate: any;
  declareDateOfDeath: any

  constructor(private beneficiaryService: BeneficiaryService, @Inject(MAT_DIALOG_DATA) public data: any, private cdr: ChangeDetectorRef, public dialogRef: MatDialogRef<BeneficiaryPage>, private util: UtilsService) {
    this.benData = data;
  }

  ngOnInit() {
    this.initForm()

    this.beneficiaryService.minDateForDeath({ "beneficiary_ref_id": this.benData.ben_data.beneficiary_reference_id }).subscribe(r => {
      this.minDate = moment(r['minDateForDeclareDeath']).format('YYYY-MM-DD')
      let diff;
      if (this.benData.ben_data?.date_of_abortion) {
        diff = moment().diff(this.benData.ben_data?.date_of_abortion, 'days')
      }
      if (this.benData.ben_data?.is_pregnant_woman) {
        let lmpMinDate = moment().subtract(12, 'months').format('YYYY-MM-DD')
        if(lmpMinDate < this.minDate){
          this.minDate = this.minDate
        }else{
          this.minDate = lmpMinDate
        }
      }
      if (diff <= 7) {
        this.minDate = moment(this.benData.ben_data?.date_of_abortion).format('YYYY-MM-DD')
      }
    })
  }

  async initForm() {
    this.deathForm = new FormGroup({
      dateOfDeath: new FormControl(null),
    });
  }

  declareDeathValue(e) {
    this.declareDateOfDeath = moment(e.value).format("YYYY-MM-DD")
  }

  close() {
    this.dialogRef.close()
  }

  declareDeath() {
    this.dialogRef.close({ event: 'Submitted' })
    const payload = {
      "beneficiary_ref_id": this.benData.ben_data.beneficiary_reference_id,
      "date_of_death": this.declareDateOfDeath
    }
    this.beneficiaryService.beneficaryDelcaredDeath(payload).subscribe(r => {
      if (r) {
        this.beneficiaryService.declaredDeathReplaySubject.next(true)
        this.util.presentToastSuccess('Updated beneficiary death details successfully')
        this.cdr.detectChanges();
      }
    })
  }
}
