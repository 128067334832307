
<div [formGroup]='parentForm'>
  <ion-row>
    <ion-col size-lg="4" size-md="4" size-sm="12" size-xs="12">
      <ion-item>
        <ion-label position="floating">State</ion-label>
        <ion-select formControlName='stateId'
        [disabled]="isDisabled"
        (ionChange)="getDistrictList(parentForm.get(['stateId']).value)">
          <ion-select-option *ngFor="let stateObj of stateList"  [value]="stateObj.state_id">
            {{ stateObj.state_name }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </ion-col>
    <ion-col size-lg="4" size-md="4" size-sm="12" size-xs="12">
      <ion-item>
        <ion-label position="floating">District</ion-label>
        <ion-select  formControlName='districtId'
        [disabled]="isDisabled"
        (ionChange)="getBlockList(parentForm.get(['districtId']).value)">
          <ion-select-option *ngFor="let districtObj of districtList"  [value]="districtObj.district_id">
            {{ districtObj.district_name }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </ion-col>
    <ion-col size-lg="4" size-md="4" size-sm="12" size-xs="12">
      <ion-item>
        <ion-label position="floating">Block</ion-label>
        <ion-select (ionChange)='blockSelectedEmit()'
        [disabled]="isDisabled"
        formControlName='blockId'>
          <ion-select-option *ngFor="let blockObj of blockList"  [value]="blockObj.block_id">
            {{ blockObj.block_name }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </ion-col>
  </ion-row>
</div>
