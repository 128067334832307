<ng-container *ngIf="dataService.userData | async">
<form [formGroup]="filterForm">
  <ion-row>
    <ion-col size-md="6" size-lg="6" size="12" class="ipadPadding"
      *ngIf="!ashaUserId && (dataService.userDetails.role === 'ANM' || dataService.userDetails.role === 'Vaccinator' || dataService.userDetails.role === 'Both(Vaccinator & Delivery Manager)')">
      <ion-label>{{'Select Facility' | translate}}</ion-label>
      <mat-form-field appearance="outline" class="ion-margin-vertical matDropdown">
        <mat-select [placeholder]="'Select from the list' | translate" formControlName="facility_id"
          (selectionChange)="facilityChange($event.value, 'facilityChange')">
          <mat-option *ngFor="let facility of facilities" [value]="facility.facility_id">
            {{facility.facilty_name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ion-col>
    <ion-col size-md="6" size-lg="6" size="12" class="ipadPadding"
      *ngIf="!ashaUserId && dataService.userDetails.role === 'Both(Vaccinator & Delivery Manager)'">
      <ion-label>{{'Select Sub Center' | translate}}</ion-label>
      <mat-form-field appearance="outline" class="ion-margin-vertical matDropdown">
        <mat-select [placeholder]="'Select from the list' | translate" formControlName="sub_center_id"
          (selectionChange)="subCenterChange($event.value, 'subCenterChange')">
          <mat-option *ngFor="let subCenter of subCenters" [value]="subCenter.sub_center_id">
            {{subCenter.sub_center_name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ion-col>
    <ion-col size-md="6" size-lg="6" size="12" class="ipadPadding">
      <ion-label>{{'Session Sites' | translate }}</ion-label>
      <mat-form-field appearance="outline" class="ion-margin-vertical matDropdown">
        <mat-select [placeholder]="'Select from the list' | translate" formControlName="session_site"
          (selectionChange)="sessionSiteDetails($event.value)">
          <mat-option *ngFor="let site of sessionSites" [value]="encDecService.get(site.session_site_id)">
            {{site.session_site_name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="12" size-xl="6" size-md="6" *ngIf="villageDetails.length > 0" class="ipadPadding">
      <ion-label>{{'LGD Villages' | translate}}</ion-label>
      <mat-form-field appearance="outline" class="ion-margin-vertical matDropdown">
        <mat-select [placeholder]="'Select from the list' | translate" formControlName="village_id" [compareWith]="compareVillages"
          (selectionChange)='onChangelocation("village")'>
          <mat-option *ngFor="let village of villageDetails"
            [value]="{'village_id': village.village_id,'village_name': village.village_name}">{{village.village_name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ion-col>
    <ion-col size="12" size-xl="6" size-md="6" *ngIf="healthVillages.length > 0" class="ipadPadding">
      <ion-label>{{'Health Villages' | translate}}</ion-label>
      <mat-form-field appearance="outline" class="ion-margin-vertical matDropdown">
        <mat-select [placeholder]="'Select from the list' | translate" formControlName="health_village_id"
          [compareWith]="compareVillages" (selectionChange)='onChangelocation("healthVillage")'>
          <mat-option *ngFor="let healthVillage of healthVillages"
            [value]="{'village_id':healthVillage.village_id,'village_name':healthVillage.village_name}">
            {{healthVillage.village_name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ion-col>
    <ng-container *ngIf="dataService.userDetails.role != 'ASHA'">

      <ion-col size="12" size-xl="6" size-md="6" *ngIf="wardData.length > 0">
        <ion-label>{{'LGD Wards'| translate }}</ion-label>
        <mat-form-field appearance="outline" class="ion-margin-vertical matDropdown">
          <mat-select [placeholder]="'Select from the list' | translate" formControlName="ward_id" [compareWith]="compareVillages"
            (selectionChange)='onChangelocation("wardId")'>
            <mat-option *ngFor="let w of wardData" [value]="{'village_id':w.ward_id,'village_name':w.ward_name}">
              {{w.ward_name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ion-col>
      <ion-col size="12" size-xl="6" size-md="6" *ngIf="healthWards.length > 0">
        <ion-label>{{'Health Wards' | translate}}</ion-label>
        <mat-form-field appearance="outline" class="ion-margin-vertical matDropdown">
          <mat-select placeholder="Select from the list" formControlName="health_ward_id" [compareWith]="compareVillages"
            (selectionChange)='onChangelocation("healthWardId")'>
            <mat-option *ngFor="let hw of healthWards" [value]="{'village_id':hw.ward_id,'village_name':hw.ward_name}">
              {{hw.ward_name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ion-col>
    </ng-container>
    <ng-container *ngIf="dataService.userDetails.role == 'ASHA'">

      <ion-col size="12" size-xl="6" size-md="6" *ngIf="wardData.length > 0">
        <ion-label>{{'LGD Wards'| translate }}</ion-label>
        <mat-form-field appearance="outline" class="ion-margin-vertical matDropdown">
          <mat-select [placeholder]="'Select from the list' | translate" formControlName="ward_id" [compareWith]="compareVillages"
            (selectionChange)='onChangelocation("wardId")'>
            <mat-option *ngFor="let w of wardData" [value]="{'village_id':w.ward_id,'village_name':w.ward_name}">
              {{w.ward_name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ion-col>
      <ion-col size="12" size-xl="6" size-md="6" *ngIf="healthWards.length > 0">
        <ion-label>{{'Health Wards' | translate}}</ion-label>
        <mat-form-field appearance="outline" class="ion-margin-vertical matDropdown">
          <mat-select [placeholder]="'Select from the list' | translate" formControlName="health_ward_id" [compareWith]="compareVillages"
            (selectionChange)='onChangelocation("healthWardId")'>
            <mat-option *ngFor="let hw of healthWards" [value]="{'village_id':hw.ward_id,'village_name':hw.ward_name}">
              {{hw.ward_name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ion-col>
    </ng-container>
  <!-- <ion-col size="12" size-lg="3" size-md="2" class="marginTop">
      <ion-button class="cancelBtn">Clear</ion-button>
    </ion-col> -->
  </ion-row>
</form>
</ng-container>