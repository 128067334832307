<ion-grid [ngClass]="{'vertical': display === 'vertical', 'horizontal': display !== 'vertical'}">
  <ion-row>
    <ion-col [formGroup]='parentForm' [size]="display === 'vertical' ? 12 : 6" size-lg
      class="ion-no-padding inputFields">

      <app-ion-custom-select [label]="'Photo ID Type' | translate" formKey='photo_id_type' [parentForm]="parentForm"
        [arrObj]="idProofsList" [arrKey]="'type'" (onSelect)="onSelectIdType($event)" [arrKeyVal]="'type'"
        [placeholder]="'Select from dropdown' | translate">
      </app-ion-custom-select>
      <ng-container *ngIf="selectIdName == 'Aadhaar Card'">
        <ion-label class="required" *ngIf="selectIdName">{{selectIdName | translate}} {{'Number' | translate}}</ion-label>
        <ion-item class="item-border ion-no-padding" lines="none"  *ngIf="toggleType">
          <ion-input required appIntegerInput numbersOnly aadhaarValid
            (keyup)="restrictUserToTypeMoreThanLength($event, 12)" appAadharNumber
            [appRange]="[200000000000, 999999999999]" errorText=" " formControlName='photo_id_number' maxlength="12"
            [placeholder]="'Photo ID Number' | translate" type="password"></ion-input>
            <ion-icon (click)="toggleType = !toggleType" name="eye-off-outline" slot="end" class="passwordIcon">
            </ion-icon>
        </ion-item>
        <ion-item class="item-border ion-no-padding" lines="none" *ngIf="!toggleType">
          <ion-input required appIntegerInput numbersOnly aadhaarValid
            (keyup)="restrictUserToTypeMoreThanLength($event, 12)" appAadharNumber
            [appRange]="[200000000000, 999999999999]" errorText=" " formControlName='photo_id_number' maxlength="12"
            [placeholder]="'Photo ID Number' | translate" type="tel"></ion-input>
            <ion-icon (click)="toggleType = !toggleType" name="eye-outline" slot="end" class="passwordIcon">
            </ion-icon>
        </ion-item>
        <ion-label
          *ngIf="!parentForm.controls.photo_id_number.valid  && (parentForm.controls.photo_id_number.hasError('range'))"
          class="error-validation ">
          <p>{{'Aadhaar Number must be 12 digits' | translate}}</p>
        </ion-label>
        <ion-label
          *ngIf="!(parentForm.controls.photo_id_number.hasError('range')) &&(parentForm.controls.photo_id_number.hasError('notValidAadhaar'))"
          class="error-validation ">
          <p>{{'Aadhaar Number is Invalid' | translate}}</p>
        </ion-label>
      </ng-container>
      <ng-container *ngIf="selectIdName == 'PAN Card'">
        <ion-label class="required" *ngIf="selectIdName">{{selectIdName | translate}} {{'Number' | translate}}</ion-label>
        <ion-item class="item-border ion-no-padding" lines="none"  *ngIf="toggleType">
          <ion-input required [appRangeLength]='[7, 20]'
            (input)="parentForm.patchValue({photo_id_number: $event.target.value.toUpperCase()})" appInputChar='pancard'
            type="password" errorText=" " formControlName='photo_id_number' maxlength="20" [placeholder]="'Photo ID Number' | translate"></ion-input>
            <ion-icon (click)="toggleType = !toggleType" name="eye-off-outline" slot="end" class="passwordIcon">
            </ion-icon>
        </ion-item>
        <ion-item class="item-border ion-no-padding" lines="none"  *ngIf="!toggleType">
          <ion-input required [appRangeLength]='[7, 20]'
            (input)="parentForm.patchValue({photo_id_number: $event.target.value.toUpperCase()})" appInputChar='pancard'
            type="text" errorText=" " formControlName='photo_id_number' maxlength="20" [placeholder]="'Photo ID Number' | translate"></ion-input>
            <ion-icon (click)="toggleType = !toggleType" name="eye-outline" slot="end" class="passwordIcon">
            </ion-icon>
        </ion-item>
        <ion-label
          *ngIf="!parentForm.controls.photo_id_number.valid  && (parentForm.controls.photo_id_number.hasError('inputcharvalidator'))"
          class="error-validation ">
          <p>{{'Please Enter Valid Pan Card Number' | translate}}</p>
        </ion-label>
        <ion-label
          *ngIf="!parentForm.controls.photo_id_number.valid &&(!parentForm.controls.photo_id_number.hasError('inputcharvalidator') && parentForm.controls.photo_id_number.hasError('rangeLength'))"
          class="error-validation ">
          <p>{{'Must be between 7 to 20 Characters' | translate}}</p>
        </ion-label>
      </ng-container>
      <ng-container *ngIf="selectIdName === 'Driving License'">
        <ion-label class="required" *ngIf="selectIdName">{{selectIdName | translate}} {{'Number' | translate}}</ion-label>
        <ion-item class="item-border ion-no-padding" lines="none" *ngIf="toggleType">
          <ion-input required [appRangeLength]='[7, 16]' appInputChar='dlnumber' type="password"
            (keyup)="removeSpaceFromPhotoId();upperCase($event)" maxlength="16" errorText=" " formControlName='photo_id_number'
            [placeholder]="'Photo ID Number' | translate"></ion-input>
            <ion-icon (click)="toggleType = !toggleType" name="eye-off-outline" slot="end" class="passwordIcon">
            </ion-icon>
        </ion-item>
        <ion-item class="item-border ion-no-padding" lines="none"  *ngIf="!toggleType">
          <ion-input required [appRangeLength]='[7, 16]' appInputChar='dlnumber' type="text"
            (keyup)="removeSpaceFromPhotoId();upperCase($event)" maxlength="16" errorText=" " formControlName='photo_id_number'
            [placeholder]="'Photo ID Number' | translate"></ion-input>
            <ion-icon (click)="toggleType = !toggleType" name="eye-outline" slot="end" class="passwordIcon">
            </ion-icon>
        </ion-item>
        <ion-label
          *ngIf="!parentForm.controls.photo_id_number.valid  && (parentForm.controls.photo_id_number.hasError('inputcharvalidator'))"
          class="error-validation ">
          <p>{{'Please enter valid driving license number' | translate}}</p>
        </ion-label>
        <ion-label
          *ngIf="!parentForm.controls.photo_id_number.valid  &&(!parentForm.controls.photo_id_number.hasError('inputcharvalidator') && parentForm.controls.photo_id_number.hasError('rangeLength'))"
          class="error-validation ">
          <p>{{'Must be between 7 to 16 Characters' | translate}}</p>
        </ion-label>
      </ng-container>
      <ng-container *ngIf="selectIdName === 'Passport'">
        <ion-label class="required" *ngIf="selectIdName">{{selectIdName | translate}} {{'Number' |translate}}</ion-label>
        <ion-item class="item-border ion-no-padding" lines="none"  *ngIf="toggleType">
          <ion-input required [appRangeLength]='[4, 14]' appInputChar='passportnumber' type="password"
            (input)="parentForm.patchValue({photo_id_number: $event.target.value.toUpperCase()})"
            (keyup)="removeSpaceFromPhotoId()" maxlength="14" errorText=" " formControlName='photo_id_number'
            [placeholder]="'Photo ID Number' | translate">
          </ion-input>
          <ion-icon (click)="toggleType = !toggleType" name="eye-off-outline" slot="end" class="passwordIcon">
          </ion-icon>
        </ion-item>
        <ion-item class="item-border ion-no-padding" lines="none"  *ngIf="!toggleType">
          <ion-input required [appRangeLength]='[4, 14]' appInputChar='passportnumber' type="text"
            (input)="parentForm.patchValue({photo_id_number: $event.target.value.toUpperCase()})"
            (keyup)="removeSpaceFromPhotoId()" maxlength="14" errorText=" " formControlName='photo_id_number'
            [placeholder]="'Photo ID Number' | translate" errorText=" ">
          </ion-input>
          <ion-icon (click)="toggleType = !toggleType" name="eye-outline" slot="end" class="passwordIcon">
          </ion-icon>
        </ion-item>
        <ion-label
          *ngIf="!parentForm.controls.photo_id_number.valid  && (parentForm.controls.photo_id_number.hasError('inputcharvalidator'))"
          class="error-validation ">
          <p>{{'Please enter valid passport number' | translate}}</p>
        </ion-label>
        <ion-label
          *ngIf="!parentForm.controls.photo_id_number.valid  &&(!parentForm.controls.photo_id_number.hasError('inputcharvalidator') && parentForm.controls.photo_id_number.hasError('rangeLength'))"
          class="error-validation ">
          <p>{{'Must be between 4 to 14 Characters' | translate}}</p>
        </ion-label>
      </ng-container>
      <ng-container *ngIf="selectIdName === 'Pension Passbook'">
        <ion-label class="required" *ngIf="selectIdName">{{selectIdName | translate}} {{'Number' | translate}}</ion-label>
        <ion-item class="item-border ion-no-padding" lines="none" *ngIf="toggleType">
          <ion-input required [appRangeLength]='[8, 14]' appInputChar='pensionpassbook' type="password"
            (input)="parentForm.patchValue({photo_id_number: $event.target.value.toUpperCase()})"
            (keyup)="removeSpaceFromPhotoId()" maxlength="14" [placeholder]="'Photo ID Number' | translate"
            errorText=" " formControlName='photo_id_number'></ion-input>
            <ion-icon (click)="toggleType = !toggleType" name="eye-off-outline" slot="end" class="passwordIcon">
            </ion-icon>
        </ion-item>
        <ion-item class="item-border ion-no-padding" lines="none" *ngIf="!toggleType">
          <ion-input required [appRangeLength]='[8, 14]' appInputChar='pensionpassbook' type="text"
            (input)="parentForm.patchValue({photo_id_number: $event.target.value.toUpperCase()})"
            (keyup)="removeSpaceFromPhotoId()" maxlength="14" [placeholder]="'Photo ID Number' | translate"
            errorText=" " formControlName='photo_id_number'></ion-input>
            <ion-icon (click)="toggleType = !toggleType" name="eye-outline" slot="end" class="passwordIcon">
            </ion-icon>
        </ion-item>
        <ion-label
          *ngIf="!parentForm.controls.photo_id_number.valid  && (parentForm.controls.photo_id_number.hasError('inputcharvalidator'))"
          class="error-validation ">
          <p>{{'Must contain only English Alphabets (“a-z” and “A-Z”), Numbers(0-9), Dot “.”' | translate}}</p>
        </ion-label>
        <ion-label
          *ngIf="!parentForm.controls.photo_id_number.valid  && (!parentForm.controls.photo_id_number.hasError('inputcharvalidator') && parentForm.controls.photo_id_number.hasError('rangeLength'))"
          class="error-validation ">
          <p>{{'Must be between 8 to 14 Characters' | translate}}</p>
        </ion-label>
      </ng-container>
      <ng-container *ngIf="selectIdName === 'NPR Smart Card'">
        <ion-label class="required" *ngIf="selectIdName">{{selectIdName | translate}} {{'Number' | translate}}</ion-label>
        <ion-item class="item-border ion-no-padding" lines="none" *ngIf="toggleType">
          <ion-input required [appRangeLength]='[8, 14]' appInputChar='idnumber' type="password"
            (input)="parentForm.patchValue({photo_id_number: $event.target.value.toUpperCase()})"
            (keyup)="removeSpaceFromPhotoId()" maxlength="14" [placeholder]="'Photo ID Number' | translate"
            errorText=" " formControlName='photo_id_number'></ion-input>
            <ion-icon (click)="toggleType = !toggleType" name="eye-off-outline" slot="end" class="passwordIcon">
            </ion-icon>
        </ion-item>
        <ion-item class="item-border ion-no-padding" lines="none" *ngIf="!toggleType">
          <ion-input required [appRangeLength]='[8, 14]' appInputChar='idnumber' type="text"
            (input)="parentForm.patchValue({photo_id_number: $event.target.value.toUpperCase()})"
            (keyup)="removeSpaceFromPhotoId()" maxlength="14" [placeholder]="'Photo ID Number' | translate"
            errorText=" " formControlName='photo_id_number'></ion-input>
            <ion-icon (click)="toggleType = !toggleType" name="eye-outline" slot="end" class="passwordIcon">
            </ion-icon>
        </ion-item>
        <ion-label
          *ngIf="!parentForm.controls.photo_id_number.valid  && (parentForm.controls.photo_id_number.hasError('inputcharvalidator'))"
          class="error-validation ">
          <p>{{'Must contain only English Alphabets (“a-z” and “A-Z”), Numbers(0-9), Dot “.”, Hyphen “-” ,Slash "/"' | translate}}</p>
        </ion-label>
        <ion-label
          *ngIf="!parentForm.controls.photo_id_number.valid  && (!parentForm.controls.photo_id_number.hasError('inputcharvalidator') && parentForm.controls.photo_id_number.hasError('rangeLength'))"
          class="error-validation ">
          <p>{{'Must be between 8 to 14 Characters' | translate}}</p>
        </ion-label>
      </ng-container>
      <ng-container *ngIf="selectIdName === 'Voter ID'">
        <ion-label class="required" *ngIf="selectIdName">{{selectIdName | translate}} {{'Number' | translate}}</ion-label>
        <ion-item class="item-border ion-no-padding" lines="none" *ngIf="toggleType">
          <ion-input required [appRangeLength]='[8, 14]' appInputChar='idnumber' type="password"
            (input)="parentForm.patchValue({photo_id_number: $event.target.value.toUpperCase()})"
            (keyup)="removeSpaceFromPhotoId()" maxlength="14" [placeholder]="'Photo ID Number' | translate"
            errorText=" " formControlName='photo_id_number'></ion-input>
            <ion-icon (click)="toggleType = !toggleType" name="eye-off-outline" slot="end" class="passwordIcon">
            </ion-icon>
        </ion-item>
        <ion-item class="item-border ion-no-padding" lines="none" *ngIf="!toggleType">
          <ion-input required [appRangeLength]='[8, 14]' appInputChar='idnumber' type="text"
            (input)="parentForm.patchValue({photo_id_number: $event.target.value.toUpperCase()})"
            (keyup)="removeSpaceFromPhotoId()" maxlength="14" [placeholder]="'Photo ID Number' | translate"
            errorText=" " formControlName='photo_id_number'></ion-input>
            <ion-icon (click)="toggleType = !toggleType" name="eye-outline" slot="end" class="passwordIcon">
            </ion-icon>
        </ion-item>
        <ion-label
          *ngIf="!parentForm.controls.photo_id_number.valid  && (parentForm.controls.photo_id_number.hasError('inputcharvalidator'))"
          class="error-validation ">
          <p>{{'Must contain only English Alphabets (“a-z” and “A-Z”), Numbers(0-9), Dot “.”, Hyphen “-”, Slash "/"' | translate}}</p>
        </ion-label>
        <ion-label
          *ngIf="!parentForm.controls.photo_id_number.valid  && (!parentForm.controls.photo_id_number.hasError('inputcharvalidator') && parentForm.controls.photo_id_number.hasError('rangeLength'))"
          class="error-validation ">
          <p>{{'Must be between 8 to 14 Characters' | translate}}</p>
        </ion-label>
      </ng-container>
      <ng-container *ngIf="selectIdName === 'Ration Card with Photo'">
        <ion-label class="required" *ngIf="selectIdName">{{selectIdName | translate}} {{'Number' | translate}}</ion-label>
        <ion-item class="item-border ion-no-padding" lines="none" *ngIf="toggleType">
          <ion-input required [appRangeLength]='[8, 18]' appInputChar='onlyAlphaNum' type="password"
            (input)="parentForm.patchValue({photo_id_number: $event.target.value.toUpperCase()})"
            (keyup)="removeSpaceFromPhotoId()" maxlength="18" [placeholder]="'Photo ID Number' | translate"
            errorText=" " formControlName='photo_id_number'></ion-input>
            <ion-icon (click)="toggleType = !toggleType" name="eye-off-outline" slot="end" class="passwordIcon">
            </ion-icon>
        </ion-item>
        <ion-item class="item-border ion-no-padding" lines="none" *ngIf="!toggleType">
          <ion-input required [appRangeLength]='[8, 18]' appInputChar='onlyAlphaNum' type="text"
            (input)="parentForm.patchValue({photo_id_number: $event.target.value.toUpperCase()})"
            (keyup)="removeSpaceFromPhotoId()" maxlength="18" [placeholder]="'Photo ID Number' | translate"
            errorText=" " formControlName='photo_id_number'></ion-input>
            <ion-icon (click)="toggleType = !toggleType" name="eye-outline" slot="end" class="passwordIcon">
            </ion-icon>
        </ion-item>
        <ion-label
          *ngIf="!parentForm.controls.photo_id_number.valid  && (parentForm.controls.photo_id_number.hasError('inputcharvalidator'))"
          class="error-validation ">
          <p>{{'Must contain only English Alphabets (“a-z” and “A-Z”), Numbers(0-9)' | translate}}</p>
        </ion-label>
        <ion-label
          *ngIf="!parentForm.controls.photo_id_number.valid  && (!parentForm.controls.photo_id_number.hasError('inputcharvalidator') && parentForm.controls.photo_id_number.hasError('rangeLength'))"
          class="error-validation ">
          <p>{{'Must be between 8 to 18 Characters' | translate}}</p>
        </ion-label>
      </ng-container>
      <ng-container *ngIf="selectIdName === 'Unique Disability ID (Unique ID for Persons with Disabilities)'">
        <ion-label class="required" *ngIf="selectIdName">{{selectIdName | translate}} {{'Number' | translate}}</ion-label>
        <ion-item class="item-border ion-no-padding" lines="none" *ngIf="toggleType" >
          <ion-input required minlength="18" maxlength="18" appInputChar='udidcard' type="password"
            (input)="parentForm.patchValue({photo_id_number: $event.target.value.toUpperCase()})"
            (keyup)="removeSpaceFromPhotoId()" [placeholder]="'Photo ID Number' | translate" errorText=" " formControlName='photo_id_number'>
          </ion-input>
          <ion-icon (click)="toggleType = !toggleType" name="eye-off-outline" slot="end" class="passwordIcon">
          </ion-icon>
        </ion-item>
        <ion-item class="item-border ion-no-padding" lines="none" *ngIf="!toggleType">
          <ion-input required minlength="18" maxlength="18" appInputChar='udidcard' type="text"
            (input)="parentForm.patchValue({photo_id_number: $event.target.value.toUpperCase()})"
            (keyup)="removeSpaceFromPhotoId()" [placeholder]="'Photo ID Number' | translate" errorText=" " formControlName='photo_id_number' errorText=" ">
          </ion-input>
          <ion-icon (click)="toggleType = !toggleType" name="eye-outline" slot="end" class="passwordIcon">
          </ion-icon>
        </ion-item>
        <ion-label
          *ngIf="!parentForm.controls.photo_id_number.valid  && (parentForm.controls.photo_id_number.hasError('inputcharvalidator'))"
          class="error-validation ">
          <p>{{'Please enter valid UDID number' | translate}}</p>
        </ion-label>
        <ion-label
          *ngIf="!parentForm.controls.photo_id_number.valid  && (!parentForm.controls.photo_id_number.hasError('inputcharvalidator') && parentForm.controls.photo_id_number.hasError('minlength'))"
          class="error-validation ">
          <p>{{'Must be 18 Characters' | translate}}</p>
        </ion-label>
      </ng-container>
      <ng-container
        *ngIf="(selectIdName == null && parentForm.controls.photo_id_type.value === 'Pension Passbook') ||(selectIdName == null && parentForm.controls.photo_id_type.value=='Voter ID') || (selectIdName == null && parentForm.controls.photo_id_type.value=='NPR Smart Card')  || (selectIdName == null && parentForm.controls.photo_id_type.value=='Unique Disability ID (Unique ID for Persons with Disabilities)')">
        <ion-label class="required">{{'Photo ID Number' | translate}}</ion-label>
        <ion-item class="item-border ion-no-padding" lines="none" *ngIf="toggleType">
          <ion-input required [appRangeLength]='[8, 14]' appInputChar='idnumber' type="password"
            errorText=" " formControlName='photo_id_number' maxlength="14"
            [placeholder]="'Enter ID number without special characters &#8220;&#8211;&#44;&#47;&#44;&#40;&#41;&#8220; ' | translate">
          </ion-input>
          <ion-icon (click)="toggleType = !toggleType" name="eye-off-outline" slot="end" class="passwordIcon">
          </ion-icon>
        </ion-item>
        <ion-item class="item-border ion-no-padding" lines="none" *ngIf="!toggleType">
          <ion-input required [appRangeLength]='[8, 14]' appInputChar='idnumber' type="text"
            errorText=" " formControlName='photo_id_number' maxlength="14"
            [placeholder]="'Enter ID number without special characters &#8220;&#8211;&#44;&#47;&#44;&#40;&#41;&#8220; ' | translate" errorText=" ">
          </ion-input>
          <ion-icon (click)="toggleType = !toggleType" name="eye-outline" slot="end" class="passwordIcon">
          </ion-icon>
        </ion-item>
        <ion-label
          *ngIf="!parentForm.controls.photo_id_number.valid  && (parentForm.controls.photo_id_number.hasError('inputcharvalidator'))"
          class="error-validation ">
          <p>{{'Must contain only English Alphabets (“a-z” and “A-Z”), Numbers(0-9), Dot “.”, Hyphen “-”,Slash "/"' | translate}}</p>
        </ion-label>
        <ion-label
          *ngIf="!parentForm.controls.photo_id_number.valid  && (!parentForm.controls.photo_id_number.hasError('inputcharvalidator') && parentForm.controls.photo_id_number.hasError('rangeLength'))"
          class="error-validation ">
          <p>{{'Must be between 8 to 14 Characters' | translate}}</p>
        </ion-label>
      </ng-container>
      <ng-container *ngIf="selectIdName == null && parentForm.controls.photo_id_type.value === 'Aadhaar Card'">
        <ion-label class="required">{{'Photo ID Number' | translate}}</ion-label>
        <ion-item class="item-border ion-no-padding" lines="none" *ngIf="toggleType">
          <ion-input required appIntegerInput numbersOnly aadhaarValid
            (keyup)="restrictUserToTypeMoreThanLength($event, 12)" appAadharNumber
            [appRange]="[200000000000, 999999999999]" errorText=" " formControlName='photo_id_number' maxlength="12"
            [placeholder]="'Photo ID Number' | translate" type="password"></ion-input>
            <ion-icon (click)="toggleType = !toggleType" name="eye-off-outline" slot="end" class="passwordIcon">
            </ion-icon>
        </ion-item>
        <ion-item class="item-border ion-no-padding" lines="none" *ngIf="!toggleType">
          <ion-input required appIntegerInput numbersOnly aadhaarValid
            (keyup)="restrictUserToTypeMoreThanLength($event, 12)" appAadharNumber
            [appRange]="[200000000000, 999999999999]" errorText=" " formControlName='photo_id_number' maxlength="12"
            [placeholder]="'Photo ID Number' | translate" type="tel"></ion-input>
            <ion-icon (click)="toggleType = !toggleType" name="eye-outline" slot="end" class="passwordIcon">
            </ion-icon>
        </ion-item>
        <ion-label
          *ngIf="!parentForm.controls.photo_id_number.valid  && (parentForm.controls.photo_id_number.hasError('range'))"
          class="error-validation ">
          <p>{{'Aadhaar Number must be 12 digits' | translate}}</p>
        </ion-label>
        <ion-label
          *ngIf="!(parentForm.controls.photo_id_number.hasError('range')) &&(parentForm.controls.photo_id_number.hasError('notValidAadhaar'))"
          class="error-validation ">
          <p>{{'Aadhaar Number is Invalid' | translate}}</p>
        </ion-label>
      </ng-container>
      <ng-container *ngIf="selectIdName == null && parentForm.controls.photo_id_type.value=='PAN Card'">
        <ion-label class="required">{{'Photo ID Number' | translate}}</ion-label>
        <ion-item class="item-border ion-no-padding" lines="none"  *ngIf="toggleType">
          <ion-input required [appRangeLength]='[7, 20]'
            (input)="parentForm.patchValue({photo_id_number: $event.target.value.toUpperCase()})" appInputChar='pancard'
            type="password" errorText=" " formControlName='photo_id_number' maxlength="20" [placeholder]="'Photo ID Number' | translate"></ion-input>
            <ion-icon (click)="toggleType = !toggleType" name="eye-off-outline" slot="end" class="passwordIcon">
            </ion-icon>
        </ion-item>
        <ion-item class="item-border ion-no-padding" lines="none"  *ngIf="!toggleType">
          <ion-input required [appRangeLength]='[7, 20]'
            (input)="parentForm.patchValue({photo_id_number: $event.target.value.toUpperCase()})" appInputChar='pancard'
            type="text" errorText=" " formControlName='photo_id_number' maxlength="20" [placeholder]="'Photo ID Number' | translate"></ion-input>
            <ion-icon (click)="toggleType = !toggleType" name="eye-outline" slot="end" class="passwordIcon">
            </ion-icon>
        </ion-item>
        <ion-label
          *ngIf="!parentForm.controls.photo_id_number.valid  && (parentForm.controls.photo_id_number.hasError('inputcharvalidator'))"
          class="error-validation ">
          <p>{{'Please Enter Valid Pan Card Number' | translate}}</p>
        </ion-label>
        <ion-label
          *ngIf="!parentForm.controls.photo_id_number.valid &&(!parentForm.controls.photo_id_number.hasError('inputcharvalidator') && parentForm.controls.photo_id_number.hasError('rangeLength'))"
          class="error-validation ">
          <p>{{'Must be between 7 to 20 Characters' | translate}}</p>
        </ion-label>
      </ng-container>
      <ng-container *ngIf="selectIdName == null && parentForm.controls.photo_id_type.value=='Driving License'">
        <ion-label class="required">{{'Photo ID Number' | translate}}</ion-label>
        <ion-item class="item-border ion-no-padding" lines="none" *ngIf="toggleType" >
          <ion-input required [appRangeLength]='[7, 16]' appInputChar='dlnumber' type="password"
            (keyup)="removeSpaceFromPhotoId();upperCase($event)" maxlength="16" errorText=" " formControlName='photo_id_number'
            [placeholder]="'Photo ID Number' | translate"></ion-input>
            <ion-icon (click)="toggleType = !toggleType" name="eye-off-outline" slot="end" class="passwordIcon">
            </ion-icon>
        </ion-item>
        <ion-item class="item-border ion-no-padding" lines="none" *ngIf="!toggleType">
          <ion-input required [appRangeLength]='[7, 16]' appInputChar='dlnumber' type="text"
            (keyup)="removeSpaceFromPhotoId();upperCase($event)" maxlength="16" errorText=" " formControlName='photo_id_number'
            [placeholder]="'Photo ID Number' | translate"></ion-input>
            <ion-icon (click)="toggleType = !toggleType" name="eye-outline" slot="end" class="passwordIcon">
            </ion-icon>
        </ion-item>
        <ion-label
          *ngIf="!parentForm.controls.photo_id_number.valid  && (parentForm.controls.photo_id_number.hasError('inputcharvalidator'))"
          class="error-validation ">
          <p>{{'Please enter valid driving license number' | translate}}</p>
        </ion-label>
        <ion-label
          *ngIf="!parentForm.controls.photo_id_number.valid  &&(!parentForm.controls.photo_id_number.hasError('inputcharvalidator') && parentForm.controls.photo_id_number.hasError('rangeLength'))"
          class="error-validation ">
          <p>{{'Must be between 7 to 16 Characters' | translate}}</p>
        </ion-label>
      </ng-container>
      <ng-container *ngIf="selectIdName == null && parentForm.controls.photo_id_type.value =='Passport'">
        <ion-label class="required">{{'Photo ID Number' | translate}}</ion-label>
        <ion-item class="item-border ion-no-padding" lines="none"  *ngIf="toggleType">
          <ion-input required [appRangeLength]='[4, 14]' appInputChar='passportnumber' type="password"
            (input)="parentForm.patchValue({photo_id_number: $event.target.value.toUpperCase()})"
            (keyup)="removeSpaceFromPhotoId()" maxlength="14" errorText=" " formControlName='photo_id_number'
            [placeholder]="'Photo ID Number' | translate">
          </ion-input>
          <ion-icon (click)="toggleType = !toggleType" name="eye-off-outline" slot="end" class="passwordIcon">
          </ion-icon>
        </ion-item>
        <ion-item class="item-border ion-no-padding" lines="none"  *ngIf="!toggleType">
          <ion-input required [appRangeLength]='[4, 14]' appInputChar='passportnumber' type="text"
            (input)="parentForm.patchValue({photo_id_number: $event.target.value.toUpperCase()})"
            (keyup)="removeSpaceFromPhotoId()" maxlength="14" errorText=" " formControlName='photo_id_number'
            [placeholder]="'Photo ID Number' | translate" errorText=" ">
          </ion-input>
          <ion-icon (click)="toggleType = !toggleType" name="eye-outline" slot="end" class="passwordIcon">
          </ion-icon>
        </ion-item>
        <ion-label
          *ngIf="!parentForm.controls.photo_id_number.valid  && (parentForm.controls.photo_id_number.hasError('inputcharvalidator'))"
          class="error-validation ">
          <p>{{'Please enter valid passport number' | translate}}</p>
        </ion-label>
        <ion-label
          *ngIf="!parentForm.controls.photo_id_number.valid  &&(!parentForm.controls.photo_id_number.hasError('inputcharvalidator') && parentForm.controls.photo_id_number.hasError('rangeLength'))"
          class="error-validation ">
          <p>{{'Must be between 4 to 14 Characters' | translate}}</p>
        </ion-label>
      </ng-container>
      <ng-container *ngIf="selectIdName == null && parentForm.controls.photo_id_type.value=='Ration Card with Photo'">
        <ion-label *ngIf="!selectIdName" class="required">{{'Photo ID Number' | translate}}</ion-label>
        <ion-label class="required" *ngIf="selectIdName">{{selectIdName}} {{'Number' | translate}}</ion-label>
        <ion-item class="item-border ion-no-padding" lines="none" *ngIf="toggleType">
          <ion-input required [appRangeLength]='[8, 18]' appInputChar='onlyAlphaNum' type="password"
            (input)="parentForm.patchValue({photo_id_number: $event.target.value.toUpperCase()})"
            (keyup)="removeSpaceFromPhotoId()" maxlength="18" [placeholder]="'Photo ID Number' | translate"
            errorText=" " formControlName='photo_id_number'></ion-input>
            <ion-icon (click)="toggleType = !toggleType" name="eye-off-outline" slot="end" class="passwordIcon">
            </ion-icon>
        </ion-item>
        <ion-item class="item-border ion-no-padding" lines="none" *ngIf="!toggleType">
          <ion-input required [appRangeLength]='[8, 18]' appInputChar='onlyAlphaNum' type="text"
            (input)="parentForm.patchValue({photo_id_number: $event.target.value.toUpperCase()})"
            (keyup)="removeSpaceFromPhotoId()" maxlength="18" [placeholder]="'Photo ID Number' | translate"
            errorText=" " formControlName='photo_id_number'></ion-input>
            <ion-icon (click)="toggleType = !toggleType" name="eye-outline" slot="end" class="passwordIcon">
            </ion-icon>
        </ion-item>
        <ion-label
          *ngIf="!parentForm.controls.photo_id_number.valid  && (parentForm.controls.photo_id_number.hasError('inputcharvalidator'))"
          class="error-validation ">
          <p>{{'Must contain only English Alphabets (“a-z” and “A-Z”), Numbers(0-9)' | translate}}</p>
        </ion-label>
        <ion-label
          *ngIf="!parentForm.controls.photo_id_number.valid  && (!parentForm.controls.photo_id_number.hasError('inputcharvalidator') && parentForm.controls.photo_id_number.hasError('rangeLength'))"
          class="error-validation ">
          <p>{{'Must be between 8 to 18 Characters' | translate}}</p>
        </ion-label>
      </ng-container>
      <ng-container *ngIf="parentForm.controls.photo_id_type.value === 'Student Photo Id Card'">
        <ion-label *ngIf="!selectIdName" class="required">{{'Photo ID Number' | translate}}</ion-label>
        <ion-label *ngIf="selectIdName" class="required">{{selectIdName | translate}} {{'Number' | translate}}</ion-label>
        <ion-item class="item-border ion-no-padding" lines="none" *ngIf="toggleType">
          <ion-input required [appRangeLength]='[4, 12]' appInputChar='onlyAlphaNum' type="password"
            (input)="parentForm.patchValue({photo_id_number: $event.target.value.toUpperCase()})"
            (keyup)="removeSpaceFromPhotoId()" maxlength="14" [placeholder]="'Photo ID Number' | translate"
            errorText=" " formControlName='photo_id_number'></ion-input>
            <ion-icon (click)="toggleType = !toggleType" name="eye-off-outline" slot="end" class="passwordIcon">
            </ion-icon>
        </ion-item>
        <ion-item class="item-border ion-no-padding" lines="none" *ngIf="!toggleType">
          <ion-input required [appRangeLength]='[4, 12]' appInputChar='onlyAlphaNum' type="text"
            (input)="parentForm.patchValue({photo_id_number: $event.target.value.toUpperCase()})"
            (keyup)="removeSpaceFromPhotoId()" maxlength="14" [placeholder]="'Photo ID Number' | translate"
            errorText=" " formControlName='photo_id_number'></ion-input>
            <ion-icon (click)="toggleType = !toggleType" name="eye-outline" slot="end" class="passwordIcon">
            </ion-icon>
        </ion-item>
        <ion-label
          *ngIf="!parentForm.controls.photo_id_number.valid  && (parentForm.controls.photo_id_number.hasError('inputcharvalidator'))"
          class="error-validation ">
          <p>{{'Must contain only English Alphabets (“a-z” and “A-Z”), Numbers(0-9), Dot “.”, Hyphen “-”' | translate}}</p>
        </ion-label>
        <ion-label
          *ngIf="!parentForm.controls.photo_id_number.valid  && (!parentForm.controls.photo_id_number.hasError('inputcharvalidator') && parentForm.controls.photo_id_number.hasError('rangeLength'))"
          class="error-validation ">
          <p>{{'Must be between 4 to 12 Characters' | translate}}</p>
        </ion-label>
      </ng-container>
    </ion-col>
  </ion-row>
</ion-grid>
