import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AppState, AuthState } from '../../interfaces/auth.interface';

export const selectAuthState =
  createFeatureSelector<AppState, AuthState>('auth');

export const selectAuth = createSelector(
  selectAuthState,
  (state: AuthState) => state
);

export const selectIsAuthenticated = createSelector(
  selectAuthState,
  (state: AuthState) => {
    // console.log(state);
    return state?.isAuthenticated}
);

