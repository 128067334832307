import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import {BehaviorSubject, Observable,Observer } from 'rxjs'
import { environment } from 'src/environments/environment';
import { UtilsService } from '../../shared/services/utils.service';
import { Platform } from '@ionic/angular';
import { switchMap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private notificationsCount = new BehaviorSubject<number>(0);
  public notificationCount$: Observable<number>;
  constructor(private http: HttpClient,
    private readonly snackBar: MatSnackBar,
    private readonly zone: NgZone,
    private sanitizer: DomSanitizer,
    private util:UtilsService,
    private plt: Platform, 
  ) {
    this.notificationCount$ = this.notificationsCount.asObservable();
  }
  

  default(message: string) {
    this.show(message, {
      duration: 2000,
      panelClass: 'default-notification-overlay'
    });
  }

  info(message: string) {
    this.show(message, {
      duration: 2000,
      panelClass: 'info-notification-overlay'
    });
  }

  success(message: string) {
    this.show(message, {
      duration: 2000,
      panelClass: 'success-notification-overlay'
    });
  }

  warn(message: string) {
    this.show(message, {
      duration: 2500,
      panelClass: 'warning-notification-overlay'
    });
  }

  error(message: string) {
    this.show(message, {
      duration: 3000,
      panelClass: 'error-notification-overlay'
    });
  }

  private show(message: string, configuration: MatSnackBarConfig) {
    // Need to open snackBar from Angular zone to prevent issues with its position per
    // https://stackoverflow.com/questions/50101912/snackbar-position-wrong-when-use-errorhandler-in-angular-5-and-material
    this.zone.run(() => this.snackBar.open(message, null, configuration));
  }
  getNotificationDetails(postParams): Observable<any> {
    return this.http.post(`${environment.admin_prefix_url}/notification/fetchNotifications`, postParams);
  }

  getUnreadNotifications(): Observable<any> {
    return this.http.get(`${environment.admin_prefix_url}/notification/getUnreadNotifications`);
  }

  readNotification(item: any): Observable<any> {
    return this.http.post(
      `${environment.admin_prefix_url}/notification/read`,
      { notificationId: item.notification_id }
    );
  }
  logShareCnt() {
    return this.http.post(`${environment.beneficiary_registration_prefix_url}/beneficiary/v2/sharedCount`, {"shared":true});
  }
  parseMsg(item) {
    if (item.placeholder && item.placeholder !== '""') {
      const placeHolder = this.util.parseJson(item.placeholder);
      if (placeHolder instanceof Object) {
        placeHolder.map((e, i) => {
          let msg = e;
          if (e && e.toString().match(/api\/v1\/admin/)) {
            const filename = e.substring(e.lastIndexOf('/') + 1);
            msg = `(<a target="_blank" href="${environment.admin_prefix_url + e.replace('api/v1/admin/', '')}">${filename}</a>)`;
          }
          item.message = item.message.replace(`$${(i + 1)}`, (msg ? msg : ''));
          return e;
        });
      }
      return this.sanitizer.bypassSecurityTrustHtml(item.message);
    }
    return item.message;
  }
  setCountOfNotifications(count) {
    this.notificationsCount.next(count);
  }
  downloadImageSource(fileData) {
  }

  downloadImage(userId, lang) {
  }
  downloadImage1(userId, lang) {
  }

  downloadImageFromUrl(imageUrl) {
  }

  writeFile(fileData){
  }
  getBase64ImageFromURL(url: string) {
    return new Observable((observer: Observer<string>) => {
      const img: HTMLImageElement = new Image();
      img.crossOrigin = "Anonymous";
      img.src = url;
      if (!img.complete) {
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = err => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }

  getBase64Image(img: HTMLImageElement) {
    const canvas: HTMLCanvasElement = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx: CanvasRenderingContext2D = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    const dataURL: string = canvas.toDataURL("image/png");

    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }
}



