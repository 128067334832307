import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef, OnDestroy, Input, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Device } from '@capacitor/device';
import moment from 'moment';
import { switchMap } from 'rxjs/operators';
import { DownloadFileService } from 'src/app/modules/shared/services/download-file.service';
import { AddRecipientService } from 'src/app/screens/add-recipient/services/add-recipient.service';
import { RecipientService } from '../../services/recipient.service';
import { Subscription } from 'rxjs';
import { UtilsService } from 'src/app/modules/shared/services/utils.service';
import { Location } from '@angular/common';
import { SessionService } from 'src/app/screens/session/services/session.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LookUpService } from 'src/app/modules/shared/services/look-up.service';

@Component({
  selector: 'app-vaccinated-rejected-status',
  templateUrl: './vaccinated-rejected-status.component.html',
  styleUrls: ['./vaccinated-rejected-status.component.scss'],
})
export class VaccinatedRejectedStatusComponent implements OnInit, OnDestroy {
  @Input() activeStepIndex = null;
  @Output() onStepChange: EventEmitter<any> = new EventEmitter();
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  coordinates: any = null;
  reasonForm: FormGroup;
  vaccinationNotDoneReasons = [];
  vaccinationStatusData;
  isformSubmitted: boolean = true;
  currentTime = new Date();
  fileDownloadInProgress = false;
  subscribe: Subscription;
  environment;
  allMaterailsData:any=[];

  constructor(public recipientService: RecipientService, public addRecipientService: AddRecipientService,
    public downloadFile: DownloadFileService, public cdr: ChangeDetectorRef, public util: UtilsService,
    private loc: Location, private sessionService: SessionService,private router:Router,public lookup:LookUpService) { 
    this.lookup.getMaterials().subscribe(m => {
       this.allMaterailsData = m;
       this.cdr.detectChanges();
    });

    }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.activeStepIndex) {
      if (this.activeStepIndex == 'VACCINATIONREJECTED') {
        this.reloadComponent();
      } else {
        this.unSubscribe();
      }
    }
  }

  ngOnInit() { }

  unSubscribe() {
    if (this.subscribe) this.subscribe.unsubscribe();
  }

  reloadComponent() {
    this.environment =environment
    this.buildForm();
    this.getVaccinationNotDoneReason();
    this.subscribe = this.addRecipientService.benSuccessData.subscribe(res => {
      this.vaccinationStatusData = res;
      if(this.vaccinationStatusData.date_of_session){
        this.currentTime = this.vaccinationStatusData.date_of_session;
      }
    })
  }
  buildForm() {
    this.reasonForm = new FormGroup({
      reason: new FormControl('', [Validators.required]),
      comments: new FormControl(''),
      reject_consent: new FormControl('', [Validators.required])
    });
  }
  getVaccinationNotDoneReason() {
    this.recipientService.getVaccinationNotDoneReason().subscribe(res => {
      this.vaccinationNotDoneReasons = res;
    })
  }
  onSelectReasonType(event) {
    if (event == 'VNDR_003') {
      this.reasonForm.get('comments').setValidators([Validators.required]);
    }
    else {
      this.reasonForm.get('comments').clearValidators();
      this.reasonForm.get('comments').updateValueAndValidity();
    }
  }
  async saveMetaInfo() {
    const deviceInfo = await Device.getInfo();
    const metaPostData = {
      "beneficiary_id": this.vaccinationStatusData?.beneficiary_id,
      "vaccinator_device": deviceInfo.model,
      'vaccinator_lat': this.coordinates?.latitude ? (this.coordinates.latitude).toString() : '0',
      'vaccinator_long': this.coordinates?.longitude ? (this.coordinates.longitude).toString() : '0',
      "vaccination_datetime": moment().format()
    }
    this.recipientService.saveMetaInfoOfVaccination(metaPostData).subscribe();

  }
  updateVaccinationStatus(status) {
    let sessionId = null;
    if (this.loc.path().match(/session\/[^\s]*/g)) {
      sessionId = this.loc.path().replace('/session/', '');
    }
    // const benficieryData=this.vaccinationStatusData.this.vaccinationStatusData;
    const payload = {
      vaccination_status: status,
      session_id: this.vaccinationStatusData.session_id,
      session_site_id: this.vaccinationStatusData.session_site_id,
      session_state_id: this.vaccinationStatusData.state_id,
      session_district_id: this.vaccinationStatusData.district_id,
      village_id: this.vaccinationStatusData.village_id ? this.vaccinationStatusData.village_id :undefined,
      village_name: this.vaccinationStatusData.village_name ? this.vaccinationStatusData.village_name :undefined,
      health_village_id: this.vaccinationStatusData.health_village_id ? this.vaccinationStatusData.health_village_id :undefined,
      health_village_name: this.vaccinationStatusData.health_village_name ? this.vaccinationStatusData.health_village_name :undefined,
      ward_id: this.vaccinationStatusData.ward_id ? this.vaccinationStatusData.ward_id :undefined,
      ward_name: this.vaccinationStatusData.ward_name ? this.vaccinationStatusData.ward_name :undefined,
      health_ward_id: this.vaccinationStatusData.health_ward_id ? this.vaccinationStatusData.health_ward_id :undefined,
      health_ward_name: this.vaccinationStatusData.health_ward_name ? this.vaccinationStatusData.health_ward_name :undefined,
    }
    if(this.vaccinationStatusData.session_type_name == 'UIP'){
      payload['materialDetails'] = this.vaccinationStatusData.materialDetails;
      this.vaccinationNotDoneReasons.find(ele => {
        if (ele.reasonId == this.reasonForm.get('reason').value) {
          if (ele.reasonId == 'VNDR_003') {
            payload['reason'] = this.reasonForm.get('comments').value
          } else {
            payload['reason'] = ele.reasonName;
          }
        }
      })
      this.recipientService.updateMultiUipSessionVaccination(payload).subscribe(res=>{
        if (sessionId && payload.session_id && payload.session_id == sessionId) {
          this.sessionService.refreshSessionData$.next(true);
        }
        this.closeDialog();
      })
    }
   else if ( this.vaccinationStatusData.session_type_name == 'Others') {
      this.vaccinationNotDoneReasons.find(ele => {
        if (ele.reasonId == this.reasonForm.get('reason').value) {
          if (ele.reasonId == 'VNDR_003') {
            payload['reason'] = this.reasonForm.get('comments').value
          } else {
            payload['reason'] = ele.reasonName;
          }
        }
      })
      // payload['reason'] = this.reasonForm.get('reason').value;
      this.recipientService.updateVaccinationUIPStatus(payload).subscribe(res => {
        if (sessionId && payload.session_id && payload.session_id == sessionId) {
          this.sessionService.refreshSessionData$.next(true);
        }
        this.closeDialog();
      })
    }
    else {
      this.recipientService.updateVaccinationStatus(payload).pipe(
        switchMap(r => {
          if (sessionId && payload.session_id && payload.session_id == sessionId) {
            this.sessionService.refreshSessionData$.next(true);
          }
          this.saveMetaInfo();
          return this.recipientService.getApplicationTrackingDetails(this.vaccinationStatusData.beneficiary_reference_id);
        })).subscribe(res => {
          this.closeDialog();
        })
    }
  }
  onConfirmRejection() {
    this.reasonForm.markAllAsTouched();
    if (!this.reasonForm.valid) {
      return;
    } else {
      const payLoad = {
        vaccination_notdone_reason: this.reasonForm.value
      };
      this.recipientService.updateNotVaccinatedResaons(payLoad, this.vaccinationStatusData).subscribe(r => {
        this.updateVaccinationStatus(0);
      });
    }
  }
  closeDialog() {
    this.onClose.emit();
    this.sessionService.refreshSessionData$.next(true);
    if(this.vaccinationStatusData.session_id){
      this.router.navigate([`/session/${this.vaccinationStatusData.session_id}`])
    }else{
      this.router.navigate(['/dashboard'])
    }
  }

  gotoStep(val) {
    if(val == 'prev' && !this.vaccinationStatusData?.isRejected && this.util.getPlatformWidth() < 575){
      if(this.vaccinationStatusData.session_id){
        this.router.navigate([`/session/${this.vaccinationStatusData.session_id}`])
      }
    }else{
      this.onStepChange.emit(val);
    }
  }
  downloadCertificate() {
    if (!this.vaccinationStatusData?.beneficiary_reference_id) { return; }
    this.fileDownloadInProgress = true;
    const payload = {
      beneficiary_reference_id: '' + this.vaccinationStatusData.beneficiary_reference_id,
      vaccination_status: this.vaccinationStatusData.vaccination_status,
      typeOfCert: (this.vaccinationStatusData?.vaccination_doses_due == this.vaccinationStatusData?.dose_scheduled + 1) && this.vaccinationStatusData?.precaution_dose ? "Precaution" : undefined,
    };
    if(this.vaccinationStatusData?.session_type_name == 'UIP'){
      payload['beneficiary_name'] = this.vaccinationStatusData.ben_name,
      this.downloadFile.downloadUIPVaccinationCertificate(payload)
      .subscribe(r => {
        this.fileDownloadInProgress = false;
        this.cdr.detectChanges();
      }, er => {
        this.fileDownloadInProgress = false;
        this.cdr.detectChanges();
        this.util.presentToastError(('Please try after sometime'));
      });
      }else{
    this.downloadFile.downloadVaccinationCertificate(payload)
      .subscribe(r => {
        this.fileDownloadInProgress = false;
        this.cdr.detectChanges();
      }, er => {
        this.fileDownloadInProgress = false;
        this.cdr.detectChanges();
        this.util.presentToastError(('Please try after sometime'));
      });
    }
  }
  ngOnDestroy() {
    if (this.subscribe) this.subscribe.unsubscribe();
    // console.log("destroyed", this.subscribe.closed)
  }

  addMoreVaccine(){
    if (this.util.getPlatformWidth() > 575) { this.onStepChange.emit('add-vaccine'); }
    else { 
      this.router.navigateByUrl(`/member-vaccination-info/${this.vaccinationStatusData.beneficiary_id}/${this.vaccinationStatusData.session_id}`)
     }
    
  }
  labelName(doseArray){
    const materialDoseInfo = this.allMaterailsData?.find(e => e.material_id == doseArray.material_id);
    if(doseArray.is_precaution){
      return materialDoseInfo?.display_precaution_dose_names[doseArray.material_dose - 1];
    }
    return materialDoseInfo?.display_dose_names[doseArray.material_dose - 1 ];
    }

}
