import {createFeatureSelector, createSelector} from "@ngrx/store";
import {UserState} from "../reducers/user.reducer";
import {Statuses} from "../../constants/app.constants";
import { AppState } from "../../interfaces/auth.interface";

export const userState = createFeatureSelector<AppState, UserState>('user');

export const isUserLoading = createSelector(
  userState,
  (state: UserState) => state.status === Statuses.LOADING
);


export const getUserDetails = createSelector(
  userState,
  (state: UserState) => {
    return state?.data
  }
);