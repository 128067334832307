<div class="cm-right">
  <ion-spinner name="crescent" class="spinner-pos" *ngIf="!qrCodeLoaded" ></ion-spinner>
  <ion-img [src]="qrImg" alt=""  *ngIf="qrCodeLoaded"></ion-img>
  <div class="ion-text-center" >
    <p class="timer">
      <ng-container *ngIf="resendTimer > 0" >{{'Expire in' | translate}}  <span id="time"></span></ng-container>
      {{ (resendTimer > 0 ? resendTimer + ' ' + ('seconds' | translate) : '') }}
      <a *ngIf="resendTimer <= 0 && qrCodeLoaded"
      style="cursor: pointer; text-decoration: none;"
      (click)="reloadBarcode()" class="resend">{{'Refresh Barcode' | translate}}</a>
    </p>
  </div>
</div>
