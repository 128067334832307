import { Component, OnInit, ViewChild, Input, OnChanges, SimpleChanges, AfterViewInit, Output, EventEmitter, OnDestroy, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/modules/shared/services';
import { Location } from '@angular/common';
import { AddRecipientService } from '../../services/add-recipient.service';
import { UtilsService } from 'src/app/modules/shared/services/utils.service';
import { getSessionDetails } from 'src/app/modules/core/store/selectors/session.selectors';
import { select, Store } from '@ngrx/store';
import { BeneficiaryService } from 'src/app/screens/beneficiary/services/beneficiary.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RecipientService } from 'src/app/screens/recipient/services/recipient.service';
import { SessionService } from 'src/app/screens/session/services/session.service';



@Component({
  selector: 'app-add-recipient-identification-page',
  templateUrl: './member-identification.page.html',
  styleUrls: ['./member-identification.page.scss'],
})
export class MemberIdentificationPageComponent {
  sessionDetails:[]
  benfDetails$:Observable<any>;
  benId;
  sessionId;
  benData;
  constructor(
    public dataService: DataService,
    private router: Router,
    private loc: Location,
    private addRecipientService: AddRecipientService,
    public util: UtilsService,
    public store$: Store,
    public beneficiaryService:BeneficiaryService,
    public recipientService:RecipientService,
    public actRoute:ActivatedRoute,
    public sessionService:SessionService
  ) {
    this.benId = this.actRoute.snapshot.params.benID
    this.sessionId = this.actRoute.snapshot.params.sessionID;
  }

  ngOnInit() {
    
    
    const stateData: any = this.loc.getState();

    
    if(!stateData.data && this.benId && this.sessionId ){
      this.onRefreshedData()
    }
    if(stateData && stateData.data){
      this.addRecipientService.beneficiaryDetails = stateData.data;
    }
  }
  onRefreshedData(){
    this.sessionService.getSessionInfo(this.sessionId).toPromise().then(r=>{
      this.sessionDetails = r;
      this.recipientService.getBeneficairyDetails(this.benId).toPromise().then(r=>{
        this.benData = r;
        let obj ={
          ... this.sessionDetails,
          ... this.benData,
          ...{ isBenfAddedToSession: true }
        }
        this.addRecipientService.beneficiaryDetails = obj;
    })
  })     
  }

  onStepChange(val: any) {
    if (val === 'next') {
      if(this.benId && this.sessionId){
        this.router.navigate([`/member-personal-details/${this.benId}/${this.sessionId}`]);
      }else{
        this.router.navigate(['/new-member/member-personal-details']);
      }
    } else {
      this.loc.back();
    }
  }

  closeDialog() {
  }

}


