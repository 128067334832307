import { FormGroup } from '@angular/forms';
import { Component, Input, OnInit, AfterContentChecked, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { DataService } from 'src/app/modules/shared/services/data.service';
import { LocationService } from 'src/app/modules/shared/services/location.service';
import { UtilsService } from '../../services/utils.service';

import moment from 'moment';


@Component({
  selector: 'app-ion-custom-datepicker',
  templateUrl: './ion-custom-datepicker.component.html',
  styleUrls: ['./ion-custom-datepicker.component.scss'],
})
export class IonCustomDatepickerComponent implements OnInit, AfterContentChecked {
  @Input() formKey: any = null;
  @Input() minDate: any = new Date('01-01-1920');
  @Input() maxDate: any = new Date();
  @Input() isDisabled: boolean = false;
  @Input() parentForm: FormGroup;
  @Input() label: any = null;
  @Input() class: any = null;
  @Input() placeholder: any = null;
  @Output() dateValue = new EventEmitter()
  required = ''

  constructor(
    private locationsService: LocationService,
    private dataService: DataService,
    public utils: UtilsService,
  ) {
  }

  ngOnInit() {
    if (this.class == "noclass") {
      this.class = "";
    }
    else {
      this.class = "large-label";
    }
    if (this.formKey == 'dose_per_vial') {
      this.required = ''
    } else {
      this.required = 'required'
    }
    if (this.utils.getIsMobileDevice() || this.utils.getPlatformWidth() < 768) {
      this.minDate = moment(this.minDate).format('YYYY-MM-DD');
      this.maxDate = moment(this.maxDate).format('YYYY-MM-DD')
    }
  }

  ngAfterContentChecked(): void {
  }

  onChange(event) {
    // this.onSelect.emit(this.parentForm.get([this.formKey]).value);
  }

  onChangeDate(event) {
    this.dateValue.emit(event)
  }

  formatDate(value: string) {
    const dt = moment(value).format('DD/MM/YYYY');
    this.parentForm.get([this.formKey]).setValue(dt);
    this.dateValue.emit({ value })
    // return format(parseISO(value), 'MMM dd yyyy');
  }



}
