import { Component, OnInit, ViewChild, Input, OnChanges, SimpleChanges, AfterViewInit, Inject, } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Store, select } from '@ngrx/store';
import * as _ from 'lodash';
import { AddRecipientService } from '../../services/add-recipient.service';
import { Observable } from 'rxjs';
import { selectIsAuthenticated } from 'src/app/modules/core/core.module';


@Component({
  selector: 'app-recipient-vaccinate-stepper',
  templateUrl: './vaccinate-stepper.component.html',
  styleUrls: ['./vaccinate-stepper.component.scss'],
})
export class VaccinateStepperComponent implements OnInit {
  currentActiveStep = '';
  @ViewChild('stepper') stepper: MatStepper;
  isAuthenticated$: Observable<boolean>;
  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public addRecipientService: AddRecipientService,
    private store$: Store,
  ) {
    this.addRecipientService.beneficiaryDetails = data;
    this.addRecipientService.benSuccessDetails = data;
  }

  ngOnInit(): void {
    if(this.data.session_type_name=='UIP'){
        this.currentActiveStep = 'MULTIVACCUIP';
    }else{
      this.currentActiveStep = 'VACCINATEMEMBER';
    }
    this.isAuthenticated$ = this.store$.pipe(select(selectIsAuthenticated));
    this.isAuthenticated$.subscribe(r => {
      if(!r){
        this.closeDialog();
      }
    });

  }

  onStepChange(val : any) {
    if(val === 'next'){
      this.stepper.next()
    } else if(val == 'add-vaccine'){
      this.stepper.selectedIndex = 0;
    } else {
      this.stepper.previous();
    }
  }

  closeDialog() {
    this.dialogRef.close({ event: 'close', data: {}});
  }

  onTabChange(e: any) {
    let steps;
    if(this.data.session_type_name=='UIP'){
      steps = {0:"MULTIVACCUIP",1: "VACCINATEMEMBER", 2: "VACCINATIONREJECTED"};
    }else{
     steps = {0: "VACCINATEMEMBER", 1: "VACCINATIONREJECTED"};
    }
    this.currentActiveStep = steps[e.selectedIndex];
  }

}


