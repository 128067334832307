import { Component, OnInit, ViewChild, Input, OnChanges, SimpleChanges, AfterViewInit, Inject, } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Store, select } from '@ngrx/store';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { AddRecipientService } from '../../services/add-recipient.service';
import { selectIsAuthenticated } from 'src/app/modules/core/core.module';
import { Location } from '@angular/common';
import { UtilsService } from 'src/app/modules/shared/services/utils.service';




@Component({
  selector: 'app-recipient-verify-vaccinate-page',
  templateUrl: './verify-vaccinate.page.html',
  styleUrls: ['./verify-vaccinate.page.scss'],
})
export class VerifyVaccinatePageComponent implements OnInit {
  currentActiveStep = 'IDENTIFICATION';
  isAuthenticated$: Observable<boolean>;
  @ViewChild('stepper') stepper: MatStepper;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public addRecipientService: AddRecipientService,
    private loc: Location,
    public util: UtilsService,
  ) {}

  ngOnInit(): void {
    // this.addRecipientService.beneficiaryDetails = this.loc.getState();
  }

  closeDialog() {
    this.dialogRef.close({ event: 'close', data: {}});
  }

  onTabChange(e: any) {
    const steps = {0: 'IDENTIFICATION', 1: "PERSONALDETAILS",
    2: "VERIFIEDSUCCESS", 3: "VACCINATEMEMBER", 4: "VACCINATIONREJECTED"};
    this.currentActiveStep = steps[e.selectedIndex];
  }

}


