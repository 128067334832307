import { Optional, Inject, Output, EventEmitter, Input } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { UtilsService } from './../../../../modules/shared/services/utils.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { PairDeviceDialog } from '../../../remote-interface/components/pair-device-dialog/pair-device-dialog';
import { LookUpService } from 'src/app/modules/shared/services/look-up.service';
import { AadharService } from '../../services/aadhar.service';
import { RemoteInterfaceService } from 'src/app/screens/remote-interface/service/remote-address.service';
import { AddRecipientService } from 'src/app/screens/add-recipient/services/add-recipient.service';
import { Platform } from '@ionic/angular';
@Component({
  selector: 'app-aadhar-verification-type',
  styleUrls: ['./verification-type.scss'],
  templateUrl: './verification-type.html',
})
export class VerificationTypeComponent {
  @Output() onSelectVerificationType: EventEmitter<any> = new EventEmitter();
  aadhareKYC: FormGroup;
  beneficiaryData: any = {};
  AlreadyRegistered: boolean = false;
  AadharNumber:any;

  constructor(
    public util: UtilsService,
    public lookupService: LookUpService,
    public aadharService: AadharService,
    public remoteInterfaceService: RemoteInterfaceService,
    public addRecipientService: AddRecipientService,
    public plt: Platform, 
  ) {

    this.aadhareKYC = new FormGroup({
      photo_id_type: new FormControl("Aadhaar Card"),
      photo_id_number: new FormControl(null, [Validators.required]),
      eKYC_by: new FormControl(null, [Validators.required]),
      benNumber: new FormControl(null, [Validators.required])
    });
  }
  ngOnInit() {
    this.addRecipientService.beneficiaryData.subscribe(benf => {
      this.beneficiaryData = benf;
      if (this.beneficiaryData?.ben_type_id && this.beneficiaryData?.photo_id_type == 'Aadhaar Card' && this.beneficiaryData.hasOwnProperty('beneficiary_reference_id')) {
        this.AlreadyRegistered = true;
        this.AadharNumber = this.beneficiaryData?.photo_id_number;
        this.aadhareKYC.get('photo_id_number').setValue(this.beneficiaryData?.photo_id_number)
      }
      if (this.beneficiaryData?.guardian_photo_id_number && this.beneficiaryData?.guardian_photo_id_type== 'Aadhaar Card') {
        this.AlreadyRegistered = true;
        this.AadharNumber = this.beneficiaryData?.guardian_photo_id_number;
        this.aadhareKYC.get('photo_id_number').setValue(this.beneficiaryData?.guardian_photo_id_number)
      }

    });

    // this.lookupService.getGender().subscribe(res => this.genderList = res);
    // this.lookupService.getYob().subscribe(res => this.yobList = res);
    // this.lookupService.getDob().subscribe(res => this.dobList = res);
    // this.lookupService.getMob().subscribe(res => this.mobList = res);
    this.addRecipientService.sendFalse.subscribe(r => {
      if (r == false) {
        this.AlreadyRegistered = false;
      }
    })
  }

  onSelecteKYCType() {

    // this.addRecipientService.beneficiaryDetails = { aadhaarNumber: this.aadhareKYC.get('photo_id_number').value};
    /*
    let beneficiaryData = {
      ...this.aadhareKYC.value,
      ...this.beneficiaryData,
      aadhaarNumber: this.aadhareKYC.get('photo_id_number').value
    };
    this.addRecipientService.beneficiaryDetails = beneficiaryData;
    */
  }

  restrictUserToTypeMoreThanLength(event, length, variable?) {
    // console.log('inputValue', event);
    const inputValue = event.srcElement.value;
    let year = inputValue.substring(0, 4);
    if (inputValue.length > length) {
      event.srcElement.value = inputValue.substring(0, length);
      event.stopPropagation();
      return false;
    }
  }

  onSubmit() {
    const verificationType = this.aadhareKYC.get('eKYC_by').value;
    let beneficiaryData = {
      ...this.aadhareKYC.value,
      ...this.beneficiaryData,
      aadhaarNumber: this.aadhareKYC.get('photo_id_number').value
    };
    this.addRecipientService.beneficiaryDetails = beneficiaryData;
    if (this.aadhareKYC.get('eKYC_by').value === 'Facial') {
      if (!this.aadharService.enableFaceRdService()) {
        this.aadharService.openPairDevicePopup();
      }
      this.remoteInterfaceService.deviceInterfaceObservable$.subscribe(r => {
        if (this.aadharService.enableFaceRdService()) {
          this.onSelectVerificationType.emit(verificationType);
        }
      });
    } else if (this.aadhareKYC.get('eKYC_by').value === 'Biometric') {
      if (!sessionStorage.getItem('biometricdevice')) {
        this.util.presentToastError('Please Configure Biometric Device in Configurations');
        return;
      }
      this.onSelectVerificationType.emit(verificationType);
    } else {
      this.onSelectVerificationType.emit(verificationType);
    }
  }
}
