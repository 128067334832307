<ng-container *ngIf="!scannedData">
    <form autocomplete="off" [formGroup]='otpForm' (ngSubmit)='verifyOTP()'>
        <ion-grid class="inputFields sidebar-inner-content">
            <h3 class="pageSubTitle ion-text-uppercase">{{'Registered Number Authentication' | translate}}</h3>
            <ion-row>
                <ion-col size="12">
                    <ion-label class="required">
                        {{'Aadhaar UID Number' | translate}}
                    </ion-label>
                    <h2>{{beneficiaryData?.aadhaarNumber | benfNumberFormat}}</h2>
                    <ion-label class="ion-margin-top">
                        {{'6 Digit OTP' | translate}}
                    </ion-label>
                    <ion-item class="item-border ion-no-padding" lines="none">
                        <ion-input autocomplete="off" formControlName='otp' type='tel' maxlength="6" appIntegerInput [appRange]='[000001,999999]' [appMin]='111111' [appMax]='999999' placeholder="- - - - - -" errorText=" " ></ion-input>
                        <ion-button (click)="generateAadhaarOTP()" [disabled]="timer" class="linkBtn" expand="full" fill="outline">
                            {{'Resend' | translate}}
                        </ion-button>
                    </ion-item>
                    <ion-label *ngIf="timer">{{timer + ' sec '}}</ion-label>
                    <ion-label *ngIf="formSubmitted && !otpForm.controls.otp.valid  && (otpForm.controls.otp.hasError('required'))" class="error-validation ">
                        <p>{{'Please Enter OTP' | translate}}</p>
                    </ion-label>
                    <ion-label *ngIf="!otpForm.controls.otp.valid  && (otpForm.controls.otp.hasError('range'))" class="error-validation ">
                        <p>{{'Must be 6 digits' | translate}}</p>
                    </ion-label>

                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col size-md="12">
                    <span class="checkboxSpan" [ngClass]="{'checkbox-required': otpForm.get('consent').touched && otpForm.get('consent')?.errors?.required}">
                  <mat-checkbox color="primary"
                  [ngClass]="{'checkbox-required': otpForm.get('consent').touched && otpForm.get('consent')?.errors?.required}"
                  formControlName="consent"></mat-checkbox><p> {{'I hereby confirm that I have read out the information provided by the beneficiary...' | translate}} <a (click)="openBottomSheet()">{{'Read More' | translate}}</a></p>
                  </span>
                    <span class="checkboxSpan" *ngIf="beneficiaryData!=null && beneficiaryData!=undefined && beneficiaryData.ben_type_id == environment.static_ids.PregnantWomen || beneficiaryData.ben_type_id == environment.static_ids.LactatingMother">
          <mat-checkbox color="primary"
          [ngClass]="{'checkbox-required': otpForm.get('female_consent').touched && otpForm.get('female_consent')?.errors?.required}"

            formControlName="female_consent" required></mat-checkbox>
            <p *ngIf="beneficiaryData?.session_type_name == 'UIP'">{{'The beneficiary has been informed about the risk and benefits associated with the ROUTINE vaccination' | translate}}</p>
            <p *ngIf="beneficiaryData?.session_type_name == 'COVID-19'">{{'The beneficiary has been informed about the risk of exposure to COVID-19  infection along with the risk and benefits associated with the COVID-19 vaccination.' | translate}}</p>
           <p *ngIf="!beneficiaryData?.hasOwnProperty('session_type_name')">{{'The beneficiary has been informed about the risk and benefits associated with the vaccination' | translate}}</p>
                  </span>
                    <div class="ion-text-end">
                        <ion-button class="primaryBtn" [disabled]="verificationInprogress" shape="round" type="submit">
                            <ion-spinner *ngIf="verificationInprogress" name="lines-small"></ion-spinner>
                            {{'Verify Member' | translate}} </ion-button>
                    </div>
                </ion-col>
            </ion-row>
        </ion-grid>
        <!-- <div class="sidebar-footer">
            <ion-row>
                <ion-col size-md="12">
                    <span class="checkboxSpan" [ngClass]="{'checkbox-required': otpForm.get('consent').touched && otpForm.get('consent')?.errors?.required}">
                    <mat-checkbox
                    [ngClass]="{'checkbox-required': otpForm.get('consent').touched && otpForm.get('consent')?.errors?.required}"
                    formControlName="consent"></mat-checkbox><p> I hereby confirm that I have read out the information provided by the beneficiary... <a (click)="openBottomSheet()">{{'Read More' | translate}}</a></p>
                    </span>
                    <span class="checkboxSpan" *ngIf="beneficiaryData!=null && beneficiaryData!=undefined && beneficiaryData.ben_type_id == 19 || beneficiaryData.ben_type_id == 20">
            <mat-checkbox
            [ngClass]="{'checkbox-required': otpForm.get('female_consent').touched && otpForm.get('female_consent')?.errors?.required}"

              formControlName="female_consent" required></mat-checkbox><p>The beneficiary has been informed about the risk of exposure to
              COVID-19 infection along with the risk and benefits associated with the COVID-19 vaccination.</p>
                    </span>
                    <ion-button class="primaryBtn" expand="full" [disabled]="verificationInprogress" shape="round" type="submit">
                        <ion-spinner *ngIf="verificationInprogress" name="lines-small"></ion-spinner>
                        Verify Member </ion-button>
                </ion-col>
            </ion-row>
        </div> -->
    </form>
</ng-container>
<ng-container *ngIf="scannedData">
    <form autocomplete="off" [formGroup]='personalDetForm' (ngSubmit)='continue()'>
        <ion-grid class="inputFields">
            <h3 class="pageSubTitle ion-text-uppercase">{{'Registered Number Authentication' | translate}}</h3>
            <ion-row>
                <ion-col size="12">
                    <ion-label>
                        {{'Aadhaar UID Number' | translate}}*
                    </ion-label>
                    <h2>9876 - 2345 - 6543</h2>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col size="12">
                    <div>
                        <h3 class="ion-text-uppercase pageSubTitle">{{'Otp Authentication' | translate}}</h3>
                        <app-common-recipient-fields [parentForm]="personalDetForm"></app-common-recipient-fields>
                    </div>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col size-md="12" class="ion-text-end">
                    <mat-checkbox color="primary">{{'I hereby confirm that I have read out the information provided by the beneficiary' | translate}}... Read More
                    </mat-checkbox>
                    <ion-button class="primaryBtn" [disabled]="otpGenerateInProgress" shape="round" type="submit">
                        <ion-spinner *ngIf="otpGenerateInProgress" name="lines-small"></ion-spinner>
                        {{'Continue' | translate}}</ion-button>
                </ion-col>
            </ion-row>
        </ion-grid>
        <!-- <div class="sidebar-footer">
            <ion-row>
                <ion-col size-md="12">
                    <mat-checkbox>I hereby confirm that I have read out the information provided by the beneficiary... Read More
                    </mat-checkbox>
                    <ion-button class="primaryBtn" expand="full" [disabled]="otpGenerateInProgress" shape="round" type="submit">
                      <ion-spinner *ngIf="otpGenerateInProgress" name="lines-small"></ion-spinner>
                      Continue</ion-button>
                </ion-col>
            </ion-row>
        </div> -->
    </form>
</ng-container>
