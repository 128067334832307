import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute,Router, RouterState } from '@angular/router';
import { Observable } from 'rxjs';
import { environment as env } from '../../../environments/environment';
import { environment } from './../../../environments/environment';
import { AddRecipientService } from './services/add-recipient.service';
import { Store,select } from '@ngrx/store';
import { getSessionDetails } from 'src/app/modules/core/store/selectors/session.selectors';

@Component({
  selector: 'app-add-recipient-page',
  templateUrl: 'add-recipient.page.html',
  styleUrls: ['add-recipient.page.scss'],
})
export class AddRecipinentPage implements OnInit {
  @ViewChild('stepper') stepper: MatStepper;
  currentActiveStep = 'CATEGORYINFO';

  constructor(public router: Router, public addRecipientService: AddRecipientService,public activatedRoute:ActivatedRoute,
    private store$: Store) {
    // if session id in url
    // this.addRecipientService.beneficiaryDetails = {session_id: routeSession};
    // const state: RouterState = router.routerState;
    // let session_id = state.snapshot.url.split('/')[2];
    // console.log(session_id, "sessionID")
    // if (session_id) {
    //   this.addRecipientService.beneficiaryDetails = { session_id: session_id };
    // } else {
    //   this.addRecipientService.beneficiaryDetails = {}
    // }
  }
  ngOnInit() {
    // const session_id = this.activatedRoute.snapshot.params.sessionID
    
    // if(session_id)
    // this.store$.pipe(select(getSessionDetails)).subscribe((r: any)=>{
    //   if(r && session_id && r.session_id == session_id){
    //     if(r.session_status != 1){
    //       this.addRecipientService.beneficiaryDetails = { session_id: null };
    //     }else{
    //       this.addRecipientService.beneficiaryDetails = {
    //         session_id: session_id,
    //         session_type_id: r.session_type_id,
    //         session_type_name: r.session_type_name,
    //         min_age:r.min_age,
    //         max_age:r.max_age
    //       };
    //     }
    //   }
    // });
    // else{
    //   this.addRecipientService.beneficiaryDetails = {}
    // }
  }

  onStepChange(val: any) {
    this.router.navigate(['/member-identification']);
    // if (val === 'next') {
    //   this.stepper.next()
    // } else {
    //   this.stepper.previous();
    // }
  }

  closeDialog() {
  }

  onTabChange(e: any) {
    const steps = {0: 'CATEGORYINFO', 1: 'IDENTIFICATION', 2: "PERSONALDETAILS",
    3: "VERIFIEDSUCCESS", 4: "VACCINATIONINFO", 5: "VACCINATEMEMBER", 6: "VACCINATIONREJECTED"};
    if(e?.selectedIndex) { this.currentActiveStep = steps[e.selectedIndex]; }
  }


}
