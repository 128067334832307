import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { ActionSheetController, Platform } from '@ionic/angular';
import { UtilsService } from './utils.service';
// import { File } from '@ionic-native/file';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
// import { FileOpener } from '@ionic-native/file-opener/ngx';
import { environment } from 'src/environments/environment';
import { switchMap } from 'rxjs/operators';
import { EncDecService } from './encryption-decryption.service';
import {FileOpener} from '@capacitor-community/file-opener';
import {Capacitor} from "@capacitor/core";
import write_blob from "capacitor-blob-writer";
import { AuthService } from '../../core/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class DownloadFileService {
  constructor(
    private plt: Platform,
    private auth: AuthService,
    private util: UtilsService,
    private http: HttpClient,
    private encDecService: EncDecService,
  ) { }


  downloadSesionInfoPdf(postData, sessionInfo, cb): Observable<any>{
    return this.auth.refreshToken().pipe(switchMap((response) => {
      this.auth.setAccessToken(response);
      return this.downloadSessionDetails(postData, sessionInfo, cb);
    }))
  }


  downloadSessionDetails(postData, sessionInfo, cb) {
    if (JSON.stringify(sessionInfo) != '{}') {
      const fileName = `${sessionInfo.session_name.replace(/ /g, '-')}-${moment(sessionInfo.sessionDate).format('DDMMYYYY')}.pdf`;
      const requestOptions: Object = {
        responseType: 'blob' as 'blob'
      };
      return this.http.post<any>(`${environment.vaccinator_url}/downloadSessionDetails`, postData, requestOptions)
        .pipe(switchMap((fileData: any) => {
          return this.downloadFile(fileName, fileData);
        }));
    }
  }

  downloadVaccinationCertificate(payload): Observable<any>{
    return this.auth.refreshToken().pipe(switchMap((response) => {
      this.auth.setAccessToken(response);
      return this.regenerateCert(payload);
    }))
  }


  regenerateCert(payload): Observable<any> {
    payload['env'] = this.encDecService.set(environment.env);
    const requestOptions: Object = {
      responseType: 'blob' as 'blob'
    };
    const fileName = `certificate-${moment().format('YYYYMMDD')}.pdf`;
    return this.http.post<any>(
      `${environment.vaccinator_url}/certificate/regenerateCert`,
      payload,
      requestOptions
    ).pipe(switchMap((fileData: any) => {
      return this.downloadFile(fileName, fileData);
    }));
  }

  downloadReport(payload, reportName): Observable<any>{
    return this.auth.refreshToken().pipe(switchMap((response) => {
      this.auth.setAccessToken(response);
      return this.downloadReportByUrl(payload, reportName);
    }))
  }


  downloadReportByUrl(payload, reportName): Observable<any> {
    let fileName = `${reportName}-${moment().format('DDMMYYYY')}.xlsx`;
    let endpoint;

    if (reportName === 'Vaccinator' || reportName === 'ASHA') {
      endpoint = 'exportPreRegisteredBen';
    } else {
      endpoint = 'exportDpmBens';
    }

    const requestOptions: Object = {
      responseType: 'blob' as 'blob'
    };

    return this.http.post<any>(
      `${environment.vaccinator_url}/${endpoint}`,
      payload, requestOptions
    ).pipe(switchMap((fileData: any) => {
      return this.downloadExcelFile(fileName, fileData);
    }));
  }

  downloadExcelFile(fileName, fileData) {
    return new Observable((observer) => {
      if (!this.plt.is('desktop') && (this.plt.is('cordova') || this.plt.is('hybrid'))) {
        const writeSecretFile = async (fileData, fileName) => {
          await write_blob({
            path: /*'Download/'+*/ fileName,
            blob: new Blob([fileData]),
            directory: Directory.Data,
          });
          const {uri} = await Filesystem.getUri({
            path: /*'Download/'+*/ fileName,
            directory: Directory.Data,
          });
          FileOpener.open({
            filePath: uri,
            contentType:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            openWithDefault: false,
          });
        };
        writeSecretFile(fileData, fileName);

        observer.next();
      } else {
        const blob: any = new Blob([fileData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

        const link = document.createElement('a');
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('target', '_blank');
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        observer.next();
      }
    });

  }




  downloadUIPVaccinationCertificate(payload): Observable<any>{
    return this.auth.refreshToken().pipe(switchMap((response) => {
      this.auth.setAccessToken(response);
      return this.downloadMCPCard(payload);
    }))
  }


  downloadMCPCard(payload): Observable<any> {
    payload['env'] = this.encDecService.set(environment.env);
    const requestOptions: Object = {
      responseType: 'blob' as 'blob'
    };
    const postData = {
      "beneficiary_ref_id": this.encDecService.setWithSpace(payload.beneficiary_reference_id),
      "lmc_date": payload.lmcDate ? payload.lmcDate : undefined
    }
    const fileName = `${payload.beneficiary_name}-${moment().format('YYYYMMDD')}-ReportCard.pdf`;
    return this.http.post<any>(
      // `${environment.vaccinator_url}/vaccinationsChart`, postData, requestOptions
      `${environment.vaccinator_url}/getMcpCard`, postData, requestOptions
    ).pipe(switchMap((fileData: any) => {
      return this.downloadFile(fileName, fileData);
    }));
  }

  exportVaccinatedCertificate(payload): Observable<any>{
    return this.auth.refreshToken().pipe(switchMap((response) => {
      this.auth.setAccessToken(response);
      return this.exportVaccinatedBens(payload);
    }))
  }


  exportVaccinatedBens(payload): Observable<any> {
    payload.facility_id = this.encDecService.setWithSpace(payload.facility_id)
    if(payload.asha_village_id){
      payload.asha_village_id = this.encDecService.isAlreadyDataEncrypted(payload.asha_village_id)
    }
    if(payload.village_id && payload.village_id.length > 0){
      payload.village_id = payload.village_id.map(e => {
        if(e.village_id.slice(-1) != "=")
        e.village_id = this.encDecService.setWithSpace(e.village_id)
        return e;
      })
    }
    // payload['env'] = this.encDecService.set(environment.env);
    const requestOptions: Object = {
      responseType: 'blob' as 'blob'
    };
    const fileName = `VaccinatedBeneficieryList -${moment().format('YYYYMMDD')}.xlsx`;
    return this.http.post<any>(
      // `${environment.vaccinator_url}/vaccinationsChart`, postData, requestOptions
      `${environment.vaccinator_url}/exportVaccinatedBens`, payload, requestOptions
    ).pipe(switchMap((fileData: any) => {
      return this.downloadFile(fileName, fileData);
    }));
  }

  exportDueListBenfs(payload): Observable<any>{
    return this.auth.refreshToken().pipe(switchMap((response) => {
      this.auth.setAccessToken(response);
      return this.exportVaccinationDueList(payload);
    }))
  }


  exportVaccinationDueList(payload): Observable<any> {
    // payload['env'] = this.encDecService.set(environment.env);
    const requestOptions: Object = {
      responseType: 'blob' as 'blob'
    };
    payload.facility_id = this.encDecService.setWithSpace(payload.facility_id)
    if(payload.asha_village_id){
      payload.asha_village_id = this.encDecService.isAlreadyDataEncrypted(payload.asha_village_id)
    }
    if(payload.village_id && payload.village_id.length > 0){
      payload.village_id = payload.village_id.map(e => {
        if(e.village_id.slice(-1) != "=")
        e.village_id = this.encDecService.setWithSpace(e.village_id)
        return e;
      })
    }
    const fileName = `BeneficiaryDueList -${moment(payload.from).format('YYYYMMDD')}-${moment(payload.to).format('YYYYMMDD')}.xlsx`;
    return this.http.post<any>(
      // `${environment.vaccinator_url}/vaccinationsChart`, postData, requestOptions
      `${environment.vaccinator_url}/exportVaccinationDueList`, payload, requestOptions
    ).pipe(switchMap((fileData: any) => {
      return this.downloadFile(fileName, fileData);
    }));
  }

  downloadPregnancyReportDetails(payload, referenceID): Observable<any>{
    return this.auth.refreshToken().pipe(switchMap((response) => {
      this.auth.setAccessToken(response);
      return this.downloadPregnencyDetailsPDF(payload, referenceID);
    }))
  }


  downloadPregnencyDetailsPDF(payload, referenceID): Observable<any> {
    payload['env'] = this.encDecService.set(environment.env);
    const requestOptions: Object = {
      responseType: 'blob' as 'blob'
    };
    payload['benId'] = this.encDecService.setWithSpace(payload['benId'])
    const fileName = `PregnancyReport-${referenceID}-${moment().format('YYYYMMDD')}-${payload.pregnancy_number}.pdf`;
    return this.http.post<any>(
      `${environment.vaccinator_url}/downloadPregnencyDetailsPDF`,
      payload,
      requestOptions
    ).pipe(switchMap((fileData: any) => {
      return this.downloadFile(fileName, fileData);
    }));
  }

  downloadFile(fileName, fileData) {
    return new Observable((observer) => {
      if (!this.plt.is('desktop') && (this.plt.is('cordova') || this.plt.is('hybrid'))) {
        const writeSecretFile = async (fileData, fileName) => {
          await write_blob({
            path: /*'Download/'+*/ fileName,
            blob: new Blob([fileData]),
            directory: Directory.Data,
          });
          const {uri} = await Filesystem.getUri({
            path: /*'Download/'+*/ fileName,
            directory: Directory.Data,
          });
          FileOpener.open({
            filePath: uri,
            // contentType: 'text/plain',
            contentType:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            openWithDefault: false,
          });
        };
        writeSecretFile(fileData, fileName);
        observer.next();
      } else {
        const blob: any = new Blob([fileData], { type: 'application/pdf' });

        const link = document.createElement('a');
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('target', '_blank');
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        observer.next();
      }
    });
  }

  sessionSummaryDownload(payload): Observable<any>{
    return this.auth.refreshToken().pipe(switchMap((response) => {
      this.auth.setAccessToken(response);
      return this.downloadSessionSummary(payload);
    }))
  }


  downloadSessionSummary(payload): Observable<any> {
    const requestOptions: Object = {
      responseType: 'blob' as 'blob'
    };
    const fileName = `DownloadSessionSummary -${moment(payload.from).format('YYYYMMDD')}-${moment(payload.to).format('YYYYMMDD')}.xlsx`;
    return this.http.post<any>(
      `${environment.vaccinator_url}/downloadSessionSummary`, payload, requestOptions
    ).pipe(switchMap((fileData: any) => {
      return this.downloadFile(fileName, fileData);
    }));
  }
}
