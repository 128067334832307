<div [formGroup]='parentForm'>
    <!-- <p *ngIf="utils.getIsMobileDevice()" class="customSelect">
    <ion-label position="floating">{{label}}</ion-label>
    <ion-select formControlName='{{formKey}}' [disabled]="isDisabled" (ionChange)="onChange()"
    [selectedText]="parentForm.get(formKey).value"
              
    placeholder="Select from dropdown">
      <ion-select-option *ngFor="let arr of arrObj" [value]="arr[arrKey]">
        {{ arr[arrKeyVal] }}
      </ion-select-option>
    </ion-select>
  </p> -->
  <div class="inputFields">
    <ion-label [ngClass]="{'required': isRequired}">{{ label | translate }}</ion-label>
    <mat-form-field appearance="outline" class="matDropdown ion-margin-vertical">
      <mat-select disableOptionCentering formControlName='{{formKey}}'
        (selectionChange)='onChange()' [placeholder]="placeholder | translate">
        <mat-option *ngFor="let arr of arrObj" [value]="arr[arrKey]">
          {{ arr[arrKeyVal] | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
