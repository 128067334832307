<ng-container *ngIf="util.getPlatformWidth() <= environment.static_ids.TabResoltion">
  <ion-header class="noDesktop mobile-header-white">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button (click)="gotoStep('prev')" text="" class="ion-margin-end"
          icon="arrow-back-outline"></ion-back-button>
      </ion-buttons>
      <h4 class="heading">{{ (!verifiedData?.isPastDated ? 'Member Vaccination' : 'Member Previous Vaccination' ) | translate}}</h4>
    </ion-toolbar>
  </ion-header>

  <ng-container *ngTemplateOutlet="tpl"></ng-container>

</ng-container>
<ng-container *ngIf="util.getPlatformWidth() >= environment.static_ids.TabResoltion">
  <ng-container *ngTemplateOutlet="tpl"></ng-container>
</ng-container>

<ng-template #tpl>
  <div class="sidebar-header noMobile">
    <h1>
      <ion-icon *ngIf="!noBack" (click)="gotoStep('prev')" class="custom-arrow-left"></ion-icon>
      {{ (!verifiedData?.isPastDated ? 'Member Vaccination' : 'Member Previous Vaccination' ) | translate}}
      <ion-icon (click)="closeDialog()" class="custom-close"></ion-icon>
    </h1>
  </div>

  <div class="sidebar-content">
    <ion-grid class="sidebar-inner-content existingMmeberPanel">
      <!-- <h2 class="ion-padding-horizontal">{{verifiedData?.ben_name}}</h2> -->
      <ion-card class="ion-no-padding memberSuccessCard">
        <ion-card-content class="ion-no-padding">
          <ion-accordion-group>
            <ion-accordion value="verifiedDetails">
              <ion-item slot="header" color="light" class="ion-margin-bottom">
                <h3 class="pageSubTitle">{{'MEMBER DETAILS' | translate}}
                  <p>
                    <ion-text>{{verifiedData?.ben_name}}</ion-text>
                    <ion-text>{{verifiedData?.dob | age}} / {{gender | translate}}</ion-text>

                  </p>
                </h3>
              </ion-item>
              <ion-list slot="content">
                <ion-grid class="ion-no-padding">
                  <ion-row>
                    <ion-col size="6">
                      <ion-text>
                        <ion-label>{{'Mobile Number' | translate}}</ion-label>
                        <h5>{{verifiedData?.ben_mobile_number}}</h5>
                      </ion-text>
                    </ion-col>
                    <ion-col size="6" *ngIf="verifiedData?.guardian_name" class="ion-text-right">
                      <ion-text>
                        <ion-label>{{'Mother / Guardian' | translate}}</ion-label>
                        <h5>{{verifiedData?.guardian_name}}</h5>
                      </ion-text>
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col size="6" *ngIf="verifiedData?.father_name">
                      <ion-text>
                        <ion-label>{{'Father Name' | translate}}</ion-label>
                        <h5>{{verifiedData?.father_name}}</h5>
                      </ion-text>
                    </ion-col>
                    <ion-col [ngClass]="{'ion-text-right': verifiedData?.father_name, 'ion-text-left': !verifiedData?.father_name}" size="6" *ngIf="verifiedData?.ben_type_id === 7007">
                      <ion-text>
                        <ion-label>{{'Reference ID' | translate}}</ion-label>
                        <h5>{{verifiedData?.beneficiary_reference_id}}</h5>
                      </ion-text>
                    </ion-col>
                  </ion-row>
                </ion-grid>

              </ion-list>
            </ion-accordion>
          </ion-accordion-group>
        </ion-card-content>
      </ion-card>


      <ion-card class="ion-no-padding memberSuccessCard">
        <ion-card-content class="ion-no-padding">
          <ion-accordion-group value="vaccinationDetails">
            <ion-accordion value="vaccinationDetails">
              <ion-item slot="header" color="light" class="ion-margin-bottom">
                <h3  class="pageSubTitle">
                  {{(!verifiedData?.isPastDated ? 'Review VACCINATION DETAILS' : 'Review Previous Vaccination Details') | translate}}
                </h3>
              </ion-item>
              <ion-list slot="content" *ngIf="verifiedData?.session_type_name != 'UIP'">
                <ion-grid class="ion-no-padding">
                  <ion-row>
                    <ion-col size="12">
                      <ion-text>
                        <ion-label>{{'Vaccine Name' | translate}}</ion-label>
                        <h5>{{verifiedData?.material_name}}</h5>
                      </ion-text>
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col size="6">
                      <ion-text>
                        <ion-label>{{'Vaccine Dose' | translate}}</ion-label>
                        <h5>{{(verifiedData?.vaccination_doses_due == verifiedData?.dose_scheduled + 1 ) ?
                          'PrecautionDose': 'Dose' + verifiedData?.vaccination_doses_due}}</h5>
                      </ion-text>
                    </ion-col>
                    <ion-col size="6">
                      <ion-text class="ion-text-right">
                        <ion-label>{{'Batch Number' | translate}}</ion-label>
                        <h5>{{verifiedData?.batch_number}}</h5>
                      </ion-text>
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col size="6">
                      <ion-text>
                        <ion-label>{{'Date of Vaccination' | translate}}</ion-label>
                        <h5>{{currentTime | displayDateFormat}}</h5>
                      </ion-text>
                    </ion-col>
                    <ion-col size="6">
                      <ion-text class="ion-text-right">
                        <ion-label>{{'Time' | translate}}</ion-label>
                        <h5>{{currentTime | displayDateFormat}}</h5>
                      </ion-text>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-list>
              <ion-list slot="content" *ngIf="verifiedData?.session_type_name == 'UIP'">
                <ion-grid class="ion-no-padding" *ngFor="let material of verifiedData?.materialDetails | sortBy :'asc': 'material_id'">
                  <ion-row>
                    <ion-col size="12">
                      <ion-text>
                        <ion-label> {{'Vaccine Name' | translate}}</ion-label>
                        <h5>{{material.material_name | translate}}</h5>
                      </ion-text>
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col size="6">
                      <ion-text>
                        <ion-label>{{'Dose' | translate}}</ion-label>
                        <h5>{{labelName(material)}}</h5>
                      </ion-text>
                    </ion-col>
                    <ion-col size="6" *ngIf="material?.prev_vaccination_date">
                      <ion-text class="ion-text-right">
                        <ion-label>{{'Vaccine Received' | translate}}</ion-label>
                        <h5>{{material.prev_vaccination_date | displayDateFormat}}</h5>
                      </ion-text>
                    </ion-col>
                    <ion-col size="6" *ngIf="!material?.prev_vaccination_date">
                      <ion-text class="ion-text-right">
                        <ion-label>{{'Date of vaccination' | translate}}</ion-label>
                        <h5 *ngIf="verifiedData.isPastDated">N/A</h5>
                        <h5 *ngIf="!verifiedData.isPastDated">{{currentTime | displayDateFormat}}</h5>
                      </ion-text>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-list>
            </ion-accordion>
          </ion-accordion-group>
        </ion-card-content>
      </ion-card>

      <div *ngIf="userRole !== 'Verifier'">
        <ion-row>
          <ion-col size-md="12">
            <ion-button class="primaryBtn" [disabled]="formSubmitted" expand="full" shape="round" type="submit" (click)="nextStep()">
              <ion-spinner *ngIf="formSubmitted"  name="lines-small"></ion-spinner>
              {{(verifiedData?.isPastDated ? 'Submit' : 'Vaccinate Member') | translate}}
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="!verifiedData?.isPastDated">
          <ion-col size-md="12">
            <ion-button class="cancelBtn" expand="block" fill="outline" (click)="rejectMember()">{{'Reject Member' | translate}}
            </ion-button>
          </ion-col>
        </ion-row>
      </div>
    </ion-grid>
  </div>
</ng-template>