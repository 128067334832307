import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter, Output } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { EncDecService } from 'src/app/modules/shared/services/encryption-decryption.service';
import { DataService } from 'src/app/modules/shared/services';
import { Device } from '@capacitor/device';
import { AddRecipientService } from '../../add-recipient/services/add-recipient.service';
import { LookUpService } from 'src/app/modules/shared/services/look-up.service';
import { ReplaySubject } from 'rxjs'
import { UtilsService } from 'src/app/modules/shared/services/utils.service';
import { LocalStorageService } from 'src/app/modules/core/services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class RecipientService {
  secret_key = environment.userKey;
  private beneficiaryDataSubject: BehaviorSubject<any>;
  private benSuccessDataSubject: BehaviorSubject<any>
  public beneficiaryData: Observable<any>;
  mySubject = new ReplaySubject(2);

  coordinates: any;
  beneficieryVerifiedData;
  genderList: any = [{ "gender": "Male", "id": 1 }, { "gender": "Female", "id": 2 }, { "gender": "Others", "id": 3 }];
  yobList;
  mobList;
  dilougeClosed = new EventEmitter<any>();
  categoryName = new EventEmitter<any>()

  @Output() vaccinationUrlData = new EventEmitter<string>();

  constructor(
    private http: HttpClient,
    private dataService: DataService,
    private encDecService: EncDecService,
    private util: UtilsService,
    private addRecipientService: AddRecipientService, public lookupService: LookUpService,
    public localStorage:LocalStorageService
  ) {
    this.beneficiaryDataSubject = new BehaviorSubject<any>(null);
    this.beneficiaryData = this.beneficiaryDataSubject.asObservable();

    this.addRecipientService.benSuccessData.subscribe(res => {
      this.beneficieryVerifiedData = res;
    })
    this.lookupService.getGender().subscribe(res => {
      this.genderList = res
    })
    this.lookupService.getYob().subscribe(res => {
      this.yobList = res
    })
    this.lookupService.getMob().subscribe(res => {
      this.mobList = res;
    })
  }


  getBeneficairyDetails(beneficiaryID): Observable<any> {
    if (window.navigator.onLine) {
      const payload = {
        beneficiaryId : this.encDecService.setWithSpace(beneficiaryID)
      }
      return this.http.post(`${environment.vaccinator_url}/getbeneficiary_details`, payload)
        .pipe(map((r: any) => {
          r = this.encDecService.decryptPhotoId(r);
          r = this.encDecService.decryptMobileNo(r);
          r.linkedmembers.map(l=> {this.encDecService.decryptPhotoId(l);this.encDecService.decryptMobileNo(l)});
          this.beneficiaryDataSubject.next(r);
          return r;
        }));
    } else {
      return of(null);
    }
  }

  getVaccinationDetailsOfBeneficiary(beneficiaryID, materialCatType): Observable<any> {
    if (window.navigator.onLine) {
      if (materialCatType == 'Universal Immunization Programme') {
        return this.http.get(`${environment.vaccinator_url}/getuipvaccinations_details/${beneficiaryID}`);
      }
      else if (materialCatType != 'Others') {
        return this.http.get(`${environment.vaccinator_url}/getvaccinations_details/${beneficiaryID}`);
      } else {
        return this.http.get(`${environment.vaccinator_url}/othervaccinations_details/${beneficiaryID}`);
      }
    }
  }

  getAEFIHistory(beneficiaryID) {
    if (window.navigator.onLine) {
      return this.http.get(`${environment.vaccinator_url}/getAEFIHistory/${beneficiaryID}`)
        .pipe(map(r => {
          return r;
        }));
    } else {
      return of(null);
    }
  }
  sendOtpToBeneficiary(payload: any) {
    payload.secret = this.secret_key;
    return this.http.post<any>(`${environment.auth_prefix_url_v2}/generateMobileOTPv2`, payload);
  }

  verifyOTP(postData) {
    const payload = {
      otp: this.encDecService.setWithSpace(postData.otp),
      txnId: postData.txnId
    };
    return this.http.post(`${environment.auth_prefix_url_v2}/validateMobileOtpv2`, payload);
  }
  getGenderName(genderId): any {
    let gendObj = this.genderList?.find(ele => genderId == ele.id);
    if (gendObj) {
      return gendObj.gender;
    }
    return null;
  }
  getMonthName(monthId) {
    let monthObj = this.mobList?.find(ele => monthId == ele.id)
    if (monthObj) {
      return monthObj.month;
    }
    return null;
  }
  getYobId(Year): any {
    let yearObj = this.yobList?.find(ele => Year == ele.year)
    if (yearObj) {
      return yearObj.id;
    }
    return null;
  }
  EditAndUpdateBenDetails(payload) {
    console.log("ben details",payload)
    const formData = new FormData();
    if(payload.is_pvtg){ 
      formData.append('is_pvtg', payload.is_pvtg);
    }
    if(payload.transaction_id){ 
      formData.append('transaction_id', payload.transaction_id);
    }
    formData.append('beneficiary_id', this.encDecService.setWithSpace(payload.beneficiary_id));
    formData.append('ben_name', payload.ben_name);
    formData.append('beneficiary_gender', payload.beneficiary_gender);
    formData.append('ben_mobile_number', this.encDecService.setWithSpace(payload.ben_mobile_number));
    if (!payload.dob && payload.year) {
      formData.append('year', moment.isMoment(payload.year) ? moment(payload.year).format("YYYY") : payload.year);
    } else if (payload.dob) {
      formData.append('dob', moment(payload.dob).format("YYYY-MM-DD"));
      formData.append('year', moment(payload.dob).format("YYYY"));
    }
    formData.append('photo_id_type', this.encDecService.set(payload.photo_id_type));
    formData.append('photo_id_number', this.encDecService.set(payload.photo_id_number));
    formData.append('is_update', payload.is_update);
    if (payload.blood_group) {
      formData.append('blood_group', payload.blood_group);
    }
    if (payload.father_name) {
      formData.append('father_name', payload.father_name);
    }
    if (payload.child_weight && payload.child_weight !='' && payload.child_weight !='NULL') {
      formData.append('child_weight', payload.child_weight);
    }
    if (payload.lmc_date) {
      formData.append('lmc_date', payload.lmc_date);
    }
    if (payload.previous_pregnancy_year) {
      formData.append('previous_pregnancy_year', payload.previous_pregnancy_year);
    }
    if (payload.td_doses_received != null) {
      formData.append('td_doses_received', payload.td_doses_received);
    }
    if(payload?.pregnancy_number){
      formData.append('pregnancy_number', payload?.pregnancy_number);
    }


    return this.http.post<any>(
      `${environment.beneficiary_vaccinator_v3_url}/editBeneficiaryDetails`,
      formData)
  }
  updateBenDetails(payload) {
    const formData = new FormData();
    formData.append('is_pvtg', payload.is_pvtg);
    formData.append('beneficiary_id', this.encDecService.setWithSpace(payload.beneficiary_id));
    formData.append('state_id', this.encDecService.setWithSpace(payload.state_id));
    formData.append('ben_name', this.encDecService.setWithSpace(payload.ben_name));
    formData.append('session_id', this.encDecService.setWithSpace(payload.session_id));
    formData.append('beneficiary_gender', this.getGenderName(payload.ben_gender));
    formData.append('ben_mobile_number', this.encDecService.setWithSpace(payload.ben_mobile_number));
    formData.append('photo_id_type', this.encDecService.set(payload.photo_id_type));
    formData.append('photo_id_number', this.encDecService.set(payload.photo_id_number));
    // formData.append('dob', moment(payload.year).format("YYYY/MM/DD"));
    // moment(payload.year).format('YYYY-MM-DD')
    formData.append('father_name', payload?.father_name);
    if(payload?.child_weight && payload?.child_weight !='' && payload?.child_weight !='NULL'){ 
      formData.append('child_weight', payload?.child_weight);
    }
    if (!payload.dob) {
      formData.append('dob', moment(payload.year).format(`${payload.year}-01-01`));
    } else {
      const dobDetails = moment(payload.dob).format('YYYY-MM-DD');
      formData.append('dob', dobDetails);
    }
    // formData.append('dob', payload.year);
    if (payload?.fileInManualverification != null && payload?.fileInManualverification != undefined) {
      formData.append('file', payload?.fileInManualverification?.fileObject, `photo-${new Date().getTime()}.${payload.fileInManualverification.fileObject.type}`);
    }
    return this.http.post<any>(
      `${environment.beneficiary_vaccinator_v3_url}/updateBeneficiaryBasicDetails`,
      formData)
  }
  updateBeneficiaryWithBenefitHealthId(payload): Observable<any> {
    var dateMonthYear
    if (payload?.aadharData?.dayOfBirth && payload?.aadharData?.monthOfBirth && payload?.aadharData?.yearOfBirth) {
      dateMonthYear = payload.aadharData.dayOfBirth + "-" + this.getMonthName(payload.aadharData.monthOfBirth) + "-" + payload.aadharData.yearOfBirth;
    }
    else {
      dateMonthYear = "01" + "-" + "JAN" + "-" + payload.aadharData.yearOfBirth;
    }
    const postData = {
      aadhar_number: this.encDecService.setWithSpace(payload.aadhaarNumber),
      ben_name: this.encDecService.setWithSpace(payload.ben_name),
      beneficiary_gender: this.getGenderName(payload.ben_gender),
      beneficiary_gender_id: payload.ben_gender,
      beneficiary_id: this.encDecService.setWithSpace(payload.beneficiary_id),
      benefit_id: this.encDecService.setWithSpace(payload.aadharData.benefitId),
      dob: dateMonthYear,
      health_id: this.encDecService.setWithSpace(payload.aadharData.healthIdNumber),
      photo_id_type: payload.photo_id_type_number,
      photo_id_type_name: payload.photo_id_type,
      session_id: this.encDecService.setWithSpace(payload.session_id),
      state_id: this.encDecService.setWithSpace(payload.state_id),//has to come from session
      year: payload.year,
      father_name: payload?.father_name ? payload?.father_name : undefined,
      child_weight: payload?.child_weight && payload?.child_weight !='' && payload?.child_weight !='NULL' ? payload?.child_weight : undefined,
      year_id: this.getYobId(payload.year),
      is_pvtg: payload.is_pvtg,
    }
    return this.http.post<any>(
      `${environment.beneficiary_vaccinator_v3_url}/updateBeneficiaryHealthID`,
      postData)
  }
  verifyBeneficiary(payload) {
    const verifyPayload = {
      session_id: this.encDecService.setWithSpace(payload.session_id),
      state_id: this.encDecService.setWithSpace(payload.state_id),
      district_id: this.encDecService.setWithSpace(payload.district_id),
      beneficiary_id: this.encDecService.setWithSpace(payload.beneficiary_id),
      is_verified: true
    };
    return this.http.post<any>(
      `${environment.beneficiary_vaccinator_v3_url}/verifyBeneficiary`,
      verifyPayload)
  }
  updateRDmetainfo(payload): Observable<any> {
    return this.http.post<any>(
      `${environment.vaccinator_url}/updateRDmetainfo`,
      { ...payload, ...{ user_type: this.dataService.userDetails.role } }
    );
  }
  saveMetaInfoVerificationDetails(payload): Observable<any> {
    payload.beneficiary_id = this.encDecService.isAlreadyDataEncrypted(payload.beneficiary_id)
    payload.state_id = this.encDecService.isAlreadyDataEncrypted(payload.state_id)
    payload.district_id = this.encDecService.isAlreadyDataEncrypted(payload.district_id)
    payload.facility_id = this.encDecService.isAlreadyDataEncrypted(payload.facility_id)
    payload.site_id = this.encDecService.isAlreadyDataEncrypted(payload.site_id)
    payload.session_id = this.encDecService.isAlreadyDataEncrypted(payload.session_id)
    return this.http.post<any>(
      `${environment.beneficiary_vaccinator_v3_url}/verification`,
      payload
    );
  }
  getSessionInfo(sessionId) {
    const payload = {
      session_id : this.encDecService.setWithSpace(sessionId)
    }
    return this.http.post<any>(
      `${environment.vaccinator_url}/getSessionData`, payload)
  }
  updateMultiUipSessionVaccination(postData,bendob?) {
    let benDob;
    if(bendob){
      benDob = moment(bendob).format("YYYY-MM-DD")
    }
    // if(this.beneficieryVerifiedData && this.beneficieryVerifiedData.dob){
    //   console.log(this.beneficieryVerifiedData.dob,"this.beneficieryVerifiedData.dob")
    //   benDob = moment(this.beneficieryVerifiedData.dob).format("YYYY-MM-DD")
    // }
    let isValid = true;
    postData.materialDetails.forEach(ele=>{
      if((ele.prev_vaccination_date && (moment(ele.prev_vaccination_date).isBefore(benDob))) ||(ele.vaccination_date && (moment(ele.vaccination_date).isBefore(benDob)))){
        isValid = false;
       return of(this.util.presentToastError("Given vaccination dates are before DOB."))
      }
    })
    if(isValid){
    let benId;
    if(postData?.child_beneficiaryId){
     benId = postData?.child_beneficiaryId
    postData.beneficiaryId = this.encDecService.setWithSpace(benId);
    }else{
       benId = this.beneficieryVerifiedData?.beneficiary_id ? this.beneficieryVerifiedData?.beneficiary_id : postData.beneficiaryId
       postData.beneficiaryId = this.encDecService.setWithSpace(benId);
      }
    const stateId = this.beneficieryVerifiedData?.state_id
    // postData.beneficiaryId = this.encDecService.setWithSpace(benId);
    postData.stateID = stateId ? this.encDecService.setWithSpace(stateId) : undefined;
    postData.env = this.encDecService.set(environment.env);
    delete postData.child_beneficiaryId;
    if(this.dataService.userDetails.role == environment.static_ids.BothVaccinatorAndDeliveryManager ){
      postData.sub_center_id = this.localStorage.getItem('sub_center_id')
    }
    postData.session_id = postData.session_id ? this.encDecService.setWithSpace(postData.session_id) : undefined,
    postData.session_site_id = postData.session_site_id ? this.encDecService.setWithSpace(postData.session_site_id) : undefined,
    postData.session_state_id = postData.session_state_id ? this.encDecService.setWithSpace(postData.session_state_id) : undefined,
    postData.session_district_id =  postData.session_district_id ? this.encDecService.setWithSpace(postData.session_district_id) : undefined,
    postData.village_id = postData.village_id ? this.encDecService.setWithSpace(postData.village_id) :undefined,
    postData.health_village_id = postData.health_village_id ? this.encDecService.setWithSpace(postData.health_village_id) :undefined,
    postData.ward_id = postData.ward_id ? this.encDecService.setWithSpace(postData.ward_id) :undefined,
    postData.health_ward_id = postData.health_ward_id ?  this.encDecService.setWithSpace(postData.health_ward_id) :undefined,
    postData.asha_user_id = postData.asha_user_id ? this.encDecService.setWithSpace(postData.asha_user_id) : undefined
    console.log(postData) 
    return this.http.post(`${environment.vaccinator_url}/addMultiUipSessionVaccination`, postData)
    }
    else{
      return of(null)
    }
  }
  updateVaccinationUIPStatus(postData) {
    postData.beneficiaryId = this.encDecService.setWithSpace(this.beneficieryVerifiedData.beneficiary_id);
    postData.stateID = this.encDecService.setWithSpace(this.beneficieryVerifiedData.state_id);
    postData.env = this.encDecService.set(environment.env);
    postData.vaccination_dose = this.beneficieryVerifiedData.vaccination_doses_due;
    return this.http.post(`${environment.vaccinator_url}/uipSessionVaccination`, postData)
  }
  updateVaccinationStatus(postData) {
    postData.beneficiaryId = this.encDecService.setWithSpace(this.beneficieryVerifiedData.beneficiary_id);
    postData.stateID = this.encDecService.setWithSpace(this.beneficieryVerifiedData.state_id);
    postData.env = this.encDecService.set(environment.env);
    if (this.beneficieryVerifiedData.vaccination_status < 2) {
      postData.vaccination_dose = this.beneficieryVerifiedData.vaccination_doses_due;
    }
    else {
      postData.vaccination_dose = this.beneficieryVerifiedData.vaccination_status;
    }
    // postData['precaution_dose'] = (this.beneficieryVerifiedData.vaccination_doses_due == this.beneficieryVerifiedData.dose_scheduled + 1) ? 1 : undefined;
    // if(postData.vaccination_status<2){
    //   postData.vaccination_dose= this.beneficieryVerifiedData.beneficiaryDetails.vaccination_doses_due;

    // }else{
    //   postData.vaccination_dose = this.beneficieryVerifiedData.beneficiaryDetails.vaccination_doses_due;
    // }
    return this.http.post(`${environment.beneficiary_vaccinator_v3_url}/vaccinationstatus`, postData)
  }
  getApplicationTrackingDetails(trackerId) {
    const encryptedTrackerId = this.encDecService.set('' + trackerId);
    return this.http.post<any>(
      `${environment.beneficiary_vaccinator_v3_url}/beneficiaryTracker`,
      { beneficiaryReferenceId: encryptedTrackerId }
    );
  }
  saveMetaInfoOfVaccination(payload): Observable<any> {
    return this.http.post<any>(
      `${environment.beneficiary_vaccinator_v3_url}/vaccination`,
      payload
    );
  }
  getVaccinationNotDoneReason() {
    return this.http.get<any>(
      `${environment.beneficiary_vaccinator_v3_url}/getVaccinationNotDoneReasons`)
  }

  updateNotVaccinatedResaons(postData, benfDetails) {
    const encryptedBenId = this.encDecService.set(`${benfDetails.beneficiary_id}`);
    const encryptedStateId = this.encDecService.set(`${benfDetails.state_id}`);
    postData.beneficiaryId = encryptedBenId;
    postData.stateID = encryptedStateId;
    return this.http.post(`${environment.beneficiary_vaccinator_v3_url}/vaccinationNotDoneReasons`, postData)
      .pipe(map(r => {
        return r;
      }));
  }

  declarePregnancy(beneficiary_id, lmc_date, blood_group, previous_pregnancy_year,td_doses_received?) {
    const encyptedBenId = this.encDecService.set(`${beneficiary_id}`);
    const addedFrom = (this.dataService.userDetails.role == 'Vaccinator'  || this.dataService.userDetails.role == 'ANM') ? 'vaccinator' : (this.dataService.userDetails.role == environment.static_ids.DeliveryManagerKey || this.dataService.userDetails.role == environment.static_ids.BothplanningUnitAndDelivery) ? 'dpm' : ' '
    const postData = {
      beneficiary_id: encyptedBenId, lmc_date: lmc_date, blood_group: blood_group,
      previous_pregnancy_year: previous_pregnancy_year,
      added_from:addedFrom,
      facility_id:(this.dataService.userDetails.role == environment.static_ids.DeliveryManagerKey || this.dataService.userDetails.role == environment.static_ids.BothplanningUnitAndDelivery) ? this.dataService.userDetails.facility_id :undefined,
      site_id:this.encDecService.setWithSpace(this.dataService.userDetails.session_site_id),
      td_doses_received:td_doses_received?td_doses_received:undefined,
      platform: this.util.getIsMobileDevice() ? 'APK': 'Web',
      sub_center_id:this.dataService.userDetails.role == environment.static_ids.BothVaccinatorAndDeliveryManager ? this.localStorage.getItem('sub_center_id') : undefined
    }
    return this.http.post(`${environment.beneficiary_vaccinator_v3_url}/declarePregnancy`, postData)

  }

  uipVaccinationsManufacturers(beneficiary_id, session_site_id, dose, material_id, material_name, batch_number, vaccination_date, manufacturer_id, manufacturer_name) {
    const encryptionId = this.encDecService.set(`${beneficiary_id}`);
    const encryptSiteId = this.encDecService.set(`${session_site_id}`);
    const postData = { beneficiary_id: encryptionId, session_site_id: encryptSiteId, dose: dose, material_id: material_id, material_name: material_name, batch_number: batch_number, vaccination_date: vaccination_date, manufacturer_id: manufacturer_id, manufacturer_name: manufacturer_name }
    return this.http.post(`${environment.vaccinator_url}/uipVaccinate`, postData)
  }

  vaccinationCards(beneficiary_id, session_site_id) {
    const encryptedBenId = this.encDecService.set(`${beneficiary_id}`);
    const encryptedSessionSiteID = this.encDecService.set(`${session_site_id}`);
    const postData = { beneficiary_id: encryptedBenId, session_site_id: encryptedSessionSiteID }
    return this.http.post(`${environment.vaccinator_url}/viewImmuinisationSchedule`, postData);
  }

  getOtherVaccinationData(beneficiary_id, session_site_id) {
    const encryptedBenId = this.encDecService.set(`${beneficiary_id}`);
    const encryptedSessionSiteID = this.encDecService.set(`${session_site_id}`);
    const postData = { beneficiary_id: encryptedBenId, session_site_id: encryptedSessionSiteID }
    return this.http.get(`${environment.vaccinator_url}/getothervaccinations_details/${beneficiary_id}`);
  }

  getVaccinationCenters(session_site, material_id) {
    const encryptSiteId = this.encDecService.set(`${session_site}`);
    // const encryptMaterialId = this.encDecService.set(`${material_id}`);
    const postData = { session_site_id: encryptSiteId, material_id: material_id }
    return this.http.post(`${environment.vaccinator_url}/getVaccinsOfCenter`, postData)
  }

  getManfacDetails() {
    // const encryptSiteId = this.encDecService.set(`${material_id}`);
    return this.http.get(`${environment.vaccinator_url}/getManDetails`)
  }

  getUIPSessionsData(session_site_id) {
    const encryptSessionSiteID = this.encDecService.set(`${session_site_id}`);
    return this.http.post(`${environment.vaccinator_url}/getUipSessiondetails`, { session_site_id: encryptSessionSiteID })
  }

  getVaccinationWithSession(beneficiary_id, session_id, session_site_id, vaccination_dose, vaccination_status, stateID, env, session_district_id) {
    const encryptionId = this.encDecService.set(`${beneficiary_id}`);
    const encryptEnv = this.encDecService.set(`${env}`);
    const encryptStateID = this.encDecService.set(`${stateID}`);

    const postData = { beneficiaryId: encryptionId, session_id: session_id, session_site_id: session_site_id, vaccination_dose: vaccination_dose, vaccination_status: vaccination_status, stateID: encryptStateID, env: encryptEnv, session_district_id: session_district_id }
    return this.http.post(`${environment.vaccinator_url}/uipSessionVaccination`, postData)
  }
  isHcwFlw(benf) {
    let currAge = parseInt(moment().format("YYYY")) - parseInt(benf.year);
    if (
      currAge > 18 && benf.beneficiary_type_category_id && (benf.beneficiary_type_category_id == "1" || benf.beneficiary_type_category_id == "2")) {
      return true;
    } else if (currAge > 18 && !benf.beneficiary_type_category_id &&
      [3001, 3, 4, 1001, 1002, 2001, 4001, 5001, 6001, 7001].includes(benf.ben_type_id)) {
      return true;
    }
    return false;
  }
  uploadSpecialDocument(formData) {
    return this.http.post<any>(
      `${environment.vaccinator_url}/updateComorbidityCertificatePhoto`, formData

    )
  }

  addOTPLog(payload) {
    return of(null);
  }

  photoidVerification(payload) {
    return of(null);
  }

  updatePrevPregnancyDeatils(payload) {
    payload['beneficiary_id'] = this.encDecService.set(`${payload.beneficiary_id}`)
    return this.http.post(`${environment.vaccinator_url}/updatePrevPregnancy`, payload)
  }

  getUipVaccineByBenRef(payload) {
    payload['beneficiary_reference_id'] = this.encDecService.set(`${payload.beneficiary_reference_id}`)
    return this.http.post<any>(`${environment.vaccinator_url}/getUipVaccineByBenRef`, payload);
  }

  updatePrevVaccinations(postData,bendob?) {
    let benDob;
    if(bendob){
      benDob = moment(bendob).format("YYYY-MM-DD")
    }
    let isValid = true;
    postData.materialDetails.forEach(ele=>{
      if(ele.prev_vaccination_date && (moment(ele.prev_vaccination_date).isBefore(bendob))){
        isValid = false;
       return of(this.util.presentToastError("Given vaccination dates are before DOB."))
      }
    })
    if(isValid){
    let benId;
    if(postData?.child_beneficiaryId){
     benId = postData?.child_beneficiaryId
    postData.beneficiaryId = this.encDecService.setWithSpace(benId);
    }else{
       benId = this.beneficieryVerifiedData?.beneficiary_id ? this.beneficieryVerifiedData?.beneficiary_id : postData.beneficiaryId
       postData.beneficiaryId = this.encDecService.setWithSpace(benId);
      }
    const stateId = this.beneficieryVerifiedData?.state_id
    // postData.beneficiaryId = this.encDecService.setWithSpace(benId);
    postData.stateID = stateId ? this.encDecService.setWithSpace(stateId) : undefined;
    postData.env = this.encDecService.set(environment.env);
    delete postData.child_beneficiaryId;
    if(this.dataService.userDetails.role == environment.static_ids.BothVaccinatorAndDeliveryManager){
      postData['sub_center_id'] = this.localStorage.getItem('sub_center_id')
      }
    return this.http.post(`${environment.vaccinator_url}/updatePrevVaccinations`, postData)
    }else{
      return of(null)
    }
  }
  
}

