<div class="sidebar-header noMobile">
    <h1>
        <ion-icon *ngIf="!noBack" (click)="gotoStep('prev')" class="custom-arrow-left"></ion-icon>
        <ion-icon *ngIf="isInVerificationSteps()" (click)="gotoStep('prev')" class="custom-arrow-left"></ion-icon>
           <ng-container *ngIf="!noBack">{{'Add Member' | translate}}</ng-container>
           <ng-container *ngIf="noBack">{{'Member Verification' | translate}}</ng-container>
        <ion-icon (click)="closeDialog()" class="custom-close"></ion-icon>
    </h1>
</div>
<ion-header class="noDesktop mobile-header-white">
  <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button *ngIf="!noBack" (click)="gotoStep('prev')"  text="" icon="arrow-back-outline">
        </ion-back-button>
        <ion-back-button *ngIf="isInVerificationSteps()" (click)="gotoStep('prev')"  text="" icon="arrow-back-outline">
        </ion-back-button>
      </ion-buttons>
      <h4 class="heading">{{'Member Verification' | translate}}</h4>
  </ion-toolbar>
</ion-header>
<ng-container *ngIf="activeStepIndex == 'IDENTIFICATION'">
  <app-recipient-verification #child (onStepChange)="gotoStep($event)"></app-recipient-verification>
</ng-container>

