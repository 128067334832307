import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, ReplaySubject, of, throwError} from 'rxjs';
import {ForgotPassword, Login, OtpLogin, PasswordPolicy, ResetPassword} from "../interfaces/auth.interface";
import {User} from "../interfaces/user.interface";
import { EncDecService } from '../../shared/services/encryption-decryption.service';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { LocalStorageService } from './local-storage.service';
import { Store } from '@ngrx/store';
import { DataService } from '../../shared/services';
import { Platform } from '@ionic/angular';
import { UtilsService } from '../../shared/services/utils.service';
import { Device } from '@capacitor/device';



@Injectable({
  providedIn: 'root'
})
export class AuthService {
  secret_key = environment.authKey;
  passwordPolicyData: PasswordPolicy;
  userId: any = null;
  logged_mobile_number = null;
  otpSentFromForgotPage = new ReplaySubject(1);
  sendPolicyData = new ReplaySubject(1);
  txnId = null;

  
  constructor(
    private http: HttpClient,
    private encDecService: EncDecService,
    public localStorage:LocalStorageService,
    private dataServ: DataService,
    private platform: Platform,
    private util: UtilsService,
  ) { }

  login(loginParams: any): Observable<any> {
    if(loginParams?.user_name) {
      return this.loginWithCredentials(loginParams);
    } else {
      return this.loginWithOTP(loginParams);
    }
  }

  loginWithCredentialsold(loginParams: Login): Observable<any> {
    const ts = moment().toDate().getTime();
    let loginSubmit: any = {};
    loginSubmit.user_name = this.encDecService.setWithSpace(loginParams.user_name);
    loginSubmit.password = this.encDecService.setWithDynamicKey(loginParams.password, ts);
    loginSubmit.ts=ts;
    loginSubmit.secret= this.secret_key;
    return this.http.post(`${environment.auth_prefix_url}/login`, loginSubmit, {responseType: 'text'});
  }

  loginWithCredentials(loginParams: Login): Observable<any> {
    const ts = moment().toDate().getTime();
    let loginSubmit: any = {};
    loginSubmit.user_name = this.encDecService.setWithSpace(loginParams.user_name);
    loginSubmit.password = this.encDecService.setWithDynamicKey(loginParams.password, ts);
    loginSubmit.ts=ts;
    loginSubmit.secret= this.secret_key;
    return this.http.post(`${environment.auth_prefix_url}/${environment.isProduction ? 'login' : 'newlogin'}`, loginSubmit);
  }

  loginWithOTP(loginParams: OtpLogin): Observable<any> {
    let payload: any = {};
    payload.otp = this.encDecService.setWithSpace(loginParams.otp);
    payload.txnId=loginParams.txnId;
    return this.http.post(`${environment.auth_prefix_url}/verifyotp`, payload);
  }


  verifyMobileNumber(forgotParams: ForgotPassword): Observable<any> {
    forgotParams.mobileNo = '' + this.encDecService.setWithSpace('' + forgotParams.mobileNo);
    return this.http.post<any>(`${environment.user_prefix_url}/verifyMobileNumber`, forgotParams);
  }

  getOTP(params): Observable<any>  {
    const payload = {
      transaction_id: this.txnId,
      mobile: this.encDecService.setWithSpace(params.mobileNo),
      secret: this.secret_key
    }
    return this.http.post<any>(`${environment.auth_prefix_url}/getotp`, payload);
  }

  resetPassword(params: ResetPassword): Observable<any> {
    const postData = {
      newPassword: this.encDecService.set(params.password),
      otp: this.encDecService.set(params.otp),
      phoneNumber: params.mobileNo,
    };
    return this.http.post<any>(`${environment.user_prefix_url}/resetPassword`, postData);
  }

  getPasswordPolicy(): Observable<any> {
    return this.http.get<PasswordPolicy[]>(`${environment.admin_prefix_v3_url}/passwordPolicy/password_policy`)
    .pipe(map(res => {
      const dataKey = 'data';
      if (res[dataKey].length > 0) {
        this.sendPolicyData.next(res[dataKey])
        this.passwordPolicyData = {
          ...res[dataKey][0],
          is_min_password_length: false,
          is_alphabetical: false,
          is_numeric: false,
          is_special_chars: false
        };
        const start = /^/;
        const alpha = /(?=.*?[A-Z])(?=.*?[a-z])/;
        const numeric = /(?=.*?[0-9])/;
        const special = /(?=.*?[!@#$&*])/;
        const length = `/.{${this.passwordPolicyData.min_password_length},20}$/`;
        let pattern = start.source;

        if (this.passwordPolicyData.alphabetical === 1) {
          pattern += alpha.source;
        }

        if (this.passwordPolicyData.numeric === 1) {
          pattern += numeric.source;
        }

        if (this.passwordPolicyData.special_chars === 1) {
          pattern += special.source;
        }
        pattern += eval(length).source;
        return pattern;
      }
      return null;
    }));
  }
  clearSession() {
    if(this.localStorage.getItem('session_site_id')){
      this.localStorage.removeItem('session_site_id')
    }
    if(this.localStorage.getItem('siteInfo')){
      this.localStorage.removeItem('siteInfo')
    }
    if(this.localStorage.getItem('facilityId')){
      this.localStorage.removeItem('facilityId')
    }
    if(this.localStorage.getItem('sub_center_id')){
      this.localStorage.removeItem('sub_center_id')
    }
    if(sessionStorage.getItem('AUTH')){
      this.localStorage.removeItem('AUTH')
    }
    
    if (sessionStorage.getItem('userToken')) {
      const headers = new HttpHeaders();
      headers.set('Authorization', `${JSON.parse(sessionStorage.getItem('userToken'))}`);
      sessionStorage.removeItem('userToken');
      sessionStorage.clear();
      return false;
    } else {
      sessionStorage.clear();
    }
  }

  validatePassword(res: any) {
    const passwordPolicyData = this.passwordPolicyData;
    const start = /^/;
    const alpha = /(?=.*?[A-Z])(?=.*?[a-z])/;
    const numeric = /(?=.*?[0-9])/;
    const special = /(?=.*?[!@#$&*])/;
    const length = /.{6,20}$/;

    if ((passwordPolicyData.min_password_length <= res.length)) {
      this.passwordPolicyData.is_min_password_length = true;
    } else {
      this.passwordPolicyData.is_min_password_length = false;
    }

    if ((passwordPolicyData.alphabetical === 1 && alpha.test(res))) {
      this.passwordPolicyData.is_alphabetical = true;
    } else {
      this.passwordPolicyData.is_alphabetical = false;
    }

    if ((passwordPolicyData.numeric === 1 && numeric.test(res))) {
      this.passwordPolicyData.is_numeric = true;
    } else {
      this.passwordPolicyData.is_numeric = false;
    }

    if ((passwordPolicyData.special_chars === 1 && special.test(res))) {
      this.passwordPolicyData.is_special_chars = true;
    } else {
      this.passwordPolicyData.is_special_chars = false;
    }
  }


  updatePassword(formData: any){
    formData.currentPassword = this.encDecService.set(formData.currentPassword);
    formData.newPassword = this.encDecService.set(formData.newPassword);

    if(!formData['userId']){
      formData['userId'] = this.encDecService.setWithSpace(this.userId);
    }
    return this.http.post<any>(environment.user_prefix_url + '/updatePassword', formData);
  }

  updatePasswordProfile(formData){
    formData.currentPassword = this.encDecService.set(formData.currentPassword);
    formData.newPassword = this.encDecService.set(formData.newPassword);
    return this.http.post<any>(environment.user_prefix_url + '/updatePasswordProfile', formData);
  }

  logOut(){
    if(this.localStorage.getItem('userToken')){ 
      this.http.post(environment.auth_prefix_url + '/logout', '', {responseType: 'text'}).subscribe();
    }
    if (localStorage.getItem('notification')) {
      localStorage.removeItem('notification')
      localStorage.clear()
    }
    if (this.localStorage.getItem('facilityId')) {
      this.localStorage.removeItem('facilityId')
    }
    if (this.localStorage.getItem('location_Id')) {
      this.localStorage.removeItem('location_Id')
    }
    if(this.localStorage.getItem('sub_center_id')){
      this.localStorage.removeItem('sub_center_id')
    }
    if (this.localStorage.getItem('siteInfo')) {
      this.localStorage.removeItem('siteInfo')
    }
    if (this.localStorage.getItem('session_site_id')) {
      this.localStorage.removeItem('session_site_id')
    }
    this.dataServ.siteInfoDetails = of(null);
  }

  refreshToken(): Observable<any> {
    return this.http.post(`${environment.auth_prefix_url}/getAccessToken`, {refreshToken: this.localStorage.getItem('refreshToken')});
  }

  setAccessToken(res){
      this.localStorage.setItem('userToken', res.accessToken);
      this.localStorage.setItem('refreshToken', res.refreshToken);
  }

  async postLoginUserUpdate(username: any) {
    const deviceInfo = await Device.getInfo();
    const payload = {
      user_name: (username || this.logged_mobile_number),
      "platform": deviceInfo.platform
    };
    this.http.post(`${environment.auth_prefix_url}/postLoginUserUpdate`, payload, {responseType: 'text'}).subscribe();
    this.platform.ready().then(async () => {
      if(this.util.getIsMobileDevice()){ 
        const deviceInfo = await Device.getInfo();
        const deviceId = await Device.getId();
        const payload = {
            "device_id": deviceId.identifier,
            "device_name": deviceInfo.operatingSystem,
            "device_model": deviceInfo.model,
            "platform": deviceInfo.platform,
            "osVersion": deviceInfo.osVersion,
            "iOSVersion": deviceInfo.platform == 'ios' ? deviceInfo.iOSVersion : null,
            "androidSDKVersion": deviceInfo.platform == 'android' ? deviceInfo.androidSDKVersion : null
        };

        this.http.post(`${environment.vaccinator_url}/mobile/addDeviceDetails`, payload).subscribe();
      }
    });
  }
}
