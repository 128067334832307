import { PopoverController } from '@ionic/angular';
import { I18nService } from 'src/app/modules/i18n/i18n.service';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UtilsService } from '../../services/utils.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss'],
})
export class LanguageComponent implements OnInit, OnChanges {
  language: string;
  selectedLang: any = '';
  isMobile = false;
  env = environment;
  @Input() isEditMode = false;

  constructor(
    private i18nService: I18nService,
    public popoverController: PopoverController,
    public util: UtilsService
    ) {}

  ngOnInit() {
    this.selectedLang = '';
    this.language = this.currentLanguage;
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('isEditMode',changes);
      if(changes.isEditMode || !changes.isEditMode){
        this.isEditMode = changes.isEditMode.currentValue;
      }
  }

  setLanguage() {
    if (!this.selectedLang){ return; }
    this.i18nService.language = this.selectedLang.id;
  }

  selectLanguage(event) {
    // console.log('selectLanguage', event)
    if(this.util.getPlatformWidth() >= 575){
      this.selectedLang = event.value;
    }else{
      this.selectedLang = event.detail.value;
      // console.log('selectLanguage', event.detail.value)
    }
    if (!this.selectedLang){ return; }
    this.i18nService.language = this.selectedLang.id;
    this.popoverController.dismiss();
  }

  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get currentLanguageName() {
    const lang = this.languages.find(l=>l.id == this.currentLanguage);
    return lang.regional;
  }

  get languages(): any {
    return this.i18nService.supportedLanguages;
  }
}
