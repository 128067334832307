<form [formGroup]="villageDetailsForm" class="village-filter">
  <ng-container *ngIf="util.getPlatformWidth() >= environment?.static_ids?.TabResoltion">
    <ion-grid>
      <ion-row>
        <ion-col size-md="12" size-lg="12" size="12">
          <ion-label>Session Sites</ion-label>
          <mat-form-field appearance="outline" class="ion-margin-vertical matDropdown">
            <mat-select placeholder="Select from the list" formControlName="session_site"
              (selectionChange)="sessionSiteDetails($event.value)">
              <mat-option *ngFor="let site of sessionSites" [value]="site.session_site_id">
                {{site.session_site_name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="villageDetailsForm.get('session_site').value">
        <ion-col size-md="6" size-lg="6" size="12" *ngIf="userDetails?.village_data?.village_ids.length > 0">
          <ion-label>LGD Villages</ion-label>
          <mat-form-field appearance="outline" class="ion-margin-vertical matDropdown">
            <!-- <mat-label>LGD Villages</mat-label> -->
            <mat-select placeholder="Select from the list" formControlName="village_ID"
              (selectionChange)="selectVillages($event,'village_ID')">
              <mat-option *ngFor="let village of lgdVillages" [value]="village.village_id">
                {{village.village_name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ion-col>
        <ion-col size-md="6" size-lg="6" size="12" *ngIf="userDetails?.village_data?.health_village_ids.length > 0">
          <ion-label>Health Villages</ion-label>
          <mat-form-field appearance="outline" class="ion-margin-vertical matDropdown">
            <mat-select placeholder="Select from the list" formControlName="health_village_ID"
              (selectionChange)="selectVillages($event,'health_village_ID')">
              <mat-option *ngFor="let vilg of healthViillages" [value]="vilg.village_id">
                {{vilg.village_name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-grid>
      <ion-row *ngIf="villageDetailsForm.get('session_site').value">
        <ion-col size-md=" 6" size-lg="6" size="12" *ngIf="wardData.length > 0">
          <mat-label>LGD Wards</mat-label>
          <mat-form-field appearance="outline" class="ion-margin-vertical matDropdown">
            <mat-select placeholder="Select from the list" formControlName="ward_Id"
              (selectionChange)="selectVillages($event,'ward_Id')">
              <mat-option *ngFor="let ward of wardData" [value]="ward.ward_id">
                {{ward.ward_name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ion-col>
        <ion-col size-md="6" size-lg="6" size="12" *ngIf="healthWards.length > 0">
          <mat-label>Health Wards</mat-label>
          <mat-form-field appearance="outline" class="ion-margin-vertical matDropdown">
            <mat-select placeholder="Select from the list" formControlName="health_ward_Id"
              (selectionChange)="selectVillages($event,'health_ward_Id')">
              <mat-option *ngFor="let hltWard of healthWards" [value]="hltWard.ward_id">
                {{hltWard.ward_name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ng-container>


  <ng-container *ngIf="util.getPlatformWidth() < environment?.static_ids?.TabResoltion">
    <ion-row>
      <ion-col size-md="12" size-lg="12" size="12">
        <ion-label>Session Sites</ion-label>
        <mat-form-field appearance="outline" class="ion-margin-vertical matDropdown">
          <mat-select placeholder="Select from the list" formControlName="session_site"
            (selectionChange)="sessionSiteDetails($event.value)">
            <mat-option *ngFor="let site of sessionSites" [value]="site.session_site_id">
              {{site.session_site_name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="villageDetailsForm.get('session_site').value">
      <ion-col size-md="6" size-lg="6" size="12" *ngIf="userDetails?.village_data?.village_ids.length > 0">
        <ion-select placeholder="Select from the list" formControlName="village_ID"
          (ionChange)="selectVillages($event,'village_ID')">
          <ion-select-option *ngFor="let village of lgdVillages" [value]="village.village_id">
            {{village.village_name}}
          </ion-select-option>
        </ion-select>
      </ion-col>
      <ion-col size-md="6" size-lg="6" size="12" *ngIf="userDetails?.village_data?.health_village_ids.length > 0">
        <ion-select placeholder="Select from the list" formControlName="health_village_ID"
          (ionChange)="selectVillages($event,'health_village_ID')">
          <ion-select-option *ngFor="let vilg of healthViillages" [value]="vilg.village_id">
            {{vilg.village_name}}
          </ion-select-option>
        </ion-select>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="villageDetailsForm.get('session_site').value">
      <ion-col size-md="6" size-lg="6" size="12" *ngIf="userDetails?.village_data?.ward_ids.length > 0">
        <ion-select placeholder="Select from the list" formControlName="ward_Id"
          (ionChange)="selectVillages($event,'ward_Id')">
          <ion-select-option *ngFor="let ward of wardData" [value]="ward.ward_id">
            {{ward.ward_name}}
          </ion-select-option>
        </ion-select>
      </ion-col>
      <ion-col size-md="6" size-lg="6" size="12" *ngIf="userDetails?.village_data?.health_ward_ids.length > 0">
        <ion-select placeholder="Select from the list" formControlName="health_ward_Id"
          (ionChange)="selectVillages($event,'health_ward_Id')">
          <ion-select-option *ngFor="let hltWard of healthWards" [value]="hltWard.ward_id">
            {{hltWard.ward_name}}
          </ion-select-option>
        </ion-select>
      </ion-col>
    </ion-row>
  </ng-container>
</form>
