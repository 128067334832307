import {createAction, props} from "@ngrx/store";
import * as fromAuthInterface from "../../interfaces/auth.interface";

const TYPE = '[Auth]';

export const Login = createAction(`${TYPE} Login`, props<{params: fromAuthInterface.Login}>());
export const loginWithOTP = createAction(`${TYPE} Login`, props<{params: fromAuthInterface.OtpLogin}>());
export const LoginSuccess = createAction(`${TYPE} Login Success`);
export const LoginFailure = createAction(`${TYPE} Login Failure`, props<{error: any}>());
export const Logout = createAction(`${TYPE} Logout`);
