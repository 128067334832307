import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({providedIn: 'root'})
export class EncDecService {

  private keys = null;

  constructor() {}

  set(value){
    if(!value || !this.keys){ return value};
    var ciphertext = CryptoJS.AES.encrypt(value, this.keys).toString();
    return ciphertext;
  }

  setWithSpace(value){
    if(!value || !this.keys){ return value};
    var ciphertext = CryptoJS.AES.encrypt('' + value, this.keys).toString();
    return ciphertext;
  }

  encDetails(obj){
    let keyvalue = CryptoJS.AES.decrypt(obj.value, `${obj.timestamp}UWIN`);
    this.keys = keyvalue.toString(CryptoJS.enc.Utf8); 
  }

  setWithDynamicKey(value, ts){
    if(!this.keys){ return value;}
    const dynamicKey = this.keys + ts;
    var ciphertext = CryptoJS.AES.encrypt(value, dynamicKey).toString();
    return ciphertext;
  }
  
  //The get method is use for decrypt the value.
  get(value){
    if(!value || !this.keys){return null;}
    var decrypted = CryptoJS.AES.decrypt(value, this.keys);
    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  isEncrypted(value){
    return this.get(value).replaceAll('"', '');
  }
  isAlreadyDataEncrypted(value){
    if(value.toString().slice(-1) == "="){
      return value
    }else{
      return this.setWithSpace(value)
    }
  }


  decryptPhotoId(data){
    if(data.photo_id_number){
      data.photo_id_number = this.isEncrypted(data.photo_id_number);
    } 
    if(data.photo_id_type){
      data.photo_id_type = this.isEncrypted(data.photo_id_type)
    }
    if(data.beneficiary_id_type_name){
      data.beneficiary_id_type_name = this.isEncrypted(data.beneficiary_id_type_name);
    }
    return data;
  }

  
  decryptMobileNo(data){
    if(data.ben_mobile_number){
      data.ben_mobile_number = this.isEncrypted(data.ben_mobile_number);
    }
    if(data.mobile_number){
      data.mobile_number = this.get(data.mobile_number);
    }
    if(data.user_id)  {data.user_id =  this.get(data.user_id)};

    if(data.user_name) data.user_name = this.get(data.user_name); 
        return data;
    }
    decryptLocationId(data){
      if(data.facility_id){
        data.facility_id = this.isEncrypted(data.facility_id)
      }
      if(data.district_id){
        data.district_id = this.isEncrypted(data.district_id)
      }
      if(data.state_id){
        data.state_id = this.isEncrypted(data.state_id)
      }
      if(data.subdistrict_id){
        data.subdistrict_id = this.isEncrypted(data.subdistrict_id)
      }
    }
   
  }

