import { FormGroup } from '@angular/forms';
import { Component, Input, OnInit, AfterContentChecked, Output, EventEmitter, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { DataService } from 'src/app/modules/shared/services/data.service';
import { LocationService } from 'src/app/modules/shared/services/location.service';
import { UtilsService } from '../../services/utils.service';
import { RecipientService } from 'src/app/screens/recipient/services/recipient.service';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { BeneficiaryService } from 'src/app/screens/beneficiary/services/beneficiary.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-photo-id-select',
  templateUrl: './photo-id-select.component.html',
  styleUrls: ['./photo-id-select.component.scss'],
})
export class PhotoIdSelectComponent implements OnInit, AfterContentChecked, OnChanges {
  @Input() page = null;
  @Input() yearOfBirth: any = null;
  @Input() formKey: any = null;
  @Input() arrObj: any = [];
  @Input() arrKey: any = null;
  @Input() isDisabled: boolean = false;
  @Input() arrKeyVal: any = null;
  @Input() parentForm: FormGroup;
  @Output() onSelect = new EventEmitter();
  @Input() label: any = null;
  @Input() class: any = null;
  @Input() display = 'vertical';
  @Input() benTypeId;
  @Input() gaurdianYOB: any;
  idProofsList: any = [];
  selectIdName = null;
  beneficaryDetails
  toggleType = true;

  constructor(
    private locationsService: LocationService,
    private dataService: DataService,
    public utils: UtilsService,
    private beneficaryService: BeneficiaryService,
    public cdr: ChangeDetectorRef, public actRoute: ActivatedRoute,) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.benTypeId) { this.benTypeId = changes.benTypeId.currentValue; }
    if (changes.yearOfBirth || changes.gaurdianYOB || changes.benTypeId) {
      this.idProofsList = [];
      this.selectIdName = null;
      if (changes.yearOfBirth) { this.yearOfBirth = changes.yearOfBirth.currentValue; }
      if (changes.gaurdianYOB) { this.gaurdianYOB = changes.gaurdianYOB.currentValue; }
      this.onLoad();
    }
  }
  ngOnInit() {
    // this.yearOfBirth = null;
    this.onLoad();
    if (this.class == "noclass") {
      this.class = "";
    }
    else {
      this.class = "large-label";
    }
    //While page Refresh
    if(this.actRoute.snapshot.params.recipientID){  
      this.beneficaryService.getBeneficairyDetails(this.actRoute.snapshot.params.recipientID).subscribe(r => {
        this.beneficaryDetails = r
      });
    }

    //Normal edit flow
    this.beneficaryService.beneficiaryData.subscribe(r => {
      this.beneficaryDetails = r
    })
  }

  onLoad() {
    let params = {
      year: null
    };
    // this.yearOfBirth = null;
    if (this.yearOfBirth) {
      // if(this.yearOfBirth.toString().length != 4){return;}
      const isChild = (parseInt(moment().format('YYYY')) - parseInt(this.yearOfBirth)) < 10;
      if (isChild || this.benTypeId == environment.static_ids.Infant_ID || this.benTypeId == environment.static_ids.NewBornBaby) {
        params.year = parseInt(this.gaurdianYOB);
      } else {
        params.year = parseInt(this.yearOfBirth);
      }
      if (!isNaN(params.year) && params.year != null && params.year != undefined && (params.year).toString().length == 4) {
        this.dataService.getIdProofBasedOnAge(params)
          .subscribe(r => {
            this.idProofsList = r.types;
            const found = this.idProofsList.some(el => el.id === 17);
            if (!found && this.beneficaryDetails?.photo_id_type == 'Student Photo Id Card') {
              this.parentForm.get('photo_id_number').reset()
            } else {
              this.idProofsList = r.types;
            }
            // excluded student id for Adolcent
            if(environment.isProduction && this.parentForm.get('ben_type_id').value == '1701'){
              this.idProofsList = this.idProofsList.filter(i=>i.id != 17);
            }
            // excluded student id for Adolcent
            this.cdr.detectChanges();
          });
      }
    } else {
      this.dataService.getIdProofTypes()
        .subscribe(r => {
          this.idProofsList = r;
          this.cdr.detectChanges();
        });
    }
  }

  ngAfterContentChecked(): void {
  }

  onChange() {
    this.onSelect.emit(this.parentForm.get([this.formKey]).value);
  }

  onSelectIdType(id) {
    this.selectIdName = id.toString().trim();
    this.parentForm.get('photo_id_number').reset()
    this.toggleType = true;
  }

  removeSpaceFromPhotoId() {
    let inputVal: any = this.parentForm.get('photo_id_number').value;
    this.parentForm.controls.photo_id_number
    if (this.utils.isPresent(inputVal)) {
      this.parentForm.get('photo_id_number').setValue(inputVal.trim())
    }

  }


  restrictUserToTypeMoreThanLength(event, length, variable?) {
    const inputValue = event.srcElement.value;
    let year = inputValue.substring(0, 4);
    if (inputValue.length > length) {
      event.srcElement.value = inputValue.substring(0, length);
      event.stopPropagation();
      return false;
    }
  }

  upperCase(event) {
    //return str.toUpperCase();
    let inputValue: any = event.srcElement.value;
    event.srcElement.value = inputValue.toUpperCase();
  }


}
