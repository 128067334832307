import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { AddRecipientService } from 'src/app/screens/add-recipient/services/add-recipient.service';
import { environment } from 'src/environments/environment';
import moment from 'moment';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { LookUpService } from 'src/app/modules/shared/services/look-up.service';
import { RecipientService } from 'src/app/screens/recipient/services/recipient.service';
import { UtilsService } from 'src/app/modules/shared/services/utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/modules/shared/services';
import { getSessionDetails } from 'src/app/modules/core/store/selectors/session.selectors';
import { Store, select } from '@ngrx/store';
import { SessionService } from 'src/app/screens/session/services/session.service';
import { NewBeneficiaryService } from 'src/app/screens/new-beneficiary/services/new-beneficiary.service';
import { getUserDetails } from 'src/app/modules/core/store/selectors/user.selectors';
import { of } from 'rxjs';


@Component({
  selector: 'app-update-beneficiary-vaccination',
  templateUrl: './update-beneficiary-vaccination.component.html',
  styleUrls: ['./update-beneficiary-vaccination.component.scss'],
})
export class UpdateBeneficiaryVaccinationComponent implements OnInit {
  @Output() onNextPage = new EventEmitter();
  @Output() afterAddingVaccination = new EventEmitter();
  @Input() session_id: string = null;
  @Input() beneficiaryDetails: any = {};
  @Input() benId: string = null;
  @Input() skip = false;

  prevVaccinesByAge: any = [];
  uipVaccineByBenArr: any = [];
  isPentavalentVaccineChecked: boolean = false;
  isDptVaccineChecked: boolean = false;
  selectedMat: any = null;
  showDoseInfo = false;
  multiSelectionUIPVaccForm: FormGroup;
  maxDoseDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
  minDoseDate = new Date();
  allMaterailsData: any = [];
  showSummary: boolean = false;
  selectedMaterials: any = [];
  sessionDate;
  vaccinesEligible: any = [];
  duplicateVaccinesEligible: any = [];
  isMaterialDueDateAvailable = true;
  userInfo:any;
  isVaccinatedToday:boolean = false;
  isDptBoosterChecked = false;
  isMMRChecked = false;
  isMRChecked = false;
  constructor(
    public addRecipientService: AddRecipientService,
    public cdr: ChangeDetectorRef,
    public newBeneficiaryService: NewBeneficiaryService,
    public util: UtilsService,
    public router: Router,
    public dataService: DataService,
    public actRoute: ActivatedRoute,
    public fb: FormBuilder,
    public lookup: LookUpService,
    public recipientService: RecipientService,
    private store$: Store,
    private sessionService: SessionService,
  ) {
    this.store$.pipe(select(getUserDetails))
    .subscribe(r => {
      this.userInfo = r;
    })
    this.lookup.getMaterials().subscribe(m => { this.allMaterailsData = m });
  }

  async ngOnInit() {
    this.addRecipientService.benSuccessDetails = of([]);
    if (this.router.url.match(/update-beneficiary-vaccination/g) && this.beneficiaryDetails.beneficiary_type_name == "Citizen" && !this.beneficiaryDetails.is_pregnant_woman) {
      if(!(this.calAgeByDob() >=10 && this.calAgeByDob() < 17)){ 
        this.router.navigate([`beneficiary/${this.benId}/vaccination-details`])
        return;
      } 
    }
    if (this.session_id) {
      this.store$.pipe(select(getSessionDetails)).subscribe((r: any) => {
        this.sessionDate = r.date_of_session
      })
    }
    if((this.beneficiaryDetails.is_pregnant_woman)){
      // this.maxDoseDate = moment().format('YYYY-MM-DD')
      const currentDate = moment().format('YYYY-MM-DD')
      let edddate = moment.utc(this.beneficiaryDetails?.lmc_date).add(9,'months').add(7,'days').format("YYYY-MM-DD");
      if(currentDate < edddate ){
        if((this.userInfo.role == environment.static_ids.BothplanningUnitAndDelivery || this.userInfo.role == environment.static_ids.DeliveryManagerKey)){
          this.maxDoseDate = currentDate
        }else {
          this.maxDoseDate = this.maxDoseDate
        }
      }else{
        this.maxDoseDate = edddate;
      }
    }
    this.multiSelectionUIPVaccForm = this.fb.group({
      materialDetails: this.fb.array([]),
    })
    this.getAllUipVaccinesByBenf();
    this.util.showSummaryVaccinationScreen.subscribe(res => {
      this.showSummary = !!res;
      this.onBack()
    })
    this.loadPreviousVaccination();
  }

  calAgeByDob() {
    return Math.round(moment().diff(moment(this.beneficiaryDetails.dob), 'years', true));
  }


  loadPreviousVaccination() {
    const params = {
      beneficiary_id: this.beneficiaryDetails?.beneficiary_id
    };
    this.addRecipientService.getUipMaterialDoseDetailsByAge(params)
      .subscribe(res => {
        //  this.prevVaccinesByAge = res
        this.prevVaccinesByAge = this.vaccinatedDoseOfBenf(res);
        this.minDoseDate = new Date(this.beneficiaryDetails.dob);
        if (this.beneficiaryDetails.ben_type_id == environment.static_ids.PregnantWomen || (this.beneficiaryDetails.lmc_date && this.beneficiaryDetails.is_pregnant_woman)) {
          this.minDoseDate = new Date(this.beneficiaryDetails?.lmc_date);

        }
        if (this.beneficiaryDetails?.is_pregnant_woman) {
          // this.prevVaccinesByAge = this.validateTdBooster(this.prevVaccinesByAge);
          let index = this.prevVaccinesByAge.map((ele, index) => {
            if ((ele.material_id == environment.static_ids.TD_Booster || ele.material_id == environment.static_ids.TD_Vaccine) && !ele.is_eligible) {
              return index
            }
          }).filter(element => element >= 0);
        }
        this.cdr.detectChanges();
      }, e => {
      })

  }
  
  getAllUipVaccinesByBenf() {
    if (!this.beneficiaryDetails.beneficiary_reference_id) { return; }
    this.uipVaccineByBenArr = [];
    this.recipientService.getUipVaccineByBenRef({ beneficiary_reference_id: this.beneficiaryDetails.beneficiary_reference_id })
      .subscribe(r => {
        if (r) {
          const isCurrentVaccinationDone = r.find(e=> !e.is_past_dated && e.vaccination_date); 
          if(isCurrentVaccinationDone){
            this.util.presentToastInfo('Past History Vaccination Update not allowed because beneficiary already vaccinated based on history');
            this.onNextPage.emit();
          }
          this.uipVaccineByBenArr = r.filter(this.isTDVaccineCompleted);
          console.log('this.uipVaccineByBenArr', this.uipVaccineByBenArr);
          const formArray: FormArray = this.multiSelectionUIPVaccForm.get('materialDetails') as FormArray;
          this.uipVaccineByBenArr.forEach(e=>{
            formArray.push(new FormControl({
              material_id: e.material_id, material_name: e.material_name,
              material_dose: e.dose,
              prev_vaccination_date: e.vaccination_date,
              is_precaution: e.is_precaution
            }));
          });
          console.log('this.multiSelectionUIPVaccForm.get(materialDetails)', this.multiSelectionUIPVaccForm.get('materialDetails').value);  
          this.isPentavalentVaccineChecked = this.uipVaccineByBenArr.find(m => m.material_id === environment.static_ids.Pentavalent_Vaccine) ? true : false;
          this.isDptVaccineChecked = this.uipVaccineByBenArr.find(m => m.material_id === environment.static_ids.DPT_vaccine) ? true : false;
          this.isMRChecked = this.uipVaccineByBenArr.find(m => m.material_id === environment.static_ids.MR) ? true : false;
          this.isMMRChecked = this.uipVaccineByBenArr.find(m => m.material_id === environment.static_ids.MMR) ? true : false;
          this.cdr.detectChanges();
        }
        this.loadPreviousVaccination();
      }, e => {
        this.loadPreviousVaccination();
      });
  }
  isTDVaccineCompleted(ele) {
    const currentDate = moment()
    if (ele.material_id == environment.static_ids.TD_Booster || ele.material_id == environment.static_ids.TD_Vaccine) { // && this.verifiedData?.is_pregnant_woman
      const previousTdDate = moment(ele.vaccination_date);
      // if(this.verifiedData.previous_pregnancy_year){
      // const previousTdDate = moment(this.verifiedData.previous_pregnancy_year)
      const diff = currentDate.diff(previousTdDate, 'years')
      return diff <= 3
      // }
    }
    return true;
  }
  validateTdBooster(materials) {
    const currentDate = moment()
    return materials.map(m => {
      if (this.beneficiaryDetails.previous_pregnancy_year) {
        const previousTdDate = moment(this.beneficiaryDetails?.previous_pregnancy_year, 'YYYY')
        const diff = currentDate.diff(previousTdDate, 'years')
        if (m.material_id == environment.static_ids.TD_Booster) {
          m.is_eligible = diff <= 3;
        } else if (m.material_id == environment.static_ids.TD_Vaccine) {
          m.is_eligible = diff > 3;
        }
      } else if (!this.beneficiaryDetails?.previous_pregnancy_year && this.beneficiaryDetails.is_pregnant_woman) {
        if (m.material_id == environment.static_ids.TD_Vaccine) {
          m.is_eligible = true;
        } else if (m.material_id == environment.static_ids.TD_Booster) {
          m.is_eligible = false;
        }
      }
      return m;
    });
  }
  isFullyVaccinatedByMaterial(material) {
    if ((material.material_id == environment.static_ids.DPT_vaccine && this.isPentavalentVaccineChecked)) {
      return true;
    }
    if ((material.material_id == environment.static_ids.Pentavalent_Vaccine && this.isDptVaccineChecked)) {
      return true;
    }
    if(this.isDptVaccineChecked && material.material_id == environment.static_ids.DPT_BOOSTER){
      const dptBstMat = this.allMaterailsData.find(e => e.material_id == environment.static_ids.DPT_BOOSTER);
      const dptData = this.uipVaccineByBenArr.filter(m => m.material_id === environment.static_ids.DPT_vaccine);
      if(!dptData.find(e=>e.dose == 3)){
        const selectMat = this.multiSelectionUIPVaccForm.get('materialDetails').value;
        const isAllDPTSelected = selectMat.find(e=>e.material_id == environment.static_ids.DPT_vaccine && e.material_dose == 3);
        if(!isAllDPTSelected){
          if(this.isDptBoosterChecked){ 
            this.onMaterialCheckSelection(dptBstMat, { checked: false });
            this.prevVaccinesByAge.map(e=>{
              if(e.material_id == environment.static_ids.DPT_BOOSTER){
                e.checked = false;
                e.selected = false;
              }
            });
          }
          return true;
        }
      }
    }
     
    if(material.material_id == environment.static_ids.MMR){
      const MRdata = this.uipVaccineByBenArr.filter(m => m.material_id === environment.static_ids.MR);
      if(!MRdata.find(e=>e.dose == 2)){
        const selectMat = this.multiSelectionUIPVaccForm.get('materialDetails').value;
        const isMMR2Selected = selectMat.find(e=>e.material_id == environment.static_ids.MR && e.material_dose == 2);
        if(isMMR2Selected){return true;}
      }else if(MRdata.find(e=>e.dose == 2)){
        return true;
      }
    }
  }
  doseReceived(material, d, isPrecaution = false) {
    if (material) {
      return this.uipVaccineByBenArr.find(m => {
        if (isPrecaution) {
          return m?.material_id == material?.material_id && m?.dose == d && m.is_precaution
        }
        return m?.material_id == material?.material_id && m?.dose == d && !m.is_precaution

      });
    }
    return null;
  }
  isVaccinatedForSingleDose(material) {
    if (material.no_of_doses > 1) { return false; }
    return this.doseReceived(material, 1);
  }
  vaccinatedDoseOfBenf(materials) {
    return materials.map(m => {
      m.checked = false;
      if (m.no_of_doses == 1) {
        m.is_single_dose_vaccinated = this.isVaccinatedForSingleDose(m);
        if(m.is_single_dose_vaccinated){ 
          m.checked = true;
          m.selected = true;
          m.doseReceivedDate = m.is_single_dose_vaccinated.vaccination_date;
        }
        m.is_eligible = !m.is_single_dose_vaccinated;
      }
      else {
        m.is_eligible = false;
        m.dose_array = [];
        for (let i = 0; i < parseInt(m.no_of_doses); i++) {
          let isEligible = false;
          let doseReceivedDate = null;
          let isVaccinatedOutside = false;
          let due_date = null;
          let doseReceived = this.doseReceived(m, i + 1);
          if (!doseReceived) {
            let doseDuration = this.doseDuration(m, i + 1);
            m.is_eligible = !m.is_eligible ? doseDuration == 'Pending' : true;
            isEligible = doseDuration == 'Pending';
            due_date = doseDuration != 'Pending' ? doseDuration : null;
          } else {
            m.checked = true;m.selected = true;
            isVaccinatedOutside = doseReceived.is_past_dated;
            doseReceivedDate = doseReceived.vaccination_date;
          }
          m.dose_array.push({
            material_id: m.material_id,
            material_name: m.material_name,
            dose: i + 1, isEligible, due_date, doseReceivedDate, isVaccinatedOutside
          });
        }
        for (let i = 0; i < parseInt(m.no_of_precaution_doses); i++) {
          let isEligible = false;
          let doseReceivedDate = null;
          let isVaccinatedOutside = false;
          let due_date = null;
          let doseReceived = this.doseReceived(m, i + 1, m.no_of_precaution_doses > 0);
          if (!doseReceived) {
            let doseDuration = this.doseDuration(m, i + 1, isVaccinatedOutside, m.no_of_precaution_doses > 0);
            m.is_eligible = !m.is_eligible ? doseDuration == 'Pending' : true;
            isEligible = doseDuration == 'Pending';
            due_date = doseDuration != 'Pending' ? doseDuration : null;
          } else {
            isVaccinatedOutside = doseReceived.is_past_dated;
            doseReceivedDate = doseReceived.vaccination_date;
          }
          m.dose_array.push({
            material_id: m.material_id,
            material_name: m.material_name,
            dose: i + 1, isEligible, due_date, doseReceivedDate, isVaccinatedOutside,
            is_precaution: true
          });
        }
      }
      return m;
    })
  }
  doseDuration(material, dose: any, prev = false, isPrecaution = false) {
    const materialDoseInfo = this.allMaterailsData.find(e => e.material_id == material.material_id);

    //check if current dose is received
    //if received return
    // if not then check whether dose is due / pending
    // if due return date
    // else return pending
    if (this.doseReceived(materialDoseInfo, dose, isPrecaution)) {
      // if(material.no_of_doses > 1){this.onMaterialCheckSelection(material,{checked:true});}
      return 'Pending';
    }
    if (isPrecaution) {
      let lastVaccData
      if (dose > 1) {
        lastVaccData = this.doseReceived(materialDoseInfo, (dose - 1), isPrecaution);
      } else {
        lastVaccData = this.doseReceived(materialDoseInfo, material.no_of_doses, false);
      }
      if (!lastVaccData) { return 'Upcoming'; }
      const dayDuration = materialDoseInfo.precaution_dose_frequencies[dose - 1];
      let lastVaccinatedDate; let dayDiffOfLastVaccine; let nxtdoseDate;
      if (lastVaccData.vaccination_date) {
        lastVaccinatedDate = moment(lastVaccData.vaccination_date).startOf('day');
        dayDiffOfLastVaccine = moment().diff(lastVaccinatedDate, 'days');
        nxtdoseDate = lastVaccinatedDate.add(dayDuration, 'days');
      } else {
        nxtdoseDate = this.getMinDoseDate(material, dose);
      }
      if (!lastVaccData.vaccination_date) {
        return moment().isSameOrAfter(nxtdoseDate) ? 'Pending' : 'Upcoming';
      } else if (dayDuration > dayDiffOfLastVaccine) {
        let daysLeftForVaccination = dayDuration - dayDiffOfLastVaccine;
        return 'Upcoming ' + moment(nxtdoseDate).format('LL');
      } else {
        // if(material.no_of_doses > 1){this.onMaterialCheckSelection(material,{checked:true});}
        return 'Pending';
      }
    }
    else {
      if (dose > 1) {
        const lastVaccData = this.doseReceived(materialDoseInfo, (dose - 1));
        if (!lastVaccData) { return 'Upcoming'; }
        let nextDose = dose;
        const dayDuration = materialDoseInfo.dose_frequencies[dose - 1];
        let lastVaccinatedDate; let dayDiffOfLastVaccine; let nxtdoseDate;
        if (lastVaccData.vaccination_date) {
          lastVaccinatedDate = moment(lastVaccData.vaccination_date).startOf('day');
          dayDiffOfLastVaccine = moment().diff(lastVaccinatedDate, 'days');
          nxtdoseDate = lastVaccinatedDate.add(dayDuration, 'days');
        } else {
          nxtdoseDate = this.getMinDoseDate(material, dose)
        }
        if (!lastVaccData.vaccination_date) {
          return moment().isSameOrAfter(nxtdoseDate) ? 'Pending' : 'Upcoming';
        } else if (dayDuration > dayDiffOfLastVaccine) {
          let daysLeftForVaccination = dayDuration - dayDiffOfLastVaccine;
          return 'Upcoming ' + moment(nxtdoseDate).format('LL');
        } else {
          // if(material.no_of_doses > 1){this.onMaterialCheckSelection(material,{checked:true});}
          return 'Pending';
        }
      } else {
        // if(material.no_of_doses > 1){this.onMaterialCheckSelection(material,{checked:true});}
        return 'Pending';
      }
    }

  }
  isDoseEligibleForMinAge(material, dose) {
    if (dose.doseReceivedDate || dose.isVaccinatedOutside) { return true };
    const materialDoseInfo = this.allMaterailsData.find(e => e.material_id == material.material_id);
    let maxAgeInDays = 0; let minAgeInDays = 0; let agelimit = null;
    if (dose.is_precaution) {
      minAgeInDays = materialDoseInfo.precaution_lower_age_limits[dose.dose - 1];
      maxAgeInDays = materialDoseInfo.precaution_upper_age_limits[dose.dose - 1];
      agelimit = materialDoseInfo.precaution_display_upper_age_limits[dose.dose - 1];
    } else {
      minAgeInDays = materialDoseInfo.lower_age_limits[dose.dose - 1];
      maxAgeInDays = materialDoseInfo.upper_age_limits[dose.dose - 1];
      agelimit = materialDoseInfo.display_upper_age_limits[dose.dose - 1];
    }
    let currentDate = moment();
    const days = currentDate.diff(this.beneficiaryDetails.dob, 'days') //this.beneficiaryDetails.dob
    if (maxAgeInDays == null || !agelimit || agelimit == 'nullD') { return true }

    if (days >= minAgeInDays) {
      return true;
    } else {
      return false;
    }
  }
  labelName(doseArray) {
    const materialDoseInfo = this.allMaterailsData.find(e => e.material_id == doseArray.material_id);
    if (doseArray.is_precaution) {
      return materialDoseInfo.display_precaution_dose_names[doseArray.dose - 1];
    }
    return materialDoseInfo.display_dose_names[doseArray.dose - 1];
  }
  materialDoseName(material) {
    const materialDoseInfo = this.allMaterailsData.find(e => e.material_id == material.material_id);
    if (material.is_precaution) {
      return materialDoseInfo.display_precaution_dose_names[material.material_dose - 1];
    }
    return materialDoseInfo.display_dose_names[material.material_dose - 1];
  }
  onBack() {
    this.showSummary = false;
    this.dataService.showOtherFunctionalities = this.showSummary;
    // this.clearMaterialFormCtrlArr();
    // this.loadPreviousVaccination()
  }
  clearMaterialFormCtrlArr() {
    const formArray = this.multiSelectionUIPVaccForm.get('materialDetails') as FormArray
    while (formArray.length !== 0) {
      formArray.removeAt(0)
    }
  }
  isDoseChecked(material, dose) {
    return this.multiSelectionUIPVaccForm.get('materialDetails').value.find(element => {
      if (!dose.is_precaution) {
        return element.material_id == material.material_id && element.material_dose == dose.dose && !element.is_precaution; // && !element.is_precaution
      } else {
        return element.material_id == material.material_id && element.material_dose == dose.dose && element.is_precaution;
      }
      // if (dose.is_precaution && element.material_id == material.material_id && element.material_dose == dose.dose && element.is_precaution) {
      //   return dose.is_precaution && element.material_id == material.material_id && element.material_dose == dose.dose && element.is_precaution
      // }
    });
  }
  isLastDoseChecked(material, doseObj) {
    if (doseObj.dose == 1 && !doseObj.is_precaution) { return false; }
    if (this.doseReceived(material, doseObj.dose - 1, doseObj.is_precaution) && (!doseObj.is_precaution || (doseObj.dose > 1 && doseObj.is_precaution))) { return false; }
    if (doseObj.is_precaution && this.doseReceived(material, material.no_of_doses) && doseObj.dose == 1) { return false; }
    return !this.multiSelectionUIPVaccForm.get('materialDetails').value.find(element => {

      if (!doseObj.is_precaution) {
        const dose = doseObj.dose - 1;
        return element.material_id == material.material_id && element.material_dose == dose
          && !element.is_precaution; // && !element.is_precaution
      } else {
        const dose = doseObj.dose - 1;
        if (doseObj.dose == 1) {
          return element.material_id == material.material_id && element.material_dose == material.no_of_doses
            && !element.is_precaution;
        } else {
          return element.material_id == material.material_id && element.material_dose == dose
            && element.is_precaution;
        }
      }
    });
  }
  isDoseEligibleByLastDose(material, dose) {
    return !moment().isSameOrAfter(this.getMinDoseDate(material, dose))
  }
  onSelectByDoseDate(material, dose, event) {
    const rmInd = this.uipVaccineByBenArr.findIndex(m=>m.material_id == material.material_id && m.dose == dose.dose && (!dose.is_precaution || m.is_precaution == dose.is_precaution));
    this.uipVaccineByBenArr.splice(rmInd,1);
    this.removeAllNxtDoses(material, dose, {checked: false});
    this.multiSelectionUIPVaccForm.get('materialDetails').value.forEach(element => {
      if (!dose.is_precaution && element.material_id == material.material_id && element.material_dose == dose.dose) {
        element['prev_vaccination_date'] = moment(event.value).format('YYYY-MM-DD');
      }
      if (dose.is_precaution && element.material_id == material.material_id && element.material_dose == dose.dose && element.is_precaution) {
        element['prev_vaccination_date'] = moment(event.value).format('YYYY-MM-DD');
      }
    });
  }
  onSelectByDose(material, dose, event) {
    const materialObj = this.multiSelectionUIPVaccForm.get('materialDetails').value.find(element => {
      return element.material_id == material.material_id && ((element.material_dose == dose.dose && element.is_precaution == dose.is_precaution) || !element.material_dose);
    });
    const formArray: FormArray = this.multiSelectionUIPVaccForm.get('materialDetails') as FormArray;
    if (event.checked) {
      if (!materialObj) {
        formArray.push(new FormControl({
          material_id: material.material_id, material_name: material.material_name,
          material_dose: dose.dose,
          is_precaution: dose.is_precaution,
          prev_vaccination_date: undefined
        }));
      } else if (materialObj && !materialObj.material_dose) {
        formArray.controls.forEach((ctrl: FormControl) => {
          if (ctrl.value.material_id == material.material_id) {
            ctrl.value.material_dose = dose.dose;
            if (dose.is_precaution) {
              ctrl.value.is_precaution = dose.is_precaution;
            }
          }
        });
      }
    } else {
      let i: number = 0;
      const Index = formArray.controls.findIndex((ctrl: FormControl) => {
        // Remove the unselected element from the arrayForm
        if (ctrl.value.material_id == material.material_id && ctrl.value.material_dose == dose.dose) {
          if (dose.is_precaution && ctrl.value.is_precaution) {
            return true;

          }
          else if (!dose.is_precaution && !ctrl.value.is_precaution) {
            return true;
          }
        }
      });
      if (Index != -1) {
        formArray.removeAt(Index);
        this.removeAllNxtDoses(material, dose, event);
        const rmInd = this.uipVaccineByBenArr.findIndex(m=>m.material_id == material.material_id && m.dose == dose.dose && (!dose.is_precaution || m.is_precaution == dose.is_precaution));
        this.uipVaccineByBenArr.splice(rmInd,1);
      }
    }
  }
  removeAllNxtDoses(material, dose, event) {
    let noOfDoses = dose.is_precaution ? material.no_of_precaution_doses : material.no_of_doses;
    let nxtDose = dose.dose + 1;
    for (let i = nxtDose; i <= noOfDoses; i++) {
      this.onSelectByDose(material, { ...dose, dose: i }, event);
      this.prevVaccinesByAge = this.prevVaccinesByAge.map(e=>{ 
        if(e.material_id == material.material_id){
           e.dose_array = e.dose_array.map(d=>{
            if(d.dose == i){
              d.doseReceivedDate = null;
              d.isVaccinatedOutside = false;
            }
            return d;
          });
        }
        return e;
      });
      const rmInd = this.uipVaccineByBenArr.findIndex(m=>m.material_id == material.material_id && m.dose == i && !m.is_precaution);
      this.uipVaccineByBenArr.splice(rmInd,1);
    }
    if (!dose.is_precaution) {
      let noOfDoses = material.no_of_precaution_doses;
      for (let i = 1; i <= noOfDoses; i++) {
        this.onSelectByDose(material, { ...dose, dose: i, is_precaution: true }, event);
        this.prevVaccinesByAge = this.prevVaccinesByAge.map(e=>{ 
          if(e.material_id == material.material_id){
             e.dose_array = e.dose_array.map(d=>{
              if(d.dose == i && d.is_precaution){
                d.doseReceivedDate = null;
                d.isVaccinatedOutside = false;
              }
              return d;
            });
          }
          return e;
        });
        const rmInd = this.uipVaccineByBenArr.findIndex(m=>m.material_id == material.material_id && m.dose == i && m.is_precaution);
        this.uipVaccineByBenArr.splice(rmInd,1);
      }
    }
  }
  onselectedDoseDateEvent(material, event) {
    this.multiSelectionUIPVaccForm.get('materialDetails').value.forEach(element => {
      if (element.material_id == material.material_id) {
        element['prev_vaccination_date'] = moment(event.value).format('YYYY-MM-DD');
      }
    });
  }

  showVaccineInfoByBenf(material) {
    // if (this.isFullyVaccinatedByMaterial(material)) { return false; }
    this.selectedMat = material;
    if (!material.selected) { material.selected = true }
    else { material.selected = false; }
    this.showDoseInfo = !this.showDoseInfo;
    // if(material.no_of_doses == 1){
    this.onMaterialCheckSelection(material, { checked: material.selected });
    // }
    this.cdr.detectChanges();
    // return this.allMaterailData.filter(m=>m.material_id==material.material_id);
  }
  onMaterialCheckSelection(material, event) {
    const formArray: FormArray = this.multiSelectionUIPVaccForm.get('materialDetails') as FormArray;
    if (event.checked) {
      material['checked'] = true;
      if (material.material_id == environment.static_ids.Pentavalent_Vaccine) {
        this.isFullyVaccinatedByMaterial(material)
        // this.isPentavalentVaccineChecked = true;
      }
      if (material.material_id == environment.static_ids.DPT_vaccine) {
        this.isFullyVaccinatedByMaterial(material)
        // this.isDptVaccineChecked = true;
      }
      if (material.material_id == environment.static_ids.DPT_BOOSTER) {
        this.isDptBoosterChecked = true;
      }
      if(material.material_id == environment.static_ids.MMR){
        this.isMMRChecked = true;
      }
      if(material.material_id == environment.static_ids.MR){
        this.isMRChecked = true;
      }
      formArray.push(new FormControl({
        material_id: material.material_id, material_name: material.material_name,
        material_dose: material.no_of_doses > 1 ? null : 1,
        prev_vaccination_date: null
      }));
    } else {
      if (material.material_id == environment.static_ids.Pentavalent_Vaccine) {
        this.isPentavalentVaccineChecked = this.uipVaccineByBenArr.find(m => m.material_id === environment.static_ids.Pentavalent_Vaccine) ? true : false;
      }
      if (material.material_id == environment.static_ids.DPT_vaccine) {
        this.isDptVaccineChecked = this.uipVaccineByBenArr.find(m => m.material_id === environment.static_ids.DPT_vaccine) ? true : false;
      }
      if (material.material_id == environment.static_ids.DPT_BOOSTER) {
        this.isDptBoosterChecked = this.uipVaccineByBenArr.find(m => m.material_id === environment.static_ids.DPT_BOOSTER) ? true : false;
      }
      if(material.material_id == environment.static_ids.MMR){
        this.isMMRChecked = this.uipVaccineByBenArr.find(m => m.material_id === environment.static_ids.MMR) ? true : false;
      }
      if(material.material_id == environment.static_ids.MR){
        this.isMRChecked = this.uipVaccineByBenArr.find(m => m.material_id === environment.static_ids.MR) ? true : false;
      }
      let i: number = 0;
      // this.errorMessage = '';
      const indexes = formArray.controls.map((ctrl: FormControl, index) => {
        if (ctrl.value.material_id == material.material_id) {
          return index;
        }
      }).filter(element => element >= 0);
      for (let i = indexes.length - 1; i >= 0; i--) {
        formArray.removeAt(indexes[i]);
      }
      material['checked'] = false;
      material['prev_vaccination_date'] = null;
      if (material.material_id == environment.static_ids.DPT_BOOSTER) {
        this.isDptBoosterChecked = false;
      }
      // debugger;
      this.removeAllNxtDoses(material, {dose:1}, {checked: false});
      const rmInd = this.uipVaccineByBenArr.findIndex(m=>m.material_id == material.material_id && m.dose == 1);
      this.uipVaccineByBenArr.splice(rmInd,1);
      this.prevVaccinesByAge = this.prevVaccinesByAge.map(e=>{ 
        if(e.material_id == material.material_id){
           e.dose_array = e.dose_array.map(d=>{
            if(d.dose == 1){
              d.doseReceivedDate = null;
              d.isVaccinatedOutside = false;
            }
            return d;
          });
        }
        return e;
      });
  
      return
    }
  }
  getMaxDoseDate(material, dose) {
    let currentDate
    if (this.session_id) {
      currentDate = moment(this.sessionDate).subtract('days', 1);
    } else {
      currentDate = moment().subtract('days', 1)
    }
    const materialDoseInfo = this.allMaterailsData.find(e => e.material_id == material.material_id);
    if (this.beneficiaryDetails.is_pregnant_woman) { 
      if((this.userInfo.role == environment.static_ids.BothplanningUnitAndDelivery || this.userInfo.role == environment.static_ids.DeliveryManagerKey)){
        currentDate = moment();
      }
      if(moment(this.maxDoseDate).isAfter(currentDate)){
        return currentDate;
      }
      return this.maxDoseDate; 
    }
    if (dose.dose == 1 && !dose.is_precaution
      && (materialDoseInfo && materialDoseInfo.upper_age_limits && materialDoseInfo?.display_upper_age_limits && 
        (this.isDptVaccineChecked && materialDoseInfo?.material_id != environment.static_ids.DPT_BOOSTER)
        || !this.isDptVaccineChecked)) {
      let agelimit;
      if(materialDoseInfo?.display_upper_age_limits){
        agelimit = materialDoseInfo?.display_upper_age_limits[0];
      }
      let maxDate;
      if(materialDoseInfo.upper_age_limits){ 
       maxDate = moment(this.minDoseDate).add(materialDoseInfo.upper_age_limits[0], 'days');
      }
      if (maxDate <= currentDate || !agelimit || agelimit == 'nullD') {
        return maxDate;
      } else {
        return this.maxDoseDate;
      }
      // return moment(this.minDoseDate).add(materialDoseInfo.upper_age_limits[0],'days').format('YYYY-MM-DD');
    }
    if (dose.is_precaution) {
      let agelimit = materialDoseInfo.display_upper_age_limits[dose.dose - 1];
      const maxAgeInDays = materialDoseInfo.precaution_upper_age_limits[dose.dose - 1];
      if (maxAgeInDays == null || !agelimit || agelimit == 'nullD') {
        return this.maxDoseDate;
      } else {
        let maxDate = moment(this.beneficiaryDetails.dob).add(maxAgeInDays, 'days');
        if (maxDate <= currentDate) {
          return maxDate
        } else {
          return this.maxDoseDate;
        }
      }
    } else {
      let agelimit = materialDoseInfo.display_upper_age_limits[dose.dose - 1];
      const maxAgeInDays = materialDoseInfo.upper_age_limits[dose.dose - 1];
      if (maxAgeInDays == null || !agelimit || agelimit == 'nullD') {
        return this.maxDoseDate;
      } else {
        let maxDate = moment(this.beneficiaryDetails.dob).add(maxAgeInDays, 'days');
        if (maxDate <= currentDate) {
          return maxDate
        } else {
          return this.maxDoseDate;
        }
      }
    }
  }
  isLastDoseDateAdded(material, dose) {
    let value;
    if (this.uipVaccineByBenArr && this.uipVaccineByBenArr.length > 0) {
      this.uipVaccineByBenArr.filter(ele => {
        if ((dose.is_precaution && dose.dose > 1) || (!dose.is_precaution && dose.dose)) {
          if (ele.material_id == material.material_id && ele.dose == (dose.dose - 1)) {
            value = ele;
          }
        } else {
          if (ele.material_id == material.material_id && ele.dose == material.no_of_doses) {
            value = ele;
          }
        }

      });

      if (value) { return value; }
      else if (!value) { return this.isLastDoseAddedInFormArr(material, dose); }

      return value;
    } else {
      return this.isLastDoseAddedInFormArr(material, dose);
    }
  }
  isLastDoseAddedInFormArr(material, dose) {
    return this.multiSelectionUIPVaccForm.get('materialDetails').value.find(element => {
      if ((!dose.is_precaution && dose.dose)) {
        return element.material_id == material.material_id && element.material_dose == (dose.dose - 1)
          && (element.prev_vaccination_date || element.vaccination_date);
      } else if ((dose.is_precaution && dose.dose > 1)) {
        return element.material_id == material.material_id && element.material_dose == (dose.dose - 1)
          && (element.prev_vaccination_date || element.vaccination_date) && element.is_precaution;
      } else if (dose.is_precaution) {
        return element.material_id == material.material_id && element.material_dose == material.no_of_doses
          && (element.prev_vaccination_date || element.vaccination_date)
      }
    })
  }
  //check if any previous dose is selected for that particular vaccination
  isAnypreviousDoseDateAdded(material, dose){
    const materials = this.multiSelectionUIPVaccForm.get('materialDetails').value
    return materials.filter(ele=>{
      return material.material_id == ele.material_id
      && ((ele.material_dose < dose.dose && !ele.is_precaution) || ele.is_precaution)
      && (ele.prev_vaccination_date || ele.vaccination_date)
    })
  }

  getMinDoseDate(material, dose) {
    const materialDoseInfo = this.allMaterailsData.find(e => e.material_id == material.material_id);
    let minDoseDate = moment(this.minDoseDate).format('YYYY-MM-DD');
    /**
     * Checking If User is pregnant women and with lmc date
     * 
     */
    if (!this.beneficiaryDetails?.is_pregnant_woman && !this.beneficiaryDetails?.lmc_date 
      && !dose.is_precaution && dose.dose == 1
      && (
        (this.isDptVaccineChecked && materialDoseInfo?.material_id != environment.static_ids.DPT_BOOSTER)
        || !this.isDptVaccineChecked)
      && (
        (this.isMRChecked && materialDoseInfo?.material_id != environment.static_ids.MMR)
        || !this.isMRChecked)
      ) {
      minDoseDate = moment(this.minDoseDate).add(materialDoseInfo.min_age, 'days').format('YYYY-MM-DD');
      // }
      // if(!dose.is_precaution && dose.dose == 1) {
      return minDoseDate;
    }
    let lastDoseData = this.isLastDoseDateAdded(material, dose);
    let lastDoseDate = null;
    if(this.isDptVaccineChecked && materialDoseInfo?.material_id == environment.static_ids.DPT_BOOSTER){
      const dptData = this.uipVaccineByBenArr.filter(m => m.material_id === environment.static_ids.DPT_vaccine);
      if(!dptData.find(e=>e.dose == 3)){
        const selectMat = this.multiSelectionUIPVaccForm.get('materialDetails').value;
        lastDoseData = selectMat.find(e=>e.material_id == environment.static_ids.DPT_vaccine && e.material_dose == 3);
      }
      if(lastDoseData){ 
        lastDoseDate = lastDoseData.vaccination_date ? lastDoseData.vaccination_date : lastDoseData.prev_vaccination_date;
      }
      if(lastDoseData && !lastDoseDate){
        const dptMat = this.allMaterailsData.find(e => e.material_id == environment.static_ids.DPT_vaccine);
        lastDoseData.prev_vaccination_date = this.getMinDoseDate(dptMat, {dose: 3});
      }
    }

    if(this.isMRChecked && materialDoseInfo?.material_id == environment.static_ids.MMR){
      const mrData = this.uipVaccineByBenArr.filter(m => m.material_id === environment.static_ids.MR);
      let mrMat = this.allMaterailsData.find(e => e.material_id == environment.static_ids.MR);
      lastDoseData = mrData.find(e=>e.dose == 1);
      if(!mrData.find(e=>e.dose == 1)){
        const selectMat = this.multiSelectionUIPVaccForm.get('materialDetails').value;
        lastDoseData = selectMat.find(e=>e.material_id == environment.static_ids.MR && e.material_dose == 1);
      }
      lastDoseDate = lastDoseData?.vaccination_date ? lastDoseData?.vaccination_date : lastDoseData?.prev_vaccination_date;
      if(lastDoseData && !lastDoseDate){
        lastDoseData.prev_vaccination_date = this.getMinDoseDate(mrMat, {dose: 1});
      }
    }
    if (lastDoseData) {
      lastDoseDate = lastDoseData.vaccination_date ? lastDoseData.vaccination_date : lastDoseData.prev_vaccination_date;
    }
    if (lastDoseData && lastDoseDate) {
      const lastDoseDate = lastDoseData.vaccination_date ? lastDoseData.vaccination_date : lastDoseData.prev_vaccination_date;
      let dayDuration; let lowerAgeDuration;
      if (dose.is_precaution) {
        dayDuration = materialDoseInfo.precaution_dose_frequencies[dose.dose - 1];
        lowerAgeDuration = materialDoseInfo.precaution_lower_age_limits[dose.dose - 1];
      } else {
        dayDuration = materialDoseInfo.dose_frequencies[dose.dose - 1];
        lowerAgeDuration = materialDoseInfo.lower_age_limits[dose.dose - 1];
      }
      if(this.isDptVaccineChecked && materialDoseInfo?.material_id == environment.static_ids.DPT_BOOSTER){
        dayDuration = materialDoseInfo.dose_frequencies[1];
      }
      if(this.isMRChecked && materialDoseInfo?.material_id == environment.static_ids.MMR){
        let mrMat = this.allMaterailsData.find(e => e.material_id == environment.static_ids.MR);
        dayDuration = mrMat.dose_frequencies[1];
      }
      const lastVaccinatedDate = moment(lastDoseDate).startOf('day');
      const mData = lastVaccinatedDate.add(dayDuration, 'days');
      const lowerAgeLimitOfDose = moment(this.minDoseDate).add(lowerAgeDuration, 'days');
      if (lowerAgeLimitOfDose.isBefore(mData)) {
        return mData.format('YYYY-MM-DD');
      } else {
        return lowerAgeLimitOfDose.format('YYYY-MM-DD');
      }
    } else {
      var sum = 0;
      var latestValue;
      if(this.isAnypreviousDoseDateAdded(material, dose) && this.isAnypreviousDoseDateAdded(material, dose).length > 0){
        latestValue = this.isAnypreviousDoseDateAdded(material, dose).slice(-1)
        for(let i=latestValue[0].material_dose;i< dose.dose;i++){
          sum = sum + materialDoseInfo.dose_frequencies[i]
        }
        
        if (dose.is_precaution) {
          if(!latestValue[0].is_precaution){
            const lastDoseValue = this.getMinDoseDate(material,{dose:material.no_of_doses})
            
            for(let i=0;i<material.no_of_precaution_doses;i++){
              sum = sum + materialDoseInfo.precaution_dose_frequencies[i]
            }
            const lastVaccinatedDate = moment(lastDoseValue).startOf('day');
            return lastVaccinatedDate.add(sum, 'days').format('YYYY-MM-DD');
          }else{
            for(let i=latestValue[0].material_dose;i<material.no_of_precaution_doses;i++){
              sum = sum + materialDoseInfo.precaution_dose_frequencies[i]
            }
          }
          // 
          }
        const vaccinatedDate = latestValue[0].prev_vaccination_date || latestValue[0].vaccination_date
        const lastVaccinatedDate = moment(vaccinatedDate).startOf('day');
      return lastVaccinatedDate.add(sum, 'days').format('YYYY-MM-DD');
      }
      if (materialDoseInfo?.material_id != environment.static_ids.TD_Booster) {
        if (materialDoseInfo.lower_age_limits[dose.dose - 1]) {
          sum = materialDoseInfo.lower_age_limits[dose.dose - 1];
        }
        if (dose.is_precaution && materialDoseInfo.precaution_display_lower_age_limits[dose.dose - 1]) {
          sum = materialDoseInfo.precaution_lower_age_limits[dose.dose - 1];
        }
      }
      if(materialDoseInfo?.material_id == environment.static_ids.TD_Booster){
        sum = materialDoseInfo.dose_frequencies[0];
      }
      const lastVaccinatedDate = moment(minDoseDate).startOf('day');
      return lastVaccinatedDate.add(sum, 'days').format('YYYY-MM-DD');
    }
  }
  checkMaterialDoseIsEligible(material) {
    let materials = this.prevVaccinesByAge;
    return materials.find(e => e.material_id == material.material_id && e.is_eligible);
  }
  
  submit() {
    let nonSelected = 0;
    this.multiSelectionUIPVaccForm.markAllAsTouched();
    this.selectedMaterials = this.multiSelectionUIPVaccForm.get('materialDetails').value;
     
    if (this.selectedMaterials.length == 0) {
      this.util.presentToastError('Please Select Vaccine');
      return
    }
    this.selectedMaterials.forEach((element, index) => {
      if(this.beneficiaryDetails.is_pregnant_woman && (this.userInfo.role == environment.static_ids.PregnantWomen || this.userInfo.role == environment.static_ids.BothplanningUnitAndDelivery)){
        if(element.prev_vaccination_date == moment().format('YYYY-MM-DD')){
          element['vaccination_date'] = element.prev_vaccination_date;
          delete element.prev_vaccination_date
          this.isVaccinatedToday = true;
        }
        else{
          this.isVaccinatedToday = false
        }
      }
      if (element.material_dose == null) {
        this.util.presentToastError('Please Select Vaccination Dose');
        nonSelected = nonSelected + 1
        return
      }
    });
    if (nonSelected == 0) {
      this.showSummary = true;
      this.dataService.showOtherFunctionalities = this.showSummary;
      this.afterAddingVaccination.emit(this.showSummary)
    }

  }
  inProgress = false;
  submitPrevData(value) {
    const payload = {
      "vaccination_status": 1,
      "is_past_dated": this.isVaccinatedToday ? false : true,
      "materialDetails": this.selectedMaterials,
      "beneficiaryId": this.beneficiaryDetails?.beneficiary_id,
    };
    this.inProgress = true;
    this.recipientService.updatePrevVaccinations(payload,this.beneficiaryDetails?.dob).subscribe(res => {
      if(res){
      this.util.presentToastSuccess("Vaccination Data Updated Successfully");
      if (value == 'onlySave') {
        if (this.session_id) {
          // this.sessionService.refreshSessionData$.next(true);

          this.onNextPage.emit();

          // this.router.navigate([`/session/${this.session_id}`]);
        }
        else if (this.actRoute.snapshot.queryParams.fromBenPage == 'true') {
          this.onNextPage.emit();

          // this.router.navigate([`beneficiary/${this.benId}/vaccination-details`])
        }
        else {
          this.sessionService.refreshSessionData$.next(true);
          this.onNextPage.emit();

          // this.router.navigateByUrl('/dashboard');
        }

      }
    }
      this.inProgress = false;
    },e=>{this.inProgress = false;})
  }
  title() {
    if (this.showSummary) {
      let obj = { title: 'Vaccination Summary', showSummary: this.showSummary, benId: this.benId, sessionId: this.session_id ? this.session_id : undefined }
      return obj
    } else {
      let obj = { title: 'Record Previous Vaccination', showSummary: this.showSummary, benId: this.benId, sessionId: this.session_id ? this.session_id : undefined }
      return obj
    }
  }
  disableBySpecificCase(material, dose?){
    let minDoseDateCalculated = moment(this.getMinDoseDate(material, {dose: 1})).endOf('day'); 
    if(material.material_id == environment.static_ids.MR && dose && dose.dose == 2){
      const MRdata = this.uipVaccineByBenArr.find(m => m.material_id === environment.static_ids.MMR);
      if(!MRdata){
        const selectMat = this.multiSelectionUIPVaccForm.get('materialDetails').value;
        const isMMRSelected = selectMat.find(e=>e.material_id == environment.static_ids.MMR);
        if(isMMRSelected){return true;}
      }else{
        return true;
      }
    }else if(this.isMRChecked && material.material_id == environment.static_ids.MMR){
      let mmrMat = this.allMaterailsData.find(e => e.material_id == environment.static_ids.MMR);
      let minDoseDate = moment(this.getMinDoseDate(mmrMat, {dose: 1})).endOf('day'); 
      if(moment(minDoseDate).isAfter(moment())){ 
        this.onMaterialCheckSelection(mmrMat, { checked: false });
        this.prevVaccinesByAge.map(e=>{
          if(e.material_id == environment.static_ids.MMR){
            e.checked = false;
            e.selected = false;
          }
        });
        return true;
      }
    }else if(this.isDptVaccineChecked && material.material_id == environment.static_ids.DPT_BOOSTER){
      let dptBMat = this.allMaterailsData.find(e => e.material_id == environment.static_ids.DPT_BOOSTER);
      let minDoseDate = moment(this.getMinDoseDate(dptBMat, {dose: 1})).endOf('day'); 
      if(moment(minDoseDate).isAfter(moment())){ 
        this.onMaterialCheckSelection(dptBMat, { checked: false });
        this.prevVaccinesByAge.map(e=>{
          if(e.material_id == environment.static_ids.DPT_BOOSTER){
            e.checked = false;
            e.selected = false;
          }
        });
        return true;
      }
    }
    else if(moment(minDoseDateCalculated).isAfter(moment())){ 
      return true
    }
    return false;
  }
  isSubmitDisable(){
    this.selectedMaterials = this.multiSelectionUIPVaccForm.get('materialDetails').value;
    return this.selectedMaterials.length == 0 || this.selectedMaterials.find(ele=>ele.material_dose == null) ? true : false;
  }
}
