<!-- <div id="app-custom-loader">
  Loading ##
</div> -->
<!-- <ion-loading></ion-loading> -->
<!-- <div class="progress-loader">
  <div class="loading-spinner">
      <img src="assets/images/loader-gif.svg">
      <span class="loading-message">Please wait...</span>
  </div>
</div> -->

<!-- <div id="preloader">

  <div id="loader"></div>
</div> -->
<div class="spinner" [hidden]="!loading">
  <div></div>
  <!-- <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div> -->
</div>
 