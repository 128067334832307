import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { RecipientService } from 'src/app/screens/recipient/services/recipient.service';
import { AddRecipientService } from '../../services/add-recipient.service';
import { Location } from '@angular/common';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UtilsService } from 'src/app/modules/shared/services/utils.service';
import * as moment from 'moment';
import { LookUpService } from 'src/app/modules/shared/services/look-up.service';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';
import { DataService } from 'src/app/modules/shared/services';
import { LocalStorageService } from 'src/app/modules/core/services/local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { I18nService } from '../../../../modules/i18n/i18n.service';
import { Title } from '@angular/platform-browser';
import { UserService } from 'src/app/modules/core/services/user.service';


@Component({
  selector: 'app-select-multi-vaccination-uip',
  templateUrl: './select-multi-vaccination-uip.component.html',
  styleUrls: ['./select-multi-vaccination-uip.component.scss'],
})
export class SelectMultiVaccinationUipComponent implements OnInit, OnDestroy {
  @Input() activeStepIndex = null;
  @Output() onStepChange: EventEmitter<any> = new EventEmitter();
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  selectedIndex: number = 0;
  benfSubscription: Subscription;
  verifiedData;
  benficiaryId;
  multiSelectionUIPVaccForm: FormGroup;
  vaccinesEligible: any = [];
  materialArray: any = [];
  errorMessage = '';
  isChecked: boolean = false;
  duplicateVaccinesEligible: any = [];
  formSubmitted: boolean = false;
  showPreviousVaccination = false;
  loadingPrevVaccines = false;
  prevVaccinesByAge: any = [];
  maxDoseDate = new Date();
  minDoseDate = new Date();
  uipVaccineByBenArr: any = [];
  showDoseInfo = false;
  selectedMat: any = null;
  allMaterailsData: any = [];
  villageDetails = [];
  environment;
  isMaterialDueDateAvailable = true;
  isPentavalentVaccineChecked: boolean = false;
  isDptVaccineChecked: boolean = false;
  healthVillagesDetails = [];
  wardDetails = [];
  healthWardDetails = [];
  wardData = [];
  healthWards = [];
  showprevBttn: boolean = true;
  isDptBoosterChecked = false;
  isMMRChecked = false;
  isMRChecked = false;
  villageDataDetails: boolean = false;
  currLang = 'en'; 
  ashaList:any = [];
  selectAllChecked = false;

  constructor(
    public addRecipientService: AddRecipientService,
    public recipientService: RecipientService,
    public actRoute: ActivatedRoute,
    public loc: Location,
    public fb: FormBuilder,
    public util: UtilsService,
    public cdr: ChangeDetectorRef,
    private lookup: LookUpService,
    public dataService: DataService,
    public router: Router,
    public localStorage: LocalStorageService,
    private translate: TranslateService,
    private i18nService: I18nService,
    private titleService: Title,
    public userService: UserService,

  ) {
    // this.maxDoseDate.setDate(this.maxDoseDate.getDate()-1);
    this.lookup.getMaterials().subscribe(m => { this.allMaterailsData = m });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.activeStepIndex) {
      if (this.activeStepIndex == 'MULTIVACCUIP') {
        this.reloadComponent();
      }
      else {
        this.unSubscribe();
      }
    }
  }
  unSubscribe() {
    if (this.benfSubscription) this.benfSubscription.unsubscribe();
    // if(this.benfVerSubscription)this.benfVerSubscription.unsubscribe();
  }

  async ngOnInit() {
    this.environment = environment;
    this.multiSelectionUIPVaccForm = this.fb.group({
      materialDetails: this.fb.array([]),
      village_id: '',
      health_village_id: '',
      ward_id: '',
      health_ward_id: '',
      tag_session_site: '',
      asha_user_id: ''
    })
    this.dataService.userData.subscribe(async res => {
      if (res) {
        const siteVillages = await this.lookup.getSiteVillages().toPromise();
        this.villageDetails = siteVillages.villageData;
        this.healthVillagesDetails = siteVillages.healthVillageData;
        this.wardDetails = siteVillages.wardData;
        this.healthWardDetails = siteVillages.healthWardData;
      }
    })
    this.setCurrLanguage();
    this.getAshaUsers()
  }

  getAshaUsers() {
    if (this.localStorage.getItem('session_site_id')) {
      this.userService.getAshaUsers().subscribe((r: any) => {
        this.ashaList = _.orderBy(r, [(o) => { return o.session_site_id || '' }, 'name'], ['asc', 'asc']);
      });
    }
  }

  onChangelocation(e) {
    if (e) {
      this.multiSelectionUIPVaccForm.get('tag_session_site').reset();
      this.multiSelectionUIPVaccForm.get('tag_session_site').disable();
      this.multiSelectionUIPVaccForm.get('tag_session_site').updateValueAndValidity();
    }
  }

  handleSessionSiteAndVillage(e) {
    if (e.checked == true) {
      this.multiSelectionUIPVaccForm.get('village_id').reset();
      this.multiSelectionUIPVaccForm.get('village_id').disable();
      this.multiSelectionUIPVaccForm.get('village_id').updateValueAndValidity()

      this.multiSelectionUIPVaccForm.get('health_village_id').reset();
      this.multiSelectionUIPVaccForm.get('health_village_id').disable();
      this.multiSelectionUIPVaccForm.get('health_village_id').updateValueAndValidity()

      this.multiSelectionUIPVaccForm.get('ward_id').reset();
      this.multiSelectionUIPVaccForm.get('ward_id').disable();
      this.multiSelectionUIPVaccForm.get('ward_id').updateValueAndValidity()

      this.multiSelectionUIPVaccForm.get('health_ward_id').reset();
      this.multiSelectionUIPVaccForm.get('health_ward_id').disable()
      this.multiSelectionUIPVaccForm.get('health_ward_id').updateValueAndValidity()
    }else{
      this.multiSelectionUIPVaccForm.get('village_id').reset();
      this.multiSelectionUIPVaccForm.get('village_id').enable();
      this.multiSelectionUIPVaccForm.get('village_id').updateValueAndValidity()

      this.multiSelectionUIPVaccForm.get('health_village_id').reset();
      this.multiSelectionUIPVaccForm.get('health_village_id').enable();
      this.multiSelectionUIPVaccForm.get('health_village_id').updateValueAndValidity()

      this.multiSelectionUIPVaccForm.get('ward_id').reset();
      this.multiSelectionUIPVaccForm.get('ward_id').enable();
      this.multiSelectionUIPVaccForm.get('ward_id').updateValueAndValidity()

      this.multiSelectionUIPVaccForm.get('health_ward_id').reset();
      this.multiSelectionUIPVaccForm.get('health_ward_id').enable();
      this.multiSelectionUIPVaccForm.get('health_ward_id').updateValueAndValidity()

    }
  }

  isTDVaccineCompleted(ele) {
    const currentDate = moment()
    if (ele.material_id == environment.static_ids.TD_Booster || ele.material_id == environment.static_ids.TD_Vaccine) { // && this.verifiedData?.is_pregnant_woman
      const previousTdDate = moment(ele.vaccination_date);
      // if(this.verifiedData.previous_pregnancy_year){
      // const previousTdDate = moment(this.verifiedData.previous_pregnancy_year)
      const diff = currentDate.diff(previousTdDate, 'years')
      return diff <= 3
      // }
    }
    return true;
  }
  isDataInprogress = false;
  getAllUipVaccinesByBenf() {
    if (!this.verifiedData.beneficiary_reference_id) { return; }
    this.uipVaccineByBenArr = [];
    this.isDataInprogress = true;
    this.recipientService.getUipVaccineByBenRef({ beneficiary_reference_id: this.verifiedData.beneficiary_reference_id })
      .subscribe(r => {
        if (r) {
          // this.uipVaccineByBenArr = r;
          this.uipVaccineByBenArr = r.filter(this.isTDVaccineCompleted);
          // if(isLastTdVaccineAboveThree){
          //   this.uipVaccineByBenArr = r.filter(ele=>{
          //     return ele.material_id != 126 &&  ele.material_id != 127
          //   })
          // }
          this.isPentavalentVaccineChecked = this.uipVaccineByBenArr.find(m => m.material_id === environment.static_ids.Pentavalent_Vaccine) ? true : false;
          this.isDptVaccineChecked = this.uipVaccineByBenArr.find(m => m.material_id === environment.static_ids.DPT_vaccine) ? true : false;
          this.isMRChecked = this.uipVaccineByBenArr.find(m => m.material_id === environment.static_ids.MR) ? true : false;
          this.isMMRChecked = this.uipVaccineByBenArr.find(m => m.material_id === environment.static_ids.MMR) ? true : false;



          this.cdr.detectChanges();
        }

        this.loadVaccinesOfBenf();
      }, e => { this.loadVaccinesOfBenf(); });
  }

  reloadComponent() {
    // this.materialArray = [];
    this.benfSubscription = this.addRecipientService.benSuccessData.subscribe(res => {
      this.verifiedData = res;
      this.minDoseDate = new Date(res.dob);
      this.maxDoseDate = new Date(moment(this.verifiedData.date_of_session).subtract('days', 1).format('YYYY-MM-DD'));
      if (res.dob == moment().format('DD-MMM-YYYY')) {
        this.showprevBttn = false
      }
      else {
        this.showprevBttn = true
      }
      if (res.ben_type_id == environment.static_ids.PregnantWomen || (res?.lmc_date && res.is_pregnant_woman)) {
        this.minDoseDate = new Date(res?.lmc_date);
        let edddate = new Date(moment.utc(this.verifiedData?.lmc_date).add(9, 'months').add(7, 'days').format("YYYY-MM-DD"));
        this.maxDoseDate = edddate;
      }

      this.getAllUipVaccinesByBenf();
    })
  }
  loadCurrentVaccines() {
    this.gotoStep('prev');
    this.getAllUipVaccinesByBenf()
  }
  loadVaccinesOfBenf() {
    const params = {
      beneficiary_id: this.verifiedData?.beneficiary_id,
      session_id: this.verifiedData?.session_id,
    }
    this.loadingPrevVaccines = true;
    this.addRecipientService.getuipMaterialdosedetails(params).subscribe(res => {
      this.vaccinesEligible = res;
      this.selectedIndex = 0;
      this.duplicateVaccinesEligible = this.vaccinatedDoseOfBenf(this.vaccinesEligible);
      if (this.verifiedData?.is_pregnant_woman) {
        this.duplicateVaccinesEligible = this.validateTdBooster(this.duplicateVaccinesEligible);
      }
      this.isMaterialDueDateAvailable = true;
      this.duplicateVaccinesEligible.forEach((vaccine) => {
        if (this.isMaterialDueDateAvailable && vaccine.is_eligible) {
          this.isMaterialDueDateAvailable = false;
        }
        vaccine.dose_array?.forEach(dose => {
          if (this.isMaterialDueDateAvailable && dose.isEligible) {
            this.isMaterialDueDateAvailable = false;
          }
        })
      });
      this.loadingPrevVaccines = false;
      this.isDataInprogress = false;
      this.cdr.reattach();
    }, e => {
      this.isDataInprogress = false;
      this.loadingPrevVaccines = false;
    })
  }
  onselectedDoseEvent(material, d, event) {
    this.multiSelectionUIPVaccForm.get('materialDetails').value.forEach(element => {
      if (element.material_id == material.material_id) {
        element['material_dose'] = event.value;
        element['is_precaution'] = d.is_precaution
      }
    });
  }
  onselectedDoseDateEvent(material, event) {
    this.multiSelectionUIPVaccForm.get('materialDetails').value.forEach(element => {
      if (element.material_id == material.material_id) {
        element['prev_vaccination_date'] = moment(event.value).format('YYYY-MM-DD');
      }
    });
  }
  onMaterialCheckSelection(material, event) {
    const formArray: FormArray = this.multiSelectionUIPVaccForm.get('materialDetails') as FormArray;
    if (event.checked) {
      material['checked'] = true;
      if (material.material_id == environment.static_ids.Pentavalent_Vaccine) {
        this.isFullyVaccinatedByMaterial(material)
        this.isPentavalentVaccineChecked = true;
      }
      if (material.material_id == environment.static_ids.DPT_vaccine) {
        this.isFullyVaccinatedByMaterial(material)
        this.isDptVaccineChecked = true;
      }
      if (material.material_id == environment.static_ids.DPT_BOOSTER) {
        this.isDptBoosterChecked = true;
      }
      if (material.material_id == environment.static_ids.MMR) {
        this.isMMRChecked = true;
        if (!this.showPreviousVaccination) { this.isMRChecked = false; }
      }
      if (material.material_id == environment.static_ids.MR) {
        this.isMRChecked = true;
        if (!this.showPreviousVaccination) { this.isMMRChecked = false; }
      }
      formArray.push(new FormControl({
        material_id: material.material_id, material_name: material.material_name,
        material_dose: material.no_of_doses > 1 ? null : 1,
        prev_vaccination_date: this.showPreviousVaccination ? null : undefined
      }));
    } else {
      if (material.material_id == environment.static_ids.Pentavalent_Vaccine) {
        this.isPentavalentVaccineChecked = this.uipVaccineByBenArr.find(m => m.material_id === environment.static_ids.Pentavalent_Vaccine) ? true : false;
      }
      if (material.material_id == environment.static_ids.DPT_vaccine) {
        this.isDptVaccineChecked = this.uipVaccineByBenArr.find(m => m.material_id === environment.static_ids.DPT_vaccine) ? true : false;
      }
      if (material.material_id == environment.static_ids.DPT_BOOSTER) {
        this.isDptBoosterChecked = this.uipVaccineByBenArr.find(m => m.material_id === environment.static_ids.DPT_BOOSTER) ? true : false;
      }
      if (material.material_id == environment.static_ids.MMR) {
        this.isMMRChecked = this.uipVaccineByBenArr.find(m => m.material_id === environment.static_ids.MMR) ? true : false;
      }
      if (material.material_id == environment.static_ids.MR) {
        this.isMRChecked = this.uipVaccineByBenArr.find(m => m.material_id === environment.static_ids.MR) ? true : false;
      }
      let i: number = 0;
      this.errorMessage = '';
      const indexes = formArray.controls.map((ctrl: FormControl, index) => {
        if (ctrl.value.material_id == material.material_id) {
          return index;
        }
      }).filter(element => element >= 0);
      for (let i = indexes.length - 1; i >= 0; i--) {
        formArray.removeAt(indexes[i]);
      }
      material['checked'] = false;
      if (this.showPreviousVaccination) {
        material['prev_vaccination_date'] = null;
      }
      if (material.material_id == environment.static_ids.DPT_BOOSTER) {
        this.isDptBoosterChecked = false;
      }
      return
    }
  }
  gotoStep(val) {
    if (this.showPreviousVaccination && val == 'prev') {
      this.clearMaterialFormCtrlArr();
      this.showPreviousVaccination = false;
      return;
    }
    if (val == 'prev' && !this.verifiedData?.isRejected && this.util.getPlatformWidth() < this.environment.TabResoltion) {
      if (this.verifiedData.session_id) {
        this.router.navigate([`/session/${this.verifiedData.session_id}`])
      } else {
        this.router.navigate([`/dashboard`])
      }
    } else {
      this.onStepChange.emit(val);
    }

  }
  closeDialog() {
    this.onClose.emit();
  }

  submit() {
    this.errorMessage = ''
    const isUserTagToSite = this.multiSelectionUIPVaccForm.get('tag_session_site').value;
    if ((this.villageDetails.length > 0 || this.healthVillagesDetails.length > 0 || this.wardDetails.length > 0 || this.healthWardDetails.length > 0)) {
      if (!this.showPreviousVaccination
        && (this.multiSelectionUIPVaccForm.get('village_id').value == ''
          || this.multiSelectionUIPVaccForm.get('village_id').value == null && !isUserTagToSite)
        && (this.multiSelectionUIPVaccForm.get('health_village_id').value == ''
          || this.multiSelectionUIPVaccForm.get('health_village_id').value == null && !isUserTagToSite)
        && (this.multiSelectionUIPVaccForm.get('ward_id').value == ''
          || this.multiSelectionUIPVaccForm.get('ward_id').value == null && !isUserTagToSite)
        && (this.multiSelectionUIPVaccForm.get('health_ward_id').value == ''
          || this.multiSelectionUIPVaccForm.get('health_ward_id').value == null && !isUserTagToSite)) {
        this.util.presentToastError("Tag the village/ward or Tag to session site");
        return;
      }
    }
    
    const selectedAsha = this.multiSelectionUIPVaccForm.get('asha_user_id').value
    if((selectedAsha ==  null || selectedAsha == undefined || selectedAsha == '') && this.ashaList.length > 0) return this.util.presentToastError("Please select asha from drop down")

    this.multiSelectionUIPVaccForm.markAllAsTouched();
    let selectedMaterials = this.multiSelectionUIPVaccForm.get('materialDetails').value;
    this.formSubmitted = true;
    selectedMaterials.forEach((element, index) => {
      if (!this.checkMaterialDoseIsEligible(element)) {
        selectedMaterials.splice(index, 1);
        return;
      }
      if (element.material_dose == null) {
        this.errorMessage = 'Please Select Vaccination Dose';
      }
      if (this.showPreviousVaccination && element.material_dose != null && element.prev_vaccination_date == null) {
        // this.errorMessage = 'Please Select Vaccination Dose Date';
      }
    });
    if (this.verifiedData.session_type_name == 'UIP' && selectedMaterials.length == 0) {
      this.util.presentToastError('Please Select Vaccine Type');
      return
    }
    if (!this.showPreviousVaccination && this.vaccinesEligible.length == 0 && this.verifiedData.session_type_name == 'UIP') {
      this.util.presentToastError('No Vaccines are available');
      return
    }
    if (!this.showPreviousVaccination && this.vaccinesEligible.length > 0 && selectedMaterials && selectedMaterials?.length == 0) {
      this.util.presentToastError('Please Select Vaccine Type');
      return
    }
    if (this.errorMessage != '' && selectedMaterials && selectedMaterials?.length > 0) {
      this.util.presentToastError(this.errorMessage);
      return
    }
    if (!this.multiSelectionUIPVaccForm.valid) {
      return;
    }
    let healthVillageName = this.healthVillagesDetails.find(ele => {
      return this.multiSelectionUIPVaccForm.get('health_village_id').value == ele.village_id
    })
    let LGDVillageName = this.villageDetails.find(ele => {
      return this.multiSelectionUIPVaccForm.get('village_id').value == ele.village_id
    })

    let healthWardName = this.healthWardDetails.find(ele => {
      return this.multiSelectionUIPVaccForm.get('health_ward_id').value == ele.add_ward_id
    })
    let wardName = this.wardDetails.find(ele => {
      return this.multiSelectionUIPVaccForm.get('ward_id').value == ele.ward_id
    })
    this.addRecipientService.benSuccessDetails = {
      ... this.verifiedData,
      ...this.multiSelectionUIPVaccForm.value,
      ...{ isPastDated: this.showPreviousVaccination ? true : undefined },
      ...{ health_village_name: healthVillageName ? healthVillageName.village_name : undefined },
      ...{ village_name: LGDVillageName ? LGDVillageName.village_name : undefined },
      ...{ health_ward_name: healthWardName ? healthWardName.add_ward_name : undefined },
      ...{ ward_name: wardName ? wardName.ward_name : undefined }
    };
    this.gotoStep('next')
  }

  addPrevVaccination() {
    this.isMaterialDueDateAvailable = false;
    this.clearMaterialFormCtrlArr();
    this.showPreviousVaccination = true;
    this.loadingPrevVaccines = true;
    const params = {
      beneficiary_id: this.verifiedData.beneficiary_id,
    }
    this.addRecipientService.getUipMaterialDoseDetailsByAge(params)
      .subscribe(res => {
        this.loadingPrevVaccines = false;
        this.prevVaccinesByAge = this.vaccinatedDoseOfBenf(res);
        if (this.verifiedData?.is_pregnant_woman) {
          this.prevVaccinesByAge = this.validateTdBooster(this.prevVaccinesByAge);
          let index = this.prevVaccinesByAge.map((ele, index) => {
            if ((ele.material_id == environment.static_ids.TD_Booster || ele.material_id == environment.static_ids.TD_Vaccine) && !ele.is_eligible) {
              return index
            }
          }).filter(element => element >= 0);
          // this.prevVaccinesByAge.splice(index[0],1);
        }
        this.cdr.detectChanges();
      }, e => {
        this.loadingPrevVaccines = false;
      })
  }

  clearMaterialFormCtrlArr() {
    const formArray = this.multiSelectionUIPVaccForm.get('materialDetails') as FormArray
    while (formArray.length !== 0) {
      formArray.removeAt(0)
    }
  }


  showVaccineInfoByBenf(material, isallDosesChecked?) {
    if (this.isFullyVaccinatedByMaterial(material)) { return false; }
    this.selectedMat = material;
    // if (!material.selected) { material.selected = true }
    // else {
    //   if(isallDosesChecked){
    //     material.selected =  material.selected
    //   }else{
    //     material.selected = false;
    //   }
    // }
    if (isallDosesChecked === true) {
      material.selected = true;
    } else if (isallDosesChecked === false) {
      material.selected = false;
    } else if (!material.selected) {
      material.selected = true;
    } else {
      material.selected = false;
    }
  
    this.onMaterialCheckSelection(material, { checked: material.selected });
    this.cdr.detectChanges();
    this.updateSelectAllCheckboxStatus();
  }
  
  updateSelectAllCheckboxStatus() {
    this.selectAllChecked = this.duplicateVaccinesEligible.every(v => v.selected);
  }

  doseReceived(material, d, isPrecaution = false) {
    if (material) {
      return this.uipVaccineByBenArr.find(m => {
        if (isPrecaution) {
          return m?.material_id == material?.material_id && m?.dose == d && m.is_precaution
        }
        return m?.material_id == material?.material_id && m?.dose == d && !m.is_precaution

      });
    }
    return null;
  }

  isVaccineDue() {

  }

  doseDuration(material, dose: any, prev = false, isPrecaution = false) {
    const materialDoseInfo = this.allMaterailsData.find(e => e.material_id == material.material_id);


    //check if current dose is received
    //if received return
    // if not then check whether dose is due / pending
    // if due return date
    // else return pending
    if (this.doseReceived(materialDoseInfo, dose, isPrecaution)) {
      // if(material.no_of_doses > 1){this.onMaterialCheckSelection(material,{checked:true});}
      return 'Pending';
    }
    if (isPrecaution) {
      let lastVaccData
      if (dose > 1) {
        lastVaccData = this.doseReceived(materialDoseInfo, (dose - 1), isPrecaution);
      } else {
        lastVaccData = this.doseReceived(materialDoseInfo, material.no_of_doses, false);
      }
      if (!lastVaccData) return this.translate.instant('Upcoming');
      const dayDuration = materialDoseInfo.precaution_dose_frequencies[dose - 1];
      let lastVaccinatedDate; let dayDiffOfLastVaccine; let nxtdoseDate;


      if (lastVaccData.vaccination_date) {
        lastVaccinatedDate = moment(lastVaccData.vaccination_date).startOf('day');
        dayDiffOfLastVaccine = moment().diff(lastVaccinatedDate, 'days');
        nxtdoseDate = lastVaccinatedDate.add(dayDuration, 'days');
      } else {
        nxtdoseDate = this.getMinDoseDate(material, { ...material, dose: dose, is_precaution: isPrecaution });
      }


      if (!lastVaccData.vaccination_date) {
        const pendingText = this.translate.instant('Pending');
        const upcomingText = this.translate.instant('Upcoming');
        return moment().isSameOrAfter(nxtdoseDate) ? pendingText : upcomingText;
      } else if (dayDuration > dayDiffOfLastVaccine) {
        let daysLeftForVaccination = dayDuration - dayDiffOfLastVaccine;
        const pendingText = this.translate.instant('Pending');
        const upcomingText = this.translate.instant('Upcoming');
        return moment().isSameOrAfter(nxtdoseDate) ? pendingText : upcomingText;
      } else {
        // if(material.no_of_doses > 1){this.onMaterialCheckSelection(material,{checked:true});}
        return 'Pending';
      }
    }
    else {
      if (dose > 1) {
        const lastVaccData = this.doseReceived(materialDoseInfo, (dose - 1));
        if (!lastVaccData) { return this.translate.instant('Upcoming'); }
        let nextDose = dose;
        const dayDuration = materialDoseInfo.dose_frequencies[dose - 1];
        let lastVaccinatedDate; let dayDiffOfLastVaccine; let nxtdoseDate;

        if (!lastVaccData.vaccination_date && materialDoseInfo.material_id == environment.static_ids.TD_Vaccine && dose > 1) {
          let nxtTdVaccDate = moment(new Date(this.verifiedData?.lmc_date)).add(dayDuration, 'days').format();
          dayDiffOfLastVaccine = moment().diff(nxtTdVaccDate, 'days');
          lastVaccData.vaccination_date = nxtTdVaccDate;
        }

        if (lastVaccData.vaccination_date) {
          lastVaccinatedDate = moment(lastVaccData.vaccination_date).startOf('day');
          dayDiffOfLastVaccine = moment().diff(lastVaccinatedDate, 'days');
          nxtdoseDate = lastVaccinatedDate.add(dayDuration, 'days');
        } else {
          nxtdoseDate = this.getMinDoseDate(material, { ...material, dose: dose })
        }

        if (!lastVaccData.vaccination_date) {
          const pendingText = this.translate.instant('Pending');
    const upcomingText = this.translate.instant('Upcoming');
    return moment().isSameOrAfter(nxtdoseDate) ? pendingText : upcomingText;
        } else if (dayDuration > dayDiffOfLastVaccine) {
          let daysLeftForVaccination = dayDuration - dayDiffOfLastVaccine;
          return 'Upcoming ' + moment(nxtdoseDate).format('LL');
        } else {
          // if(material.no_of_doses > 1){this.onMaterialCheckSelection(material,{checked:true});}
          return 'Pending';
        }
      } else {
        if (this.verifiedData.is_pregnant_woman) {
          if (materialDoseInfo.material_id == environment.static_ids.TD_Vaccine) {
            const dayDuration = materialDoseInfo.dose_frequencies[dose];
            const lmcDate = moment(this.verifiedData.lmc_date).startOf('day');
            const dayDiffOfLMC = moment().diff(lmcDate, 'days');
            if (dayDuration > dayDiffOfLMC) {
              const upcomingText = this.translate.instant('Upcoming');
              return upcomingText + ' ' + moment(lmcDate).add(dayDuration, 'days').format('LL');
            } else {
              // if(material.no_of_doses > 1){this.onMaterialCheckSelection(material,{checked:true});}
              return 'Pending';
            }
          }
        }
        // if(material.no_of_doses > 1){this.onMaterialCheckSelection(material,{checked:true});}
        return 'Pending';
      }
    }

  }

  isVaccinatedForSingleDose(material) {
    if (material.no_of_doses > 1) { return false; }
    return this.doseReceived(material, 1);
  }
  isDoseEligibleForCurrentAge(material, dose) {
    if (dose.doseReceivedDate || dose.isVaccinatedOutside) { return true };
    const materialDoseInfo = this.allMaterailsData.find(e => e.material_id == material.material_id);
    let maxAgeInDays = 0; let minAgeInDays = 0; let agelimit = null;
    if (dose.is_precaution) {
      minAgeInDays = materialDoseInfo.precaution_lower_age_limits[dose.dose - 1];
      maxAgeInDays = materialDoseInfo.precaution_upper_age_limits[dose.dose - 1];
      agelimit = materialDoseInfo.precaution_display_upper_age_limits[dose.dose - 1];
    } else {
      minAgeInDays = materialDoseInfo.lower_age_limits[dose.dose - 1];
      maxAgeInDays = materialDoseInfo.upper_age_limits[dose.dose - 1];
      agelimit = materialDoseInfo.display_upper_age_limits[dose.dose - 1];
    }
    if (maxAgeInDays == null || !agelimit || agelimit == 'nullD') { return true }
    let currentDate = moment();
    const days = currentDate.diff(this.verifiedData.dob, 'days')
    if (days < maxAgeInDays && days >= minAgeInDays) {
      return true;
    } else {
      return false;
    }
  }
  isDoseEligibleForMinAge(material, dose) {
    if (dose.doseReceivedDate || dose.isVaccinatedOutside) { return true };
    const materialDoseInfo = this.allMaterailsData.find(e => e.material_id == material.material_id);
    let maxAgeInDays = 0; let minAgeInDays = 0; let agelimit = null;
    if (dose.is_precaution) {
      minAgeInDays = materialDoseInfo.precaution_lower_age_limits[dose.dose - 1];
      maxAgeInDays = materialDoseInfo.precaution_upper_age_limits[dose.dose - 1];
      agelimit = materialDoseInfo.precaution_display_upper_age_limits[dose.dose - 1];
    } else {
      minAgeInDays = materialDoseInfo.lower_age_limits[dose.dose - 1];
      maxAgeInDays = materialDoseInfo.upper_age_limits[dose.dose - 1];
      agelimit = materialDoseInfo.display_upper_age_limits[dose.dose - 1];
    }
    let currentDate = moment();
    const days = currentDate.diff(this.verifiedData.dob, 'days')
    if (maxAgeInDays == null || !agelimit || agelimit == 'nullD') { return true }

    if (days >= minAgeInDays) {
      return true;
    } else {
      return false;
    }
  }
  vaccinatedDoseOfBenf(materials) {
    return materials.map(m => {
      m.checked = false;

      if (m.no_of_doses == 1) {
        m.is_single_dose_vaccinated = this.isVaccinatedForSingleDose(m);
        m.is_eligible = !m.is_single_dose_vaccinated;
      } else {
        m.is_eligible = false;
        m.dose_array = [];
        for (let i = 0; i < parseInt(m.no_of_doses); i++) {
          let isEligible = false;
          let doseReceivedDate = null;
          let isVaccinatedOutside = false;
          let due_date = null;
          let doseReceived = this.doseReceived(m, i + 1);
          if (!doseReceived) {
            let doseDuration = this.doseDuration(m, i + 1);
            m.is_eligible = !m.is_eligible ? doseDuration == 'Pending' : true;
            isEligible = doseDuration == 'Pending';
            due_date = doseDuration != 'Pending' ? doseDuration : null;
          } else {
            isVaccinatedOutside = doseReceived.is_past_dated;
            doseReceivedDate = doseReceived.vaccination_date;
          }
          m.dose_array.push({
            material_id: m.material_id,
            material_name: m.material_name,
            dose: i + 1, isEligible, due_date, doseReceivedDate, isVaccinatedOutside
          });
        }
        for (let i = 0; i < parseInt(m.no_of_precaution_doses); i++) {
          let isEligible = false;
          let doseReceivedDate = null;
          let isVaccinatedOutside = false;
          let due_date = null;
          let doseReceived = this.doseReceived(m, i + 1, m.no_of_precaution_doses > 0);
          if (!doseReceived) {
            let doseDuration = this.doseDuration(m, i + 1, isVaccinatedOutside, m.no_of_precaution_doses > 0);
            m.is_eligible = !m.is_eligible ? doseDuration == 'Pending' : true;
            isEligible = doseDuration == 'Pending';
            due_date = doseDuration != 'Pending' ? doseDuration : null;
          } else {
            isVaccinatedOutside = doseReceived.is_past_dated;
            doseReceivedDate = doseReceived.vaccination_date;
          }
          m.dose_array.push({
            material_id: m.material_id,
            material_name: m.material_name,
            dose: i + 1, isEligible, due_date, doseReceivedDate, isVaccinatedOutside,
            is_precaution: true
          });
        }
      }
      return m;
    })
  }
  labelName(doseArray) {
    const materialDoseInfo = this.allMaterailsData.find(e => e.material_id == doseArray.material_id);
    if (doseArray.is_precaution) {
      return materialDoseInfo.display_precaution_dose_names[doseArray.dose - 1];
    }
    return materialDoseInfo.display_dose_names[doseArray.dose - 1];
    // if(doseArray.is_precaution){
    //   if(doseArray.material_id == environment.static_ids.OPV_Vaccine ){
    //     return "OPV-Booster"+ ' '+`${doseArray.dose}`
    //   }
    //   else{
    //     return 'Booster Dose'+' '+`${doseArray.dose}`
    //   }
    // }
    // if(doseArray.material_id == environment.static_ids.OPV_Vaccine){
    //   return 'OPV' +' '+`${doseArray.dose -1}`
    // }else{
    //   return 'Dose'+' '+`${doseArray.dose}`
    // }
  }

  checkMaterialDoseIsEligible(material) {
    let materials = !this.showPreviousVaccination ? this.duplicateVaccinesEligible : this.prevVaccinesByAge;
    return this.showPreviousVaccination || materials.find(e => e.material_id == material.material_id && e.is_eligible);
  }

  onSelectByDose(material, dose, event) {
    const materialObj = this.multiSelectionUIPVaccForm.get('materialDetails').value.find(element => {
      return element.material_id == material.material_id && ((element.material_dose == dose.dose && element.is_precaution == dose.is_precaution) || !element.material_dose);
    });
    const formArray: FormArray = this.multiSelectionUIPVaccForm.get('materialDetails') as FormArray;
    if (event.checked) {
      if (!materialObj) {
        formArray.push(new FormControl({
          material_id: material.material_id, material_name: material.material_name,
          material_dose: dose.dose,
          is_precaution: dose.is_precaution,
          prev_vaccination_date: this.showPreviousVaccination ? null : undefined
        }));
      } else if (materialObj && !materialObj.material_dose) {
        formArray.controls.forEach((ctrl: FormControl) => {
          if (ctrl.value.material_id == material.material_id) {
            ctrl.value.material_dose = dose.dose;
            if (dose.is_precaution) {
              ctrl.value.is_precaution = dose.is_precaution;
            }
          }
        });
      }
    } else {
      let i: number = 0;
      const Index = formArray.controls.findIndex((ctrl: FormControl) => {
        // Remove the unselected element from the arrayForm
        if (ctrl.value.material_id == material.material_id && ctrl.value.material_dose == dose.dose) {
          if (dose.is_precaution && ctrl.value.is_precaution) {
            // ctrl.value.is_precaution = undefined;
            // formArray.removeAt(i);
            return true;

          }
          else if (!dose.is_precaution && !ctrl.value.is_precaution) {
            // formArray.removeAt(i);
            return true;
          }

          // material['checked'] = false;
          // return;
        }
        // i++;
      });
      if (Index != -1) {
        formArray.removeAt(Index);
        // if(Index != -1){
        this.removeAllNxtDoses(material, dose, event);
      }
      // }
    }
  }


  isDoseChecked(material, dose) {
    return this.multiSelectionUIPVaccForm.get('materialDetails').value.find(element => {
      if (!dose.is_precaution) {
        return element.material_id == material.material_id && element.material_dose == dose.dose && !element.is_precaution; // && !element.is_precaution
      } else {
        return element.material_id == material.material_id && element.material_dose == dose.dose && element.is_precaution;
      }
      // if (dose.is_precaution && element.material_id == material.material_id && element.material_dose == dose.dose && element.is_precaution) {
      //   return dose.is_precaution && element.material_id == material.material_id && element.material_dose == dose.dose && element.is_precaution
      // }
    });
  }

  isLastDoseChecked(material, doseObj) {
    if (doseObj.dose == 1 && !doseObj.is_precaution) { return false; }
    if (this.doseReceived(material, doseObj.dose - 1, doseObj.is_precaution) && (!doseObj.is_precaution || (doseObj.dose > 1 && doseObj.is_precaution))) { return false; }
    if (doseObj.is_precaution && this.doseReceived(material, material.no_of_doses) && doseObj.dose == 1) { return false; }
    return !this.multiSelectionUIPVaccForm.get('materialDetails').value.find(element => {

      if (!doseObj.is_precaution) {
        const dose = doseObj.dose - 1;
        return element.material_id == material.material_id && element.material_dose == dose
          && !element.is_precaution; // && !element.is_precaution
      } else {
        const dose = doseObj.dose - 1;
        if (doseObj.dose == 1) {
          return element.material_id == material.material_id && element.material_dose == material.no_of_doses
            && !element.is_precaution;
        } else {
          return element.material_id == material.material_id && element.material_dose == dose
            && element.is_precaution;
        }
      }
    });
  }

  isDoseEligibleByLastDose(material, dose) {
    return !moment().isSameOrAfter(this.getMinDoseDate(material, dose))
  }

  onSelectByDoseDate(material, dose, event) {
    this.removeAllNxtDoses(material, dose, {checked: false});
    this.multiSelectionUIPVaccForm.get('materialDetails').value.forEach(element => {
      if (!dose.is_precaution && element.material_id == material.material_id && element.material_dose == dose.dose) {
        element['prev_vaccination_date'] = moment(event.value).format('YYYY-MM-DD');
      }
      if (dose.is_precaution && element.material_id == material.material_id && element.material_dose == dose.dose && element.is_precaution) {
        element['prev_vaccination_date'] = moment(event.value).format('YYYY-MM-DD');
      }
    });
  }

  removeAllNxtDoses(material, dose, event) {
    let noOfDoses = dose.is_precaution ? material.no_of_precaution_doses : material.no_of_doses;
    let nxtDose = dose.dose + 1;
    for (let i = nxtDose; i <= noOfDoses; i++) {
      this.onSelectByDose(material, { ...dose, dose: i }, event);
      this.prevVaccinesByAge = this.prevVaccinesByAge.map(e=>{ 
        if(e.material_id == material.material_id){
           e.dose_array = e.dose_array.map(d=>{
            if(d.dose == i){
              d.doseReceivedDate = null;
            }
            return d;
          });
        }
        return e;
      });
    }
    if (!dose.is_precaution && noOfDoses == dose.dose) {
      let noOfDoses = material.no_of_precaution_doses;
      for (let i = 1; i <= noOfDoses; i++) {
        this.onSelectByDose(material, { ...dose, dose: i, is_precaution: true }, event);
        this.prevVaccinesByAge = this.prevVaccinesByAge.map(e=>{ 
          if(e.material_id == material.material_id){
             e.dose_array = e.dose_array.map(d=>{
              if(d.dose == i && d.is_precaution){
                d.doseReceivedDate = null;
              }
              return d;
            });
          }
          return e;
        });
      }
    }
  }

  isLastDoseDateAdded(material, dose) {
    let value;
    if (this.uipVaccineByBenArr && this.uipVaccineByBenArr.length > 0) {
      this.uipVaccineByBenArr.filter(ele => {
        if ((dose.is_precaution && dose.dose > 1) || (!dose.is_precaution && dose.dose)) {
          if (ele.material_id == material.material_id && ele.dose == (dose.dose - 1)) {
            value = ele;
          }
        } else {
          if (ele.material_id == material.material_id && ele.dose == material.no_of_doses) {
            value = ele;
          }
        }

      });

      if (value) { return value; }
      else if (!value && this.showPreviousVaccination) { return this.isLastDoseAddedInFormArr(material, dose); }

      return value;
    } else {
      return this.isLastDoseAddedInFormArr(material, dose);
    }
  }

  isLastDoseAddedInFormArr(material, dose) {
    return this.multiSelectionUIPVaccForm.get('materialDetails').value.find(element => {
      if ((!dose.is_precaution && dose.dose)) {
        return element.material_id == material.material_id && element.material_dose == (dose.dose - 1)
          && (element.prev_vaccination_date || element.vaccination_date);
      } else if ((dose.is_precaution && dose.dose > 1)) {
        return element.material_id == material.material_id && element.material_dose == (dose.dose - 1)
          && (element.prev_vaccination_date || element.vaccination_date) && element.is_precaution;
      } else if (dose.is_precaution) {
        return element.material_id == material.material_id && element.material_dose == material.no_of_doses
          && (element.prev_vaccination_date || element.vaccination_date)
      }
    })
  }
  //check if any previous dose is selected for that particular vaccination

  isAnypreviousDoseDateAdded(material, dose){
    const materials = this.multiSelectionUIPVaccForm.get('materialDetails').value
    return materials.filter(ele=>{
      return material.material_id == ele.material_id
      && ((ele.material_dose < dose.dose && !ele.is_precaution) || ele.is_precaution)
      && (ele.prev_vaccination_date || ele.vaccination_date)
    })
  }

  isFullyVaccinatedByMaterial(material) {
    if ((material.material_id == environment.static_ids.DPT_vaccine && this.isPentavalentVaccineChecked)) {
      return true;
    }
    if ((material.material_id == environment.static_ids.Pentavalent_Vaccine && this.isDptVaccineChecked)) {
      return true;
    }
    if (this.isDptVaccineChecked && material.material_id == environment.static_ids.DPT_BOOSTER) {
      const dptBstMat = this.allMaterailsData.find(e => e.material_id == environment.static_ids.DPT_BOOSTER);
      const dptData = this.uipVaccineByBenArr.filter(m => m.material_id === environment.static_ids.DPT_vaccine);
      if (!dptData.find(e => e.dose == 3)) {
        const selectMat = this.multiSelectionUIPVaccForm.get('materialDetails').value;
        const isAllDPTSelected = selectMat.find(e => e.material_id == environment.static_ids.DPT_vaccine && e.material_dose == 3);
        if (!this.showPreviousVaccination 
          && (!isAllDPTSelected || selectMat.find(e => e.material_id == environment.static_ids.DPT_vaccine))) {
          this.onMaterialCheckSelection(dptBstMat, { checked: false });
          this.duplicateVaccinesEligible.map(e => {
            if (e.material_id == environment.static_ids.DPT_BOOSTER) {
              e.checked = false;
              e.selected = false;
            }
          });
          return true;
        }
        if (!isAllDPTSelected && this.showPreviousVaccination) {
          this.onMaterialCheckSelection(dptBstMat, { checked: false });
          this.prevVaccinesByAge.map(e => {
            if (e.material_id == environment.static_ids.DPT_BOOSTER) {
              e.checked = false;
              e.selected = false;
            }
          });
          return true;
        }
      }
    }

    if (!this.showPreviousVaccination && this.isMMRChecked && material.material_id == environment.static_ids.MR) {
      return true;
    }

    if (material.material_id == environment.static_ids.MMR) {
      const MRdata = this.uipVaccineByBenArr.filter(m => m.material_id === environment.static_ids.MR);

      if (!MRdata.find(e => e.dose == 2)) {
        const mmrMat = this.allMaterailsData.find(e => e.material_id == environment.static_ids.MMR);
        const selectMat = this.multiSelectionUIPVaccForm.get('materialDetails').value;
        const isMRSelected = selectMat.find(e => e.material_id == environment.static_ids.MR && e.material_dose == 2);
        if (selectMat.find(e => e.material_id == environment.static_ids.MR) && !this.showPreviousVaccination) {
          this.onMaterialCheckSelection(mmrMat, { checked: false });
          this.duplicateVaccinesEligible.map(e => {
            if (e.material_id == environment.static_ids.MMR) {
              e.checked = false;
              e.selected = false;
            }
          });
          return true;
        } else if (isMRSelected && this.showPreviousVaccination) {
          this.onMaterialCheckSelection(mmrMat, { checked: false });
          this.prevVaccinesByAge.map(e => {
            if (e.material_id == environment.static_ids.MMR) {
              e.checked = false;
              e.selected = false;
            }
          });
          return true;
        }
      }else{
        return true;
      }

    }

    return this.uipVaccineByBenArr.find(m => {
      if (material.no_of_precaution_doses) {
        return m?.material_id == material?.material_id && m?.dose == material.no_of_precaution_doses && m.is_precaution
      }
      else if (m?.material_id == material?.material_id && m?.dose == material.no_of_doses) {
        return true;
      }
    });

  }

  validateTdBooster(materials) {
    const currentDate = moment()
    return materials.map(m => {
      if (this.verifiedData.previous_pregnancy_year && this.verifiedData.previous_pregnancy_year != '') {
        const arrVacc = !this.showPreviousVaccination ? this.duplicateVaccinesEligible : this.prevVaccinesByAge;
        // m.is_single_dose_vaccinated = arrVacc.find(ele => ele.material_id == environment.static_ids.TD_Vaccine && ele.dose_array.find(e => e.dose == 2 && e.doseReceivedDate)) ? false : true;
        const previousTdDate = moment(this.verifiedData.previous_pregnancy_year, 'YYYY')
        const diff = currentDate.diff(previousTdDate, 'years')

        if (m.material_id == environment.static_ids.TD_Booster) {
          const materialDoseInfo = this.allMaterailsData.find(e => e.material_id == m.material_id);
          const dayDuration = materialDoseInfo.dose_frequencies[0];
          const lmcDate = moment(this.verifiedData.lmc_date).startOf('day');
          const dayDiffOfLMC = moment().diff(lmcDate, 'days');
          m.is_single_dose_vaccinated = dayDuration > dayDiffOfLMC;
          m.is_eligible = !(dayDuration > dayDiffOfLMC);
        }
        if (m.material_id == environment.static_ids.TD_Vaccine) {
          // m.is_eligible = !m.is_single_dose_vaccinated;
          m.is_eligible = !m.is_single_dose_vaccinated;
        }
      } else if (!this.verifiedData.previous_pregnancy_year && this.verifiedData.is_pregnant_woman) {
        if (m.material_id == environment.static_ids.TD_Vaccine) {
          m.is_eligible = true;
        }
        if (m.material_id == environment.static_ids.TD_Booster) {
          m.is_eligible = false;
        }
      }
      return m;
    });
  }

  getMaxDoseDate(material, dose) {
    // let currentDate = moment();
    let currentDate = moment(this.verifiedData.date_of_session).subtract('days', 1);
    const materialDoseInfo = this.allMaterailsData.find(e => e.material_id == material.material_id);
    if (this.verifiedData.is_pregnant_woman) {
      if (moment(this.maxDoseDate).isAfter(currentDate)) {
        return currentDate;
      }
      return this.maxDoseDate;
    }

    if (dose.dose == 1 && !dose.is_precaution
      && (
        (this.isDptVaccineChecked && materialDoseInfo?.material_id != environment.static_ids.DPT_BOOSTER)
        || !this.isDptVaccineChecked)
      && (
        (this.isMRChecked && materialDoseInfo?.material_id != environment.static_ids.MMR)
        || !this.isMMRChecked)
    ) {
      let agelimit = materialDoseInfo.display_upper_age_limits[0];
      let maxDate = moment(this.minDoseDate).add(materialDoseInfo.upper_age_limits[0], 'days');
      if (maxDate <= currentDate || !agelimit || agelimit == 'nullD') {
        return maxDate
      } else {
        return this.maxDoseDate;
      }
      // return moment(this.minDoseDate).add(materialDoseInfo.upper_age_limits[0],'days').format('YYYY-MM-DD');
    }

    if (dose.is_precaution) {
      let agelimit = materialDoseInfo.display_upper_age_limits[dose.dose - 1];
      const maxAgeInDays = materialDoseInfo.precaution_upper_age_limits[dose.dose - 1];
      if (maxAgeInDays == null || !agelimit || agelimit == 'nullD') {
        return this.maxDoseDate;
      } else {
        let maxDate = moment(this.verifiedData.dob).add(maxAgeInDays, 'days');
        if (maxDate <= currentDate) {
          return maxDate
        } else {
          return this.maxDoseDate;
        }
      }
    } else {
      let agelimit = materialDoseInfo.display_upper_age_limits[dose.dose - 1];
      const maxAgeInDays = materialDoseInfo.upper_age_limits[dose.dose - 1];
      if (maxAgeInDays == null || !agelimit || agelimit == 'nullD') {
        return this.maxDoseDate;
      } else {
        let maxDate = moment(moment(this.verifiedData.dob).add(maxAgeInDays, 'days').format());
        if (maxDate <= currentDate) {
          return maxDate
        } else {
          return this.maxDoseDate;
        }
      }
    }
  }

  getMinDoseDate(material, dose) {
    const materialDoseInfo = this.allMaterailsData.find(e => e.material_id == material.material_id);
    let minDoseDate = moment(this.minDoseDate).format('YYYY-MM-DD');
    if (!this.verifiedData?.is_pregnant_woman && !this.verifiedData?.lmc_date
      && !dose.is_precaution && dose.dose == 1
      && (
        (this.isDptVaccineChecked && materialDoseInfo?.material_id != environment.static_ids.DPT_BOOSTER)
        || !this.isDptVaccineChecked)
      && (
        (this.isMRChecked && materialDoseInfo?.material_id != environment.static_ids.MMR)
        || !this.isMRChecked)
    ) {
      minDoseDate = moment(this.minDoseDate).add(materialDoseInfo.min_age, 'days').format('YYYY-MM-DD');
      return minDoseDate;
    }
    let lastDoseData = this.isLastDoseDateAdded(material, dose);
    let lastDoseDate = null;
    if (this.isDptVaccineChecked && materialDoseInfo?.material_id == environment.static_ids.DPT_BOOSTER) {
      const dptData = this.uipVaccineByBenArr.filter(m => m.material_id === environment.static_ids.DPT_vaccine);
      const dptMat = this.allMaterailsData.find(e => e.material_id == environment.static_ids.DPT_vaccine);
      lastDoseData = dptData.find(e => e.dose == 3);
      if (!dptData.find(e => e.dose == 3)) {
        const selectMat = this.multiSelectionUIPVaccForm.get('materialDetails').value;
        lastDoseData = selectMat.find(e => e.material_id == environment.static_ids.DPT_vaccine && e.material_dose == 3);
      }
      if (lastDoseData) {
        lastDoseDate = lastDoseData?.vaccination_date ? lastDoseData?.vaccination_date : lastDoseData?.prev_vaccination_date;
      }
      if (lastDoseData && !lastDoseDate) {
        lastDoseData.prev_vaccination_date = this.getMinDoseDate(dptMat, { dose: 3 });
      }
    }
    if (this.isMRChecked && materialDoseInfo?.material_id == environment.static_ids.MMR) {
      const mrData = this.uipVaccineByBenArr.filter(m => m.material_id === environment.static_ids.MR);
      let mrMat = this.allMaterailsData.find(e => e.material_id == environment.static_ids.MR);
      lastDoseData = mrData.find(e => e.dose == 1);
      if (!mrData.find(e => e.dose == 1)) {
        const selectMat = this.multiSelectionUIPVaccForm.get('materialDetails').value;
        lastDoseData = selectMat.find(e => e.material_id == environment.static_ids.MR && e.material_dose == 1);
      }
      lastDoseDate = lastDoseData?.vaccination_date ? lastDoseData?.vaccination_date : lastDoseData?.prev_vaccination_date;
      if (lastDoseData && !lastDoseDate) {
        lastDoseData.prev_vaccination_date = this.getMinDoseDate(mrMat, { dose: 1 });
      }
    }
    if (lastDoseData) {
      lastDoseDate = lastDoseData.vaccination_date ? lastDoseData.vaccination_date : lastDoseData.prev_vaccination_date;
    }
    if (lastDoseData && lastDoseDate) {
      const lastDoseDate = lastDoseData.vaccination_date ? lastDoseData.vaccination_date : lastDoseData.prev_vaccination_date;
      let dayDuration; let lowerAgeDuration;
      if (dose.is_precaution) {
        dayDuration = materialDoseInfo.precaution_dose_frequencies[dose.dose - 1];
        lowerAgeDuration = materialDoseInfo.precaution_lower_age_limits[dose.dose - 1];
      } else {
        dayDuration = materialDoseInfo.dose_frequencies[dose.dose - 1];
        lowerAgeDuration = materialDoseInfo.lower_age_limits[dose.dose - 1];
      }
      if (this.isDptVaccineChecked && materialDoseInfo?.material_id == environment.static_ids.DPT_BOOSTER) {
        dayDuration = materialDoseInfo.dose_frequencies[1];
      }
      if (this.isMRChecked && materialDoseInfo?.material_id == environment.static_ids.MMR) {
        let mrMat = this.allMaterailsData.find(e => e.material_id == environment.static_ids.MR);
        dayDuration = mrMat.dose_frequencies[1];
      }
      const lastVaccinatedDate = moment(lastDoseDate).startOf('day');
      const mData = lastVaccinatedDate.add(dayDuration, 'days');
      const lowerAgeLimitOfDose = moment(this.minDoseDate).add(lowerAgeDuration, 'days');

      if (lowerAgeLimitOfDose.isBefore(mData) || materialDoseInfo.material_id == environment.static_ids.TD_Vaccine) {

        return mData.format('YYYY-MM-DD');
      } else {
        return lowerAgeLimitOfDose.format('YYYY-MM-DD');
      }
      // return lastVaccinatedDate.add(dayDuration, 'days').format('YYYY-MM-DD');
    } else {
      var sum = 0;
      var latestValue;
      if(this.isAnypreviousDoseDateAdded(material, dose) && this.isAnypreviousDoseDateAdded(material, dose).length > 0){
        latestValue = this.isAnypreviousDoseDateAdded(material, dose).slice(-1)
        for(let i=latestValue[0].material_dose;i< dose.dose;i++){
          sum = sum + materialDoseInfo.dose_frequencies[i]
        }
        
        if (dose.is_precaution) {
          if(!latestValue[0].is_precaution){
            const lastDoseValue = this.getMinDoseDate(material,{dose:material.no_of_doses})
            
            for(let i=0;i<material.no_of_precaution_doses;i++){
              sum = sum + materialDoseInfo.precaution_dose_frequencies[i]
            }
            const lastVaccinatedDate = moment(lastDoseValue).startOf('day');
            return lastVaccinatedDate.add(sum, 'days').format('YYYY-MM-DD');
          }else{
            for(let i=latestValue[0].material_dose;i<material.no_of_precaution_doses;i++){
              sum = sum + materialDoseInfo.precaution_dose_frequencies[i]
            }
          }
          // 
          }
        const vaccinatedDate = latestValue[0].prev_vaccination_date || latestValue[0].vaccination_date
        const lastVaccinatedDate = moment(vaccinatedDate).startOf('day');
      return lastVaccinatedDate.add(sum, 'days').format('YYYY-MM-DD');
      }
      if (materialDoseInfo?.material_id != environment.static_ids.TD_Booster) {

        if (materialDoseInfo.lower_age_limits[dose.dose - 1]) {
          sum = materialDoseInfo.lower_age_limits[dose.dose - 1];
        }
        if (dose.is_precaution && materialDoseInfo.precaution_display_lower_age_limits[dose.dose - 1]) {
          sum = materialDoseInfo.precaution_lower_age_limits[dose.dose - 1];
        }
      }
      if(materialDoseInfo?.material_id == environment.static_ids.TD_Booster){
        sum = materialDoseInfo.dose_frequencies[0];
      }

      const lastVaccinatedDate = moment(minDoseDate).startOf('day');
      return lastVaccinatedDate.add(sum, 'days').format('YYYY-MM-DD');
    }
  }

  isCheckMinAgeIsGreaterThanPrevDoseFrq() {

  }

  onTabChange(event) {
    if (event == '1') {
      this.addPrevVaccination();
    } else {
      this.loadCurrentVaccines();
    }
  }

  disableBySpecificCase(material, dose?) {
    if (material.material_id == environment.static_ids.MR && dose && dose.dose == 2) {
      const MRdata = this.uipVaccineByBenArr.find(m => m.material_id === environment.static_ids.MMR);
      if (!MRdata) {
        const selectMat = this.multiSelectionUIPVaccForm.get('materialDetails').value;
        const isMMRSelected = selectMat.find(e => e.material_id == environment.static_ids.MMR);
        if (isMMRSelected) { return true; }
      } else {
        return true;
      }
    } else if (this.isMRChecked && material.material_id == environment.static_ids.MMR) {
      let mmrMat = this.allMaterailsData.find(e => e.material_id == environment.static_ids.MMR);
      let minDoseDate = moment(this.getMinDoseDate(mmrMat, { dose: 1 })).endOf('day');
      if (moment(minDoseDate).isAfter(moment())) {
        this.onMaterialCheckSelection(mmrMat, { checked: false });
        this.prevVaccinesByAge.map(e => {
          if (e.material_id == environment.static_ids.MMR) {
            e.checked = false;
            e.selected = false;
          }
        });
        return true;
      }
    } else if (this.isDptVaccineChecked && material.material_id == environment.static_ids.DPT_BOOSTER) {
      let dptBMat = this.allMaterailsData.find(e => e.material_id == environment.static_ids.DPT_BOOSTER);
      let minDoseDate = moment(this.getMinDoseDate(dptBMat, { dose: 1 })).endOf('day');
      if (moment(minDoseDate).isAfter(moment())) {
        this.onMaterialCheckSelection(dptBMat, { checked: false });
        this.prevVaccinesByAge.map(e => {
          if (e.material_id == environment.static_ids.DPT_BOOSTER) {
            e.checked = false;
            e.selected = false;
          }
        });
        return true;
      }
    } else if (this.verifiedData?.is_pregnant_woman) {
      return !material.is_eligible;
    }
    return false;
  }

  selectAllEligibleDoses(event){
    console.log('#############', event);
    console.log(this.duplicateVaccinesEligible);
    this.selectAllChecked = event.checked;
    // if(event.checked){
      this.duplicateVaccinesEligible.forEach(v=>{
        if(v.is_eligible){
          if(v.no_of_doses > 1){ 
            v.dose_array.forEach(d=>{
                if(d.isEligible && !d.doseReceivedDate && !d.isVaccinatedOutside && this.isDoseEligibleForCurrentAge(v,d)){
                  this.showVaccineInfoByBenf(v,event.checked);
                  this.onselectedDoseEvent(v,d,{value: d.dose});
                }
            })
          }else{
            this.showVaccineInfoByBenf(v,event.checked);
          }
        }else{
          v.selected = false;
          v.checked = false;
        }
      });
    // }
  }

  setCurrLanguage(){
    if(environment.i18_enabled){ 
        this.currLang = this.i18nService.language;
        this.translate.onLangChange.subscribe( (r: any) => {
          this.currLang = this.translate.currentLang;
          this.titleService.setTitle(this.translate.instant('TITLE'));
        });
      }
    }

  ngOnDestroy(): void {
    this.unSubscribe();
  }
}
