<ng-container *ngIf="!showRecordPreviousVaccination">
  <!-- Vaccination Detail  -- {{materialType}} -->
  <div *ngIf="!declareDeath && !isDataLoading">
    <ion-button *ngIf="!isPregnancyOutcomeDone() && isCurrentVaccinationTaken()" expand="full" (click)="downloadCertificate()"
      [disabled]="fileDownloadInProgress" type="button" class="primaryBtn noDesktop downloadBtn" shape="round"
      color="primary" fill="outline">
      <ion-spinner name="lines-small" *ngIf="fileDownloadInProgress"></ion-spinner>
      <ion-icon slot="start" *ngIf="!fileDownloadInProgress" class="custom-download-slim"></ion-icon>{{'Download Certificate' | translate}}
    </ion-button>
    <ion-button expand="full" *ngIf="!lmcDate && userRole !== 'ASHA'" (click)="recordPreviousVaccinationExistingBen()" type="button"
      class="primaryBtn noDesktop downloadBtn" shape="round" color="primary" fill="outline">
      {{(isCurrentVaccinationDone() ? ('Record Previous Vaccination' | translate): ('Update Previous Vaccination' | translate))}}
    </ion-button>
    <ng-container *ngIf="careContext && userRole !== 'ASHA'">
          <ion-button expand="full" *ngIf="env.isChildABHAenabled && env.isM2Enabled && benData.health_id && isCurrentVaccinationTaken() && careContext?.linkStatus" (click)="linkCareContext()"
            [disabled]="fileDownloadInProgress" type="button" class="primaryBtn noDesktop" shape="round" color="primary"
            fill="outline">
            {{'Link Care Context' | translate}}
          </ion-button>
        </ng-container>
  </div>

  <form [formGroup]='vaccinationDetailsForm' class="not-required">
    <ion-grid class="noDesktop statusIcons" *ngIf="!showRecordPreviousVaccination">
      <ion-card class="detailsCard" *ngFor="let vc of vaccinationData">
        <ion-item>
          <ion-label class="ion-padding-vertical">{{'VACCINE / DOSE' | translate}}</ion-label>
          <ion-note class="ion-padding-vertical" slot="end">{{vc.Vaccine}}</ion-note>
        </ion-item>
        <ion-item>
          <ion-label class="ion-padding-vertical">{{'Vaccination Date' | translate}}</ion-label>
          <ion-note class="ion-padding-vertical" slot="end">{{formatVaccinationDate(vc.vaccination_date)}}</ion-note>
        </ion-item>
        <ion-item>
          <ion-label class="ion-padding-vertical">{{'Due Date' | translate}}</ion-label>
          <ion-note class="ion-padding-vertical" slot="end">{{vc.due_date}}</ion-note>
        </ion-item>
        <ion-item *ngIf="!benData.is_pregnant_woman">
          <ion-label class="ion-padding-vertical">{{'Age' | translate}}</ion-label>
          <ion-note class="ion-padding-vertical venue" slot="end" [innerHTML]="vc.displayAge"></ion-note>
        </ion-item>
        <ion-item>
          <ion-label class="ion-padding-vertical">{{'Status' | translate}}</ion-label>
          <ion-note class="ion-padding-vertical stringCaptalize" slot="end">
            <span [ngClass]="{
                 'completed': vc.vaccination_status == 'completed',
                 'completedHistory': vc['is_past_dated'] == true,
                 'upcoming | translate': vc.vaccination_status == 'upcoming',
                 'pending': vc.vaccination_status == 'pending' || vc.vaccination_status == 'eligible',
                 'rejected': vc.vaccination_status == 'rejected'}">
              <ion-icon [ngClass]="{
                  'custom-check': vc.vaccination_status == 'completed',
                  'custom-pregnancy-rejected': vc.vaccination_status == 'rejected',
                  'custom-iconTimerDue': vc.vaccination_status == 'pending' || vc.vaccination_status == 'eligible',
                  'custom-iconTimer': vc.vaccination_status == 'upcoming'}"></ion-icon>
            </span>{{vc['is_past_dated'] == true ? 'Completed based on History/Records' : vc['msg']}}
          </ion-note>
        </ion-item>
      </ion-card>
      <ion-row class="ion-no-padding" *ngIf="vaccinationData.length > 0">
        <ion-col size-lg="12" class="ion-text-end">
          <ion-button expand="full" *ngIf="!(pregnanacyService.enableNewBornTab | async) && recordPrev"
            (click)="this.showRecordPreviousVaccination = true;recordPreviousVaccination()" class="primaryBtn"
            shape="round" color="primary" fill="outline">{{('Record Previous Vaccination') | translate}}</ion-button>

        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-grid class="noDesktop statusIcons" *ngIf="showRecordPreviousVaccination">
      <ion-card class="ion-padding-horizontal detailsCard" *ngFor="let vc of vaccinationData">
        <ion-item>
          <ion-label class="ion-padding-vertical">{{'VACCINE / DOSE' | translate}}</ion-label>
          <ion-note class="ion-padding-vertical" slot="end">{{vc.Vaccine}}</ion-note>
        </ion-item>
        <ion-item>
          <ion-label class="ion-padding-vertical">{{'Vaccination Date' | translate}}</ion-label>
          <ion-note class="ion-padding-vertical" slot="end">{{vc.vaccination_date}}</ion-note>
        </ion-item>
      </ion-card>
    </ion-grid>

    <!--
  START DESKTOP VIEW
  -->
    <ng-container *ngIf="isDataLoading">
      <div class="ion-margin customTable noMobile statusIcons" *ngIf="!showRecordPreviousVaccination"
        [ngClass]="routeAnimationsElements && declareDeath ? 'afterDeath' : ' '">
        <table mat-table matSort [dataSource]="dataSourceList" #table multiTemplateDataRows
          class="surveyor-report-table">
          <ng-container matColumnDef="{{column.fieldId}}" *ngFor="let column of columnsToDisplay">
            <ng-container *ngIf="column.enableSort == false">
              <th mat-header-cell *matHeaderCellDef>
                <ion-skeleton-text animated> {{column.fieldName | translate}}</ion-skeleton-text>
              </th>
            </ng-container>
            <ng-container *ngIf="column.enableSort == true">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                <ion-skeleton-text animated> {{column.fieldName | translate}}</ion-skeleton-text>
              </th>
            </ng-container>
            <ng-container *ngIf="column.fieldType == 'string'">
              <td mat-cell class="capsname" *matCellDef="let element">
                <ion-skeleton-text animated></ion-skeleton-text>
            </ng-container>
            <ng-container *ngIf="column.fieldType == 'displayAge'">
              <td mat-cell *matCellDef="let element" style="width:116px;">
                <ion-skeleton-text animated></ion-skeleton-text>
              </td>
            </ng-container>
            <ng-container *ngIf="column.fieldType == 'date'">
              <td mat-cell *matCellDef="let element">
                <ion-skeleton-text animated></ion-skeleton-text>
              </td>
            </ng-container>
            <ng-container *ngIf="column.fieldType == 'displayAge'">
              <td mat-cell *matCellDef="let element" style="width:116px;">
                <ion-skeleton-text animated></ion-skeleton-text>
              </td>
            </ng-container>
            <ng-container *ngIf="column.fieldType == 'vaccination_status'">
              <td mat-cell *matCellDef="let element">
                <ion-skeleton-text animated></ion-skeleton-text>
              </td>
            </ng-container>

          </ng-container>
          <ng-container matColumnDef="nodata">
            <td mat-footer-row *matFooterCellDef [colSpan]="displayedColumns.length" style="text-align: center">
              {{'No record(s) found!' | translate}}
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>

          <tr mat-row *matRowDef="let element; columns: displayedColumns;"
            [ngStyle]="{'background-color':element?.aefi_incident_id ? '#e0e0e0': ''}">
          </tr>
        </table>
      </div>
    </ng-container>
    <ng-container *ngIf="!isDataLoading">
      <ion-row class="ion-no-padding" *ngIf="vaccinationData.length > 0">
        <ion-col size-lg="12" class="ion-text-end noMobile ion-padding-horizontal">
          <ion-button (click)="this.showRecordPreviousVaccination = true;recordPreviousVaccination()" class="primaryBtn"
            shape="round" color="primary"
            *ngIf="lmcDate && !(pregnanacyService.enableNewBornTab | async) && recordPrev && !isPregnancyOutcomeDone()"
            fill="outline">{{('Record Previous Vaccination') | translate}}</ion-button>
          <ion-button class="primaryBtn"
            *ngIf="(!recordPrev && (benData?.beneficiary_type_name !== 'Citizen' || benData?.is_pregnant_woman)) && (!isNewBornChild() && userRole !== 'ASHA') || (benData?.beneficiary_type_name == 'Citizen' && calAgeByDob() >=10 && calAgeByDob() < 17)"
            (click)="recordPreviousVaccinationExistingBen()" shape="round" color="primary" fill="outline">
            {{(isCurrentVaccinationDone() ? ('Record Previous Vaccination' | translate): ('Update Previous Vaccination' | translate))}}
          </ion-button>    
          <ng-container *ngIf="careContext && userRole !== 'ASHA'">
          <ion-button *ngIf="env.isChildABHAenabled && env.isM2Enabled && benData.health_id && isCurrentVaccinationTaken() && careContext?.linkStatus" (click)="linkCareContext()"
            [disabled]="fileDownloadInProgress" type="button" class="primaryBtn" shape="round" color="primary"
            fill="outline">
            {{'Link Care Context' | translate}}
          </ion-button>
        </ng-container>
          <ion-button *ngIf="!isPregnancyOutcomeDone() && isCurrentVaccinationTaken()" (click)="downloadCertificate()"
            [disabled]="fileDownloadInProgress" type="button" class="primaryBtn" shape="round" color="primary"
            fill="outline">
            <ion-spinner name="lines-small" *ngIf="fileDownloadInProgress"></ion-spinner>
            <ion-icon slot="start" *ngIf="!fileDownloadInProgress" class="custom-download-slim"></ion-icon>{{'Download Certificate' | translate}}
          </ion-button>
        </ion-col>
      </ion-row>
      <div class="ion-margin customTable noMobile statusIcons" [ngClass]="declareDeath ? 'afterDeath' : ' '">
        <table mat-table matSort [dataSource]="dataSourceList" #table multiTemplateDataRows
          class="surveyor-report-table">
          <ng-container matColumnDef="{{column.fieldId}}" *ngFor="let column of columnsToDisplay">
            <ng-container *ngIf="column.enableSort == false">
              <th mat-header-cell *matHeaderCellDef>
                {{column.fieldName | translate}}
              </th>
            </ng-container>
            <ng-container *ngIf="column.enableSort == true">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                {{column.fieldName | translate}}
              </th>
            </ng-container>
            <ng-container *ngIf="column.fieldType == 'string'">
              <td mat-cell class="capsname" *matCellDef="let element">
                <span [innerHTML]="element[column.fieldId]"></span>
              </td>
            </ng-container>
            <ng-container *ngIf="column.fieldType == 'dose'">
              <td mat-cell class="capsname" *matCellDef="let element">
                <span *ngIf="element.material_name == 'Oral Polio Vaccine (OPV)'">
                  {{element.dose - 1}}
                </span>
                <span *ngIf="element.material_name != 'Oral Polio Vaccine (OPV)'">
                  {{element[column.fieldId]}}
                </span>
              </td>
            </ng-container>
            <ng-container *ngIf="column.fieldType == 'date'">
              <td mat-cell *matCellDef="let element">
                {{element[column.fieldId] == 'N/A' ? 'N/A' : element[column.fieldId] | displayDateFormat}}
              </td>
            </ng-container>
            <ng-container *ngIf="column.fieldType == 'displayAge'">
              <td mat-cell *matCellDef="let element" style="width:116px;">
                <span [innerHTML]="element[column.fieldId]"></span>
              </td>
            </ng-container>
            <ng-container *ngIf="column.fieldType == 'vaccination_status'">
              <td mat-cell *matCellDef="let element" class="stringCaptalize">
                <span [ngClass]="{
                 'completed': element[column.fieldType] == 'completed',
                 'completedHistory': element['is_past_dated'] == true,
                 'upcoming | translate': element[column.fieldType] == 'upcoming',
                 'pending': element[column.fieldType] == 'pending' || element[column.fieldType] == 'eligible',
                 'rejected': element[column.fieldType] == 'rejected'}">
                  <ion-icon [ngClass]="{
                      'custom-check': element[column.fieldType] == 'completed',
                      'custom-iconTimer': element[column.fieldType] == 'upcoming',
                      'custom-iconTimerDue': element[column.fieldType] == 'pending' || element[column.fieldType] == 'eligible',
                      'custom-pregnancy-rejected': element[column.fieldType] == 'rejected'}"></ion-icon>
                </span>{{element['is_past_dated'] == true ? 'Completed based on History/Records' : element['msg']}}
              </td>
            </ng-container>
          </ng-container>
          <ng-container matColumnDef="nodata">
            <td mat-footer-row *matFooterCellDef [colSpan]="displayedColumns.length" style="text-align: center">
              {{'No record(s) found!' | translate}}
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let element; columns: displayedColumns;"
            [ngStyle]="{'background-color':element?.aefi_incident_id ? '#e0e0e0': ''}">
          </tr>
        </table>
      </div>
    </ng-container>
    <div *ngIf="vaccinationData.length == 0" style="text-align: center"> {{'No record(s) found!' | translate}}</div>
  </form>

  <!--
  END DESKTOP VIEW
  -->
</ng-container>
<ng-container *ngIf="showRecordPreviousVaccination">
  <ion-row class="ion-no-padding">
    <ion-col size-lg="4">
      <ion-button (click)="onBack()" class="primaryBtn" shape="round" color="primary" fill="outline">{{'Back' |
        translate}}</ion-button>
    </ion-col>
  </ion-row>
  <ng-container *ngIf="util.getPlatformWidth() >= 575">

    <ion-row class="grid-header">
      <ion-col size="6">
        <h2>{{'Vaccination Name' | translate}}</h2>
      </ion-col>
      <ion-col size="3">
        <h2>{{'Is Vaccination Given?' | translate}}</h2>
      </ion-col>
      <ion-col size="3">
        <h2>{{'Vaccination Date' | translate}}</h2>
      </ion-col>
    </ion-row>
    <ion-row class="grid-content" *ngFor="let material of PreviouslyTookvaccines;let i = index;">
      <ion-col size="6">
        <h4>{{material.Vaccine}}</h4>
      </ion-col>
      <ion-col size="3">
        <mat-checkbox color="primary" (click)="showVaccineInfoByBenf(material)" [ngClass]="{'active': material.checked}"></mat-checkbox>
      </ion-col>
      <ion-col size="3">
        <!-- <ion-item class=item-border> -->
        <mat-form-field *ngIf="material.checked" appearance="outline" class="matDropdown ion-margin-vertical">
          <input readonly matInput [matDatepicker]="dp" [max]="getMaxDoseDate()"
            [min]="getMinDoseDate(material,material.dose_interval_id)"
            (dateChange)="onselectedDoseDateEvent(material, $event)">
          <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
          <mat-datepicker #dp></mat-datepicker>
        </mat-form-field>
        <!-- </ion-item> -->
      </ion-col>
    </ion-row>
    <ion-row *ngIf="PreviouslyTookvaccines.length == 0">
      <ion-col class="ion-text-center">
        <h4>{{'No records Found' | translate}}</h4>
      </ion-col>
    </ion-row>
  </ng-container>
  <ng-container *ngIf="util.getPlatformWidth() < 575">
    <ion-card class="inputFields noDesktop" *ngFor="let material of PreviouslyTookvaccines;let i = index;">
      <ion-card-content>
        <ion-row>
          <ion-col size="12">
            <ion-label>{{'Vaccination Name' | translate}}</ion-label>
            <ion-text>{{material.Vaccine}}</ion-text>
          </ion-col>
        </ion-row><ion-row>
          <ion-col size="12" class="ion-margin-vertical">
            <mat-checkbox (click)="showVaccineInfoByBenf(material)"
              [ngClass]="{'active': material.checked}"></mat-checkbox>
            <ion-label class="ion-padding-start">{{'Is Vaccination Given?' | translate}}</ion-label>
          </ion-col>
          <ion-col size="12" *ngIf="material.checked">
            <ion-label>
              {{'Vaccination Date' | translate}}
            </ion-label>
            <mat-form-field appearance="outline" class="matDropdown ion-margin-vertical">
              <input readonly matInput [matDatepicker]="dp" [max]="getMaxDoseDate()"
                [min]="getMinDoseDate(material,material.dose_interval_id)"
                (dateChange)="onselectedDoseDateEvent(material, $event)">
              <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
              <mat-datepicker #dp></mat-datepicker>
            </mat-form-field>
          </ion-col>
        </ion-row>
      </ion-card-content>
    </ion-card>
    <ion-row *ngIf="PreviouslyTookvaccines.length == 0">
      <ion-col class="ion-text-center">
        <h4>{{'No records Found' | translate}}</h4>
      </ion-col>
    </ion-row>
  </ng-container>
  <ion-row *ngIf="PreviouslyTookvaccines.length > 0">
    <ion-col>
      <ion-button [disabled]="isSaveEnabled()" (click)="updatePreviousVaccination()">{{'Submit' |
        translate}}</ion-button>
    </ion-col>
  </ion-row>
</ng-container>