import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EncDecService } from 'src/app/modules/shared/services/encryption-decryption.service';
import { environment } from 'src/environments/environment';
import { AbhaComponent } from '../../recipient/components/abha/abha.component';
import { UtilsService } from 'src/app/modules/shared/services/utils.service';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { DataService } from 'src/app/modules/shared/services';
import { LocalStorageService } from 'src/app/modules/core/services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class NewBeneficiaryService {
  
  public parentDetailsPassSubject: BehaviorSubject<any>
  public parentDetailsData: Observable<any>
  constructor(private http: HttpClient, private encDecService: EncDecService, public router: Router,
    public modalController: ModalController, private dialog: MatDialog, public util: UtilsService,public dataService:DataService,public localStorage:LocalStorageService){
    this.parentDetailsPassSubject = new BehaviorSubject<any>(null);
    this.parentDetailsData = this.parentDetailsPassSubject.asObservable();
  }

  onSpotRegistration(payload: any): Observable<any> {
   
    if (window.navigator.onLine) {
       payload.set('photo_id_number',this.encDecService.setWithSpace(payload.get('photo_id_number')))
       payload.set('photo_id_type',this.encDecService.setWithSpace(payload.get('photo_id_type')))
       payload.set('mobile_number',this.encDecService.setWithSpace(payload.get('mobile_number')))
       payload.set('district_id',this.encDecService.setWithSpace(payload.get('district_id')))
       payload.set('state_id',this.encDecService.setWithSpace(payload.get('state_id')))
       if(payload.get('facility_id')){
        payload.set('facility_id',this.encDecService.setWithSpace(payload.get('facility_id')))
       }
       payload.set('beneficiary_type_category_id',this.encDecService.setWithSpace(payload.get('beneficiary_type_category_id')))
       payload.set('ben_type_id',this.encDecService.setWithSpace(payload.get('ben_type_id')))
       if(this.dataService.userDetails.role == environment.static_ids.BothVaccinatorAndDeliveryManager){
       payload.append('sub_center_id',this.localStorage.getItem('sub_center_id'))
       }
      return this.http.post<any>(
        `${environment.vaccinator_url}/beneficiary/registration`,
        payload
      );
    }
  }
  async openABHADialog(regResult) {
    // this.closeDialog();
    if (this.util.getPlatformWidth() > 575) {
      const dialogRef = this.dialog.open(AbhaComponent, {
        height: "auto",
        width: "500px",
        hasBackdrop: true,
        disableClose: false,
        data: { ben_data: regResult, isFromRegistration: true },
      })
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.router.navigate([`/dashboard`])
        }
      });
    }
    else {


      // console.log(benfData,"resting follow")
      //  this.addBenTOUWIN(benfData)
      const modal = await this.modalController.create({
        component: AbhaComponent,
        cssClass: 'create-abha-modal',
        componentProps: {
          isFromRegistration: true,
          userData: regResult,

        }
      });
      await modal.present();
      const { data } = await modal.onWillDismiss();
      if (data.dismis) {
        this.router.navigate([`/dashboard`])

      }
    }
  }

  getBeneficiariesListById(payload: any): Observable<any> {
    if (window.navigator.onLine) {
      return this.http.post<any>(
        `${environment.vaccinator_url}/getBeneficiaryByVaccinatorId`,
        payload
      ).pipe(map((b:any)=>{
        b.map(benf=>{
          this.encDecService.decryptPhotoId(benf);
          this.encDecService.decryptMobileNo(benf);
        });
        return b;
      }));;
    }
  }
}  
