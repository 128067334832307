import { AfterContentInit, ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UtilsService } from 'src/app/modules/shared/services/utils.service';
import { AbhaService } from 'src/app/screens/create-abha/services/abha.service';
import { BeneficiaryPage } from '../../beneficiary.page';
import { Subscription, switchMap, takeWhile, tap, timer } from 'rxjs';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AbhaConsentComponent } from 'src/app/modules/shared/components/abha-consent/abha-consent.component';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-link-hip-care-context',
  templateUrl: './link-hip-care-context.component.html',
  styleUrls: ['./link-hip-care-context.component.scss'],
})
export class LinkHipCareContextComponent  implements OnInit, AfterContentInit {
  inProgress = false;
  hipForm = new FormGroup({
    phrAddress: new FormControl(null),
    authMode: new FormControl('Demographic'),
  });
  otpForm = new FormGroup({
    otp: new FormControl('', Validators.required),
  });
  demoauthForm = new FormGroup({
    concent: new FormControl(null, Validators.requiredTrue),
  });
  @Input() benData: any = {};
  formSubmitted = false;
  otpSent = false;
  timer: number;
  otpResSubscr: Subscription;
  otpVerResSubscr: Subscription;
  careContxt: Subscription;
  transactionId = null;
  iterationLimitReached = 0;
  otpVerified = false;
  linkingSuccess = false;
  isDemoGraphic = false;
  authMode = null;
  constructor(
    private abhaService: AbhaService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<BeneficiaryPage>,
    private util: UtilsService,
    private cdr: ChangeDetectorRef,
    private _bottomSheet: MatBottomSheet,
    private modalCtrl: ModalController
  ) { 
    if(data && data?.ben_data){ 
      this.benData = data?.ben_data;
    }
  }

  ngAfterContentInit(): void {
    this.hipForm.get('phrAddress').setValue(this.benData.phr_address);
    this.hipForm.get('phrAddress').disable();
    this.sendLinkingOTP();
      
  }

  ngOnInit() {
  }

  sendLinkingOTP(){
    this.isDemoGraphic = false;
    this.otpVerified = false;
    this.formSubmitted = true;
    console.log('@@@@@sendLinkingOTP@@@@@@@@@', this.util.findInvalidControls(this.hipForm));
    if(!this.hipForm.valid){return false;}
    const formData = this.hipForm.getRawValue();
    let payload = {
      phrAddress: formData.phrAddress,
      authMode: formData.authMode == 'Demographic' ? 'DEMOGRAPHICS': 'MOBILE_OTP'
    }
    this.authMode = payload.authMode;
    this.inProgress = true;
    this.abhaService.getLinkingOTP(payload).subscribe(res=>{
      console.log('##########sendLinkingOTP###############', res);
      if(res.requestId){
        this.iterationLimitReached = 0;
        this.otpResSubscr = timer(0, 5000).pipe(
          switchMap(() => this.abhaService.getLinkingOTPResponse({requestId: res.requestId}))
        ).subscribe(result =>{
          if(!result.waiting){ 
          console.log('##########sendLinkingOTP###############', result);
          }
          this.iterationLimitReached++;
          if(result.transactionId){
            this.transactionId = result.transactionId;
            this.inProgress = false;
            this.otpResSubscr.unsubscribe();
            this.iterationLimitReached = 0;
            if(payload.authMode == 'MOBILE_OTP'){ 
              this.otpSent = true;
              this.otpForm.reset();
              this.startTimer();
              
            }else if (payload.authMode == 'DEMOGRAPHICS'){
              this.isDemoGraphic = true;
            }
          }else if(result.error){
            this.inProgress = false;
            this.util.presentToastError(result?.error?.error?.message || result?.error?.message);
            this.otpResSubscr.unsubscribe();
            this.close;
          }else if(this.iterationLimitReached == 10){
            this.inProgress = false;
            this.otpResSubscr.unsubscribe();
            this.util.presentToastInfo("Please Try After Some time. ABDM Gateway not responding!");
            this.close();
          }
        });
      }
    })
  }

  startTimer() {
    let counter = this.abhaService.otpTimer;
    this.timer = this.abhaService.otpTimer;
    timer(1000, 1000)
      .pipe(
        takeWhile(() => counter > 0),
        tap(() => counter--)
      )
      .subscribe(() => { this.timer = counter; this.cdr.detectChanges()});
  }

  close(){  
    if(this.util.getPlatformWidth() >= 575){
      this.dialogRef.close();
    }else{
      this.modalCtrl.dismiss(true);
    }
  }

  verifyLinkingByDemographic(){
    this.formSubmitted = true;
    console.log('@@@@@verifyLinkingOTP@@@@@@@@@', this.util.findInvalidControls(this.hipForm));
    if(!this.demoauthForm.get('concent').value){
      this.util.presentToastError('Please select the consent');
      return;
    }
    if(!this.demoauthForm.valid){return false;}
    let payload = {
      transactionId: this.transactionId,
      referenceId: this.benData.beneficiary_reference_id,
    }
    this.iterationLimitReached = 0;
    this.inProgress = true;
    this.abhaService.verifyLinkingByDemographic(payload).subscribe(res=>{
      console.log('#########verifyLinkingOTP################', res);
      if(res.requestId){
        this.otpVerResSubscr = timer(0, 5000).pipe(
          switchMap(() => this.abhaService.verifyLinkingOTPResponse({requestId: res.requestId}))
        ).subscribe(result =>{
          console.log('#########onCareContextRes#########', result);
          this.iterationLimitReached ++;
          if(!result.waiting && result.response){  
            this.otpVerResSubscr.unsubscribe();
            this.otpVerified = true;
            this.onCareContextRes(result.response.requestId);
          }else if(result.error){
            this.inProgress = false;
            console.log('#########onCareContextRes#########', result.error);
            this.util.presentToastError(result?.error?.error?.message || result?.error?.message);
            this.otpVerResSubscr.unsubscribe();
            this.close();
          }else if(this.iterationLimitReached == 10){
            this.inProgress = false;
            this.otpVerResSubscr.unsubscribe();
            this.util.presentToastInfo("Please Try After Some time. ABDM Gateway not responding!");
            this.close();
          }
        });
      }
    })
  }
  
  verifyLinkingOTP(){
    this.formSubmitted = true;
    console.log('@@@@@verifyLinkingOTP@@@@@@@@@', this.util.findInvalidControls(this.hipForm));
    if(!this.hipForm.valid){return false;}
    const formData = this.otpForm.getRawValue();
    let payload = {
      transactionId: this.transactionId,
      otp: formData.otp,
      referenceId: this.benData.beneficiary_reference_id,
    }
    this.iterationLimitReached = 0;
    this.inProgress = true;
    this.abhaService.verifyLinkingOTP(payload).subscribe(res=>{
      console.log('#########verifyLinkingOTP################', res);
      if(res.requestId){
        this.otpVerResSubscr = timer(0, 5000).pipe(
          switchMap(() => this.abhaService.verifyLinkingOTPResponse({requestId: res.requestId}))
        ).subscribe(result =>{
          console.log('#########onCareContextRes#########', result);
          this.iterationLimitReached ++;
          if(!result.waiting && result.response){  
            this.otpVerResSubscr.unsubscribe();
            this.otpVerified = true;
            this.onCareContextRes(result.response.requestId);
          }else if(result.error){
            this.inProgress = false;
            console.log('#########onCareContextRes#########', result.error);
            this.util.presentToastError(result?.error?.error?.message || result?.error?.message);
            this.otpVerResSubscr.unsubscribe();
            this.close();
          }else if(this.iterationLimitReached == 10){
            this.inProgress = false;
            this.otpVerResSubscr.unsubscribe();
            this.util.presentToastInfo("Please Try After Some time. ABDM Gateway not responding!");
            this.close();
          }
        });
      }
    })
  }

  initCareContext(request){
    this.linkingSuccess = false;
    this.abhaService.initAddCareContextRes(request).subscribe(result =>{
      console.log('############initCareContext###################')
      this.onCareContextRes(result.requestId);
    });
  }

  onCareContextRes(requestId){
    this.iterationLimitReached = 0;
    this.otpVerResSubscr = timer(0, 5000).pipe(
      switchMap(() => this.abhaService.getOnAddContextRes({requestId: requestId}))
    ).subscribe(result =>{
      this.iterationLimitReached ++;
      if(!result.waiting && result.acknowledgement && result.acknowledgement.status == 'SUCCESS'){
        this.inProgress = false;
        console.log('#########onCareContextRes#########', result);
        this.linkingSuccess = true;
        this.otpVerResSubscr.unsubscribe();
        setTimeout(()=>{
          this.close();
        }, 5000);
      }else if(result.error){
        console.log('#########onCareContextRes#########', result.error);
        this.util.presentToastError(result?.error?.message);
        this.otpVerResSubscr.unsubscribe();
        this.close();
      }else if(this.iterationLimitReached == 10){
        this.otpVerResSubscr.unsubscribe();
        this.iterationLimitReached = 0;
        this.util.presentToastInfo("Please Try After Some time. ABDM Gateway not responding!");
        this.close();
      }
    });
  }

  openBottomSheet() {
    this._bottomSheet.open(AbhaConsentComponent, {
      data:{benCat: true}
    });
  }

  

}
