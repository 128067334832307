import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/modules/core/services/local-storage.service';
import { DataService } from 'src/app/modules/shared/services';
import { EncDecService } from 'src/app/modules/shared/services/encryption-decryption.service';
import { LookUpService } from 'src/app/modules/shared/services/look-up.service';
import { AshaService } from 'src/app/screens/asha-dashboard/services/asha.service';
import { SessionService } from 'src/app/screens/session/services/session.service';
import * as _ from 'lodash';
import { tap } from 'rxjs/operators';
@Component({
  selector: 'app-common-facility-site-location-filter',
  templateUrl: './common-facility-site-location-filter.component.html',
  styleUrls: ['./common-facility-site-location-filter.component.scss'],
})
export class CommonFacilitySiteLocationFilterComponent implements OnInit {
  filterForm: FormGroup;
  @Input() ashaUserId: any = null;
  @Input() ashaFacId: any = null;
  @Input() showVillages = false;
  villageDetails = [];
  healthVillages = [];
  wardData = [];
  healthWards = [];
  villageIds = [];
  healthVillageIds = [];
  wardIds = [];
  healthWardIds = [];
  facilities = [];
  locationDataIsLoading = false;
  ashaUserName = null;
  filterData: any = {};
  sessionSites: any = [];
  villageData: any = [];
  allSiteDetails: any = [];
  selectedSessionSite: any = {};
  subCenters:any = [];
  bothRole:boolean = false;
  constructor(
    private lookup: LookUpService,
    private activateRouter: ActivatedRoute,
    public encDecService: EncDecService,
    public dataService: DataService,
    private ashaServ: AshaService,
    private localStorage: LocalStorageService,
    private sessionService: SessionService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.filterForm = new FormGroup({
      session_site: new FormControl(null),
      ward_id: new FormControl(null),
      health_ward_id: new FormControl(null),
      village_id: new FormControl(null),
      health_village_id: new FormControl(null),
      facility_id: new FormControl(null),
      sub_center_id:new FormControl(null)
    });
    this.bothRole = this.dataService.userDetails.role === 'Both(Vaccinator & Delivery Manager)';
    if (this.localStorage.getItem('facilityId')) {
      this.filterForm.get('facility_id').setValue(this.localStorage.getItem('facilityId').toString());
    }
    if (this.localStorage.getItem('sub_center_id') && this.bothRole) {
      this.filterForm.get('sub_center_id').setValue(this.localStorage.getItem('sub_center_id'));
      this.sessionService.changedSubcenter.next(true)
    }
    if (!this.ashaUserId && (this.dataService.userDetails.role === 'ANM' 
    || this.dataService.userDetails.role === 'Vaccinator' 
    || this.dataService.userDetails.role === 'Both(Vaccinator & Delivery Manager)')) {
      this.dataService.getAllSites().pipe(tap(res => {
        if (res) {
          this.allSiteDetails = res;
          // if(this.dataService.userDetails.role === 'ANM' || this.dataService.userDetails.role === 'Vaccinator'){
            this.facilities = _.uniqBy(res, function (e) {
              return e.facility_id;
            });
          // }
          // if(this.dataService.userDetails.role === 'Both(Vaccinator & Delivery Manager)'){
          //   this.subCenters = _.uniqBy(res, function (e) {
          //     return e.sub_center_id;
          //   });
          // }
         
          // this.facilities.push({
          //   facility_id: this.dataService.userDetails.facility_id,
          //   facilty_name: this.dataService.userDetails.facility_name,
          // });
        }
      })
      ).subscribe(async res => {
        // if(this.bothRole) this.subCenterChange(this.localStorage.getItem('sub_center_id'))
        // if (this.localStorage.getItem('facilityId')) {
          // this.filterForm.get('facility_id').setValue(this.localStorage.getItem('facilityId'));
        // }
        this.facilityChange(this.localStorage.getItem('facilityId'));
         if(this.bothRole) this.subCenterChange(this.localStorage.getItem('sub_center_id'))
        if (this.localStorage.getItem('session_site_id')) {
          const sID = this.encDecService.get(this.localStorage.getItem('session_site_id'));
          this.filterForm.get('session_site').setValue(sID);
          this.sessionSiteDetails(this.filterForm.get('session_site').value);
        }
        this.cdr.detectChanges()
      }
      )
    }
    else {
      if(this.ashaUserId && this.ashaFacId){ 
        this.localStorage.setItem('facilityId', this.ashaFacId);
      }else{
        this.localStorage.setItem('facilityId', this.dataService.userDetails.facility_id);
        this.ashaUserId = this.dataService.userDetails?.user_id.endsWith('=') ? this.encDecService.get(this.dataService.userDetails?.user_id) : this.dataService.userDetails?.user_id;
      }

      
      this.sessionService.getAllSitesOfAsha(this.encDecService.setWithSpace(this.ashaUserId)).subscribe((r: any) => {
        this.sessionSites = r.map(e => {
          e.session_site_id = this.encDecService.set((e.session_site_id).toString());
          return e;
        });
        this.getVillageWardData(this.ashaUserId);
      })
    }
  }

  subCenterChange(event, subCenterChange?){
    if(subCenterChange) {
      this.localStorage.setItem('sub_center_id', event);
      this.filterForm.get('session_site').reset();
      this.sessionService.changedSubcenter.next(true);
      this.localStorage.removeItem('session_site_id');
      this.sessionSites = this.allSiteDetails.filter(ele => ele.sub_center_id == event);
      let siteInfo = {};
      this.localStorage.setItem('siteInfo', siteInfo)
    } else {
      this.localStorage.setItem('sub_center_id', event);
      this.sessionSites = this.allSiteDetails.filter(ele => ele.sub_center_id == event);
    }
    // this.sessionStorage.removeItem(key)
    // this.facilities = _.uniqBy(this.facilities, function (e) {
    //         return e.facility_id;
    //       });
  }

  facilityChange(event, facilityChange?) {
    if(facilityChange) {
      this.localStorage.removeItem('session_site_id');
      let siteInfo = {};
      this.localStorage.setItem('siteInfo', siteInfo)
      this.sessionService.facilityChanged.next(true)
    }
    this.localStorage.setItem('facilityId', event);
    if(this.bothRole)
    { 
      if (this.localStorage.getItem('sub_center_id')) {
          this.filterForm.get('sub_center_id').setValue(this.localStorage.getItem('sub_center_id'));
        }
      this.subCenters = _.uniqBy(this.allSiteDetails, function (e) {
            return e.sub_center_id;
          });;
      // this.subCenterChange(event)
    }
    else{
      this.sessionSites = this.allSiteDetails.filter(ele => ele.facility_id == event);
    }
  }
  
  sessionSiteDetails(e) {
    let value = e;
    this.villageDetails = [];
    this.wardData = [];
    this.healthVillages = [];
    this.healthWards = [];
    this.localStorage.removeItem('location_Id')
    this.filterForm.get('village_id').reset()
    this.filterForm.get('health_village_id').reset()

    this.filterForm.get('ward_id').reset()
    this.filterForm.get('health_ward_id').reset()

    // Send the session_site_name to store.
    this.selectedSessionSite = this.sessionSites.find(e => this.encDecService.get(e.session_site_id) == value);
    this.localStorage.setItem('session_site_id', this.encDecService.set(value));
    const siteInfo = { ...this.selectedSessionSite, session_site_id: value };
    this.localStorage.setItem('siteInfo', siteInfo);
    this.dataService.siteInfoDetails = siteInfo;
    this.localStorage.removeItem('location_Id');
    this.filterData.session_site_id = value;
    this.dataService.commonFltrDetails = {};
    if (this.dataService.userDetails.role === 'ASHA' || this.ashaUserId) {
      this.loadVillageBySite();
    } else if (this.showVillages) {
      this.loadVillagesBySiteIds([value]);
    }
  }

  getVillageWardData(ashaUserId) {
    this.locationDataIsLoading = true;
    this.lookup.getVillagebyAshaid(ashaUserId).subscribe(r => {
      this.villageData = r;
      if (this.localStorage.getItem('session_site_id')) {
        this.filterForm.get('session_site').setValue(this.encDecService.get(this.localStorage.getItem('session_site_id')));
        this.sessionSiteDetails(this.filterForm.get('session_site').value);
      }
      this.locationDataIsLoading = false;
    }, e => {
      this.locationDataIsLoading = false;
    });
  }
  loadVillagesBySiteIds(siteIds: any) {
    return new Promise((resolve, reject) => {
      this.villageDetails = []; this.healthVillages = [];
      this.wardData = []; this.healthWards = [];

      siteIds.forEach(async (s) => {
        const siteVillages = await this.ashaServ.getVillagesOrWardsBySiteId(s).toPromise();
        this.villageDetails = _.uniqBy(this.villageDetails.concat(siteVillages.villageData.map(e => { e['session_site_id'] = s; return e; })), 'village_id');
        this.healthVillages = _.uniqBy(this.healthVillages.concat(siteVillages.healthVillageData.map(e => { e['session_site_id'] = s; return e; })), 'village_id');
        this.wardData = _.uniqBy(this.wardData.concat(siteVillages.wardData.map(e => { e['session_site_id'] = s; return e; })), 'ward_id');
        this.healthWards = _.uniqBy(this.healthWards.concat(siteVillages.healthWardData.map(e => { e['session_site_id'] = s; return e; })), 'add_ward_id');
        this.cdr.detectChanges()
      });
      setTimeout(() => { resolve(true); }, 200);
    });

  }

  loadVillageBySite() {
    const r = this.villageData.filter(e => e.session_site_id == this.filterData.session_site_id);
    this.villageDetails = r.filter(e => !e.isHealthVillage && !e.ward_id);
    this.wardData = r.filter(e => !e.isHealthWard && !e.village_id);
    this.healthVillages = r.filter(e => e.isHealthVillage);
    this.healthWards = r.filter(e => e.isHealthWard);
    this.loadDefaultVillage();
  }

  loadDefaultVillage() {
    if (this.localStorage.getItem('location_Id')) {
      let villageIds: any = this.localStorage.getItem('location_Id');
      let locKey = '';
      if (villageIds.type == 'village_id') {
        locKey = 'village';
        this.filterForm.get('village_id').setValue({ 'village_id': villageIds.value, 'village_name': villageIds.village_name });
      } else if (villageIds.type == 'health_village_id') {
        locKey = 'healthVillage';
        this.filterForm.get('health_village_id').setValue({ 'village_id': villageIds.value, 'village_name': villageIds.village_name });
      } else if (villageIds.type == 'ward_Id') {
        locKey = 'wardId';
        this.filterForm.get('ward_id').setValue({ 'village_id': villageIds.value, 'village_name': villageIds.village_name });

      } else if (villageIds.type == 'health_ward_id') {
        locKey = 'healthWardId';
        this.filterForm.get('health_ward_id').setValue({ 'village_id': villageIds.value, 'village_name': villageIds.village_name });
      }
      this.onChangelocation(locKey);
    }
  }

  onChangelocation(value) {
    if (value == "village") {
      this.filterForm.get('health_village_id').reset();
      this.filterForm.get('health_ward_id').reset();
      this.filterForm.get('ward_id').reset();
    } else if (value == "healthVillage") {
      this.filterForm.get('village_id').reset();
      this.filterForm.get('health_ward_id').reset();
      this.filterForm.get('ward_id').reset();
    } if (value == "wardId") {
      this.filterForm.get('village_id').reset();
      this.filterForm.get('health_village_id').reset();
      this.filterForm.get('health_ward_id').reset();
    } else if (value == "healthWardId") {
      this.filterForm.get('village_id').reset();
      this.filterForm.get('health_village_id').reset();
      this.filterForm.get('ward_id').reset();
    }

    let village_id = null; let key = null; let type = null;
    if (this.filterForm.get('village_id').value) {
      village_id = this.filterForm.get('village_id').value;
      type = 'village_id';
      key = 'LGD Village';
    } else if (this.filterForm.get('health_village_id').value) {
      village_id = this.filterForm.get('health_village_id').value;
      type = 'health_village_id';
      key = 'Health Village';
    } else if (this.filterForm.get('ward_id').value) {
      village_id = this.filterForm.get('ward_id').value;
      key = 'LGD Ward';
      type = 'ward_id';
    } else if (this.filterForm.get('health_ward_id').value) {
      village_id = this.filterForm.get('health_ward_id').value;
      key = 'Health Ward';
      type = 'add_ward_id';
    }
    if (village_id) {
      this.localStorage.setItem('location_Id', { ...village_id, type, value: village_id.village_id });
      this.dataService.commonFltrDetails = { village_id, key };
      this.dataService.siteInfoDetails = this.selectedSessionSite;
    }
  }

  compareVillages(a1: any, a2: any): boolean {
    return a1 && a2 ? a1.village_id === a2.village_id : a1 === a2;
  }

}
