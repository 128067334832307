import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf, ErrorHandler } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { AuthGuard } from './guards/auth.guard';
import {
  StoreRouterConnectingModule,
  RouterStateSerializer
} from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../../../environments/environment';
import { AuthEffects } from './store/effects/auth.effects';
import { UserEffects } from './store/effects/user.effects';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { AppResponseInterceptor } from './interceptors/app-response.interceptor';
import { ErrorHandlerInterceptor } from './interceptors/error-handler.interceptor';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { selectIsAuthenticated, selectAuth } from './store/selectors/auth.selectors';
import { TitleService } from './services/title.service';
import {
  reducers,
  metaReducers,
  selectRouterState
} from './core.state';
import { SettingsEffects } from './settings/settings.effects';
import {
  selectEffectiveTheme,
  selectSettingsStickyHeader
} from './settings/settings.selectors';
import { CustomSerializer } from './router/custom-serializer';
import { UserResolverService } from './services/user-resolver.service';
import { SessionEffects } from './store/effects/session.effects';
import { IsAuthenticatedGuard } from './guards/is-authenticated';
import { UserGuard } from './guards/user.guard';
import { RoleGuard } from './guards/role.guard';


export {
  TitleService,
  selectAuth,
  selectIsAuthenticated,
  selectRouterState,
  selectEffectiveTheme,
  selectSettingsStickyHeader
};



const exportComponents = [
];

const exportDirectives = [
];

const exportPipes = [
];

const exportModules = [
]

@NgModule({
  declarations: [
    ...exportComponents,
    ...exportDirectives,
    ...exportPipes
  ],
  imports: [
    CommonModule,
    HttpClientModule,

    // ngrx
    StoreModule.forRoot(reducers, { 
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot([
      AuthEffects, UserEffects, SettingsEffects, SessionEffects,
    ]),
    environment.production
      ? []
      : StoreDevtoolsModule.instrument({
          name: 'U-WIN Vaccinator'
    }),
    ...exportModules
  ],
  exports: [
    ...exportComponents,
    ...exportDirectives,
    ...exportModules,
    ...exportPipes,
  ],
  providers: [
    AuthGuard,
    UserGuard,
    RoleGuard,
    IsAuthenticatedGuard,
    UserResolverService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppResponseInterceptor,
      multi: true
    },
    { provide: RouterStateSerializer, useClass: CustomSerializer },
  ]
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule,
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule');
    }
  }
}
