import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appConvertToUpperCase]'
})
export class ConvertToUpperCaseDirective {
@Output() convertedStr = new EventEmitter<string>();
  constructor() { }

  @HostListener('ionInput', ['$event'])
  onInput(event: any) {
    let inputValue: any = event.srcElement.value;
    inputValue = inputValue.replaceAll('  ', ' ');
    event.srcElement.value = this.camelCase(inputValue);
    this.convertedStr.emit(event.srcElement.value);
    return true;
  }
  camelCase(str) {
    let splitStr = str.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  }
}