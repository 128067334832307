import { Component, OnInit, ViewChild, Input, OnChanges, SimpleChanges, AfterViewInit, Output, EventEmitter, ElementRef, ChangeDetectorRef, } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/modules/shared/services';
import { UtilsService } from 'src/app/modules/shared/services/utils.service';
import * as _ from 'lodash';
import * as config from 'src/environments/config';
import { Observable, Subscription, timer } from 'rxjs';
import { ROUTE_ANIMATIONS_ELEMENTS } from 'src/app/modules/core/services/route.animations';
import { Device } from '@capacitor/device';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AddRecipientService } from '../../services/add-recipient.service';
import { RecipientService } from 'src/app/screens/recipient/services/recipient.service';
import { takeWhile, tap } from 'rxjs/operators';
import { ImageUploadService } from 'src/app/modules/shared/services/image-upload.service';
import * as regActions from 'src/app/modules/core/store/actions/registration.actions';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { Location } from '@angular/common';


@Component({
  selector: 'app-add-recipient-member-category-info-page',
  templateUrl: './member-category-info.page.html',
  styleUrls: ['./member-category-info.page.scss'],
})
export class MemberCategoryInfoPageComponent {
  constructor(
    public dataService: DataService,
    private router: Router,
    private loc: Location,
    private addRecipientService: AddRecipientService,
    public util: UtilsService,
  ) {
  }

  ngOnInit(): void {
    const stateData: any = this.loc.getState();
    if(stateData && stateData.data){
      this.addRecipientService.beneficiaryDetails = stateData.data;
    }
    // this.addRecipientService.beneficiaryDetails = this.loc.getState();

  }

  onStepChange(val: any) {
    if (val === 'next') {
      this.router.navigate(['/new-member/member-identification']);
    } else {
      this.loc.back();
    }
  }

  closeDialog() {
  }

}


