<ng-container>
    <div class="sidebar-header noMobile">
        <h1>
            <ion-icon (click)="gotoStep('prev')" class="custom-arrow-left"></ion-icon>
            {{'Member Details' | translate}}
            <ion-icon (click)="closeDialog()" class="custom-close"></ion-icon>
        </h1>
    </div>
    <ion-header class="noDesktop mobile-header-white">
        <ion-toolbar>
            <ion-buttons slot="start">
                <ion-back-button (click)="gotoStep('prev')" text="" icon="arrow-back-outline">
                </ion-back-button>
            </ion-buttons>
            <h4 class="heading">{{'Member Details' | translate}}</h4>
        </ion-toolbar>
    </ion-header>
    <form [formGroup]='personalDetForm' autocomplete="off" (ngSubmit)='onSubmit()'>
        <div class="sidebar-content inputFields">
            <ion-grid class="progressHeader">
                <ion-row>
                    <ion-col size="3">
                        <div class="progress--circle progress--75">
                            <div class="progress__number">3/4</div>
                        </div>
                    </ion-col>
                    <ion-col size="9">
                        <h1>{{'Personal Details' | translate}}</h1>
                        <span>{{'Next: Vaccination Details' | translate}}</span>
                    </ion-col>
                </ion-row>
            </ion-grid>
            <ion-grid class="sidebar-inner-content">
                <ng-container *ngIf="!benData?.beneficiary_id && (benData?.verifiedBy !== 'Aadhar' || benData?.guardian_ben_id)">
                    <app-common-recipient-fields [includeDOB]="isPWI" [parentForm]="personalDetForm"></app-common-recipient-fields>
                </ng-container>
                <ng-container *ngIf="!benData?.beneficiary_id && benData?.verifiedBy == 'Aadhar' && !benData?.guardian_ben_id">
                    <app-recipient-basic-summary [parentForm]="personalDetForm"></app-recipient-basic-summary>
                </ng-container>
                <ng-container *ngIf="benData?.beneficiary_id">
                    <app-recipient-basic-summary *ngIf="!stepChange" [parentForm]="personalDetForm" [adhaarDataMatch]="adhaarDataMatch"></app-recipient-basic-summary>
                </ng-container>
                <div *ngIf="specialDocument == true">
                    <input type="file" #fileInput (change)="uploadFile($event)" hidden="true" accept="image/png,image/jpeg" />
                    <ng-container *ngIf="uploadedSpecialDocFileDetails?.fileObject">
                        <ion-card class="uploadFile uploadPic ion-text-center">
                            <img [src]="uploadedSpecialDocFileDetails?.selectedFileBLOB">
                            <div class="imgClose" (click)="removeImage()">
                                <ion-icon name="trash-outline"></ion-icon>
                            </div>
                        </ion-card>
                    </ng-container>
                    <ng-container *ngIf="!uploadedSpecialDocFileDetails?.fileObject">
                        <ion-card (click)="selectImageSource()" class="uploadFile ion-text-center" [ngClass]="{'error-border': formsubmitted && !personalDetForm.controls.file.valid}">
                            <h3>
                                <ion-icon class="custom-camera"></ion-icon>{{'Upload Special Document' | translate}}
                            </h3>
                            <ion-text>{{'Click & Upload' | translate}}</ion-text>
                        </ion-card>
                    </ng-container>
                </div>
                <ion-row>
                    <ion-col size-md="12" class="ion-text-end">
                        <ion-button *ngIf="showReject" class="primaryBtn" shape="round" (click)="reject()">{{RejectBtnText}}</ion-button>
                        <ion-button *ngIf="!showReject" class="primaryBtn" shape="round" type="submit">{{ benData?.verifiedBy === 'Manual' ? ('Verify & Continue' | translate) : ('Confirm' | translate) }}
                            <ion-spinner *ngIf="Inprogress" name="lines-small"></ion-spinner>
                        </ion-button>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </div>
        <!-- <div class="sidebar-footer">
            <ion-row>
                <ion-col size-md="12">
                    <ion-button *ngIf="showReject" class="primaryBtn" expand="full" shape="round" (click)="reject()">{{RejectBtnText}}</ion-button>
                    <ion-button *ngIf="!showReject" class="primaryBtn" expand="full" shape="round" type="submit">{{benData?.verifiedBy=='Manual'? 'Continue': 'Confirm'}}
                        <ion-spinner *ngIf="Inprogress" name="lines-small"></ion-spinner>
                    </ion-button>
                </ion-col>
            </ion-row>
        </div> -->
    </form>
</ng-container>
