<div class="sidebar-content inputFields customTab" *ngIf="addRecipientService.beneficiaryData | async as bData">
  <ng-container *ngIf="!beneficiaryDetails">
    <ion-grid class="progressHeader">
      <ion-row>
        <ion-col size="3">
          <div class="progress--circle progress--50">
            <div class="progress__number">2/4</div>
          </div>
        </ion-col>
        <ion-col size="9">
          <h1>{{'Identification' | translate}}</h1>
          <span>{{'Next: Personal Details' | translate}}</span>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ng-container>
  <ng-container *ngIf="beneficiaryDetails">
    <ion-grid class="progressHeader">
      <ion-row>
        <ion-col size="9">
          <h1>Member Name</h1>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ng-container>
  <ng-container *ngIf="!aadharVerificationType">
    <ion-grid class="ion-no-padding ion-margin-top">
      <ion-row class="ion-margin-bottom">
        <ion-col size="12" class="ion-padding-horizontal">
          <div class="segmentNavSlider">
            <ion-segment [(ngModel)]="segment" (ionChange)="segmentChanged($event)">
              <ion-segment-button [value]="0">
                <ion-label>{{'Manual Verification' | translate}}</ion-label>
              </ion-segment-button>
              <ion-segment-button [value]="1">
                <ion-label>{{'AAdhaar eKYC' | translate}}</ion-label>
              </ion-segment-button>
            </ion-segment>
          </div>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="benData">
        <ion-col size="12" class="sidebar-inner-content">
          <!-- <ion-slides #slides pager="true" [options]="slideOpts" (ionSlideDidChange)="slideChanged($event)"
            class="availability-slides">
            <ion-slide class="sidebar-inner-content ion-no-margin"> -->
            <ng-container *ngIf="segment == 0">
              <form [formGroup]='verificationForm' autocomplete="off" (ngSubmit)='onSubmit()'>
                <ng-template mat-tab-label>
                  {{'Manual Verification' | translate}}
                </ng-template>

                <ng-container *ngIf="(benData.guardian_ben_id && benData.guardian_yob) || (!benData.guardian_ben_id)">
                  <app-photo-id-select [parentForm]="verificationForm" [yearOfBirth]='yearOfBirth'
                    [benTypeId]='benData?.ben_type_id' [gaurdianYOB]='benData?.guardian_yob'></app-photo-id-select>
                </ng-container>
                <ng-container *ngIf="benData.guardian_ben_id && !benData.guardian_yob">
                  <ng-container *ngIf="guadianObservable | async as gData">
                    <app-photo-id-select [parentForm]="verificationForm" [yearOfBirth]='yearOfBirth'
                      [benTypeId]='gData?.ben_type_id' [gaurdianYOB]='gData?.year'></app-photo-id-select>
                  </ng-container>
                </ng-container>



                <!-- <input type="file" #fileInput (change)="uploadFile($event)" hidden="true" accept="image/*" /> -->
                <input type="file" #fileInput (change)="uploadFile($event)" hidden="true" accept="image/png,image/jpeg"
                  (click)="resetFile()" />
                <ng-container *ngIf="uploadedFileDetails?.fileObject">
                  <ion-row>
                    <ion-col size="12">
                      <ion-card class="uploadFile uploadPic ion-text-center">
                        <img [src]="uploadedFileDetails?.selectedFileBLOB">
                        <div class="imgClose" (click)="removeImage()">
                          <ion-icon name="trash-outline"></ion-icon>
                        </div>
                      </ion-card>
                    </ion-col>
                  </ion-row>
                </ng-container>
                <ng-container *ngIf="!uploadedFileDetails?.fileObject">
                  <ion-row>
                    <ion-col size="12">
                      <ion-card
                        [ngClass]="{'error-border': formSubmitted && !verificationForm?.controls.photo_value.valid}"
                        (click)="selectImageSource()" class="uploadFile ion-text-center">
                        <img src="assets/images/icon-cloud.svg">
                        <h3>
                          <!-- <ion-icon class="custom-camera"></ion-icon> -->
                          {{'Upload Photo ID' | translate}}
                        </h3>
                        <ion-text>{{'Click & Upload' | translate}}</ion-text>
                      </ion-card>
                    </ion-col>
                  </ion-row>
                </ng-container>
                <div>
                  <ion-row>
                    <ion-col size-md="12" class="dark">
                      <!-- <mat-checkbox (change)="checkValue($event)" formControlName="consent">I hereby confirm that I have read
                          out the information provided by the
                          beneficiary... <a (click)="openBottomSheet()">{{'Read More' | translate}}</a> </mat-checkbox> -->
                      <span class="checkboxSpan"
                        *ngIf="benData!=null && benData!=undefined && benData?.ben_type_id == environment.static_ids.PregnantWomen || benData?.ben_type_id == environment.static_ids.LactatingMother"
                        [ngClass]="{'checkbox-required': verificationForm.get('female_consent').touched && verificationForm.get('female_consent')?.errors?.required}">
                        <mat-checkbox
                          [ngClass]="{'checkbox-required': verificationForm.get('female_consent').touched && verificationForm.get('female_consent')?.errors?.required}"
                          formControlName="female_consent" (change)="checkValue($event)" required> </mat-checkbox>
                        <p *ngIf="benData?.session_type_name == 'UIP'">{{'The beneficiary has been informed about the risk
                          and benefits associated with the ROUTINE vaccination' | translate}}</p>
                        <p *ngIf="benData?.session_type_name == 'COVID-19'">{{'The beneficiary has been informed about the
                          risk of exposure to COVID-19 infection along with the risk and benefits associated with the
                          COVID-19 vaccination.' | translate}}</p>
                        <p *ngIf="!benData?.hasOwnProperty('session_type_name')">{{'The beneficiary has been informed about
                          the risk and benefits associated with the vaccination' | translate}</p>

                      </span>
                      <div class="ion-text-end">
                        <ion-button class="primaryBtn" shape="round" expand="full" type="submit">{{'Continue' | translate}}
                          <ion-icon name="chevron-forward-outline"></ion-icon>
                        </ion-button>
                      </div>
                    </ion-col>
                  </ion-row>
                </div>
              </form>
            </ng-container>
            <!-- </ion-slide>
            <ion-slide class="sidebar-inner-content  ion-no-margin"> -->
              <ng-container *ngIf="segment == 1">
              <app-aadhar-verification-type (onSelectVerificationType)="onSelectVerificationType($event)">
              </app-aadhar-verification-type>
              </ng-container>
            <!-- </ion-slide>
          </ion-slides> -->
        </ion-col>
      </ion-row>
    </ion-grid>
    <mat-tab-group [selectedIndex]="selectedIndex" mat-align-tabs="start" mat-stretch-tabs="false" class="sidebar-inner-content" *ngIf="false">
      <mat-tab label="Manual Verification">
        <form [formGroup]='verificationForm' autocomplete="off" (ngSubmit)='onSubmit()'>
          <ng-template mat-tab-label>
            {{'Manual Verification' | translate}}
          </ng-template>
          <ion-grid class="ion-margin-top">
            <ng-container *ngIf="(benData.guardian_ben_id && benData.guardian_yob) || (!benData.guardian_ben_id)">
              <app-photo-id-select [parentForm]="verificationForm" [yearOfBirth]='yearOfBirth'
                [benTypeId]='benData?.ben_type_id' [gaurdianYOB]='benData?.guardian_yob'></app-photo-id-select>
            </ng-container>
            <ng-container *ngIf="benData.guardian_ben_id && !benData.guardian_yob">
              <ng-container *ngIf="guadianObservable | async as gData">
                <app-photo-id-select [parentForm]="verificationForm" [yearOfBirth]='yearOfBirth'
                  [benTypeId]='gData?.ben_type_id' [gaurdianYOB]='gData?.year'></app-photo-id-select>
              </ng-container>
            </ng-container>


          </ion-grid>
          <!-- <input type="file" #fileInput (change)="uploadFile($event)" hidden="true" accept="image/*" /> -->
          <h1>Hello</h1>
          <input type="file" #fileInput (change)="uploadFile($event)" hidden="true" accept="image/png,image/jpeg"
            (click)="resetFile()" />
          <ng-container *ngIf="uploadedFileDetails?.fileObject">
            <ion-card class="uploadFile uploadPic ion-text-center">
              <img [src]="uploadedFileDetails?.selectedFileBLOB">
              <div class="imgClose" (click)="removeImage()">
                <ion-icon name="trash-outline"></ion-icon>
              </div>
            </ion-card>
          </ng-container>
          <ng-container *ngIf="!uploadedFileDetails?.fileObject">
            <ion-card [ngClass]="{'error-border': formSubmitted && !verificationForm?.controls.photo_value.valid}"
              (click)="selectImageSource()" class="uploadFile ion-text-center">
              <img src="assets/images/icon-cloud.svg">
              <h3>
                <!-- <ion-icon class="custom-camera"></ion-icon> -->
                {{'Upload Photo ID' | translate}}
              </h3>
              <ion-text>{{'Click & Upload' | translate}}</ion-text>
            </ion-card>
          </ng-container>
          <div>
            <ion-row>
              <ion-col size-md="12" class="ion-text-end">
                <!-- <mat-checkbox (change)="checkValue($event)" formControlName="consent">I hereby confirm that I have read
                  out the information provided by the
                  beneficiary... <a (click)="openBottomSheet()">{{'Read More' | translate}}</a> </mat-checkbox> -->

                <span class="checkboxSpan"
                  *ngIf="benData!=null && benData!=undefined && benData?.ben_type_id == environment.static_ids.PregnantWomen || benData?.ben_type_id == environment.static_ids.LactatingMother"
                  [ngClass]="{'checkbox-required': verificationForm.get('female_consent').touched && verificationForm.get('female_consent')?.errors?.required}">
                  <mat-checkbox
                    [ngClass]="{'checkbox-required': verificationForm.get('female_consent').touched && verificationForm.get('female_consent')?.errors?.required}"
                    formControlName="female_consent" (change)="checkValue($event)" required> </mat-checkbox>
                  <p>{{'The beneficiary has been informed about the risk of
                    exposure to COVID-19 infection along with the risk and benefits associated with the COVID-19
                    vaccination.' | translate}}</p>
                </span>
                <ion-button class="primaryBtn" shape="round" expand="full"  type="submit">{{'Continue' | translate}}
                  <ion-icon name="chevron-forward-outline"></ion-icon>
                </ion-button>
              </ion-col>
            </ion-row>
          </div>
        </form>
      </mat-tab>
      <mat-tab label="Aadhaar eKYC">
        <ng-template mat-tab-label>
          {{'Aadhaar eKYC' | translate}}
        </ng-template>
        <app-aadhar-verification-type (onSelectVerificationType)="onSelectVerificationType($event)">
        </app-aadhar-verification-type>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
  <ng-container *ngIf="aadharVerificationType === 'Facial'">
    <app-aadhar-facial-verification (gotoStep)="gotoStep($event)"></app-aadhar-facial-verification>
  </ng-container>
  <ng-container *ngIf="aadharVerificationType === 'Demographic'">
    <app-aadhar-demographic-verification (gotoStep)="gotoStep($event)"></app-aadhar-demographic-verification>
  </ng-container>
  <ng-container *ngIf="aadharVerificationType === 'Biometric'">
    <app-aadhar-biometric-verification (gotoStep)="gotoStep($event)"></app-aadhar-biometric-verification>
  </ng-container>
  <ng-container *ngIf="aadharVerificationType === 'OTP'">
    <app-aadhar-otp (gotoStep)="gotoStep($event)"></app-aadhar-otp>
  </ng-container>
</div>