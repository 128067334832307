import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {throwError} from 'rxjs';
import * as sessActions from '../actions/session.actions';

@Injectable()
export class SessionEffects {

  constructor(private actions$: Actions) {}
}
