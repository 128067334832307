import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { UserService } from './user.service';
import { select, Store } from '@ngrx/store';
import { getUserDetails } from '../store/selectors/user.selectors';
import { filter, take } from 'rxjs/operators';

@Injectable()
export class UserResolverService implements Resolve<any> {
  constructor(private http: HttpClient, private store$: Store,private userService: UserService) { }

  /**
   * resolve method
   * @param route
   * @param state
   */
  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    // return this.store$.dispatch(GetProfile());
    return this.store$.pipe(
      select(getUserDetails),
      filter(data => !!data), // <- waiting until data is present
      take(1), // <- important to add
      )
    }

}
