import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-abha-consent',
  templateUrl: './abha-consent.component.html',
  styleUrls: ['./abha-consent.component.scss'],
})
export class AbhaConsentComponent implements OnInit {
  @Input() benCat = null;
  constructor(
    private _bottomSheetRef: MatBottomSheetRef<AbhaConsentComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) { }

  ngOnInit() {}

  dismissModal() {
    this._bottomSheetRef.dismiss();
  }

}
