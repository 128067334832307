<ng-container  *ngIf="util.getPlatformWidth() < 575">
    <ion-content class="ion-page">
      <ng-container *ngTemplateOutlet="tpl"></ng-container>
    </ion-content>
    </ng-container>
    <ng-container  *ngIf="util.getPlatformWidth() >= 575">
        <ng-container *ngTemplateOutlet="tpl"></ng-container>
    </ng-container>
  <ng-template #tpl>

<app-add-recipient-personal-details *ngIf="!benfData.special_session_type_id" [activeStepIndex]="'PERSONALDETAILS'"  (onClose)="closeDialog()"
(onStepChange)="onStepChange($event)">
</app-add-recipient-personal-details>
<app-add-recipient-personal-details *ngIf="benfData.special_session_type_id == 1"  [isPWI]="true" [activeStepIndex]="'PERSONALDETAILS'"  (onClose)="closeDialog()" (onStepChange)="onStepChange($event)">
</app-add-recipient-personal-details>
</ng-template>