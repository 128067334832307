import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { tap, map, take, filter } from 'rxjs/operators';
import { DataService } from '../../shared/services';
import { getUserDetails } from '../store/selectors/user.selectors';
import { selectIsAuthenticated } from '../core.module';


@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(private store$: Store,
              private router: Router) {
  }

  userStatePromise(): Observable<any> {
    // return this.store$.dispatch(GetProfile());
    return this.store$.pipe(
      select(getUserDetails),
      filter(data => !!data), // <- waiting until data is present
      take(1), // <- important to add
      )
  }

  
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const rolesArray: string[] = next.data.role;
    return this.userStatePromise().pipe( 
      map((user: any) => { 
        if(user){
            if (user && rolesArray && rolesArray.length > 0) {
              if (rolesArray.indexOf(user?.role) >= 0) {
                    return true;
              } else {
                this.router.navigate(['/unauthorized']);
                return false;
              }
            }
            return true;
        }
        return false;
      })
    );
  }

}
