import { Directive, Input, HostListener } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidatorFn, FormGroup, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[appInputChar]',
  providers: [{ provide: NG_VALIDATORS, useExisting: InputCharDirective, multi: true }]
})
export class InputCharDirective {

  @Input('appInputChar') appInputChar: string;

  validate(control: AbstractControl): { [key: string]: any } | null {
    return this.appInputChar ? InputCharValidator(this.appInputChar)(control) : null;
  }
}
export function InputCharValidator(inputCharVal): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {

    if (control && (control.value === '' || control.value === null || control.value === undefined)) {
      return null;
    }
    // location facility name Validation (Allow only “.”,  “-“,  “( )”  ,”0-9”, “a-z”, “A-Z”)
    const beneficiaryRegex = /^[ A-Za-z0-9.\-()]*$/;
    // User Names Field Validation (Allow only  “.”,  “-“,  “( )” , “a-z”, “A-Z”)
    const usernameRegex = /^[ A-Za-z.\-()]*$/;
    // Street Name Field Validation (Allow only “.”,  “,”, “-“,  “( )”  ,”0-9”, “a-z”, “A-Z”)
    const streetRegex = /^[ A-Za-z0-9.,\-()]*$/;
    // location facility name Validation (Allow only “.”,  “-“, “/“ , “( )”  ,”0-9”, “a-z”, “A-Z”)
    const beneficiaryIdRegex = /^[ A-Za-z0-9/.,\-()]*$/;
    // id number Validation (Allow only “.”,  “-“, “/“ , “( )”  ,”0-9”, “a-z”, “A-Z”)
    const idRegex = /^[ A-Za-z0-9./-]*$/;
    // id number Validation (Allow only “.”,  “-“, “/“ , “( )”  ,”0-9”, “a-z”, “A-Z”)
    const nameRegex = /^[ A-Za-z.']*$/;
    // passport number Validation
    const passportCard = /^[A-Z0-9]*$/; ///^[A-Z]{1,4}[0-9]{5,7}$/;
    // pan card number Validation
    const pancard = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    // dl regex validation
    const drivingLicence = /^(([A-Z]{2}[0-9]{2})()|([A-Z]{2}[0-9]{2}))((19|20)[0-9][0-9])[0-9]{6,7}$/;  //^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/;
    const penspassbook = /^[ A-Za-z0-9.]*$/;
    const udidCard = /^[A-Z]{2}[0-9]{16}$/;
    // Alpha Num
    const alphaNumRegex = /^[ A-Za-z0-9]*$/;


    let validate;
    if (control && control.value) {
      if (inputCharVal === 'beneficiary') {
        validate = beneficiaryRegex.test(control.value);
      } else if (inputCharVal === 'username') {
        validate = usernameRegex.test(control.value);
      } else if (inputCharVal === 'street') {
        validate = streetRegex.test(control.value);
      } else if (inputCharVal === 'idproof') {
        validate = beneficiaryIdRegex.test(control.value);
      } else if (inputCharVal === 'idnumber') {
        validate = idRegex.test(control.value);
      } else if (inputCharVal === 'name') {
        validate = nameRegex.test(control.value);
      } else if (inputCharVal === 'passportnumber') {
        validate = passportCard.test(control.value);
      } else if (inputCharVal === 'pancard') {
        validate = pancard.test(control.value);
      } else if (inputCharVal == 'dlnumber') {
        validate = drivingLicence.test(control.value);
      } else if (inputCharVal == 'pensionpassbook') {
        validate = penspassbook.test(control.value);
      } else if (inputCharVal == 'udidcard') {
        validate = udidCard.test(control.value);
      } else if (inputCharVal === 'onlyAlphaNum') {
        validate = alphaNumRegex.test(control.value);
      } else {
        validate = false;
      }
      // validate = locAndFacilityRegex.test("sdsgs.sdfs") control.value.length >= rangeVal[0] && control.value.length <= rangeVal[1];
    }
    return !validate ? { inputcharvalidator: { value: control.value } } : null;
  };
}
