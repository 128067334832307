import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import moment from 'moment';
import { map, switchMap } from 'rxjs/operators';
import { UtilsService } from 'src/app/modules/shared/services/utils.service';
import { AddRecipientService } from 'src/app/screens/add-recipient/services/add-recipient.service';
import { Device } from '@capacitor/device';
import { ActivatedRoute, Router } from '@angular/router';
import { LookUpService } from 'src/app/modules/shared/services/look-up.service'
import { Subscription } from 'rxjs';
import { SessionService } from 'src/app/screens/session/services/session.service';
import { Location } from '@angular/common';
import { RecipientService } from 'src/app/screens/recipient/services/recipient.service';
import { DataService } from 'src/app/modules/shared/services';

@Component({
  selector: 'app-vaccinate-member-page',
  templateUrl: './vaccinate-member.page.html',
  styleUrls: ['./vaccinate-member.page.scss'],
})
export class VaccinateMemberPageComponent {
  benId;
  sessionId;
  sessionDetails;
  benData
  constructor(
    public dataService: DataService,
    private router: Router,
    private loc: Location,
    private addRecipientService: AddRecipientService,
    public util: UtilsService,
    public actRoute:ActivatedRoute,
    public sessionService:SessionService,
    public recipientService:RecipientService
  ) {
    this.benId = this.actRoute.snapshot.params.benID
    this.sessionId = this.actRoute.snapshot.params.sessionID;
  }
  ngOnInit() {
    const stateData: any = this.loc.getState();
    if (stateData && stateData.data) {
      this.addRecipientService.benSuccessDetails = stateData.data;
      this.addRecipientService.beneficiaryDetails = stateData.data;
    }
    // if(!stateData.data){
    //   this.onRefresheVaccinatedData()
    // }
    if(stateData.navigationId == 1){
      // this.onRefresheVaccinatedData()
      this.router.navigate([`/member-vaccination-info/${this.benId}/${this.sessionId}`]);
    }
  }
  onRefresheVaccinatedData(){
    this.sessionService.getSessionInfo(this.sessionId).toPromise().then(r=>{
      this.sessionDetails = r;
      this.recipientService.getBeneficairyDetails(this.benId).toPromise().then(r=>{
        this.benData = r;
        let obj ={
          ... this.sessionDetails,
          ... this.benData,
          ...{ isBenfAddedToSession: true }
        }
        this.addRecipientService.benSuccessDetails = obj;
        this.addRecipientService.beneficiaryDetails = obj;
      //  this.router.navigate([`/member-vaccination-info/${this.benData.beneficiary_id}/${this.sessionDetails.session_id}`]);

    })
  })  
  }
  onStepChange(val: any) {
    if (val === 'next') {
      if(this.benId && this.sessionId){
        this.router.navigate([`/vaccination-rejected/${this.benId}/${this.sessionId}`]);
      }else{
      this.router.navigate(['/new-member/vaccination-rejected']);
      }
    } else {
      this.loc.back();
    }
  }

  closeDialog() {
  }

}
