<!-- <ion-content appScrollbarTheme  fullscreen class="aadhar-concen-wrap"> -->
<ion-grid appScrollbarTheme fullscreen class="aadhar-concen-wrap">
    <ion-row>
        <ion-col size="12" class="ion-text-end">
            <ion-icon class="custom-close" (click)="dismissModal()"></ion-icon>
        </ion-col>
    </ion-row>
    <ion-row>
        <ion-col size-lg="12" size-md="12">
            <div class="aadhar-concent">
                <ul>
                    {{'AADHAAR_CONCENT1' | translate}}
                    <br />
                    <p></p>
                    {{'AADHAAR_CONCENT2' | translate}}
                    <br />
                    <p></p>
                    {{'AADHAAR_CONCENT3' | translate}}
                </ul>
            </div>
        </ion-col>
    </ion-row>
</ion-grid>
<!-- </ion-content>      -->
