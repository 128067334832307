import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef, OnDestroy, Input, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Device } from '@capacitor/device';
import moment from 'moment';
import { switchMap } from 'rxjs/operators';
import { DownloadFileService } from 'src/app/modules/shared/services/download-file.service';
import { AddRecipientService } from 'src/app/screens/add-recipient/services/add-recipient.service';
import { Subscription } from 'rxjs';
import { UtilsService } from 'src/app/modules/shared/services/utils.service';
import { Location } from '@angular/common';
import { SessionService } from 'src/app/screens/session/services/session.service';
import { RecipientService } from 'src/app/screens/recipient/services/recipient.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/modules/shared/services';

@Component({
  selector: 'app-vaccination-rejected-page',
  templateUrl: './vaccination-rejected.page.html',
  styleUrls: ['./vaccination-rejected.page.scss'],
})
export class VaccinationRejectedPageComponent{
  benId;
  sessionId;
  constructor(
    public dataService: DataService,
    private router: Router,
    private loc: Location,
    public util: UtilsService,
    public actRoute:ActivatedRoute,
    public sessionService:SessionService,
    public recipientService:RecipientService
    
  ) {
    this.benId = this.actRoute.snapshot.params.benID
    this.sessionId = this.actRoute.snapshot.params.sessionID;
   
  }
  ngOnInit(){
    const stateData: any = this.loc.getState();
    if(stateData.navigationId == 1){
      this.router.navigate([`/session/${this.sessionId}`]);
    }
  }

  onStepChange(val: any) {
    if (val === 'next') {
      this.router.navigate(['/dashboard']);
    } else {
      this.loc.back();
    }
  }

  closeDialog() {
  }
}
