import { AbstractControl, FormGroup } from '@angular/forms';
import { Component, Input, OnInit, AfterContentChecked, Output, EventEmitter } from '@angular/core';
import { DataService } from 'src/app/modules/shared/services/data.service';
import { LocationService } from 'src/app/modules/shared/services/location.service';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'app-ion-custom-select',
  templateUrl: './ion-custom-select.component.html',
  styleUrls: ['./ion-custom-select.component.scss'],
})
export class IonCustomSelectComponent implements OnInit, AfterContentChecked {
  @Input() formKey: any = null;
  @Input() arrObj: any = [];
  @Input() arrKey: any = null;
  @Input() isDisabled: boolean = false;
  @Input() arrKeyVal: any = null;
  @Input() parentForm: FormGroup;
  @Output() onSelect = new EventEmitter();
  @Input() label: any = null;
  @Input() class: any = null;
  @Input() placeholder: any = null;
  required = ''

  constructor(
    private locationsService: LocationService,
    private dataService: DataService,
    public utils: UtilsService,
  ) {
  }

  ngOnInit() {
    if (this.class == "noclass") {
      this.class = "";
    }
    else {
      this.class = "large-label";
    }
    if (this.formKey == 'dose_per_vial') {
      this.required = ''
    } else {
      this.required = 'required'
    }
  }

  get isRequired() {
    if(this.formKey && this.parentForm){ 
      if(!this.parentForm.get(this.formKey).validator){return false;}
      const validatorV = this.parentForm.get(this.formKey).validator({} as AbstractControl);
      if (validatorV && validatorV.required) {
        return true;
      }
    }
    return false;
  }

  ngAfterContentChecked(): void {
  }

  onChange() {
    this.onSelect.emit(this.parentForm.get([this.formKey]).value);
  }
}
