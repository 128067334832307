import { Component, OnInit, ViewChild, Input, OnChanges, SimpleChanges, AfterViewInit, Output, EventEmitter, } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { DataService } from 'src/app/modules/shared/services';
import { UtilsService } from 'src/app/modules/shared/services/utils.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ROUTE_ANIMATIONS_ELEMENTS } from 'src/app/modules/core/services/route.animations';
import { Device } from '@capacitor/device';
import { AddRecipientService } from '../../services/add-recipient.service'
import * as regActions from 'src/app/modules/core/store/actions/registration.actions';
import { Store, select } from '@ngrx/store';
import { getSessionDetails } from 'src/app/modules/core/store/selectors/session.selectors';
import { filter, take } from 'rxjs/operators';
import { getRegistrationDetails } from 'src/app/modules/core/store/selectors/registration.selectors';
import { VerificationComponent } from '../verification/verification.component';



@Component({
  selector: 'app-add-recipient-identification',
  templateUrl: './identification.component.html',
  styleUrls: ['./identification.component.scss'],
})
export class IdentificationComponent implements OnChanges {
  @Input() noBack = false;
  @Input() activeStepIndex: any;
  @Output() onStepChange: EventEmitter<any> = new EventEmitter();
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @ViewChild(VerificationComponent) child: VerificationComponent;

  constructor(
    private addRecipient: AddRecipientService,
    private store$: Store,
  ) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.activeStepIndex){
      if(this.activeStepIndex == 'IDENTIFICATION') {
        this.reloadComponent();
      } else {
      }
    }
  }

  reloadComponent() {
    if(this.child){this.child.resetAll();}
    this.addRecipient.stepHistory = ['first-step'];
  }

  gotoStep(val) {
    if(val == 'next'){
      this.onStepChange.emit(val);
    }else{
      if(this.addRecipient.stepHistory.includes('verification')){
        this.child.resetAll();
        this.addRecipient.stepHistory = ['first-step'];
      }else{
        this.onStepChange.emit(val);
      }
    }
  }

  closeDialog() {
    this.addRecipient.beneficiaryDetails = {}
    this.onClose.emit();
  }

  isInVerificationSteps() {
    return this.noBack && this.addRecipient.stepHistory.includes('verification')
  }
}


