import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EncDecService } from 'src/app/modules/shared/services/encryption-decryption.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AbhaService {
  otpTimer = 90;
  generateByParent = null;
  abhaToken = null;
  profileXtoken = null;
  tToken = null;
  transId = null;
  linkedAccounts = null;
  childBenfId = null;
  verifiedProfileData = null;
  isABHAGeneratingForGuardian = false;
  isABHAExist = null;
  parentAbhaToken = {};
  abhaVerifiedByMobileNo = null;
  verifyByABHAOrMob = null
  constructor(
    private http: HttpClient,
    private encDecService: EncDecService,
  ) { }

  sendOtpToRegisteredAadharMobileNo(aadhaarNumber): Observable<any> {
    const aadhaarnumber = this.encDecService.setWithSpace(aadhaarNumber);
    const postData = {
      aadhaar: aadhaarnumber
    };
    return this.http.post<any>(
      `${environment.vaccinator_url}/abha/otpOnAadhar`,
      postData
    );
  }

  demographicAuthentication(payload): Observable<any> {
    return this.http.post<any>(
      `${environment.vaccinator_url}/abha/demoAuth`,
      payload
    );
  }

  verifyAbhaAadhaarOTP(postData) {
    return this.http.post<any>(
      `${environment.vaccinator_url}/abha/AabhaByAadhar`,
      postData
    );
  }

  verifyAadhaarOTP(postData) {
    const payload = {
      otp: postData.otp,
      txnId: postData.txnId,
      mobileNumber: this.encDecService.setWithSpace(postData.mobileNumber),
      beneficiary_reference_id: postData.beneficiary_reference_id
    };
    return this.http.post<any>(
      `${environment.vaccinator_url}/abha/enrolAabhaByAadhar`,
      payload
    );
  }

  verifyDemographicDetails(postData) {
    const payload = {
      aadharNumber: this.encDecService.setWithSpace(postData.aadharNumber),
      beneficiary_reference_id: postData.beneficiary_reference_id,
      guradian_reference_id: postData.guradian_reference_id ||  undefined
    }
    return this.http.post<any>(
      `${environment.vaccinator_url}/abha/enrolAabhaByDemoAuth`,
      payload
    );
  }

  sendOtpToMobileNo(postData){
    return this.http.post<any>(
      `${environment.vaccinator_url}/abha/updateMobileOTP`,
      postData
    );
  }

  verifyMobileUpdateOtp(postData){
    return this.http.post<any>(
      `${environment.vaccinator_url}/abha/updateMobileOTPVerify`,
      postData
    );
  }
  
  sendOtpToRegisteredABHAMobileNo(postData){
    const payload = {
      loginHint:postData.loginHint,
      otpSystem: postData.otpSystem,
      value: this.encDecService.setWithSpace(postData.value),
    }
    return this.http.post<any>(
      `${environment.vaccinator_url}/abha/updateProfileOTP`,
      payload
    );
  }
  verifyABHAOTP(postData) {
    return this.http.post<any>(
      `${environment.vaccinator_url}/abha/updateProfileOTPVerify`,
      postData
    );
  }

  saveAbhaAddress(postData) {
    postData.beneficiary_reference_id = this.encDecService.set('' + postData.beneficiary_reference_id);
    return this.http.post<any>(
      `${environment.vaccinator_url}/abha/abhaAddress`,
      postData
    );
  }

  getLinkedAbhaAccounts(){
    return this.http.post<any>(
      `${environment.vaccinator_url}/abha/getChildParentMappedAbhaId`,
      {token: this.abhaToken}
    );
  }

  createChildAbhaId(payload){
    return this.http.post<any>(
      `${environment.vaccinator_url}/abha/createChildAbhaId`,
      payload
    );
  }

  linkExistHealthId(postData){
    const payload = {
      beneficiary_reference_id: postData.beneficiary_reference_id,
      abha_number:  this.encDecService.setWithSpace(postData.abha_number),
      phr_address: this.encDecService.setWithSpace(postData.phr_address),
    }
    return this.http.post<any>(
      `${environment.vaccinator_url}/abha/saveAabhaNumber`,
      payload
    );
  }

  profileUpdateInABHA(payload){
    return this.http.post<any>(
      `${environment.vaccinator_url}/abha/verifyAndUpdateAabha`,
      payload
    );
  }

  updateBenDetailsInApp(payload) {
    const formData = new FormData();
    formData.append('beneficiary_id', this.encDecService.setWithSpace(payload.beneficiary_id));
    formData.append('ben_name', this.encDecService.setWithSpace(payload.ben_name));
    formData.append('beneficiary_gender', payload.beneficiary_gender);
    formData.append('dob', payload.dob);
    formData.append('aadhaar_eKYC', "true");
    formData.append('year', payload.year);
    return this.http.post<any>(
      `${environment.beneficiary_vaccinator_v3_url}/updateBeneficiaryBasicDetails`,
      formData)
  }

  getLinkingOTP(payload): Observable<any>{
    payload.phrAddress = this.encDecService.setWithSpace(payload.phrAddress)
    return this.http.post<any>(
      `${environment.hip_port_url}/getLinkingOTP`,
      payload
    );
  }

  verifyLinkingOTP(payload){
    return this.http.post<any>(
      `${environment.hip_port_url}/verifyLinkingOTP`,
      payload
    );
  }

  verifyLinkingByDemographic(payload){
    return this.http.post<any>(
      `${environment.hip_port_url}/verifyLinkingByDemographic`,
      payload
    );
  }

  getLinkingOTPResponse(payload){
    return this.http.post<any>(
      `${environment.hip_port_url}/getLinkingOTPResponse`,
      payload
    );
  }

  verifyLinkingOTPResponse(payload){
    return this.http.post<any>(
      `${environment.hip_port_url}/getOnConfirmResponse`,
      payload
    );
  }

  initAddCareContextRes(payload){
    return this.http.post<any>(
      `${environment.hip_port_url}/addCareContext`,
      payload
    );
  }
  
  getOnAddContextRes(payload){
    return this.http.post<any>(
      `${environment.hip_port_url}/getOnAddContextRes`,
      payload
    );
  }

  getABDMProfileToken(payload){
    return this.http.post<any>(
      `${environment.vaccinator_url}/abha/getXToken`,
      payload
    );
  }


}

