import {ChangeDetectorRef, Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from 'src/app/modules/shared/services';
import { EncDecService } from 'src/app/modules/shared/services/encryption-decryption.service';
import { UtilsService } from 'src/app/modules/shared/services/utils.service';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/modules/core/services/user.service';
import { NotificationService } from 'src/app/modules/core/services/notification.service';
// import { Clipboard } from '@capacitor/clipboard';


@Component({
  selector: 'notification-dialog',
  templateUrl: './notification-dialog.html',
  styleUrls:['./notification-dialog.scss']
})
export class NotificationDialog {
  notificationTplKey: string = 'APPRECIATION';
  isDownloadInProgress = false;
  public urlString = '';
  lang = 'en';
user_id = null;

  constructor(
    private util: UtilsService,
    public dialogRef: MatDialogRef<NotificationDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dataService: DataService,
    private encService: EncDecService,
    private cdr: ChangeDetectorRef,
    public noticationServ:NotificationService
  ) {
  }

  ngOnInit(): void {
    this.notificationTplKey = this.data && this.data.notificationKey ?  this.data.notificationKey: this.notificationTplKey;
    this.urlString = `${environment.vaccination_status_url}/${this.encService.get(''+ this.dataService.userDetails.user_id)}/${this.lang}`;
  }

  onChange(val){
    this.urlString = `${environment.vaccination_status_url}/${this.dataService.userDetails.user_id}/${val}`
  }
  
 closeDialog() {
      this.dialogRef.close();
  }
  

  async copyToClipboard() {
    // await Clipboard.write({
    //   string: this.urlString,
    // });
    navigator.clipboard.writeText(this.urlString);
  }

  
  logShare(){
    this.noticationServ.logShareCnt().subscribe();
  }



}
