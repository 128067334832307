import { AuthState } from '../../interfaces/auth.interface';
import { Login, LoginFailure, LoginSuccess, Logout } from '../actions/auth.actions';
import { createReducer, on, Action } from '@ngrx/store';

export const initialState: AuthState = {
  isAuthenticated: false,
  error: null
};

const reducer = createReducer(
  initialState,
  on(LoginSuccess, (state) => ({ ...state, isAuthenticated: true, error: null })),
  on(LoginFailure, (state, error) => ({ ...state, isAuthenticated: false, ...error})),
  on(Logout, (state) => ({ ...state, isAuthenticated: false, error: null  }))
);

export function authReducer(
  state: AuthState | undefined,
  action: Action
): AuthState {
  return reducer(state, action);
}
