import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataService } from 'src/app/modules/shared/services';
import { EncDecService } from 'src/app/modules/shared/services/encryption-decryption.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { Observable, ReplaySubject,of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AshaService {
  selectedFilterIndex = null;
  ashaDetails = null;
  fileDownloadInProgress = false; 
  benCountDeVaccinated = new ReplaySubject(1);
  constructor(
    private http: HttpClient,
    private encDecService: EncDecService,
    private dataService: DataService,
  ) { }

  getVillagesOrWardsBySiteId(site_id): Observable<any> {
    if (!site_id) {
      return of([]);
    }
    const payload = {
      //  session_site_id: site_id
       session_site_id: site_id.slice(-1) == '=' ? site_id :this.encDecService.setWithSpace(site_id)
    };
    return this.http.post(`${environment.vaccinator_url}/getVillageDetailsBySiteId`, payload).pipe(map(res =>{
      Object.values(res).map(e => e.forEach(el=>{
        if(el.add_ward_id){
            el.add_ward_id = this.encDecService.get(el.add_ward_id)
        }
        if(el.ward_id){
            el.ward_id = this.encDecService.get(el.ward_id)
        }
        if(el.village_id){
            el.village_id = this.encDecService.get(el.village_id)
        }
    })
    )
    return res
    }))
 
  }


  getBenDueListByVillage(payload){
    payload.facility_id = this.encDecService.setWithSpace(payload.facility_id);
    payload.asha_village_id = payload.asha_village_id ? this.encDecService.setWithSpace(payload.asha_village_id):undefined
    if(payload.village_id && payload.village_id.length > 0){
      payload.village_id = payload.village_id.map(e => {
        if(e.village_id.slice(-1) != "=")
        e.village_id = this.encDecService.setWithSpace(e.village_id)
        return e;
      })
    }
    // return this.http.post(`${environment.vaccinator_url}/getBenDueListByVillage`, payload);
    return this.http.post(`${environment.vaccinator_url}/vaccinationDueListJson`, payload).pipe(map((b:any)=>{
      b.map(benf=>{
        this.encDecService.decryptMobileNo(benf);
      });
      return b;
    }));
  }
  getBenVaccinatedListByVillage(payload){
    payload.facility_id = this.encDecService.setWithSpace(payload.facility_id);
    payload.asha_village_id = payload.asha_village_id ? this.encDecService.setWithSpace(payload.asha_village_id):undefined
    if(payload.village_id && payload.village_id.length > 0){
      payload.village_id = payload.village_id.map(e => {
        if(e.village_id.slice(-1) != "=")
        e.village_id = this.encDecService.setWithSpace(e.village_id)
        return e;
      })
    }
    return this.http.post(`${environment.vaccinator_url}/VaccinatedBensJson`, payload).pipe(map((b:any)=>{
      b.map(benf=>{
        this.encDecService.decryptMobileNo(benf);
      });
      return b;
    }));
  }
  
  getBeneficiariesByAshaId(payload){
    payload['user_id']= this.encDecService?.get(this.dataService.userDetails?.user_id)
    return this.http.post(`${environment.vaccinator_url}/getBeneficiariesByAshaId`, payload)
    .pipe(map((b:any)=>{
      b.map(benf=>{
        this.encDecService.decryptPhotoId(benf);
        this.encDecService.decryptMobileNo(benf);
      });
      return b;
    }));
  }

  filterTxtArr(isUpcoming = true){
    let filterObj: any = {
      'Next Week': {from:0, to: 7, param: 'days'},
      'Next 15 Days': {from:0, to: 15, param: 'days'},
      'Next One Month': {from:0, to: 1, param: 'months'},
    }
  
    if(!isUpcoming){ 
      filterObj = {
        'Last Week': {from:0, to: 7, param: 'days'},
        'Last 15 Days': {from:0, to: 15, param: 'days'},
        'Last One Month': {from:0, to: 1, param: 'months'},
      }
    }
    for(let i=1; i<=3;i++){
      let txt = moment().endOf('month').add(i, "month").format('MMMM YYYY');
      if(!isUpcoming){ 
        txt = moment().endOf('month').subtract(i, "month").format('MMMM YYYY');
      }
      filterObj[txt] = {from:i, to: i, param: 'months'}
    }
    return filterObj; 
  }

  getfilterDataByType(Obj,isUpcoming=true){
    let from = moment().add(Obj.from, Obj.param);
    let to = moment().add(Obj.to, Obj.param);
    if(!isUpcoming){ 
        to = moment().subtract(Obj.from, Obj.param);
        from = moment().subtract(Obj.to, Obj.param);
    }
    return {
      from: Obj.from ? from.startOf(Obj.param).format('YYYY-MM-DD') : from.format('YYYY-MM-DD'), 
      to: Obj.from ? to.endOf(Obj.param).format('YYYY-MM-DD') : to.format('YYYY-MM-DD')}  
  }

  updateVillageIdsByUserId(postData){
 
  //  console.log("mobile",postData[0].mobile_number )
  
  //  postData[0].mobile_number = this.encDecService.setWithSpace(postData[0].mobile_number)
   
    let payload =   {
      ...postData,
   
    }
    console.log("payload", payload)
    return this.http.post(`${environment.vaccinator_url}/mappingAshaVillager`, postData)
    ;
  }

  
}
