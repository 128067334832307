import {createFeatureSelector, createSelector} from "@ngrx/store";
import {SessionState} from "../reducers/session.reducer";
import {Statuses} from "../../constants/app.constants";
import { AppState } from "../../interfaces/auth.interface";

export const sessionState = createFeatureSelector<AppState, SessionState>('sess');

export const isSessionLoading = createSelector(
  sessionState,
  (state: SessionState) => state.status === Statuses.LOADING
);


export const getSessionDetails = createSelector(
  sessionState,
  (state: SessionState) => {
    return state?.data
  }
);
