<ng-container [formGroup]='parentForm'>
  <ng-container *ngIf="parentForm?.controls?.user_photo">
    <ion-card *ngIf="parentForm.controls.user_photo.value" class="uploadFile uploadPic ion-text-center">
      <img [src]="parentForm.controls.user_photo.value">
    </ion-card>
  </ng-container>
  <ion-row>
    <ion-col size-md="12">
      <ion-label class="required">{{((benData?.verifiedBy=='Manual' || benData?.ben_type_id == 23 || benData?.ben_type_id == 7008 ||
        benData?.special_session_type_id == 1) ? 'Full Name':'Name as on Aadhaar UID')}}</ion-label>
      <ion-item class="item-border ion-no-padding" lines="none">
        <ion-input formControlName="ben_name" placeholder="Enter member name" type="text" appInputTrim
          [appRangeLength]='[2, 30]' appInputChar='username' appConvertToUpperCase maxlength="30"
          (convertedStr)="changedString($event,parentForm?.controls?.ben_name?.value)" errorText=" " ></ion-input>
      </ion-item>
      <ion-label
        *ngIf="!parentForm?.controls?.ben_name?.valid  && (parentForm?.controls?.ben_name?.hasError('inputcharvalidator'))"
        class="error-validation">
        <p>{{'BENEFICIARY.VALIDATION.USERNAME_PATTERN2' | translate}}</p>
      </ion-label>
      <ion-item
        *ngIf="!parentForm?.controls?.ben_name?.valid  && (!parentForm?.controls?.ben_name?.hasError('inputcharvalidator') && parentForm?.controls?.ben_name?.hasError('rangeLength'))"
        class="error-validation">
        <p>{{'BENEFICIARY.VALIDATION.BENEFICIARY_NAME_CHARACTERS' | translate}}</p>
      </ion-item>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size-md="12">
      <ion-label class="required">Gender</ion-label>
      <div class="ion-margin-vertical gender">
        <mat-radio-group formControlName="gender" aria-label="Select an option">
          <mat-radio-button *ngFor="let g of genderList | async" [value]="g.id">{{g.gender}}</mat-radio-button>
        </mat-radio-group>
      </div>
    </ion-col>

  </ion-row>
  <ion-row *ngIf="parentForm?.controls?.dob">
    <ion-col size-md="12">
      <!-- <ion-label class="required">Date of birth</ion-label> -->
      <div class="ion-margin-vertical gender">
        <app-ion-custom-datepicker style="width: 100%;" label="Date of birth" formKey='dob' [parentForm]="parentForm"
          [class]="'noclass'" placeholder="DD/MM/YYYY" [minDate]="minDob" [maxDate]="maxDob">
        </app-ion-custom-datepicker>
      </div>
    </ion-col>

  </ion-row>

  <ion-row *ngIf="benData?.aadharData && !benData?.guardian_ben_id">
    <ion-col size-md="12">
      <app-ion-custom-select style="width: 100%;" label="Year of Birth" formKey='yob' [parentForm]="parentForm"
        (onSelect)="changedYear($event)" [arrObj]="yobList | async" [arrKey]="'id'" [arrKeyVal]="'year'"
        [class]="'noclass'" placeholder="YYYY">
      </app-ion-custom-select>
    </ion-col>
  </ion-row>
  <ion-row>
    <!-- <ion-col size-md="12">
      <app-ion-custom-datepicker style="width: 100%;" label="Date of Birth" formKey='dob' [parentForm]="parentForm"
        [class]="'noclass'" placeholder="DD/MM/YYYY" [minDate]="minDob" [maxDate]="maxDob">
      </app-ion-custom-datepicker>
    </ion-col> -->
  </ion-row>
  <ng-container *ngIf="benData?.ben_type_id == environment.static_ids.PregnantWomen">
    <ion-row>
      <ion-col size-md="12">
        <app-ion-custom-datepicker style="width: 100%;" label="Date of Last Menstrual Cycle" formKey='lmp_date'
          [parentForm]="parentForm" [class]="'noclass'" placeholder="DD/MM/YYYY" [minDate]="minLMPDate"
          [maxDate]="maxLMPDate" (dateValue)="lmpDateEvent($event)">
        </app-ion-custom-datepicker>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size-md="12">
        <ion-label>Blood Group</ion-label>
        <mat-form-field appearance="outline" class="ion-margin-vertical matDropdown">
          <mat-select placeholder="Select from the list" formControlName="blood_group">
            <mat-option *ngFor="let group of bloodGroupArray" [value]="group.groupType">{{group.groupType}}</mat-option>
          </mat-select>
        </mat-form-field>
      </ion-col>
    </ion-row>
    <ion-row>
      <app-ion-custom-datepicker class="not-required" style="width: 100%;" label="Date of Last Pregnancy Outcome"
                        formKey='previous_pregnancy_year' [parentForm]="parentForm" [class]="'noclass'" placeholder="DD/MM/YYYY"
                        [minDate]="minDate" [maxDate]="maxPreviousYear">
      </app-ion-custom-datepicker>
      <!-- <ion-col size-md="12" size="12">
          <ion-label>Previous Pregnancy Year</ion-label>
          <ion-item class="item-border ion-no-padding" lines="none"> 
          <ion-input type="tel" [appMin]="minDate" [appMax]="maxPreviousYear" maxlength="4" minlenth="4" formControlName="previous_pregnancy_year" errorText=" " ></ion-input>
          </ion-item>
        </ion-col> -->
    </ion-row>
  </ng-container>
  <ng-container *ngIf="benData?.ben_type_id == environment.static_ids.Infant_ID || benData?.ben_type_id == environment.static_ids.NewBornBaby">
    <ion-row>
      <ion-col size="12">
        <ion-label>Child Weight at Birth In KG’s
        </ion-label>
        <ion-item class="item-border ion-no-padding" lines="none">
          <ion-input type="number" step="0.01" (keypress)="keypress($event)" placeholder="Child Weight at Birth In KGs" formControlName="child_weight" errorText=" " ></ion-input>
        </ion-item>
        <ion-label *ngIf="this.parentForm?.get('child_weight')?.errors?.pattern" class="error-validation">
          <p>Please enter valid child weight</p>
        </ion-label>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size-md="12">
        <ion-label>Father Name</ion-label>
        <ion-item class="item-border ion-no-padding" lines="none">
          <ion-input formControlName="father_name" placeholder="Enter Father's Name" type="text" maxlength="50"
            appInputTrim [appRangeLength]='[2, 50]' appInputChar='username' appConvertToUpperCase
            (convertedStr)="changedString($event,parentForm?.controls?.father_name.value)" errorText=" " ></ion-input>
        </ion-item>
        <ion-label
          *ngIf="!parentForm?.controls?.father_name?.valid  && (parentForm?.controls?.father_name?.hasError('inputcharvalidator'))"
          class="error-validation">
          <!-- <p>{{'BENEFICIARY.VALIDATION.USERNAME_PATTERN2' | translate}}</p> -->
          <p>{{'Must contain only English Alphabets (“a-z” and “A-Z”), Dot “.”, Hyphen “-”, Open parenthesis “(“ and Close parenthesis “)”' | translate}}</p>
        </ion-label>
        <ion-label
          *ngIf="!parentForm?.controls?.father_name?.valid  && (!parentForm?.controls?.father_name?.hasError('inputcharvalidator') && parentForm?.controls?.father_name?.hasError('rangeLength'))"
          class="error-validation">
          <p>{{'Must be between 2 to 50 Characters' | translate}}</p>

          <!-- <p>{{'BENEFICIARY.VALIDATION.BENEFICIARY_NAME_CHARACTERS' | translate}}</p> -->
        </ion-label>
      </ion-col>
    </ion-row>
  </ng-container>

  <!-- <ion-row
    *ngIf="benData?.verifiedBy=='Manual' && (benData?.added_from == 'self' ||  (benData?.ben_type_id == 5 && benData?.added_from != 'onspot'))">
    <ion-col>
      <ion-label>{{'Last 4 digit of Reference ID' | translate}}</ion-label>
      <ion-item class="item-border ion-no-padding" lines="none">
        <ion-input placeholder="Enter Last 4 digit of Reference ID " numbersOnly  type="tel" formControlName="reference_id"
          minlength="4" maxlength="4" type="tel" errorText=" " ></ion-input>
      </ion-item>

    </ion-col>
  </ion-row> -->

</ng-container>
