export const ErrorCodes = {
  USRRES0004:  'File must not exceed 1000 records',
  IMPUSER002:  'File name is invalid. Please refer Template for valid file names',
  IMPUSER003:  'FIle type must be Excel (.xls/.xlsx)',
  IMPUSER004:  'File must not exceed 1000 records',
  IMPUSER005:  'File record Header is invalid',
  IMPUSER026:  'File is missing',
  IMPUSER027:  'User id is mandatory',
  IMPUSER028:  'Invalid column header sequence for user type',
  IMPUSER029:  'Uploading empty file',
  IMPLOCER030:  'Invalid column header sequence for location type',
  IMPUSER043:  'All columns in template is mandatory',
  IMPUSER044:  'Invalid template column sequence',
  IMPISERR001:  'Internal server error',
  IMPFACI0007:  'Invalid column header sequence for facility type',
  ADMLOC0000:  'Invalid Facility/Location Mapping',
  ADMLOC0001:  'State name is mandatory and should be minimum 1 character and maximum 50 characters ',
  ADMLOC0002:  'Latitude is mandatory and Must be between -90 and 90 ',
  ADMLOC0003:  'Longitude is mandatory and Must be between -180 and 180 ',
  ADMLOC0004:  'District name is mandatory and should be minimum 1 character and maximum 50 characters ',
  ADMLOC0005:  'Block name is mandatory and should be minimum 1 character and maximum 50 characters ',
  ADMLOC0006:  'Village name is mandatory and should be minimum 1 character and maximum 50 characters ',
  ADMLOC0007:  'District Name already exists',
  ADMLOC0008:  'Block Name already exists',
  ADMLOC0009:  'Village Name already exists',
  ADMLOC0010:  'State Name already exists',
  ADMFAC0001:  'Name should be minimum 1 character and maximum 50 characters ',
  ADMFAC0002:  'Street name should be minimum 5 characters and maximum 500 characters ',
  ADMFAC0003:  'Pin should be 6 digits ',
  ADMFAC0004:  'Latitude is mandatory and Must be between -90 and 90 ',
  ADMFAC0005:  'Longitude is mandatory and Must be between -180 and 180 ',
  ADMFAC0006:  'Level is mandatory ',
  ADMFAC0007:  'Facility Name already exists',
  ADMFAC00071:  'DH Name already exists',
  ADMFAC00072:  'CHC/SDH/TH Name already exists',
  ADMFAC00073:  'PHC Name already exists',
  ADMFAC00074:  'SC Name already exists',
  ADMROL0001:  'User Name should be minimum 3 characters and maximum 50 characters ',
  ADMROL0002:  'Mobile Number should be number and of 10 digits ',
  ADMROL0003:  'Role is mandatory ',
  ADMROL0004:  'State is mandatory ',
  ADMROL0005:  'DH is mandatory ',
  ADMROL0006:  'Mobile Number is already registered',
  ADMROL0007:  'User is not known',
  ADMROL0008:  'File format should be JPG, JPEG or PDF',
  ADMROL0009:  'Status value is mandatory',
  ADMROL0010:  'File format should be PNG, JPEG ',
  ADMROL0011:  'User status cannot be changed to Active as User is associated with Inactive Role',
  ADMROL0012:  'Status cannot be changed as X Active Users are associated with the Role In Order to change a Role to Inactive, please make sure no Active Users are associated with it',
  BENREG0001:  'First Name must be alphabets and special characters',
  BENREG0002:  'Last Name is Mandatory and minimum 1 characters and maximum 50 characters ',
  BENREG0003:  'RCHID should be number and should not be greater than 15 digits ',
  BENREG0004:  'Aadhaar Number should be number and should be of 12 digits ',
  BENREG0005:  'Mobile Number should be number and of 10 digits ',
  BENREG0006:  'State is mandatory ',
  BENREG0007:  'District is mandatory ',
  BENREG0008:  'Block is mandatory ',
  BENREG0009:  'Village is mandatory ',
  BENREG0010:  'Asha is mandatory ',
  BENREG0012:  'First Name can be maximum 50 characters long  ',
  BENREG0011:  'First Name should be atleast 1 character long',
  BENDET0001:  'First Name is mandatory',
  BENDET0002:  'Last Name is mandatory',
  BENDET0003:  'Current Age(in yrs) is mandatory and values must be 15 to 49 ',
  BENDET0004:  'Mobile Number should be number and of 10 digits ',
  BENDET0005:  'Mobile No Belongs To is mandatory ',
  BENDET0006:  'RCHID should be number and length should not be greater than 15 digits ',
  BENDET0007:  'Aadhaar Number should be number and of 12 digits ',
  BENDET0008:  'Religion is mandatory ',
  BENDET0009:  'Other Religion is mandatory ',
  BENDET0010:  'Caste is mandatory ',
  BENDET0011:  'BPL / APL is mandatory ',
  BENDET0012:  'JSY Beneficiary is mandatory ',
  BENDET0013:  'PMMVY Beneficiary is mandatory ',
  BENDET0014:  'House / Street is mandatory ',
  BENDET0015:  'Nearest Landmark is mandatory ',
  BENDET0016:  'Pin Code must be 6 digit ',
  BENDET0017:  'First Name is mandatory',
  BENDET0018:  'Last Name is mandatory',
  BENDET0019:  'Blood Group is mandatory ',
  BENDET0020:  'Husband Age (in yrs) is mandatory and must be between 10 to 99 ',
  BENDET0021:  'Using any Contraception Method is mandatory ',
  BENDET0022:  'Method is mandatory ',
  BENDET0023:  'Account Belongs To is mandatory ',
  BENDET0024:  'Account Holder Name  is mandatory ',
  BENDET0025:  'Account Number  is mandatory ',
  BENDET0026:  'Bank / PO Name  is mandatory ',
  BENDET0027:  'Branch Name  is mandatory ',
  BENDET0028:  'IFSC code is mandatory and should not be greater than 11 digits ',
  BENDET0029:  'Other Caste is mandatory ',
  BENDET0030:  'Other Mobile Number belongs To is mandatory ',
  BENDET0031:  'State is mandatory ',
  BENDET0032:  'District is mandatory ',
  BENDET0033:  'Block is mandatory ',
  BENDET0034:  'Village is mandatory ',
  BENDET0035:  'RH Factor is mandatory ',
  BENDET0036:  'Other Method is mandatory ',
  BENPRE0001:  'Abortion (if any) is Mandatory ',
  BENPRE0002:  'Abortion Date is Mandatory ',
  BENPRE0003:  'Abortion Type is Mandatory ',
  BENPRE0004:  'Abortion Kind is Mandatory ',
  BENPRE0005:  'Where Was Abortion Done  is Mandatory ',
  BENPRE0006:  'Maternal Death is Mandatory ',
  BENPRE0007:  'Death Date is Mandatory ',
  BENPRE0008:  'Mother Died in Which Phase of Labour is mandatory ',
  BENPRE0009:  'Post Partum Complication is mandatory ',
  BENPRE0010:  'Complication Type is mandatory ',
  BENPRE0011:  'Probable Cause Of Death is mandatory ',
  BENPRE0012:  'Delivery Done is mandatory ',
  BENPRE0013:  'Place of Delivery is mandatory ',
  BENPRE0014:  'Who Conducted  Delivery is mandatory ',
  BENPRE0015:  'Type of  Delivery is mandatory ',
  BENPRE0016:  'Duration of Labour is mandatory ',
  BENPRE0017:  'Delivery Complication is mandatory ',
  BENPRE0018:  'Any Blood/Blood Products Given to Mother is mandatory ',
  BENPRE0019:  'No of Units Transfused is mandatory and values must be 1 to 50 ',
  BENPRE0020:  'Any Transfusion Reactions is mandatory ',
  BENPRE0021:  'Live Birth is mandatory ',
  BENPRE0022:  'Still Birth is mandatory ',
  BENPRE0023:  'Time of Delivery is mandatory ',
  BENPRE0024:  'Child Gender is mandatory ',
  BENPRE0025:  'Child Weight At Birth is mandatory ',
  BENPRE0026:  'BCG Given is mandatory ',
  BENPRE0027:  'Reason is mandatory ',
  BENPRE0028:  'Other Reason is mandatory ',
  BENPRE0029:  'Hepatitis B Birth Does Given is mandatory ',
  BENPRE0030:  'Reason is mandatory ',
  BENPRE0031:  'Other Reason is mandatory ',
  BENPRE0032:  'OPV Zero Does Given is mandatory ',
  BENPRE0033:  'Reason is mandatory ',
  BENPRE0034:  'Other Reason is mandatory ',
  BENPRE0035:  'Vitamin K Injection Given is mandatory ',
  BENPRE0036:  'Reason is mandatory ',
  BENPRE0037:  'Other Reason is mandatory ',
  BENPRE0038:  'Breastfeeding Started Within 1 Hour  is mandatory ',
  BENPRE0039:  'Reason is mandatory ',
  BENPRE0040:  'Other Reason is mandatory ',
  BENPRE0041:  'Still Birth Type is mandatory ',
  BENPRE0042:  'Other Complication Type is mandatory ',
  BENPRE0043:  'Other Cause of Death is mandatory ',
  BENPRE0044:  'Date of Delivery is mandatory ',
  BENPRE0045:  'Other Who Conducted Delivery is mandatory ',
  BENPRE0046:  'Other Delivery Complication is mandatory ',
  BENPRE0047:  'Other Any Transfusion Reactions is mandatory ',
  BENPRE0048:  'Beneficiary is not known ',
  BENFAC0001:  'Refer Date is mandatory ',
  BENFAC0002:  'Where To Refer  is mandatory ',
  BENFAC0003:  'Facility Refer Reason  is mandatory ',
  BENFAC0004:  'Transportation Type  is mandatory ',
  BENFAC0005:  'Refer Date and Time is mandatory ',
  BENFAC0006:  'Reference From Facility is mandatory ',
  BENFAC0007:  'Is the mother being admitted to this  facility is mandatory ',
  BENFAC0008:  'Other Reference From  is mandatory ',
  BENFAC0009:  'Other Transportation Type  is mandatory ',
  BENFAC0010:  'Other Where To Refer  is mandatory ',
  BENFAC0011:  'Other Women Illness  is mandatory ',
  BENFAC0012:  'Other Facility Refer Reason  is mandatory ',
  BENFAC0013:  'Other  Reason  is mandatory ',
  BENFAC0014:  'Reason for not admitting is mandatory ',
  BENFAC0015:  'Other  Not admitted Reason  is mandatory ',
  BENFAC0016:  'Mother is being sent to which facility  is mandatory ',
  BENFAC0017:  'Other  Facility To   is mandatory ',
  BENFAC0018:  'Beneficiary is not known',
  BENMED0001:  'History of any Blood Transfusion is mandatory ',
  BENMED0002:  'History of any Current or Past Systemic illness is mandatory ',
  BENMED0003:  'Family History of Systemic illness is mandatory ',
  BENMED0004:  'History of any Drug intake is mandatory ',
  BENMED0005:  'What Drug  is mandatory ',
  BENMED0006:  'History of Any Allergies is mandatory ',
  BENMED0007:  'Allergy to What is mandatory ',
  BENMED0008:  'History of Habit Forming Substance is mandatory ',
  BENMED0009:  'Which Type is mandatory ',
  BENMED0010:  'Other Type is mandatory ',
  BENMED0011:  'Gravida (No. of times women got pregnant) is mandatory ',
  BENMED0012:  'Specify Gravida  is mandatory and values must be 6 to 999 ',
  BENMED0013:  'Para (No. of Pregnancies reached 28 weeks)  is mandatory ',
  BENMED0014:  'Child Name  is mandatory ',
  BENMED0015:  'Child Gender  is mandatory ',
  BENMED0016:  'Status  is mandatory ',
  BENMED0017:  'Other History of Obsterical Operations  is mandatory ',
  BENMED0018:  'Age of Death  is mandatory ',
  BENMED0019:  'Child Weight (in kg) is mandatory ',
  BENMED0020:  'Child Current Age (in yrs) is mandatory ',
  BENMED0021:  'Child Current Age (in months) is mandatory ',
  BENMED0022:  'Abortions is mandatory ',
  BENMED0023:  'Gestational Age (in weeks) is mandatory ',
  BENMED0024:  'Abortion Type is mandatory ',
  BENMED0025:  'Abortion Kind is mandatory ',
  BENMED0026:  'Abortion Date is mandatory ',
  BENMED0027:  'Where was Abortion Done is mandatory ',
  BENMED0028:  'Other Where was Abortion Done is mandatory ',
  BENMED0029:  'Previous Pregnancy Complications  is mandatory ',
  BENMED0030:  'History of Obstetrical Operations  is mandatory ',
  BENMED0031:  'Is Women Pregnant  is mandatory ',
  BENMED0032:  'How was it Confirmed is mandatory ',
  BENMED0033:  'Specify Parity  is mandatory and values must be 6 to 999 ',
  BENMED0034:  'Others Past Systemic Illness  is mandatory ',
  BENMED0035:  'Others Family History of Systemic Illness is mandatory ',
  BENMED0036:  'Live Births  is mandatory ',
  BENMED0037:  'Other Previous Pregnancy Complications is mandatory ',
  BENMED0038:  'Beneficiary is not known. ',
  BENPAR0001:  'Foetal Heart Rate(beats per minute) is mandatory ',
  BENPAR0002:  'Amniotic Fluid is mandatory ',
  BENPAR0003:  'Cervical Dilatation (cm) is mandatory ',
  BENPAR0004:  'Contractions per 10 min is mandatory ',
  BENPAR0005:  'Drugs and IV Fluids is mandatory ',
  BENPAR0006:  'Pulse (beats per minute is mandatory) and values must be 40 to 180 ',
  BENPAR0007:  'Blood Pressure Systolic  is mandatory and values must be 20 to 220 ',
  BENPAR0008:  'Blood Pressure Diastolic  is mandatory and values must be 20 to 220 ',
  BENPAR0009:  'Temperature  is mandatory and values must be 35 to 45 ',
  BENPAR0010:  'Contractions Lasts 1  is mandatory ',
  BENPAR0011:  'Contractions Lasts 2  is mandatory ',
  BENPAR0012:  'Contractions Lasts 3  is mandatory ',
  BENPAR0013:  'Contractions Lasts 4  is mandatory ',
  BENPAR0014:  'Contractions Lasts 5  is mandatory ',
  BENPAR0015:  'Beneficiary is not known',
  BENANC0001:  '1st Day of LMP  is mandatory ',
  BENANC0002:  'Weight(in kg)  is mandatory and must be 30 to 200 ',
  BENANC0003:  'Height(in cm)  is mandatory and must be 105 to 210 ',
  BENANC0004:  'Any Symptoms of High Risk  is mandatory ',
  BENANC0005:  'BP Check  is mandatory ',
  BENANC0006:  'Not Done Reason  is mandatory ',
  BENANC0007:  'Other Reason  is mandatory ',
  BENANC0008:  'Pulse Rate(beats per minute)  is mandatory and must be 40 to 200 ',
  BENANC0009:  'Repository Rate(breaths per minute)  is mandatory and must be 10 to 30 ',
  BENANC0010:  'Pallor  is mandatory ',
  BENANC0011:  'Jaundice  is mandatory ',
  BENANC0012:  'Odema  is mandatory ',
  BENANC0013:  'Breast Examination  is mandatory ',
  BENANC0014:  'Inverted / Flat Nipples  is mandatory ',
  BENANC0015:  'Current Symptoms indicating Discomfort  is mandatory ',
  BENANC0016:  'Other Current Symptoms indicating Discomfort  is mandatory ',
  BENANC0017:  'Other Any Symptoms of High Risk  is mandatory ',
  BENANC0018:  'Fundal Height / Size Of the Uterus(in weeks)  is mandatory ',
  BENANC0019:  'Pregnancy Test  is mandatory ',
  BENANC0020:  'Not Done Reason  is mandatory ',
  BENANC0021:  'Other Reason  is mandatory ',
  BENANC0022:  'UPT Result  is mandatory ',
  BENANC0023:  'USG Result  is mandatory ',
  BENANC0024:  'UPT Test Date  is mandatory ',
  BENANC0025:  'USG Test Date  is mandatory ',
  BENANC0026:  'Hemoglobin Test   is mandatory ',
  BENANC0027:  'Not Done Reason   is mandatory ',
  BENANC0028:  'Other Reason   is mandatory ',
  BENANC0029:  'HB (gm%)  is mandatory and values must be 2 to 18 ',
  BENANC0030:  'Hemoglobin Test Date  is mandatory ',
  BENANC0031:  'Urine Test  for Sugar is mandatory ',
  BENANC0032:  'Not Done Reason   is mandatory ',
  BENANC0033:  'Other Reason   is mandatory ',
  BENANC0034:  'Urine Sugar  is mandatory ',
  BENANC0035:  'Urine Sugar  Test Date  is mandatory ',
  BENANC0036:  'Urine Test  for Proteins is mandatory ',
  BENANC0037:  'Not Done Reason   is mandatory ',
  BENANC0038:  'Other Reason   is mandatory ',
  BENANC0039:  'Urine Albumin  is mandatory ',
  BENANC0040:  'Urine Albumin  Test Date  is mandatory ',
  BENANC0041:  'Rapid Malaria Test is mandatory ',
  BENANC0042:  'Not Done Reason   is mandatory ',
  BENANC0043:  'Other Reason   is mandatory ',
  BENANC0044:  'Rapid Malaria Test Result  is mandatory ',
  BENANC0045:  'Rapid Malaria Test Date  is mandatory ',
  BENANC0046:  'Blood Group Test is mandatory ',
  BENANC0047:  'Not Done Reason   is mandatory ',
  BENANC0048:  'Other Reason   is mandatory ',
  BENANC0049:  'Blood Group  is mandatory ',
  BENANC0050:  'RH Factor  is mandatory ',
  BENANC0051:  'Blood Group Test Date  is mandatory ',
  BENANC0052:  'VDRL / RPR Test  is mandatory ',
  BENANC0053:  'Not Done Reason   is mandatory ',
  BENANC0054:  'Other Reason   is mandatory ',
  BENANC0055:  'VDRL / RPR Test  Result is mandatory ',
  BENANC0056:  'VDRL / RPR Test  Date is mandatory ',
  BENANC0057:  'HIV Screening  Test  is mandatory ',
  BENANC0058:  'Not Done Reason   is mandatory ',
  BENANC0059:  'Other Reason   is mandatory ',
  BENANC0060:  'HIV Screening  Test  Result is mandatory ',
  BENANC0061:  'HIV Screening  Test  Date is mandatory ',
  BENANC0062:  'HBsAG  Test  is mandatory ',
  BENANC0063:  'Not Done Reason   is mandatory ',
  BENANC0064:  'Other Reason   is mandatory ',
  BENANC0065:  'HBsAG  Test   Result is mandatory ',
  BENANC0066:  'HBsAG  Test  Date is mandatory ',
  BENANC0067:  'Blood Sugar  Test  is mandatory ',
  BENANC0068:  'Not Done Reason   is mandatory ',
  BENANC0069:  'Other Reason   is mandatory ',
  BENANC0070:  'Fasting values  must be 60 to 500 ',
  BENANC0071:  'Post Prandial values  must be 60 to 500 ',
  BENANC0072:  'Random values  must be 60 to 500 ',
  BENANC0073:  'Test For Gestational Diabetes Mellitus is mandatory ',
  BENANC0074:  'Not Done Reason   is mandatory ',
  BENANC0075:  'Other Reason   is mandatory ',
  BENANC0076:  'Result is mandatory ',
  BENANC0077:  'Gestational Diabetes Mellitus test date is mandatory ',
  BENANC0078:  'TSH Test is mandatory ',
  BENANC0079:  'Not Done Reason   is mandatory ',
  BENANC0080:  'Other Reason   is mandatory ',
  BENANC0081:  'TSH Test Result is mandatory ',
  BENANC0082:  'TSH Test Date is mandatory ',
  BENANC0083:  'TT / Td Dose is  mandatory ',
  BENANC0084:  'Not Done Reason   is mandatory ',
  BENANC0085:  'Other Reason   is mandatory ',
  BENANC0087:  'TT / TD Date is mandatory ',
  BENANC0088:  'No of Folic Acid Tabs Given is mandatory and values must be 0 to 60 ',
  BENANC0089:  'No of IFA Tabs Given is mandatory and values must be 0 to 400 ',
  BENANC0090:  'No of calcium tablets given  is mandatory and values must be 0 to 400 ',
  BENANC0091:  'Albendazole Given is mandatory ',
  BENANC0092:  'BP Systolic(mm Hg) is mandatory and values must be 50 to 300 ',
  BENANC0093:  'BP Diastolic(mm Hg) is mandatory and values must be 30 to 200 ',
  BENANC0094:  'Beneficiary is not known',
  BENANC0095:  'Abdominal Examination is not applicable till 12 weeks of pregnancy! ',
  REPREP0001:  'Based On  is mandatory ',
  REPREP0002:  'Date Range  is mandatory ',
  USRPRF0001:  'User is not known',
  USRPRF0002:  'Invalid OTP',
  USRPRF0003:  'No user found',
  USRPRF0004:  'New Mobile Number cannot be same as Current Mobile Number',
  USRPRF0005:  'The new mobile number is already registered',
  USRPRF0006:  'New Password cannot be empty ',
  USRPRF0007:  'Current Password does not match ',
  USRPRF0008:  'New password cannot be same as current password ',
  USRPRF0009:  'Phone Number should be of 10 digits ',
  USRPRF0010:  'OTP should be of 6 digits',
  USRAUT0001:  'Invalid Username or Password ',
  BENDOC0001:  'No files were uploaded ',
  BENDOC0002:  'File format should be PNG, JPG, JPEG or PDF ',
  BENSUM0001:  'Invalid User ',
  BENSUM0002:  'No files were uploaded ',
  USRPRF0011:  'Enter the OTP to help us verify your identity',
  USRPRF0012:  'This mobile number is not registered ',
  USRRES0002:  'Filename is incorrect',
  USRRES0003:  'Invalid file',
  USRRES0005:  'Invalid column name',
  ROLE0000001:  'Role Name already exists',
  ADMROL0014:  'Role is Inactive ',
  ADMDASH0012:  'Cannot Delete Default Filter',
  ADMDASH0009:  'Filter Title Already Exists'
};
