import {Component} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilsService } from 'src/app/modules/shared/services/utils.service';

@Component({
  selector: 'notification-dialog',
  templateUrl: './notification-dialog.html',
  styleUrls:['./notification-dialog.scss']
})
export class NotificationDialog {
  constructor(
    private util: UtilsService,
    public dialogRef: MatDialogRef<NotificationDialog>,
  ) {
  }

  onClose() {
    this.dialogRef.close(0);
  }

}
