import {Statuses} from "../../constants/app.constants";
import {Action, createReducer, on} from "@ngrx/store";
import * as regActions from "../actions/registration.actions";


export interface RegistrationState {
  data: any;
  status: Statuses
}

export const initialState: RegistrationState = {
  data: null,
  status: Statuses.UNINITIALIZED
};

const registration = createReducer(
  initialState,
  on(regActions.SetRegistration, (state, payload) => {
    return {
      ...state,
      data: payload.reg,
      status: Statuses.LOADED
    };
  }),
  on(regActions.LoadingRegistration, (state) => {
    return {
      ...state,
      data: null,
      status: Statuses.LOADING
    }
  }),
  on(regActions.ResetRegistration, (state => ({...state, data: null, status: Statuses.UNINITIALIZED})))
);

export function registrationReducer(state: RegistrationState | undefined, action: Action) {
  return registration(state, action);
}
