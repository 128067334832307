import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter, Output } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { EncDecService } from 'src/app/modules/shared/services/encryption-decryption.service';
import { DataService } from 'src/app/modules/shared/services';
import { Device } from '@capacitor/device';
import { AddRecipientService } from '../../add-recipient/services/add-recipient.service';
import { LookUpService } from 'src/app/modules/shared/services/look-up.service';
import { ReplaySubject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class BeneficiaryService {
  public beneficiaryDataSubject: BehaviorSubject<any>;
  public beneficiaryData: Observable<any>;
  declaredDeathReplaySubject = new ReplaySubject(1);
  loadVaccinationchart = new ReplaySubject(1);

  constructor(
    private http: HttpClient, 
    private encDecSer: EncDecService) {
    this.beneficiaryDataSubject = new BehaviorSubject<any>(null);
    this.beneficiaryData = this.beneficiaryDataSubject.asObservable();

  }
  
  getBeneficairyDetails(beneficiaryID): Observable<any> {
    if (window.navigator.onLine) {
      const payload = {
        beneficiaryId : this.encDecSer.setWithSpace(beneficiaryID)
      }
      return this.http.post(`${environment.vaccinator_url}/getbeneficiary_details`, payload)
        .pipe(map((r: any) => {
          r = this.encDecSer.decryptPhotoId(r); 
          r = this.encDecSer.decryptMobileNo(r); 
          // console.log('@@@@@@@@@@@@@@@@@', r);
          this.changeRelatedValues(r);
          // r.linkedmembers.map(l=>{
          //     l = this.encDecSer.decryptPhotoId(l);
          //     return l; 
          // });
          r.linkedmembers.map(l=> {
            this.changeRelatedValues(l);
            this.encDecSer.decryptPhotoId(l);this.encDecSer.decryptMobileNo(l)
          });

          this.beneficiaryDataSubject.next(r);
          return r;
        }));
    } else {
      return of(null);
    }
  }


  getVaccinationDetailsOfBeneficiary(beneficiaryID, materialCatType): Observable<any> {
    if (window.navigator.onLine) {
      if (materialCatType == 'Universal Immunization Programme' || materialCatType == 'UIP') {
        return this.http.get(`${environment.vaccinator_url}/getuipvaccinations_details/${beneficiaryID}`);
      }
      else if (materialCatType.toUpperCase() != 'OTHERS') {
        return this.http.get(`${environment.vaccinator_url}/getvaccinations_details/${beneficiaryID}`);
      } else {
        return this.http.get(`${environment.vaccinator_url}/othervaccinations_details/${beneficiaryID}`);
      }
    }
  }

  getVaccinationChart(benfRefId, lmcDate?, pregNumber?) {
    const payload = {
      "beneficiary_ref_id": this.encDecSer.setWithSpace(benfRefId),
      "lmc_date": lmcDate ? lmcDate : undefined,
      pregnancy_number:pregNumber || undefined
    }
    return this.http.post(`${environment.vaccinator_url}/vaccinationsChartJson`, payload);
  }

  beneficaryDelcaredDeath(refId) {
    refId.beneficiary_ref_id = this.encDecSer.setWithSpace(refId.beneficiary_ref_id);
    return this.http.post(`${environment.vaccinator_url}/declareBenDeath`, refId);
  }

  isHcwFlw(benf) {
    if (benf.beneficiary_type_category_id && (benf.beneficiary_type_category_id == '1' || benf.beneficiary_type_category_id == '2')) {
      return true;
    } else if (!benf.beneficiary_type_category_id && [3001, 3, 4, 1001, 1002, 2001, 4001, 5001, 6001, 7001].includes(benf.ben_type_id)) {
      return true;
    }
    return false;
  }


  minDateForDeath(refId) {
    refId.beneficiary_ref_id = this.encDecSer.setWithSpace(refId.beneficiary_ref_id);
    return this.http.post(`${environment.vaccinator_url}/getMinDateForDeclareDeath`, refId);
  }

  getMinmaxDateDOBEdit(benRefId): Observable<any> {
    if (window.navigator.onLine) {
      return this.http.post(`${environment.vaccinator_url}/minmaxDateDOBEdit`, {beneficiary_ref_id: this.encDecSer.setWithSpace(benRefId)});
    } else {
      return of(null);
    }
  }
  changeRelatedValues(data){
    (data.relation_with_guardian == 1 || data.relation_with_guardian == 'Mother') ? data.relation_with_guardian = 'Mother' :
      (data.relation_with_guardian == 2 || data.relation_with_guardian == 'Father') ? data.relation_with_guardian = 'Father' :
        data.relation_with_guardian = 'Guardian';
   }

  benVaccineLinkStatus(benRefId): Observable<any> {
    if (window.navigator.onLine) {
      return this.http.post(`${environment.vaccinator_url}/benVaccineLinkStatus`, {beneficiary_reference_id: this.encDecSer.setWithSpace(benRefId)});
    } else {
      return of(null);
    }
  }
}
