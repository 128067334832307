import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import moment from 'moment';
import { map, switchMap } from 'rxjs/operators';
import { UtilsService } from 'src/app/modules/shared/services/utils.service';
import { AddRecipientService } from 'src/app/screens/add-recipient/services/add-recipient.service';
import { RecipientService } from '../../services/recipient.service';
import { Device } from '@capacitor/device';
import { ActivatedRoute, Router } from '@angular/router';
import { LookUpService } from 'src/app/modules/shared/services/look-up.service'
import { Subscription } from 'rxjs';
import { SessionService } from 'src/app/screens/session/services/session.service';
import { Location } from '@angular/common';
import { EncDecService } from 'src/app/modules/shared/services/encryption-decryption.service';
import { select, Store } from '@ngrx/store';
import { getUserDetails } from 'src/app/modules/core/store/selectors/user.selectors';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-vaccinate-member',
  templateUrl: './vaccinate-member.component.html',
  styleUrls: ['./vaccinate-member.component.scss'],
})
export class VaccinateMemberComponent implements OnInit {
  benfSubscription: Subscription;
  @Input() activeStepIndex = null;
  @Output() onStepChange: EventEmitter<any> = new EventEmitter();
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Input() noBack: boolean = false;
  verifiedData;
  sessionInfo;
  currentTime = new Date();
  coordinates: any = null;
  deviceInfo: any;
  isRejected: boolean = false;
  genderList;
  gender;
  userRole;
  environment;
  allMaterailsData: any = [];
  formSubmitted = false;
  
  constructor(
    public addRecipientService: AddRecipientService,
    public recipientService: RecipientService,
    private lookupService: LookUpService,
    private sessionService: SessionService,
    public util: UtilsService,
    public router: Router,
    private loc: Location,
    private encDecService: EncDecService,
    private store$: Store,
    public lookup: LookUpService,
    public cdr:ChangeDetectorRef
  ) {
    this.store$.pipe(select(getUserDetails)).subscribe(userInfo => {
      this.userRole = userInfo.role;
    });
    // this.lookup.getMaterials().subscribe(m => { this.allMaterailsData = m });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // this.lookup.getMaterials().subscribe(m => { this.allMaterailsData = m });
    if (changes.activeStepIndex) {
      if (this.activeStepIndex == 'VACCINATEMEMBER') {
        this.reloadComponent();
      } else {
        this.unSubscribe();
      }
    }
  }
 async ngOnInit() { 
  await this.lookup.getMaterials().subscribe(m => { 
    this.allMaterailsData = m
    this.cdr.detectChanges();
   });

  }

  unSubscribe() {
    if (this.benfSubscription) this.benfSubscription.unsubscribe();
    // if(this.benfVerSubscription)this.benfVerSubscription.unsubscribe();
  }

  reloadComponent() {
    this.formSubmitted = false;
    this.environment = environment
    this.benfSubscription = this.addRecipientService.benSuccessData.subscribe(res => {
      this.verifiedData = res;
      console.log('verifiedData', this.verifiedData)
      if (this.verifiedData?.date_of_session) {
        this.currentTime = this.verifiedData?.date_of_session;
      }
      // this.getGenderName(this.verifiedData?.ben_gender)
      // if(this.verifiedData!="" && this.verifiedData != undefined && this.verifiedData != null)
      // this.recipientService.getSessionInfo(this.verifiedData?.verified_in_session).subscribe(res=>{
      //   console.log("sessionInfo",res)
      //   this.sessionInfo=res;
      // })
      this.gender = this.recipientService.getGenderName(this.verifiedData?.ben_gender);
    })


  }

  closeDialog() {
    this.onClose.emit()
  }

  gotoStep(val) {
    this.onStepChange.emit(val);
  }
  async saveMetaInfo() {
    const deviceInfo = await Device.getInfo();
    const metaPostData = {
      "beneficiary_id": this.verifiedData?.beneficiary_id,
      "vaccinator_device": deviceInfo.model,
      'vaccinator_lat': this.coordinates?.latitude ? (this.coordinates.latitude).toString() : '0',
      'vaccinator_long': this.coordinates?.longitude ? (this.coordinates.longitude).toString() : '0',
      "vaccination_datetime": moment().format()
    }
    this.recipientService.saveMetaInfoOfVaccination(metaPostData).subscribe();

  }
  rejectMember() {
    this.isRejected = true;
    this.verifiedData.isRejected = true;
    this.addRecipientService.benSuccessDetails = this.verifiedData;
    this.gotoStep('next')

  }
  nextStep() {
    let sessionId = null;
    if (this.loc.path().match(/session\/[^\s]*/g)) {
      sessionId = this.loc.path().replace('/session/', '');
    }
    const payload = {
      vaccination_status: 1,
      session_id: this.verifiedData.session_id,
      session_site_id: this.verifiedData.session_site_id,
      session_state_id: this.verifiedData.state_id,
      session_district_id: this.verifiedData.district_id,
      asha_user_id: this.verifiedData.asha_user_id
    }
    if (this.verifiedData.session_type_name == 'UIP') {
      payload['is_past_dated'] = this.verifiedData.isPastDated ? true : undefined;
      payload['materialDetails'] = this.verifiedData.materialDetails;
      payload['village_id'] = this.verifiedData.village_id;
      payload['health_village_id'] = this.verifiedData.health_village_id;
      payload['village_name'] = this.verifiedData.village_name;
      payload['health_village_name'] = this.verifiedData.health_village_name;
      payload['ward_id'] = this.verifiedData.ward_id;
      payload['health_ward_id'] = this.verifiedData.health_ward_id;
      payload['ward_name'] = this.verifiedData.ward_name;
      payload['health_ward_name'] = this.verifiedData.health_ward_name;
     
      this.formSubmitted = true;
      this.recipientService.updateMultiUipSessionVaccination(payload,this.verifiedData.dob).subscribe(res => {
        if(res){
        let successMessage = this.verifiedData.isPastDated ? 'Member Vaccination Details Updated Successfully' : 'Vaccinated Successfully'
        this.util.presentToastSuccess(successMessage); //res['message']
        this.verifiedData.isRejected = false;
        this.verifiedData.vaccination_status = payload.vaccination_status;
        this.addRecipientService.benSuccessDetails = this.verifiedData;
        if (sessionId && payload.session_id && payload.session_id == sessionId) {
          this.sessionService.refreshSessionData$.next(true);
        }
        this.formSubmitted = false;
        if (this.verifiedData?.isPastDated) {
          this.addMoreVaccine()
        } else {
          this.gotoStep('next')
        }
      }else{
        this.formSubmitted = false;
      }
        // this.gotoStep('next')
      }, e=>{
        this.formSubmitted = false;
      })
    }
    else if (this.verifiedData.session_type_name == 'Others') {
      this.recipientService.updateVaccinationUIPStatus(payload).subscribe(res => {
        this.util.presentToastSuccess(res['message']);
        this.verifiedData.isRejected = false;
        this.verifiedData.vaccination_status = payload.vaccination_status;
        this.addRecipientService.benSuccessDetails = this.verifiedData;
        if (sessionId && payload.session_id && payload.session_id == sessionId) {
          this.sessionService.refreshSessionData$.next(true);
        }
        if (this.verifiedData?.isPastDated) {
          this.addMoreVaccine()
        } else {
          this.gotoStep('next')
        }
        // this.gotoStep('next')
      })
    }
    else {
      if (this.isPrecautionDose(this.verifiedData)) {
        payload['precaution_dose'] = 1;
      }
      // payload['precautionDose'] = (this.verifiedData.vaccination_doses_due == this.verifiedData.dose_scheduled +1)? 1 : undefined;
      this.recipientService.updateVaccinationStatus(payload).pipe(
        switchMap(r => {
          this.saveMetaInfo();
          return this.recipientService.getApplicationTrackingDetails(this.verifiedData.beneficiary_reference_id);
        })).subscribe(res => {
          this.util.presentToastSuccess("vaccinated successfully");
          this.verifiedData.isRejected = false;
          this.verifiedData.vaccination_status = payload.vaccination_status;
          // this.verifiedData.batch_number = this.sessionInfo.batch_number;
          this.addRecipientService.benSuccessDetails = this.verifiedData;
          if (sessionId && payload.session_id && payload.session_id == sessionId) {
            this.sessionService.refreshSessionData$.next(true);
          }
          // this.gotoStep('next')
          if (this.verifiedData?.isPastDated) {
            this.addMoreVaccine()
          } else {
            this.gotoStep('next')
          }
        })
    }
  }
  isPrecautionDose(benf) {
    if (this.verifiedData.precaution_dose &&
      (this.verifiedData.material_id == 7
        && (parseInt(benf.material_id) == 2 || parseInt(benf.material_id) == 3)
        && benf.vaccination_doses_due == 3
      ) ||
      (parseInt(benf.material_id) == 7 && benf.vaccination_doses_due == 3) ||
      (parseInt(benf.material_id) <= 4 && benf.vaccination_doses_due == 3) ||
      (benf.material_id == 5 && benf.vaccination_doses_due == 4)
    ) {
      return true;
    }
    return false;
  }
  labelName(doseArray) {
    const materialDoseInfo = this.allMaterailsData.find(e => e.material_id == doseArray.material_id);
    if (doseArray.is_precaution) {
      return materialDoseInfo?.display_precaution_dose_names[doseArray.material_dose - 1];
    }
    return materialDoseInfo?.display_dose_names[doseArray.material_dose - 1];
  }
  addMoreVaccine() {
    if (this.util.getPlatformWidth() > 575) { this.onStepChange.emit('add-vaccine'); }
    else {
      this.router.navigateByUrl(`/member-vaccination-info/${this.verifiedData.beneficiary_id}/${this.verifiedData.session_id}`)
    }

  }
}
