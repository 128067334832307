<!-- <div class="abha-num-generate-wrapper" *ngIf="currentStage === 1">
  <div class="top-msg msg-block">
    <span class="important-txt">
      <span class="alert-icon">
        <span class="icon-info_outline"></span>
      </span>
      <span class="vtxt accessibility-plugin-ac">
        {{'ABHA_SCREEN.ENTER_AADHAR_DETAILS' | translate}}
      </span>
    </span>
  </div>
  <form [formGroup]="abhaForm" autocomplete="off">
    <ion-row>
      <ion-col size-lg="6" size-md="8" size-sm="7" size-xs="12">
        <div lines="none">
          <ion-item>
            <mat-form-field appearance="outline" class="enter_aadhar_field">
              <input class="accessibility-plugin-ac" matInput numbersOnly aadhaarValid
                (keyup)="restrictUserToTypeMoreThanLength($event, 12)" appAadharNumber
                [appRange]="[200000000000, 999999999999]" formControlName="photo_id_number"
                placeholder="Enter Aadhaar Number">
            </mat-form-field>

            <mat-error
              *ngIf="!abhaForm.controls.photo_id_number.valid  && (abhaForm.controls.photo_id_number.hasError('range'))"
              class="error-validation accessibility-plugin-ac ion-item-start">
              <span class="accessibility-plugin-ac">
                {{'BENEFICIARY.VALIDATION.AADHAAR_NUMBER_DIGITS' |
                translate}}</span>
            </mat-error>

            <mat-error *ngIf="!(abhaForm.controls.photo_id_number.hasError('range')) &&
                  (abhaForm.controls.photo_id_number.hasError('notValidAadhaar'))"
              class="error-validation accessibility-plugin-ac">
              <span class="accessibility-plugin-ac">
                {{'BENEFICIARY.VALIDATION.INVALID_AADHAAR' |
                translate}}</span>
            </mat-error>
          </ion-item>

        </div>
      </ion-col>
      <ion-col size-lg="3" size-md="4" size-sm="5" size-xs="12" class="ion-text-start">
        <ion-button [disabled]="abhaForm.invalid || !abhaForm.value.aadhar_concent" (click)="generateAadhaarOTP()" role="button" type="submit"
          class="primary accessibility-plugin-ac aadhar-submit-btn ion-text-start">
          {{'CERT_CORRECTION.SUBMIT_BTN' | translate}}
        </ion-button>
      </ion-col>
    </ion-row>

    <ion-item class="benificiary-checkbox" lines="none">
      <ion-checkbox formControlName='aadhar_concent'></ion-checkbox>
      <ion-label class="no-ellipse-checkbox-label">{{'BENEFICIARY.AADHAAR_CONCENT' | translate}}
        <a (click)="openBottomSheet()" class="view-more concen-btn"
          style="text-decoration: none;font-weight: normal;">{{'VIEW_MORE' | translate}}</a>
        <span *ngIf="!voiceRcgn.isStoppedSpeechRecog" class="label-tooltip-aadhaar aadhar-mike-icon"
          tooltip="Say I CONFIRM" positionH="right" positionV="top">
          <span class="icon-microphone custom-mike"></span>
        </span>
      </ion-label>
    </ion-item>
  </form>
</div> -->

<!-- <ng-container *ngIf="currentStage === 2">
    <form autocomplete="off" [formGroup]='otpForm' (ngSubmit)='verifyVaccinatorOTP()'>
        <ion-grid class="inputFields sidebar-inner-content">
            <h3 class="pageSubTitle ion-text-uppercase">Generate ABHA Number</h3>
            <ion-row>
                <ion-col size="12">
                    <ion-label>
                        OTP sent to mobile number linked with Aadhar
                    </ion-label>
                    <ion-item class="item-border ion-no-padding" lines="none">
                        <ion-input autocomplete="off" formControlName='otp' type='tel' maxlength="6" appIntegerInput [appRange]='[000001,999999]' [appMin]='000001' [appMax]='999999' placeholder="- - - - - -" errorText=" " ></ion-input>
                        <ion-button (click)="generateAadhaarOTP()" [disabled]="timer" class="linkBtn" expand="full" fill="outline">
                            Resend
                        </ion-button>
                    </ion-item>
                    <ion-label *ngIf="timer">{{timer + ' sec '}}</ion-label>
                    <ion-label *ngIf="formSubmitted && !otpForm.controls.otp.valid  && (otpForm.controls.otp.hasError('required'))" class="error-validation ">
                        <p>Please Enter OTP</p>
                    </ion-label>
                    <ion-label *ngIf="!otpForm.controls.otp.valid  && (otpForm.controls.otp.hasError('range'))" class="error-validation ">
                        <p>Must be 6 digits</p>
                    </ion-label>

                </ion-col>
            </ion-row>
        </ion-grid>
        <ion-row>
          <ion-button class="primaryBtn" expand="full" [disabled]="verificationInprogress" shape="round" type="submit">
            <ion-spinner *ngIf="verificationInprogress" name="lines-small"></ion-spinner>
            Verify OTP </ion-button>
        </ion-row>
    </form>
</ng-container>

<ng-container *ngIf="currentStage === 4">
  <ion-row>
    <ion-col size-lg="8" size-md="8" size-sm="12" size-xs="12" offset-lg="2" offset-md="2" class="register-box">
      <ion-item>
        <ion-col size-lg="12" size-md="12" size-sm="12" size-xs="12" class="register-header">
          <h3>
            <a [routerLink]="['/dashboard']" aria-label="Back" tabindex="0" class="back-arrow">
              <ion-icon name="arrow-back-outline" alt="back-arrow" title="back-arrow"></ion-icon>
            </a>
            <span class="accessibility-plugin-ac" tabindex="0">{{'ABHA_SCREEN.CREATE_LINK_ABHA_ADDRESS' | translate}}
            </span>
          </h3>
        </ion-col>
      </ion-item>

      <ion-row *ngIf="!enableOtpForm">
        <ion-col size="12">
          <div class="abha_address_form">
            <div class="top_heading">
              <h4 class="accessibility-plugin-ac" tabindex="0">
                {{'ABHA_SCREEN.LINK_ADDRESS_NOTE_1' | translate}}
              </h4>
            </div>
            <ion-row>
              <ion-col size-lg="12" class="ion-text-start">
                <h5 class="accessibility-plugin-ac" tabindex="0">
                  {{'ABHA_SCREEN.LINK_ADDRESS_NOTE_2' | translate}}
                </h5>
              </ion-col>
            </ion-row>
            <form [formGroup]="abhaAddressForm" (ngSubmit)="submitABHAAddress()" autocomplete="off">

              <ion-row>
                <ion-col size-lg="6">
                  <ion-item>

                    <mat-form-field appearance="outline" class="enter_name_field">

                      <input class="accessibility-plugin-ac" matInput
                        [appRangeLength]='[4, 32]' autocomplete="off" placeholder="Name" formControlName="name" (keyup)="removeWhiteSpace($event)"/>
                    </mat-form-field>
                    <select class="custom_dropdown">
                      <option>@abdm</option>
                    </select>
                    <mat-error
                      *ngIf="!abhaAddressForm.controls.name.valid  &&
                        (!abhaAddressForm.controls.name.hasError('inputcharvalidator') && abhaAddressForm.controls.name.hasError('rangeLength'))"
                      class="error-validation accessibility-plugin-ac">
                      <p class="accessibility-plugin-ac">
                        {{'ABHA_SCREEN.ABHAADDRESS_CHARACTERS' | translate}}
                      </p>
                    </mat-error>
                    <mat-error
                      *ngIf="isSubmitted && abhaAddressForm.controls['name'].invalid && abhaAddressForm.controls['name'].errors.required"
                      class="error-validation accessibility-plugin-ac">
                      {{'BENEFICIARY.VALIDATION.PASSPORT_REQ'
                      | translate}}
                    </mat-error>
                  </ion-item>
                  <h5 *ngIf="aadharNum">{{'ABHA_SCREEN.ENTER_AADHAAR' | translate}}</h5>
                  <ion-item *ngIf="aadharNum">

                    <mat-form-field appearance="outline" class="enter_name_field">
                      <input class="accessibility-plugin-ac" matInput numbersOnly aadhaarValid
                        (keyup)="restrictUserToTypeMoreThanLength($event, 12)" appAadharNumber
                        [appRange]="[200000000000, 999999999999]" formControlName="photo_id_number"
                        placeholder=" Enter aadhaar number">
                    </mat-form-field>
                    <mat-error
                      *ngIf="!abhaAddressForm.controls.photo_id_number.valid  && (abhaAddressForm.controls.photo_id_number.hasError('range'))"
                      class="error-validation accessibility-plugin-ac ion-item-start">
                      <span class="accessibility-plugin-ac">
                        {{'BENEFICIARY.VALIDATION.AADHAAR_NUMBER_DIGITS' |
                        translate}}</span>
                    </mat-error>

                    <mat-error *ngIf="!(abhaAddressForm.controls.photo_id_number.hasError('range')) &&
                            (abhaAddressForm.controls.photo_id_number.hasError('notValidAadhaar'))"
                      class="error-validation accessibility-plugin-ac">
                      <span class="accessibility-plugin-ac">
                        {{'BENEFICIARY.VALIDATION.INVALID_AADHAAR' |
                        translate}}</span>
                    </mat-error>
                  </ion-item>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col size-lg="12">
                  <ion-button role="button" [disabled]="abhaAddressForm.invalid" type="submit"
                    class="primary accessibility-plugin-ac aadhar-submit-btn ion-text-start">
                    {{'ABHA_SCREEN.LINK_ABHA_ADDRESS' | translate}}
                  </ion-button>
                </ion-col>
              </ion-row>
            </form>
          </div>
        </ion-col>
      </ion-row>

      <ion-row *ngIf="enableOtpForm">
        <ion-col size="12">
          <div class="abha_otp_form">
            <form [formGroup]="otpForm" autocomplete="off">
              <ion-row>
                <ion-col size-lg="12" class="ion-text-start">
                  <h4 class="accessibility-plugin-ac" tabindex="0">
                    {{'ABHA_SCREEN.LINK_AADHAR_OTP' | translate}}
                  </h4>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col size-lg="6" size-md="8" size-sm="7" size-xs="12">
                  <div lines="none">
                    <ion-item>
                      <mat-form-field appearance="outline" class="enter_otp_field">
                        <input class="accessibility-plugin-ac" #otp matInput formControlName="otp"
                          (keyup)="restrictUserToTypeMoreThanLength($event, 6, 'otpNumber')" autocomplete="off"
                          maxlength="6" placeholder="{{'LOGIN.ENTER_OTP' | translate}}" />
                      </mat-form-field>
                      <mat-error
                        *ngIf="!(otpForm.controls.otp.hasError('range'))  && (otpForm.controls.otp.hasError('pattern'))"
                        class="error-validation">
                        <span
                          class="accessibility-plugin-ac">{{'BENEFICIARY.VALIDATION.MOBILE_PATTERN_STARTS_WITH'
                          | translate}}</span>
                      </mat-error>
                    </ion-item>
                  </div>
                </ion-col>
                <ion-col size-lg="3" size-md="4" size-sm="5" size-xs="12" class="ion-text-start">
                  <ion-button role="button" type="submit" (click)="submitABHAAddress()"
                    class="primary accessibility-plugin-ac aadhar-submit-btn ion-text-start">
                    {{'ABHA_SCREEN.VERIFY' | translate}}</ion-button>
                </ion-col>
              </ion-row>
            </form>
          </div>
        </ion-col>
      </ion-row>
    </ion-col>
  </ion-row>
</ng-container>
<ng-container *ngIf="currentStage === 3 || currentStage === 5">
  <div class="page-wrapper">
    <div class="register-wrap">
      <ion-grid>

        <ion-row>
          <ion-col size-lg="8" size-md="8" size-sm="12" size-xs="12" offset-lg="2" offset-md="2" class="register-box">
            <ion-item>
              <ion-col size-lg="12" size-md="12" size-sm="12" size-xs="12" class="register-header">
                <h3>
                  <span class="accessibility-plugin-ac" tabindex="0">{{header}}
                  </span>
                </h3>
              </ion-col>
            </ion-item>
            <ion-col size-lg="12" size-md="12" size-sm="12" size-xs="12" class="ion-text-center">
              <div class="success_block">
                <span class="success_icon">
                  <img src="../../../assets/images/success-icon.svg">
                </span>
                <h4 class="accessibility-plugin-ac">{{msg}}</h4>
                <p class="accessibility-plugin-ac">{{idType}}: {{abhaID}}</p>
                <div *ngIf="currentStage === 3">
                  <button ion-button type="button" (click)="setAbhaAddress()"
                    class="primary accessibility-plugin-ac aadhar-submit-btn ">Click here to add ABHA Address</button>
                </div>
              </div>

            </ion-col>

          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>

</ng-container> -->

<!-- <div class="page-wrapper" *ngIf="false">
  <div class="register-wrap">
    <ion-grid class="padding-0">
      <ion-row>
        <ion-col size-lg="8" size-md="8" size-sm="12" size-xs="12" offset-lg="2" offset-md="2" class="register-box">
          <ion-item>
            <ion-col size-lg="12" size-md="12" size-sm="12" size-xs="12" class="register-header">
              <h3>
                <a [routerLink]="['/dashboard']" aria-label="Back" tabindex="0" class="back-arrow">
                  <ion-icon name="arrow-back-outline" alt="back-arrow" title="back-arrow"></ion-icon>
                </a>
                <span class="accessibility-plugin-ac" tabindex="0">{{'ABHA_SCREEN.GENERATE_ABHA_HEADER' | translate}}
                </span>
              </h3>
            </ion-col>
          </ion-item>

          <ion-row>
            <ion-col size="12">
              <div *ngIf="consentForm" class="abha-num-generate-wrapper">
                <div class="terms-condition">
                  <h6>{{'ABHA_SCREEN.CONSENT_1' | translate}}</h6>
                  <p>{{'ABHA_SCREEN.POINT_1' | translate}}</p>
                  <p>{{'ABHA_SCREEN.POINT_2' | translate}}</p>
                  <p>{{'ABHA_SCREEN.POINT_3' | translate}}</p>
                  <p>{{'ABHA_SCREEN.POINT_4' | translate}}</p>
                  <p>{{'ABHA_SCREEN.POINT_5' | translate}}</p>
                </div>
                <ion-button (click)="acceptTerms()" role="button" type="submit"
                  class="primary accessibility-plugin-ac aadhar-submit-btn agree-btn ion-text-start">
                  {{'ABHA_SCREEN.AGREE' | translate}}
                </ion-button>
              </div>
              <div *ngIf="aadharForm" class="abha-num-generate-wrapper">
                <div class="top-msg msg-block">
                  <span class="important-txt">
                    <span class="alert-icon">
                      <span class="icon-info_outline"></span>
                    </span>
                    <span class="vtxt accessibility-plugin-ac">
                      {{'ABHA_SCREEN.ENTER_AADHAR_DETAILS' | translate}}
                    </span>
                  </span>
                </div>
                <form [formGroup]="abhaForm" autocomplete="off">
                  <ion-row>
                    <ion-col size-lg="6" size-md="8" size-sm="7" size-xs="12">
                      <div lines="none">
                        <ion-item>
                          <mat-form-field appearance="outline" class="enter_aadhar_field">
                            <input class="accessibility-plugin-ac" matInput numbersOnly aadhaarValid
                              (keyup)="restrictUserToTypeMoreThanLength($event, 12)" appAadharNumber
                              [appRange]="[200000000000, 999999999999]" formControlName="photo_id_number"
                              placeholder="Enter Aadhaar Number">
                          </mat-form-field>

                          <mat-error
                            *ngIf="!abhaForm.controls.photo_id_number.valid  && (abhaForm.controls.photo_id_number.hasError('range'))"
                            class="error-validation accessibility-plugin-ac ion-item-start">
                            <span class="accessibility-plugin-ac">
                              {{'BENEFICIARY.VALIDATION.AADHAAR_NUMBER_DIGITS' |
                              translate}}</span>
                          </mat-error>

                          <mat-error *ngIf="!(abhaForm.controls.photo_id_number.hasError('range')) &&
                                (abhaForm.controls.photo_id_number.hasError('notValidAadhaar'))"
                            class="error-validation accessibility-plugin-ac">
                            <span class="accessibility-plugin-ac">
                              {{'BENEFICIARY.VALIDATION.INVALID_AADHAAR' |
                              translate}}</span>
                          </mat-error>
                        </ion-item>

                      </div>
                    </ion-col>
                    <ion-col size-lg="3" size-md="4" size-sm="5" size-xs="12" class="ion-text-start">
                      <ion-button [disabled]="abhaForm.invalid" (click)="onSubmit()" role="button" type="submit"
                        class="primary accessibility-plugin-ac aadhar-submit-btn ion-text-start">
                        {{'CERT_CORRECTION.SUBMIT_BTN' | translate}}
                      </ion-button>
                    </ion-col>
                  </ion-row>


                </form>
              </div>
              <div *ngIf="enableOtpForm" class="abha-otp-form">
                <form [formGroup]="otpForm" autocomplete="off">
                  <ion-row>
                    <ion-col size-lg="12" class="ion-text-start">
                      <h4 class="accessibility-plugin-ac" tabindex="0">
                        {{'ABHA_SCREEN.OTP_FOR_AADHAR_LINK' | translate}}
                      </h4>
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col size-lg="6" size-md="8" size-sm="7" size-xs="12">
                      <div lines="none">
                        <ion-item>
                          <mat-form-field appearance="outline" class="enter_otp_field">
                            <input class="accessibility-plugin-ac" #otp matInput formControlName="otp"
                              (keyup)="restrictUserToTypeMoreThanLength($event, 6, 'otpNumber')" autocomplete="off"
                              maxlength="6" placeholder="{{'LOGIN.ENTER_OTP' | translate}}" />
                          </mat-form-field>
                          <mat-error
                            *ngIf="!(otpForm.controls.otp.hasError('range'))  && (otpForm.controls.otp.hasError('pattern'))"
                            class="error-validation">
                            <span
                              class="accessibility-plugin-ac">{{'BENEFICIARY.VALIDATION.MOBILE_PATTERN_STARTS_WITH'
                              | translate}}</span>
                          </mat-error>
                        </ion-item>
                      </div>
                    </ion-col>



                    <ion-col size-lg="3" size-md="4" size-sm="5" size-xs="12" class="ion-text-start">
                      <ion-button role="button" type="submit" (click)="submitOtp()"
                        class="primary accessibility-plugin-ac aadhar-submit-btn ion-text-start">
                        {{'ABHA_SCREEN.VERIFY' | translate}}</ion-button>
                    </ion-col>


                  </ion-row>


                </form>
              </div>
            </ion-col>
          </ion-row>


        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</div> -->
<!-- <button mat-icon-button class="close-button" (click)="closeDialog()">
  <span class="icon-cancel"></span>
</button> -->
<!--
<div class="modalWelcome">
  <ion-row>
    <ion-col class="modalHeading ion-text-end">
      <h1 mat-dialog-title>Dear Vaccination team members,</h1>
      <img src="../../../../../assets/images/popup-heading.gif" />
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col size="12" class="modalBody">
      <p>Greetings From MoHFW,</p>
      <p>As India crosses the milestone of more than 150 crore covid-19 vaccine doses administered, we salute your dedication and contribution to this historic cause.</p>
      <p>We are proud of your inspiring efforts. Together we will defeat COVID.</p>
      <h1>Jai Hind.</h1>
      <img src="../../../../../assets/images/popup-body.svg" />
    </ion-col>
  </ion-row>

  <div mat-dialog-actions class="ion-text-end">
    <ion-button color="primary" shape="round" type='submit' (click)="closeDialog()">Ok</ion-button>
    <span class="icon-cancel" (click)="closeDialog()"></span>
    <button mat-button (click)="closeDialog()" >Ok</button>
  </div>

</div> -->
<!-- <mat-dialog-content> -->

<!-- <ion-grid [ngClass]="{'abhaDialog':createFromModal || isFromRegistration}"> -->
  <ion-grid class="abhaDialog">
  <header class="abhaHeader">
    <h3 class="pageTitle">
      <ion-icon (click)=" backbutton()" *ngIf="currentStage === 1 || currentStage === 2" slot="start"
        class="custom-arrow-left"></ion-icon>{{ header | translate}}
    </h3>
    <ion-icon (click)="onClose()" class="custom-close"></ion-icon>
  </header>
  <ion-row class="abhaInfo"
    *ngIf="(createFromModal==true || benData.createFromModal==true) &&(currentStage === 1 || currentStage === 4)">
    <ion-col size="6">
      <h3>{{'Name' | translate}}</h3>
      <ion-text>{{benDataFromCowin?.ben_name}}</ion-text>
    </ion-col>
    <ion-col size="6">
      <h3>{{'Mobile Number' | translate}}</h3>
      <ion-text>{{benDataFromCowin?.ben_mobile_number}}</ion-text>
    </ion-col>
    <ion-col size="6" class="ion-margin-bottom">
      <h3>{{'Reference Id' | translate}}</h3>
      <ion-text>{{utilsService.maskForLastChars(benDataFromCowin?.beneficiary_reference_id |
        benfNumberFormat)}}</ion-text>
    </ion-col>
  </ion-row>
  <content>
    <ion-grid class="ion-no-padding inputFields" *ngIf="currentStage === 1">
      <form [formGroup]="abhaForm" (ngSubmit)='generateAadhaarOTP()'>
        <ion-row>
          <ion-col size="12" size-lg="12" size-md="12" size-xs="12">
            <ion-label class="required">{{'Aadhaar Number' | translate}}</ion-label>
            <ion-item class="item-border ion-no-padding" lines="none">
              <ion-input aadhaarValid (keyup)="restrictUserToTypeMoreThanLength($event, 12)" appAadharNumber
                [appRange]="[200000000000, 999999999999]" formControlName="photo_id_number"
                [placeholder]="'Enter Aadhaar Number' | translate" errorText=" " ></ion-input>
            </ion-item>
            <div *ngIf="!abhaForm.controls.photo_id_number.valid && abhaForm.controls.photo_id_number.hasError('range')"
              class="error-validation accessibility-plugin-ac ion-item-start">
              <p class="accessibility-plugin-ac">
                {{ 'Aadhaar Number must be 12 digits' | translate }}
              </p>
            </div>
            <div *ngIf="!(abhaForm.controls.photo_id_number.hasError('range')) &&
                  (abhaForm.controls.photo_id_number.hasError('notValidAadhaar'))"
              class="error-validation accessibility-plugin-ac">
              <p class="accessibility-plugin-ac">
                {{'Invalid Aadhaar number' | translate}}</p>
            </div>
            <ion-item class="benificiary-checkbox ion-no-padding" lines="none">
              <ion-checkbox formControlName='aadhar_concent' class="ion-margin-end"></ion-checkbox>
              <ion-label class="no-ellipse-checkbox-label">{{'I hereby confirm that I have read out...' | translate}}
                <a (click)="openBottomSheet()" class="view-more concen-btn"
                  style="text-decoration: none;font-weight: normal;">{{'View More' | translate}}</a>
              </ion-label>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row class="ion-hide-sm-down "> <!-- Desktop -->
          <ion-col size="12" size-lg="12" size-md="12" size-xs="12" class="ion-text-end">
            <ion-button class="primaryBtn" shape="round" type="submit"
              [disabled]="abhaForm.invalid || !abhaForm.value.aadhar_concent">{{'Submit' | translate}}<ion-spinner
                *ngIf="verificationInprogress" name="lines-small"></ion-spinner>
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row class="ion-hide-sm-up ion-margin-top"><!-- Mobile-->
          <ion-col size="12" size-lg="12" size-md="6" size-xs="12">
            <ion-button class="primaryBtn" expand="block" shape="round" type="submit"
              [disabled]="abhaForm.invalid || !abhaForm.value.aadhar_concent"><ion-spinner
                *ngIf="verificationInprogress" name="lines-small"></ion-spinner>{{'Submit' | translate}}
            </ion-button>
          </ion-col>
        </ion-row>
      </form>
    </ion-grid>
    <ion-grid class="ion-no-padding inputFields" *ngIf="currentStage === 2">
      <form [formGroup]='otpForm' (ngSubmit)='verifyVaccinatorOTP()'>
        <ion-row>
          <ion-col size="12" size-lg="12" size-md="12">
            <ion-label class="otpText">
              {{'Enter OTP sent to the mobile number' | translate}} <b>{{aadharMobile}}</b>
            </ion-label>

            <ion-item class="item-border ion-no-padding" lines="none">
              <ion-input autocomplete="off" formControlName='otp' type='tel' maxlength="6" appIntegerInput
                [appRange]='[000001,999999]' [appMin]='000001' [appMax]='999999' placeholder="- - - - - -" errorText=" "> </ion-input>
              <ion-label
                *ngIf="formSubmitted && !otpForm.controls.otp.valid  && (otpForm.controls.otp.hasError('required'))"
                class="error-validation ">
                <p>{{'Please Enter OTP' | translate}}</p>
              </ion-label>
              <ion-label *ngIf="!otpForm.controls.otp.valid  && (otpForm.controls.otp.hasError('range'))"
                class="error-validation ">
                <p>{{'Must be 6 digits' | translate}}</p>
              </ion-label>
              <ion-button [disabled]="timer" class="linkBtn" expand="full" fill="outline" type="button"
                (click)="generateAadhaarOTP()">
                {{'Resend' | translate}}
              </ion-button>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="6">
            <ion-label *ngIf="timer">{{timer + ' sec '}}</ion-label>
          </ion-col>
          <ion-col size="6" class="ion-text-end">
            <ion-button class="primaryBtn" shape="round" type="submit">{{'Verify OTP' | translate}}<ion-spinner
                *ngIf="verificationInprogress" name="lines-small"></ion-spinner>
            </ion-button>
          </ion-col>
        </ion-row>
      </form>
    </ion-grid>
    <ion-grid class="ion-no-padding inputFields" *ngIf="currentStage === 3">
      <ion-row>
        <ion-col size="12" class="ion-text-center">
          <ion-img src="assets/images/green-tick.svg" class="biometric-success"></ion-img>
          <h3 class="pageSubTitle">
            {{'Your ABHA Number has been generated successfully' | translate}}
          </h3>
          <p><b>{{'ABHA Number :' | translate}}</b>{{abhaID}}</p>
          <ion-button fill="outline" shape="round" class="btnOuline" (click)="setAbhaAddress()">{{'Click here to add ABHA Address' | translate}}</ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-grid class="ion-no-padding inputFields" *ngIf="currentStage === 4">
      <form [formGroup]=' abhaAddressForm' (ngSubmit)='submitABHAAddress()'>
        <h3 class="pageSubTitle">
          {{'Link ABHA Address with your ABHA to enable health data sharing' | translate}}
        </h3>
        <ion-row>
          <ion-col size="12">
            <ion-label class="required">{{'ABHA Address' | translate}}</ion-label>
            <ion-item class="item-border ion-no-padding custom-input" lines="none">
              <ion-input [appRangeLength]='[4, 32]' autocomplete="off" formControlName="name"
                (keyup)="removeWhiteSpace($event)" [placeholder]="'ABHA Address' | translate" type="text" errorText=" " ></ion-input>
              <ion-text class="custom-input-dropdown" slot="end">
                @abdm
              </ion-text>
            </ion-item>
            <div
              *ngIf="!abhaAddressForm.controls.name.valid  &&
                        (!abhaAddressForm.controls.name.hasError('inputcharvalidator') && abhaAddressForm.controls.name.hasError('rangeLength'))"
              class="error-validation accessibility-plugin-ac">
              <p class="accessibility-plugin-ac">
                {{'Must be between 4 to 32 Characters' | translate}}
              </p>
            </div>
            <div
              *ngIf="isSubmitted && abhaAddressForm.controls['name'].invalid && abhaAddressForm.controls['name'].errors.required"
              class="error-validation accessibility-plugin-ac">
              <p>{{'Must be between 4 to 14 Characters'
                | translate}}</p>
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12" class="ion-text-end">
            <ion-button shape="round" class="primaryBtn" type="submit" [disabled]="abhaAddressForm.invalid">{{'Link ABHA
              Address' | translate}} <ion-spinner *ngIf="verificationInprogress" name="lines-small"></ion-spinner></ion-button>
          </ion-col>
        </ion-row>
      </form>
    </ion-grid>
    <ion-grid class="ion-no-padding inputFields" *ngIf="currentStage === 5">
      <ion-row>
        <ion-col size="12" class="ion-text-center">
          <ion-img src="assets/images/green-tick.svg" class="biometric-success"></ion-img>
          <h3 class="pageSubTitle">
            {{'Your ABHA Address has been generated successfully' | translate}}
          </h3>
          <p><b>{{'ABHA Address' | translate}} :</b> {{abhaID}}@abdm</p>
        </ion-col>
      </ion-row>
    </ion-grid>
  </content>
</ion-grid>