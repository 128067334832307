import { FormGroup } from '@angular/forms';
import { Component, Input, OnInit, AfterContentChecked, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { DataService } from 'src/app/modules/shared/services/data.service';
import { LocationService } from 'src/app/modules/shared/services/location.service';
import { LookUpService } from '../../services/look-up.service';
import { Observable } from 'rxjs';
import { AddRecipientService } from 'src/app/screens/add-recipient/services/add-recipient.service';
import moment from 'moment';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-common-recipient-fields',
  templateUrl: './common-recipient-fields.component.html',
  styleUrls: ['./common-recipient-fields.component.scss'],
})
export class CommonRecipientFieldsComponent implements OnInit {
  @Input() includeDOB = false;
  @Input() parentForm: FormGroup;
  genderList: Observable<any>;
  yobList: Observable<any>;
  sendingYearCommon;
  benData:any;
  minDob = new Date('01-01-1920');
  maxDob = new Date();
  maxLMPDate = new Date();
  minDate;
  currentYear;
  bloodGroupArray = [{ id: 1, groupType: 'A+ve' }, { id: 2, groupType: 'B+ve' }, { id: 3, groupType: 'AB+ve' }, { id: 4, groupType: 'O+ve' }, { id: 5, groupType: 'O-ve' }, { id: 6, groupType: 'A-ve' }, { id: 7, groupType: 'B-ve' }, { id: 8, groupType: 'AB-ve' }]
  // minLMPDate = new Date(moment().subtract('months', 12).format('DD-MM-YYYY'));
  minLMPDate;
  environment;
  maxPreviousYear;

  constructor(
    private locationsService: LocationService,
    private dataService: DataService,
    private lookupService: LookUpService,
    private addRecipientService: AddRecipientService,
    private cdr: ChangeDetectorRef,
  ) {
    // this.lookupService.getGender().subscribe(r=>{this.genderList = r;});
    // this.lookupService.getYob().subscribe(r=>{this.yobList = r;});
    this.genderList = this.lookupService.getGender();
    this.yobList = this.lookupService.getYob()

  }

  ngOnInit() {
    this.environment = environment;
    this.currentYear=moment().year();
    this.maxPreviousYear = moment().format('YYYY-MM-DD')
    this.addRecipientService.beneficiaryData.subscribe(benf => {
      this.benData = benf;
      if(this.benData && this.benData?.beneficiary_id){
        this.minLMPDate = this.benData.lmp_date;
      }
       this.minDate = moment(this.benData.dob).add(12,'years').format('YYYY-MM-DD');
      if(this.benData && this.benData?.ben_type_id == environment.static_ids.PregnantWomen){
        this.maxDob = new Date(moment().subtract('years', 10).format('MM-DD-YYYY'));
        this.minLMPDate = new Date(moment().subtract(12,'months',).format('MM-DD-YYYY'));
        this.cdr.detectChanges();
      }
      // if(this.benData.session_id  && this.benData.beneficiary_type_category_id!=1 && this.benData.beneficiary_type_category_id!=2){
      //   const presentYear = +moment().format('YYYY');
      //   const MinimumAge = presentYear - this.benData.min_age;
      //   const MaximumAge = presentYear -this.benData.max_age ;
      //   this.maxDob = new Date(`12-31-${MinimumAge}`);
      //   this.minDob = new Date(`01-01-${MaximumAge}`);
      // }
      
      if(this.benData.session_id && this.benData.special_session_type_id == 1){
        const presentYear = +moment().format('YYYY');
      const MinimumAge =  moment.utc().subtract(`${this.benData.min_age}`, 'days').startOf('day');
      const MaximumAge = moment.utc().subtract(`${this.benData.max_age}`, 'days').startOf('day');
      this.maxDob = new Date(`${MinimumAge}`);
      this.minDob = new Date(`${MaximumAge}`);
      }
      else{
        this.minDob = new Date('01-01-1920');
         this.maxDob = new Date();
      }
    })
  }
  changedString(value,formControlName){
    if(formControlName == 'ben_name'){
      this.parentForm.get('ben_name').setValue(value);
    }else if(formControlName == 'father_name'){
      this.parentForm.get('father_name').setValue(value);
    }
  }
  changedYear(event) {
    if (event == this.parentForm.value.yob) {
      this.sendingYearCommon = event
      this.addRecipientService.sendYear.emit(this.sendingYearCommon)
    }
  }
  lmpDateEvent(event){
    var max = moment(event.value,'DD/MM/YYYY').subtract(1,'months').format('YYYY-MM-DD');
    this.maxPreviousYear = max;
  }
  keypress(event) {
    if(event.charCode === 45) {
      event.preventDefault();
    }
  }

}
