<form [formGroup]="myForms" (ngSubmit)="f" #f="ngForm" class="d-inline-flex " style="position: relative;" #searchBenf>
  <mat-form-field class="searchBar searchfunc" appearance="outline" [class.drop-down-input-active]="showPanel | async"
    cdkOverlayOrigin #originOverlay="cdkOverlayOrigin">

    <mat-select formControlName="photo_id_type_id" (selectionChange)="getDetailsType($event)"
      [placeholder]="'Search By' | translate" class="searchBy dropdown-select-category-field"
      panelClass="dropdown-select-category-panel">
      <mat-option [value]="0">{{'Mobile Number' | translate}}</mat-option>
      <mat-option [value]="'reference_ID' + 99">
        {{'Reference ID' | translate}}</mat-option>
      <mat-option *ngFor="let dataType of dataTypes" [value]="dataType.id" title="{{dataType.type | translate}}">{{ dataType.type | translate}}
      </mat-option>
    </mat-select>
    <input matInput type="text" [placeholder]="'Search Members' | translate" (input)="onSelectionChange($event)"
      (keypress)="myForms.get('photo_id_type_id').value === 0 || myForms.get('photo_id_type_id').value === 'reference_ID99' ? numberOnly($event) : null"
      formControlName="search_value" onclick="event.stopPropagation();">
    <ion-spinner *ngIf="isDataInProgress" name="lines-small" class="custom-search"></ion-spinner>
    <!-- <ion-icon slot="end" class="custom-search" (click)="onResize(searchBenf.getBoundingClientRect())"></ion-icon> -->
  </mat-form-field>
  <div class="iconBg">
    <ion-icon *ngIf="!isDataInProgress" slot="end" class="custom-search" (click)="searchData()"></ion-icon>
  </div>


  <mat-error *ngIf="onlySearch">
    {{ 'Please select options from drop down' | translate}}
  </mat-error>
  <mat-error *ngIf="search_value.invalid && (search_value.dirty || search_value.touched)" class="alert-danger">
    <mat-error *ngIf="search_value.errors.required && f.submitted && search_value.errors?.required">
      {{'Input is required'| translate}}
    </mat-error>
    <mat-error *ngIf="search_value.errors.notValidAadhaar && AadharVal">
      {{'Aadhar card should be 12 digits' | translate}}
    </mat-error>
    <mat-error *ngIf="search_value.errors.pattern && referenceVal">{{'Please enter valid Reference ID' | translate}}
    </mat-error>
    <mat-error *ngIf="search_value.errors.pattern && MobileVal">{{ 'Please enter 10 digit mobile number' | translate}}
    </mat-error>
    <mat-error *ngIf="search_value.errors.validatorsID">
      {{'Please enter valid' | translate}} {{photoType}}
    </mat-error>
  </mat-error>
  <mat-error *ngIf="alphabets">
    {{'Please Enter Numbers Only' | translate}}
  </mat-error>

</form>
<ng-template cdkConnectedOverlay cdkConnectedOverlayHasBackdrop [cdkConnectedOverlayOffsetY]="-5"
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop" [cdkConnectedOverlayOrigin]="originOverlay"
  [cdkConnectedOverlayOpen]="showPanel | async">
  <div *ngIf="!noData && showDilouge" class="searchOverly" id="cdkdata" [ngStyle]="{'width.px':searchBenf.offsetWidth}">
    <mat-card class="drop-down-search-panel search-by-category">
      <ion-item (click)="beneficiaryClicked(ben.beneficiary_id)" *ngFor="let ben of beneficaryData">
        <ion-grid class="ion-no-padding">
          <ion-row class="ion-align-items-center ion-padding ben-list-item">
            <ion-col size="8">
              <p class="ben-list-item-name">{{ ben.ben_name }}</p>
              <div class="ben-list-item-content">
                <mat-icon aria-hidden="false" class="icon-mobile" aria-label="Example home icon">settings_cell
                </mat-icon>
                {{ben.ben_mobile_number}}
              </div>
            </ion-col>
            <ion-col size="4" class="ion-text-right ben-list-item-content">
              <div></div>
              <b class="">{{ ben.dob | age }} | </b>
              <b>{{ ben.beneficiary_gender | translate }}</b>
            </ion-col>
          </ion-row>

        </ion-grid>
      </ion-item>
    </mat-card>
  </div>
  <mat-card *ngIf="noData && showDilouge" class="drop-down-search-panel search-by-category" id="cdkdata"
    [ngStyle]="{'width.px':searchBenf.offsetWidth}">
    <p class="returnNoData">{{returnNoData}}&nbsp;
      <!-- <a *ngIf="userRole != 'Delivery Manager'" (click)="openAddMember()" class="newMember">add new member</a> -->
    </p>
  </mat-card>
</ng-template>