<form [formGroup]='personalDetForm' autocomplete="off" (ngSubmit)='onSubmit()'>
    <ion-grid class="inputFields demographicBlock">

        <ion-row>
            <ion-col size="12">
                <ion-label>
                    {{'UID Number' | translate}}
                </ion-label>
                <h2 class="qrIcon">{{beneficiaryData?.aadhaarNumber | benfNumberFormat}}
                    <ion-icon class="custom-qrcodeicon" (click)="scanQR('fromScan')"></ion-icon>
                </h2>
                <!-- <h2>{{beneficiaryData?.aadhaarNumber | benfNumberFormat}}
                  
                </h2>
                <ion-img src="assets/images/qrcodeicon.svg"class="qrcode-icon" (click)="scanQR('fromScan')"></ion-img> -->
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col size="12">
                <ion-label class="required">{{'Name' | translate}}</ion-label>
                <ion-item class="item-border ion-no-padding" lines="none">
                  <ion-input  type="text" formControlName="ben_name" errorText=" ">
                  </ion-input>
                </ion-item>
              </ion-col>
        </ion-row>
        <ion-row>
            <ion-col size="12">
                <ion-label>{{'Gender' | translate}}</ion-label>
                <div class="ion-margin-vertical">
                  <mat-radio-group aria-label="Select an option" formControlName="gender" color="primary">
                    <mat-radio-button [value]="1">{{'Male' | translate}}</mat-radio-button>
                    <mat-radio-button [value]="2">{{'Female' | translate}}</mat-radio-button>
                    <mat-radio-button [value]="3">{{'Others' | translate}}</mat-radio-button>
                  </mat-radio-group>
                </div>
              </ion-col>
        </ion-row>
        <ion-row *ngIf="beneficiaryData?.beneficiary_id || beneficiaryData?.guardian_ben_id">
            <ion-col size="12">
                <ion-label class="required">{{'Year of Birth' | translate}}</ion-label>
                <ion-item class="item-border ion-no-padding" lines="none">
                  <ion-input   formControlName="yob"  type="number" matInput [appMin]="[minYob]"
                  appLimitTo="4" 
                  [appMax]="currentYear" errorText=" ">
                  </ion-input>
                </ion-item>
              </ion-col>
        </ion-row>
        <!-- <ion-row>
            <app-ion-custom-datepicker *ngIf="showDobField()" [class]="'noclass'" style="width: 100%;"
                        label="Date of Birth" formKey='newBenDob' [parentForm]="benForm" placeholder="DD/MM/YYYY"
                        [minDate]="dobMinDate" [maxDate]="dobMaxDate" (dateValue)="getBeneficiaryTypeonDoB($event)">
                      </app-ion-custom-datepicker>
        </ion-row> -->
        <ng-container *ngIf="!scannedData">
            <!-- <div>
                <ion-row>
                    <ion-col size="12">
                        <ion-img (click)="scanQR()" src="assets/images/qrcode.png" class="ion-margin-vertical"></ion-img>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col size="12" class="ion-text-center ion-margin-top">
                        <ion-title class="ion-margin-vertical">Scan Aadhaar QR Code </ion-title>
                        <ion-label>Verify member using Aadhaar’s<br /> secure QR code easily</ion-label>
                    </ion-col>
                </ion-row>
            </div> -->
            
        </ng-container>
        <div>
            <ion-row>
                <ion-col size-md="12">
                    <span class="checkboxSpan" [ngClass]="{'checkbox-required': personalDetForm.get('consent').touched && personalDetForm.get('consent')?.errors?.required}">
          <mat-checkbox color="primary"
          [ngClass]="{'checkbox-required': personalDetForm.get('consent').touched && personalDetForm.get('consent')?.errors?.required}"

          formControlName="consent"></mat-checkbox><p>{{'I hereby confirm that I have read
            out the information provided by the beneficiary...' | translate}}
          <a (click)="openBottomSheet()">{{'Read More' | translate}}</a></p>
        </span>
                    <span class="checkboxSpan" *ngIf="beneficiaryData!=null && beneficiaryData!=undefined && beneficiaryData?.ben_type_id == environment.static_ids.PregnantWomen || beneficiaryData?.ben_type_id == environment.static_ids.LactatingMother" [ngClass]="{'checkbox-required': personalDetForm.get('female_consent').touched && personalDetForm.get('female_consent')?.errors?.required}">
            <mat-checkbox
            [ngClass]="{'checkbox-required': personalDetForm.get('female_consent').touched && personalDetForm.get('female_consent')?.errors?.required}"
            formControlName="female_consent" required></mat-checkbox>
            <p *ngIf="beneficiaryData?.session_type_name == 'UIP'">{{'The beneficiary has been informed about the risk and benefits associated with the ROUTINE vaccination' | translate}}</p>
            <p *ngIf="beneficiaryData?.session_type_name == 'COVID-19'">{{'The beneficiary has been informed about the risk of exposure to COVID-19  infection along with the risk and benefits associated with the COVID-19 vaccination.' | translate}}</p>
           <p *ngIf="!beneficiaryData?.hasOwnProperty('session_type_name')">{{'The beneficiary has been informed about the risk and benefits associated with the vaccination' | translate}}</p>
            
          </span>
                    <div class="ion-text-end">
                        <ion-button class="primaryBtn" [disabled]="verificationInProgress" shape="round" type="submit">
                            <ion-spinner name="lines-small" *ngIf="verificationInProgress"></ion-spinner>{{'Verify Member' | translate}} </ion-button>
                    </div>
                </ion-col>
            </ion-row>
        </div>
        <!-- <ng-container *ngIf="scannedData">
      <div>
          <h3 class="ion-text-uppercase pageSubTitle">Demographic Authentication</h3>
          <app-common-recipient-fields [parentForm]="personalDetForm"></app-common-recipient-fields>

      </div>
    </ng-container> -->
    </ion-grid>
    <!-- <ng-container *ngIf="scannedData">
<div class="sidebar-footer">
    <ion-row>
        <ion-col size-md="12">
            <mat-checkbox formControlName="consent">I hereby confirm that I have read out the information provided by the beneficiary... Read More</mat-checkbox>
            <ion-button class="primaryBtn" expand="full" shape="round" type="submit">Verify Member </ion-button>
        </ion-col>
    </ion-row>
</div>
</ng-container> -->
</form>
