import { Optional, Inject, Output, EventEmitter } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { UtilsService } from './../../../../modules/shared/services/utils.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';

import { MatDialog } from '@angular/material/dialog';
import { PairDeviceDialog } from '../../../remote-interface/components/pair-device-dialog/pair-device-dialog';
import { AadharService } from '../../services/aadhar.service';
import { RemoteInterfaceService } from 'src/app/screens/remote-interface/service/remote-address.service';
import { AddRecipientService } from 'src/app/screens/add-recipient/services/add-recipient.service';
import { ModalController } from '@ionic/angular';
import { AadhaarConcentComponent } from 'src/app/modules/shared/components/aadhaar-concent/aadhaar-concent.component';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import AadhaarNewQRCodeReader from '../../android-native-plugin/aadhaar-qrcode-plugin';
import { NgxXmlToJsonService } from 'src/app/modules/shared/services/ngx-xml-to-json.service';
import { LookUpService } from 'src/app/modules/shared/services/look-up.service';
import { environment } from 'src/environments/environment';
import { EncDecService } from 'src/app/modules/shared/services/encryption-decryption.service';
import * as moment from 'moment';
import { Haptics, ImpactStyle } from '@capacitor/haptics';


@Component({
  selector: 'app-aadhar-demographic-verification',
  styleUrls: ['./demographic.scss'],
  templateUrl: './demographic.html',
})
export class DemographicComponent implements OnInit {
  @Output() gotoStep: EventEmitter<any> = new EventEmitter();
  beneficiaryData: any = {};
  personalDetForm: FormGroup;
  scannedData: any = null;
  startTimeInMS;
  verificationInProgress = false;
  endTimeInMS;
  qrText;
  scanSub: any;
  yobList: any = [];
  environment;
  fromScan:boolean = false;
  adhaarDataMatch: any = null;
  minYob = new Date().getFullYear() - 100;
  currentYear =  new Date().getFullYear();
  constructor(
    private aadharService: AadharService,
    // private qrScanner: QRScanner,
    private remoteInterfaceService: RemoteInterfaceService,
    public addRecipientService: AddRecipientService,
    public util: UtilsService,
    private modalController: ModalController,
    private _bottomSheet: MatBottomSheet,
    // private vibration: Vibration,
    private ngxXml2jsonService: NgxXmlToJsonService,
    private lookup: LookUpService,
    private encDecService:EncDecService
  ) {
    this.personalDetForm = new FormGroup({
      female_consent: new FormControl(''),
      consent: new FormControl('', [Validators.required]),
      ben_name:new FormControl('', [Validators.required]),
      gender:new FormControl('', [Validators.required]),
      yob:new FormControl('')
    })
    this.lookup.getYob().subscribe(r=>{this.yobList =r});

  }

  ngOnInit(): void {
    this.environment = environment;
    this.scannedData = null;
    this.addRecipientService.beneficiaryData.subscribe(benf => {
      this.beneficiaryData = benf;
      if (this.beneficiaryData != null && this.beneficiaryData != undefined) {
        if (this.beneficiaryData.ben_type_id == environment.static_ids.PregnantWomen || this.beneficiaryData.ben_type_id == environment.static_ids.LactatingMother) {
          this.personalDetForm.get('female_consent').setValidators([Validators.required]);
          if(!this.beneficiaryData?.guardian_ben_id){
            this.personalDetForm.get('gender').setValue(2)
          }
        }
        else {
          this.personalDetForm.get('female_consent').clearValidators();
          this.personalDetForm.get('female_consent').reset();
        }
      }
      if(!this.beneficiaryData?.guardian_ben_id){
      this.personalDetForm.get('ben_name').setValue(this.beneficiaryData.ben_name)
      let year = moment(this.beneficiaryData.dob).format('YYYY')
      this.personalDetForm.get('yob').setValue(year)
      if(this.beneficiaryData.ben_gender){
        this.personalDetForm.get('gender').setValue(this.beneficiaryData.ben_gender)
      }
    }
      // this.personalDetForm.get('adhaarNumber').setValue(benf.adhaarNumber);
    });

    // this.remoteInterfaceService.deviceInterfaceObservable$.subscribe(r => {
    //   this.aadharService.enableFaceRdService()
    // });
    // this.util.presentToastInfo("Please Verify Consent")
  }

  openBottomSheet() {
    this._bottomSheet.open(AadhaarConcentComponent);
  }



  scanAadharQR(): Observable<any>{
    if(this.util.getIsMobileDevice()){
      return this.qrScannInMobile();
    }else{
      return this.qrScannInDesktop();
    }
  }

  // checkedConsent(event) {
  //   if (this.personalDetForm.valid) {
  //     this.scanQR();
  //   }
  // }


  qrScannInDesktop() {
    const suid = localStorage.getItem('suid');
    return this.remoteInterfaceService.requestForPingAPI(suid).pipe(
      switchMap(res => {
        if (res === 'devping') {
          return this.remoteInterfaceService.requestForQRCode(suid);
        } else {
          return of('RC:1209')
        }
      })
    ).pipe(map((res: any) => {
      if (res && res.ret === 'Y') {
        // this.setDataInFormNewQRDeskTop(res);
        return res;
      } else {
        if (res && res === 'RC:1201') {
          this.util.presentToastError('Session Timeout');
          this.aadharService.openPairDevicePopup();
        } else if (res === 'RC:1209') {
          localStorage.removeItem('suid');
          this.util.presentToastError('RD Mobile Device Not Reachable');
          this.verificationInProgress = false;
          this.aadharService.openPairDevicePopup();

        } else if (res === 'RC:1205') {
          this.util.presentToastError('Read Tim8eout');
        } else {
          this.util.presentToastError('Error Connecting RD Service');
        }
        return null;
      }

    }));
  }

  private qrScannInMobile() {
    // document.getElementsByTagName('body')[0].style.opacity = '0';
    const hapticsVibrate = async () => {
      await Haptics.vibrate();
    }
    return new Observable(observer => {
      this.checkPermission();
      document.querySelector('body').classList.add('scanner-active');
      const startScan = async () => {
        // Check camera permission
        // This is just a simple example, check out the better checks below
        await BarcodeScanner.checkPermission({ force: true });
        
        // make background of WebView transparent
        // note: if you are using ionic this might not be enough, check below
        BarcodeScanner.hideBackground();
        
        const result = await BarcodeScanner.startScan(); // start scanning and wait for a result
        
        // if the result has content
        if (result.hasContent) {
          const textFound = result.content;
          const obj = this.ngxXml2jsonService.xmlToJson(textFound);
              this.qrText = obj;
              hapticsVibrate();
              if (!obj['PrintLetterBarcodeData']) {
                AadhaarNewQRCodeReader.readQRData({ qrText: textFound }).then(res => {
                  if (res.hasOwnProperty('error')) {
                    this.stopScanning();
                    observer.error();
                    this.util.presentToastError('Adhaar QR Code is Invalid');
                  } else {
                    this.stopScanning();
                    observer.next(res);
                    // this.setDataInFormNewQR(res);
                  }
                }).catch(err => {
                  this.stopScanning();
                  observer.error();
                  this.util.presentToastError('Adhaar QR Code is Invalid');
                });
              } else {
                const aadhrRes = this.qrText['PrintLetterBarcodeData']['@attributes'] || this.qrText['PrintLetterBarcodeData']['attr'];
                observer.next(aadhrRes);
                // this.setDataInForm();
                this.stopScanning();
              }
        }
      }
      startScan();
    });
  }
 async verifyUsingDemoAuthManual(){
  this.verificationInProgress = true
    this.aadharService.getAccessToken().pipe(
      switchMap(res => {

        sessionStorage.setItem('aadhaarAccessToken', JSON.stringify(res.token));
        const verifyAdhaarPostData = {
          aadhaar: this.encDecService.set(this.beneficiaryData?.aadhaarNumber),
          name: this.personalDetForm.get('ben_name').value,
          yearOfBirth: this.personalDetForm.get('yob').value,
          gender: this.aadharService.genderGrp[this.personalDetForm.get('gender').value],
          consentHealthId: true,
          token: res.token,
          flag: 'surveyor'
        };
        return this.aadharService.verifyDemoGraphicDetails(verifyAdhaarPostData);
      })
    ).subscribe(res => {
      this.verificationInProgress = false;
      this.util.presentToastSuccess("Demo Authentication Successful")
      let benfAdarData:any;
   
      const AadharData =JSON.parse(this.encDecService.get(res.result)) 
    benfAdarData = {
      ...this.beneficiaryData,
      aadharData:AadharData,
      verifiedBy: 'Aadhar',
      verifiedType: this.fromScan? 'qr':undefined,
    };
    this.addRecipientService.beneficiaryDetails = benfAdarData;
    if (this.beneficiaryData.beneficiary_id && !this.beneficiaryData.guardian_ben_id){
       this.adhaarDataMatch = this.addRecipientService.matchWithAadharData(benfAdarData);
        if (!this.adhaarDataMatch.dataMatch) { 
          this.beneficiaryData.demoAadharstatus="reject";
         }
    this.addRecipientService.beneficiaryDetails = benfAdarData;

        }
      this.gotoStep.emit('next');
    },(err)=>{
      this.verificationInProgress = false;
      this.beneficiaryData.demoAadharstatus="reject";
      let beneficiaryData = {
        ...this.beneficiaryData
      };
      this.addRecipientService.beneficiaryDetails = beneficiaryData;
      if (this.beneficiaryData.beneficiary_id){
        this.gotoStep.emit('next');
      }
    })

  }
  

  async verifyUsingDemoAuth(aadharData: any) {
    this.aadharService.getAccessToken().pipe(
      switchMap(res => {
        sessionStorage.setItem('aadhaarAccessToken', JSON.stringify(res.token));
        const verifyAdhaarPostData = {
          aadhaar: this.encDecService.set(aadharData.uid),
          name: this.beneficiaryData.ben_name,
          yearOfBirth: this.beneficiaryData.year,
          gender: this.aadharService.genderGrp[this.beneficiaryData.ben_gender],
          consentHealthId: true,
          token: res.token,
          flag: 'surveyor'
        };
        return this.aadharService.verifyDemoGraphicDetails(verifyAdhaarPostData);
      })
    ).subscribe(res => {
      this.verificationInProgress = false;
      // this.personalDetForm.controls.ben_name.disable();
      // this.personalDetForm.controls.yob.disable();
      // this.personalDetForm.controls.gender.disable();
      aadharData.benefitId = res.benefitId;
      aadharData.healthId = res.healthIdNumber;
      // this.saveAadhaarPayload(res);
      // if (healthId) {
      // }

      // if (benefitId) {
      //   // this.benefitId = benefitId;
      // }
      this.endTimeInMS = new Date().getTime();
      const secTakenForTS = ((this.endTimeInMS - this.startTimeInMS)/1000).toFixed(2);
      let beneficiaryData = {
        ...{
          verifiedBy: 'Aadhar',
          verifiedType: 'qr',//'demographic
          aadharData: aadharData,
          time_took:secTakenForTS
        },
        ...this.beneficiaryData
      };
      this.addRecipientService.beneficiaryDetails = beneficiaryData;
      this.gotoStep.emit('next');
      this.util.presentToastSuccess('Demo Authentication Successful');
      // this.nextStep.emit();
    }, err => {
      // this.errorOccurredThroughAadhaar = true;
      //   this.aadhaarVerificationFormGrp.get('verifyAadhaarType').setValue(null);
      //     this.isOtpVerified = false;
      //  this.showAuthButton = false;
      this.verificationInProgress = false;
      this.beneficiaryData.demoAadharstatus="reject";
      this.addRecipientService.beneficiaryDetails = this.beneficiaryData;
      this.gotoStep.emit('next');

    });
  }



  onSubmit() {
    this.personalDetForm.markAllAsTouched();
    if (!this.personalDetForm.valid) {
    this.util.presentToastInfo("Please Verify Consent")
       return;
      }
    // this.gotoStep('next');
    // this.scanQR();
    this.verifyUsingDemoAuthManual();
  }

  stopScanning() {
    BarcodeScanner.showBackground();
    BarcodeScanner.stopScan();
    document.querySelector('body').classList.remove('scanner-active');
  }
  
  scanQR(value?) {
    if(value){
      this.fromScan = true;
    }
    if (!this.aadharService.enableFaceRdService()) {
      this.aadharService.openPairDevicePopup();
    }
    this.startTimeInMS = new Date().getTime();
    // this.verificationInProgress = true;
    this.scanAadharQR()
      .subscribe(res => {
        if (res) {
          
          if(this.util.getIsMobileDevice()){
            // document.getElementsByTagName('body')[0].style.opacity = '1';
            this.stopScanning();
          }
          // if (this.beneficiaryData.beneficiary_id) {
          //   // this.verifyUsingDemoAuth(res);
          // } else {
            this.endTimeInMS = new Date().getTime();
            const secTakenForTS = ((this.endTimeInMS - this.startTimeInMS)/1000).toFixed(2);
            let beneficiaryData = {
              ...{
                verifiedBy: 'Aadhar',
                verifiedType: 'qr',//demographic
                aadharData: res,
                time_took:secTakenForTS
              },
              ...this.beneficiaryData
              
            };
            if(res.uid!=this.beneficiaryData.aadhaarNumber){
              this.util.presentToastError("UID data didn't match with entered AAdhar number");
              this.verificationInProgress = false;
              return;
            }
            else{
            this.addRecipientService.beneficiaryDetails = beneficiaryData;
            this.verificationInProgress = false;
            // this.gotoStep.emit('next');
          }
       
            // this.gotoStep.emit('next');
          // }
          this.personalDetForm.get('ben_name').setValue(res?.name)
          this.personalDetForm.get('yob').setValue(res?.yob)
          this.personalDetForm.get('gender').setValue(this.aadharService.genderGrpFull[res?.gender])
          this.personalDetForm.get('ben_name').disable()
          this.personalDetForm.get('yob').disable()
          this.personalDetForm.get('gender').disable()
          // this.gotoStep.emit('next');
          // this.aadharService.setDataInFormNewQRDeskTop(r, this.personalDetForm);

        }
      },e=>{
        this.verificationInProgress = false;
      });
  }
  
  checkPermission = async () => {
    const status = await BarcodeScanner.checkPermission();
  
    if (status.denied) {
      // the user denied permission for good
      // redirect user to app settings if they want to grant it anyway
      const c = confirm('If you want to grant permission for using your camera, enable it in the app settings.');
      if (c) {
        BarcodeScanner.openAppSettings();
      }
    }
  };
}
