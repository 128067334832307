import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Injectable()
export class CustomMatLangPaginatorService extends MatPaginatorIntl {
  changes = new Subject<void>();

  constructor(private translate: TranslateService) {
    super();
    this.initTranslations();
    this.translate.onLangChange.subscribe(() => {
      this.initTranslations();
    });
  }

  initTranslations() {
    this.translate.get('Items per page').subscribe((translation: string) => {
      this.itemsPerPageLabel = translation;
      this.changes.next();
    });
    this.translate.get('Next page').subscribe((translation: string) => {
      this.nextPageLabel = translation;
      this.changes.next();
    });
    this.translate.get('Previous page').subscribe((translation: string) => {
      this.previousPageLabel = translation;
      this.changes.next();
    });
    this.translate.get('PAGINATOR.FIRST_PAGE').subscribe((translation: string) => {
      this.firstPageLabel = translation;
      this.changes.next();
    });
    this.translate.get('PAGINATOR.LAST_PAGE').subscribe((translation: string) => {
      this.lastPageLabel = translation;
      this.changes.next();
    });
  }
}
