import { Optional, Inject, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { UtilsService } from './../../../../modules/shared/services/utils.service';
import { RemoteInterfaceService } from '../../service/remote-address.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { of, Subscription } from 'rxjs';
@Component({
  selector: 'app-pair-device-qr-code',
  templateUrl: './qr-code.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QrCodeComponent implements OnDestroy {
  @Output() onSessionCreate: EventEmitter<any> = new EventEmitter();
  subscr: Subscription;
  suid;
  qrImg;
  remoteConfig: FormGroup;
  qrCodeLoaded = false;
  intervalObj = null;
  resendTimer = 55;


  constructor(
    private util: UtilsService,
    private remoteService: RemoteInterfaceService,
    private cdr: ChangeDetectorRef
  ) {}
  ngOnInit() {
    this.reloadBarcode();
  }

  startTimer() {
    if (this.intervalObj) {
      clearInterval(this.intervalObj)
    }
    let timer = 55;
    let th = this;
    this.intervalObj = setInterval(function () {
      th.resendTimer = --timer
      if (th.resendTimer < 0) {
        clearInterval(this.intervalObj);
      }
      th.cdr.detectChanges();
    }, 1000);
  }

  reloadBarcode() {
    this.resendTimer = 55;
    this.qrCodeLoaded = false;
    const oldSuid = localStorage.getItem('suid');
    this.subscr = this.remoteService.createSessionClient(oldSuid).pipe(
      switchMap((res:any) => {
        if (res && res.ret == 'Y') {
          this.startTimer();
          this.suid = res.suid;
          this.qrImg = "data:image/png;base64," + res.qrimg;
          this.qrCodeLoaded = true;
          this.cdr.detectChanges();
          return this.remoteService.waitForAckClient(this.suid);
        } else {
          if (res == 'RC:1201') {
            this.util.presentToastError('No Client Session');
          }
          return of(null)
        }
      })
    ).subscribe((res:any) => {
      if (res) {
        try {
          const response = JSON.parse(res);
          if (response.ret == 'Y') {
            this.onSessionCreate.emit({
              ...response,
              suid: this.suid
            });
          }
        } catch(e){
          if (res == 'RC:1105') {
            this.util.presentToastError('QR Code Expired');
          } else if (res == 'RC:1201') {
            this.util.presentToastError('No Client Session');
          }
        }
      }
    });
  }

  isQRLoaded() {
    return this.qrCodeLoaded;
  }

  ngOnDestroy() {
    this.subscr.unsubscribe();
  }


}
