import { Optional, Inject, Output, EventEmitter } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { UtilsService } from './../../../../modules/shared/services/utils.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { PairDeviceDialog } from '../../../remote-interface/components/pair-device-dialog/pair-device-dialog';
import { AadharService } from '../../services/aadhar.service';
import { RemoteInterfaceService } from 'src/app/screens/remote-interface/service/remote-address.service';
import { AddRecipientService } from 'src/app/screens/add-recipient/services/add-recipient.service';
import { GuardsCheckEnd } from '@angular/router';
import { NgxXmlToJsonService } from 'src/app/modules/shared/services/ngx-xml-to-json.service';
import { ModalController } from '@ionic/angular';
import { AadhaarConcentComponent } from 'src/app/modules/shared/components/aadhaar-concent/aadhaar-concent.component';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import AadhaarCalls from '../../android-native-plugin/aadhaar-calls-plugin';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-aadhar-facial-verification',
  styleUrls: ['./facial.scss'],
  templateUrl: './facial.html',
})
export class FacialComponent {
  @Output() gotoStep: EventEmitter<any> = new EventEmitter();
  beneficiaryData: any = {};
  personalDetForm: FormGroup;
  scannedData: any = null;
  startTimeInMS;
  endTimeInMS;
  environment;
  verificationInProgress = false;
  constructor(
    private aadharService: AadharService,
    private remoteInterfaceService: RemoteInterfaceService,
    public addRecipientService: AddRecipientService,
    public util: UtilsService,
    private ngxXml2jsonService: NgxXmlToJsonService,
    private modalController: ModalController,
    private _bottomSheet: MatBottomSheet
  ) { }

  ngOnInit(): void {
    this.environment= environment
    this.personalDetForm = this.aadharService.buildForm();
    this.personalDetForm = new FormGroup({
      female_consent: new FormControl(''),
      consent: new FormControl('', [Validators.required])
    })
    this.addRecipientService.beneficiaryData.subscribe(benf => {
      this.beneficiaryData = benf;

      // this.personalDetForm.get('adhaarNumber').setValue(benf.adhaarNumber);
      if (this.beneficiaryData != null && this.beneficiaryData != undefined) {
        if (this.beneficiaryData.ben_type_id == environment.static_ids.PregnantWomen || this.beneficiaryData.ben_type_id == environment.static_ids.LactatingMother) {
          this.personalDetForm.get('female_consent').setValidators([Validators.required]);
        }
        else {
          this.personalDetForm.get('female_consent').clearValidators();
          this.personalDetForm.get('female_consent').reset();
        }
      }
    });

    this.remoteInterfaceService.deviceInterfaceObservable$.subscribe(r => {
      this.aadharService.enableFaceRdService()
    });
    this.util.presentToastInfo("Please Verify Consent")
  }

  startFacialScan() {
    this.startTimeInMS = new Date().getTime();
    if (this.util.getIsMobileDevice()) {
      this.initializeRdServiceForAndroid()
    }else{
      this.initializeRdServiceForDeskTop()
    }
  }

  // checkedConsent() {
  //   if (this.personalDetForm.valid) {
  //     this.startFacialScan();
  //   }
  // }

  openBottomSheet() {
    this._bottomSheet.open(AadhaarConcentComponent);
  }


  async verifyFaceAuth(pidData: string) {
    this.aadharService.getAccessToken().pipe(
      switchMap(res => {
        if (res) {
          const aadhaarNumber = this.beneficiaryData.aadhaarNumber;
          const payload = {
            token: res.token,
            aadhaar: aadhaarNumber,
            pid: pidData,
            consentHealthId: true,
            flag: 'surveyor',
            bioType: 'FID',
          };
          return this.aadharService.verifyFaceAuth(payload);
        }
      })
    ).subscribe(res => {
      this.scannedData = res;
      this.verificationInProgress = false;
      // this.aadharService.setDataInFormNewQRDeskTop(res, this.personalDetForm);
      this.util.presentToastSuccess('Face Authentication Successful');
      this.endTimeInMS = new Date().getTime();
      const secTakenForTS = ((this.endTimeInMS - this.startTimeInMS)/1000).toFixed(2);
      let beneficiaryData = {
        ...this.beneficiaryData,
        ...{
          verifiedBy: 'Aadhar',
          verifiedType: 'facial',//Facial //faceauth
          aadharData: res,
          time_took:secTakenForTS
        },
      };
      if (this.beneficiaryData.beneficiary_id) {
        this.verificationInProgress = false;
        this.beneficiaryData.demoAadharstatus="reject";
        this.addRecipientService.beneficiaryDetails = beneficiaryData;
        this.gotoStep.emit('next');
      }
      else{
      this.addRecipientService.beneficiaryDetails = beneficiaryData;
      this.gotoStep.emit('next');
    }

      // this.saveRDMetaInfo();
    }, err => {
      this.verificationInProgress = false;
    });
  } 
  initializeRdServiceForAndroid(){
    AadhaarCalls.captureFace().then(pidDataRes => {
      if (pidDataRes.hasOwnProperty('PID_DATA')) {
        const pidData = pidDataRes.PID_DATA;
        const parser = new DOMParser();
        const xml = parser.parseFromString(pidData, 'text/xml');
        const biometricInfo: any = this.ngxXml2jsonService.xmlToJson(pidData,{});
        if (biometricInfo && biometricInfo.hasOwnProperty('PidData')) {
          const pidJSON = biometricInfo.PidData;
          if (pidJSON && pidJSON.hasOwnProperty('Resp')) {
            const response = pidJSON.Resp['@attributes'] || pidJSON.Resp['attr'];
            if (response.errCode != '0') {
              this.util.presentToastError('Face Capture Failed');
              const errMsg = response?.errInfo ? response.errInfo : 'Face Capture Failed';
              this.util.presentToastError(errMsg);
              this.verificationInProgress = false;
            } else {
              this.verifyFaceAuth(pidData);
            }
          } else {
            const message = 'Invalid Response';
            this.util.presentToastError(message);
            this.verificationInProgress = false;
          }
        } else {
          const message = 'Invalid Response';
          this.util.presentToastError(message);
          this.verificationInProgress = false;
        }
      } else if (pidDataRes.hasOwnProperty('message')) {
        const message = pidDataRes.message;
        this.util.presentToastError(message);
        this.verificationInProgress = false;
      }
    }).catch(err => {
      this.util.presentToastError('Invalid Response');
      this.verificationInProgress = false;
    });
  }


  initializeRdServiceForDeskTop() {
    const suid = localStorage.getItem('suid');
    this.util.presentToastInfo('Facial RD Service Initialized');
    this.verificationInProgress = true;
    return this.remoteInterfaceService.requestForPingAPI(suid).pipe(
      switchMap(res => {
        if (res === 'devping') {
          return this.remoteInterfaceService.requestForFaceAuth(suid);
        } else {
          return of('RC:1209')
        }
      })
    ).subscribe((res: any) => {
      if (res && res.ret == 'Y') {
        const pidData = res.pload;
        const parser = new DOMParser();
        const xml = parser.parseFromString(pidData, 'text/xml');
        const biometricInfo: any = this.ngxXml2jsonService.xmlToJson(pidData,{});
        if (biometricInfo && biometricInfo.hasOwnProperty('PidData')) {
          const pidJSON = biometricInfo.PidData;
          // console.log('Image Capture', JSON.stringify(pidJSON.CustOpts['Param']));
          if (pidJSON && pidJSON.hasOwnProperty('Resp')) {
            const response = pidJSON.Resp['@attributes'] || pidJSON.Resp['attr'];
            // console.log('Image Capture', JSON.stringify(response));

            if (response.errCode != '0') {
              this.aadharService.saveErrorLogOfFaceAuth(biometricInfo, pidData);
              const errMsg = response?.errInfo ? response.errInfo : 'Face Capture Failed';
              this.util.presentToastError(errMsg);
              this.verificationInProgress = false;
            } else {
              this.util.presentToastInfo('Verifying Facial eKYC');
              return this.verifyFaceAuth(pidData);;
            }
          } else {
            const message = 'Invalid Response';
            this.verificationInProgress = false;
            this.util.presentToastError(message);
          }
        } else {
          const message = 'Invalid Response';
          this.verificationInProgress = false;
          this.util.presentToastError(message);
        }
        return res;
      } else {
        if (res === 'RC:1201') {
          this.util.presentToastError('Session Timeout');
        } else if (res === 'RC:1209') {
          localStorage.removeItem('suid');
          this.aadharService.openPairDevicePopup();
          this.util.presentToastError('RD Mobile Device Not Reachable');
        } else if (res === 'RC:1205') {
          this.util.presentToastError('Read Timeout');
        } else {
          localStorage.removeItem('suid');
          this.aadharService.openPairDevicePopup();
          this.util.presentToastError('Error Occurred Connecting RD Service');
        }
        this.verificationInProgress = false;
      }
    }, err => {
      localStorage.removeItem('suid');
      this.aadharService.openPairDevicePopup();
      this.verificationInProgress = false;
      this.util.presentToastError('Error Occurred Connecting RD Service');
    });
  }

  onSubmit() {
    this.personalDetForm.markAllAsTouched();
    if (!this.personalDetForm.valid) { return; }
    // this.gotoStep('next');
    this.startFacialScan();

  }
}
