<!-- Profile Header Start -->
<ng-template #profile>
  <ion-header class="noDesktop mobile-header">


    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button (click)="navigate()" text="" icon="arrow-back-outline">
        </ion-back-button>
      </ion-buttons>
      <ion-button *ngIf="(pageData.age <= 5 || pageData.is_pregnant_woman || declareDeathEnable(pageData)) && userInfo?.role !== 'ASHA' 
        && !pageData.ben_death && !declaredDeath" size="small" slot="end" class="primaryBtn mobile-btn" fill="outline"
        mat-icon-button [matMenuTriggerFor]="menuTabs">
        <ion-icon class="custom-mobile-dots"></ion-icon>
        <mat-menu #menuTabs="matMenu">
          <button *ngIf="pageData.is_pregnant_woman && userInfo?.role === 'Vaccinator'" mat-menu-item (click)="homeDelivery(pageData)">
            <span>{{'Home Delivery' | translate}} </span>
          </button>
          <button *ngIf="pageData.is_pregnant_woman" mat-menu-item (click)="abortPregnant(pageData)">
            <span>{{'Declare Abortion' | translate}} </span>
          </button>
          <button *ngIf="(declareDeathEnable(pageData)) && !pageData.ben_death" mat-menu-item (click)="declareDeath(pageData)">
            <span>{{'Declare Death' | translate}}</span>
          </button>
        </mat-menu>
      </ion-button>
      <h4 class="heading">{{'Beneficiary Details' | translate}}</h4>
    </ion-toolbar>


  </ion-header>
</ng-template>
<!-- Profile Header End -->

<!-- Common Header Start -->
<ng-template #commonPageHeading>
  <ion-header class="noDesktop mobile-header">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button *ngIf="pageData.title !='Record Previous Vaccination'" (click)="onCommonPageBack(pageData)"
          text="" icon="arrow-back-outline"></ion-back-button>
      </ion-buttons>
      <h4 class="heading">{{pageData.title | translate}}</h4>
    </ion-toolbar>
  </ion-header>
</ng-template>
<!-- Common Header End -->

<!-- Registration Header Start -->
<ng-template #newMember>
  <ion-header class="noDesktop mobile-header-white">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button (click)="goBack()" text="" icon="arrow-back-outline"></ion-back-button>
      </ion-buttons>
      <h4 class="heading">{{pageData.title | translate}}</h4>
    </ion-toolbar>
  </ion-header>
</ng-template>
<!-- Registration Header End -->

<!-- Session Start Header Start -->
<ng-template #sessionHeading>
  <ion-header class="noDesktop mobile-header">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button (click)="goBack()" text="" icon="arrow-back-outline">
        </ion-back-button>
      </ion-buttons>

      <h2 class="heading sessionHeaderTitle">
        {{pageData.material_name ? pageData.material_name : (pageData?.uip_session_category) ?
        (pageData?.uip_session_category) : 'UIP' }}
        <span
          [ngClass]="{
            'onGoing': pageData.session_status == 1,
            'yetToStart': pageData.session_status == 0 ||  pageData.session_status == 6,
            'completed': pageData.session_status == 2 ||  pageData.session_status == 3 ||  pageData.session_status == 5}"></span>
      </h2>
    </ion-toolbar>

    <ion-row>
      <ion-col size="12" class="ion-no-padding">
        <span class="mini-text">{{dateLabel(pageData)}} ({{pageData.start_time | date: 'hh:mm a'}} - {{pageData.end_time
          | date: 'hh:mm a'}}) |
          {{pageData.ageGroup}}</span>
      </ion-col>
    </ion-row>

  </ion-header>
</ng-template>
<!-- Session Start Header End -->



<!-- Session End Header Start -->
<ng-template #startEndSession>
  <ion-header class="noDesktop mobile-header">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button defaultHref="/" text="" icon="arrow-back-outline">
        </ion-back-button>
      </ion-buttons>

      <h2 class="heading">
        {{pageData?.title}}
      </h2>
    </ion-toolbar>

    <ion-row>
      <ion-col size="12" class="ion-no-padding">
        <span class="mini-text">{{pageData?.material_name}} | {{pageData?.date_of_session | date: 'mediumDate'}} |
          {{pageData?.start_time | date: 'hh a'}} - {{pageData?.end_time | date: 'hh a'}}</span>
      </ion-col>
    </ion-row>
  </ion-header>
</ng-template>
<!-- Session End Header End -->

<ng-container>
  <div class="noDesktop">
    <ng-template [ngTemplateOutlet]="
  template == 'commonPageHeading' ? commonPageHeading :
  template == 'session' ? sessionHeading :
  template == 'start-end-session' ? startEndSession :
  template == 'new-member' ? newMember :
  template == 'profile' ? profile :
  commonTpl"></ng-template>
  </div>
</ng-container>
<ng-container>
  <div class="noMobile">
    <ng-template [ngTemplateOutlet]="commonTpl"></ng-template>
  </div>
</ng-container>

<ng-template #commonTpl>
  <ion-grid *ngIf="userDetails$ | async as userInfo">
    <ion-row class="spaceBetween">
      <ion-col size="12" size-xs="2" size-sm="12" size-md="1" size-lg="2">
        <div (click)="toggleSideNav()" class="hamburgerIcon">
          <ion-icon slot="start" class="custom-hamburger"></ion-icon>
        </div>

        <h1 class="headerTitle ion-hide-lg-down"
          title="{{siteInfo?.session_site_name}} {{userInfo?.block ? ', ' + userInfo?.block : ''}}">
          <img class="noMobile" src="assets/icons/building.svg" />
          <span title="{{userInfo?.session_site_name}} {{userInfo?.block ? ', ' + userInfo?.block : ''}}">
            {{siteInfo?.session_site_name}} {{userInfo?.block ? ', ' + userInfo?.block : ''}}
          </span>
        </h1>
        
      </ion-col>
      <ng-container *ngIf="!hideSearch">
        <ion-col size="12" size-xs="12" size-sm="12" size-md="6" size-lg="5" class="noMobile">
          <app-web-search-beneficary></app-web-search-beneficary>
        </ion-col>
      </ng-container>
      <ng-container *ngIf="hideSearch">
        <ion-col size="12" size-xs="12" size-sm="12" size-md="6" size-lg="5" class="noMobile">
        </ion-col>
      </ng-container>
      <ion-col size="12" size-xs="10" size-sm="12" size-md="5" size-lg="5" class="ion-text-end ion-align-self-end">


        <ion-text class="siteInfo"
          *ngIf="userInfo?.role == environment.static_ids.DeliveryManagerKey || userInfo?.role == 'ASHA' || userInfo?.role == environment.static_ids.BothplanningUnitAndDelivery || userInfo?.role == environment.static_ids.BothVaccinatorAndDeliveryManager"
          title="{{userInfo?.state}}|{{userInfo?.district}}{{userInfo?.subdistrict
            ? '|'+userInfo?.subdistrict : ''}}{{userInfo?.block ? '|'+userInfo?.block : ''}}{{userInfo?.facility_name ?
            '|'+userInfo?.facility_name : '' }}"><span>{{userInfo?.state}}</span><span>{{userInfo?.district}}{{userInfo?.subdistrict
              ? ''+userInfo?.subdistrict : ''}}{{userInfo?.block ? ''+userInfo?.block : ''}}</span><span>{{userInfo?.facility_name ?
                ''+userInfo?.facility_name : '' }}{{userInfo?.role == 'ASHA' ? villageNames ?''+villageNames : ''
                :''}}</span></ion-text>

        <ion-text class="headerSearchIcon ion-hide-lg-up ionHideNexus"><ion-icon
            class="custom-search" routerLink="/search-beneficiary"></ion-icon></ion-text>
        <button mat-button class="dpBtn user-menu-icon" [matMenuTriggerFor]="menu">
          <img *ngIf="!profilePic" [src]="userInfo?.profile_picture_url" (error)="handleMissingImage($event)"
            alt="profile-avtar" class="profileAvatar" />
          <img *ngIf="profilePic" src="assets/images/profilepic.png" alt="profile-avtar" class="profileAvatar" />
        </button>
        <mat-menu #menu="matMenu" class="userProfile">
          <mat-card>
            <mat-card-header>
              <div mat-card-avatar>
                <button mat-button class="dpBtn">
                  <img *ngIf="!profilePic" [src]="userInfo?.profile_picture_url" (error)="handleMissingImage($event)"
                    alt="profile-avtar" class="profileAvatar" />
                  <img *ngIf="profilePic" src="assets/images/profilepic.png" alt="profile-avtar"
                    class="profileAvatar" />
                </button>
              </div>
              <mat-card-title>{{userInfo?.display_name}}</mat-card-title>
              <mat-card-title class="role">({{userInfo?.role}})</mat-card-title>
              <mat-card-subtitle routerLink="/my-profile">{{'View Profile' | translate}}</mat-card-subtitle>
              
            </mat-card-header>
            <mat-card-footer>
              <button (click)="logOut()" mat-menu-item>{{'Logout' | translate}}</button>
            </mat-card-footer>
          </mat-card>
        </mat-menu>
      </ion-col>
    </ion-row>
  </ion-grid>
</ng-template>