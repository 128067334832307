<!-- <ion-col class="notifications">
    <ion-icon class="custom-bell" [matMenuTriggerFor]="notification"></ion-icon>
    <span class="notificationInd"></span>

    <mat-menu #notification="matMenu" class="notificationsPanel">
        <mat-card>
            <mat-card-content>
                <ion-grid> -->
                    <!-- <h3>
                      New
                  </h3> -->
                    <!-- <ion-text>
                        <b>COVISHIELD’s 2nd dose</b> will remain valid for pre booked appointments even if the period is <b>less than 84 days... </b> <span (click)="openDialog()" class="read-more">Read More</span>
                        <br>
                        <span class="date">1 Aug 2021</span>
                    </ion-text>
                </ion-grid> -->
                <!-- <ion-grid>
                  <h3>
                      Older
                  </h3>
                  <ion-text>Feature to <b>pair Android smartphone with CoWIN</b> has been added & can be done from <b>Configuration Menu.</b>
                      <br>
                      <span class="date">20 Jul 2021</span>
                  </ion-text>

                  <ion-text><b>COVISHIELD’s 2nd dose</b> will remain valid for pre booked appointments even if the period is <b>less than 84 days.</b>
                      <br>
                      <span class="date">1 Jul 2021</span>
                  </ion-text>
              </ion-grid> -->
<!-- 
            </mat-card-content>
        </mat-card>
    </mat-menu>
</ion-col> -->
 
<ion-col class="notifications">
    <ion-icon name="notifications" [matMenuTriggerFor]="notification"></ion-icon>
    <span class="notificationInd"></span>
  
     <mat-menu #notification="matMenu" class="notificationsPanel">
        <mat-card>
            <mat-card-content>
                <ion-grid>
                    <h3>
                        New
                    </h3>
                    <ion-text>
                        <b>Greetings from MoHFW,</b><br>
                        <!-- Appreciation Letter..<a>read more</a> -->
                        <br>
                        <span class="date">{{notify2Date | displayDateFormat}}</span>
                    </ion-text>
                </ion-grid>
                <!-- <ion-grid>
                    <h3>
                        Older
                    </h3>
                    <ion-text (click)="openDialog('150-CRORE')">
                      <b>Greetings from MoHFW,</b><br>
                      As India crosses the milestone of more than 150 crore covid-19 vaccine doses administered..<a>read more</a>
                      <br>
                      <span class="date">{{notify1Date | date:'dd MMM y'}}</span>
                  </ion-text>
              </ion-grid> -->
  
            </mat-card-content>
        </mat-card>
    </mat-menu>
  </ion-col>

