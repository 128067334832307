import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'src/app/modules/core/services/local-storage.service';
import { getUserDetails } from 'src/app/modules/core/store/selectors/user.selectors';
import { SessionService } from 'src/app/screens/session/services/session.service';
import { EncDecService } from '../../services/encryption-decryption.service';
import { LookUpService } from '../../services/look-up.service';
import { UtilsService } from '../../services/utils.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-asha-villages-filter',
  templateUrl: './asha-villages-filter.component.html',
  styleUrls: ['./asha-villages-filter.component.scss'],
})
export class AshaVillagesFilterComponent implements OnInit {
  userDetails;
  lgdVillages = [];
  wardData = [];
  healthViillages = [];
  healthWards = [];
  selectedVillages = [];
  villageValues: any;
  sessions: Observable<any>;
  @Output() villagesData = new EventEmitter();
  villageIds: any;
  healthVillageIds: any;
  wardIDs: any;
  healthWardIDs: any;
  villageDetailsForm: FormGroup;
  selectedVillageIds: any = [];
  getLocationIds: any;
  values: any;
  environment;
  sessionSites: any
  getVillageByIdData: any = [];

  constructor(private lookUpServ: LookUpService, public util: UtilsService, private encDecServ: EncDecService,
    private store$: Store, public sessionService: SessionService, public localStorage: LocalStorageService) {
  }

  ngOnInit() {
    this.environment = environment;
    this.store$.pipe(select(getUserDetails)).subscribe(userInfo => {
      this.userDetails = userInfo;
    });
    this.onInit();
    this.sessionService.getAllSitesOfAsha().subscribe(r => {
      this.sessionSites = r;
      this.getVillagesByUser();
    })

  }

  onInit() {
    this.villageDetailsForm = new FormGroup({
      session_site: new FormControl(''),
      village_ID: new FormControl(''),
      health_village_ID: new FormControl(''),
      ward_Id: new FormControl(''),
      health_ward_Id: new FormControl('')
    });
  }

  getVillagesByUser() {
    this.lookUpServ.getVillagebyAshaid(this.encDecServ.get(this.userDetails?.user_id)).subscribe(res => {
      this.getVillageByIdData = res;
      if(this.localStorage.getItem('session_site_id')){
        this.villageDetailsForm.get('session_site').setValue(this.localStorage.getItem('session_site_id'));
        this.sessionSiteDetails(this.localStorage.getItem('session_site_id'));
      }
      this.setDefaultVillage();
    })
  }

  setDefaultVillage(){
    if (this.localStorage.getItem('location_Id')) {
      this.villageIds = this.localStorage.getItem('location_Id')
      if (this.villageIds.type == 'village_ID') {
        this.villageDetailsForm.get('village_ID').setValue(this.villageIds.value);
      }
      else if (this.villageIds.type == 'health_village_ID') {
        this.villageDetailsForm.get('health_village_ID').setValue(this.villageIds.value);

      }
      else if (this.villageIds.type == 'ward_Id') {
        this.villageDetailsForm.get('ward_Id').setValue(this.villageIds.value);

      }
      else if (this.villageIds.type == 'health_ward_Id') {
        this.villageDetailsForm.get('health_ward_Id').setValue(this.villageIds.value);

      }
    }
  }

  sessionSiteDetails(e) {
    let value = e;
    this.lgdVillages = [];
    this.wardData = [];
    this.healthViillages = [];
    this.healthWards = [];
    this.villageDetailsForm.get('village_ID').reset()
    this.villageDetailsForm.get('health_village_ID').reset()

    this.villageDetailsForm.get('ward_Id').reset()
    this.villageDetailsForm.get('health_ward_Id').reset()

    // Send the session_site_name to store.
    this.localStorage.setItem('session_site_id', value);
    const filteredVillages = this.getVillageByIdData.filter(village => village.session_site_id === value);
    if (filteredVillages) {
      this.lgdVillages = filteredVillages.filter(e => !e.isHealthVillage && !e.ward_id && e.village_name !== null);
      this.wardData = filteredVillages.filter(e => !e.isHealthWard && !e.village_id && e.ward_name !== null);
      this.healthViillages = filteredVillages.filter(e => e.isHealthVillage && e.village_name !== null);
      this.healthWards = filteredVillages.filter(e => e.isHealthWard && e.ward_name !== null);
    }
  }

  selectVillages(event, villageKey) {
    if (this.util.getPlatformWidth() < environment?.static_ids?.TabResoltion) {
      this.villageValues = event.detail.value

    } else {
      this.villageValues = event.value
    }
    if (this.villageValues && villageKey == 'village_ID') {
      if (this.villageDetailsForm.get('health_village_ID').value) {
        this.villageDetailsForm.get('health_village_ID').reset();
      }
      this.getLocationIds = this.lgdVillages.filter(item => item.village_id == this.villageValues);
      //  this.selectedVillages.push({type:villageKey,value:event.value});
    }
    else if (this.villageValues && villageKey == 'health_village_ID') {
      if (this.villageDetailsForm.get('village_ID').value) {
        this.villageDetailsForm.get('village_ID').reset()
        // this.villageDetailsForm.get()
      }
      this.getLocationIds = this.healthViillages.filter(item => item.village_id == this.villageValues);
    }
    else if (this.villageValues && villageKey == 'ward_Id') {
      if (this.villageDetailsForm.get('health_ward_Id').value) {
        this.villageDetailsForm.get('health_ward_Id').reset();
      }
      this.getLocationIds = this.wardData.filter(item => item.ward_id == this.villageValues);

    }
    else if (this.villageValues && villageKey == 'health_ward_Id') {
      if (this.villageDetailsForm.get('ward_Id').value) {
        this.villageDetailsForm.get('ward_Id').reset();
      }
      this.getLocationIds = this.healthWards.filter(item => item.ward_id == this.villageValues);
    }
    this.selectedVillages.push({ type: villageKey, value: this.villageValues, village_name: (villageKey == 'village_ID' || villageKey == 'health_village_ID') ? this.getLocationIds[0].village_name : this.getLocationIds[0].ward_name });
    this.selectedVillageIds = this.selectedVillages.filter(item => item.value == this.villageValues)

    let payload;

    if (this.userDetails.village_data.ward_ids.length > 0 || this.userDetails.village_data.health_ward_ids.length > 0) {
      payload = {
        health_ward_id: this.selectedVillageIds[0].type == 'health_ward_Id' ? [this.selectedVillageIds[0].value] : undefined,
        ward_id: this.selectedVillageIds[0].type == 'ward_Id' ? [this.selectedVillageIds[0].value] : undefined,
      }
    }
    if (this.userDetails.village_data.health_village_ids.length > 0 || this.userDetails.village_data.village_ids.length > 0) {
      payload = {
        health_village_id: this.selectedVillageIds[0].type == 'health_village_ID' ? [this.selectedVillageIds[0].value] : undefined,
        village_id: this.selectedVillageIds[0].type == 'village_ID' ? [this.selectedVillageIds[0].value] : undefined,
      };
    }
    this.sessions = this.sessionService.getOnGoingAndUpComingSessionsForAsha(payload);
    this.sessionService.FromAshaFilterToSession(this.sessions);
    this.localStorage.setItem('location_Id', this.selectedVillageIds[0]);
    this.sessionService.storageValues(this.selectedVillageIds[0].village_name)
  }
}
