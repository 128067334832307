import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef, SimpleChanges, Input, } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { DataService } from 'src/app/modules/shared/services';
import { UtilsService } from 'src/app/modules/shared/services/utils.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Location } from '@angular/common';
import { Device } from '@capacitor/device';
import { FormArray, FormBuilder, FormControl, FormControlName, FormGroup, Validators } from '@angular/forms';
import { AddRecipientService } from '../../services/add-recipient.service';
import { SessionService } from 'src/app/screens/session/services/session.service';
import { LookUpService } from 'src/app/modules/shared/services/look-up.service';
import { ImageUploadService } from '../../../../modules/shared/services/image-upload.service';
import { EncDecService } from 'src/app/modules/shared/services/encryption-decryption.service'
import { Subscription } from 'rxjs';
import { RecipientService } from 'src/app/screens/recipient/services/recipient.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-add-recipient-vaccination-info',
  templateUrl: './vaccination-info.component.html',
  styleUrls: ['./vaccination-info.component.scss'],
})
export class VaccinationInfoComponent implements OnInit {
  benfSubscription: Subscription;
  @Input() activeStepIndex = null;
  @Output() onStepChange: EventEmitter<any> = new EventEmitter();
  @Output() onClose: EventEmitter<any> = new EventEmitter();

  // dosesArr = [{ id: 0, name: 'Dose 1' }, { id: 1, name: 'Dose 2' }, { id: 2, name: 'Dose 3' }];
  dosesArr = []
  vaccDetForm: FormGroup;
  doseDates = [];
  allStepsData;
  totalSessions;
  sessions: any = [];
  yobList;
  year;
  doseDate1;
  doseDate2;
  submitPayLoad: any
  show = false;
  sessionDosesValue
  material_id;
  dateSelection;
  genderList;
  gender;
  session;
  coordinates: any;
  deviceInfo: any;
  isCheckingDate: boolean = false;
  maxDate = new Date();
  materialIdFromSession;
  // fileUploadData;
  // fileExt;
  sendResponse;
  changeEventYear;
  materialArray: any = []
  // showErrorMessage;
  sessionCapacity: boolean = false;
  Inprogress: boolean = false;
  minDate = new Date(2021, 0, 16);
  errorMessage = '';
  vaccinesEligible: any = [];
  formSubmitted: boolean = false;
  duplicateVaccinesEligible: any = [];
  showPreviousVaccination = false;
  loadingPrevVaccines = false;
  prevVaccinesByAge: any = [];
  minDoseDate = new Date();
  maxDoseDate = new Date();
  uipVaccineByBenArr: any = [];
  showDoseInfo = false;
  selectedMat: any = null;
  allMaterailsData: any = [];
  villageDetails = [];
  environment;
  isMaterialDueDateAvailable = true;
  isPentavalentVaccineChecked:boolean = false;
  isDptVaccineChecked:boolean = false;
  healthVillagesDetails=[];
  wardDetails = [];
  healthWardDetails = [];
  wardData = [];
  healthWards = [];
  showPrevVaccBtn:boolean=true;

  constructor(public addRecipientService: AddRecipientService, public sessionService: SessionService, public cdrChange: ChangeDetectorRef, public lookupService: LookUpService, public util: UtilsService, private fb: FormBuilder,
    public dataService: DataService, public imageService: ImageUploadService, private encrypt: EncDecService, private SessionService: SessionService, private loc: Location,
    private recipientService: RecipientService, private lookup: LookUpService) {
    this.maxDoseDate.setDate(this.maxDoseDate.getDate() - 1);
    this.vaccDetForm = this.fb.group({
      session_id: ['', Validators.required],
      dose: '',
      vaccinated_outside_india: '',
      materialDetails: this.fb.array([]),
      dose_received: '',
      village_id: '',
      health_village_id:'',
      ward_id: '',
      health_ward_id:''
    });
    this.lookupService.getMaterials().subscribe(m => { this.allMaterailsData = m });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.activeStepIndex) {
      if (this.activeStepIndex == 'VACCINATIONINFO') {
        this.reloadComponent();
      } else {
        this.unSubscribe();
      }
    }
  }

  async ngOnInit() {
    this.environment = environment
    
    const siteVillages = await this.lookup.getSiteVillages().toPromise();
    this.villageDetails = siteVillages.villageData;
    this.healthVillagesDetails = siteVillages.healthVillageData;
    this.wardData = siteVillages.wardData;
    this.healthWards = siteVillages.healthWardData;

  }
  onselectedDoseEvent(material,d, event) {
    this.vaccDetForm.get('materialDetails').value.forEach(element => {
      if (element.material_id == material.material_id) {
        element['material_dose'] = event.value;
        element['is_precaution'] = d.is_precaution
      }
    })
  }
  onselectedDoseDateEvent(material, event) {
    this.vaccDetForm.get('materialDetails').value.forEach(element => {
      if (element.material_id == material.material_id) {
        element['prev_vaccination_date'] = moment(event.value).format('YYYY-MM-DD');
      }
    });
  }
  onMaterialCheckSelection(material, event) {
    const formArray: FormArray = this.vaccDetForm.get('materialDetails') as FormArray;
    if (event.checked) {
      material['checked'] = true;
      if(material.material_id == environment.static_ids.Pentavalent_Vaccine){
        this.isFullyVaccinatedByMaterial(material)
        this.isPentavalentVaccineChecked = true;
      }
      if(material.material_id == environment.static_ids.DPT_vaccine ){
        this.isFullyVaccinatedByMaterial(material)
        this.isDptVaccineChecked = true;
      }
      let matDet: any = {
        material_id: material.material_id,
        material_name: material.material_name,
        material_dose: material.no_of_doses > 1 ? null : 1,
        prev_vaccination_date: this.showPreviousVaccination ? null : undefined
      };
      formArray.push(new FormControl(matDet));
    } else {
      if(material.material_id == environment.static_ids.Pentavalent_Vaccine){
        this.isPentavalentVaccineChecked = false;
        this.isPentavalentVaccineChecked = this.uipVaccineByBenArr.find(m=>m.material_id === environment.static_ids.Pentavalent_Vaccine) ? true : false;
      }
      if(material.material_id == environment.static_ids.DPT_vaccine){
        this.isDptVaccineChecked = false;
        this.isDptVaccineChecked = this.uipVaccineByBenArr.find(m=>m.material_id === environment.static_ids.DPT_vaccine) ? true : false;
      }
      let i: number = 0;
      this.errorMessage = '';
      const indexes  = formArray.controls.map((ctrl: FormControl,index)=>{
        if (ctrl.value.material_id == material.material_id) {
          return index;
        }
        }).filter(element => element >= 0);
        for(let i= indexes.length-1;i >=0;i--){
          formArray.removeAt(indexes[i]);
        }
          material['checked'] = false;
          if (this.showPreviousVaccination) {
            material['prev_vaccination_date'] = null;
          }
        return
      // formArray.controls.forEach((ctrl: FormControl) => {
      //   // Remove the unselected element from the arrayForm
      //   if (ctrl.value.material_id == material.material_id) {
      //     formArray.removeAt(i);
      //     console.log(formArray,"valuess")
      //     material['checked'] = false;
      //     if (this.showPreviousVaccination) {
      //       material['prev_vaccination_date'] = null;
      //     }
      //     return;
      //   }
      //   i++;
      // });
    }
  }
  isDoseEligibleForCurrentAge(material,dose){
    if(dose.doseReceivedDate || dose.isVaccinatedOutside){return true};
    const materialDoseInfo = this.allMaterailsData.find(e => e.material_id == material.material_id);
   
    let maxAgeInDays = 0;let minAgeInDays = 0;let agelimit = null; 
    if(dose.is_precaution){ 
      minAgeInDays = materialDoseInfo.precaution_lower_age_limits[dose.dose - 1];
      maxAgeInDays = materialDoseInfo.precaution_upper_age_limits[dose.dose - 1];
      agelimit = materialDoseInfo.precaution_display_upper_age_limits[dose.dose - 1];
    }else{
      minAgeInDays = materialDoseInfo.lower_age_limits[dose.dose - 1];
      maxAgeInDays = materialDoseInfo.upper_age_limits[dose.dose - 1];
      agelimit = materialDoseInfo.display_upper_age_limits[dose.dose - 1];
    }
    if(maxAgeInDays == null || !agelimit || agelimit == 'nullD'){return true}
    let currentDate = moment();
    const days = currentDate.diff(this.allStepsData.dob,'days')
    if(days < maxAgeInDays && days >= minAgeInDays){
    return true;
    }else{
    return false;
    }
  }

  isDoseEligibleForMinAge(material,dose){
    if(dose.doseReceivedDate || dose.isVaccinatedOutside){return true};
    const materialDoseInfo = this.allMaterailsData.find(e => e.material_id == material.material_id);
    let maxAgeInDays = 0;let minAgeInDays = 0;let agelimit = null; 
    if(dose.is_precaution){ 
      minAgeInDays = materialDoseInfo.precaution_lower_age_limits[dose.dose - 1];
      maxAgeInDays = materialDoseInfo.precaution_upper_age_limits[dose.dose - 1];
      agelimit = materialDoseInfo.precaution_display_upper_age_limits[dose.dose - 1];
    }else{
      minAgeInDays = materialDoseInfo.lower_age_limits[dose.dose - 1];
      maxAgeInDays = materialDoseInfo.upper_age_limits[dose.dose - 1];
      agelimit = materialDoseInfo.display_upper_age_limits[dose.dose - 1];
    }
    let currentDate = moment();
    const days = currentDate.diff(this.allStepsData.dob,'days')
    if(maxAgeInDays == null || !agelimit || agelimit == 'nullD'){return true}
    
    if(days >= minAgeInDays){
    return true;
    }else{
    return false;
    }
  }
  
  onMaterialCheckEvent(material, event, index) {
    const formArray: FormArray = this.vaccDetForm.get('materialDetails') as FormArray;
    if (event.checked) {
      // Add a new control in the arrayForm
      formArray.push(new FormControl({
        material_id: material.material_id, material_name: material.material_name,
        material_dose: material.no_of_doses > 1 ? null : 1,
      }));
      let value = material.no_of_doses;
      for (let i = 1; i <= value; i++) {
        this.materialArray.push({ id: i, name: `Dose ${i}`, material_id: material.material_id, index: index, radioname: material.material_name });
      }
    }
    /* unselected */
    else {
      // find the unselected element
      let i: number = 0;
      this.errorMessage = '';
      formArray.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value.material_id == material.material_id) {
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          this.materialArray = this.materialArray.filter(el => {
            return el.material_id != material.material_id;
          })
          return;
        }

        i++;
      });
    }
  }
  onselectedDose(event) {
    const formArray: FormArray = this.vaccDetForm.get('materialDetails') as FormArray;
    this.vaccDetForm.get('materialDetails').value.forEach(element => {
      if (element.material_name == event.value.radioname) {
        element['material_dose'] = event.value.id;
      }
    });
  }
  unSubscribe() {
    if (this.benfSubscription) this.benfSubscription.unsubscribe();
    // if(this.benfVerSubscription)this.benfVerSubscription.unsubscribe();
  }
  loadCurrentVaccines() {
    this.gotoStep('prev');
    this.getAllUipVaccinesByBenf();
  }

  async reloadComponent() {
    this.clearMaterialFormCtrlArr();
    this.showPreviousVaccination = false;
    this.deviceInfo = await Device.getInfo();
    this.benfSubscription = this.addRecipientService.benSuccessData.subscribe(res => {
      this.allStepsData = res;
      this.minDoseDate = new Date(res?.dob);
      this.maxDoseDate =  new Date(moment(this.allStepsData?.date_of_session).subtract('days',1).format('YYYY-MM-DD'));
      // if (res.is_pregnant_woman == true) {
      //   let year = new Date(res.dob).getFullYear();
      //   let month = new Date(res.dob).getMonth();
      //   let day = new Date(res.dob).getDay();
      //   this.minDoseDate = new Date(year + 15, month, day);
      // }
      if(res.dob==moment().format('DD-MMM-YYYY')){
        this.showPrevVaccBtn=false;
      }
      else{
        this.showPrevVaccBtn=true;
      }
      if (res.ben_type_id == environment.static_ids.PregnantWomen ||(res?.lmc_date && res.is_pregnant_woman)) {
        this.minDoseDate = new Date(res.lmc_date);
      }
      if (res?.session_id) {
        this.vaccDetForm.get('session_id').setValue(res?.session_id);
        if (this.allStepsData.session_type_name == 'UIP') {
          this.getAllUipVaccinesByBenf();
        }
      } else {
        this.getSessionsByAge();
      }
    })
    this.lookupService.getYob().subscribe(res => {
      this.yobList = res
    })
    this.lookupService.getGender().subscribe(res => {
      this.genderList = res
    })
    this.lookupService.getMaterials().subscribe(res => {
      this.allMaterailsData = res;
    })

  }

  getSessionId(event) {
    this.sessionCapacity = false;
    this.vaccDetForm.get('dose').reset();
    this.dosesArr = [];
    this.session = event;
    // direct second dose third dose removed
    // if (this.sessionService.isNewVaccine(this.session)) {
    //   this.sessionDosesValue = 1;
    // } else {
    //   this.sessionDosesValue = event.dose_scheduled;
    // }
    this.sessionDosesValue = 1;
    // this.dosesArr = [];
    // for (let i = 0; i < this.sessionDosesValue; i++) {
    //   this.dosesArr.push({ id: i, name: `Dose ${i + 1}` })
    // }
    this.materialIdFromSession = event.material_id;
    this.allStepsData.session_type_name == this.session.session_type_name;
    if (this.session.session_type_name == 'UIP') {
      this.clearMaterialFormCtrlArr();
      this.getUIPMaterialsByBenf(this.session.session_id);

    } else {
      this.vaccinesEligible = [];
      this.duplicateVaccinesEligible = [];
      this.clearMaterialFormCtrlArr();
    }
  }

  getUIPMaterialsByBenf(sessID) {
    const params = {
      beneficiary_id: this.allStepsData.beneficiary_id,
      session_id: sessID,
    }
    this.loadingPrevVaccines = true;
    this.showPreviousVaccination = true;
    // this.cdrChange.detectChanges();
    this.addRecipientService.getuipMaterialdosedetails(params).subscribe(res => {
      this.vaccinesEligible = res;
      this.duplicateVaccinesEligible = this.vaccinatedDoseOfBenf(this.vaccinesEligible);
      if (this.allStepsData?.is_pregnant_woman) {
        this.duplicateVaccinesEligible = this.validateTdBooster(this.duplicateVaccinesEligible);
      }
      this.isMaterialDueDateAvailable = true;
      this.duplicateVaccinesEligible.forEach((vaccine) => {
        if (this.isMaterialDueDateAvailable && vaccine.is_eligible) {
          this.isMaterialDueDateAvailable = false;
        }
        vaccine.dose_array?.forEach(dose => {
          if (this.isMaterialDueDateAvailable && dose.isEligible) {
            this.isMaterialDueDateAvailable = false;
          }
        })
      });
      this.cdrChange.detectChanges();
      this.loadingPrevVaccines = false;
      this.showPreviousVaccination = false
      this.cdrChange.detectChanges();
    }, e => {
      this.loadingPrevVaccines = false;
    })
  }

  ageGroup(sess) {
    if (!sess?.maximum_age_allowed) {
      return `${sess?.minimum_age_allowed} & Above`;
    }
    return `${sess?.minimum_age_allowed} - ${sess?.maximum_age_allowed}`;
  }


  onSelectDoseDate(event) {
  }

  doseChange(event) {
    if (event.value == 0 && this.session?.onspot_dose_one_available == 0) {
      this.sessionCapacity = true;
    }
    else if (event.value == 1 && this.session?.onspot_dose_two_available == 0) {
      this.sessionCapacity = true;
    }
    else if (event.value == 2 && this.session?.onspot_dose_three_available == 0) {
      this.sessionCapacity = true;
    }
    else {
      this.sessionCapacity = false;
    }
    this.isCheckingDate = false
  }

  resetPrevSelection() {
    this.doseDates.forEach(i => {
      this.vaccDetForm.removeControl(`dose${i}_date`)
    });
    this.doseDates = [];
  }

  gotoStep(val) {
    if (this.showPreviousVaccination && val != 'next') {
      this.clearMaterialFormCtrlArr();
      this.showPreviousVaccination = false;
      return;
    }
    this.vaccDetForm.get('dose').reset();
    this.onStepChange.emit(val);
  }

  closeDialog() {
    this.onClose.emit()
  }

  getSessionsByAge() {
    // this.material_id = 3; //for checking
    this.addRecipientService.sendYear.subscribe(res => {
      this.changeEventYear = res;
    })
    if (this.allStepsData?.dob) {
      // this.year = moment(this.allStepsData.dob).format('YYYY');
      this.year = this.allStepsData.dob;
    } {
      this.yobList?.find(ele => {
        if (this.allStepsData?.yob == ele.id) {
          this.year = ele.year;
        } else if (this.changeEventYear == ele.id) {
          this.year = ele.year;
        }
      });
    }

    this.genderList?.find(ele => {
      if (this.allStepsData?.gender == ele.id) {
        this.gender = ele.gender;
      }
    })
  }

  onSubmit() {
    let sessionTypeName = this.session?.session_type_name;
    // if (this.villageDetails.length > 0) {
    //   this.vaccDetForm.get('village_id').setValidators([]);
    //   if(!this.showPreviousVaccination){ 
    //     this.vaccDetForm.get('village_id').setValidators([Validators.required]);
    //   }
    //   this.vaccDetForm.get('village_id').updateValueAndValidity();
    //   if (!this.showPreviousVaccination && this.vaccDetForm.get('village_id').value == '' || this.vaccDetForm.get('village_id').value == null) {
    //     this.util.presentToastError("Please Select Village")
    //   }
    // } else {
    //   this.vaccDetForm.get('village_id').clearValidators();
    //   this.vaccDetForm.get('village_id').updateValueAndValidity();
    // }
    if(this.villageDetails.length > 0 || this.healthVillagesDetails.length > 0){
    if(!this.showPreviousVaccination 
      && (this.vaccDetForm.get('village_id').value == '' 
      || this.vaccDetForm.get('village_id').value == null) 
      &&  (this.vaccDetForm.get('health_village_id').value == '' 
      || this.vaccDetForm.get('health_village_id').value == null)
      && (this.vaccDetForm.get('ward_id').value == '' 
      || this.vaccDetForm.get('ward_id').value == null) 
      &&  (this.vaccDetForm.get('health_ward_id').value == '' 
      || this.vaccDetForm.get('health_ward_id').value == null)){
      this.util.presentToastError("Please Select Location")
      return;
}
    }
    this.errorMessage = '';
    this.vaccDetForm.markAllAsTouched();
    this.vaccDetForm.get('dose').setValue(0);


    if (this.allStepsData?.session_id) {
      this.vaccDetForm.get('session_id').setValue(this.allStepsData?.session_id);
      sessionTypeName = this.allStepsData?.session_type_name;
    }
    let selectedMaterials = this.vaccDetForm.get('materialDetails').value;
    this.formSubmitted = true;
    selectedMaterials.forEach((element, index) => {
      if (!this.checkMaterialDoseIsEligible(element)) {
        selectedMaterials.splice(index, 1);
        return;
      }
      if (element.material_dose == null) {
        this.errorMessage = 'Please Select Vaccination Dose';
      }
      if (this.showPreviousVaccination && element.material_dose != null && element.prev_vaccination_date == null) {
        // this.errorMessage = 'Please Select Vaccination Dose Date';
      }
    });
    if (!this.showPreviousVaccination && this.session?.sessionTypeName == 'UIP' && this.vaccinesEligible.length == 0) {
      this.util.presentToastError('No Vaccines are available');
      return
    }
    if (sessionTypeName == 'UIP' && selectedMaterials && selectedMaterials?.length == 0) {
      this.util.presentToastError('Please Select Vaccine Type');
      return
    }
    if (this.errorMessage != '' && selectedMaterials && selectedMaterials?.length > 0) {
      this.util.presentToastError(this.errorMessage);
      return
    }
    //  if(this.allStepsData?.session_id){
    //   this.vaccDetForm.get('session_id').setValue(this.allStepsData?.session_id);
    //  }
    if (!this.vaccDetForm.valid) {
      return;
    }

    if (this.isCheckingDate == true) {
      return;
    }
    let healthVillageName = this.healthVillagesDetails.find(ele=>{
      return this.vaccDetForm.get('health_village_id').value == ele.village_id
    })
    let LGDVillageName = this.villageDetails.find(ele=>{
      return this.vaccDetForm.get('village_id').value == ele.village_id
    })
    let healthWardName = this.healthWardDetails.find(ele=>{
      return this.vaccDetForm.get('health_ward_id').value == ele.add_ward_id
    })
    let wardName = this.wardDetails.find(ele=>{
      return this.vaccDetForm.get('ward_id').value == ele.ward_id
    })
    this.Inprogress = true;
    const benfPayload = {
      ...this.allStepsData,
      ...this.session,
      ... this.deviceInfo,
      ...{ isPastDated: this.showPreviousVaccination ? true : undefined },
      ...this.vaccDetForm.value,
      ...{health_village_name: healthVillageName ? healthVillageName.village_name :undefined},
      ...{village_name: LGDVillageName ? LGDVillageName.village_name :undefined},
      ...{health_ward_name: healthWardName ? healthWardName.add_ward_name :undefined},
      ...{ward_name: wardName ? wardName.ward_name :undefined}
    }
    if (this.allStepsData?.isBenfAddedToSession) {
      this.addRecipientService.benSuccessDetails = { ...benfPayload, ...{ isBenfAddedToSession: true } };
      this.gotoStep('next');
      return;
    }
    this.addRecipientService.addBenefToOngoingSessionCommonMethod(benfPayload).subscribe(res => {
      if (benfPayload.dose > 0) {
        benfPayload.vaccination_doses_due = benfPayload.dose + 1
      }

      this.addRecipientService.benSuccessDetails = { ...benfPayload, ...{ isBenfAddedToSession: true } };
      let sessionId = null;
      if (this.loc.path().match(/session\/[^\s]*/g)) {
        sessionId = this.loc.path().replace('/session/', '');
      }
      if (sessionId && this.allStepsData.session_id && this.allStepsData.session_id == sessionId) {
        this.sessionService.refreshSessionData$.next(true);
      }
      this.Inprogress = false;
      this.gotoStep('next');
    }, er => {
      this.Inprogress = false;
    })
    // this.addRecipientService.benSuccessDetails = benfPayload;//for the first time assigned to new observable
    // this.gotoStep('next');

  }

  showChkBoxOutsideIndia(session) {
    return this.vaccDetForm.controls.dose.value && session?.dose_scheduled == 2 && this.sessionService.isNewVaccine(session);
  }

  addPrevVaccination() {
    this.isMaterialDueDateAvailable = false;
    this.clearMaterialFormCtrlArr();
    this.showPreviousVaccination = true;
    this.loadingPrevVaccines = true;
    const params = {
      beneficiary_id: this.allStepsData.beneficiary_id,
    }
    this.addRecipientService.getUipMaterialDoseDetailsByAge(params)
      .subscribe(res => {
        this.loadingPrevVaccines = false;
        this.prevVaccinesByAge = this.vaccinatedDoseOfBenf(res);
        if (this.allStepsData?.is_pregnant_woman) {
          this.prevVaccinesByAge = this.validateTdBooster(this.prevVaccinesByAge);
          let index = this.prevVaccinesByAge.map((ele,index) =>{
            if(ele.material_id == environment.static_ids.TD_Booster){
              return index
            }
          }).filter(element => element >= 0);
          this.prevVaccinesByAge.splice(index[0],1);
        }
        this.cdrChange.detectChanges();
      }, e => {
        this.loadingPrevVaccines = false;
        //  this.cdrChange.detectChanges();

      })
  }

  clearMaterialFormCtrlArr() {
    const formArray = this.vaccDetForm.get('materialDetails') as FormArray
    while (formArray.length !== 0) {
      formArray.removeAt(0)
    }
  }

  showVaccineInfoByBenf(material) {
    if(this.isFullyVaccinatedByMaterial(material)){return false;}
    this.selectedMat = material;
    if (!material.selected) { material.selected = true }
    else { material.selected = false; }
    this.showDoseInfo = !this.showDoseInfo;
    // if(material.no_of_doses == 1){
    this.onMaterialCheckSelection(material, { checked: material.selected });
    // }
  }

  doseReceived(material, d,isPrecaution =false) {
      return this.uipVaccineByBenArr.find(m => {
        if(isPrecaution){
          return  m?.material_id == material?.material_id && m?.dose == d && m.is_precaution
        }
        return m?.material_id == material?.material_id && m?.dose == d
        
      });
  }

  isVaccineDue() {

  }

  doseDuration(material, dose: any, prev = false,isPrecaution=false) {
    const materialDoseInfo = this.allMaterailsData.find(e => e.material_id == material.material_id);
    //check if current dose is received
    //if received return
    // if not then check whether dose is due / pending
    // if due return date
    // else return pending
    if (this.doseReceived(materialDoseInfo, dose,isPrecaution)) {
      // if(material.no_of_doses > 1){this.onMaterialCheckSelection(material,{checked:true});}
      return 'Pending';
    }
    if(isPrecaution){
      let lastVaccData
      if (dose > 1) {
        lastVaccData = this.doseReceived(materialDoseInfo, (dose - 1),isPrecaution);
        }else{
              lastVaccData = this.doseReceived(materialDoseInfo,material.no_of_doses,false);
        }
        if (!lastVaccData) { return 'Upcoming'; }
        const dayDuration = materialDoseInfo.precaution_dose_frequencies[dose-1];
        let lastVaccinatedDate;let dayDiffOfLastVaccine;let nxtdoseDate;
        if(lastVaccData.vaccination_date){ 
          lastVaccinatedDate = moment(lastVaccData.vaccination_date).startOf('day');
          dayDiffOfLastVaccine = moment().diff(lastVaccinatedDate, 'days');
          nxtdoseDate = lastVaccinatedDate.add(dayDuration, 'days');
        }else{
          nxtdoseDate = this.getMinDoseDate(material, dose);  
        }
        if(!lastVaccData.vaccination_date){
          return moment().isSameOrAfter(nxtdoseDate) ? 'Pending' : 'Upcoming';
        }else if (dayDuration > dayDiffOfLastVaccine) {
          let daysLeftForVaccination = dayDuration - dayDiffOfLastVaccine;
          return 'Upcoming ' + moment(nxtdoseDate).format('LL');
        } else {
          // if(material.no_of_doses > 1){this.onMaterialCheckSelection(material,{checked:true});}
          return 'Pending';
        }
    }else{
    if (dose > 1) {
      const lastVaccData = this.doseReceived(materialDoseInfo, (dose - 1))
      if (!lastVaccData) { return 'Upcoming'; }
      let nextDose = dose;
      const dayDuration = materialDoseInfo.dose_frequencies[dose - 1];
      let lastVaccinatedDate;let dayDiffOfLastVaccine;let nxtdoseDate;
      if(lastVaccData.vaccination_date){ 
        lastVaccinatedDate = moment(lastVaccData.vaccination_date).startOf('day');
        dayDiffOfLastVaccine = moment().diff(lastVaccinatedDate, 'days');
        nxtdoseDate = lastVaccinatedDate.add(dayDuration, 'days');
      }else{
        nxtdoseDate = this.getMinDoseDate(material, dose)
      }
      if(!lastVaccData.vaccination_date){
        return moment().isSameOrAfter(nxtdoseDate) ? 'Pending' : 'Upcoming';
      }else if (dayDuration > dayDiffOfLastVaccine) {
        let daysLeftForVaccination = dayDuration - dayDiffOfLastVaccine;
        return 'Upcoming on ' + moment(nxtdoseDate).format('LL');
      } else {
        // if(material.no_of_doses > 1){this.onMaterialCheckSelection(material,{checked:true});}
        return 'Pending';
      }
    } else {
      // if(material.no_of_doses > 1){this.onMaterialCheckSelection(material,{checked:true});}
      return 'Pending';
    }
  }

  }
  isTDVaccineCompleted(ele) {
    const currentDate = moment()
    if (ele.material_id == environment.static_ids.TD_Booster || ele.material_id == environment.static_ids.TD_Vaccine) { // && this.verifiedData?.is_pregnant_woman
      const previousTdDate = moment(ele.vaccination_date);
      const diff = currentDate.diff(previousTdDate, 'years')
      // if(this.allStepsData.previous_pregnancy_year){
      //   const previousTdDate = moment(this.allStepsData.previous_pregnancy_year)
      //   const diff = currentDate.diff(previousTdDate, 'years')
      return diff <= 3
      // }
    }
    return true;
  }

  getAllUipVaccinesByBenf() {
    if (!this.allStepsData.beneficiary_reference_id) { return; }
    this.uipVaccineByBenArr = [];
    this.recipientService.getUipVaccineByBenRef({ beneficiary_reference_id: this.allStepsData.beneficiary_reference_id })
      .subscribe(r => {
        if (r) {
          this.uipVaccineByBenArr = r.filter(this.isTDVaccineCompleted);
          this.isPentavalentVaccineChecked = this.uipVaccineByBenArr.find(m=>m.material_id === environment.static_ids.Pentavalent_Vaccine) ? true : false;
          this.isDptVaccineChecked = this.uipVaccineByBenArr.find(m=>m.material_id === environment.static_ids.DPT_vaccine) ? true : false;
          // this.uipVaccineByBenArr = r;
          this.cdrChange.detectChanges();
        }
        this.getUIPMaterialsByBenf(this.allStepsData.session_id);
      }, e => { this.getUIPMaterialsByBenf(this.allStepsData.session_id); });
  }

  isVaccinatedForSingleDose(material) {
    if (material.no_of_doses > 1) { return false; }
    return this.doseReceived(material, 1);
  }

  vaccinatedDoseOfBenf(materials) {
    return materials.map(m => {
      m.checked = false;
      if (m.no_of_doses == 1) {
        m.is_single_dose_vaccinated = this.isVaccinatedForSingleDose(m);
        m.is_eligible = !m.is_single_dose_vaccinated;
      } else {
        m.is_eligible = false;
        m.dose_array = [];
        for (let i = 0; i < parseInt(m.no_of_doses); i++) {
          let isEligible = false;
          let doseReceivedDate = null;
          let due_date = null;
          let isVaccinatedOutside = false;
          let doseReceived = this.doseReceived(m, i + 1)
          if (!doseReceived) {
            let doseDuration = this.doseDuration(m, i + 1);
            m.is_eligible = !m.is_eligible ? doseDuration == 'Pending' : true;
            isEligible = doseDuration == 'Pending';
            due_date = doseDuration != 'Pending' ? doseDuration : null;
          } else {
            isVaccinatedOutside = doseReceived.is_past_dated;
            doseReceivedDate = doseReceived.vaccination_date;
          }
          m.dose_array.push({
            material_id: m.material_id,
            material_name: m.material_name,
            dose: i + 1, isEligible, due_date, doseReceivedDate, isVaccinatedOutside
          });
        }
        for (let i = 0; i < parseInt(m.no_of_precaution_doses); i++) {
          let isEligible = false;
          let doseReceivedDate = null;
          let isVaccinatedOutside = false;
          let due_date = null;
          let doseReceived = this.doseReceived(m, i + 1,m.no_of_precaution_doses >0);
          if (!doseReceived) {
            let doseDuration = this.doseDuration(m, i + 1,isVaccinatedOutside,m.no_of_precaution_doses >0);
            m.is_eligible = !m.is_eligible ? doseDuration == 'Pending' : true;
            isEligible = doseDuration == 'Pending';
            due_date = doseDuration != 'Pending' ? doseDuration : null;
          } else {
            isVaccinatedOutside = doseReceived.is_past_dated;
            doseReceivedDate = doseReceived.vaccination_date;
          }
          m.dose_array.push({
            material_id: m.material_id,
            material_name: m.material_name,
            dose: i + 1, isEligible, due_date, doseReceivedDate,isVaccinatedOutside,
            is_precaution:true
          });
        }

      }
      return m;
    })
  }
  labelName(doseArray){
    const materialDoseInfo = this.allMaterailsData.find(e => e.material_id == doseArray.material_id);
    if(doseArray.is_precaution){
      return materialDoseInfo.display_precaution_dose_names[doseArray.dose - 1];
    }
    return materialDoseInfo.display_dose_names[doseArray.dose - 1 ];
    
    // if(doseArray.is_precaution){
    //   if(doseArray.material_id == environment.static_ids.OPV_Vaccine ){
    //     return "OPV-Booster"+ ' '+`${doseArray.dose}`
    //   }
    //   else{
    //     return 'Booster Dose'+' '+`${doseArray.dose}`
    //   }
    // }
    // if(doseArray.material_id == environment.static_ids.OPV_Vaccine){
    //   return 'OPV' +' '+`${doseArray.dose -1}`
    // }else{
    //   return 'Dose'+' '+`${doseArray.dose}`
    // }
  }

  checkMaterialDoseIsEligible(material) {
    let materials = !this.showPreviousVaccination ? this.duplicateVaccinesEligible : this.prevVaccinesByAge;
    return this.showPreviousVaccination || materials.find(e => e.material_id == material.material_id && e.is_eligible);
  }


  onSelectByDose(material, dose, event) {
    const materialObj = this.vaccDetForm.get('materialDetails').value.find(element => {
      return element.material_id == material.material_id && ((element.material_dose == dose.dose && element.is_precaution == dose.is_precaution) || !element.material_dose);
    });
    const formArray: FormArray = this.vaccDetForm.get('materialDetails') as FormArray;
    if (event.checked) {
      if (!materialObj) {
        formArray.push(new FormControl({
          material_id: material.material_id, material_name: material.material_name,
          material_dose: dose.dose,
          is_precaution:dose.is_precaution,
          prev_vaccination_date: this.showPreviousVaccination ? null : undefined
        }));
      } else if (materialObj && !materialObj.material_dose) {
        formArray.controls.forEach((ctrl: FormControl) => {
          if (ctrl.value.material_id == material.material_id) {
            ctrl.value.material_dose = dose.dose;
            if(dose.is_precaution){
              ctrl.value.is_precaution = dose.is_precaution;
            }

          }
        });
      }
    } 
    else {
      let i: number = 0;
      const Index = formArray.controls.findIndex((ctrl: FormControl) => {
        // Remove the unselected element from the arrayForm
        if (ctrl.value.material_id == material.material_id && ctrl.value.material_dose == dose.dose) {
          if(dose.is_precaution && ctrl.value.is_precaution){
            // ctrl.value.is_precaution = undefined;
            // formArray.removeAt(i);
            return true;
            
          }
          else if(!dose.is_precaution && !ctrl.value.is_precaution){
            // formArray.removeAt(i);
            return true;
          }

          // material['checked'] = false;
          // return;
        }
        // i++;
      });
      if(Index!=-1){
        formArray.removeAt(Index);
        this.removeAllNxtDoses(material, dose, event);
      }
     
    }
  }


  isDoseChecked(material, dose) {
    return this.vaccDetForm.get('materialDetails').value.find(element => {
      // return element.material_id == material.material_id && element.material_dose == dose.dose;
      if (!dose.is_precaution){ 
        return  element.material_id == material.material_id && element.material_dose == dose.dose && !element.is_precaution; // && !element.is_precaution
        }else{
          return element.material_id == material.material_id && element.material_dose == dose.dose && element.is_precaution
        }  
    });
  }

  onSelectByDoseDate(material, dose, event) {
    this.vaccDetForm.get('materialDetails').value.forEach(element => {
      if (!dose.is_precaution && element.material_id == material.material_id && element.material_dose == dose.dose) {
        element['prev_vaccination_date'] = moment(event.value).format('YYYY-MM-DD');
      }
      if (dose.is_precaution && element.material_id == material.material_id && element.material_dose == dose.dose && element.is_precaution) {
        element['prev_vaccination_date'] = moment(event.value).format('YYYY-MM-DD');
      }
    });
  }

  removeAllNxtDoses(material, dose, event){
    let noOfDoses = dose.is_precaution ? material.no_of_precaution_doses : material.no_of_doses;
    let nxtDose = dose.dose+1;
    for(let i=nxtDose; i<=noOfDoses; i++){
      this.onSelectByDose(material, {...dose, dose: i}, event);
    }
    if(!dose.is_precaution && noOfDoses == dose.dose){
      let noOfDoses = material.no_of_precaution_doses;
      for(let i=1; i<=noOfDoses; i++){
        this.onSelectByDose(material, {...dose, dose: i, is_precaution: true}, event);
      } 
    }
  }

  isLastDoseDateAdded(material, dose) {
    let value;
    if (this.uipVaccineByBenArr && this.uipVaccineByBenArr.length > 0) {
      this.uipVaccineByBenArr.filter(ele => {
        if((dose.is_precaution && dose.dose >1) || (!dose.is_precaution && dose.dose)){
          if(ele.material_id == material.material_id && ele.dose == (dose.dose-1)){
            value = ele;
          }
        }else{
          if(ele.material_id == material.material_id && ele.dose == material.no_of_doses){
            value = ele;
          }
        }
  
      });
      if(value){return value;}
      else if(!value && this.showPreviousVaccination){ return this.isLastDoseAddedInFormArr(material, dose);}

      return value;
    }else {
      return this.isLastDoseAddedInFormArr(material, dose);
    }
  }

  isLastDoseAddedInFormArr(material, dose){
    return this.vaccDetForm.get('materialDetails').value.find(element => {
      if((!dose.is_precaution && dose.dose)){
        return element.material_id == material.material_id && element.material_dose == (dose.dose - 1)
        && (element.prev_vaccination_date || element.vaccination_date);
      }else if((dose.is_precaution && dose.dose >1)){
      return element.material_id == material.material_id && element.material_dose == (dose.dose - 1)
      && (element.prev_vaccination_date || element.vaccination_date) && element.is_precaution;
      }else if(dose.is_precaution){
        return element.material_id == material.material_id && element.material_dose == material.no_of_doses
      && (element.prev_vaccination_date || element.vaccination_date)
      }

    });
  }

  isLastDoseChecked(material, doseObj){
    if(doseObj.dose == 1 && !doseObj.is_precaution){ return false;}
    if(this.doseReceived(material, doseObj.dose - 1, doseObj.is_precaution) && (!doseObj.is_precaution || (doseObj.dose > 1 && doseObj.is_precaution))){ return false;}
    if(doseObj.is_precaution && this.doseReceived(material, doseObj.no_of_doses) && doseObj.dose == 1){ return false;}
    return !this.vaccDetForm.get('materialDetails').value.find(element => {
      
      if (!doseObj.is_precaution){ 
        const dose = doseObj.dose - 1;
        return  element.material_id == material.material_id && element.material_dose == dose 
        && !element.is_precaution; // && !element.is_precaution
      }else{
        const dose = doseObj.dose - 1;
        if(doseObj.dose == 1){
          return element.material_id == material.material_id && element.material_dose == material.no_of_doses 
          && !element.is_precaution;
        }else{ 
          return element.material_id == material.material_id && element.material_dose == dose 
          && element.is_precaution;
        }
      }
    });    
  }

  isFullyVaccinatedByMaterial(material) {
    if((material.material_id == environment.static_ids.DPT_vaccine && this.isPentavalentVaccineChecked)){
      return true;
    }
    if((material.material_id == environment.static_ids.Pentavalent_Vaccine && this.isDptVaccineChecked)){
      return true;
    }
    const currentDate = moment();
  if(!this.uipVaccineByBenArr.find(m => m?.material_id == material?.material_id && m?.dose == material.no_of_doses)){
    if (this.allStepsData.previous_pregnancy_year && this.allStepsData.is_pregnant_woman) {
      if (material.material_id == environment.static_ids.TD_Booster) {
        const arrVacc = !this.showPreviousVaccination ? this.duplicateVaccinesEligible : this.prevVaccinesByAge;
        const previousTdDate = moment(this.allStepsData.previous_pregnancy_year, 'YYYY')
        const diff = currentDate.diff(previousTdDate, 'years')
        if (diff <= 3) {
          // if (arrVacc.find(ele => ele.material_id == environment.static_ids.TD_Vaccine && ele.dose_array.find(e => e.dose == 2 && e.doseReceivedDate))) {
          return false;
        }
        return true;
      } if (material.material_id == environment.static_ids.TD_Vaccine) {
        const arrVacc = !this.showPreviousVaccination ? this.duplicateVaccinesEligible : this.prevVaccinesByAge;
        const previousTdDate = moment(this.allStepsData.previous_pregnancy_year, 'YYYY')
        const diff = currentDate.diff(previousTdDate, 'years')
        if (diff <= 3) {
          // if (arrVacc.find(ele => ele.material_id == environment.static_ids.TD_Vaccine && ele.dose_array.find(e => e.dose == 2 && e.doseReceivedDate))) {
          return true;
        }
        return false;
      }
    } else if (!this.allStepsData.previous_pregnancy_year && this.allStepsData.is_pregnant_woman) {
      if (material.material_id == environment.static_ids.TD_Vaccine) {
        return false
      }
      if (material.material_id == environment.static_ids.TD_Booster) {
        return true
      }
    }
  }
    else {
      return this.uipVaccineByBenArr.find(m => {
        if (material.no_of_precaution_doses) {
          return m?.material_id == material?.material_id && m?.dose == material.no_of_precaution_doses && m.is_precaution
        }
       else if (m?.material_id == material?.material_id && m?.dose == material.no_of_doses) {
          return true;
        }
      });

      // return this.uipVaccineByBenArr.find(m => m?.material_id == material?.material_id && m?.dose == material.no_of_doses);
    }

  }

  validateTdBooster(materials) {
    const currentDate = moment()
    return materials.map(m => {
      if (this.allStepsData.previous_pregnancy_year && this.allStepsData.is_pregnant_woman) {
        const arrVacc = !this.showPreviousVaccination ? this.duplicateVaccinesEligible : this.prevVaccinesByAge;
        // m.is_single_dose_vaccinated = arrVacc.find(ele => ele.material_id == environment.static_ids.TD_Vaccine && ele.dose_array.find(e => e.dose == 2 && e.doseReceivedDate)) ? false : true;
        const previousTdDate = moment(this.allStepsData.previous_pregnancy_year)
        const diff = currentDate.diff(previousTdDate, 'years')
        m.is_single_dose_vaccinated = diff <= 3
        if (m.material_id == environment.static_ids.TD_Booster) {
          // m.is_eligible = !m.is_single_dose_vaccinated;
          m.is_eligible = m.is_single_dose_vaccinated;
        }
        if (m.material_id == environment.static_ids.TD_Vaccine) {
          m.is_eligible = !m.is_single_dose_vaccinated;
        }
      }
      else if (!this.allStepsData.previous_pregnancy_year && this.allStepsData.is_pregnant_woman) {
        if (m.material_id == environment.static_ids.TD_Vaccine) {
          m.is_eligible = true;
        }
        if (m.material_id == environment.static_ids.TD_Booster) {
          m.is_eligible = false;
        }
      }
      return m;
    });
  }
  getMaxDoseDate(material, dose){
    // let currentDate = moment();
    let currentDate = moment(this.allStepsData.date_of_session).subtract('days',1);
    const materialDoseInfo = this.allMaterailsData.find(e => e.material_id == material.material_id);
    if(dose == 0){
      let agelimit = materialDoseInfo.display_upper_age_limits[0];
      let maxDate = moment(this.minDoseDate).add(materialDoseInfo.upper_age_limits[0],'days');
      if(maxDate <=currentDate || !agelimit || agelimit == 'nullD'){
        return maxDate
      }else{
        return this.maxDoseDate;
      }
      // return moment(this.minDoseDate).add(materialDoseInfo.upper_age_limits[0],'days').format('YYYY-MM-DD');
    }
    if(dose.is_precaution){
      let agelimit = materialDoseInfo.display_upper_age_limits[dose.dose - 1];
      const maxAgeInDays = materialDoseInfo.precaution_upper_age_limits[dose.dose - 1];
      if(maxAgeInDays == null || !agelimit || agelimit == 'nullD'){
        return this.maxDoseDate;
      }else{
        let maxDate = moment(this.allStepsData.dob).add(maxAgeInDays,'days');
        if(maxDate <=currentDate){
          return maxDate
        }else{
          return this.maxDoseDate;
        }
      }
    }else{
      let agelimit = materialDoseInfo.display_upper_age_limits[dose.dose - 1];
      const maxAgeInDays = materialDoseInfo.upper_age_limits[dose.dose - 1];
      if(maxAgeInDays == null || !agelimit || agelimit == 'nullD'){
        return this.maxDoseDate;
      }else{
        let maxDate = moment(this.allStepsData.dob).add(maxAgeInDays,'days');
        if(maxDate <=currentDate){
          return maxDate
        }else{
          return this.maxDoseDate;
        }
      }
    }
  }

  isDoseEligibleByLastDose(material, dose){
    return !moment().isSameOrAfter(this.getMinDoseDate(material, dose))
  }


  getMinDoseDate(material, dose) {
    const materialDoseInfo = this.allMaterailsData.find(e => e.material_id == material.material_id);
    let minDoseDate = moment(this.minDoseDate).format('YYYY-MM-DD');
    if (this.allStepsData.ben_type_id == environment.static_ids.PregnantWomen || (!this.allStepsData.lmc_date && !this.allStepsData.is_pregnant_woman) && !dose.is_precaution && dose.dose == 1){
      minDoseDate = moment(this.minDoseDate).add(materialDoseInfo.min_age, 'days').format('YYYY-MM-DD');
    // }
    // if(!dose.is_precaution && dose.dose == 1) {
      return minDoseDate;
    }
    const lastDoseData = this.isLastDoseDateAdded(material, dose);
    let lastDoseDate = null;
    if(lastDoseData){
      lastDoseDate = lastDoseData.vaccination_date ? lastDoseData.vaccination_date : lastDoseData.prev_vaccination_date; 
    }
    if(lastDoseData && lastDoseDate){
      const lastDoseDate = lastDoseData.vaccination_date ? lastDoseData.vaccination_date : lastDoseData.prev_vaccination_date
      // const dayDuration = materialDoseInfo.dose_frequencies[dose - 1];
      let dayDuration; let lowerAgeDuration; 
      if(dose.is_precaution){
        dayDuration = materialDoseInfo.precaution_dose_frequencies[dose.dose -1];
        lowerAgeDuration = materialDoseInfo.precaution_lower_age_limits[dose.dose -1];
      }else{
        dayDuration = materialDoseInfo.dose_frequencies[dose.dose - 1];
        lowerAgeDuration = materialDoseInfo.lower_age_limits[dose.dose -1];
      }
      const lastVaccinatedDate = moment(lastDoseDate).startOf('day');
      const mData = lastVaccinatedDate.add(dayDuration, 'days');
      const lowerAgeLimitOfDose = moment(this.minDoseDate).add(lowerAgeDuration, 'days');
      if(lowerAgeLimitOfDose.isBefore(mData)){
        return mData.format('YYYY-MM-DD');
      }else{
        return lowerAgeLimitOfDose.format('YYYY-MM-DD');
      }

    } else {
      var sum = 0;
      // Running the for loop
      // for (let i = 0; i < materialDoseInfo.dose_frequencies.length; i++) {
      //   if (dose.dose > i) {
      //     sum += parseInt(materialDoseInfo.dose_frequencies[i]);
      //   }
      // }
      sum = materialDoseInfo.lower_age_limits[dose.dose -1];

      if(dose.is_precaution){
      //   let totalDosesValue =0;
      //   materialDoseInfo.dose_frequencies.forEach(element => {
      //     totalDosesValue = totalDosesValue+element
      //   });
      // for (let i = 0; i < materialDoseInfo?.precaution_dose_frequencies?.length; i++) {
      //   if(dose.dose > i){
      //     sum += materialDoseInfo.precaution_dose_frequencies[i]+totalDosesValue;
      //     console.log(sum,"test")
      //   }
      // }
      sum = materialDoseInfo.precaution_lower_age_limits[dose.dose -1];
      }

      const lastVaccinatedDate = moment(minDoseDate).startOf('day');
      return lastVaccinatedDate.add(sum, 'days').format('YYYY-MM-DD');
    }
  }
  onTabChange(event){
    if(event == '1'){
      this.addPrevVaccination();
    }else{
      this.loadCurrentVaccines();
    }    
  }


}

