import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';

import { SharedModule } from 'src/app/modules/shared/shared.module';
import { VerificationComponent } from './components/verification/verification.component';
import { RemoteInterfaceModule } from '../remote-interface/remote-interface.module';
import { AadharVerificationModule } from '../aadhar-verification/aadhar-verification.module';
import { VerifyVaccinateStepperComponent } from './components/verify-vaccinate-stepper/verify-vaccinate-stepper.component';
import { VaccinateStepperComponent } from './components/vaccinate-stepper/vaccinate-stepper.component';
import { VaccinateMemberComponent } from '../../screens/recipient/components/vaccinate-member/vaccinate-member.component';
import {VaccinatedRejectedStatusComponent} from '../recipient/components/vaccinated-rejected-status/vaccinated-rejected-status.component';
import { AddRecipinentPage } from './add-recipient.page';
import { AddRecipinentPageRoutingModule } from './add-recipient-routing.module';
import { MemberCategoryInfoComponent } from './components/member-category-info/member-category-info.component';
import { IdentificationComponent } from './components/identification/identification.component';
import { PersonalDetailsComponent } from './components/personal-details/personal-details.component';
import { VaccinationInfoComponent } from './components/vaccination-info/vaccination-info.component';
import { VerifiedSuccessComponent } from './components/verified-success/verified-success.component';
import { MemberPersonalDetailsPageComponent } from './pages/member-personal-details/member-personal-details.page';
import { MemberIdentificationPageComponent } from './pages/member-identification/member-identification.page';
import { MemberVerifiedPageComponent } from './pages/member-verified/member-verified.page';
import { MemberVaccinationInfoPageComponent } from './pages/member-vaccination-info/member-vaccination-info.page';
import { VaccinationRejectedPageComponent } from './pages/vaccination-rejected/vaccination-rejected.page';
import { MemberCategoryInfoPageComponent } from './pages/member-category-info/member-category-info.page';
import { BasicSummaryComponent } from './components/basic-summary/basic-summary.component';
import { VaccinateMemberPageComponent } from './pages/vaccinate-member/vaccinate-member.page';
import {SelectMultiVaccinationUipComponent} from './components/select-multi-vaccination-uip/select-multi-vaccination-uip.component'

const exportComponents = [
  VerificationComponent,
  VaccinateMemberComponent,
  VaccinatedRejectedStatusComponent,
  VerifyVaccinateStepperComponent,
  VaccinateStepperComponent,

  MemberCategoryInfoComponent,
  IdentificationComponent,
  PersonalDetailsComponent,
  VaccinationInfoComponent,
  VerifiedSuccessComponent,
  BasicSummaryComponent,
  SelectMultiVaccinationUipComponent,
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        SharedModule,
        RemoteInterfaceModule,
        AadharVerificationModule,
        AddRecipinentPageRoutingModule,
    ],
    declarations: [
        AddRecipinentPage,
        MemberPersonalDetailsPageComponent,
        MemberIdentificationPageComponent,
        MemberVerifiedPageComponent,
        MemberVaccinationInfoPageComponent,
        VaccinateMemberPageComponent,
        VaccinationRejectedPageComponent,
        MemberCategoryInfoPageComponent,
        ...exportComponents,
    ],
    exports: exportComponents
})
export class AddRecipientPageModule { }
