import { Injectable } from '@angular/core';
import { interval, Observable, of, ReplaySubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { EncDecService } from 'src/app/modules/shared/services/encryption-decryption.service';
import { DataService } from './data.service';
@Injectable({
  providedIn: 'root'
})
export class LookUpService {
  genderObservable$: Observable<any>;
  yobObservable$: Observable<any>;
  dobObservable$: Observable<any>;
  mobObservable$: Observable<any>;
  materialObservable$: Observable<any>;
  idProofTypes$: Observable<any>;
  pregnancyDetailsMasterData$: Observable<any>;
  coordinates = new ReplaySubject(1);
  constructor(private http: HttpClient, private encDecService: EncDecService, private dataService: DataService) { }
  public userDetails = this.dataService.userDetails;
  getGender(): Observable<any> {
    if (window.navigator.onLine) {
      if (!this.genderObservable$) {
        this.genderObservable$ = this.http.get(
          `${environment.beneficiary_vaccinator_v3_url}/getGender`)
          .pipe(
            map(res => res),
            publishReplay(1),
            refCount(),
            catchError(err => {
              throw new Error('Error Occurred while fetching data');
            })
          );

        interval(8 * 60 * 60 * 1000).subscribe(x => {
          this.genderObservable$ = null;
        });
      }
      return this.genderObservable$;
    } else {
      of(null);
    }
  }

  getDob(): Observable<any> {
    if (window.navigator.onLine) {
      if (!this.dobObservable$) {
        this.dobObservable$ = this.http.get(`${environment.beneficiary_registration_prefix_url}/beneficiary/getDob`)
          .pipe(
            map(res => res),
            publishReplay(1),
            refCount(),
            catchError(err => {
              throw new Error('Error Occurred while fetching data');
            })
          );
        interval(8 * 60 * 60 * 1000).subscribe(x => {
          this.dobObservable$ = null;
        });
      }
      return this.dobObservable$;
    }
  }

  getMob(): Observable<any> {
    if (window.navigator.onLine) {
      if (!this.mobObservable$) {
        this.mobObservable$ = this.http.get(`${environment.beneficiary_vaccinator_v3_url}/getMob`)
          .pipe(
            map(res => res),
            publishReplay(1),
            refCount(),
            catchError(err => {
              throw new Error('Error Occurred while fetching data');
            })
          );
        interval(8 * 60 * 60 * 1000).subscribe(x => {
          this.mobObservable$ = null;
        });
      }
      return this.mobObservable$;
    }
  }

  getYob(): Observable<any> {
    if (window.navigator.onLine) {
      if (!this.yobObservable$) {
        this.yobObservable$ = this.http.get(`${environment.beneficiary_vaccinator_v3_url}/getYob`)
          .pipe(
            map(res => res),
            publishReplay(1),
            refCount(),
            catchError(err => {
              throw new Error('Error Occurred while fetching data');
            })
          );
        interval(8 * 60 * 60 * 1000).subscribe(x => {
          this.yobObservable$ = null;
        });
      }
      return this.yobObservable$;
    }
  }

  // Added this service here because of code clean. Have to migrate this service after code cleanup.
  getSiteVillages(): Observable<any> {
    if (!this.dataService.userDetails?.session_site_id) {
      return of([]);
    }
    const payload = {
       session_site_id: this.encDecService.setWithSpace(this.dataService.userDetails?.session_site_id)
     // session_site_id:1548

    }
    return this.http.post(`${environment.vaccinator_url}/getVillageDetailsBySiteId`, payload).pipe(map(res =>{
      Object.values(res).map(e => e.forEach(el=>{
        if(el.add_ward_id){
            el.add_ward_id = this.encDecService.get(el.add_ward_id)
        }
        if(el.ward_id){
            el.ward_id = this.encDecService.get(el.ward_id)
        }
        if(el.village_id){
            el.village_id = this.encDecService.get(el.village_id)
        }
    })
    )
    return res
    }));
  }

  getVillagebyAshaid(user_id):Observable<any> {
    const payload = {
      user_id: this.encDecService.setWithSpace(user_id)
    }
    return this.http.post(`${environment.vaccinator_url}/getVillageByAshaId`, payload).pipe(map((b:any)=>{
      b.map(data=>{
        this.encDecService.decryptLocationId(data);
        if(data.village_id){
          data.village_id = this.encDecService.get(data.village_id)
        }
        if(data.ward_id){
          data.ward_id = this.encDecService.get(data.ward_id)
        }
        if(data.session_site_id){
          data.session_site_id = this.encDecService.get(data.session_site_id)
        }
      });
      return b;
    }
    ));
  }

  getVillageAndHealthVillagebyAshaid(user_id):Observable<any> {
    const payload = {
      user_id: user_id
    }
    return this.http.post(`${environment.vaccinator_url}/getVillageAndHealthVillagebyAshaid`, payload);
  }

  getMaterials(): Observable<any> {
    if (window.navigator.onLine) {
      if (!this.materialObservable$) {
        this.materialObservable$ = this.http.get(`${environment.vaccinator_url}/material/getAllMaterialUIP`)
          .pipe(
            map(res => res),
            publishReplay(1),
            refCount(),
            catchError(err => {
              throw new Error('Error Occurred while fetching data');
            })
          );
        interval(10 * 1000).subscribe(x => {
          this.materialObservable$ = null;
        });
      }
      return this.materialObservable$;
    }
  }

  getMaterialsByType(materialArr, materialType) {
    if (materialType == 'Others') {
      return materialArr.filter(e => e.material_category == 'Others');
    } else if (materialType == 'UIP' || materialType == 'Universal Immunization Programme') {
      return materialArr.filter(e => e.material_category == 'Universal Immunization Programme');
    }
    else {
      return materialArr.filter(e => e.material_category == 'COVID-19');
    }

  }

  getIdProofTypes(): Observable<any> {
    if (window.navigator.onLine) {
      if (!this.idProofTypes$) {
        this.idProofTypes$ = this.http.get(`${environment.beneficiary_vaccinator_v3_url}/getBeneficiaryIdType`)
          .pipe(
            map(res => res),
            publishReplay(1),
            refCount(),
            catchError(err => {
              throw new Error('Error Occurred while fetching data');
            })
          );
        interval(8 * 60 * 60 * 1000).subscribe(x => {
          this.idProofTypes$ = null;
        });
      }
      return this.idProofTypes$;
    }
  }
  getStateEligibilityTypes() {
    return this.http.get(`${environment.beneficiary_vaccinator_v3_url}/getStateEligibilityTypes`);
  }
  getPregnancyMasterData(): Observable<any> {
    if (window.navigator.onLine) {
      if (!this.pregnancyDetailsMasterData$) {
        this.pregnancyDetailsMasterData$ = this.http.get(`${environment.vaccinator_url}/pregnancyMasters`)
          .pipe(
            map(res => res),
            publishReplay(1),
            refCount(),
            catchError(err => {
              throw new Error('Error Occurred while fetching data');
            })
          );
        interval(10 * 1000).subscribe(x => {
          this.pregnancyDetailsMasterData$ = null;
        });
      }
      return this.pregnancyDetailsMasterData$;
    }
  }
  getVillageIdsByUserId(){
    const payload={
      user_id: this.encDecService?.get(this.dataService.userDetails?.user_id)
    }
    return this.http.post(`${environment.vaccinator_url}/getVillageIdsByUserId`, payload);
  }
   getBirthDosevaccines(){
    return this.http.get(`${environment.vaccinator_url}/birthDoseVaccines`)
  }
}
