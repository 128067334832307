import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'age'
})
export class AgeFormatPipe implements PipeTransform {
  transform(value: any, format: string = null): string {
    if (format && format == 'Y') {
      value = new Date(moment(value, 'YYYY').format());
    }
    const birthdate = moment(value);
    if (birthdate.isValid()) {
      const today = moment();
      const years = today.diff(birthdate, 'years');
      if (years < 5) {
        const months = today.subtract(years, 'years').diff(birthdate, 'months');
        const days = today.subtract(months, 'months').diff(birthdate, 'days');
        let ageString = '';
        if (years > 0) {
          ageString += years + 'y' + ' ';
        }
        if (months > 0) {
          ageString += months + 'm' + ' ';
        }
        if (days >= 0) {
          ageString += days + 'd' + ' ';
        }
        return ageString.trim();
      } else {
        return years + ' yr ';
      }
    }
  }
}
