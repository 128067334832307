import { Component, OnInit, ViewChild, Input, OnChanges, SimpleChanges, AfterViewInit, Output, EventEmitter, ChangeDetectorRef, OnDestroy, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { DataService } from 'src/app/modules/shared/services';
import { UtilsService } from 'src/app/modules/shared/services/utils.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ROUTE_ANIMATIONS_ELEMENTS } from 'src/app/modules/core/services/route.animations';
import { Device } from '@capacitor/device';
import { AddRecipientService } from '../../services/add-recipient.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AadharService } from 'src/app/screens/aadhar-verification/services/aadhar.service';
import { LookUpService } from 'src/app/modules/shared/services/look-up.service';
import { RecipientService } from 'src/app/screens/recipient/services/recipient.service';
import { switchMap } from 'rxjs/operators';
import { GeoLocationService } from 'src/app/modules/shared/services/geo-location.service';
import { Location } from '@angular/common';



@Component({
  selector: 'app-add-recipient-member-personal-details',
  templateUrl: './member-personal-details.page.html',
  styleUrls: ['./member-personal-details.page.scss'],
})
export class MemberPersonalDetailsPageComponent implements OnInit, OnDestroy {
  benfData:any={};
  benId;
  benSuccessSubscription: Subscription;
  sessionId;
  constructor(
    public dataService: DataService,
    private router: Router,
    private loc: Location,
    private addRecipientService: AddRecipientService,
    public util: UtilsService,
    public actRoute:ActivatedRoute,

  ) {
    this.benId = this.actRoute.snapshot.params.benID
    this.sessionId = this.actRoute.snapshot.params.sessionID;
  
  }

  ngOnInit(): void {
    this.benSuccessSubscription = this.addRecipientService.benSuccessData.subscribe(res=>{
      if(res){ 
        this.benId = res['beneficiary_id'];
        this.sessionId = res['session_id'];
        this.benfData =  res;
      }
    })
    const stateData: any = this.loc.getState();
    if(stateData && stateData.data){
      this.addRecipientService.beneficiaryDetails = stateData.data;
      this.benfData = stateData.data;
    }
    if(stateData.navigationId == 1){
      this.router.navigate([`/member-identification/${this.benId}/${this.sessionId}`]);
    }
  }

  onStepChange(val: any) {
    if (val === 'next') {
      if(this.benId && this.sessionId){
        this.router.navigate([`/member-verified/${this.benId}/${this.sessionId}`]);
      }else{
        this.router.navigate(['/new-member/member-verified']);

      }
    } else {
      this.loc.back();
    }
  }

  closeDialog() {
  }

  ngOnDestroy(){
    this.benSuccessSubscription.unsubscribe();
  }

}
