import { FormGroup, Validators } from '@angular/forms';
import {
  Component,
  Input,
  OnInit,
  AfterContentChecked,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { DataService } from 'src/app/modules/shared/services/data.service';
import { LocationService } from 'src/app/modules/shared/services/location.service';
import { UtilsService } from '../../services/utils.service';
import * as moment from 'moment';
import { LookUpService } from '../../services/look-up.service';
import { ImageUploadService } from '../../services/image-upload.service';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, filter, takeUntil } from 'rxjs';
@Component({
  selector: 'app-beneficiary-category-fields',
  templateUrl: './beneficiary-category-fields.component.html',
  styleUrls: ['./beneficiary-category-fields.component.scss'],
})
export class BeneficiaryCategoryFieldsComponent implements OnInit, OnChanges {
  @Input() yearOfBirth: any = null;
  @Input() keyFromNewMember: any = null;
  @Input() parentForm: FormGroup;
  @Input() sessionDetails: any = null;
  @Input() isFormSubmitted: boolean = false;
  @Input() isPrgSession:boolean = false;
  @Output() onSelect = new EventEmitter();
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  benCategories: any = [];
  beneficiaryTypes: any = [];
  filteredBeneficiaryTypes: any = [];
  showStateEligible: boolean = false;
  stateEligibletypes: any = [];
  isSelectedOthers: boolean = false;
  isFileUpload: boolean = false;
  categoryType: string = null;
  selectedCategory: any = null;
  typeOfCategory: boolean = false;
  uploadedEmploymentFileDetails: any = {};
  fileTxt: string = null;
  currentUrl: any;
  currentDate = moment();
  formattedDob
  destroy$ = new Subject();

  constructor(
    private dataService: DataService,
    private lookup: LookUpService,
    private imageUploadService: ImageUploadService,
    private cdr: ChangeDetectorRef,
    private util: UtilsService,
    private platform: Platform,
    private route: Router
  ) {}

  async ngOnInit() {
    this.currentUrl = this.route.url;
    
      this.parentForm
      .get('ben_type_id')
      .valueChanges.pipe(
        takeUntil(this.destroy$),
      )
      .subscribe((r) => {
        if (r == '19' || r == '20') {
          this.parentForm?.get('gender').disable();
          this.parentForm?.get('gender').setValue('Female');
          this.cdr.detectChanges();
        } else {
          this.parentForm?.get('gender').enable();
          this.cdr.detectChanges();
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(!changes?.yearOfBirth){return;}
    const dobMoment = moment(changes?.yearOfBirth?.currentValue, 'YYYY-MM-DD');
    const currentDate = moment();
    const ageInYears = currentDate.diff(dobMoment, 'years');

    if (ageInYears > 6 || ageInYears < 10) {
      this.filteredBeneficiaryTypes = [];
    }
    if(changes?.isPrgSession?.currentValue){
      this.benificaryChange(3);
       this.filteredBeneficiaryTypes = [{ben_type: 'Pregnant Woman', ben_type_id: 19}];
    }else{
      this.filteredBeneficiaryTypes = [];
    }
    console.log('changes?.yearOfBirth && ageInYears', changes?.yearOfBirth ,'&&', ageInYears)
    if ((changes?.yearOfBirth && ageInYears <= 6) || ageInYears >= 10) {
      if (changes?.yearOfBirth?.currentValue?.toString()?.length == 4) {
        this.getBeneficiaryTypeByYob(changes.yearOfBirth.currentValue);
      } else if (this.keyFromNewMember == 'categoryFields') {
        this.getBeneficiaryTypeByYob(changes.yearOfBirth.currentValue);
      }
    }
    // Adolacent check
    const tenYearsAgo = moment().subtract(10, 'years');
    const seventeenYearsAgo = moment().subtract(17, 'years');

    if (dobMoment.isBefore(tenYearsAgo) || dobMoment.isAfter(seventeenYearsAgo)) {
      this.parentForm.get('ben_type_id').reset()
    }
  }

  resetFile() {
    this.fileInput.nativeElement.value = null;
  }

  getBeneficiaryTypeByYob(value) {
    let DOB;
    const currentYear = new Date().getFullYear();
    if (!value || value > currentYear) {
      return;
    }
    if (this.keyFromNewMember == 'categoryFields') {
      if(value.toString().length == 4) {
      DOB = moment(`${value}`, 'YYYY').format('YYYY-MM-DD');
      } else {
        DOB = moment(value).format('YYYY-MM-DD');
      }
    } else {
      DOB = moment(new Date(value, 0, 1)).format('YYYY-MM-DD');
    }
    this.benCategories = [];
    this.parentForm.get('beneficiary_type_category_id').reset();
    if (!this.dataService?.registrationData) {
      this.parentForm.get('ben_type_id').reset();
    }

    this.dataService
      .getBeneficieryTypeBasedOnAge({ dob: DOB })
      .subscribe((res) => {
        this.beneficiaryTypes = res;

        if (this.dataService.loggedUserSessionType === 1) {
          this.beneficiaryTypes.forEach((element) => {
            // Separating Catgeroies to another array
            let index = this.benCategories.findIndex(
              (x) => x.category_id === element.category_id
            );
            if (index == -1) {
              this.benCategories.push({
                category_id: element.category_id,
                category_name: element.category_name,
              });
            }
          });
        } else {
          let types = this.beneficiaryTypes.filter((x) => x.category_id == 3);
          types.forEach((ele) => {
            let index = this.benCategories.findIndex(
              (x) => x.category_id === ele.category_id
            );
            if (index == -1) {
              this.benCategories.push({
                category_id: ele.category_id,
                category_name: ele.category_name,
              });
            }
          });
        }

        if (this.sessionDetails && this.sessionDetails.session_type_id == 5) {
          this.benCategories = this.benCategories.filter(
            (e) => e.category_id == 1 || e.category_id == 2
          );
        }

        if (
          this.sessionDetails &&
          this.sessionDetails.session_type_name == 'UIP'
        ) {
          this.benCategories = this.benCategories.filter(
            (e) => e.category_id == 3
          );
        }
        this.parentForm.get('beneficiary_type_category_id').setValue(3);
        this.parentForm
          .get('beneficiary_type_category_id')
          .updateValueAndValidity();
        this.parentForm.get('beneficiary_type_category_id').disable();
        this.benificaryChange(3);
      });
  }

  benificaryChange(event?) {
    this.showStateEligible = false;
    if (!this.dataService?.registrationData) {
      this.parentForm.get('ben_type_id').reset();
    }
    this.parentForm.get('ben_type_id').setErrors(null);
    this.parentForm.get('ben_type_id').updateValueAndValidity();
    this.isSelectedOthers = false;
    this.parentForm.get('beneficiary_type_name_other')?.reset();
    this.parentForm.get('state_eligibility_type_id')?.reset();
    this.parentForm.get('state_eligibility_type_id')?.setErrors(null);
    this.filteredBeneficiaryTypes = [];
    if (event == 1 || event == 2) {
      this.isFileUpload = true;
      this.categoryType = 'FLW/HCW';
      this.fileTxt = 'Upload Employment Certificate';
      this.parentForm
        .get('employment_certificate')
        .setValidators([Validators.required]);
      this.parentForm.get('employment_certificate').updateValueAndValidity();
    } else {
      this.isFileUpload = false;
      this.categoryType = '';
      this.fileTxt = '';
      this.parentForm.get('employment_certificate')?.clearValidators();
      this.parentForm.get('employment_certificate')?.updateValueAndValidity();
    }
    this.beneficiaryTypes.filter((ele) => {
      if (event === ele.category_id) {
        this.selectedCategory = ele.category_name;
        this.filteredBeneficiaryTypes.push({
          ben_type: ele.ben_type == 'Citizen' ? 'Citizen/Guardian': ele.ben_type,
          ben_type_id: ele.id,
        });
      }
      // if (
      //   this.dataService.userDetails.role == 'Vaccinator' ||
      //   this.dataService.userDetails.role == 'ANM' ||
      //   this.dataService.userDetails.role == environment.static_ids.DeliveryManagerKey || 
      //   this.dataService.userDetails.role == environment.static_ids.BothplanningUnitAndDelivery || 
      //   this.dataService.userDetails.role ==
      //     'Both(Vaccinator & Delivery Manager)' ||
      //   this.dataService.userDetails.role == 'ASHA'
      // ) {
        this.filteredBeneficiaryTypes = this.filteredBeneficiaryTypes.filter(
          (e) => e.ben_type_id != 18
        );
      // }
    });
    if (event === 2) {
      let index = this.beneficiaryTypes.findIndex((x) => x.ben_type_id == 5);
      if (index === -1) {
        this.filteredBeneficiaryTypes.push({
          ben_type: 'Others',
          ben_type_id: 1001,
        });
      }
    }
    // if (this.sessionDetails && this.sessionDetails.session_type_name == 'UIP') {
    //   this.filteredBeneficiaryTypes = this.filteredBeneficiaryTypes.filter(e => e.ben_type_id != 17 && e.ben_type_id != 18);
    // }
    if (
      this.dataService.userDetails.role ==
        environment.static_ids.DeliveryManagerKey ||
      this.dataService.userDetails.role ==
        environment.static_ids.BothplanningUnitAndDelivery ||
      environment.static_ids.SESSION_PREGNANT_CATEGORY.includes(
        this.sessionDetails?.uip_session_category
      )
    ) {
      if(this.filteredBeneficiaryTypes.find(f=>f.ben_type_id == 19)){ 
        this.parentForm.get('ben_type_id').setValue(19);
        this.parentForm.get('beneficiary_type_name').setValue("Pregnant Woman");
        this.parentForm.get('ben_type_id').disable();
        this.parentForm.get('beneficiary_type_name').disable();
      }else{
        this.parentForm.get('ben_type_id').enable();
        this.parentForm.get('beneficiary_type_name').enable();
      }
    }
    this.parentForm
      .get('beneficiary_type_category_name')
      .setValue(this.selectedCategory);
    if (this.filteredBeneficiaryTypes.length == 1) {
      this.parentForm
        .get('ben_type_id')
        .setValue(this.filteredBeneficiaryTypes[0].ben_type_id);
    }
    this.typeOfCategory = true;
    if (event) {
      if (this.filteredBeneficiaryTypes.length == 1) {
        this.parentForm
          .get('ben_type_id')
          .patchValue(this.filteredBeneficiaryTypes[0].ben_type_id);
        this.parentForm.get('ben_type_id').updateValueAndValidity();
        this.cdr.detectChanges();
        this.beneficiaryTypes.find((ele) => {
          if (this.filteredBeneficiaryTypes[0].ben_type_id == ele.id) {
            this.parentForm.get('beneficiary_type_name').setValue(ele.ben_type);
          }
        });
      }
    }
  }

async benTypeCategoryChange(event) {
    this.parentForm.get('beneficiary_type_name_other')?.reset();
    if (event == 1001) {
      this.parentForm
        .get('beneficiary_type_name_other')
        .setValidators([Validators.required]);
      this.isSelectedOthers = true;
    } else {
      this.parentForm.get('beneficiary_type_name_other')?.clearValidators();
      this.parentForm
        .get('beneficiary_type_name_other')
        ?.updateValueAndValidity();
      this.isSelectedOthers = false;
    }
    if (event == 18) {
      this.isFileUpload = true;
      this.stateEligibletypes = await this.lookup
      .getStateEligibilityTypes()
      .toPromise();

      this.showStateEligible = true;
      this.fileTxt = 'Upload Document (as per state mandatory requirements) ';
      this.parentForm
        .get('employment_certificate')
        .setValidators([Validators.required]);
      this.parentForm
        .get('state_eligibility_type_id')
        .setValidators([Validators.required]);
    } else {
      this.isFileUpload = false;
      this.showStateEligible = false;
      this.fileTxt = '';
      this.parentForm.get('employment_certificate')?.clearValidators();
      this.parentForm.get('employment_certificate')?.updateValueAndValidity();
      this.parentForm.get('state_eligibility_type_id')?.clearValidators();
      this.parentForm
        .get('state_eligibility_type_id')
        ?.updateValueAndValidity();
    }
    this.beneficiaryTypes.find((ele) => {
      if (event == ele.id) {
        this.parentForm.get('beneficiary_type_name').setValue(ele.ben_type);
      }
    });
  }

  uploadFile(event?) {
    this.imageUploadService.uploadFile(event).subscribe(
      (res) => {
        this.uploadedEmploymentFileDetails = res;
        this.parentForm
          .get('uploadedEmploymentFileDetails')
          .setValue(this.uploadedEmploymentFileDetails);
        this.parentForm.get('employment_certificate').setValue(res.fileName);
        this.cdr.detectChanges();
      },
      (err) => {
        this.util.presentToastError(err);
      }
    );
  }

  removeImage() {
    this.uploadedEmploymentFileDetails = null;
    this.parentForm.get('uploadedEmploymentFileDetails').setValue(null);
    this.parentForm.get('employment_certificate').setValue(null);
    this.cdr.detectChanges();
  }

  async selectImageSource() {
    if (!this.platform.is('desktop')) {
      this.imageUploadService.selectImageSource(this.fileInput).then((r) => {
        if (this.imageUploadService.imageCaptureType == 'takePhoto') {
          this.uploadFile();
        }
      });
    } else {
      this.imageUploadService.selectImageSource(this.fileInput);
    }
  }
}
