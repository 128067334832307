import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'benfNumberFormat'
})

export class BeneficiaryNumberFormatPipe implements PipeTransform {

  transform(number: any, args?: any): any {
    if (!number) { return null; }
    number = number.toString();
    let newStr = "";
    let i = 0;
    let range = args ? args : 4;
    if (number.length == 10) {
      range = args ? args : 3.3
    }
    let floor = number.length == 12 || 10 ? Math.floor(number.length / range) - 1 : Math.floor(number.length / range)
    for (; i < floor; i++) {
      newStr = newStr + number.substr(i * range, range) + "-";
    }
    return newStr + number.substr(i * range);
  }
}
