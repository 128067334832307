import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

Injectable()
export class BiometricRdService {

  constructor() { }

  createHttpObservable(url: string, reqBody: string, reqMethod: string): Observable<any> {
    return new Observable(observer => {
        const controller = new AbortController();
        const signal = controller.signal;

        fetch(url, {signal,
                    method: reqMethod,
                    body: reqBody,
                    headers : {
                            'content-type' : 'text/xml'
                        }
                    })
            .then(response => {
                if (response.ok) {
                  return response.text();
                }
                else {
                    observer.error('Request failed with status code: ' + response.status);
                }
            })
            .then(body => {
                observer.next(body);
                observer.complete();
            })
            .catch(err => {
                observer.error(err);
            });
        return () => controller.abort();
    });
  }



  getRDServiceInfo(): Observable<any> {
    let port = '';
    const timeStamp = new Date().valueOf();
    let url = '';
    if (sessionStorage.getItem('biometricdevice')) {
        const savedBioDevice = JSON.parse(sessionStorage.getItem('biometricdevice'));
        port = savedBioDevice.port;
        if (savedBioDevice.timestamprequired) {
            url = `${environment.rd_device_prefix_url}${port}?ts=${timeStamp}`;
        } else {
            url = `${environment.rd_device_prefix_url}${port}`;
        }
    }
    return this.createHttpObservable(url, '', 'RDSERVICE');
  }

  openRDdevice() {
    let port = '';
    const timeStamp = new Date().valueOf();
    let url = '';
    if (sessionStorage.getItem('biometricdevice')) {
        const savedBioDevice = JSON.parse(sessionStorage.getItem('biometricdevice'));
        port = savedBioDevice.port;
        if (savedBioDevice.timestamprequired) {
            url = `${environment.rd_device_prefix_url}${port}/rd/capture?ts=${timeStamp}`;
        } else {
            url = `${environment.rd_device_prefix_url}${port}/rd/capture`;
        }
    }
    return this.createHttpObservable(url, `<PidOptions> <Opts fCount="1" fType="0" iCount="0" pCount="0" format="0" pidVer="2.0" timeout="20000" otp="" posh="UNKNOWN" env="P" wadh="E0jzJ/P8UopUHAieZn8CKqS4WPMi5ZSYXgfnlfkWjrc=" /> <Demo></Demo><CustOpts> <Param name="ValidationKey" value="" /> </CustOpts> </PidOptions>`, 'CAPTURE');
  }
}
