<ion-grid>
    <header>
        <h1>
            <ion-icon (click)="onClose()" class="custom-close"></ion-icon>
        </h1>
    </header>
    <content>
        <ion-grid class="ion-text-center barcode">
            <app-pair-device-qr-code *ngIf="!showPairBtn" (onSessionCreate)="onSessionCreate($event)"></app-pair-device-qr-code>
            <ion-img *ngIf="showPairBtn" src="assets/images/pair-android.svg"></ion-img>
            <h1 class="pageTitle">{{'Pair an Android smartphone' | translate}}</h1>
            <h3 class="pageSubTitle">{{'You can pair an Android smartphone to U-WIN on desktop to scan QR codes on Aadhaar and verify beneficiaries easily' | translate}}</h3>
            <ion-button *ngIf="showPairBtn" (click)="pairDevice()" class="primaryBtn" shape="round" type="submit">{{'Pair your smartphone' | translate}} </ion-button>
        </ion-grid>
    </content>
</ion-grid>
