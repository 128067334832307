import { Component, OnInit, ViewChild, Input, OnChanges, SimpleChanges, AfterViewInit, Inject, } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Store, select } from '@ngrx/store';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { AddRecipientService } from '../../services/add-recipient.service';
import { selectIsAuthenticated } from 'src/app/modules/core/core.module';




@Component({
  selector: 'app-recipient-verify-vaccinate-stepper',
  templateUrl: './verify-vaccinate-stepper.component.html',
  styleUrls: ['./verify-vaccinate-stepper.component.scss'],
})
export class VerifyVaccinateStepperComponent implements OnInit {
  currentActiveStep = 'IDENTIFICATION';
  isAuthenticated$: Observable<boolean>;
  @ViewChild('stepper') stepper: MatStepper;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public addRecipientService: AddRecipientService,
    private store$: Store,
  ) {
    data.photo_id_type = this.matchAndReplacePhotoIdType(data);
    data.photo_id_number = this.matchAndReplacePhotoIdTypeNumber(data);
    this.addRecipientService.beneficiaryDetails = data;
  }

  matchAndReplacePhotoIdType(parentObj){
    if(!parentObj.photo_id_number){return null;}
    if(parentObj.photo_id_number.match(/[***]/g)){
      return null;
    }
    return parentObj.photo_id_type == 1 ? parentObj.photo_id_type = "Aadhaar Card" : parentObj.photo_id_type;
  }

  matchAndReplacePhotoIdTypeNumber(parentObj){
    if(!parentObj.photo_id_number){return null;}
    if(parentObj.photo_id_number.match(/[***]/g)){
      return null;
    }
    return parentObj.photo_id_number;
  }


  onStepChange(val : any) {
    if(val === 'next'){
      this.stepper.next()
    }else if(val == 'add-vaccine'){
      this.stepper.selectedIndex = 3;
    } else {
      this.stepper.previous();
    }
  }

  ngOnInit(): void {
    this.isAuthenticated$ = this.store$.pipe(select(selectIsAuthenticated));
    this.isAuthenticated$.subscribe(r => {
      if(!r){
        this.closeDialog();
      }
    });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'close', data: {}});
  }

  onTabChange(e: any) {
    let steps;
    if(this.data.session_type_name=='UIP'){
      steps = {0: 'IDENTIFICATION', 1: "PERSONALDETAILS",
      2: "VERIFIEDSUCCESS",3:"MULTIVACCUIP", 4: "VACCINATEMEMBER", 5: "VACCINATIONREJECTED"};
    }else{
      steps = {0: 'IDENTIFICATION', 1: "PERSONALDETAILS",
      2: "VERIFIEDSUCCESS", 3: "VACCINATEMEMBER", 4: "VACCINATIONREJECTED"};
    }
    
    this.currentActiveStep = steps[e.selectedIndex];
  }

}


