import {
  ActionReducerMap,
  MetaReducer,
  createFeatureSelector
} from '@ngrx/store';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';


import { initStateFromLocalStorage } from './meta-reducers/init-state-from-local-storage.reducer';

import { AppState, AuthState } from './interfaces/auth.interface';
import { authReducer } from './store/reducers/auth.reducer';
import { RouterStateUrl } from './router/router.state';
import { SettingsState } from './settings/settings.model';
import { settingsReducer } from './settings/settings.reducer';
import { userReducer } from './store/reducers/user.reducer';
import { sessionReducer } from './store/reducers/session.reducer';
import { registrationReducer } from './store/reducers/registration.reducer';

export const reducers: ActionReducerMap<AppState> = {
  auth: authReducer,
  settings: settingsReducer,
  router: routerReducer,
  user: userReducer,
  sess: sessionReducer,
  reg: registrationReducer,
};

export const metaReducers: MetaReducer<AppState>[] = [
  initStateFromLocalStorage
];

export const selectSettingsState =
  createFeatureSelector<AppState, SettingsState>('settings');


export const selectAuthState =
  createFeatureSelector<AppState, AuthState>('auth');

export const selectRouterState =
  createFeatureSelector<AppState, RouterReducerState<RouterStateUrl>>('router');

