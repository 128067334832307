import { FocusMonitor } from '@angular/cdk/a11y';
import { CdkConnectedOverlay, ConnectedPosition, Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ChangeDetectorRef, Component, ElementRef, HostBinding, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { merge, Observable } from 'rxjs';
import { mapTo, filter, debounceTime } from 'rxjs/operators';
import { searchService } from '../../services/search-service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { Subscription, of } from 'rxjs'
import { AadhaarValidation } from 'src/app/modules/shared/directives/aadhaar-validation.directive'
import { validatorsID } from '../../validators/search-validators';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
// import { AddRecipientStepperComponent } from 'src/app/screens/add-recipient/components/add-stepper/add-stepper.component';
import { select, Store } from '@ngrx/store';
import { getUserDetails } from 'src/app/modules/core/store/selectors/user.selectors';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
//  @HostListener('window:resize', ['$event.target'])
@Component({
  selector: 'app-web-search-beneficary',
  templateUrl: './web-search.component.html',
  styleUrls: ['./web-search.component.scss'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class WebSearchComponent implements OnInit {
  dataTypes: any;
  myForms: FormGroup;
  beneficaryData: any;
  benificaryObject: any;
  isDataInProgress = false;
  flagDetails: any;
  noData: boolean;
  returnNoData: string;
  AadharVal;
  MobileVal;
  panCardVal;
  drivingVal;
  PassportVal;
  pensionPassbookVal;
  referenceVal;
  showDilouge = true;
  onlySearch
  photoType;
  alphabets: boolean = false
  noDataOr: string = 'No results found. Search by other alternatives'

  @HostBinding('style.width.px') elWidth: number;
  showPanel: Observable<boolean>;
  @ViewChild(MatInput, { read: ElementRef, static: true }) private inputEl: ElementRef;
  @ViewChild(CdkConnectedOverlay, { static: true }) private connectedOverlay: CdkConnectedOverlay;

  public isPanelVisable$: Observable<boolean>;
  public isPanelHidden$: Observable<boolean>;
  private subjectKey = new Subject<any>();
  subscription: Subscription;
  userRole: any;

  constructor(
    private focusMoniter: FocusMonitor,
    private searchService: searchService,
    private fb: FormBuilder,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private store$: Store,
    private translate: TranslateService
  ) {

  }

  ngOnInit() {
    this.store$.pipe(select(getUserDetails)).subscribe(userInfo => {
      this.userRole = userInfo?.role;
    });
    this.searchService.getIdProofTypes().subscribe(res => {
      if(environment.isChildABHAenabled){ 
          this.dataTypes = [{
            "type": "ABHA Number",
            "id": 42,
            "doc_type": null,
            "flag": 4
        },...res];
      }else{
        this.dataTypes = res;
      }
      
    });
    this.subjectKey.pipe(debounceTime(800)).subscribe((d) => {
      if (this.myForms.valid) {
        this.searchBeneficaryDetails(d);
      }
    })

    this.isPanelHidden$ = this.connectedOverlay.backdropClick.pipe(mapTo(false));
    this.isPanelVisable$ = this.focusMoniter.monitor(this.inputEl)
      .pipe(
        filter((focused => !!focused)),
        mapTo(true));
    this.showPanel = merge(this.isPanelHidden$, this.isPanelVisable$);
    this.createForm();

  }

  get search_value() {
    return this.myForms.get('search_value');
  }

  get photo_id_type_id() {
    return this.myForms.get('photo_id_type_id')

  }

  searchData() {
    if (this.myForms.valid) {
      this.searchBeneficaryDetails(this.search_value.value);
    }
  }


  onResize(event) {
    this.resizeWorks(event);
    // event.stopPropagation()

  }
  private resizeWorks(el) {
    if (el == undefined) {
      this.elWidth = el.width;
      let dataWidth = document.getElementById("cdkdata")
      dataWidth.style.width = el.width + "px";

    }
  }

  createForm() {
    this.myForms = this.fb.group({
      photo_id_type_id: [
        null,
        []
      ],
      search_value: [null, [Validators.compose([Validators.required]),]],
    });
  }

  numberOnly(event): boolean {
    const charCode = (event.value) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      this.alphabets = true;
      return false;
    }
    this.alphabets = false;
    return true;
  }

  getDetailsType(event) {
    this.AadharVal = false;
    this.MobileVal = false;
    this.showDilouge = false;
    this.referenceVal = false;
    
    if (event.value == "0") {
      this.flagDetails = 0;
      this.search_value.setValidators(Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"))
      this.MobileVal = true;
    } else if (event.value == 'reference_ID99') {
      this.flagDetails = 1;
      this.search_value.setValidators(Validators.pattern(/^[0-9]*$/));
      this.search_value.updateValueAndValidity();
      this.referenceVal = true;
        }
    else if (event.value == 1) {
      this.flagDetails = 2;
      this.search_value.setValidators(AadhaarValidation())
      this.AadharVal = true;
    }else if (event.value == 42) {
      this.flagDetails = 4;
      this.photoType = 42;
      this.search_value.setValidators(validatorsID(this.photoType))
    } else {
      this.flagDetails = 2;
      this.dataTypes.find(e => {
        if (e.id == this.myForms.get('photo_id_type_id').value) {
          this.photoType = e.type;
        }
      })
      this.search_value.setValidators(validatorsID(this.photoType))
    }
    this.onlySearch = false;
    this.myForms.get('search_value').reset()
    if (event.value == 'reference_ID99' || event.value == 2) {
      this.showDilouge = false
    }
  }


  beneficiaryClicked(benId) {
    for (let ben in this.beneficaryData) {
      let id = this.beneficaryData[ben].beneficiary_id;
      if (benId == id) {
        this.router.navigate(['/beneficiary', id])
      }
    }
    this.showDilouge = false;
  }

  onSelectionChange(event) {
    const value = event.target.value
    if (value !== '') {
      this.subjectKey.next(value);
    }

    const dirty = this.myForms.dirty;
    if (dirty) {
      this.showDilouge = false;
    }
    if (this.myForms.get('photo_id_type_id').value === null || undefined) {
      this.onlySearch = true;
    }
    if (value == '') {
      this.onlySearch = false
    }
  }

  prepareAbhaNumber(value) {
    return (value).toString().replace(/(\d{2})(\d{4})(\d{4})(\d{4})/, "$1-$2-$3-$4")
  }
  

  searchBeneficaryDetails(valueData) {
    this.benificaryObject = {
      search_value: this.myForms.get('photo_id_type_id').value == 42 ? this.prepareAbhaNumber(valueData) : valueData,
      flag: this.flagDetails,
      photo_id_type_id:
        this.myForms.get('photo_id_type_id').value == 'reference_ID99' || this.myForms.get('photo_id_type_id').value == 42 ? undefined : this.myForms.get("photo_id_type_id").value || this.myForms.get("photo_id_type_id").value == "0" ? this.myForms.get('photo_id_type_id').value : undefined
    }
    this.isDataInProgress = true;
    this.cdr.detectChanges();
    this.returnNoData = '';
   
    if(valueData.length == 1) {
      this.isDataInProgress = false;
      this.noData = true
      this.showDilouge = true;
      this.returnNoData = this.translate.instant('No results found. Search by other alternative IDs.')
      this.cdr.detectChanges();
      return
    } else {
    this.searchService.searchBeneficiaryByType(this.benificaryObject)
      .subscribe(res => {
        this.isDataInProgress = false;
        if (res == '') {
          this.noData = true
          this.showDilouge = true;
          (this.userRole != environment.static_ids.DeliveryManagerKey || this.userRole != environment.static_ids.BothplanningUnitAndDelivery) ? this.returnNoData = this.translate.instant('No results found. Search by other alternative IDs.') : this.returnNoData = this.translate.instant('No results found. Search by other alternative IDs.')
        } else if (res && this.myForms.valid) {
          this.returnNoData = 'DATA FOUND';
          this.showDilouge = true
          this.beneficaryData = res;
          this.noData = false;
        } else {
          if (this.myForms.valid === true) {
            return (this.userRole != environment.static_ids.DeliveryManagerKey || this.userRole != environment.static_ids.BothplanningUnitAndDelivery) ? this.returnNoData = this.translate.instant('No results found. Search by other alternative IDs.') : this.returnNoData = this.translate.instant('No results found. Search by other alternative IDs.')
          }
        }
        this.showPanel = merge(this.isPanelHidden$, this.isPanelVisable$, of(true));
        this.cdr.detectChanges();
      }, (e) => {
        this.isDataInProgress = false;
        this.cdr.detectChanges();
      })
    }
  }

  close() {
    this.AadharVal = false;
    this.MobileVal = false;
    this.PassportVal = false;
    this.pensionPassbookVal = false;
    this.drivingVal = false;
    this.panCardVal = false;
    this.referenceVal = false;
    this.search_value.reset();
    this.showDilouge = false
  }

  getBenificaryId() {
  }

  // openAddMember() {
  //   if (this.userRole == "Vaccinator" || this.userRole == "Verifier" || this.userRole == "ANM") {
  //     const dialogRef = this.dialog.open(AddRecipientStepperComponent,
  //       {
  //         position: { right: "0", top: "0" },
  //         height: "100%",
  //         width: "450px",
  //         hasBackdrop: true,
  //         disableClose: true,
  //         panelClass: 'dialog-side-panel',
  //       });
  //   }
  //   this.myForms.reset();
  //   this.showDilouge = false;
  // }


  ngOnDestroy() {
    this.subjectKey.unsubscribe();
  }


}


