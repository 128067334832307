<form [formGroup]='personalDetForm' autocomplete="off" (ngSubmit)='onSubmit()'>
    <ion-grid class="inputFields">
        <ng-container *ngIf="!scannedData">
            <ion-row>
                <ion-col size="12">
                    <ion-label>
                        UID Number
                    </ion-label>
                    <h2>{{beneficiaryData?.aadhaarNumber| benfNumberFormat}}</h2>
                    <ion-img (click)="startFacialScan()" src="assets/images/facial.svg" class="ion-margin-vertical"></ion-img>
                </ion-col>
            </ion-row>
        </ng-container>
        <ng-container *ngIf="scannedData">
            <ion-row>
                <ion-col size="12">
                    <ion-label>
                        UID Number
                    </ion-label>
                    <h2>{{beneficiaryData?.aadhaarNumber}}</h2>
                </ion-col>
            </ion-row>
            <!-- <ion-row>
        <ion-col size="12">
          <div>
            <h3 class="ion-text-uppercase pageSubTitle">Demographic Authentication</h3>
            <app-common-recipient-fields [parentForm]="personalDetForm"></app-common-recipient-fields>
          </div>
        </ion-col>
      </ion-row> -->
            <!-- <div class="sidebar-footer">
          <ion-row>
            <ion-col size-md="12">
              <mat-checkbox formControlName="consent">I hereby confirm that I have read out the information provided by
                the beneficiary... Read More</mat-checkbox>
                <span *ngIf="beneficiaryData!=null && beneficiaryData!=undefined">
                  <mat-checkbox *ngIf="beneficiaryData.ben_type_id == 19 || beneficiaryData.ben_type_id == 20" (change)="checkedConsent()"
                    formControlName="female_consent" required> The beneficiary has been informed about the risk of exposure to
                    COVID-19 infection along with the risk and benefits associated with the COVID-19 vaccination.
                  </mat-checkbox>
                </span>
              <ion-button class="primaryBtn" expand="full" shape="round" type="submit">Verify Member </ion-button>
            </ion-col>
          </ion-row>
        </div> -->
        </ng-container>
        <div>
            <ion-row>
                <ion-col size-md="12">
                    <span class="checkboxSpan" [ngClass]="{'checkbox-required': personalDetForm.get('consent').touched && personalDetForm.get('consent')?.errors?.required}">
          <mat-checkbox
          [ngClass]="{'checkbox-required': personalDetForm.get('consent').touched && personalDetForm.get('consent')?.errors?.required}"

          formControlName="consent" ></mat-checkbox><p>I hereby confirm that I have read out the
            information provided by the
            beneficiary..
            <a (click)="openBottomSheet()">{{'Read More' | translate}}</a></p>
        </span>
                    <span class="checkboxSpan" *ngIf="beneficiaryData!=null && beneficiaryData!=undefined && beneficiaryData.ben_type_id == environment.static_ids.PregnantWomen || beneficiaryData.ben_type_id == environment.static_ids.LactatingMother">
            <mat-checkbox
            [ngClass]="{'checkbox-required': personalDetForm.get('female_consent').touched && personalDetForm.get('female_consent')?.errors?.required}"

            formControlName="female_consent" required></mat-checkbox>
            <p *ngIf="beneficiaryData?.session_type_name == 'UIP'">The beneficiary has been informed about the risk and benefits associated with the ROUTINE vaccination</p>
            <p *ngIf="beneficiaryData?.session_type_name == 'COVID-19'">The beneficiary has been informed about the risk of exposure to COVID-19  infection along with the risk and benefits associated with the COVID-19 vaccination.</p>
           <p *ngIf="!beneficiaryData?.hasOwnProperty('session_type_name')">The beneficiary has been informed about the risk and benefits associated with the vaccination</p>
          </span>
                    <div class="ion-text-end">
                        <ion-button class="primaryBtn" shape="round" type="submit" [disabled]="verificationInProgress">
                            <ion-spinner name="lines-small" *ngIf="verificationInProgress"></ion-spinner>Verify Member </ion-button>
                    </div>
                </ion-col>
            </ion-row>
        </div>
    </ion-grid>
</form>
