<ion-grid class="ion-no-padding headerBg contentContainer contentTopPadding">
  <ion-row class="headerPadding">
    <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12" class="ion-hide-md-down">
      <h1 class="pageTitle"><ion-icon *ngIf="showSummary && !vaccDataUpdated" slot="start" class="custom-arrow-left" (click)="onBack()"></ion-icon>{{(showSummary ? 'Vaccination Summary' : 'Record Previous Vaccination') | translate}}</h1>
    </ion-col>
    <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12" class="ion-text-end">
      <div *ngIf="skip && !showSummary">
        <ion-button class="primaryBtn" (click)="onSkipNextSteps()" >{{'Skip' | translate}} </ion-button>
        </div>
        <!-- <ion-button *ngIf="vaccDataUpdated" class="primaryBtn" fill="outline" (click)="navigateABHAPage()" >{{'Create ABHA' | translate}} </ion-button> -->
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="12">
      <div *ngIf="!showSummary">
        <form [formGroup]="multiSelectionUIPVaccForm" autocomplete="off">
          <!-- <h3 class="pageTitle">Record Previous Vaccination</h3> -->
        <ng-container>
          <ng-container *ngIf="prevVaccinesByAge.length == 0">
            <ion-row class="ion-margin-vertical">
              <ion-col class="ion-text-center">
                <ion-card class="ion-no-margin previousVaccCard">
                  <ion-card-content>
                    <img src="assets/images/aefireport.svg" clsss="verified-logo">
                    <p>{{'Previous Vaccines are not available for this member' |translate}}</p>
                  </ion-card-content>
                </ion-card>
                <!-- <ion-button class="primaryBtn" expand="full" (click)="loadCurrentVaccines()">Go Back</ion-button> -->
              </ion-col>
            </ion-row>
          </ng-container>
          <ion-grid *ngIf="prevVaccinesByAge.length > 0" class="vaccines-grid previousVaccinationBg">
            <ion-card class="ion-no-margin previousVaccCard">
              <ion-card-content>
            <ng-container *ngFor="let material of prevVaccinesByAge;let i = index;">
              <ion-row class="vaccine-names">
                <ion-col size="12" size-lg="5">
                  <!-- <mat-radio-group (change)="showVaccineInfoByBenf(material,i,$event)">
                                      <mat-radio-button  value="material.material_name">{{material.material_name}} </mat-radio-button>
                                  </mat-radio-group> -->
                  <mat-checkbox (change)="showVaccineInfoByBenf(material)" [ngClass]="{'active': material.checked}" color="primary"
                  [checked]="material.checked"
                    [disabled]="isFullyVaccinatedByMaterial(material) || disableBySpecificCase(material)" class="example-margin">{{material.material_name | translate}}
                  </mat-checkbox>
        
                </ion-col>
              </ion-row>
              <ion-row class="fade-in" *ngIf="material.no_of_doses > 1 && material.checked == true">
                <ion-col size="12" size-lg="4">
                  <mat-radio-group id="mat{{material.material_id}}" name="mat{{material.material_id}}">
                    <ion-card class="detailsCard ion-no-margin ion-margin-bottom">
                      <ion-card-content>
                    <ng-container *ngFor="let d of material.dose_array">
                        <ng-container *ngIf="!d.doseReceivedDate && !d.isVaccinatedOutside"> <!--*ngIf="!d.doseReceivedDate && !d.isVaccinatedOutside"-->
                          <ion-row class="dose-labels">
                            <ion-col size="5">
                              <mat-checkbox class="example-margin" color="primary"
                              [checked]="isDoseChecked(material, d)"
                              (change)="onSelectByDose(material,d,$event)"
                              [disabled]="!isDoseEligibleForMinAge(material,d) || isLastDoseChecked(material, d) || isDoseEligibleByLastDose(material, d) || disableBySpecificCase(material, d)"
                                [value]="d.dose">{{labelName(d)}}
                              </mat-checkbox>
                              
                            </ion-col>
                            <ion-col size="7"  *ngIf="isDoseChecked(material, d)">
                                <mat-form-field appearance="outline" class="matDropdown">
                                <input matInput readonly [matDatepicker]="dp" [max]="getMaxDoseDate(material,d)"
                                  [min]="getMinDoseDate(material, d)"
                                  [disabled]="!isDoseEligibleForMinAge(material,d) || isLastDoseChecked(material, d) || isDoseEligibleByLastDose(material, d) || disableBySpecificCase(material, d)"
                                  (dateChange)="onSelectByDoseDate(material, d, $event)">
                                <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                                <mat-datepicker #dp></mat-datepicker>
                              </mat-form-field>
                            </ion-col>
                          </ion-row>
                          <!-- <ion-row *ngIf="isDoseChecked(material, d)">
                            <ion-col size="12" class="ion-padding-horizontal">
                            </ion-col>
                          </ion-row> -->
                        </ng-container>
                        <ng-container *ngIf="d.doseReceivedDate || d.isVaccinatedOutside">
                          <ion-item class="completed ion-no-padding">
                              <!-- <ion-label>
                                {{labelName(d)}}
                              </ion-label>
                            <ion-note *ngIf="d.doseReceivedDate" slot="end">{{d.doseReceivedDate | displayDateFormat}}
                            </ion-note>
                            <ion-note *ngIf="!d.doseReceivedDate" slot="end">{{'N/A'}}</ion-note> -->

                            <ion-label class="opvCompleted">
                              <p>{{labelName(d)}}</p>
                              <h3 *ngIf="d.doseReceivedDate">{{d.doseReceivedDate | displayDateFormat}}</h3>
                              <h3 *ngIf="!d.doseReceivedDate">{{'Updated' | translate}}</h3>
                            </ion-label>

                          </ion-item>
                          <!-- completed -->
                        </ng-container>
                      </ng-container>
                    </ion-card-content>
                    </ion-card>
                    <!-- </ng-container> -->
                  </mat-radio-group>
                </ion-col>
              </ion-row>
        
              <ion-row class="ion-margin-vertical" *ngIf="material.no_of_doses == 1 && material.checked == true">
                <ion-col size="12" size-lg="4">
                  <ion-label>{{'Dose Date' | translate}}</ion-label>
                  <mat-form-field appearance="outline" class="matDropdown ion-margin-vertical">
                    <input readonly matInput [matDatepicker]="dp" [max]="getMaxDoseDate(material,{dose:1})"
                      [min]="getMinDoseDate(material, {dose:1})" (dateChange)="onselectedDoseDateEvent(material, $event)">
                    <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                    <mat-datepicker #dp></mat-datepicker>
                  </mat-form-field>
                </ion-col>
              </ion-row>
        
            </ng-container>
          </ion-card-content>
        </ion-card>
          </ion-grid>
        </ng-container>
        <ng-container>
          <ion-grid>
            <ion-row>
              <ion-col class="ion-text-right" size="12">
                <ion-button  class="primaryBtn"  shape="round" [disabled]="prevVaccinesByAge.length == 0 || isSubmitDisable()" (click)="submit()"
                  type="button">{{'Save & Continue' | translate}}
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ng-container>
        </form>
        </div>
        <ng-container *ngIf="showSummary">
          <!-- <ion-row>
            <ion-col size="12">
              <h1><ion-icon slot="start" class="custom-arrow-left" (click)="onBack()"></ion-icon>Vaccination Summary</h1> -->
              <!-- <ion-button (click)="onBack()" class="primaryBtn" expand="full" shape="round" type="button">Back</ion-button> -->
            <!-- </ion-col>
          </ion-row> -->
          <ion-card class="ion-no-margin previousVaccCard">
            <ion-card-content>
          <ion-grid class="ion-no-padding" *ngFor="let material of selectedMaterials | sortBy :'asc': 'material_id'">
              <ion-row>
                <ion-col size-xl="4" size-lg="4" size-md="4" size-sm="6" size-xs="12">
                  <ion-text>
                    <ion-label> {{'Vaccine Name' | translate}}</ion-label>
                    <h5>{{material.material_name}}</h5>
                  </ion-text>
                </ion-col>
                <ion-col size-xl="4" size-lg="4" size-md="4" size-sm="6" size-xs="6">
                  <ion-text>
                    <ion-label>{{'Dose' | translate}}</ion-label>
                    <h5>{{materialDoseName(material)}}</h5>
                  </ion-text>
                </ion-col>
                <ion-col size-xl="4" size-lg="4" size-md="4" size-sm="6" size-xs="6" class="textRightMobile">
                  <ion-text>
                    <ion-label>{{'Vaccine Received' | translate}}</ion-label>
                    <h5>{{material.prev_vaccination_date ? (material.prev_vaccination_date | displayDateFormat) : material.vaccination_date ? (material.vaccination_date | displayDateFormat): 'Updated'}}</h5>
                  </ion-text>
                </ion-col>

              </ion-row>
          </ion-grid>
        </ion-card-content>
        </ion-card>
          <ion-row *ngIf="!vaccDataUpdated" class="ion-margin-top">
            <ion-col size="12" class="ion-text-end">
              <!-- <ion-button class="primaryBtn" shape="round" type="button" (click)="saveAndLinkABHA()">Save & Link ABHA</ion-button> -->
              <ion-button class="primaryBtn" shape="round" type="button" (click)="submitPrevData('onlySave')">{{'Save' | translate}}</ion-button>
             </ion-col>
          </ion-row>
        </ng-container>
    </ion-col>
  </ion-row>
</ion-grid>
