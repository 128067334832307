<ng-container *ngIf="util.getPlatformWidth() < 575">
  <!-- <ion-menu [type]="'overlay'" #mobmenu content-id="main-content">
    <ion-header>
      <ion-toolbar>
        <ion-title>Menu Content</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-menu-toggle>
        <ion-button>Click to close the menu</ion-button>
      </ion-menu-toggle>
    </ion-content>
  </ion-menu> -->
  <ng-container *ngTemplateOutlet="tpl"></ng-container>
</ng-container>
<ng-container *ngIf="util.getPlatformWidth() >= 575">
  <ng-container *ngTemplateOutlet="tpl"></ng-container>
</ng-container>

<ng-template #tpl>

  <ng-container *ngIf="userDetails$ | async as userInfo">
    <div>
      <!-- <div class="logoPanel">
        <ion-img src="assets/images/u-win.svg"></ion-img>
        <div class="toggleArrow" (click)="closeSideNav()">
          <ion-icon slot="start" class="custom-hamburger-left"></ion-icon>
        </div>
      </div> -->
      <div class="toggleArrow" (click)="closeSideNav()">
        <ion-icon slot="start" class="custom-hamburger-left"></ion-icon>
      </div>
      <div class="profileSideNav noDesktop">
        <ion-grid>
          <ion-row>
          <ion-col size-xs="3">
              <button mat-button class="dpBtn user-menu-icon">
                <img *ngIf="!profilePic" [src]="userInfo?.profile_picture_url" (error)="handleMissingImage($event)"
                  alt="profile-avtar" class="profileAvatar" />
                <img *ngIf="profilePic" src="assets/images/profilepic.png" alt="profile-avtar" class="profileAvatar" />
              </button>
            </ion-col>
            <ion-col size-xs="9">
              <h3>{{userInfo?.display_name}}</h3> 
              <a (click)="toggleSideNav()" routerLink="/my-profile">{{'View Profile' | translate}}</a>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
      <mat-nav-list>
        <mat-list-item>
          <a matLine (click)="toggleSideNav()" [routerLink]="['/dashboard']"
             routerLinkActive="router-link-active" [attr.title]="'Dashboard' | translate">
            <ion-icon slot="start" class="custom-nav-home"></ion-icon>{{ 'Dashboard' | translate }}
          </a>
        </mat-list-item>        
          <mat-list-item
          *ngIf="userInfo?.ccp_type !== ENV.ARMED_FORCES_ID && (userInfo.role === 'ANM' || userInfo.role === 'Vaccinator' || userInfo.role === 'Both(Vaccinator & Delivery Manager)')"
          (click)="toggleSideNav()">
          <a matLine [routerLink]="['/manage-asha']" routerLinkActive="router-link-active" [attr.title]="'Asha Management' | translate">
            <ion-icon slot="start" class="custom-nav-session"></ion-icon>{{'Asha Management' | translate}}
          </a>
        </mat-list-item>
        <mat-list-item
          *ngIf="userInfo.role === 'ASHA' || userInfo.role === 'ANM' || userInfo.role === 'Vaccinator' || userInfo.role === 'Both(Vaccinator & Delivery Manager)'">
          <a matLine (click)="toggleSideNav()" [routerLink]="['/asha-dashboard/vaccinated-beneficiaries']"
            routerLinkActive="router-link-active" [attr.title]="'Vaccinated Beneficiaries' | translate">
            <ion-icon slot="start" class="custom-vaccinated-beneficiaries"></ion-icon>{{'Vaccinated Beneficiaries' |
            translate}}
          </a> </mat-list-item>
        <mat-list-item *ngIf="userInfo.role === 'ASHA' || userInfo.role === 'Vaccinator'"> <a matLine
            (click)="toggleSideNav()" [routerLink]="['/new-beneficiary/registered-beneficaries']"
            routerLinkActive="router-link-active" [attr.title]="'Pre-Registered Beneficiaries' | translate">
            <ion-icon slot="start" class="custom-registartion"></ion-icon>{{'Pre-Registered Beneficiaries' | translate}}
          </a> </mat-list-item>
        <mat-list-item
          *ngIf="userInfo.role === 'ANM' || userInfo.role === 'Vaccinator' || userInfo.role === 'Both(Vaccinator & Delivery Manager)' || userInfo.role === 'ASHA'">
          <a matLine (click)="toggleSideNav()" [routerLink]="['/asha-dashboard/due-list']"
            routerLinkActive="router-link-active"[attr.title]="'Due List' | translate">
            <ion-icon slot="start" class="custom-duelist"></ion-icon>{{'Due List' | translate}}
          </a> </mat-list-item>
        <mat-list-item
          *ngIf="userInfo?.ccp_type != ENV.ARMED_FORCES_ID && (userInfo.role === 'Vaccinator' || userInfo.role === 'Both(Vaccinator & Delivery Manager)')">
          <a matLine (click)="toggleSideNav()" [routerLink]="['/report-aefi']" routerLinkActive="router-link-active" [attr.title]="'Report AEFI' | translate">
            <ion-icon slot="start" class="custom-aefi"></ion-icon>{{'Report AEFI' | translate}}
          </a> </mat-list-item>
        <mat-list-item
          *ngIf="userInfo.role === 'Vaccinator' || userInfo.role === 'ANM' || userInfo.role === 'Both(Vaccinator & Delivery Manager)'">
          <a matLine (click)="toggleSideNav()" [routerLink]="['/resources']" routerLinkActive="router-link-active"
          [attr.title]="'Resources' | translate">
            <ion-icon slot="start" class="custom-nav-resource"></ion-icon>{{'Resources' | translate}}
          </a> </mat-list-item>
        <mat-list-item
          *ngIf="userInfo.role === 'Vaccinator' || userInfo.role === 'ANM' || userInfo.role === 'Both(Vaccinator & Delivery Manager)'">
          <a matLine (click)="toggleSideNav()" [routerLink]="['/configurations']" routerLinkActive="router-link-active"
          [attr.title]="'Configuration' | translate">
            <ion-icon slot="start" class="custom-nav-setting"></ion-icon>{{'Configuration' | translate}}
          </a> </mat-list-item>
        <!-- <mat-list-item>                <a matLine (click)="toggleSideNav()" [routerLink]="['/beneficiary/b78c4066-0ce5-4f9f-bcf7-30dd091deea3']" routerLinkActive="router-link-active">                    <ion-icon slot="start" class="custom-nav-setting"></ion-icon>new page</a>            </mat-list-item> -->
        <mat-list-item class="sideBarVersion">
          <ion-text>{{'Version' | translate}}: {{ENV.appVersion}}</ion-text>
        </mat-list-item>
        <mat-list-item (click)="toggleSideNav()" class="logout">
          <a matLine (click)="logout()"><ion-icon name="log-out-outline"></ion-icon>{{'Logout' | translate}}</a>
        </mat-list-item>
      </mat-nav-list>
    </div>
  </ng-container>
</ng-template>