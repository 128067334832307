import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { CoreModule } from './modules/core/core.module';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { I18nModule } from './modules/i18n/i18n.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AddRecipientPageModule } from './screens/add-recipient/add-recipient.module';
import { TranslateModule } from '@ngx-translate/core';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NotFoundComponent } from './screens/not-found/not-found.component';
import { ConfigService } from './modules/shared/services';
import { Observable } from 'rxjs';
import { TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function initializeAppConfig(configService: ConfigService): () => Observable<any>{
    return () => configService.setVersionDetails();
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
  }

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [AppComponent, NotFoundComponent],
    imports: [
        // angular
        BrowserAnimationsModule,
        BrowserModule,
        //core
        CoreModule,
        // language
        I18nModule,
        // app
        IonicModule.forRoot({ innerHTMLTemplatesEnabled: true }),
        AppRoutingModule,
        HttpClientModule,
        AddRecipientPageModule,
        TranslateModule.forRoot({
            loader: {
              provide: TranslateLoader,
              useFactory: HttpLoaderFactory,
              deps: [HttpClient],
            },
          }),
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializeAppConfig,
            deps: [ConfigService],
            multi: true,
        },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
