<!-- <button mat-icon-button class="close-button" (click)="closeDialog()">
  <span class="icon-cancel"></span>
</button> -->
<!--
<div class="modalWelcome">
  <ion-row>
    <ion-col class="modalHeading ion-text-end">
      <h1 mat-dialog-title>Dear Vaccination team members,</h1>
      <img src="../../../../../assets/images/popup-heading.gif" />
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col size="12" class="modalBody">
      <p>Greetings From MoHFW,</p>
      <p>As India crosses the milestone of more than 150 crore covid-19 vaccine doses administered, we salute your dedication and contribution to this historic cause.</p>
      <p>We are proud of your inspiring efforts. Together we will defeat COVID.</p>
      <h1>Jai Hind.</h1>
      <img src="../../../../../assets/images/popup-body.svg" />
    </ion-col>
  </ion-row>

  <div mat-dialog-actions class="ion-text-end">
    <ion-button color="primary" shape="round" type='submit' (click)="closeDialog()">Ok</ion-button>
    <span class="icon-cancel" (click)="closeDialog()"></span>
    <button mat-button (click)="closeDialog()" >Ok</button>
  </div>

</div> -->
<!-- <mat-dialog-content> -->




<ion-grid>
    <header>
        <h1 class="pageTitle">Dear Team Members,
            <ion-icon (click)="onClose()" class="custom-close"></ion-icon>
        </h1>
    </header>
    <content>
        <ion-grid class="ion-no-padding">
            <h3 class="pageSubTitle">Greetings from MoHFW,</h3>
            <p class="bodyText">As India crosses the milestone of more than 150 crore COVID-19 vaccine doses administered, we salute your dedication and contribution to this historic cause.</p>
            <p>We are proud of your inspiring efforts. Together we will defeat COVID.</p>
            <h3 class="pageSubTitle">Jai Hind.</h3>
            <div class="ion-text-center">
                <img src="assets/images/popup-body.svg" />
                <p class="tagline">150 CRORE DOSES REACHED</p>
            </div>
        </ion-grid>
    </content>
</ion-grid>
