<form [formGroup]='personalDetForm' autocomplete="off" (ngSubmit)='onSubmit()'>
    <ion-grid class="inputFields">
        <h3 class="pageSubTitle">Registered Number Authentication</h3>
        <ion-row>
            <ion-col size="12">
                <ion-label>
                    Aadhaar UID Number
                </ion-label>
                <h2>{{beneficiaryData?.aadhaarNumber | benfNumberFormat}}</h2>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col size="12">
                <div class="biometric-logo">
                    <ion-img src="assets/images/biometric.svg" class="ion-margin-vertical"></ion-img>
                    <ion-img *ngIf="scanSuccess" src="assets/images/green-tick.svg" class="biometric-success"></ion-img>
                </div>

            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col *ngIf="scanInprogress" size="12" class="ion-text-center">
                <ion-title class="ion-margin-vertical">Scanning in Progress </ion-title>
                <ion-label>Scan member’s <b>right thumb</b> to continue</ion-label>
            </ion-col>
        </ion-row>

        <ion-row>
            <ion-col size-md="12">
                <span class="checkboxSpan" [ngClass]="{'checkbox-required': personalDetForm.get('consent').touched && personalDetForm.get('consent')?.errors?.required}">
        <mat-checkbox
        [ngClass]="{'checkbox-required': personalDetForm.get('consent').touched && personalDetForm.get('consent')?.errors?.required}"

        formControlName="consent"></mat-checkbox><p>I hereby confirm that I have read
          out the information provided by the beneficiary...
        <a (click)="openBottomSheet()">{{'Read More' | translate}}</a></p>
      </span>
                <span class="checkboxSpan" *ngIf="beneficiaryData!=null && beneficiaryData!=undefined && beneficiaryData?.ben_type_id == environment.static_ids.PregnantWomen || beneficiaryData?.ben_type_id == environment.static_ids.LactatingMother" [ngClass]="{'checkbox-required': personalDetForm.get('female_consent').touched && personalDetForm.get('female_consent')?.errors?.required}">
          <mat-checkbox
          [ngClass]="{'checkbox-required': personalDetForm.get('female_consent').touched && personalDetForm.get('female_consent')?.errors?.required}"

          formControlName="female_consent" required></mat-checkbox> 
          <p *ngIf="beneficiaryData?.session_type_name == 'UIP'">The beneficiary has been informed about the risk and benefits associated with the ROUTINE vaccination</p>
            <p *ngIf="beneficiaryData?.session_type_name == 'COVID-19'">The beneficiary has been informed about the risk of exposure to COVID-19  infection along with the risk and benefits associated with the COVID-19 vaccination.</p>
           <p *ngIf="!beneficiaryData?.hasOwnProperty('session_type_name')">The beneficiary has been informed about the risk and benefits associated with the vaccination</p>
        </span>
                <div class="ion-text-end">
                    <ion-button class="primaryBtn" shape="round" type="submit">Verify Member </ion-button>
                </div>
            </ion-col>
        </ion-row>

    </ion-grid>


</form>
