<ng-container  *ngIf="util.getPlatformWidth() < 575">
    <ion-content class="ion-page">
      <ng-container *ngTemplateOutlet="tpl"></ng-container>
    </ion-content>
    </ng-container>
    <ng-container  *ngIf="util.getPlatformWidth() >= 575">
        <ng-container *ngTemplateOutlet="tpl"></ng-container>
    </ng-container>
  <ng-template #tpl>

<app-add-recipient-verified-success [activeStepIndex]="'VERIFIEDSUCCESS'"  (onClose)="closeDialog()" (onStepChange)="onStepChange($event)">
</app-add-recipient-verified-success>
</ng-template>