import { SharedModule } from './../../modules/shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { PairDeviceDialog } from './components/pair-device-dialog/pair-device-dialog';
import { QrCodeComponent } from './components/qr-code/qr-code';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    SharedModule
  ],
  declarations: [
    PairDeviceDialog,
    QrCodeComponent,
  ],
  exports: [
    QrCodeComponent
  ]
})
export class RemoteInterfaceModule {}
