import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'displayDateFormat'
})
export class CustomDateFormatPipe implements PipeTransform {
  transform(value: any): string {
    const date = moment(value).format(environment.display_date_format);
   return date
  }
}