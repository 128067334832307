<div [formGroup]='parentForm'>

  <!-- <p *ngIf="utils.getPlatformWidth() < 75" class="customSelect"> -->
  <!-- <ion-datetime class="datePicker" [min]="minDate" [max]="maxDate" formControlName='{{formKey}}'  (ionChange)="onChangeDate($event)"></ion-datetime>
      <span class="datepickerIcon">
    <ion-icon slot="end" class="custom-calendar"></ion-icon>
  </span> -->
  <!-- <ion-item>
          <ion-label [ngClass]="class" class={{required}}>{{label}}</ion-label>
          <ion-input formControlName='{{formKey}}' errorText=" " ></ion-input>
          <ion-button fill="clear" id="open-date-input-2">
              <ion-icon icon="calendar"></ion-icon>
          </ion-button>
          <ion-popover trigger="open-date-input-2" show-backdrop="false">
              <ng-template>
                  <ion-datetime [min]="minDate | date:'yyyy-MM-dd'" [max]="maxDate | date:'yyyy-MM-dd'"
                  #popoverDatetime2 presentation="date" [value]="maxDate | date:'yyyy-MM-dd'"
                  (ionChange)="formatDate(popoverDatetime2.value)"></ion-datetime>
              </ng-template>
          </ion-popover>
      </ion-item> -->
  <!-- </p> -->

  <div class="inputFields">
    <ion-label [ngClass]="class" class={{required}}>{{label}}</ion-label>
    <mat-form-field appearance="outline" class="matDropdown ion-margin-vertical">
      <input matInput [placeholder]="placeholder" readonly (change)="onChange($event)" [matDatepicker]="dp"
        (click)="dp.open()" formControlName='{{formKey}}' [min]="minDate" [max]="maxDate"
        (dateChange)="onChangeDate($event)">
      <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
      <mat-datepicker #dp></mat-datepicker>
    </mat-form-field>
  </div>
</div>
