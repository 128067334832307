import { ToastController } from '@ionic/angular';
import { ChangeDetectorRef, Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { ErrorCodes } from './error-codes';
import { UtilsService } from '../../shared/services/utils.service';
import { Store } from '@ngrx/store';
import { Logout } from '../store/actions/auth.actions';
import { TranslateService } from '@ngx-translate/core';
/**
 * Adds a default error handler to all requests.
 */
@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    private toastr: ToastController,
    private router: Router,
    private auth: AuthService,
    private store$: Store,
    private utilsService: UtilsService,
    private translate: TranslateService
    ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(error => this.errorHandler(error)));
  }

  // Customize the default error handler here if needed
  private errorHandler(response: HttpEvent<any>): Observable<HttpEvent<any>> {
    if (!environment.production) {
      // Do something with the error
    }
    const isJson = (str) => {
      try {
          JSON.parse(str);
      } catch (e) {
          return false;
      }
      return true;
    };
    if (response instanceof HttpErrorResponse) {
      switch (response.status) {
        case 401:{
          if(!((response.url.endsWith('/profilePic')) || response.error && (response.error == 'Token Expired' ||  response.error.error == 'Token Expired'))){ 
            this.utilsService.presentToastError("Something went wrong! Please Try after sometime");
            setTimeout(() => {this.store$.dispatch(Logout()); }, 1000);
          }
break;
        }
                case 403:{      // forbidden
          if(response.error == "Your session was expired. Please login with credentials"){
            this.store$.dispatch(Logout());
          }else{ 
            // console.log('@@@@@@@@@@@@@@@@@@@@@@', response);
            // this.router.navigateByUrl('/forbidden');
                        if(response.url.endsWith('/getLoggedInUserInfo')) {
              this.utilsService.presentToastError("Not Authorized to login.");
            } else {
            this.utilsService.presentToastError(response.error.message);
            }
          }
        break;
        }
        case 400:{
          if(response.error.errorCode === 'DOBEDIT002' || response.error.errorCode === 'DOBEDIT001' || response.error.errorCode === 'DOBEDIT003'){
            break;
          }
          if (response.error) {
            if (typeof(response.error) === 'string') {
              if (isJson(response.error)) {
                const errmsg = JSON.parse(response.error);
                /* Password Policy Conditions */
                this.handleError(response, errmsg);
              } else {
                this.utilsService.presentToastError(response.error);
              }
            } else if (response.error.errorCode) {
              if(response.error.offline_mode){return;}
              if (response.error.errorCode !== 'ADMROL0012') {
                this.handleError(response, response.error)
              }
            } else {
              if(response.error.offline_mode){return;}

              try{
                this.utilsService.presentToastError(response.error.message);
              } catch (e) {
                console.error('Error!!! ', e);
              }
            }
          } else {
            this.utilsService.presentToastError(response.error);
          }
          break;
        }
        case 0:{ 
          if (window.navigator.onLine){
            this.utilsService.presentToastError('Something went wrong. Please try again!');
            break;
          } else if (!window.navigator.onLine) {
            this.utilsService.presentToastError('Something went wrong. Please try again!');
            break;
          }
          else {
            break;
          }
        }
      }
    }
    throw response;
  }

  handleError(response: any, errmsg: any) {
    if (errmsg.errorCode === 'USRAUT0004' || errmsg.errorCode === 'USRAUT0005' || errmsg.errorCode === 'USRAUT0006' || errmsg.errorCode === 'USRAUT0007') {
      if (errmsg.errorCode === 'USRAUT0004') {
        const userId = errmsg.userId;
        this.auth.userId = userId;
        this.router.navigate(['/auth/updatepassword']);
      } else if (errmsg.errorCode === 'USRAUT0005') {
        this.utilsService.presentToastError(this.translate.instant(errmsg.error));
        setTimeout(() => {
          this.router.navigate(['/auth/forgot']);
        }, 100);
      } else if (errmsg.errorCode === 'USRAUT0006' || errmsg.errorCode === 'USRAUT0007') {
        const userId = errmsg.userId;
        this.auth.userId = userId;
        this.utilsService.presentToastError(this.translate.instant(errmsg.error));
        setTimeout(() => {
          this.router.navigate(['/auth/updatepassword']);
        }, 100);
      }
    } else {
      if(response.error && response.error.error){
        this.utilsService.presentToastError(this.translate.instant(`${response.error.error}`));
      } else if (errmsg.errorCode) {
        const errorMessage = ErrorCodes[errmsg.errorCode];
        if (errorMessage) {
          this.utilsService.presentToastError(this.translate.instant(errorMessage));
        } else {
          this.utilsService.presentToastError(this.translate.instant(errmsg.error));
        }
      } else {
        this.utilsService.presentToastError(this.translate.instant(response.error));
      }
    }
  }
}
