import {Statuses} from "../../constants/app.constants";
import {Action, createReducer, on} from "@ngrx/store";
import * as sessActions from "../actions/session.actions";


export interface SessionState {
  data: any;
  status: Statuses
}

export const initialSessState: SessionState = {
  data: null,
  status: Statuses.UNINITIALIZED
};

const session = createReducer(
  initialSessState,
  on(sessActions.SetSession, (state, payload) => {
    return {
      ...state,
      data: payload,
      status: Statuses.LOADED
    };
  }),
  on(sessActions.LoadingSession, (state) => {
    return {
      ...state,
      data: null,
      status: Statuses.LOADING
    }
  }),
  on(sessActions.ResetSession, (state => ({...state, data: null, status: Statuses.UNINITIALIZED})))
);

export function sessionReducer(state: SessionState | undefined, action: Action) {
  return session(state, action);
}
