import { LanguageComponent } from './../language/language.component';
import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { Observable, Subscription,of } from 'rxjs';
import { NotificationAlertsComponent } from '../notification-alerts/notification-alerts.component';
import { Router, Event, NavigationEnd } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Login, Logout } from 'src/app/modules/core/store/actions/auth.actions';
import { SidenavService } from '../../services/side-nav.service';
import { getUserDetails } from 'src/app/modules/core/store/selectors/user.selectors';
import { getSessionDetails } from 'src/app/modules/core/store/selectors/session.selectors';
import { Device } from '@capacitor/device';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddRecipientService } from 'src/app/screens/add-recipient/services/add-recipient.service';
// import { AddRecipientStepperComponent } from 'src/app/screens/add-recipient/components/add-stepper/add-stepper.component';
import { Location } from '@angular/common';
import { UserService } from '../../../core/services/user.service'
import { DomSanitizer } from '@angular/platform-browser';
import * as userActions from 'src/app/modules/core/store/actions/user.actions';
import { UtilsService } from '../../services/utils.service'
import { AddLinkingComponent } from 'src/app/screens/add-linking/add-linking.component';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from 'src/app/modules/core/services/local-storage.service';
import moment from 'moment';
import { SessionService } from 'src/app/screens/session/services/session.service';
import { DataService } from '../../services';
import { AbhaComponent } from 'src/app/screens/recipient/components/abha/abha.component';
import { DeclarePregnancyComponent } from 'src/app/screens/recipient/components/declare-pregnancy/declare-pregnancy.component';
import { DeclareDeathComponent } from 'src/app/screens/beneficiary/components/declare-death/declare-death.component';
import { PregnancyService } from 'src/app/screens/beneficiary/pregnancy/services/pregnancy.service';
import { BeneficiaryService } from 'src/app/screens/beneficiary/services/beneficiary.service';
import { DeclareAbortionComponent } from 'src/app/screens/recipient/components/declare-abortion/declare-abortion.component';
import { HomeDeliveryComponent } from 'src/app/screens/recipient/components/home-delivery/home-delivery.component';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnChanges, OnDestroy {
  @Input() template: string = null;
  @Input() pageData: any = {};
  @Input() hideSearch = false;
  userDetails$ = this.store$.pipe(select(getUserDetails));
  isMobileDevice = false;
  isSidenavOpened = false;
  sessDetails$: Observable<any>;
  currentRoute = '';
  imgUrl;
  userInfo: any;
  profilePic
  environment;
  villageNames: any;
  siteInfo: any = {};
  declaredDeath: Boolean = false;
  constructor(
    public router: Router,
    private store$: Store,
    public sideNav: SidenavService,
    public dialog: MatDialog,
    public addRecipientService: AddRecipientService,
    private cdr: ChangeDetectorRef,
    private loc: Location,
    private userService: UserService,
    private sanitizer: DomSanitizer,
    public util: UtilsService,
    public localStorage: LocalStorageService,
    private sessionServ: SessionService,
    public dataServ: DataService,
    public modalController: ModalController,
    public pregnancyService: PregnancyService,
    private beneficiaryService: BeneficiaryService,
    public prgService:PregnancyService
  ) {
    this.store$.pipe(select(getUserDetails))
      .subscribe(r => {
        this.userInfo = r;
        if (this.userInfo && this.userInfo?.ccp_type != environment.ARMED_FORCES_ID && this.userInfo?.role !== 'ASHA' && !this.userInfo?.hprId && (this.userInfo?.user_level == 'Facility' || this.userInfo?.user_level == 'Plan_Delivery_Manager')) {
          this.openLinking();
        }
      })
  }

  async ngOnInit() {
    this.environment = environment;
    const info = await Device.getInfo();
    this.isMobileDevice = info.operatingSystem === 'android' || info.operatingSystem === 'ios';
    this.sideNav.sidenavData.subscribe(r => {
      this.isSidenavOpened = r;
      this.cdr.detectChanges();
    });
    this.sessDetails$ = this.store$.pipe(select(getSessionDetails));

    this.userService.getProfilePic().subscribe(res => {
      if (res.type == 'text/html') {
        this.profilePic = true;
      } else {
        this.profilePic = false;
      }
      let blob = new Blob([res]);
      this.userInfo.profile_picture_url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(blob));
      this.cdr.detectChanges();
    })
    this.sessionServ.getValueInHeader.subscribe(res => {
      // res.subscribe(result=>{
      this.villageNames = res
      // })
    })

    this.dataServ.siteInfoData.subscribe(si => {
      this.siteInfo = {};
      if (si) {
        this.siteInfo = si;
      }
    })
    this.gettingBenDeath()
    // this.villageNames=this.localStorage.getItem('location_Id').village_name;
  }

  gettingBenDeath() {
    this.beneficiaryService.declaredDeathReplaySubject.subscribe(r => {
      if (r) {
        this.declaredDeath = true
      }
    })
  }

  async saveProfilePic(file) {
    const userId = this.userInfo.user_id;
    const postData = {
      file: file.fileObjectWithoutCompress,
      imageName: new Date().getTime(),
      userId
    }
    this.userService.saveProfilePic(postData)
      .subscribe(response => {
        this.userInfo.profile_picture_url = file.selectedFileBLOB;
        userActions.SetUser(this.userInfo);
        this.cdr.detectChanges();
        this.util.presentToastSuccess('Profile Picture Uploaded Successfully');

      }, error => {
        if (error.status === 400) {
          this.util.presentToastError('Format Not Supported');
        } else {
          this.util.presentToastError('Profile Picture Update Failed');
        }
      });
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.imgUrl = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  async navigate() {
    const currentUrl = this.router.url;
    if (currentUrl.match(/pregnancy/g)) {
      this.router.navigateByUrl('/dashboard')
    }else{
      this.loc.back()
    }
  }

  ngOnChanges(changes: SimpleChanges): void { }

  ionViewWillEnter() {
  }

  ngOnDestroy(): void {
  }

  logOut() {
    this.store$.dispatch(Logout());

  }

  toggleSideNav() {
    this.sideNav.toggle();
  }

  showHamberger() {
    if (this.sideNav.isOpened) {
    }
  }
  async openABHADilouge(benData) {
    if (this.util.getPlatformWidth() > 575) {

      const dialogRef = this.dialog.open(AbhaComponent, {
        height: "auto",
        width: "500px",
        hasBackdrop: true,
        disableClose: false,
        data: { ben_data: benData, isFromBenPage: true },
      })
      dialogRef.afterClosed().subscribe(result => {
      });
    } else {
      const modal = await this.modalController.create({
        component: AbhaComponent,
        cssClass: 'create-abha-modal',
        componentProps: {
          isFromBenPage: true,
          userData: benData,

        }
      });
      await modal.present();
      const { data } = await modal.onWillDismiss();
      // if (data?.refresh) {
      //   this.applyFilter()
      // }
    }
  }
  showPregnancy(benData) {
    if (benData.ben_gender == 2 && benData.ben_type_id != environment.static_ids.PregnantWomen && benData.ben_type_id != environment.static_ids.LactatingMother && !benData.is_pregnant_woman && !benData.hasOwnProperty('ben_death') && (benData.age >= 12 && benData.age <= 49)) {
      const currYr = parseInt(moment().format('YYYY'));
      if ((currYr - parseInt(benData.year)) > 11) {
        if (benData.date_of_delivery || benData.date_of_abortion) {
          let diffDate = benData.date_of_delivery ? benData.date_of_delivery : benData.date_of_abortion
          let diff = moment().diff(diffDate, 'months');
          if (diff < 1) {
            return false
          }
        }
        return true;
      }

    }
    return false;
  }
  // openDilouge(benData) {
  //   const dialogRef = this.dialog.open(DeclarePregnancyComponent, {
  //     height: "auto",
  //     width: "400px",
  //     hasBackdrop: false,
  //     disableClose: true,

  //     data: { ben_data: benData },
  //   })
  //   dialogRef.afterClosed().subscribe(result => {
  //     this.cdr.detectChanges();
  //   });
  //   // this.showDeclarePregnency=true;
  // }
  abortPregnant(bData) {
    this.router.navigate([`beneficiary/${bData?.beneficiary_id}/pregnancy/edit-pregnancy/${bData?.pregnancy_number}`],{ queryParams: { type: 'abort'}})

    // bData['pregnancy_number'] = 1
    // const dialogConfig = new MatDialogConfig();
    // dialogConfig.disableClose = true;
    // dialogConfig.autoFocus = true;
    // dialogConfig.width = '700px',
    //   dialogConfig.data = {
    //     benData: bData,
    //     fromBenPage: true,

    //   };
    // const dialogRef = this.dialog.open(DeclareAbortionComponent, dialogConfig);
    // dialogRef.afterClosed().subscribe(result => {
    //   if (result.event == 'success') {
    //     this.pregnancyService.refreshPregancyDetails.next(true)
    //     this.pregnancyService.medicalCondtionsbeneficary = null;
    //     if(this.router.url.match(/edit-pregnancy/g)){
    //       this.router.navigate([`beneficiary/${bData?.beneficiary_id}/pregnancy`])
    //     }
    //     this.cdr.detectChanges()
    //   }

    // }
    // );
  }
  declareDeath(bdata) {
    if(bdata?.is_pregnant_woman){
      this.router.navigate([`beneficiary/${bdata?.beneficiary_id}/pregnancy/edit-pregnancy/${bdata?.pregnancy_number}`],{ queryParams: { type: 'death'}})
      }else{
    const dilougeRef = this.dialog.open(DeclareDeathComponent, {
      height: "auto",
      width: "400px",
      hasBackdrop: false,
      disableClose: true,
      data: { ben_data: bdata },
    })
    dilougeRef.afterClosed().subscribe(result => {
      if (result?.event == 'Submitted') {
        this.declaredDeath = true;
        this.beneficiaryService.declaredDeathReplaySubject.next(true)

        bdata['ben_death'] = true;
        this.beneficiaryService.beneficiaryDataSubject.next(bdata)

      }
      this.cdr.detectChanges();
    });
  }
  }
  homeDelivery(bData){
    this.router.navigate([`beneficiary/${bData?.beneficiary_id}/pregnancy/edit-pregnancy/${bData?.pregnancy_number}`],{ queryParams: { type: 'delivery'}})
  }
  declareDeathEnable(bdata){
    let diff;
    if(bdata?.date_of_abortion){
      diff = moment().diff(bdata.date_of_abortion,'days')
    }
    if(bdata?.is_pregnant_woman || bdata?.age <= 5 || diff <= 7 ){
      return true
    }
    return false
  }
  // async homeDelivery(bdata){
  //   const modal = await this.modalController.create({
  //     component: HomeDeliveryComponent,
  //     cssClass: 'create-abha-modal',
  //     componentProps: { benDataForMobileView: bdata },
  //   });
  //   await modal.present();
  //   const  result  = await modal.onWillDismiss();
  //   if (result) {
  //   }
  //   // this.showDeclarePregnency=true;

  // }

  openLinking() {
    // this.addRecipientService.beneficiaryData = null;
    // this.addRecipientService.benSuccessData = null;
    const dialogRef = this.dialog.open(AddLinkingComponent,
      {
        position: { right: "0", top: "0" },
        //  height: "100%",
        width: "450px",
        hasBackdrop: true,
        disableClose: true,
        panelClass: 'linking-panel',
      });
  }

  openAddMember() {
    // this.addRecipientService.beneficiaryData = null;
    // this.addRecipientService.benSuccessData = null;
    // const dialogRef = this.dialog.open(AddRecipientStepperComponent,
    //   {
    //     position: { right: "0", top: "0" },
    //     height: "100%",
    //     width: "450px",
    //     hasBackdrop: true,
    //     disableClose: true,
    //     panelClass: 'dialog-side-panel',
    //   });
  }

  goBack() {
    // this.loc.back();
    let prevUrl = this.addRecipientService.getPreviousUrl();
    let urlNew = prevUrl.includes('member-');
    this.util.refreshMobileSessions.next('Refresh')
    if (prevUrl.match(/new-member/g)) {
      this.router.navigateByUrl('/dashboard')
    }
    else if ((prevUrl.match(/session/g)) || (prevUrl.match(/vaccination-rejected/g)) || urlNew == true) {
      this.router.navigateByUrl('/dashboard')
    } else {
      this.loc.back();
    }
  }
  onCommonPageBack(pageData) {
    let prevUrl = this.addRecipientService.getPreviousUrl();
    if (pageData.showSummary) {
      this.util.showSummaryVaccinationScreen.next(!pageData.showSummary)
      this.router.navigate([`new-beneficiary/record-previous-vaccination/${pageData?.benId}`])
    }
    else if(pageData.session_id){
      this.router.navigate([`session/${pageData.session_id}`])
    }
    else {
      this.router.navigateByUrl('/dashboard')
    }
  }

  openNewMember() {
    this.router.navigate(['/new-beneficiary'])
  }

  handleMissingImage(event) {
    (event.target as HTMLImageElement).src = 'assets/images/profilepic.png';
  }
  dateLabel(sess) {
    var date = moment(sess?.date_of_session).format('DD MMM YYYY')
    if (date == moment().format('DD MMM YYYY')) {
      return 'Today'
    }
    else if (date == moment().add(1, 'days').format('DD MMM YYYY')) {
      return 'Tomorrow'
    }
    else {
      return date;
    }
  }
}
