import { Component, ChangeDetectorRef, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AddLinkingService } from '../add-linking/services/add-linking.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EncDecService } from 'src/app/modules/shared/services/encryption-decryption.service';
import { DataService } from 'src/app/modules/shared/services';
import * as _ from 'lodash';
import { UtilsService } from 'src/app/modules/shared/services/utils.service';
import { Store } from '@ngrx/store';
import { Logout } from 'src/app/modules/core/store/actions/auth.actions';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-linking',
  templateUrl: './add-linking.component.html',
  styleUrls: ['./add-linking.component.scss'],
})
export class AddLinkingComponent implements OnInit {
  hprLink = environment.HPR_URL;
  linkingView: boolean = false;
  hprForm = new FormGroup({
    hprId: new FormControl('', [Validators.required])
  });
  hprOptionsForm: FormGroup;  
  getOTPView: boolean = false;
  hprView: boolean = true;
  hprOptions: boolean = false;
  HPRIList: any;
  transactionID: any;
  enterOTPView: boolean = false;
  hprID: any;
  constructor(
    private linkingService: AddLinkingService,
    private dataService: DataService,
    private encDecService: EncDecService,
    private util: UtilsService,
    private store$: Store,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog, 
    public dialogRef: MatDialogRef<[AddLinkingComponent]>, 
    @Inject(MAT_DIALOG_DATA) data
  ) {}

  ngOnInit() {    
    this.HPRIList = [
      {id: 1, name: 'Password'},
      {id: 2, name: 'OTP'},
      {id: 3, name: 'Aadhaar OTP'}
    ]
    

    this.hprOptionsForm = new FormGroup({      
      option_type: new FormControl('', [Validators.required]),
      hpri_password: new FormControl(''),
      hpri_otp: new FormControl('')
    }); 
  }
  submitHPRID(value: { [name: string]: any }) {   
    if(this.hprForm.valid) {      
      this.linkingService.searchHprId(value).subscribe((res: any) => {
        if(res) {
          this.hprID = this.encDecService.isEncrypted(res.hprIdNumber);
          this.hprView = false;
          this.hprOptions = true;
          this.hprOptionsForm.controls.option_type.setValue("1");
        }        
      },
      (error) => {
        console.log("error", error);
      }
      );
    }
    else {
      if(!this.hprForm.controls.hprId.valid) {
        this.hprForm.controls.hprId.markAsTouched();
      }      
    }
  }

  optionChange(value) {
    if(value == 2 || value == 3) {
      this.getOTPView = true;
    }else{
      this.getOTPView = false;
      this.enterOTPView = false;
    }
  }

  GetOTP() {
    let payload = {
    'otpType' : this.hprOptionsForm.controls.option_type.value == 2 ? 'MOBILE_OTP' : 'AADHAAR_OTP',
    'hprId' : this.hprID
    }
    this.linkingService.getabdmotp(payload).subscribe((res: any) => {
      this.transactionID = res.txnId;
      this.getOTPView = false;
      this.enterOTPView = true;
    },
    (err) => {
      this.getOTPView = true;
      this.enterOTPView = false;
    });
  }

  submitHPRIOptions() {
    let payload = {};
    if(this.hprOptionsForm.valid) {      
      if(this.hprOptionsForm.controls.option_type.value == 2 || this.hprOptionsForm.controls.option_type.value == 3) {
        payload = {
          "loginBy": this.hprOptionsForm.controls.option_type.value == 2 ? "MobileAuthToken" : "AadhaarOTP",
          "hprId": this.encDecService.setWithSpace(this.hprID),
          "txnId": this.transactionID,
          "otp": this.hprOptionsForm.controls.hpri_otp.value
        }
      } else {
        payload = {
          "loginBy": "Password",
          "hprId": this.encDecService.setWithSpace(this.hprID),
          "password": this.encDecService.set(this.hprOptionsForm.controls.hpri_password.value)
        }
      }
      this.linkingService.MinisryHPRLogin(payload).subscribe((res :any) => {        
        if(res.message == "Success") {
          //this.cssView = true;
         this.util.presentToastSuccess("Your HPRID is Authenticated successfully, system will log off automatically. Please login again ");
         this.dialog.closeAll();
         this.dialogRef.close({event: 'close'});         
         this.store$.dispatch(Logout());
         sessionStorage.clear();
        }                         
      },
      (err) => {        
        this.hprForm.reset();
        this.hprOptionsForm.reset();
        this.hprOptions = false;
        this.hprView = true;        
        //this.util.showErrorToast(err.error);
        console.log("err", err);
      });
    }
  }

}
