<mat-card>
  <mat-card-content>
    <ion-row>
      <ion-col size="12" size-xs="12" size-md="12" class="authenticate-view">
        <!-- <a class="back-btn" (click)="back()"><img src="../../../../../assets/images/back-arrow.svg" class="" alt="">Back</a> -->
        <h1 class="titleHeading p-0 ion-no-margin">ABDM HPR ID Authentication</h1>
        <ng-container *ngIf="hprView">
          <form [formGroup]="hprForm">
            <ion-row>
              <ion-col size-md="12" class="mt-15">
                <ion-label class="required">Enter your Healthcare Professional ID/Username</ion-label>
                <ion-item class="item-border ion-no-padding" lines="none">
                  <ion-input type="text" formControlName="hprId" required errorText=" ">
                  </ion-input>
                </ion-item>
                <!-- <ion-item class="errorlink" tooltip="Minimum 3 characters"
                *ngIf="(hprForm.get(['hprId']).touched && hprForm.get(['hprId']).hasError('required'))">
                Enter valid Healthcare Professional ID/Username *
              </ion-item>  -->
                <ion-label
                  *ngIf="!hprForm.controls.hprId.valid  && (hprForm.controls.hprId.hasError('required'))"></ion-label>
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col size="12" class="ion-hide-sm-down">
                <ion-button class="btn btnPrimary" (click)="submitHPRID(hprForm.value)">Submit</ion-button>
              </ion-col>
              <ion-col size="12" class="ion-hide-sm-up">
                <ion-button class="btn btnPrimary" expand="full"
                  (click)="submitHPRID(hprForm.value)">Submit</ion-button>
              </ion-col>
              <ion-col size="12">
                <p>Please Authenticate your HPR ID which is created from ABDM. If not kindly create your HPR ID from the
                  following link provided. </p>
                <p class="registernow_btn"><a [href]="hprLink" target="_blank">
                    <ion-icon name="phone-portrait-outline"></ion-icon>
                    Register Now
                  </a></p>
              </ion-col>
            </ion-row>
          </form>
        </ng-container>
        <ng-container *ngIf="hprOptions">
          <form [formGroup]="hprOptionsForm">
            <ion-row>
              <ion-col class="mt-15">
                <ion-label>Username/HPR ID</ion-label>
                <ion-item class="item-border ion-no-padding" lines="none">
                  <ion-input value="{{hprForm.controls.hprId.value}}" disabled errorText=" ">
                  </ion-input>
                </ion-item>
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col size="12" size-xs="12" size-md="12" class="mt-15">
                <mat-radio-group aria-label="Select an option" formControlName="option_type"
                  (change)="optionChange(hprOptionsForm.controls.option_type.value)">
                  <mat-radio-button *ngFor="let list of HPRIList" value="{{list.id}}" color="primary">{{list.name}}</mat-radio-button>
                  <!-- <mat-radio-button value="2">OTP</mat-radio-button>
                  <mat-radio-button value="3">Adhaar OTP</mat-radio-button> -->

                </mat-radio-group>
              </ion-col>
            </ion-row>

            <ion-row *ngIf="!getOTPView">
              <ion-col size="12" size-xs="12" size-md="12" class="mt-15">
                <ng-container *ngIf="!enterOTPView">
                  <ion-label>Password</ion-label>
                  <ion-item class="item-border ion-no-padding" lines="none">
                    <ion-input formControlName="hpri_password" for="hpri_password" name="hpri_password"
                      id="hpri_password" type="password" errorText=" ">
                    </ion-input>
                  </ion-item>
                  <ion-item class="errorlink" tooltip="Please Enter password"
                    *ngIf="hprOptionsForm.get(['hpri_password']).touched && hprOptionsForm.get(['hpri_password']).hasError('required')">
                    Please Enter Password
                  </ion-item>
                </ng-container>
                <ng-container *ngIf="enterOTPView">
                  <ion-label>Enter OTP</ion-label>
                  <ion-item class="item-border ion-no-padding" lines="none">
                    <ion-input matInput formControlName="hpri_otp" for="hpri_otp" name="hpri_otp" id="hpri_otp"
                      type='text' errorText=" ">
                    </ion-input>
                  </ion-item>
                  <ion-item class="errorlink" tooltip="Please Enter password"
                    *ngIf="!hprOptionsForm.get(['hpri_otp']).valid">
                    Please Enter OTP
                  </ion-item>
                </ng-container>
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col size="12" size-xs="12" size-md="3" class="mt-15">
                <ion-button class="btn btnPrimary" *ngIf="getOTPView" (click)="GetOTP()">
                  Get OTP
                </ion-button>
                <ion-button class="btn btnPrimary" *ngIf="!getOTPView" (click)="submitHPRIOptions()">
                  Authenticate
                </ion-button>
              </ion-col>

            </ion-row>
          </form>
        </ng-container>
      </ion-col>
    </ion-row>
  </mat-card-content>
</mat-card>
