import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef, SimpleChanges, Input, OnDestroy, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { DataService } from 'src/app/modules/shared/services';
import { UtilsService } from 'src/app/modules/shared/services/utils.service';
import * as _ from 'lodash';
import * as moment from 'moment';

import { Device } from '@capacitor/device';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AddRecipientService } from '../../services/add-recipient.service';
import { SessionService } from 'src/app/screens/session/services/session.service';
import { LookUpService } from 'src/app/modules/shared/services/look-up.service';
import { ImageUploadService } from '../../../../modules/shared/services/image-upload.service';
import { EncDecService } from 'src/app/modules/shared/services/encryption-decryption.service'
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { RecipientService } from 'src/app/screens/recipient/services/recipient.service';


@Component({
  selector: 'app-add-recipient-member-vaccination-info',
  templateUrl: './member-vaccination-info.page.html',
  styleUrls: ['./member-vaccination-info.page.scss'],
})
export class MemberVaccinationInfoPageComponent {
  benfData: any = null;
  benId;
  sessionId;
  sessionDetails=[];
  benificiaryData;
  stateData:any;
  isDataLoaded :boolean= false;
  constructor(
    public dataService: DataService,
    private router: Router,
    private loc: Location,
    private addRecipientService: AddRecipientService,
    public util: UtilsService,
    public actRoute:ActivatedRoute,
    public sessionService:SessionService,
    public recipientService:RecipientService,
    public cdr:ChangeDetectorRef
  ) {
    this.benId = this.actRoute.snapshot.params.benID
    this.sessionId = this.actRoute.snapshot.params.sessionID;
  }

  ngOnInit(){
    this.loadData();
  }


  ionViewWillEnter(){
    this.loadData();
  }
  loadData(){
    this.isDataLoaded = false;
    this.stateData = this.loc.getState();
    if(this.stateData && this.stateData.data){
      this.addRecipientService.benSuccessDetails = this.stateData.data;
      this.addRecipientService.beneficiaryDetails = this.stateData.data;
      // this.benfData = this.stateData.data;
      this.isDataLoaded = true;
      this.cdr.detectChanges();
    }
    if(!this.stateData.data &&this.benId && this.sessionId ){
     
      this.onRefreshedData();
    }
  }
 async onRefreshedData(){

  this.sessionDetails = await this.sessionService.getSessionInfo(this.sessionId).toPromise();
  this.benificiaryData = await this.recipientService.getBeneficairyDetails(this.benId).toPromise();
        let obj ={
          ... this.sessionDetails,
          ... this.benificiaryData,
          ...{ isBenfAddedToSession: true }
        }
        this.addRecipientService.benSuccessDetails = obj;
        this.addRecipientService.beneficiaryDetails = obj;
        // this.benfData = obj;
        this.isDataLoaded = true;
        this.cdr.detectChanges();
  }
  onStepChange(val: any) {
    if (val === 'next') {
      if(this.benId && this.sessionId){
        this.router.navigate([`/new-member/vaccinate-member/${this.benId}/${this.sessionId}`]);
      }else{
        this.router.navigate(['/new-member/vaccinate-member']);
      }
    } else {
      this.loc.back();
    }
  }

  

  closeDialog() {
  }

}
