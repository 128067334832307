import { SharedModule } from './../../modules/shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { VerificationTypeComponent } from './components/verification-type/verification-type';
import { FacialComponent } from './components/facial/facial';
import { DemographicComponent } from './components/demographic/demographic';
import { BiometricComponent } from './components/biometric/biometric';
import { AadharOtpComponent } from './components/aadhar-otp/aadhar-otp';
// import { QRScanner } from '@ionic-native/qr-scanner/ngx';
import { BiometricRdService } from './components/biometric/biometric.service';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    SharedModule
  ],
  declarations: [
    VerificationTypeComponent,
    FacialComponent,
    DemographicComponent,
    BiometricComponent,
    AadharOtpComponent,
  ],
  exports: [
    VerificationTypeComponent,
    FacialComponent,
    DemographicComponent,
    BiometricComponent,
    AadharOtpComponent,
  ],
  providers:[
    // QRScanner,
    BiometricRdService,
  ]
})
export class AadharVerificationModule {}
