import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DataService } from '../../services';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-guardian-abha-consent',
  templateUrl: './guardian-abha-consent.component.html',
  styleUrls: ['./guardian-abha-consent.component.scss'],
})
export class GuardianAbhaConsentComponent implements OnInit {
  @Output() isConsentAgreed: EventEmitter<boolean> = new EventEmitter();
  itemsObject = [1,2,3,4,5,6,7];
  selected3 = [];
  @Input() benfData: any = {};
  isBottomSheet= false;
  constructor(
    public dataServ: DataService,
    private _bottomSheetRef: MatBottomSheetRef<GuardianAbhaConsentComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ){}
  ngOnInit(): void {
    if(this.benfData && Object.keys(this.benfData).length == 0){
        if(this.data.benfData){
          this.isBottomSheet= true;
          this.benfData = this.data.benfData;
        }
    }else{
      this.isBottomSheet= false;
    }
    console.log('benfData', this.benfData);
  }
 
  toggle(item,event: MatCheckboxChange) {
     if (event.checked) {
      this.selected3.push(item);
    } else {
      const index = this.selected3.indexOf(item);
      if (index >= 0) {
        this.selected3.splice(index, 1);
      }
    }
    this.isConsentAgreed.emit(this.isChecked());
   console.log(this.isChecked());
  }

  exists(item) {
    return this.selected3.indexOf(item) > -1;
  };

  isIndeterminate() {
    return (this.selected3.length > 0 && !this.isChecked());
  };

  isChecked() {
    return this.selected3.length === this.itemsObject.length;
  };



  toggleAll(event: MatCheckboxChange) { 
    if ( event.checked ) {
      this.itemsObject.forEach(row => {
        this.selected3.push(row)
      });
    } else {
      this.selected3.length = 0 ;
    }
    console.log('@###################', this.isChecked());
    this.isConsentAgreed.emit(this.isChecked());
  }

  dismissModal() {
    this._bottomSheetRef.dismiss(this.isChecked());
  }
  
}
