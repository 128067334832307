<ion-grid [formGroup]='parentForm'>
  <ion-row class="PersonDetails">
    <ion-col size="12" *ngIf="benData.aadhaarNumber">
      <ion-label>
        <ion-text>{{'Aadhaar UID Number' | translate}}</ion-text>
        <h3>{{benData.aadhaarNumber | benfNumberFormat}}</h3>
      </ion-label>
    </ion-col>
    <ion-col size="12" *ngIf="benData?.photo_id_type && benData?.photo_id_type !='Aadhaar Card'">
      <ion-label>
        <ion-text>{{benData?.photo_id_type | translate}}</ion-text>
        <h3>{{util.mask(benData?.photo_id_number)}}</h3>
      </ion-label>
    </ion-col>
    <ion-col size="12" *ngIf="benData?.aadharData?.name">
      <ion-label>
        <ion-text>{{'Name as on Aadhaar UID' | translate}}</ion-text>
        <h3>{{benData.aadharData.name}}</h3>
      </ion-label>
    </ion-col>
    <ion-col size="12" *ngIf="benData?.ben_name">      
      <ion-label>
        <ion-text>{{'Name' | translate}}</ion-text>
        <h3>
          {{benData.ben_name}}
        <span *ngIf="benData?.verifiedBy!='Manual'">
          <ion-icon name="checkmark-done-circle-outline" *ngIf="adhaarDataMatch?.name"></ion-icon>
          <ion-icon name="close-circle-outline" *ngIf="!adhaarDataMatch?.name"></ion-icon>
        </span>
        </h3>
      </ion-label>
    </ion-col>
    <ion-col size="12" *ngIf="benData?.beneficiary_gender">      
      <ion-label>
        <ion-text>{{'Gender' | translate}}</ion-text>
        <h3>
          {{benData?.beneficiary_gender | translate}}
        <span *ngIf="benData?.verifiedBy!='Manual'">
          <ion-icon name="checkmark-done-circle-outline" *ngIf="adhaarDataMatch?.gender"></ion-icon>
          <ion-icon name="close-circle-outline" *ngIf="!adhaarDataMatch?.gender"></ion-icon>
        </span>
        </h3>
      </ion-label>
    </ion-col>
    <ion-col size="12" *ngIf="!benData?.beneficiary_gender && benData?.aadharData?.gender">
      <ion-label>
        <ion-text>{{'Gender' | translate}}</ion-text>
        <h3>{{benData.aadharData.gender == 'M' ? 'Male' : benData?.aadharData.gender == 'F' ? 'Female' : 'Other'}}</h3>
      </ion-label>
    </ion-col>
    <ion-col size="12" *ngIf="benData?.child_weight && (benData?.ben_type_id == environment.static_ids.Infant_ID|| benData?.ben_type_id == environment.static_ids.NewBornBaby)">
      <ion-label>
        <ion-text>{{'Child Weight' | translate}}</ion-text>
        <h3> {{benData?.child_weight}} Kg</h3>
      </ion-label>
    </ion-col>
    <ion-col size="12" *ngIf="!benData?.child_weight && (benData?.ben_type_id == environment.static_ids.Infant_ID || benData?.ben_type_id == environment.static_ids.NewBornBaby)">
      <ion-label>{{'Child Weight at Birth In KGs' | translate}}</ion-label>
      <ion-item class="item-border ion-no-padding" lines="none">
        <ion-input type="number" step="0.01" formControlName="child_weight" 
        [placeholder]="'Child Weight at Birth In KGs' | translate" errorText=" " ></ion-input>
      </ion-item>
      <ion-label *ngIf="this.parentForm?.get('child_weight')?.errors?.pattern" class="error-validation">
        <p>{{'Please enter valid child weight' | translate}}</p>
      </ion-label>
    </ion-col>
    <ion-col size="12" *ngIf="benData?.father_name && (benData?.ben_type_id == environment.static_ids.Infant_ID || benData?.ben_type_id == environment.static_ids.NewBornBaby)">
      <ion-label>
        <ion-text>{{'Father Name' | translate}}</ion-text>
        <h3>{{benData?.father_name}}</h3>
      </ion-label>
    </ion-col>
    <ion-col size-md="12" *ngIf="!benData?.father_name && (benData?.ben_type_id == environment.static_ids.Infant_ID || benData?.ben_type_id == environment.static_ids.NewBornBaby)">
      <ion-label>{{'Father Name' | translate}}</ion-label>
      <ion-item class="item-border ion-no-padding" lines="none">
        <ion-input formControlName="father_name" [placeholder]="'Enter Father’s Name' | translate" type="text" maxlength="50" appInputTrim
          [appRangeLength]='[2, 50]' appInputChar='username' appConvertToUpperCase errorText=" " ></ion-input>
      </ion-item>
      <ion-label
        *ngIf="!parentForm?.controls?.father_name?.valid  && (parentForm?.controls?.father_name?.hasError('inputcharvalidator'))"
        class="error-validation">
        <p>{{'Must contain only English Alphabets (“a-z” and “A-Z”), Dot “.”, Hyphen “-”, Open parenthesis “(“ and Close parenthesis “)”' | translate}}</p>
      </ion-label>
      <ion-label
        *ngIf="!parentForm?.controls?.father_name?.valid  && (!parentForm?.controls?.father_name?.hasError('inputcharvalidator') && parentForm?.controls?.father_name?.hasError('rangeLength'))"
        class="error-validation">
        <p>{{'Must be between 2 to 50 Characters' | translate}}</p>
      </ion-label>
    </ion-col>
    <ng-container *ngIf="benData?.ben_type_id == environment.static_ids.PregnantWomen &&  benData?.aadharData.gender == 'F'">
      <ion-row>
        <ion-col size-md="12">
          <app-ion-custom-datepicker style="width: 100%;" label="Date of Last Menstrual Cycle" formKey='lmp_date'
            [parentForm]="parentForm" [class]="'noclass'" placeholder="DD/MM/YYYY" [minDate]="minLMPDate"
            [maxDate]="maxLMPDate" (dateValue)="lmpDateEvent($event)">
          </app-ion-custom-datepicker>
        </ion-col>
      </ion-row>
      <ion-row class="bloodGroup">
        <ion-col size-md="12">
          <ion-label>{{'Blood Group' | translate}}</ion-label>
          <mat-form-field appearance="outline" class="ion-margin-vertical matDropdown">
            <mat-select placeholder="Select from the list" formControlName="blood_group">
              <mat-option *ngFor="let group of bloodGroupArray" [value]="group.groupType">{{group.groupType}}</mat-option>
            </mat-select>
          </mat-form-field>
        </ion-col>
      </ion-row>
      <ion-row>
        <app-ion-custom-datepicker class="not-required" style="width: 100%;" label="Date of Last Pregnancy Outcome"
                          formKey='previous_pregnancy_year' [parentForm]="parentForm" [class]="'noclass'" placeholder="DD/MM/YYYY"
                          [minDate]="minDate" [maxDate]="maxPreviousYear">
        </app-ion-custom-datepicker>
        <!-- <ion-col size-md="12" size="12">
            <ion-label>Previous Pregnancy Year</ion-label>
            <ion-item class="item-border ion-no-padding" lines="none"> 
            <ion-input type="tel" [appMin]="minDate" [appMax]="maxPreviousYear" maxlength="4" minlenth="4" formControlName="previous_pregnancy_year" errorText=" " ></ion-input>
            </ion-item>
          </ion-col> -->
      </ion-row>
    </ng-container>
    <!-- <ion-col size="12" *ngIf="!benData?.year && benData?.aadharData?.yearOfBirth">
        <ion-label>Year of Birth</ion-label>
          <ion-title>
            {{benData.aadharData.yearOfBirth}}
          </ion-title>
      </ion-col> -->
    <!-- <ion-col size-md="12" *ngIf="!benData?.dob" >
          <app-ion-custom-datepicker style="width: 100%;" label="Date of Birth" formKey='dob' [parentForm]="parentForm"
            [class]="'noclass'" placeholder="DD/MM/YYYY" [minDate]="minDob" [maxDate]="maxDob">
          </app-ion-custom-datepicker>
        </ion-col> -->
    <!-- <ion-col size="12"
      *ngIf="benData?.verifiedBy=='Manual' && (benData?.added_from == 'self' || (benData?.ben_type_id == 5 && benData?.added_from !='onspot'))">
      <ion-label>{{'Last 4 digit of Reference ID' | translate}}</ion-label>
      <ion-item class="item-border ion-no-padding" lines="none">
        <ion-input placeholder="Enter Last 4 digit of Reference ID " type="tel" numbersOnly   formControlName="reference_id"
          minlength="4" maxlength="4" type="tel" errorText=" " ></ion-input>
      </ion-item>
    </ion-col> -->
    <ion-col size="12"  *ngIf="benData?.beneficiary_id">
      <!--*ngIf="benData?.vaccination_doses_due >= 2"-->
      <!-- <ion-label>{{'Year of Birth' | translate}}</ion-label> -->
      <ion-label>{{'Year of Birth' | translate}}</ion-label>
      <ion-item class="item-border ion-no-padding error-validation" lines="none">
        <ion-input [placeholder]="'Year of Birth' | translate" appIntegerInput formControlName="yobValidation" minlength="4"
          maxlength="4" type="tel" errorText=" " ></ion-input>
      </ion-item>
    </ion-col>
    <ion-col size="12"  *ngIf="environment?.pvtg_enabled">
      <ion-label class="required">{{'PVTG' | translate}}</ion-label>
      <div class="ion-margin-vertical">
        <mat-radio-group formControlName="is_pvtg"  aria-label="Select an option">
          <mat-radio-button color="primary"  value="Yes">{{'Yes' | translate}}</mat-radio-button>
            <mat-radio-button color="primary"  value="No">{{'No' | translate}}</mat-radio-button>
        </mat-radio-group>
      </div>
      <ion-label class="error-validation"
        *ngIf="parentForm.controls.is_pvtg.touched && parentForm.controls.is_pvtg.errors?.required">
        <p>{{'Please Select PVTG' | translate}}</p>
      </ion-label>
    </ion-col>
    <!-- <ion-col size="12" *ngIf="benData?.year && benData.aadharData">
        <ion-label>Year of Birth</ion-label>
            <ion-title>****
              <ion-icon name="checkmark-done-circle-outline"  *ngIf="adhaarDataMatch?.year"></ion-icon>
            <ion-icon name="close-circle-outline"  *ngIf="!adhaarDataMatch?.year"></ion-icon>
            </ion-title>
      </ion-col> -->
      <ng-container *ngIf="benData?.verifiedBy =='Manual'">
        <ion-col size="12">
          <ion-label class="required">
              {{'OTP has been sent to mobile number' | translate}} {{benData?.ben_mobile_number | lastFourDigits}}
          </ion-label>
          <ion-item class="item-border ion-no-padding" lines="none">
              <ion-input autocomplete="off" formControlName='otp' type='tel' maxlength="6" appIntegerInput [appRange]='[000001,999999]' [appMin]='111111' [appMax]='999999' placeholder="- - - - - -" errorText=" " ></ion-input>
              <ion-button (click)="resendOtp()" [disabled]="timer" class="linkBtn" expand="full" fill="outline">
                  {{'Resend' | translate}}
              </ion-button>
          </ion-item>
          <ion-label *ngIf="timer">{{timer + ' sec '}}</ion-label>
          <ion-label *ngIf="!parentForm.controls.otp.valid  && (parentForm.controls.otp.hasError('required'))" class="error-validation ">
              <p>{{'Please Enter OTP' | translate}}</p>
          </ion-label>
          <ion-label *ngIf="!parentForm.controls.otp.valid  && (parentForm.controls.otp.hasError('range'))"
            class="error-validation ">
            <p>{{'Must be 6 digits' | translate}}</p>
          </ion-label>
          <ion-label class="orLabel" *ngIf="showReferenceId">
            <ion-text>or</ion-text>
          </ion-label>
          <ion-label *ngIf="showReferenceId">{{ 'Last Four Digits Of Reference Id' | translate }}</ion-label>
          <ion-item *ngIf="showReferenceId" class="item-border ion-no-padding error-validation" lines="none">
            <ion-input [placeholder]="'Reference ID' | translate" appIntegerInput formControlName="referenceId" minlength="4"
              maxlength="4" type="tel" errorText=" ">
            </ion-input>
          </ion-item>
          </ion-col>
          </ng-container>
          </ion-row>
</ion-grid>
