import { Component, Inject, OnInit } from '@angular/core';
import { DataService } from '../services';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DashboardPage } from 'src/app/screens/dashboard/dashboard.page';
import { UtilsService } from '../services/utils.service';

@Component({
  selector: 'app-unlink-hpr-id',
  templateUrl: './unlink-hpr-id.component.html',
  styleUrls: ['./unlink-hpr-id.component.scss'],
})
export class UnlinkHprIdComponent implements OnInit {
  HprId:any;
  constructor(public dataService:DataService,public dialogRef: MatDialogRef<DashboardPage>, @Inject(MAT_DIALOG_DATA ) public HPRId: any,public util:UtilsService) {
  this.HprId = HPRId?.HPRID
  }

  ngOnInit() {
  }
  unlink(){
    this.dataService.unlinkHPRId().subscribe(res=>{
      this.util.presentToastSuccess(res['message']);
      this.cancel('Success')
    })
  }
  cancel(data = 'close'){
    this.dialogRef.close(data);

  }

}
