<div class="sidebar-header noMobile">
  <h1>
    <ion-icon *ngIf="vaccinationStatusData?.isRejected == true" (click)="gotoStep('prev')" class="custom-arrow-left">
    </ion-icon>
    {{(vaccinationStatusData?.isRejected == false ? 'Member Vaccination' : 'Reject Vaccination') | translate}}
    <ion-icon (click)="closeDialog()" class="custom-close"></ion-icon>
  </h1>
</div>
<ion-header class="noDesktop mobile-header-white">
  <ion-toolbar>
    <ion-buttons slot="start" *ngIf="vaccinationStatusData?.isRejected == true">
      <ion-back-button (click)="gotoStep('prev')" text="" icon="arrow-back-outline"></ion-back-button>
    </ion-buttons>
    <h4 class="heading">{{(vaccinationStatusData?.isRejected == false ? 'Member Vaccination' : 'Reject Vaccination') | translate}}
      <ion-icon (click)="closeDialog()" class="custom-close ion-float-right"></ion-icon>
    </h4>
  </ion-toolbar>
  
</ion-header>
<ng-container *ngIf="vaccinationStatusData?.isRejected == true">
  <form [formGroup]='reasonForm'>
    <div class="sidebar-content">
      <ion-grid class="sidebar-inner-content">
        <ion-row>
          <ion-col size="12">
            <app-ion-custom-select [label]="'Choose reason for rejection' | translate" formKey='reason' [parentForm]="reasonForm"
              [arrObj]="vaccinationNotDoneReasons" [arrKey]="'reasonId'" [arrKeyVal]="'reasonName'"
              (onSelect)="onSelectReasonType($event)">
            </app-ion-custom-select>
          </ion-col>
          <!-- <ion-col>
                        <ion-label *ngIf="reasonForm.get('reason').touched && reasonForm.get('reason')?.errors?.required" class="error-validation ">
                            <p>{{'Reason is Required'}}</p>
                        </ion-label>
                    </ion-col> -->
        </ion-row>
        <div *ngIf="reasonForm.get('reason').value === 'VNDR_003'">
          <ion-row>
            <label>{{'Comments' | translate}}</label>
          </ion-row>
          <ion-row>
            <ion-col size="12">
              <mat-form-field appearance="outline" class="matDropdown ion-margin-vertical">
                <textarea matInput [placeholder]="'Explain the reason for rejection' | translate" formControlName="comments"
                  class="required"></textarea>
              </mat-form-field>
            </ion-col>
            <!-- <ion-col>
      <ion-label *ngIf="isformSubmitted  && reasonForm.get('comments')?.errors?.required" class="error-validation ">
        <p>{{'Please Enter Comments'}}</p>
    </ion-label>
    </ion-col> -->
          </ion-row>

        </div>
        <ion-row>
          <ion-col>



            <span class="checkboxSpan">
              <mat-checkbox color="primary"
                [ngClass]="{'checkbox-required': reasonForm.get('reject_consent').touched && reasonForm.get('reject_consent')?.errors?.required}"
                formControlName="reject_consent" required>
              </mat-checkbox>
              <p>{{'I understand that the beneficiary would not be able to take the vaccine in this session' | translate}}</p>
            </span>







          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size-md="12" class="ion-text-end">
            <ion-button class="primaryBtn" shape="round" (click)="onConfirmRejection()">{{'Reject Member' | translate}}
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
    <!-- <div class="sidebar-footer">
            <ion-row>
                <ion-col>
                    <mat-checkbox [ngClass]="{'checkbox-required': reasonForm.get('reject_consent').touched && reasonForm.get('reject_consent')?.errors?.required}" formControlName="reject_consent" required>I understand that the beneficiary would not be able to take the vaccine in this session
                    </mat-checkbox>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col size-md="12">
                    <ion-button class="primaryBtn" expand="full" shape="round" (click)="onConfirmRejection()">Reject Member
                    </ion-button>
                </ion-col>
            </ion-row>
        </div> -->
  </form>
</ng-container>
<ng-container *ngIf="vaccinationStatusData?.isRejected == false">
  <div class="sidebar-content">
    <ion-grid class="sidebar-inner-content member-success">
      <ion-row>
        <ion-col size="12" class="ion-text-center">
          <ion-img src="assets/images/vaccinated-success.svg" class="verified-logo"></ion-img>
          <p>{{'Member' |translate}} {{(vaccinationStatusData?.isPastDated ? 'Details Updated' : 'Vaccinated') | translate}} {{'Successfully' | translate}}</p>
        </ion-col>
        <!-- <ion-col size="12" class="ion-text-center">
                    <ion-title>Member Vaccinated Successfully</ion-title>
                </ion-col> -->
      </ion-row>

      <ion-card class="memberSuccessCard" *ngIf="vaccinationStatusData?.session_type_name != 'UIP'">
        <ion-card-content class="ion-no-padding">
          <ion-grid>
            <ion-row>
              <ion-col size="12">
                <ion-text>
                  <ion-label>{{'Name' | translate}}</ion-label>
                  <h5>{{vaccinationStatusData?.ben_name}}</h5>
                </ion-text>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="6">
                <ion-text>
                  <ion-label>{{'Vaccine Name' | translate}}</ion-label>
                  <h5>{{vaccinationStatusData?.material_name}}</h5>
                </ion-text>
              </ion-col>
              <ion-col size="6">
                <ion-text>
                  <ion-label>{{'Vaccine Dose' | translate}}</ion-label>
                  <h5>{{(vaccinationStatusData?.vaccination_doses_due == vaccinationStatusData?.dose_scheduled + 1 ) ?
                    'Precaution Dose': 'Dose' + vaccinationStatusData?.vaccination_doses_due }}</h5>
                </ion-text>
              </ion-col>
              <ion-col size="6">
                <ion-text>
                  <ion-label>{{'Batch Number' | translate}}</ion-label>
                  <h5>{{vaccinationStatusData?.batch_number}}</h5>
                </ion-text>
              </ion-col>
              <ion-col size="6">
                <ion-text>
                  <ion-label>{{'Date of vaccination' | translate}}</ion-label>
                  <h5> {{currentTime | displayDateFormat}}
                  </h5>
                </ion-text>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="6">
                <ion-text>
                  <ion-label>{{'Time' | translate}}</ion-label>
                  <h5>{{currentTime | date: 'hh:mm a'}}</h5>
                </ion-text>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card-content>
      </ion-card>
      <ion-card class="memberSuccessCard" *ngIf="vaccinationStatusData?.session_type_name === 'UIP'">
        <ion-card-content class="ion-no-padding">
          <ion-row>
            <ion-col size="12">
              <ion-text>
                <ion-label>{{'BENEFICIARY REFERENCE ID' | translate}}</ion-label>
                <h5>{{vaccinationStatusData.beneficiary_reference_id}}</h5>
              </ion-text>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="6">
              <ion-text>
                <ion-label>{{'Name' | translate}}</ion-label>
                <h5>{{vaccinationStatusData.ben_name}}</h5>
              </ion-text>
            </ion-col>
            <ion-col size="6">
              <ion-text>
                <ion-label>{{'Age' | translate}}</ion-label>
                <h5>{{vaccinationStatusData.dob| age}}</h5>
              </ion-text>
            </ion-col>
          </ion-row>
         
            <ion-row  *ngFor="let material of vaccinationStatusData?.materialDetails">
              <ion-col size="12">
                <ion-text>
                  <ion-label>{{'Vaccine Name' | translate}}</ion-label>
                  <h5>{{material.material_name | translate}}</h5>
                </ion-text>
              </ion-col>
            </ion-row>
            <ion-row  *ngFor="let material of vaccinationStatusData?.materialDetails">
              <ion-col size="6">
                <ion-text>
                  <ion-label>{{'Vaccine Dose' | translate}}</ion-label>
                  <!-- <h5> {{material.material_id == environment.static_ids.OPV_Vaccine ? 'OPV' : 'Dose'}} <span
                                  *ngIf="material.material_id ==  environment.static_ids.OPV_Vaccine">{{material.material_dose
                                  -1}}</span>
                                <span
                                  *ngIf="material.material_id !=  environment.static_ids.OPV_Vaccine">{{material.material_dose}}</span>
                              </h5> -->
                  <h5>{{labelName(material)}}</h5>
                </ion-text>
              </ion-col>
              <ion-col size="6" *ngIf="material?.prev_vaccination_date">
                <ion-text>
                  <ion-label>{{'Vaccine Received' | translate}}</ion-label>
                  <h5>{{material.prev_vaccination_date | displayDateFormat}}</h5>
                </ion-text>
              </ion-col>
              <ion-col size="6" *ngIf="!material?.prev_vaccination_date">
                <ion-text>
                  <ion-label>{{'Date of vaccination' | translate}}</ion-label>
                  <h5 *ngIf="vaccinationStatusData.isPastDated">N/A</h5>
                  <h5 *ngIf="!vaccinationStatusData.isPastDated">{{currentTime | displayDateFormat}}</h5>
                  <!-- <h5> {{currentTime | displayDateFormat}}</h5> -->
                </ion-text>
              </ion-col>
            </ion-row>
        
        </ion-card-content>
      </ion-card>
      <!-- <ion-card class="memberSuccessCard" *ngIf="vaccinationStatusData?.session_type_name == 'UIP'">
                <ion-item class="VaccDetailsHeader">
                    <ion-label>Vaccine Name</ion-label>
                    <ion-note class="ion-padding-vertical" slot="end">
                        Vaccine Received
                    </ion-note>
                </ion-item>
                <ion-item *ngFor="let material of vaccinationStatusData?.materialDetails">
                    <ion-label>{{material.material_name}}</ion-label>
                    <ion-note class="ion-padding-vertical" slot="end">
                        Dose {{material.material_dose}}
                    </ion-note>
                </ion-item>
            </ion-card> -->

    </ion-grid>

  </div>
  <ion-row *ngIf="vaccinationStatusData?.isPastDated">
    <ion-col size-md="12" class="ion-text-center ion-margin-vertical">
      <ion-button color="medium" class="linkBtn" fill="outline" (click)="addMoreVaccine()">{{'Add More Vaccination' | translate}}
      </ion-button>
    </ion-col>
  </ion-row>
  <ion-row *ngIf="!vaccinationStatusData?.isPastDated">
    <ion-col size-md="12" class="ion-text-center ion-margin-vertical">
      <ion-button (click)="downloadCertificate()" [disabled]="fileDownloadInProgress" type="button" class="primaryBtn"
        shape="round" color="primary">
        <ion-spinner name="lines-small" *ngIf="fileDownloadInProgress"></ion-spinner>
        {{'Download Acknowledgement' | translate}}
      </ion-button>
    </ion-col>
  </ion-row>
  <!-- <div class="sidebar-footer">
        <ion-row *ngIf="vaccinationStatusData?.isPastDated">
            <ion-col size-md="12">
                <ion-button color="medium" class="linkBtn" expand="full" fill="outline" (click)="addMoreVaccine()">Add More Vaccination
                </ion-button>
            </ion-col>
        </ion-row>
        <ion-row *ngIf="!vaccinationStatusData?.isPastDated">
            <ion-col size-md="12">
                <ion-button (click)="downloadCertificate()" [disabled]="fileDownloadInProgress" type="button" class="primaryBtn" shape="round" color="primary" expand="full">
                    <ion-spinner name="lines-small" *ngIf="fileDownloadInProgress"></ion-spinner>
                    Download Acknowledgement
                </ion-button>
            </ion-col>
        </ion-row>
    </div> -->
</ng-container>
