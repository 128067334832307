<div class="sidebar-header noMobile">
  <h1>
    <!-- <ion-icon (click)="gotoStep('prev')" class="custom-arrow-left"></ion-icon> -->
    {{'Member Verification' | translate}}
    <ion-icon (click)="closeDialog()" class="custom-close"></ion-icon>
  </h1>
</div>
<ion-header class="noDesktop mobile-header-white">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button (click)="gotoStep('prev')" text="" icon="arrow-back-outline"></ion-back-button>
    </ion-buttons>
    <h4 class="heading">{{'Member Verification' | translate}}</h4>
  </ion-toolbar>
</ion-header>
<div class="sidebar-content">
  <ion-grid class="sidebar-inner-content">
    <ion-row>
      <ion-col size="12" class="ion-text-center">
        <!-- <ion-img src="assets/images/verified-success.svg" class="verified-logo"></ion-img> -->
        <ion-img src="assets/images/icon-success-tick.svg" class="verified-logo"></ion-img>

      </ion-col>
      <ion-col size="12" class="ion-text-center">
        <p>{{'Member Verified Successfully' | translate}}</p>
      </ion-col>
    </ion-row>
    <ion-card class="memberSuccessCard">
      <ion-card-content class="ion-no-padding">
        <ion-grid>
          <ion-row>
            <ion-col size="12">
              <ion-text>
                <ion-label>{{'Name' | translate}}</ion-label>
                <h5>{{verifiedData?.ben_name}}</h5>
              </ion-text>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="6">
              <ion-text>
                <ion-label>{{'Age / Gender' | translate}}</ion-label>
                <h5>
                  <span *ngIf="verifiedData.dob">{{verifiedData.dob | age }}</span><span
                    *ngIf="!verifiedData.dob && verifiedData.year">{{verifiedData.year | age:'Y' }}</span> /
                  {{verifiedData?.beneficiary_gender | translate}}
                </h5>
              </ion-text>
            </ion-col>
            <ion-col size="6">
              <ion-text class="ion-text-right">
                <ion-label>{{'Mobile Number' | translate}}</ion-label>
                <h5>{{verifiedData?.ben_mobile_number }}</h5>
              </ion-text>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="6">
              <ion-text>
                <ion-label>{{'Photo ID' | translate}}</ion-label>
                <h5>{{verifiedData?.photo_id_type | translate}} / {{verifiedData?.photo_id_number |slice:-4}}</h5>
              </ion-text>
            </ion-col>
            <ion-col size="6">
              <ion-text class="ion-text-right">
                <ion-label class="ion-text-right">{{'Category' | translate}}</ion-label>
                <h5>{{verifiedData?.beneficiary_type_name | translate}}<span
                    *ngIf="verifiedData?.beneficiary_type_name != 'Pregnant woman'">{{ verifiedData?.is_pregnant_woman ? ('(Pregnant Woman)' | translate) : '' }}</span></h5>
              </ion-text>
            </ion-col>
          </ion-row>
          <!-- <ion-row> -->
          <!-- <ion-col size="6">
                            <ion-text>
                                <ion-label>Vaccine Received</ion-label>
                                <h5 *ngIf=" verifiedData?.vaccination_doses_due<2">
                                    Not Vaccinated

                                </h5>
                                <h5 *ngIf="verifiedData?.vaccination_doses_due>1">

                                    Dose {{verifiedData?.vaccination_doses_due-1}}
                                </h5>
                            </ion-text>
                        </ion-col> -->
          <!-- <ion-col size="6" *ngIf="verifiedData?.guardian_ben_id">
                            <ion-text class="ion-text-right">
                                <ion-label>Mother / Guardian</ion-label>
                                <h5>
                                    {{verifiedData?.guardian_name}}
                                </h5>
                            </ion-text>
                        </ion-col> -->
          <!-- </ion-row> -->
          <ion-row>
            <ion-col size="6" *ngIf="verifiedData?.guardian_ben_id">
              <ion-text>
                <ion-label>{{'Mother / Guardian' | translate}}</ion-label>
                <h5>
                  {{verifiedData?.guardian_name}}
                </h5>
              </ion-text>
            </ion-col>
            <ion-col size="6" *ngIf="verifiedData?.father_name">
              <ion-text class="ion-text-right">
                <ion-label>{{'Father Name' | translate}}</ion-label>
                <h5>
                  {{verifiedData?.father_name}}
                </h5>
              </ion-text>
            </ion-col>
            <ion-col size="6">

            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </ion-card>
    <ion-row *ngIf="verifiedData?.beneficiary_id || verifiedData?.session_id ">
      <ion-col size-md="12" class="ion-text-end ion-no-padding ion-margin-top">
        <ion-button class="primaryBtn" shape="round" type="submit" (click)="nextStep()">{{'Continue' | translate}}
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
<!-- <div class="sidebar-footer">

    <ion-row *ngIf="verifiedData?.beneficiary_id || verifiedData?.session_id ">
        <ion-col size-md="12">
            <ion-button class="primaryBtn" expand="full" shape="round" type="submit" (click)="nextStep()">Continue
                <ion-icon name="chevron-forward-outline"></ion-icon>
            </ion-button>
        </ion-col>
    </ion-row>
    <ion-row *ngIf="!verifiedData?.beneficiary_id && !verifiedData?.session_id ">
        <ion-col size-md="12">
            <ion-button class="linkBtn" expand="full" fill="outline" (click)="gotoRecipientPage()">View Immunisation Schedule</ion-button>
        </ion-col>
        <ion-col size-md="12">
            <ion-button class="primaryBtn" expand="full" shape="round" type="submit" (click)="nextStep()">Walkin COVID-19 Vaccination</ion-button>
        </ion-col>
    </ion-row>
</div> -->