import { Location } from '@angular/common';
import { Component, Output, EventEmitter, OnInit, SimpleChanges, Input, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { UtilsService } from 'src/app/modules/shared/services/utils.service';
import { SessionService } from 'src/app/screens/session/services/session.service';
import { AddRecipientService } from '../../services/add-recipient.service';

@Component({
  selector: 'app-add-recipient-verified-success',
  templateUrl: './verified-success.component.html',
  styleUrls: ['./verified-success.component.scss'],
})
export class VerifiedSuccessComponent implements OnInit {
  benfSubscription: Subscription;
  benfVerSubscription: Subscription;
  @Input() activeStepIndex = null;
  @Output() onStepChange: EventEmitter<any> = new EventEmitter();
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  verifiedData: any = {};
  benfFormData: any = {};
  constructor(
    public addRecipientService: AddRecipientService,
    public router:Router,
    private sessionService: SessionService,
    private actRoute: ActivatedRoute,
    private loc: Location,
    public util: UtilsService,

  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.activeStepIndex){
      if(this.activeStepIndex == 'VERIFIEDSUCCESS') {
        this.reloadComponent();
      } else {
        this.unSubscribe();
      }
    }
  }

  ngOnInit(){}

  unSubscribe(){
    if(this.benfSubscription)this.benfSubscription.unsubscribe();
    if(this.benfVerSubscription)this.benfVerSubscription.unsubscribe();
  }

  reloadComponent() {
    let sessionId = null;
    if(this.loc.path().match(/session\/[^\s]*/g)) {
      sessionId = this.loc.path().replace('/session/', '');
    }
    this.benfVerSubscription = this.addRecipientService.benSuccessData.subscribe(res => { //first bensuccessData is their instead of beneficiaryData
      if(sessionId && res.session_id && res.session_id == sessionId){
        this.sessionService.refreshSessionData$.next(true);
      }
      this.verifiedData = res;
      this.addRecipientService.beneficiaryDetails = res;
    })
    this.benfSubscription = this.addRecipientService.beneficiaryData.subscribe(res => { //first bensuccessData is their instead of beneficiaryData
      this.benfFormData = res;
    });
  }


  gotoStep(val) {
    if(val == 'prev' && this.util.getPlatformWidth()< 575){
      if(this.verifiedData.session_id){
        this.router.navigate([`/session/${this.verifiedData.session_id}`])
      }else{
        this.router.navigate(['/dashboard'])
      }
    }
    else{
      this.onStepChange.emit(val);
    }
  }

  closeDialog() {
    this.onClose.emit()
  }

  nextStep() {
    this.verifiedData.dose = 0; //passing 0 in vaccination-info.component.ts(used dose value for other type only in backend)
    if(!this.verifiedData.isBenfAddedToSession && this.verifiedData.session_id){
      this.addRecipientService.addBenefToOngoingSessionCommonMethod(this.verifiedData).subscribe(res => {
        this.verifiedData.vaccination_doses_due = 1;
        this.verifiedData.isBenfAddedToSession = true;
        this.addRecipientService.benSuccessDetails = this.verifiedData;
        let sessionId = null;
        if (this.loc.path().match(/session\/[^\s]*/g)) {
          sessionId = this.loc.path().replace('/session/', '');
        }
        if (sessionId && this.verifiedData.session_id) {
          this.sessionService.refreshSessionData$.next(true);
        }
        this.gotoStep('next');
      });
    }else { 
      this.gotoStep('next');
    }
  }
  VerifyAnotherMember(){
    this.router.navigate([`/session/${this.verifiedData.session_id}`]);
    this.closeDialog();
  }

  gotoRecipientPage() {
    this.closeDialog();
    this.router.navigate([`/beneficiary/${this.verifiedData.beneficiary_id}`]);
  }
}
