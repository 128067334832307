import { DataService } from './../../../modules/shared/services/data.service';
import { map } from 'rxjs/operators';
import { EncDecService } from './../../../modules/shared/services/encryption-decryption.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from './../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { UtilsService } from 'src/app/modules/shared/services/utils.service';
import { MatDialog } from '@angular/material/dialog';
import { PairDeviceDialog } from '../components/pair-device-dialog/pair-device-dialog';

@Injectable({providedIn: 'root'})
export class RemoteInterfaceService {
  private deviceInterfaceSubject: BehaviorSubject<any>;
  public deviceInterfaceObservable$: Observable<any>;
  protocol = 'https';
  port = '8443';
  baseUrl = 'https://rdserver.demo.co-vin.in/CoWinRdServer/webresources/Cowin/';
  constructor(
    private http: HttpClient,
    private encDecService: EncDecService,
    private dataService: DataService,
    public dialog: MatDialog,
    private util: UtilsService,
  ){
    this.deviceInterfaceSubject = new BehaviorSubject<any>(null);
    this.deviceInterfaceObservable$ = this.deviceInterfaceSubject.asObservable();
  }


  createSessionClient(oldSuid) {
      oldSuid = oldSuid ? oldSuid : '';
      let postData = {
          "curl":`${this.baseUrl}`,
          "stimeout":"28800000",
          "hostid":this.encDecService.get(environment.remoteAppIP),
          "suid":oldSuid,
          "userid":this.dataService.userDetails.mobile_number,
          "passcode":"1234"
      };
      return this.http.post(
          `${this.baseUrl}1`,
          postData,
          {headers: {apikey: this.encDecService.get(environment.remoteAPIKey)}}
      );
  }

  waitForAckClient(suid) {
      return this.http.get(
          `${this.baseUrl}5`,
          {headers: {suid: suid, wtimeout: "55000"}, responseType: 'text'}
      );
  }

  sendForAckClient(suid, cmd) {
      const payload = {"ret":cmd,"nsuid":uuidv4()}
      return this.http.post(
          `${this.baseUrl}7`,
          payload,
          {headers: {suid: suid, rtimeout: "55000"}, responseType: 'text'}
      );
  }

  requestForFaceAuth(suid) {
      const payload = {cmd: 'facerd', pload: this.createPidOptions(uuidv4(), 'auth')}
      return this.http.post(
          `${this.baseUrl}3`,
          payload,
          {headers: {suid: suid, rtimeout: "50000"}}
      );
  }

  pairDevice(dataRecieved: any) {
    const suid = dataRecieved.suid;
    return this.sendForAckClient(dataRecieved.suid, 'Y')
    .pipe(map((res: any) => {
      if (res) {
        try {
          res = JSON.parse(res);
          if (res.ret == 'Y') {
            localStorage.setItem('suid', dataRecieved.suid);
            this.connectionEstablished(dataRecieved.suid);
            this.util.presentToastSuccess('Connection Established Successfully');
          }
        } catch(e){
          if (res == 'RC:1105') {
            this.util.presentToastError('QR Code Expired');
          } else if (res == 'RC:1201') {
            this.util.presentToastError('No Client Session');
          }
        }
        return res;
      }
      return res;
    }));
  }

  requestForQRCode(suid) {
      const payload = {cmd: 'qrreq', pload: ''}
      return this.http.post(
          `${this.baseUrl}3`,
          payload,
          {headers: {suid: suid, rtimeout: "50000"}}
      );
  }

  requestForPhotoCapture(suid) {
      const payload = {cmd: 'capphoto', pload: ''}
      return this.http.post(
          `${this.baseUrl}3`,
          payload,
          {headers: {suid: suid, rtimeout: "50000"}}
      );
  }

  requestForLocationCapture(suid) {
      const payload = {cmd: 'gpsreq', pload: ''}
      return this.http.post(
          `${this.baseUrl}3`,
          payload,
          {headers: {suid: suid, rtimeout: "50000"}}
      );
  }

  requestForPingAPI(suid) {
      return this.http.get(
          `${this.baseUrl}11`,
          {headers: {suid: suid, wtimeout: "55000"}, responseType: 'text'}
      );
  }



  createPidOptions(txnId, purpose) {
      return "<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n" +
              "<PidOptions ver=\"1.0\" env=\"P\">\n" +
              "   <Opts fCount=\"\" fType=\"\" iCount=\"\" iType=\"\" pCount=\"\" pType=\"\" format=\"\" pidVer=\"2.0\" timeout=\"\" otp=\"\" wadh=\"sgydIC09zzy6f8Lb3xaAqzKquKe9lFcNR9uTvYxFp+A=\" posh=\"\" />\n" +
              "   <CustOpts>\n" +
              "      <Param name=\"txnId\" value=\""+txnId+"\"/>\n" +
              "      <Param name=\"purpose\" value=\""+purpose+"\"/>\n" +
              "   </CustOpts>\n" +
              "</PidOptions>";
  }

  connectionEstablished(data: any) {
    this.deviceInterfaceSubject.next(data);
  }

  enableFaceRdService() {
    const suid = localStorage.getItem('suid');
    if (!this.util.getIsMobileDevice()) {
      try {
        if (!suid) {
          return false;
        } else {
          return true;
        }
      } catch (e) {
        return false;
      }
    } else {
      return true;
    }
  }

  openPairDevicePopup() {
    const dialogRef = this.dialog.open(PairDeviceDialog,
      {
        width: "450px",
      });
  }
}
