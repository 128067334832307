import { Directive, Input, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appLimitTo]'
})
export class LimitToDirective {
  @Input('appLimitTo') limitTo = 6;

  private prevVal: any;
  constructor(private el: ElementRef) {}


  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this.el.nativeElement.value;
    const strr = '{0,'+ this.limitTo +'}';
    const re = new RegExp('^((?!(0))[0-9]' + strr + ')$', 'g');
    const resec = new RegExp('^((?!(0))[0-9]' + strr + ')$');
    const c = initalValue.replace(re, '');
    this.el.nativeElement.value = initalValue.substr(0, this.limitTo);
    if (c.length > 1 && initalValue.length > this.limitTo) {
      this.el.nativeElement.value = this.prevVal;
    }
    if (!(resec.test(initalValue))) {
      if (c.length > 1 && /^(?:0|00)\d+$/.test(c)) {
        this.el.nativeElement.value = initalValue.substr(1, c.length);
        this.el.nativeElement.type = 'text';
        this.el.nativeElement.setSelectionRange(0, 0);
        this.el.nativeElement.type = 'number';
        // setSelectionRange
      } else if ((initalValue).length === 1) {
        this.el.nativeElement.value = '';
      }
      event.stopPropagation();
      return false;
    } else {
      this.prevVal = this.el.nativeElement.value;
    }

  }
}


