<!-- <ion-content appScrollbarTheme>
    <div class="langugage-popup">
        <div class="card-body p-0">
            <ion-list>

                <ion-list-header>
                    <ion-label>Choose Language</ion-label>
                </ion-list-header>

                <ion-item *ngFor="let lang of languages">
                    <ion-icon name="checkmark-circle-outline" class="language-selected" *ngIf="language === lang.id"></ion-icon>
                    <ion-label (click)="selectLanguage(lang)">{{lang?.regional}} ({{lang?.name}})</ion-label>
                </ion-item>

            </ion-list>
        </div>
    </div>
</ion-content> -->
<ng-container *ngIf="env.i18_enabled && util.getPlatformWidth() >= 575" class="profileSetting">
<ion-item lines="full">
    <ion-grid>
        <ion-row>
            <ion-col size="3">
            <ion-label>{{'Preferred Language' | translate}}</ion-label>
            </ion-col>
            <ion-col size="6" class="validationMsg">
            <ion-label *ngIf="!isEditMode"><span>{{currentLanguageName}}</span></ion-label>
            <mat-form-field  *ngIf="isEditMode" appearance="outline" class="matDropdown ion-margin-vertical">
                <mat-select (selectionChange)='selectLanguage($event)' [placeholder]="'Preferred Language' | translate">
                    <mat-option *ngFor="let lang of languages" [value]="lang">
                        {{lang?.regional}} ({{lang?.name}})</mat-option>
                </mat-select>
            </mat-form-field>
            </ion-col>
            <ion-col size="3" class="ion-text-right">
            <ion-button *ngIf="!isEditMode" class="linkBtn" fill="outline" (click)="isEditMode=!isEditMode">
                {{'Edit' | translate}}
            </ion-button>
            <!-- <ion-button  class="linkBtn" fill="outline">{{'Cancel' |
                translate}}
              </ion-button>
              <ion-button  class="linkBtn" fill="outline">{{'Save' |
                translate}}</ion-button> -->
            </ion-col>
    </ion-row>
  </ion-grid>
</ion-item>
</ng-container>
<ng-container *ngIf="env.i18_enabled && util.getPlatformWidth() < 575">
    <ion-item class="noDesktop">
        <ion-label>{{'Preferred Language' | translate}}</ion-label>
        <ion-note *ngIf="!isEditMode" class="ion-padding-vertical" slot="end">{{currentLanguageName}}
        </ion-note>
        <ng-container *ngIf="isEditMode">
            <ion-select class="ion-padding-top" (ionChange)="selectLanguage($event)"  [placeholder]="'Preferred Language' | translate">
                <ion-select-option *ngFor="let lang of languages" [value]="lang">{{lang?.regional}} ({{lang?.name}})</ion-select-option>
            </ion-select>
        </ng-container>
    </ion-item>
</ng-container>
<!--[label]="'Preferred Language' | translate"-->