import {Injectable} from "@angular/core";
import {of, throwError} from "rxjs";
import {catchError, exhaustMap, map, mergeMap, switchMap, tap} from "rxjs/operators";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import * as authActions from "../actions/auth.actions";
import {AuthService} from "../../services/auth.service";
import {Router} from "@angular/router";
import * as userActions from "../actions/user.actions";
import {Store} from "@ngrx/store";
import { LocalStorageService } from "../../services/local-storage.service";
import { UserService } from "../../services/user.service";

@Injectable()
export class AuthEffects {

  login$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.Login),
    tap(() => this.store$.dispatch(userActions.LoadingUser())),
    switchMap(action => this.authService.login(action.params).pipe(
      tap(res => {
        this.authService.setAccessToken(res);
        // console.log('action.params',action.params);
        this.authService.postLoginUserUpdate(action.params.user_name);
        this.localStorage.setItem('AUTH', { isAuthenticated: true });
      }),
      map(() => authActions.LoginSuccess()),
      catchError(error => of(authActions.LoginFailure(error)))
    ))
  ), {dispatch: true});


  loginSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.LoginSuccess),
    mergeMap(() => this.userService.getUserDetails().pipe(
      map(user => {
        this.router.navigateByUrl('/dashboard').then()
        return userActions.SetUser({user})
      }),
      catchError(error => throwError(error))
    ))
  ), {dispatch: true});

  loginFailure$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.LoginFailure),
    tap((err) => {
      console.log('ON LOGIN Failed', err)
    })),
    { dispatch: false }
  );

  logout$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.Logout),
    tap(() => {
      this.authService.logOut();
      this.router.navigate(['/auth/login']);
      this.localStorage.removeItem('userToken');
      this.localStorage.setItem('AUTH', {
        isAuthenticated: false
      });
      
    })),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private authService: AuthService,
              private router: Router, private userService: UserService,
              private localStorage: LocalStorageService, private store$: Store,) {}
}
