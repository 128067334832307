<!-- <ion-input matInput type="hidden" formControlName="beneficiary_type_name" errorText=" " ></ion-input> -->
<app-ion-custom-select style="display:none" label="Member Category" formKey='beneficiary_type_category_id' [parentForm]="parentForm"
  [arrObj]="benCategories" [arrKey]="'category_id'" [arrKeyVal]="'category_name'" (onSelect)="benificaryChange($event)"
  placeholder="Select Member Category">
</app-ion-custom-select>
<app-ion-custom-select [label]="'Type of Category' | translate" formKey='ben_type_id' [parentForm]="parentForm"
  [arrObj]="filteredBeneficiaryTypes" [arrKey]="'ben_type_id'" [arrKeyVal]="'ben_type'"
  [placeholder]="'Select from the list' | translate" (onSelect)="benTypeCategoryChange($event)">
</app-ion-custom-select>
<!-- <div>
  <ion-card class="uploadFile uploadPic ion-text-center">
      <img src="assets/images/cataract-logo.svg">
      <div class="imgClose">
          <ion-icon name="trash-outline"></ion-icon>
      </div>
  </ion-card>

  <ion-card class="uploadFile ion-text-center">
      <h3>
          <ion-icon class="custom-camera"></ion-icon>Upload Special Document
      </h3>
      <ion-text>Click & Upload</ion-text>
  </ion-card>
</div> -->
<ion-row *ngIf="isSelectedOthers">
  <ion-col size="12">
    <ion-label class="required">Others</ion-label>
    <ion-item class="item-border ion-no-padding" lines="none">
      <ion-input formControlName="beneficiary_type_name_other" type="text" [appRangeLength]='[1, 50]'
        appInputChar='name' errorText=" ">
      </ion-input>
    </ion-item>
  </ion-col>
</ion-row>
<ion-row>
  <ion-col size-md="12" *ngIf="showStateEligible">
    <app-ion-custom-select label="State Eligibility Criteria"  formKey='state_eligibility_type_id'
       [parentForm]="parentForm"    [arrObj]="stateEligibletypes"  [arrKey]="'\id'"  [arrKeyVal]="'name'"
      placeholder="Select from dropdown">
    </app-ion-custom-select>
  </ion-col>
</ion-row>
<ion-row>

  <ion-col size-md="12" *ngIf="isFileUpload">
    <input type="file" #fileInput (change)="uploadFile($event)" hidden="true"
      accept="image/png,image/jpeg,application/pdf" (click)="resetFile()" />
    <ng-container *ngIf="uploadedEmploymentFileDetails?.fileObject">
      <ion-card class="uploadFile uploadPic ion-text-center">
        <img *ngIf="uploadedEmploymentFileDetails?.fileExt == 'pdf'" class="pdfImg" [src]="'assets/images/pdf-svg.svg'">
        <img *ngIf="uploadedEmploymentFileDetails?.fileExt != 'pdf'"
          [src]="uploadedEmploymentFileDetails?.selectedFileBLOB">
        <div class="imgClose" (click)="removeImage()">
          <ion-icon name="trash-outline"></ion-icon>
        </div>
      </ion-card>
    </ng-container>
    <ng-container *ngIf="!uploadedEmploymentFileDetails?.fileObject">
      <ion-card (click)="selectImageSource()" class="uploadFile ion-text-center"
        [ngClass]="{'error-border': isFormSubmitted && !parentForm.controls.employment_certificate.valid}">
        <h3>
          <ion-icon class="custom-camera"></ion-icon>{{fileTxt}}
        </h3>
        <ion-text>Click & Upload</ion-text>
      </ion-card>
    </ng-container>
  </ion-col>
</ion-row>