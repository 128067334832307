import { Subscription } from 'rxjs';
import { PopoverController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DataService } from 'src/app/modules/shared/services/data.service';
import { NotificationDialog } from '../../../../screens/notifications/dialog/notification-dialog/notification-dialog';

import * as _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { UtilsService } from '../../services/utils.service';
import { UserService } from 'src/app/modules/core/services/user.service';
import { NotificationService } from 'src/app/modules/core/services/notification.service';

@Component({
  selector: 'app-notification-alerts',
  templateUrl: './notification-alerts.component.html',
  styleUrls: ['./notification-alerts.component.scss'],
})
export class NotificationAlertsComponent implements OnInit, OnDestroy {
  q = '';
  timer = 0;
  items: any = [];
  cnt = 0;
  unreadNotification = [];
  dataLen = 0;
  notify2Date = new Date('2022-07-17');
  notify1Date = new Date('2022-01-07');
  subscription: Subscription;
  constructor(private route: Router,
              public popoverController: PopoverController,
              private dialog: MatDialog,
              private util: UtilsService,
              public dataService: DataService,
              public notificationServ:NotificationService) { }

  ngOnInit() {
    const userDetails = this.dataService.userDetails;
  }
  loadNotifications(){
    this.subscription = this.notificationServ.getUnreadNotifications().subscribe(r => {
      this.unreadNotification = [];
      if (r) {
        _.each(r, (notificationObj, idx) => {
          const parsedMessage = this.notificationServ.parseMsg(notificationObj);
          notificationObj.parsedMessage = parsedMessage;
        });
        this.unreadNotification = r;
        this.cnt = this.unreadNotification.length;
        this.notificationServ.setCountOfNotifications(this.cnt);
      }
    });
  }
  removeItem(item): void {
    item.read = 1;
    this.notificationServ.readNotification(item).subscribe();
    this.unreadNotification = this.unreadNotification.filter(i => i.notification_id !== item.notification_id);
    this.cnt = this.cnt - 1;
  }

  ngOnDestroy(): void {
    try {
      this.subscription.unsubscribe();
      } catch (e) {
        console.warn("Try failed", e)
      }
  }


  openDialog(key){
    this.dialog.open(NotificationDialog, {
      backdropClass: 'custom-dialog-backdrop-class',
      panelClass: 'notification-dialog',
      width: this.util.getPlatformWidth() > 575 ? '700px' : '100%',
      data: {notificationKey: key}
    });
  }

}
