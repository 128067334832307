<div class="sidebar-header noMobile">
  <h1>
    <!-- <ion-icon *ngIf="showPreviousVaccination" (click)="gotoStep('prev')" class="custom-arrow-left"></ion-icon> -->
    {{'Member Vaccination' | translate}}
    <ion-icon (click)="closeDialog()" class="custom-close"></ion-icon>
  </h1>
</div>
<!-- <div class="sidebar-content">
  <ion-grid class="sidebar-inner-content">
    <ng-container *ngFor="let material of vaccinesEligible;let i = index;">
    <ion-row class="ion-margin-vertical">
      <ion-col>
          <mat-checkbox class="example-margin" [value]="material.material_name"  [checked]='isChecked' (change)="onMaterialCheckSelection(material,$event)" >{{material.material_name}}
          </mat-checkbox>
      </ion-col>
    </ion-row>
    <ion-row class="ion-margin-vertical" *ngIf="material.no_of_doses > 1">
      <ion-col>
        <mat-radio-group >
          <mat-radio-button (change)="onselectedDoseEvent(material,$event)" *ngFor="let d of util.numSequence(material.no_of_doses)" class="example-margin" [value]="d+1">Dose {{d+1}}</mat-radio-button>
        </mat-radio-group>
      </ion-col>
    </ion-row>
  </ng-container>
  </ion-grid>
</div> -->
<ion-header class="noDesktop mobile-header-white">
  <ion-toolbar class="ion-no-padding">
    <ion-buttons slot="start">
      <ion-back-button (click)="gotoStep('prev')" text="" icon="arrow-back-outline"
        class="ion-margin-end"></ion-back-button>
    </ion-buttons>
    <h4 class="heading">{{'Member Vaccination' | translate}}</h4>
  </ion-toolbar>
</ion-header>
<ion-grid class="memberDetails ion-margin ion-no-padding">
  <ion-row>
    <ion-col class="ion-no-padding">
      <ion-accordion-group>
        <ion-accordion value="first">
          <ion-item slot="header" color="light">
            <h1 class="pageSubTitle">{{verifiedData.ben_name}}
              <p><ion-text>{{verifiedData.beneficiary_gender | translate}}</ion-text><ion-text><span
                    *ngIf="verifiedData.dob">{{verifiedData.dob | age }}</span><span
                    *ngIf="!verifiedData.dob && verifiedData.year">{{verifiedData.year | age:'Y'
                    }}</span></ion-text><ion-text>{{verifiedData.dob || verifiedData.year }}</ion-text></p>
            </h1>
          </ion-item>
          <div class="ion-padding" slot="content">
            <ion-grid class="memberInfo">
              <ion-row>
                <ion-col size="6">
                  <h5>{{'MOBILE' | translate}}</h5>
                  <h3>{{verifiedData.ben_mobile_number}}</h3>
                </ion-col>
                <ion-col size="6" class="ion-text-end">
                  <h5>{{'IDENTIFICATION TYPE' | translate}}</h5>
                  <h3>{{verifiedData.photo_id_type | translate}} </h3>
                </ion-col>
                <ion-col size="6">
                  <h5>{{'IDENTIFICATION NO' | translate}}</h5>
                  <h3>{{util.mask(verifiedData.photo_id_number)}}</h3>
                </ion-col>
                <ion-col size="6" class="ion-text-end" *ngIf="verifiedData.blood_group">
                  <h5>{{'BLOOD GROUP' | translate}}</h5>
                  <h3>{{verifiedData.blood_group}}</h3>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
        </ion-accordion>
      </ion-accordion-group>
    </ion-col>
  </ion-row>
</ion-grid>
<form [formGroup]="multiSelectionUIPVaccForm" autocomplete="off" (ngSubmit)="submit()">
  <div class="sidebar-content inputFields">
    <ion-grid class="sidebar-inner-content multi-vaccination">

      <ng-container *ngIf="!showPreviousVaccination">
        <!-- <ion-row>
              <ion-col size="12" class="ion-no-padding">
                <h3 class="pageSubTitle">{{!showPreviousVaccination ? 'Current Vaccination' : 'Previous Vaccination'}}</h3>
              </ion-col>
            </ion-row> -->

        

        <ion-row *ngIf="villageDetails?.length > 0">
          <ion-col size-md="12" class="ion-no-padding">
            <ion-label>{{'LGD Village' | translate}}</ion-label>
            <mat-form-field appearance="outline" class="ion-margin-vertical matDropdown">
              <mat-select [disabled]="multiSelectionUIPVaccForm.get('tag_session_site').value == true"
                (selectionChange)='onChangelocation("villageId")' [placeholder]="'Select from the list' | translate"
                formControlName="village_id">
                <mat-option *ngFor="let village of villageDetails " [value]="village.village_id">
                  {{village.village_name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="healthVillagesDetails?.length > 0">
          <ion-col size-md="12" class="ion-no-padding">
            <ion-label>{{'Health Village' | translate}}</ion-label>
            <mat-form-field appearance="outline" class="ion-margin-vertical matDropdown">
              <mat-select [disabled]="multiSelectionUIPVaccForm.get('tag_session_site').value == true"
                (selectionChange)='onChangelocation("healthVillageId")'
                [placeholder]="'Select from the list' | translate" formControlName="health_village_id">
                <mat-option *ngFor="let healthVillage of healthVillagesDetails " [value]="healthVillage.village_id">
                  {{healthVillage.village_name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="wardDetails?.length > 0">
          <ion-col size-md="12" class="ion-no-padding">
            <ion-label>{{'LGD Ward' | translate}}</ion-label>
            <mat-form-field appearance="outline" class="ion-margin-vertical matDropdown">
              <mat-select [disabled]="multiSelectionUIPVaccForm.get('tag_session_site').value == true"
                (selectionChange)='onChangelocation("wardId")' [placeholder]="'Select from the list' | translate"
                formControlName="ward_id">
                <mat-option *ngFor="let w of wardDetails " [value]="w.ward_id">
                  {{w.ward_name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="healthWardDetails?.length > 0">
          <ion-col size-md="12" class="ion-no-padding">
            <ion-label>{{'Health Wards' | translate}}</ion-label>
            <mat-form-field appearance="outline" class="ion-margin-vertical matDropdown">
              <mat-select [disabled]="multiSelectionUIPVaccForm.get('tag_session_site').value == true"
                (selectionChange)='onChangelocation("healthWardId")' [placeholder]="'Select from the list' | translate"
                formControlName="health_ward_id">
                <mat-option *ngFor="let hw of healthWardDetails " [value]="hw.add_ward_id">
                  {{hw.add_ward_name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="ashaList.length > 0">
          <ion-col size-md="12" class="ion-no-padding">
            <ion-label>{{'Asha' | translate}}</ion-label>
            <mat-form-field appearance="outline" class="ion-margin-vertical matDropdown">
              <mat-select [placeholder]="'Select from the list' | translate"
                formControlName="asha_user_id">
                <mat-option *ngFor="let asha of ashaList " [value]="asha?.user_id">
                  {{asha?.display_name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ion-col>
        </ion-row>
        <ion-row class="inputFields">
          <ion-col size-md="12" class="ion-no-padding ion-margin-bottom">
            <span class="checkboxSpan">
                  <mat-checkbox 
                    formControlName="tag_session_site" color="primary"  (change)="handleSessionSiteAndVillage($event)"> {{'Tag To Session Site' | translate}} </mat-checkbox>
                  
                </span>
            <!-- <ion-label>{{'Tag To Session Site' | translate}}</ion-label>
            <mat-radio-group aria-label="Select an option" formControlName="tag_session_site"
              (change)="handleSessionSiteAndVillage($event)">
              <mat-radio-button [value]='true'>Yes</mat-radio-button>
              <mat-radio-button [value]='false'>No</mat-radio-button>
            </mat-radio-group> -->
          </ion-col>
        </ion-row>

      </ng-container>
      <mat-tab-group mat-align-tabs="start" mat-stretch-tabs="false" class="vaccinationMatGridTab" [selectedIndex]=selectedIndex
        (selectedIndexChange)="onTabChange($event)">
        <mat-tab [label]="'Current Vaccination' | translate">
          <ng-container *ngIf="!showPreviousVaccination">
            <ng-container *ngIf="isDataInprogress">
              <ion-row class="ion-margin-vertical">
                <ion-col>
                  <p class="ion-text-center">{{'Loading please wait..' | translate}}</p>
                </ion-col>
              </ion-row>
            </ng-container>
            <ng-container *ngIf="!isDataInprogress && !loadingPrevVaccines && duplicateVaccinesEligible.length == 0">
              <ion-row class="ion-margin-vertical">
                <ion-col>
                  <img src="assets/images/aefireport.svg" class="verified-logo">
                  <p class="ion-text-center">{{'Vaccines are not available for this member' | translate}}</p>
                </ion-col>
              </ion-row>
            </ng-container>
            <ion-grid *ngIf="duplicateVaccinesEligible.length > 0" class="vaccines-grid currentVaccinationBg">
              <ion-row class="ion-margin-vertical bgRowClr">
                <ion-col>
                  <mat-checkbox color="primary" (change)="selectAllEligibleDoses($event)" [checked]="selectAllChecked" >{{'Select all doses for which eligible' | translate}}</mat-checkbox>
                </ion-col>
              </ion-row>
              <ng-container *ngFor="let material of duplicateVaccinesEligible;let i = index;">
                <ion-row class="vaccine-names">
                  <ion-col size="12" size-lg="12">
                    <mat-checkbox (change)="showVaccineInfoByBenf(material)" color="primary" [ngClass]="{'active': material.checked}"
                      [checked]="material.checked"
                      [disabled]="isFullyVaccinatedByMaterial(material) || disableBySpecificCase(material)">{{material.material_name | translate}}</mat-checkbox>
                  </ion-col>
                </ion-row>
                <!-- <ion-row class="vaccine-names">
                                <ion-col size="12" size-lg="12">
                                    <mat-checkbox [hidden]="true" class="example-margin" [value]="material.material_name" (change)="onMaterialCheckSelection(material,$event)">{{material.material_name}}
                                    </mat-checkbox>
                                    <ion-chip #c [ngClass]="{'active': material.checked}"
                                    [disabled]="isVaccinatedForSingleDose(material)" (click)="showVaccineInfoByBenf(material)">
                                        <ion-icon name="checkmark-circle"></ion-icon>
                                        {{material.material_name}}
                                    </ion-chip>
                                </ion-col>
                            </ion-row> -->
                <ion-row class="fade-in" *ngIf="material.no_of_doses > 1 && material.checked === true">
                  <ion-col size="12" size-lg="12">
                    <ion-card class="detailsCard">
                      <mat-radio-group id="mat{{material.material_id}}" name="mat{{material.material_id}}">
                        <ng-container *ngFor="let d of material.dose_array">
                          <ng-container *ngIf="isDoseEligibleForMinAge(material, d)">
                            <ng-container *ngIf="!d.doseReceivedDate && !d.isVaccinatedOutside && !isDoseEligibleForCurrentAge(material, d)">
                              <ion-item class="due">
                                <ion-label>{{labelName(d) | translate}}</ion-label>
                                <ion-note slot="end">{{'Not Given' | translate}}</ion-note>
                              </ion-item>
                            </ng-container>
                            <ng-container *ngIf="!d.doseReceivedDate && !d.isVaccinatedOutside && isDoseEligibleForCurrentAge(material, d)">
                              <ng-container *ngIf="d.due_date">
                                <!-- Due -->
                                <ion-item class="due">
                                  <ion-label>{{labelName(d) | translate}}</ion-label>
                                  <ion-note slot="end">{{d.due_date}}</ion-note>
                                </ion-item>
                              </ng-container>
                              <ng-container *ngIf="d.isEligible">
                                <ion-item class="pending">
                                  <mat-radio-button class="example-margin" color="primary"
                                    [checked]="isDoseChecked(material, d)"
                                    (change)="onselectedDoseEvent(material, d, $event)" [value]="d.dose">
                                    {{labelName(d) | translate}}
                                  </mat-radio-button>
                                  <ion-note slot="end">{{'Eligible' | translate}}</ion-note>
                                </ion-item>
                              </ng-container>
                            </ng-container>
                            <ng-container *ngIf="d.doseReceivedDate || d.isVaccinatedOutside">
                              <ion-item class="completed">
                                <ion-label>{{labelName(d) | translate}}</ion-label>
                                <ion-note *ngIf="d.doseReceivedDate" slot="end">{{d.doseReceivedDate | displayDateFormat}}</ion-note>
                                <ion-note *ngIf="!d.doseReceivedDate" slot="end">{{'Updated' | translate}}</ion-note>
                              </ion-item>
                            </ng-container>
                          </ng-container>
                        </ng-container>
                        
                      </mat-radio-group>
                    </ion-card>
                  </ion-col>
                </ion-row>

              </ng-container>
            </ion-grid>
          </ng-container>
        </mat-tab>

        <mat-tab [label]="'Previous Vaccination' | translate">
          <ng-container>
            <ng-container *ngIf="!loadingPrevVaccines && prevVaccinesByAge.length == 0">
              <ion-row class="ion-margin-vertical">
                <ion-col>
                  <img src="assets/images/aefireport.svg" clsss="verified-logo">
                  <p>{{'Previous Vaccines are not available for this member' | translate}}</p>
                  <!-- <ion-button class="primaryBtn" expand="full" (click)="loadCurrentVaccines()">Go Back</ion-button> -->
                </ion-col>
              </ion-row>
            </ng-container>
            <ion-grid *ngIf="prevVaccinesByAge.length > 0" class="vaccines-grid previousVaccinationBg">
              <ng-container *ngFor="let material of prevVaccinesByAge;let i = index;">
                <ion-row class="vaccine-names">
                  <ion-col size="12" size-lg="12">
                    <!-- <mat-radio-group (change)="showVaccineInfoByBenf(material,i,$event)">
                                    <mat-radio-button  value="material.material_name">{{material.material_name}} </mat-radio-button>
                                </mat-radio-group> -->
                    <mat-checkbox (change)="showVaccineInfoByBenf(material)" color="primary" [ngClass]="{'active': material.checked}"
                      [disabled]="isFullyVaccinatedByMaterial(material) || disableBySpecificCase(material)"
                      [checked]="material.checked" class="example-margin">{{material.material_name | translate}}
                    </mat-checkbox>

                  </ion-col>
                </ion-row>
                <ion-row class="fade-in" *ngIf="material.no_of_doses > 1 && material.checked == true">
                  <ion-col size="12" size-lg="12">
                    <mat-radio-group id="mat{{material.material_id}}" name="mat{{material.material_id}}">
                      <ng-container *ngFor="let d of material.dose_array">
                        <!-- <ng-container *ngIf="isDoseEligibleForMinAge(material,d)"> -->
                        <ion-card class="detailsCard">
                          <ng-container *ngIf="!d.doseReceivedDate && !d.isVaccinatedOutside">
                            <!-- <ng-container *ngIf="d.due_date"> -->
                            <!-- Due -->
                            <!-- <ion-item class="due">
                            <ion-label>Dose {{d.dose}}</ion-label>
                            <ion-note slot="end">{{d.due_date}}</ion-note>
                          </ion-item> -->
                            <!-- Due -->
                            <!-- </ng-container>
                        <ng-container *ngIf="d.isEligible"> -->
                            <!-- eligible -->
                            <!-- <ion-item class="pending">
                          <mat-checkbox class="example-margin"
                          [checked]="isDoseChecked(material, d)"
                          (change)="onSelectByDose(material,d,$event)"
                          [disabled]="!isDoseEligibleForMinAge(material,d) || isLastDoseChecked(material, d) || isDoseEligibleByLastDose(material, d)"
                            [value]="d.dose">{{labelName(d)}}
                          </mat-checkbox>
                          <ion-note slot="end">{{''}}</ion-note>

                        </ion-item> -->
                            <ion-row class="dose-labels">
                              <ion-col size="5">
                                <mat-checkbox class="example-margin" color="primary" [checked]="isDoseChecked(material, d)"
                                  (change)="onSelectByDose(material,d,$event)"
                                  [disabled]="!isDoseEligibleForMinAge(material,d) || isLastDoseChecked(material, d) || isDoseEligibleByLastDose(material, d) || disableBySpecificCase(material, d)"
                                  [value]="d.dose">{{labelName(d) | translate}}
                                </mat-checkbox>
                              </ion-col>
                              <ion-col size="7" *ngIf="isDoseChecked(material, d)">
                                <mat-form-field appearance="outline" class="matDropdown">
                                  <input matInput readonly [matDatepicker]="dp" [max]="getMaxDoseDate(material,d)"
                                    [min]="getMinDoseDate(material, d)"
                                    (dateChange)="onSelectByDoseDate(material, d, $event)"
                                    [disabled]="!isDoseEligibleForMinAge(material,d) || isLastDoseChecked(material, d) || isDoseEligibleByLastDose(material, d) || disableBySpecificCase(material, d)">
                                  <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                                  <mat-datepicker #dp></mat-datepicker>
                                </mat-form-field>
                              </ion-col>
                            </ion-row>
                            <ion-row *ngIf="isDoseChecked(material, d)">
                              <ion-col size="12" class="ion-padding-horizontal">
                                <!-- <ion-label>Dose Date</ion-label> -->
                                <!-- <mat-form-field appearance="outline" class="matDropdown ion-margin-vertical">
                              <input matInput readonly [matDatepicker]="dp" [max]="getMaxDoseDate(material,d)"
                                [min]="getMinDoseDate(material, d)"
                                (dateChange)="onSelectByDoseDate(material, d, $event)">
                              <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                              <mat-datepicker #dp></mat-datepicker>
                            </mat-form-field> -->
                              </ion-col>
                            </ion-row>


                            <!-- eligible -->
                            <!-- </ng-container> -->
                          </ng-container>
                          <ng-container *ngIf="d.doseReceivedDate || d.isVaccinatedOutside">
                            <!-- completed -->
                            <ion-item class="completed">
                              <!-- <ion-label>{{d.material_id == environment.static_ids.OPV_Vaccine ? 'OPV' : 'Dose'}}
                            {{d.material_id == environment.static_ids.OPV_Vaccine ? d.dose - 1 : d.dose }}</ion-label> -->
                              <ion-label>
                                {{labelName(d) | translate}}
                              </ion-label>
                              <ion-note *ngIf="d.doseReceivedDate" slot="end">{{d.doseReceivedDate | displayDateFormat}}
                              </ion-note>
                              <ion-note *ngIf="!d.doseReceivedDate" slot="end">{{'Updated' | translate}}</ion-note>
                            </ion-item>
                            <!-- completed -->
                          </ng-container>
                        </ion-card>
                      </ng-container>
                      <!-- </ng-container> -->
                    </mat-radio-group>
                  </ion-col>
                </ion-row>

                <ion-row class="ion-margin-vertical" *ngIf="material.no_of_doses == 1 && material.checked == true">
                  <ion-col>
                    <ion-label>{{'Dose Date' | translate}}</ion-label>
                    <mat-form-field appearance="outline" class="matDropdown ion-margin-vertical">
                      <input readonly matInput [matDatepicker]="dp" [max]="getMaxDoseDate(material,{dose:1})"
                        [min]="getMinDoseDate(material, {dose:1})"
                        (dateChange)="onselectedDoseDateEvent(material, $event)">
                      <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                      <mat-datepicker #dp></mat-datepicker>
                    </mat-form-field>
                  </ion-col>
                </ion-row>


              </ng-container>
              <!-- <ion-button class="primaryBtn" expand="full" (click)="loadCurrentVaccines()">Go Back</ion-button> -->
            </ion-grid>
          </ng-container>
        </mat-tab>
      </mat-tab-group>

    </ion-grid>
  </div>
  <!-- <ion-row>
    <ion-col size="12" *ngFor="let material of vaccinesEligible;let i = index;">
      <mat-checkbox class="example-margin" (change)="onMaterialCheckEvent(material,$event,i)">
        <mat-option [value]="material.material_name">{{material.material_name}}</mat-option>
        <span *ngIf="material.no_of_doses > 1">
          <span *ngFor="let dose of materialArray">
            <mat-radio-group aria-label="Select an option" *ngIf="dose.index == i" [name]="dose.radioname">
              <mat-radio-button [value]="dose" (change)="onselectedDose($event)">{{dose.name}}</mat-radio-button>
            </mat-radio-group>
          </span>
        </span>
        <span *ngIf="multiSelectionUIPVaccForm.get('materialDetails').value.length>0">
          <ion-label *ngFor="let selectedDose of multiSelectionUIPVaccForm.get('materialDetails').value"
            class="error-validation">
            <p
              *ngIf="formSubmitted && selectedDose.hasOwnProperty('material_dose') && selectedDose.material_dose===null && selectedDose.material_id == material.material_id">
              {{errorMessage}}</p>
          </ion-label>
        </span>
      </mat-checkbox>
    </ion-col>
  </ion-row> -->
  <div class="sidebar-footer">
    <!-- <ion-row *ngIf="!showPreviousVaccination">
      <ion-col size-md="12">
        <ion-button *ngIf="showprevBttn" class="linkBtn" expand="full" fill="outline" (click)="addPrevVaccination()">Add
          Previous Vaccination
        </ion-button>
      </ion-col>
    </ion-row> -->
    <ion-row>
      <ion-col size-md="12" class="fixedCol lang-{{currLang}}">
        <ion-button [disabled]="(duplicateVaccinesEligible.length === 0 && !showPreviousVaccination) ||
          (prevVaccinesByAge.length === 0 && showPreviousVaccination)|| isMaterialDueDateAvailable" class="primaryBtn"
          expand="full" shape="round" type="submit">{{'Save & Continue' | translate}}
        </ion-button>
      </ion-col>
    </ion-row>

  </div>
</form>