import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EncDecService } from 'src/app/modules/shared/services/encryption-decryption.service';
import { DataService } from 'src/app/modules/shared/services';
import * as _ from 'lodash';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  refreshSessionData$ = new BehaviorSubject<boolean>(true);
  sessionStatus = {
    0: 'Yet to Start',
    1: 'On Going',
    2: 'Cancelled',
    3: 'Completed',
    5: 'Freeze',
    6: 'Yet to Start'
  };

  beneficiaries: any = [];
  sessionData: any = {};
  private filterSubject: BehaviorSubject<any>;
  public filterCriterial: Observable<any>;
  public sessionsFromAshaFilter: BehaviorSubject<any>
  public sessionsByVillage: Observable<any>
  public localStorgeFromAsha: BehaviorSubject<any>
  public getValueInHeader: Observable<any>
  public villageValues = new ReplaySubject(1)
  hideSlots = new ReplaySubject(1);
  public sendSearchData = new ReplaySubject(1)
  public changedSubcenter = new Subject();
  public facilityChanged = new Subject();

  constructor(
    private http: HttpClient,
    private dataService: DataService,
    private encDecService: EncDecService,
    public alertController: AlertController,
  ) {
    this.filterSubject = new BehaviorSubject<any>(null);
    this.filterCriterial = this.filterSubject.asObservable();
    this.sessionsFromAshaFilter = new BehaviorSubject<any>(null);
    this.sessionsByVillage = this.sessionsFromAshaFilter.asObservable();
    this.localStorgeFromAsha = new BehaviorSubject<any>(null);
    this.getValueInHeader = this.localStorgeFromAsha.asObservable();
  }

  appyFilter(filterParams) {
    this.filterSubject.next(filterParams);
  }

  FromAshaFilterToSession(sessions) {
    this.sessionsFromAshaFilter.next(sessions)
  }

  storageValues(values) {
    this.localStorgeFromAsha.next(values)
  }

  getSessions(params: any): Observable<any> {
    if (window.navigator.onLine) {
      params.session_site_id = this.encDecService.setWithSpace(this.dataService.userDetails.session_site_id);
      return this.http.post(`${environment.session_prefix_url}/bulkSesssion/v2/getVaccinatorSessions`, params);

    } else {
      return of(null);
    }
  }

  getOnGoingAndUpComingSessions(params): Observable<any> {
    if (!params.session_site_id) {
      return of([]);
    }
    if (window.navigator.onLine) {
      // params.status = 1;
      if((params.session_site_id)?.slice(-1) == '='){
        params.session_site_id = params.session_site_id
      }else{
        params.session_site_id = this.encDecService.setWithSpace(params.session_site_id);
      }
      return this.http.post(`${environment.vaccinator_url}/ongoingUpcomingSessions`, params)
        .pipe(map((r: any) => {
          let sessList = [];
          if (r) {
            sessList = r?.upcoming_session_data.concat(r?.ongoing_session_data);
            sessList = _.orderBy(sessList, (s) => new Date(s.date_of_session), ['asc']);
          }
          return sessList;
        }));
    }
  }

  getOnGoingAndUpComingSessionsForAsha(params): Observable<any> {
    // if (window.navigator.onLine) {
    // params.status = 1;
    if (Object.keys(params).length == 0) {
      return of([]);
    }
    params.village_id = params.village_id ? params.village_id.map(e=>this.encDecService.setWithSpace(e)) :undefined;
    params.health_village_id = params.health_village_id ? params.health_village_id.map(e=>this.encDecService.setWithSpace(e)) :undefined;
    params.health_ward_id = params.health_ward_id ? params.health_ward_id.map(e=>this.encDecService.setWithSpace(e)) :undefined;
    params.ward_id =  params.ward_id ? params.ward_id.map(e=>this.encDecService.setWithSpace(e)) :undefined;
    return this.http.post(`${environment.vaccinator_url}/getSessionsByVillageId`, params)
      .pipe(map((r: any) => {
        let sessList = [];
        if (r) {
          // sessList = r?.upcoming_session_data.concat(r?.ongoing_session_data);
          // sessList = _.orderBy(sessList, (s) => new Date(s.date_of_session), ['asc']);
          const siteId = this.encDecService.get(params.session_site_id);
          sessList = r?.upcoming_session_data.concat(r?.ongoing_session_data);
          sessList = sessList.filter(s=>s.session_site_id == siteId);
          sessList = _.orderBy(sessList, (s) => new Date(s.date_of_session), ['asc']);
        }
        return sessList;
      }));
    // }
  }

  getOnGoingSessions(params): Observable<any> {
    if (window.navigator.onLine) {
      params.status = 1;
      params.session_site_id = this.encDecService.setWithSpace(this.dataService.userDetails.session_site_id);
      return this.http.post(`${environment.vaccinator_url}/getVacinnatorSessionByStatus`, params);
    }
  }

  getSessionInfo(sessionID) {
    if (window.navigator.onLine) {
      const payload = {
        session_id : this.encDecService.setWithSpace(sessionID)
      }
      return this.http.post(`${environment.vaccinator_url}/getSessionData`, payload)
        .pipe(map((r: any) => {
          r.scheduled_beneficiaries.map(s=>{this.encDecService.decryptPhotoId(s);this.encDecService.decryptMobileNo(s)});
          r.rejected_beneficiaries.map(s=>{this.encDecService.decryptPhotoId(s);this.encDecService.decryptMobileNo(s)});
          r.vaccinated_beneficiaries.map(s=>{this.encDecService.decryptPhotoId(s);this.encDecService.decryptMobileNo(s)});
          r.verified_beneficiaries.map(s=>{this.encDecService.decryptPhotoId(s);this.encDecService.decryptMobileNo(s)});
          if(r.noshow_beneficiaries){
            r.noshow_beneficiaries.map(s=>{this.encDecService.decryptPhotoId(s);this.encDecService.decryptMobileNo(s)});
          }
          this.sessionData = r;
          return r;
        }));
    }
  }

  getSlotsAvailable(params): Observable<any> {
    if (!this.dataService.userDetails.session_site_id) {
      return of({});
    }
    if (window.navigator.onLine) {
      params.session_site_id = this.encDecService.setWithSpace(this.dataService.userDetails.session_site_id);
      if(params.session_id){
      params.session_id = this.encDecService.isAlreadyDataEncrypted(params.session_id);
      }
      return this.http.post(`${environment.vaccinator_url}/getSlotsAvailable`, params);
    }
  }

  startSession(payload): Observable<any> {
    let sessionDetails = payload.sessionInfo;
    let params = {
      sessionId: sessionDetails.session_id,
      session_site_id: this.dataService.userDetails.session_site_id,
    }
    if (sessionDetails.special_session_type_id == 1 || (sessionDetails?.is_special_session == 1 && sessionDetails?.special_session_type_id == undefined)) {
      params["contact_person_name"] = this.encDecService.setWithSpace(payload.contact_person_name)
      params["contact_person_mobile"] = this.encDecService.setWithSpace(payload.contact_person_mobile)
      params["social_group"] = this.encDecService.setWithSpace(payload.social_group)
      params["name_of_institution"] = this.encDecService.setWithSpace(payload.name_of_institution)
      params["contact_person_id_type"] = this.encDecService.setWithSpace(payload.contact_person_id_type)
      params["contact_person_id_value"] = this.encDecService.setWithSpace(payload.contact_person_id_value)
      params["contact_person_designation"] = this.encDecService.setWithSpace(payload.contact_person_designation)
    }
    if (window.navigator.onLine) {
      return this.http.post(`${environment.vaccinator_url}/StartSession`, params);
    }
  }

  stopSession(postData): Observable<any> {
    if (window.navigator.onLine) {
      postData.session_site_id = this.dataService.userDetails.session_site_id;
      return this.http.post(`${environment.vaccinator_url}/EndSessionV2`, postData);

    }
  }

  stopUipSession(postData): Observable<any> {
    if (window.navigator.onLine) {
      return this.http.post(`${environment.vaccinator_url}/uipEndSession`, postData);

    }
  }

  getMaterialTypes() {
    return this.http.get(`${environment.admin_prefix_url}/material/getAllMaterial`)
  }

  getSessionHistory(params) {
    if (window.navigator.onLine) {
      // params.session_site_id = this.encDecService.setWithSpace(this.dataService.userDetails.session_site_id);
      return this.http.post(`${environment.vaccinator_url}/session_history`, params);
    } else {
      return of(null);
    }
  }

  isNewVaccine(session) {
    return !['COVAXIN', 'SPUTNIK V', 'COVISHIELD', 'ZyCoV-D', 'SPUTNIK V AD05 DOSE 2', 'SPUTNIK V AD26 DOSE 1'].find(ele => ele == session.material_name);
  }

  ageGroup(sess) {
    /*
    if(!sess?.maximum_age_allowed) {
      return `${sess?.minimum_age_allowed} & Above`;
    }
    return `${sess?.minimum_age_allowed} - ${sess?.maximum_age_allowed}`;
    */
    if (environment.static_ids.SESSION_PREGNANT_CATEGORY.includes(sess.uip_session_category)) {
      return '12-49Y'
    }
    else if (!sess?.display_max_age && !sess?.display_min_age && !sess?.maximum_age_allowed && !sess?.minimum_age_allowed) {
      return `All Age`
    }
    else {
      if (!sess?.display_max_age && sess?.display_min_age) {
        return `${sess?.display_min_age} & Above`;
      } else if (sess?.display_min_age) {
        return `${sess?.display_min_age} - ${sess?.display_max_age}`;
      } else {
        if (!sess?.maximum_age_allowed) {
          return `${sess?.minimum_age_allowed}Y & Above`;
        }
        return `${sess?.minimum_age_allowed}Y - ${sess?.maximum_age_allowed}Y`;
      }
    }
  }

  getbentypeupdate(params) {
    return this.http.post(`${environment.vaccinator_url}/bentypeupdate`, params);
  }

  searchBeneficiaryByType(params): Observable<any> {
    if(!this.encDecService.isEncrypted(params.search_value))
    params.search_value = this.encDecService.setWithSpace(params.search_value);
    return this.http.post(`${environment.vaccinator_url}/beneficiary/getBeneficiaries`, params)
    .pipe(map((b:any)=>{
      b.map(benf=>{
        this.encDecService.decryptPhotoId(benf)
        this.encDecService.decryptMobileNo(benf)
      });
      return b;
    }));
  }

  addBeneficiaryToOngoingSessionBySearch(params, beneficiary?: any): Observable<any> {
    const addedFrom = (this.dataService.userDetails.role == 'Vaccinator' || this.dataService.userDetails.role == 'ANM') ? 'vaccinator' : (this.dataService.userDetails.role == environment.static_ids.DeliveryManagerKey || this.dataService.userDetails.role == environment.static_ids.BothplanningUnitAndDelivery) ? 'dpm' : ' '
    if (window.navigator.onLine) {
      params['added_from'] = addedFrom;
      if (this.dataService.userDetails.facility_id) {
        params['facility_id'] = (this.dataService.userDetails.role == environment.static_ids.DeliveryManagerKey || this.dataService.userDetails.role == environment.static_ids.BothplanningUnitAndDelivery) ? this.dataService.userDetails.facility_id : undefined;
      }
      if (this.dataService.userDetails.session_site_id) {
        params['site_id'] = this.encDecService.setWithSpace(this.dataService.userDetails.session_site_id);
      }
     params['sessionId'] = this.encDecService.setWithSpace(params['sessionId']);
     params['state_id'] = this.encDecService.setWithSpace(params['state_id']);
     params['districtId'] = this.encDecService.setWithSpace(params['districtId']);
     params['beneficiary_id'] = this.encDecService.setWithSpace(params['beneficiary_id']);
      return this.http.post(`${environment.vaccinator_url}/searchaddBenToOngoingSession`, params)
        .pipe(map((r: any) => {
          // if (r && r.message == 'Beneficiary Added Successfully') {
          //   this.appendBenficiaryToSessionCacheObj(params, beneficiary);
          // }
          return r;
        }));
    }
  }
  
  async presentAlertConfirm(options) {
    const alert = await this.alertController.create({
      cssClass: options.cssClass ? options.cssClass : 'alert-confirm-box',
      header: options.header ? options.header : 'Confirm!',
      message: options.message ? options.message : '',
      inputs: options.inputs ? options.inputs : [],
      buttons: options.buttons ? options.buttons : []
    });
  }

  undoVaccinations(params) {
    const payload = {
      session_id: this.encDecService.setWithSpace(params.sessionId),
      ben_id: this.encDecService.setWithSpace(params.vaccInfo.beneficiary_id)
    }
    return this.http.post(`${environment.vaccinator_url}/undoVaccination`, payload)
  }

  getAllSitesOfAsha(userId = undefined) {
    return this.http.post(`${environment.vaccinator_url}/getAllSitesOfAsha`, {user_id:userId}).pipe(map((b:any)=>{
      b.map(data=>{
        data.session_site_id = this.encDecService.get(data.session_site_id)
      });
      return b;
    }));
  }

  sessionSummaryReport(payload) {
    return this.http.post(`${environment.vaccinator_url}/sessionSummary`, payload)
  }
}

