import { Component, OnInit, ViewChild, Input, OnChanges, SimpleChanges, AfterViewInit, Output, EventEmitter, ChangeDetectorRef, ElementRef, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { DataService } from 'src/app/modules/shared/services';
import { UtilsService } from 'src/app/modules/shared/services/utils.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ROUTE_ANIMATIONS_ELEMENTS } from 'src/app/modules/core/services/route.animations';
import { Device } from '@capacitor/device';
import { AddRecipientService } from '../../services/add-recipient.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AadharService } from 'src/app/screens/aadhar-verification/services/aadhar.service';
import { LookUpService } from 'src/app/modules/shared/services/look-up.service';
import { RecipientService } from 'src/app/screens/recipient/services/recipient.service';
import { switchMap } from 'rxjs/operators';
// import { GeoLocationService } from 'src/app/modules/shared/services/geo-location.service';
import { ImageUploadService } from 'src/app/modules/shared/services/image-upload.service';
import { EncDecService } from 'src/app/modules/shared/services/encryption-decryption.service';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/modules/core/services/user.service';


@Component({
  selector: 'app-add-recipient-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.scss'],
})
export class PersonalDetailsComponent implements OnInit {
  benfSubscription: Subscription;
  @Input() activeStepIndex = null;
  @Input() isPWI = false;
  @Output() onStepChange: EventEmitter<any> = new EventEmitter();
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  personalDetForm: FormGroup;
  benData: any;
  coordinates: any;
  showReject: boolean = false;
  RejectBtnText = 'Reject';
  startVerificationTime = moment().format();
  adhaarDataMatch: any = null;
  Inprogress: boolean = false;
  uploadedSpecialDocFileDetails: any;
  formsubmitted: boolean = false;
  specialDocument: boolean = false;
  deviceInfo;
  environment
  stepChange = false;
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  constructor(
    public addRecipientService: AddRecipientService,
    private aadharService: AadharService,
    public lookupService: LookUpService,
    public recipientService: RecipientService,
    private router: Router,
    public util: UtilsService,
    public imageUploadService: ImageUploadService,
    private encDecService: EncDecService,
    private platform: Platform,
    private cdr: ChangeDetectorRef,
    private userServ: UserService,
  ) {
    this.personalDetForm = new FormGroup({
      ben_name: new FormControl(null, [Validators.required]),
      // dob: new FormControl('', Validators.required),
      yob: new FormControl(''),
      lmp_date: new FormControl(''),
      gender: new FormControl('', Validators.required),
      user_photo: new FormControl(''),
      reference_id: new FormControl(''),
      yobValidation: new FormControl(''),
      file: new FormControl(''),
      blood_group: new FormControl(''),
      child_weight: new FormControl('', [Validators.pattern('^[0-9]{1}([.][0-9]{1,3})?$'), Validators.min(0.4)]),
      father_name: new FormControl(''),
      previous_pregnancy_year: new FormControl(''),
      is_pvtg: new FormControl('', Validators.required),
      otp: new FormControl(null),
      referenceId: new FormControl('')
    });
    if (!environment.pvtg_enabled) {
      this.personalDetForm.get('is_pvtg').setValidators([]);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.activeStepIndex) {
      if (this.activeStepIndex == 'PERSONALDETAILS') {
        this.reloadComponent();
      } else {
        this.unSubscribe();
      }
    }
  }

  ngOnInit() { }

  unSubscribe() {
    if (this.benfSubscription) this.benfSubscription.unsubscribe();
  }


  async reloadComponent() {
    this.environment = environment;
    this.deviceInfo = await Device.getInfo();
    if (this.isPWI && !this.personalDetForm.get('dob')) {
      this.personalDetForm.addControl('dob', new FormControl(''));
      this.cdr.detectChanges();
    }
    this.stepChange = false;
    // this.coordinates = await this.geo.getCurrentPosition();
    this.lookupService.coordinates.subscribe(r => {
      this.coordinates = r;
    })
    this.benfSubscription = this.addRecipientService.beneficiaryData.subscribe(benf => {
      this.benData = benf;
      console.log('this.benData', this.benData);
      this.adhaarDataMatch = {};
      if (this.benData?.ben_type_id == environment.static_ids.PregnantWomen && !benf?.beneficiary_id) {
        this.personalDetForm.get('lmp_date').setValidators([Validators.required]);
        this.personalDetForm.get('lmp_date').updateValueAndValidity();
        // this.personalDetForm.get('blood_group').setValidators([Validators.required]);
        // this.personalDetForm.get('blood_group').updateValueAndValidity();
      }
      if (this.benData?.beneficiary_id) {
        // if(this.benData?.dob){
        //   this.benData.dob = moment(new Date(this.benData?.dob)).format();
        // this.personalDetForm.get('dob').setValue(this.benData.dob);
        // }else{
        // this.personalDetForm.get('dob').setValue(this.benData.year);
        // }
        if (this.benData?.ben_type_id != environment.static_ids.Infant_ID && this.benData?.ben_type_id != environment.static_ids.NewBornBaby) {
          this.benData.child_weight = null;
        }
        this.personalDetForm.patchValue(this.benData);
        if (this.benData?.lmc_date) {
          this.personalDetForm.get('lmp_date').setValue(this.benData?.lmc_date);
        }
        this.personalDetForm.get('gender').setValue(this.benData.ben_gender);

        if (this.benData.hasOwnProperty("demoAadharstatus")) {
          this.showReject = true;
        }
        else {
          this.showReject = false;
        }
        this.personalDetForm.get('gender').disable();
        this.personalDetForm.get('ben_name').disable();
      }
      const isChild = benf.guardian_ben_id ? true : false;
      if (benf?.verifiedBy === 'Aadhar' && !isChild) {

        if (benf?.beneficiary_id) {
          this.adhaarDataMatch = this.addRecipientService.matchWithAadharData(benf);
          if (!this.adhaarDataMatch.dataMatch) { this.showReject = !this.adhaarDataMatch.dataMatch }
        }
        if (!benf?.beneficiary_id && !isChild) {
          const yearOfBirth = benf.aadharData.yob || benf.aadharData.yearOfBirth;
          if (yearOfBirth != moment(this.benData.dob).format('YYYY')) {
            this.showReject = true;
            this.util.presentToastError("Aadhar DOB is not matched with entered DOB")
            this.RejectBtnText = 'Back';
          }
          else {
            this.showReject = false;
          }
        }
        if (benf?.aadharData.gender == 'M') {
          this.showReject = true;
        }
        else {
          this.showReject = false;
        }
        this.aadharService.setDataInFormNewQRDeskTop(benf.aadharData, this.personalDetForm);
        this.personalDetForm.updateValueAndValidity();
      }

      if (this.benData != null && this.benData != undefined) {
        if (this.benData.ben_type_id == environment.static_ids.PregnantWomen || this.benData.ben_type_id == 20) {
          this.personalDetForm.get('gender').disable();
          this.personalDetForm.get('gender').setValue(2);
        }
      }
    });
    // if((this.benData?.precaution_special_person==1 && this.benData?.vaccination_status == 2)|| (this.benData?.special_person== true && this.benData?.vaccination_status == 1)){
    if (this.benData?.special_person == true && this.benData?.vaccination_status == 1 && this.benData?.session_type_name == 'COVID-19') {
      this.specialDocument = true;
      this.personalDetForm.get('file').setValidators([Validators.required]);
    } else {
      this.personalDetForm.get('file').reset();
      this.personalDetForm.get('file').clearValidators();
      this.personalDetForm.get('file').updateValueAndValidity();
    }
  }

  gotoStep(val) {
    this.stepChange = false;
    this.onStepChange.emit(val);
    if (val == 'prev') {
      this.benData.aadharData = null;
    }
    // this.personalDetForm.get('dob').clearValidators()
    // this.personalDetForm.get('dob').updateValueAndValidity()
    this.personalDetForm.get('lmp_date').clearValidators()
    this.personalDetForm.get('lmp_date').updateValueAndValidity()
    this.personalDetForm.get('blood_group').clearValidators()
    this.personalDetForm.get('blood_group').updateValueAndValidity()
  }
  reject() {
    if (this.RejectBtnText == 'Back') {
      this.gotoStep('prev')
      this.addRecipientService.sendFalse.next(false);
    }
    else {
      this.router.navigate([`/session/${this.benData.session_id}`]);
      this.closeDialog();
    }
  }
  closeDialog() {
    this.onClose.emit()
  }
  async saveMetaInfo(beneficiaryData) {
    var verified_Type;
    if (beneficiaryData.verifiedType == 'facial') {
      verified_Type = 'faceauth'
    }
    else if (beneficiaryData.verifiedType == 'qr') {
      verified_Type = 'demographic'
    }
    else {
      verified_Type = beneficiaryData.verifiedType
    }
    const metaPostData = {
      'beneficiary_id': beneficiaryData.beneficiary_id,
      'state_id': beneficiaryData.state_id,//have get from session
      'state_name': beneficiaryData.state_name,//have get from session
      'district_id': beneficiaryData.district_id,//have to get from session
      'district_name': beneficiaryData.district_name,//have get from session
      'facility_id': beneficiaryData.facility_id,//have get from session
      'facility_name': beneficiaryData.facility_name,////have get from session
      'site_id': beneficiaryData.session_site_id,////have get from session
      'site_name': beneficiaryData.session_site_name,
      'session_id': beneficiaryData.session_id,
      'session_name': beneficiaryData.session_name,////have get from session
      'verification_type': beneficiaryData.verifiedBy == 'Aadhar' ? 'Aadhaar Card' : 'BASIC', //changed because in DB we are storing differently
      'verification_method': verified_Type, //'NONE'
      'verification_status': 1,
      'verifier_lat': this.coordinates?.latitude ? (this.coordinates.latitude).toString() : '0',
      'verifier_long': this.coordinates?.longitude ? (this.coordinates.longitude).toString() : '0',
      'vaccinator_lat': this.coordinates?.latitude ? (this.coordinates.latitude).toString() : '0',
      'vaccinator_long': this.coordinates?.longitude ? (this.coordinates.longitude).toString() : '0',
      'verifier_device': this.deviceInfo.model,
      'verification_starttime': this.startVerificationTime,
      'verification_endtime': moment().format()
    }
    this.recipientService.saveMetaInfoVerificationDetails(metaPostData).subscribe();
  }
  async saveRDMetaInfo() {
    // if (!this.util.getIsMobileDevice()) {
    if (this.benData.verifiedType === 'facial' || this.benData.verifiedType === 'qr') {
      this.recipientService.updateRDmetainfo({
        type: this.benData.verifiedType,
        suid: localStorage.getItem('suid'),
        session_id: this.benData.session_id,
        time_took: this.benData.time_took
      }).subscribe();
    }
    // }
  }
  uploadFile(event?) {
    this.imageUploadService.uploadFile(event).subscribe(res => {
      this.uploadedSpecialDocFileDetails = res;
      this.personalDetForm.get('file').setValue(res.fileName);
    },
      err => {
        this.util.presentToastError(err);
      });
  }
  removeImage() {
    this.uploadedSpecialDocFileDetails = null;
    this.personalDetForm.get('file').setValue(null);
  }
  async selectImageSource() {
    if (!this.platform.is('desktop')) {
      this.imageUploadService.selectImageSource(this.fileInput).then(r => {
        if (this.imageUploadService.imageCaptureType == 'takePhoto') {
          this.uploadFile();
        }
      });
    } else {
      this.imageUploadService.selectImageSource(this.fileInput);
    }
  }

  validateReferenceId(benRefId) {
    let beneficiaryId = this.benData.beneficiary_reference_id.toString();

    let lastFourDigits = beneficiaryId.substring(beneficiaryId.length - 4);

    if (lastFourDigits === benRefId) {
      this.util.presentToastSuccess("Benificary Added and Verified Successfully")
      this.afterVerification();
    } else {
      this.util.presentToastError("Please Enter Correct Last 4 Digits of Beneficary ID")
      return
    }
  }

  onSubmit() {
    this.formsubmitted = true;
    this.personalDetForm.markAllAsTouched();
    if (this.personalDetForm.get('gender').value == null || this.personalDetForm.get('gender').value == undefined || this.personalDetForm.get('gender').value == "") {
      this.util.presentToastError('Please Select Gender')
    }
    if (!this.isPWI && (this.personalDetForm.get('yobValidation').value != this.benData.year) && (this.benData?.verifiedBy == 'Manual') && (this.benData?.beneficiary_id)) {  // && (this.benData?.vaccination_doses_due >= 2)
      if (this.personalDetForm.get('yobValidation').value == null || this.personalDetForm.get('yobValidation').value == '' || this.personalDetForm.get('yobValidation').value == undefined) {
        this.personalDetForm.get('yobValidation').setValidators([Validators.required])
        this.personalDetForm.get('yobValidation').updateValueAndValidity();
        return;
      } else {
        this.util.presentToastError('Entered Year of birth does not match with Beneficiary details');
        return;
      }
    }

    if (!this.personalDetForm.valid) {
      return;
    }

    if (this.benData.verifiedBy == 'Manual' && !this.personalDetForm.get('referenceId').value) {
      this.verifyOTP();
    } else {
      this.validateReferenceId(this.personalDetForm.get('referenceId').value)
    }
    // if(this.benData?.verifiedBy=='Manual' && (this.benData?.added_from == 'self' ||  (this.benData?.ben_type_id == 5 && this.benData?.added_from != 'onspot'))){
    //   if(this.personalDetForm.get('reference_id').value == '' || this.personalDetForm.get('reference_id').value == null){
    //     this.util.presentToastError('Please Enter last four digits of Reference ID');
    //       return;
    //   }
    // }

    // if (this.personalDetForm.get('reference_id').valid && ((this.benData?.ben_type_id == 5 && this.benData?.added_from != 'onspot')  || this.benData?.added_from == 'self') && this.benData?.verifiedBy == "Manual") {
    //   const referenceId = this.personalDetForm.get('reference_id').value;
    //   const lastDigitsRefId = this.benData.beneficiary_reference_id;
    //   const fourDigits = lastDigitsRefId.toString().slice(-4);
    //   if (fourDigits != referenceId) {
    //     this.util.presentToastError('Entered Reference ID does not match with Beneficiary Reference ID');
    //     return;
    //   }
    // }


  }

  afterVerification() {
    const latLongData = {
      'verifier_lat': this.coordinates?.latitude ? (this.coordinates.latitude).toString() : '0',
      'verifier_long': this.coordinates?.longitude ? (this.coordinates.longitude).toString() : '0',
      'vaccinator_lat': this.coordinates?.latitude ? (this.coordinates.latitude).toString() : '0',
      'vaccinator_long': this.coordinates?.longitude ? (this.coordinates.longitude).toString() : '0',
      'verifier_device': this.deviceInfo.model
    }
    let beneficiaryData = {
      ...this.benData,
      ...this.personalDetForm.getRawValue(),
      ...latLongData
    };
    this.addRecipientService.beneficiaryDetails = beneficiaryData;
    // this.gotoStep('next');
    this.Inprogress = true;

    if (this.benData?.beneficiary_id) {
      this.existingBenficiary(beneficiaryData);
    } else {
      this.newBenficiary(beneficiaryData);
    }
  }

  newBenficiary(beneficiaryData) {
    this.addRecipientService
      .addNewBeneficiary()
      .subscribe((res: any) => {
        this.util.presentToastSuccess("Benificary Added and Verified Successfully");
        let sendResponse = res.beneficiaryDetails;
        this.Inprogress = false;
        /**
         *
         * Commented By
        const ImageUrl={
          file_path: sendResponse?.beneficiaryDetails.upload_doc_url
        }
        if(ImageUrl.file_path !={}){
          this.downloadFile(ImageUrl).subscribe(res=>{
          })
        }
        const ImageUrl1={
          file_path: sendResponse.beneficiaryDetails.employment_certificate
        }
        if(ImageUrl1.file_path !={}){
          this.downloadFile(ImageUrl1).subscribe(res=>{
          })
        }
        sendResponse = sendResponse?.beneficiaryDetails;
        */
        this.addRecipientService.benSuccessDetails = { ...beneficiaryData, ...sendResponse };//for the first time assigned to new observable
        this.gotoStep('next')
      }, err => {
        this.util.presentToastError(err.error.error)
        this.Inprogress = false;
        this.cdr.detectChanges();
      })

  }

  uploadSpecialDocument() {
    const formData = new FormData();
    formData.append('beneficiary_id', this.encDecService.setWithSpace(this.benData?.beneficiary_id));
    formData.append('file', this.uploadedSpecialDocFileDetails?.fileObject, `prophoto-${new Date().getTime()}.${this.uploadedSpecialDocFileDetails?.fileObject?.type}`);
    this.recipientService.uploadSpecialDocument(formData).subscribe(res => {

    })
  }

  existingBenficiary(beneficiaryData: any) {
    if (beneficiaryData?.beneficiary_id) {
      if (beneficiaryData?.verifiedBy == "Manual") {
        this.recipientService.updateBenDetails(beneficiaryData).pipe(
          switchMap(res => {
            return this.recipientService.verifyBeneficiary(beneficiaryData);
          })
        ).subscribe(res => {
          this.saveMetaInfo(beneficiaryData);
          this.Inprogress = false;
          if (this.specialDocument) this.uploadSpecialDocument();
          this.gotoStep('next');
        }, err => {
          this.Inprogress = false;
          this.util.presentToastError(err.error.error)
        })
      }
      else {
        this.recipientService.updateBeneficiaryWithBenefitHealthId(beneficiaryData).pipe(
          switchMap(res => {
            return this.recipientService.verifyBeneficiary(beneficiaryData);
          })
        ).subscribe(res => {
          this.saveRDMetaInfo();
          this.saveMetaInfo(beneficiaryData);
          if (this.specialDocument) this.uploadSpecialDocument();
          this.gotoStep('next');

        }, err => {
          this.Inprogress = false;
          this.util.presentToastError(err.error.error)

        })
      }
      this.addRecipientService.benSuccessDetails = beneficiaryData;
    }
  }

  verifyOTP() {
    const payload = {
      otp: this.personalDetForm.get('otp').value,
      txnId: this.userServ.txnId
    }
    this.userServ.verifyBenOtp(payload).subscribe({
      next: (res: any) => {
        if (res?.verified) {
          this.afterVerification();
        } else {
          this.util.presentToastError('Invalid OTP');
        }
      },
      error: (err) => {
        console.log(err);
      }
    })
  }
}


