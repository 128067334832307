<form [formGroup]="hipForm" class="autoScroll"  (submit)="sendLinkingOTP()">
  <ion-grid *ngIf="!otpSent && !otpVerified && !isDemoGraphic">    
    <h1 class="pageTitle">{{'Link care context with ABDM' | translate}}<ion-icon (click)="close()" class="custom-close"></ion-icon></h1>
    <ion-row>
      <ion-col size="12">
        <ion-label class="required">{{'ABHA Address' | translate}}</ion-label>
        <ion-item class="item-border ion-no-padding" lines="none">
          <ion-input type="text" matInput 
          formControlName="phrAddress" type="text"  errorText=" ">
          </ion-input>
        </ion-item>   
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12" class="ion-text-center">
        <ng-container>
          <h3 class="pageSubTitle">
            {{'Linking care context is in process by Demo Graphic details. Please wait...' | translate}}
          </h3>
          <p><ion-spinner></ion-spinner></p>
        </ng-container>
      </ion-col>
    </ion-row>
    
    <!-- <ion-row>
      <ion-col size="12">
        <ion-label class="required">{{'Authentication Through' | translate}}</ion-label>
        <div class="ion-margin-vertical">
          <mat-radio-group  formControlName="authMode"  aria-label="Select an option" class="auth-type" color="primary">
            <mat-radio-button *ngFor="let re of ['Demographic', 'One Time Password']" 
            [value]="re">{{re | translate}}</mat-radio-button>
          </mat-radio-group>
        </div>
        <ion-label class="error-validation"
          *ngIf="formSubmitted && hipForm.controls.authMode.errors?.required">
          <p>{{'Please Select Authentication Through' | translate}}</p>
        </ion-label>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12" class="ion-text-end">
        <ion-button [disabled]="inProgress" *ngIf="!otpSent"  class="primaryBtn" type="submit">
          <ion-spinner *ngIf="inProgress" name="lines-small"></ion-spinner>
          {{'Submit' | translate}}
        </ion-button>
      </ion-col>
    </ion-row> -->
  </ion-grid>
</form>
<form [formGroup]="otpForm"  (submit)="verifyLinkingOTP()">
  <ion-grid *ngIf="otpSent && !otpVerified && !isDemoGraphic">
   
    <h1 class="pageTitle">{{'Link care context with ABDM' | translate}} <ion-icon (click)="close()" class="custom-close"></ion-icon></h1>
    <ion-row>
      <ion-col size="12">
        <ion-label class="otpTxt">
          {{'Enter OTP sent to the mobile number' | translate}} <b></b>
        </ion-label>
      </ion-col>
    </ion-row>
   
    <ion-row>
      <ion-col size="12" >
        <ion-item class="item-border">
          <ion-input autocomplete="off" formControlName='otp' type='tel' maxlength="6" appIntegerInput
            [appRange]='[000001,999999]' [appMin]='000001' [appMax]='999999' placeholder="- - - - - -"> </ion-input>
          
          <ion-button [disabled]="timer" class="linkBtn" expand="full" fill="outline" type="button"
            (click)="sendLinkingOTP()">
            {{'Resend' | translate}}
          </ion-button>
        </ion-item>
        <ion-label
            *ngIf="!otpForm.controls.otp.valid  && (otpForm.controls.otp.hasError('required'))"
            class="error-validation ">
            <p>{{'Please Enter OTP' | translate}}</p>
          </ion-label>
          <ion-label *ngIf="!otpForm.controls.otp.valid  && (otpForm.controls.otp.hasError('range'))"
            class="error-validation ">
            <p>{{'Must be 6 digits' | translate}}</p>
          </ion-label>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="6" >
        <ion-label *ngIf="timer">{{timer + ' sec '}}</ion-label>
      </ion-col>
      <ion-col size="6" class="ion-text-end">
        <ion-button [disabled]="inProgress" class="primaryBtn" shape="round" type="submit"><ion-spinner
          *ngIf="inProgress" name="lines-small"></ion-spinner>{{'Verify OTP' | translate}}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</form>
<form [formGroup]="demoauthForm"  (submit)="verifyLinkingByDemographic()">
  <ion-grid *ngIf="!otpVerified && isDemoGraphic && authMode === 'DEMOGRAPHICS'">   
    <h1 class="pageTitle">{{'Link care context with ABDM' | translate}}  <ion-icon (click)="close()" class="custom-close"></ion-icon></h1>
    <ion-row>
      <ion-col size="12">
        <ion-grid class="contextDtls ion-no-padding">
          <ion-row class="ion-margin-bottom">
            <ion-col size="12">
              <h3>{{'Name' | translate}}</h3>              
              <ion-text>{{benData.ben_name}}</ion-text>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="4">
              <h3>{{'Gender' | translate}}</h3>
              <ion-text>{{benData.beneficiary_gender}}</ion-text>
            </ion-col>
            <ion-col size="4">
              <h3>{{'Mobile Number' | translate}}</h3>
              <ion-text>{{benData.ben_mobile_number}}</ion-text>
            </ion-col>
            <ion-col size="4">
              <h3>{{'Date of Birth' | translate}}</h3>
              <ion-text><ion-text>{{benData.dob | displayDateFormat}}</ion-text></ion-text>
            </ion-col>            
          </ion-row>          
        </ion-grid>
      </ion-col>
    </ion-row>
    
    <ion-row>
      <ion-col size="12">
        <ion-item class="benificiary-checkbox ion-no-padding" lines="none">
          <ion-checkbox formControlName='concent'></ion-checkbox>
          <ion-label class="no-ellipse-checkbox-label">{{'I Agree to link care context with ABDM.' | translate}}
            <!-- <a (click)="openBottomSheet()" class="view-more concen-btn"
              style="text-decoration: none;font-weight: normal; cursor: pointer;">{{'View More' | translate}}</a> -->
          </ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12" class="ion-text-end">
        <ion-button [disabled]="inProgress" *ngIf="!otpSent"  class="primaryBtn" type="submit">
          <ion-spinner *ngIf="inProgress" name="lines-small"></ion-spinner>
          {{'Submit' | translate}}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</form>

<ion-grid *ngIf="otpVerified" [ngClass]="'abhaDialog'">
    <ion-grid class="ion-no-padding inputFields">
      <h1 style="text-align: center;">{{'Link care context with ABDM' | translate}}</h1>
      <ion-row>
        <ion-col size="12" class="ion-text-center">
          <ion-img *ngIf="linkingSuccess" src="assets/images/green-tick.svg" class="biometric-success"></ion-img>
          <ng-container *ngIf="!linkingSuccess">
            <h3 class="pageSubTitle">
              {{'Linking care context is in process. Please wait...' | translate}}
            </h3>
            <p><ion-spinner></ion-spinner></p>
          </ng-container>
          <ng-container *ngIf="linkingSuccess">
            <h3 class="pageSubTitle">
              {{'Linking care context successfully completed.' | translate}}
            </h3>
          </ng-container>
        </ion-col>
      </ion-row>
    </ion-grid>
</ion-grid>