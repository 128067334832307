import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable} from 'rxjs';
import {User} from "../interfaces/user.interface";
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { EncDecService } from '../../shared/services/encryption-decryption.service';
import { DataService } from '../../shared/services';
import { UtilsService } from '../../shared/services/utils.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  userDetails: any = null;
  txnId = null;
 
  constructor(private http: HttpClient, private encDecService: EncDecService,
    private dataService:DataService,private util:UtilsService,private localStorage:LocalStorageService) {
    }
  secret_key= environment.userKey;
  getUserDetails(): Observable<any> {
    return this.http.get<any>(`${environment.vaccinator_url}/getLoggedInUserInfo`)
      .pipe(map((res) => {
        this.userDetails = res.loggedInUser;
        this.userDetails = this.encDecService.decryptMobileNo(this.userDetails);
        return res.loggedInUser;
      }));
  }
  getSocialGroups() {
    return this.http.get<any>(`${environment.beneficiary_registration_prefix_url}/beneficiary/getSocialgroups`);
  }
  getRegisteredBeneficieryOtp(mobileNo): Observable<any> {
     const payload = {
      mobile: mobileNo,
      secret: this.secret_key
    }
    return this.http.post<any>(`${environment.auth_prefix_url_v2}/generateMobileOTPv2`, payload);
  }

  generateBenOtp(mobileNo): Observable<any> {
    const payload = {
     mobile: mobileNo,
     secret: this.secret_key
   }
   return this.http.post<any>(`${environment.vaccinator_url}/beneficiary/generateBenOtp`, payload);
 }

  verifyRegisteredBeneficieryOtp(payload) {
    payload.otp = this.encDecService.setWithSpace(payload.otp);
    payload.txnId = payload.txnId;
    return this.http.post(`${environment.auth_prefix_url_v2}/validateMobileOtpv2`, payload);
  }

  verifyBenOtp(payload) {
    payload.otp = this.encDecService.setWithSpace(payload.otp);
    payload.txnId = payload.txnId;
    return this.http.post(`${environment.vaccinator_url}/beneficiary/verifyBenOtp`, payload);
  }

  updateProfileDetails(id, data) {
    id = this.encDecService.setWithSpace(id);
    data.userId = id;
    return this.http.post<any>(`${environment.user_prefix_url}/updateUser`, data);
  }
  getUserProfileDetails(){
    return this.http.post<any>(environment.user_prefix_url + '/getDetails', {})
  }
  getProfilePic() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
    let userId= this.dataService.userDetails.user_id;
    if(userId.slice(-1)!= "="){
      userId = this.encDecService.setWithSpace(userId)
    }
    return this.http.post<Blob>(`${environment.vaccinator_url}/download/profilePic`,{userId:userId},
      { headers, responseType: 'blob' as 'json' })
  }
  sendOTPUpdateMobileNumber(postData: any): Observable<any> {
    return this.http.post(environment.user_prefix_url + '/sendOTPUpdateMobileNumber/', postData);
  }
  updateMobileNo(postData: any): Observable<any> {
    return this.http.post(environment.user_prefix_url + '/updateMobileNumber/', postData);
  }
  

  saveProfilePic(params) {
    const formData = new FormData();
    formData.append('file', params.file, `${params.imageName}`);
    // formData.append('userId',(params.userId)); //by default getting encrypted id so not again encrypting
    return this.http.post(`${environment.vaccinator_url}/beneficiary/uploadProfilePic`,
      formData);
  }

  creatAshaUser(userData) {
    console.log("payload",  userData)
    console.log("payload",  userData.mappingAsha[0]["mobile_number"])
    // userData.mappingAsha[0]["mobile_number"] = this.encDecService.setWithSpace(userData.mappingAsha[0]["mobile_number"])
    let payload = {
      ...userData,
      is_active:"1",
      role_id: this.encDecService.setWithSpace(environment.static_ids.ASHAROLE),
      "sub_category_id":"[\"\"]",
      "state_id": this.encDecService.setWithSpace(this.userDetails.state_id),
      "district_id": this.encDecService.setWithSpace(this.userDetails.district_id),
      "mobile_number": this.encDecService.setWithSpace(userData.mobile_number),
      "facility_id": this.encDecService.setWithSpace(userData.facility_id),
      "subdistrict": this.encDecService.setWithSpace(userData.subdistrict),
      "village_id" :  userData.village_id != "" ? userData.village_id.map(e=> this.encDecService.setWithSpace(e)):"",
      "token": JSON.parse(sessionStorage.getItem('UWIN-userToken'))
      // "block_id": this.encDecService.setWithSpace(this.userDetails.block_id),
    };
    if(this.userDetails?.block_id){
      payload['block_id']=this.encDecService.setWithSpace(this.userDetails.block_id);
    }
    console.log("payload",  payload)
    return this.http.post(`${environment.vaccinator_url}/createAndMapAsha`, payload)
  }

  getAshaUsers(){
    // let payload = {"current_page":1,"page_size":300, role_id: this.userDetails.role_id};
     let payload = {session_site_id: this.localStorage.getItem('session_site_id') ? this.localStorage.getItem('session_site_id') : this.encDecService.setWithSpace(this.dataService.userDetails?.session_site_id)}
    return this.http.post(`${environment.vaccinator_url}/getAsha`, payload)
    .pipe(map((r: any) => {
      r.map(s=>{
        this.encDecService.decryptMobileNo(s)
        this.encDecService.decryptLocationId(s)
        // s.session_site_id = this.encDecService.get(s.session_site_id)
      });
      return r;
    }));
  }

  toggleUserActiveStatus(userDet){
    let payload = {user_id: this.encDecService.setWithSpace(userDet.user_id), isActive: userDet.is_active ? '0': '1'};
    return this.http.post(`${environment.admin_prefix_v3_url}/userMgmt/updateUserStatus`, payload);
  }

  resetPwdOfAshaUser(payload){
    return this.http.post(`${environment.admin_prefix_v3_url}/userMgmt/resetPasswordByAdmin`, payload);
  }

  mappingAshaVillager(payload){
    payload.session_site_id = this.encDecService.setWithSpace(payload.session_site_id),
    payload.subdistrict_id = this.encDecService.setWithSpace(payload.subdistrict_id)
    return this.http.post(`${environment.vaccinator_url}/mappingAshaVillager`, payload);
  }
  deleteAshaUser(payload){
    return this.http.post(`${environment.admin_prefix_v3_url}/userMgmt/deleteUser`, payload);
  }
  

}
