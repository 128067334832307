import { Injectable } from '@angular/core';
import { interval, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError, map, publishReplay, refCount, switchMap } from 'rxjs/operators';
import { RemoteInterfaceService } from '../../remote-interface/service/remote-address.service';
import { UtilsService } from 'src/app/modules/shared/services/utils.service';
import { MatDialog } from '@angular/material/dialog';
import { PairDeviceDialog } from '../../remote-interface/components/pair-device-dialog/pair-device-dialog';
import { LookUpService } from 'src/app/modules/shared/services/look-up.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { EncDecService } from 'src/app/modules/shared/services/encryption-decryption.service';
import * as moment from 'moment';
@Injectable({
  providedIn: 'root'
})
export class AadharService {
  yobList: any;
  genderGrp = { 1: 'M', 2: 'F', 3: 'O' };
  genderGrpFull = { 'M':1,'F':2, 'O':3 };
  personalDetForm: FormGroup;

  constructor(
    private http: HttpClient,
    public remoteInterfaceService: RemoteInterfaceService,
    public util: UtilsService,
    public dialog: MatDialog,
    private lookup: LookUpService,
    private sanitization: DomSanitizer,
    private encDecService: EncDecService,
  ) { this.lookup.getYob().subscribe(res => this.yobList = res); }

  buildForm(): FormGroup {
    return this.personalDetForm = new FormGroup({
      adhaarNumber: new FormControl(''),
      ben_name: new FormControl(null, [Validators.required]),
      yob: new FormControl('', Validators.required),
      gender: new FormControl('', Validators.required),
      user_photo: new FormControl(''),
      consent: new FormControl('', Validators.required),
      female_consent: new FormControl(null),
    });
  }

  openPairDevicePopup() {
    const dialogRef = this.dialog.open(PairDeviceDialog,
      {
        width: "450px",
      });
  }

  enableFaceRdService() {
    const suid = localStorage.getItem('suid');
    if (this.util.getIsDeskTop()) {
      try {
        if (!suid) {
          return false;
        } else {
          return true;
        }
      } catch (e) {
        return false;
      }
    } else {
      return true;
    }
  }

  setDataInFormNewQRDeskTop(aadharData: any, formGrp: FormGroup) {
    const g = (aadharData.gender.toString().toUpperCase() === 'M' || aadharData.gender.toString().toUpperCase() === 'MALE') ? 1 :
      (aadharData.gender.toString().toUpperCase() === 'F' || aadharData.gender.toString().toUpperCase() === 'FEMALE') ? 2 : 3;
    let yob = aadharData.yob ? aadharData.yob : aadharData.yearOfBirth ? aadharData.yearOfBirth : null;

    if (aadharData.dob && aadharData.dob !== 'l version=') {
      let dates = aadharData.dob;
      if (aadharData.dob.includes('-')) {
        dates = aadharData.dob.split('-');
      } else {
        dates = aadharData.dob.split('/');
      }
      const yobObj = this.yobList.filter(obj => obj.year === parseInt(dates[2]));
      if (yobObj) {
        const obj = yobObj[0];
        formGrp.controls.yob.setValue('');
        setTimeout(() => {
          formGrp.controls.yob.setValue(obj.id);
          formGrp.controls.yob.disable();
        }, 10);
      }
    }
    if (yob) {
      const yobObj = this.yobList.find(obj => obj.year === parseInt(yob));

      if (yobObj) {
        formGrp.controls.yob.setValue(yobObj.id);
        formGrp.controls.yob.disable();
      }
    }

    // if (aadharData.uid) {
    //   if (aadharData.uid.indexOf('XXXXXXXX') == -1) {
    //     formGrp.controls.adhaarNumber.setValue(aadharData.uid);
    //   }
    // }

    if (aadharData.name) {
      formGrp.controls.ben_name.setValue(aadharData.name);
      formGrp.controls.ben_name.disable();
    }

    formGrp.controls.gender.setValue(g);
    formGrp.controls.gender.disable();
    if (aadharData.photo) {
      let imgSrc = this.sanitization.bypassSecurityTrustUrl("data:image/png;base64," + aadharData.photo);
      formGrp.controls.user_photo.setValue(imgSrc);
    }
    if (aadharData.profilePhoto) {
      let imageSrc = "data:image/png;base64," + aadharData.profilePhoto;
      formGrp.controls.user_photo.setValue(imageSrc);
    }

  }




  getAccessToken(): Observable<any> {
    const isProd = environment.isProduction;
    let payload = {};
    if (isProd) {
      payload = { env: this.encDecService.set('Prod') }
    } else {
      payload = { env: this.encDecService.set('Not Prod') }
    }
    return this.http.post(
      `${environment.aadhar_vaccinator_url}/getToken`,
      payload
    );
  }

  verifyDemoGraphicDetails(postData): Observable<any> {
    const isProd = environment.isProduction;
    if (isProd) {
      postData['env'] = this.encDecService.set('Prod')
    } else {
      postData['env'] = this.encDecService.set('Not Prod')
    }
    // postData.aadhaar = this.encDecService.set(postData.aadhaar)
    return this.http.post<any>(
      `${environment.aadhar_vaccinator_url}/demoAuthv2`,
      postData
    );
  }

  verifyBiometricAuth(postData): Observable<any> {
    const isProd = environment.isProduction;
    if (isProd) {
      postData['env'] = this.encDecService.set('Prod')
    } else {
      postData['env'] = this.encDecService.set('Not Prod')
    }
    return this.http.post<any>(
      `${environment.aadhar_vaccinator_url}/verifyBiov2`,
      postData
    );
  }

  saveErrorLogOfFaceAuth(biometricInfo, pidData) {
    const pidJSON = biometricInfo.PidData;
    const response = pidJSON.Resp['@attributes'];
    const errMsg = response?.errInfo ? response.errInfo : 'Face Capture Failed';


    let params: any = {};
    pidJSON.CustOpts['Param'].forEach(ele => {
      params[ele['@attributes']['name']] = ele['@attributes']['value'];
    });

    const postData = {
      ...params,
      suid: localStorage.getItem('suid'),
      errCode: response.errCode,
      errInfo: response.errInfo,
      dateOfTrns: moment().format(),
      pidData: btoa(pidData)
    };
    this.logFaceAuthErrors(postData);
  }

  logFaceAuthErrors(postData) {
    this.http.post<any>(`${environment.vaccinator_url}/createDesktopRDError`, postData)
      .subscribe();
  }

  verifyFaceAuth(payload): Observable<any> {
    const isProd = environment.isProduction;
    if (isProd) {
      payload['env'] = this.encDecService.set('Prod')
    } else {
      payload['env'] = this.encDecService.set('Not Prod')
    }
    return this.http.post<any>(
      `${environment.aadhar_vaccinator_url}/verifyFaceAuthv2`,
      payload
    ).pipe(map(response => {
      if (response) {
        return JSON.parse(this.encDecService.get(response.result));
      }
      return response;
    }));
  }

  generateOTP(postData): Observable<any> {
    const isProd = environment.isProduction;
    if (isProd) {
      postData['env'] = this.encDecService.set('Prod')
    } else {
      postData['env'] = this.encDecService.set('Not Prod')
    }
    return this.http.post<any>(
      `${environment.aadhar_vaccinator_url}/getOtp`,
      postData
    );
  }

  sendOtpToRegisteredAadharMobileNo(aadhaarNumber): Observable<any> {
    const aadhaarnumber = this.encDecService.set(aadhaarNumber);
    return this.getAccessToken().pipe(
      switchMap(res => {
        sessionStorage.setItem('aadhaarAccessToken', JSON.stringify(res.token));
        const verifyAdhaarPostData = {
          aadhaar: '' + aadhaarnumber,
          token: res.token
        };
        return this.generateOTP(verifyAdhaarPostData);
      })
    );
  }

  generateVaccinatorOTP(postData): Observable<any> {
    const isProd = environment.isProduction;
    if (isProd) {
      postData['env'] = this.encDecService.set('Prod');
    } else {
      postData['env'] = this.encDecService.set('Not Prod');
    }
    return this.http.post<any>(
      `${environment.aadhar_vaccinator_url}/getOtp`,
      postData
    );
  }

  sendVaccinatorOtpToRegisteredAadharMobileNo(aadhaarNumber): Observable<any> {
    const aadhaarnumber = this.encDecService.set(aadhaarNumber);
    const verifyAdhaarPostData = {
      aadhaar: '' + aadhaarnumber
    };
    return this.generateVaccinatorOTP(verifyAdhaarPostData);
  }

  verifyAadhaarOTP(postData): Observable<any> {
    const isProd = environment.isProduction;
    if (isProd) {
      postData['env'] = this.encDecService.set('Prod')
    } else {
      postData['env'] = this.encDecService.set('Not Prod')
    }
    return this.http.post<any>(
      `${environment.aadhar_vaccinator_url}/verifyOTPv2`,
      postData
    );
  }

  verifyVacinatorAadhaarOTP(postData): Observable<any> {
    const isProd = environment.isProduction;
    if (isProd) {
      postData['env'] = this.encDecService.set('Prod')
    } else {
      postData['env'] = this.encDecService.set('Not Prod')
    }
    return this.http.post<any>(
      `${environment.aadhar_vaccinator_url}/verifyOTP`,
      postData
    );
  }

  linkABHAAddressData(postData): Observable<any> {
    const isProd = environment.isProduction;
    if (isProd) {
      postData['env'] = this.encDecService.set('Prod')
    } else {
      postData['env'] = this.encDecService.set('Not Prod')
    }
    return this.http.post<any>(
      `${environment.aadhar_vaccinator_url}/ABHAAddress`,
      postData
    );
  }

  verifyOTP(formData) {
    return this.getAccessToken().pipe(
      switchMap(res => {
        sessionStorage.setItem('aadhaarAccessToken', JSON.stringify(res.token));
        const verifyAdhaarPostData = {
          otp: this.encDecService.set('' + formData.otp),
          txnId: formData.txnId,
          token: res.token,
          consentHealthId: true,
          flag: 'surveyor'
        };
        return this.verifyAadhaarOTP(verifyAdhaarPostData);
      })
    ).pipe(map(response => {
      if (response) {
        return JSON.parse(this.encDecService.get(response.result));
      }
      return response;
    }));
  }

  verifyVaccinatorOTP(formData) {
    const verifyAdhaarPostData = {
      otp: this.encDecService.set('' + formData.otp),
      txnId: formData.txnId,
      consentHealthId: true,
      beneficiary_reference_id: this.encDecService.set('' + formData.beneficiary_reference_id)
    };
    return this.verifyVacinatorAadhaarOTP(verifyAdhaarPostData);
  }

  linkABHAAddress(formData) {
    const verifyAdhaarPostData = {
      token: formData.token,
      beneficiary_reference_id: this.encDecService.set('' + formData.beneficiary_reference_id),
      ABHAAddress: formData.ABHAAddress
    };
    return this.linkABHAAddressData(verifyAdhaarPostData);
  }

}
