import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import {
  LoadingController,
  AlertController,
  ToastController,
} from '@ionic/angular';
import { Platform } from '@ionic/angular';
import { PluginListenerHandle } from '@capacitor/core';
import { Network, NetworkStatus } from '@capacitor/network';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

import * as moment from 'moment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Observer } from 'rxjs/internal/types';
import { FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { ReplaySubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  validationMessage = new ReplaySubject(1);
  sessionLength = new ReplaySubject(1);
  refreshMobileSessions = new ReplaySubject(1);
  showSummaryVaccinationScreen = new ReplaySubject(1);
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  networkStatus: NetworkStatus;
  networkListener: PluginListenerHandle;
  private isMobileDevice = false;
  constructor(
    private location: Location,
    public loadingController: LoadingController,
    private toastr: ToastController,
    private platform: Platform,
    public alertController: AlertController,
    private http: HttpClient,
    private _snackBar: MatSnackBar,
    private translate: TranslateService
  ) { }

  getPlatformWidth() {
    // console.log("getIsApp", this.getIsApp())
    return this.platform.width();
  }

  // getIsApp() {
  //   // || this.platform.is('mobileweb')
  //   if(this.platform.is('core')) {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // }

  numSequence(n): Array<number> {
    let arr = [];
    for (let i = 0; i < parseInt(n); i++) {
      arr.push(i);
    }
    return arr;
  }

  removeEmptyObjects(obj: any) {
    return Object.keys(obj)
      .filter((k) => obj[k] !== '' && obj[k] !== null && obj[k] !== undefined) // Remove undef. and null.
      .reduce(
        (newObj, k) =>
          typeof obj[k] === 'object'
            ? Object.assign(newObj, { [k]: this.removeEmptyObjects(obj[k]) }) // Recurse.
            : Object.assign(newObj, { [k]: obj[k] }), // Copy value.
        {}
      );
  }

  isEmptyObject(obj) {
    return obj && Object.keys(obj).length === 0;
  }

  parseJson(str) {
    try {
      const json = JSON.parse(str);
      return typeof json === 'object' ? json : str;
    } catch (e) {
      return str;
    }
  }

  isPresent(str): boolean {
    if (str === '' || str === null || str === undefined) {
      return false;
    }
    return true;
  }

  blobToDataURL(blob, callback) {
    const a = new FileReader();
    a.onload = (e) => {
      callback(e.target.result);
    };
    a.readAsDataURL(blob);
  }

  /* showErrorToast(msg) {
    this.tostr.error(msg, 'Error');
  }

  showSuccessToast(msg) {
    this.tostr.success(msg, 'Success');
  }

  showWarningToast(msg) {
    this.tostr.warning(msg, 'Warning');
  }

  showInfoToast(msg) {
    this.tostr.info(msg, 'Info');
  } */

  parseToJsonObject(res) {
    let body = [];
    try {
      res.forEach((element, index) => {
        const parseObject = JSON.parse(element);
        if (this.isPresent(parseObject.lastLoggedInOut)) {
          // parseObject.lastLoggedInOut = moment(parseObject.lastLoggedInOut).add(330, 'minutes').toDate();
        }
        body.push(parseObject);
      });
    } catch {
      for (const obj of res) {
        if (this.isPresent(obj.lastLoggedInOut)) {
          // obj.lastLoggedInOut = moment(obj.lastLoggedInOut).add(330, 'minutes').toDate();
        }
      }
      body = res;
    }
    return body;
  }

  sortComparer(reference: string, comparer: string) {
    if (reference == null || reference === undefined) {
      reference = '';
    }
    if (comparer == null || comparer === undefined) {
      comparer = '';
    }
    if (
      reference.toString().trim().toLowerCase() <
      comparer.toString().trim().toLowerCase()
    ) {
      return -1;
    }
    if (
      reference.toString().trim().toLowerCase() >
      comparer.toString().trim().toLowerCase()
    ) {
      return 1;
    }
    return 0;
  }

  handleGridData(res, reqType) {
    if (reqType === 'add') {
      return this.parseToJsonObject(res)
        .sort((a, b) => this.sortComparer(a.date_created, b.date_created))
        .reverse();
    } else if (reqType === 'edit') {
      return this.parseToJsonObject(res)
        .sort((a, b) => this.sortComparer(a.date_modified, b.date_modified))
        .reverse();
    } else {
      return this.parseToJsonObject(res);
    }
  }

  replaceSpaceToUnderscore(str) {
    return str.replace(/ /g, '_');
  }

  replaceHyphenToUnderscore(str) {
    return str.replace(/-/g, '_');
  }
  mask(str) {
    str = str + '';
    if (str) {
      var vis = str.slice(-4);
      var countNum = '';

      for (var i = str.length - 4; i > 0; i--) {
        countNum += '*';
      }
      return countNum + vis;
    }
  }

  maskForLastChars(str) {
    str = str + '';
    if (str) {
      return str.replace(/[0-9]{4}$/, '****');
    }
  }

  last4char(str) {
    str = str + '';
    if (str) {
      var vis = str.slice(-4);
      return vis;
    }
  }
  checkDataType(val, type = 'str') {
    if (type == 'int') {
      return val ? parseInt(val) : 0;
    }
    return val ? val : '';
  }

  trimIdCard(str) {
    str = str + '';
    if (str) {
      if (str.length > 15) {
        str = str.slice(0, 15) + '...';
      }

      return str;
    }
  }

  shortNumber(value) {
    if (value === null) {
      return null;
    }
    if (value === 0) {
      return '0';
    }
    const fractionSize = 1;
    let abs = Math.abs(value);
    const rounder = Math.pow(10, fractionSize);
    const isNegative = value < 0;
    let key = '';
    const powers = [
      { key: 'Q', value: Math.pow(10, 15) },
      { key: 'T', value: Math.pow(10, 12) },
      { key: 'B', value: Math.pow(10, 9) },
      { key: 'M', value: Math.pow(10, 6) },
      { key: 'k', value: 1000 },
    ];
    for (let i = 0; i < powers.length; i++) {
      let reduced = abs / powers[i].value;
      reduced = Math.round(reduced * rounder) / rounder;
      if (reduced >= 1) {
        abs = reduced;
        key = powers[i].key;
        break;
      }
    }
    return (isNegative ? '-' : '') + abs + key;
  }

  wordwrap(str, width, spaceReplacer) {
    if (str.length > width) {
      let p = width;
      for (; p > 0 && str[p] !== ' '; p--) { }
      if (p > 0) {
        const left = str.substring(0, p);
        const right = str.substring(p + 1);
        return (
          left + spaceReplacer + this.wordwrap(right, width, spaceReplacer)
        );
      }
    }
    return str;
  }

  goToPreviousRoute() {
    this.location.back();
  }

  b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  presentLoading() {
    return this.loadingController.create({
      cssClass: 'my-custom-class',
      message: '',
      duration: 50,
    });
  }
  async presentToastError(toastMessage, position?) {
    let pos;
    if (this.isMobileDevice) {
      pos = position ? position : 'bottom';
    } else {
      pos = position ? position : 'bottom';
    }

    // const toast = await this.toastr.create({
    //   message: toastMessage,
    //   duration: 5000,
    //   position: pos,
    //   cssClass: 'danger',
    //   color: 'danger'
    // });
    // toast.present();
    const translatedMessage = this.translate.instant(toastMessage);

    this._snackBar.open(translatedMessage, null, {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 5000,
      panelClass: 'danger',
    });
  }

  async presentToastSuccess(toastMessage, position?) {
    let pos;
    if (this.isMobileDevice) {
      pos = position ? position : 'bottom';
    } else {
      pos = position ? position : 'bottom';
    }
    // const toast = await this.toastr.create({
    //   message: toastMessage,
    //   duration: 5000,
    //   position: pos,
    //   cssClass: 'success',
    //   color: 'success'
    // });
    // toast.present();
    const translatedMessage = this.translate.instant(toastMessage);
    
    this._snackBar.open(translatedMessage, null, {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 5000,
      panelClass: 'success',
    });
  }

  async presentToastInfo(toastMessage, position?) {
    let pos;
    if (this.isMobileDevice) {
      pos = position ? position : 'bottom';
    } else {
      pos = position ? position : 'bottom';
    }
    // const toast = await this.toastr.create({
    //   message: toastMessage,
    //   duration: 5000,
    //   position: pos,
    //   cssClass: 'info',
    //   color: 'info'
    // });
    // toast.present();
    const translatedMessage = this.translate.instant(toastMessage);
    
    this._snackBar.open(translatedMessage, null, {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 5000,
      panelClass: 'info',
    });
  }

  getIsMobileDevice() {
    if (
      !this.platform.is('desktop') &&
      (this.platform.is('cordova') || this.platform.is('hybrid'))
    ) {
      this.isMobileDevice = true;
    } else {
      this.isMobileDevice = false;
    }
    return this.isMobileDevice;
  }

  getIsDeskTop() {
    return this.platform.is('desktop');
  }

  async observeNetwork() {
    this.networkListener = Network.addListener(
      'networkStatusChange',
      (status) => {
        if (!status.connected) {
          this.presentToastError('Network disconnected');
        }
        this.networkStatus = status;
      }
    );

    this.networkStatus = await Network.getStatus();
  }

  getLastWeekDayNames() {
    const days = [];
    for (let i = 7; i > 0; i--) {
      const day = moment().subtract(i, 'days');
      days.push(day.format('ddd'));
    }
    return days;
  }

  ordinalInWord(i) {
    const n = [
      'one',
      'two',
      'three',
      'four',
      'five',
      'six',
      'seven',
      'eight',
      'nine',
      'ten',
      'eleven',
      'twelve',
      'thirteen',
      'fourteen',
      'fifteen',
      'sixteen',
      'seventeen',
      'eighteen',
      'nineteen',
    ];
    const s = [
      'Zeroth',
      'First',
      'Second',
      'Third',
      'Fourth',
      'Fifth',
      'Sixth',
      'Seventh',
      'Eighth',
      'Ninth',
      'Tenth',
      'Eleventh',
      'Twelfth',
      'Thirteenth',
      'Fourteenth',
      'Fifteenth',
      'Sixteenth',
      'Seventeenth',
      'Eighteenth',
      'Nineteenth',
    ];
    const p = [
      'twent',
      'thirt',
      'fourt',
      'fift',
      'sixt',
      'sevent',
      'eight',
      'ninet',
    ];
    const c = [
      'hundred',
      'thousand',
      'million',
      'billion',
      'trillion',
      'quadrillion',
      'quintillion',
    ];
    const b = Math.floor(Math.log10(i));
    if (i < 20) {
      return s[i];
    } // Special case for sub-20
    if (b === 1) {
      // Between 21 and 99
      if (i % 10 === 0) {
        return p[Math.floor(i / 10) - 2] + 'ieth';
      } // On the tens, return p+"ieth"
      return p[Math.floor(i / 10) - 2] + 'y-' + s[i % 10]; // Otherwise, return hyphenated
    }
    if (b === 2) {
      // Between 100 and 999
      const e = Math.floor(i / Math.pow(10, b)); // The first number
      return (
        n[e - 1] +
        '-' +
        c[0] +
        ' ' +
        this.ordinalInWord(i - e * Math.pow(10, b))
      );
    }
    // Greater than 1000 we break into groups of 10^3 followed by a multiplier
    const m = (b % 3) + 1; // Take the first m digits off
    const cm = Math.floor(b / 3);
    const x = Math.floor(i / Math.pow(10, b - m + 1));
    const numberToString = function (y) {
      // Converts a number less than 1000 to its string representation as a multiplier
      if (y < 20) {
        return n[y - 1];
      }
      if (y < 100) {
        return p[Math.floor(y / 10) - 2] + 'y-' + n[(y % 10) - 1];
      }
      return (
        n[Math.floor(y / 100) - 1] +
        ' ' +
        c[0] +
        ' ' +
        numberToString(y - Math.floor(y / 100) * 100)
      );
    };
    return (
      numberToString(x) +
      ' ' +
      c[cm] +
      ' ' +
      this.ordinalInWord(i - x * Math.pow(10, b - m + 1))
    );
  }

  async presentAlertConfirm(options) {
    const alert = await this.alertController.create({
      cssClass: options.cssClass ? options.cssClass : 'alert-confirm-box',
      header: options.header ? options.header : 'Confirm!',
      message: options.message ? options.message : '',
      inputs: options.inputs ? options.inputs : [],
      buttons: options.buttons ? options.buttons : [],
    });

    await alert.present();
  }

  dataURItoBlob(dataURI: string): Observable<Blob> {
    return new Observable((observer: Observer<Blob>) => {
      const byteString: string = window.atob(dataURI);
      const arrayBuffer: ArrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array: Uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([int8Array], { type: 'image/jpeg' });
      observer.next(blob);
      observer.complete();
    });
  }

  generateName(): string {
    const date: number = new Date().valueOf();
    let text: string = '';
    const possibleText: string =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 5; i++) {
      text += possibleText.charAt(
        Math.floor(Math.random() * possibleText.length)
      );
    }
    // Replace extension according to your media type like this
    return date + '.' + text + '.jpg';
  }

  /**Method that will create Blob and show in new window */
  createBlobImageFileAndShow(base64Str, cb): void {
    this.dataURItoBlob(base64Str).subscribe((blob: Blob) => {
      const imageBlob: Blob = blob;
      const imageName: string = this.generateName();
      const imageFile: File = new File([imageBlob], imageName, {
        type: 'image/jpeg',
      });
      // // on demo image not open window
      // window.open(window.URL.createObjectURL(imageFile));
      cb(imageBlob);
    });
  }

  downloadFile(fileUrl): Observable<any> {
    const requestOptions: Object = {
      responseType: 'blob' as 'blob',
    };
    return this.http.get<any>(
      `${environment.admin_prefix_url_port}${fileUrl}`,
      requestOptions
    );
  }
  createPromiseBlobImageFileAndShow(base64Str) {
    return new Promise((resolve, reject) => {
      this.dataURItoBlob(base64Str).subscribe((blob: Blob) => {
        const imageBlob: Blob = blob;
        const imageName: string = this.generateName();
        const imageFile: File = new File([imageBlob], imageName, {
          type: "image/jpeg"
        });
        // // on demo image not open window
        // window.open(window.URL.createObjectURL(imageFile));
        resolve(imageBlob);
      });
    });
  }

  /**
   * For version older than Angular 8
   * Marks all controls in a form group as touched
   * @param formGroup - The form group to touch
   */
  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control: any) => {
      control.clearValidators();
      control.updateValueAndValidity();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  getPaginatedItems(items, page, pageSize) {
    const pg = page || 1;
    const pgSize = pageSize || 100;
    const offset = (pg - 1) * pgSize;
    const pagedItems = _.drop(items, offset).slice(0, pgSize);
    return {
      page: pg,
      pageSize: pgSize,
      total: items.length,
      total_pages: Math.ceil(items.length / pgSize),
      data: pagedItems,
    };
  }

  filterArray(arr: Object[], filters: Object) {
    const getValue = (value) =>
      typeof value === 'string' ? value.toUpperCase() : value;
    const filterKeys = Object.keys(filters);
    return arr.filter((item) => {
      // filters using the (OR) operator
      return filterKeys.some((key) => {
        // ignores an empty filter
        if (!filters[key].length) return true;
        return filters[key].find((filter) => filter == item[key]);
      });
    });
  }

  filterArrByKey(coll, value) {
    return _.filter(
      coll,
      _.flow(
        _.values,
        _.partialRight(_.some, _.method('match', new RegExp(value, 'i')))
      )
    );
  }

  findInvalidControls(formGrp) {
    const invalid = [];
    const controls = formGrp.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }


  ageCal(dob){
    const birthdate = moment(dob);
    console.log('@@@@@@@@@', dob, birthdate);
    if (birthdate.isValid()) {
      console.log('################', dob, birthdate);
      const today = moment();
      const years = today.diff(birthdate, 'years');
        const months = today.subtract(years, 'years').diff(birthdate, 'months');
        const days = today.subtract(months, 'months').diff(birthdate, 'days');
      return {
        year: years,
        month: months,
        day: days
      }
      
    }
  }

  getCurrAge(benf) {
    return parseInt(moment().format("YYYY")) - parseInt(benf.year);
  }


}
