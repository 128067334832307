import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'displayDateByAge'
})
export class DisplayDateByAgePipe implements PipeTransform {
  transform(value: any): string {
// console.log('displayDateByAge', Math.round(moment().diff(moment(value, 'DD-MMM-YYYY'), 'years', true)));
   if(Math.round(moment().diff(moment(value, 'DD-MMM-YYYY'), 'years', true)) > 18){
    return moment(value).format('YYYY');
   }
   const date = moment(value).format(environment.display_date_format);
   return date
  }
}