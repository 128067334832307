import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidenavService {
  private sideNavSubject: BehaviorSubject<any>;
  public sidenavData: Observable<any>;
  private sidenav: MatSidenav;
  public isOpened = false;

  constructor() {
    this.sideNavSubject = new BehaviorSubject<any>(null);
    this.sidenavData = this.sideNavSubject.asObservable();
  }

  public setSidenav(sidenav: MatSidenav) {
    this.sidenav = sidenav;
    this.sideNavSubject.next(sidenav);
  }

  public open() {
    this.isOpened = true;
    this.sideNavSubject.next(this.isOpened);
    return this.sidenav.open();
  }

  public close() {
    this.isOpened = false;
    this.sideNavSubject.next(this.isOpened);
    return this.sidenav.close();
  }

  public toggle(): void {
    this.isOpened = !this.isOpened;
    this.sideNavSubject.next(this.isOpened);
  }
}
