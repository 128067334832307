import { Optional, Inject, Output, EventEmitter, OnDestroy } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { UtilsService } from './../../../../modules/shared/services/utils.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { PairDeviceDialog } from '../../../remote-interface/components/pair-device-dialog/pair-device-dialog';
import { environment } from 'src/environments/environment';
import { NgxXmlToJsonService } from 'src/app/modules/shared/services/ngx-xml-to-json.service';
import { EncDecService } from 'src/app/modules/shared/services/encryption-decryption.service';
import { AadharService } from '../../services/aadhar.service';
import { AddRecipientService } from 'src/app/screens/add-recipient/services/add-recipient.service';
import { LookUpService } from 'src/app/modules/shared/services/look-up.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AadhaarConcentComponent } from 'src/app/modules/shared/components/aadhaar-concent/aadhaar-concent.component';
import { BiometricRdService } from './biometric.service';
import AadhaarCalls from '../../android-native-plugin/aadhaar-calls-plugin';


@Component({
  selector: 'app-aadhar-biometric-verification',
  styleUrls: ['./biometric.scss'],
  templateUrl: './biometric.html',
})
export class BiometricComponent implements OnInit, OnDestroy {
  @Output() gotoStep: EventEmitter<any> = new EventEmitter();
  aadhareKYC: FormGroup;
  beneficiaryData: any;
  yobList: any;
  personalDetForm: any;
  scanInprogress = false;
  scanSuccess = false;
  environment;
  constructor(
    private util: UtilsService,
    public dialog: MatDialog,
    private ngxXml2jsonService: NgxXmlToJsonService,
    private encDecService: EncDecService,
    private aadharService: AadharService,
    public addRecipientService: AddRecipientService,
    public rdService: BiometricRdService,
    private lookupService: LookUpService,
    private _bottomSheet: MatBottomSheet
  ) {

    this.personalDetForm = new FormGroup({
      female_consent: new FormControl(''),
      consent: new FormControl('', [Validators.required])
    })

    this.lookupService.getYob().subscribe(res => {
      this.yobList = res
    })

    this.aadhareKYC = new FormGroup({
      photo_id_type: new FormControl(1),
      photo_id_number: new FormControl(null, [Validators.required]),
      eKYC_by: new FormControl(null, [Validators.required]),
    });
  }

  ngOnInit() {
    this.environment = environment;
    this.addRecipientService.beneficiaryData.subscribe(benf => {
      this.beneficiaryData = benf;
      if (this.beneficiaryData != null && this.beneficiaryData != undefined) {
        if (this.beneficiaryData.ben_type_id == environment.static_ids.PregnantWomen || this.beneficiaryData.ben_type_id == environment.static_ids.LactatingMother) {
          this.personalDetForm.get('female_consent').setValidators([Validators.required]);
        }
        else {
          this.personalDetForm.get('female_consent').clearValidators();
          this.personalDetForm.get('female_consent').reset();
        }
      }
    });
  }

  private initializeBioMetricDevice() {
    this.scanSuccess = false;
    this.scanInprogress = true;
    this.rdService.getRDServiceInfo().pipe(
      switchMap((res: any) => {
        // const parser = new DOMParser();
        // const xml = parser.parseFromString(res, 'text/xml');
        const rdDeviceInfo: any = this.ngxXml2jsonService.xmlToJson(res,{});
        if (rdDeviceInfo && rdDeviceInfo.hasOwnProperty('RDService')) {
          const rdServiceObj = rdDeviceInfo.RDService;
          const attributes = rdServiceObj['@attributes'] || rdServiceObj['attr'];
          if (attributes && attributes.hasOwnProperty('status') && attributes.status === 'READY') {
            return this.rdService.openRDdevice();
          } else {
            throw { errorCode: 'RD000001', errorMessage: 'RD Device is not connected' };
          }
        } else {
          throw { errorCode: 'RD000001', errorMessage: 'RD Device is not connected' };
        }
      })
    ).subscribe(res => {
      this.scanInprogress = false;
      // const parser = new DOMParser();
      // const xml = parser.parseFromString(res, 'text/xml');
      const biometricInfo: any = this.ngxXml2jsonService.xmlToJson(res,{});
      if (biometricInfo && biometricInfo.hasOwnProperty('PidData')) {
        const pidJSON = biometricInfo.PidData;
        if (pidJSON && pidJSON.hasOwnProperty('Resp')) {
          const response = pidJSON.Resp['@attributes'] || pidJSON.Resp['attr'];
          if (response.errCode !== '0') {
            this.util.presentToastError(response.errInfo);

          } else {
            const pidData = res;
            const aadhaarNumber = this.encDecService.set(this.beneficiaryData.aadhaarNumber);
            this.verifyUsingBioMetricAuth(pidData, aadhaarNumber);
            // this.hideAuthenticationAndConsent = true;
          }
        }
      } else {
        this.util.presentToastError('Invalid Response');
        // this.showAuthButton = false;
      }
    }, err => {
      this.scanInprogress = false;
      if (err && err.hasOwnProperty('errorMessage')) {
        this.util.presentToastError(err.errorMessage);
        // this.showAuthButton = false;
      }
    });
  }

  private initializeBioMetricDeviceMobile() {
    AadhaarCalls.getRDdeviceInfo().then(res => {
      if (res.isDeviceConnected) {
        AadhaarCalls.captureFingerPrint().then(pidDataRes => {
          if (pidDataRes.hasOwnProperty('PID_DATA')) {
            const pidData = pidDataRes.PID_DATA;
            const parser = new DOMParser();
            const xml = parser.parseFromString(pidData, 'text/xml');
            const biometricInfo: any = this.ngxXml2jsonService.xmlToJson(res,{});
            // console.log(biometricInfo);
            if (biometricInfo && biometricInfo.hasOwnProperty('PidData')) {
              const pidJSON = biometricInfo.PidData;
              if (pidJSON && pidJSON.hasOwnProperty('Resp')) {
                const response = pidJSON.Resp['@attributes'] || pidJSON.Resp['attr'];
                if (response.errCode !== '0') {
                  this.util.presentToastError(response.errInfo);
                  // this.showAuthButton = false;
                } else {
                  const aadhaarNumber = this.encDecService.set(this.beneficiaryData.aadhaarNumber);
                  this.verifyUsingBioMetricAuth(pidData, aadhaarNumber);
                }
              }
            }
          } else {
            this.util.presentToastError('Timeout');
            // this.showAuthButton = false;
          }
        }).catch(err => {
          this.util.presentToastError('Invalid Response');
          // this.showAuthButton = false;
        });
      } else {
        this.util.presentToastError('RD Device is not connected');
        // this.showAuthButton = false;
      }
    }).catch(err => {
      this.util.presentToastError('RD Device is not connected');
      // this.showAuthButton = false;
    });
  }

  async verifyUsingBioMetricAuth(pidData: any, aadhaarNumber: any) {
    this.scanSuccess = false;
    this.aadharService.getAccessToken().pipe(
      switchMap(res => {
        sessionStorage.setItem('aadhaarAccessToken', JSON.stringify(res.token));
        const verifyAdhaarPostData = {
          aadhaar: '' + aadhaarNumber,
          consentHealthId: true,
          token: res.token,
          pid: pidData,
          flag: 'surveyor'
        };
        return this.aadharService.verifyBiometricAuth(verifyAdhaarPostData);
      })
    ).subscribe(response => {
      let res = JSON.parse(this.encDecService.get(response.result));
      if (Object.keys(res).length === 0) {
        this.util.presentToastError('BENEFICIARY.VALIDATION.BIOMETRIC_AUTHENTICATION_FAILED');
      } else {
        this.scanSuccess = true;
        let beneficiaryData = {
          ...this.beneficiaryData,
          ...{
            verifiedBy: 'Aadhar',
            verifiedType: 'biometric',
            aadharData: res,
          },
        };
        if(res.uid!=this.beneficiaryData.aadhaarNumber){
          this.util.presentToastError("UID data didn't match with entered AAdhar number");
          this.scanInprogress = false;
          return;
        }else{
        this.addRecipientService.beneficiaryDetails = beneficiaryData;
        this.gotoStep.emit('next');
        this.util.presentToastSuccess('Biometric Authentication Successful');
        }
        // this.isOtpVerified = true;
        // this.showAuthButton = false;

      }
    }, err => {
      // this.errorOccurredThroughAadhaar = true;
      // this.showAuthButton = false;
    });
  }


  onSubmit() {
    this.personalDetForm.markAllAsTouched();
    if (!this.personalDetForm.valid) {
    this.util.presentToastInfo("Please Verify Consent")
       return;
      }
    // this.gotoStep('next');
    if(this.util.getIsMobileDevice()){
      this.initializeBioMetricDeviceMobile();
    } else{
      this.initializeBioMetricDevice();
    }
  }

  openBottomSheet() {
    this._bottomSheet.open(AadhaarConcentComponent);
  }

  ngOnDestroy(){
  }


}
