import { environment } from './../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ConnectivityProvider } from './connectivity.provider';
import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { UtilsService } from './utils.service';
import { select, Store } from '@ngrx/store';
import { getUserDetails } from '../../core/store/selectors/user.selectors';
import { EncDecService } from './encryption-decryption.service';
import { NavigationEnd, Router } from '@angular/router';
import { LocalStorageService } from '../../core/services/local-storage.service';
declare var require: any;

@Injectable({
  providedIn: 'root'
})
export class DataService {
  public showHeader: boolean = true;
  public headerName: string;
  private _userDetails: any;
  private userDataSubject: BehaviorSubject<any>;
  public userData: Observable<any>;
  private _idTypes: any;
  private _userLoggedinSessionType: any;
  private appVersionNo: string = require('../../../../../package.json').version;
  updatedSessionId = new ReplaySubject(1);
  currSiteDetails: any = null;
  settings: any = {};
  registrationData:any = null;
  private previousUrl: string = undefined;
  private currentUrl: string = undefined;
  showOtherFunctionalities:boolean = false;
  private commonFltrSubject: BehaviorSubject<any>;
  public commonFltrData: Observable<any>;
  private siteInfoSubject: BehaviorSubject<any>;
  public siteInfoData: Observable<any>;
  sessionSiteId: any = null;
  sitesArr: any = [];
  constructor(
    private utilsService: UtilsService,
    private netProvider: ConnectivityProvider,
    private store$: Store,
    private http: HttpClient, private encDecService: EncDecService,private router:Router,
    private localStorage: LocalStorageService,
  ) {
    this.store$.pipe(select(getUserDetails)).subscribe(userInfo => {
      if (userInfo) {
        this.userDetails = userInfo;
        if (userInfo?.user_level == 'Facility' || userInfo?.user_level == 'Plan_Delivery_Manager') {
          this.getSessionType(this._userDetails.session_site_id).subscribe(res => {
            this.loggedUserSessionType = res[0].session_category_type;
          });
          this.getAllSites();
        }
      }
    });
    this.userDataSubject = new BehaviorSubject<any>(null);
    this.userData = this.userDataSubject.asObservable();
    this.commonFltrSubject = new BehaviorSubject<any>(null);
    this.commonFltrData = this.commonFltrSubject.asObservable();
    this.siteInfoSubject = new BehaviorSubject<any>(this.localStorage.getItem('siteInfo') || null);
    this.siteInfoData = this.siteInfoSubject.asObservable();
        
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      };
    });
  }
  public getPreviousUrl() {
    return this.previousUrl;
  }


  get userDetails() {
    return this._userDetails;
  }

  set userDetails(userDetails) {
    this.userDataSubject.next(userDetails);
    this._userDetails = userDetails;
  }

  set commonFltrDetails(userDetails) {
    this.commonFltrSubject.next(userDetails);
  }

  set siteInfoDetails(siteDetails) {
    if(this.userDetails){ 
      this.userDetails.session_site_id = (siteDetails && siteDetails.session_site_id) ? siteDetails?.session_site_id : null;
    }
    this.siteInfoSubject.next(siteDetails);
  }

  get appVersion() {
    return this.appVersionNo;
  }

  set appVersion(val) {
    this.appVersionNo = val;
  }
  get loggedUserSessionType() {
    return this._userLoggedinSessionType;
  }

  set loggedUserSessionType(loggedUserSessionType) {
    this._userLoggedinSessionType = loggedUserSessionType;
  }
  getSessionType(siteId) {
    if (!siteId) {
      return EMPTY
    }
    const payload = {
      session_site_id: this.encDecService.setWithSpace(siteId)
    }
    return this.http.post(`${environment.vaccinator_url}/getSessionSiteDetails`, payload)
      .pipe(map(r => {
        this.currSiteDetails = r[0];
        return r;
      }));
  }
  getAllSites() {
    return this.http.get(`${environment.vaccinator_url}/getAllSites`)
    .pipe(map((res: any) => {
      if (res) {
        this.sitesArr = res;
        this.sitesArr =  res.map(e=>{this.encDecService.decryptLocationId(e)})
        if(this.localStorage.getItem('facilityId')){ 
          this.sitesArr = res.filter(ele=> ele.facility_id == this.localStorage.getItem('facilityId')) //checking based on facility selected
        }
      }
      return res;
    }));
  }
  setDefaultSite(siteId) {
    const payload = {
      session_site_id: siteId //this.encDecService.set(siteId)
    }
    return this.http.post(`${environment.vaccinator_url}/setDefaultSite`, payload);
  }
  getUserType() {
    let userType;
    if (this._userDetails && this._userDetails['isSupervisor'] === 1) {
      userType = 'supervisor';
    } else if (this._userDetails && this._userDetails['role'] === 'Verifier') {
      userType = 'Verifier';
    } else {
      userType = 'vaccinator';
    }
    return userType;
  }

  getIdProofBasedOnAge(param): Observable<any> {
    if (window.navigator.onLine) {
      return this.http.post<any>(
        `${environment.vaccinator_url}/getPhotoIdsBasedOnAgeUIP`, param
      ).pipe(map(e => {
        if (e) {
          e.types = e.types.map(l => { l.type = l.type.toString().trim(); return l; });
          // e.forEach(element => {
          //   element.type = element.type.toString().trim()
          // });
        }
        return e;
      }));
    }
  }

  getDPMBenfeficaries(payload) {
    return this.http.post(`${environment.vaccinator_url}/dpmBeneficiaries`, payload)
      .pipe(map(r => {
        this.currSiteDetails = r[0];
        return r;
      }));
  }


  getIdProofTypes() {
    // if(this._idTypes) {
    if (window.navigator.onLine) {
      return this.http.get<any>(
        `${environment.beneficiary_vaccinator_v3_url}/getBeneficiaryIdType`
      )
    }
    // } else {
    //   return of(this._idTypes);
    // }
  }
  getBeneficieryTypeBasedOnAge(payload) {
    return this.http.post(`${environment.beneficiary_vaccinator_v3_url}/getBeneficiaryTypeBasedOnAge`, payload)
  }

  getConfigValue(key){
    if(this.settings && this.settings.length > 0){
      const res = this.settings?.find(e=>key==e.key_name);
      return res ? res.value : null
    }
  }
  unlinkHPRId(){
    return this.http.post(`${environment.auth_prefix_url_v2}/unlinkHPRLogin`,{})
  }
}
