import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse
} from '@angular/common/http';

import { BehaviorSubject, Observable, catchError, filter, switchMap, take, throwError } from 'rxjs';
import { LocalStorageService } from '../services/local-storage.service';
import { AuthService } from '../services/auth.service';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    constructor(private localStorage: LocalStorageService, private authService: AuthService) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const userToken = this.localStorage.getItem('userToken');
        if (userToken) {
            request = request.clone({
                setHeaders: {
                    Authorization: userToken
                }
            });
        }
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
              if (
                error instanceof HttpErrorResponse &&
                !request.url.includes('auth/login') &&
                error.status === 401
                && (error.error && (error.error == 'Token Expired' ||  error.error.error == 'Token Expired')) 
                && this.localStorage.getItem('refreshToken')
              ) {
                return this.handle401Error(request, next);
              }
      
              return throwError(() => error);
            })
          );
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);
            return this.authService.refreshToken().pipe(
                switchMap((response: any) => {
                    this.isRefreshing = false;
                    this.authService.setAccessToken(response);
                    this.refreshTokenSubject.next(response.accessToken);
                    request = request.clone({
                        setHeaders: {
                            Authorization: `${response.accessToken}`,
                        },
                    });
                    return next.handle(request);
                }),
                catchError((error) => {
                    this.isRefreshing = false;
                    this.authService.logOut();
                    return throwError(() => error);
                })
            );
        }

        return this.refreshTokenSubject.pipe(
            filter(token => token !== null),
            take(1),
            switchMap((token) => {
                request = request.clone({
                    setHeaders: {
                        Authorization: `${token}`,
                    },
                });
                return next.handle(request);
            })
        );
    
      }


}
