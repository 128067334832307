import { ConnectivityProvider } from './../../services/connectivity.provider';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import { select, Store } from '@ngrx/store';
import { SidenavService } from '../../services/side-nav.service';
import { Logout } from 'src/app/modules/core/store/actions/auth.actions';
import { getUserDetails } from 'src/app/modules/core/store/selectors/user.selectors';
import { UtilsService } from '../../services/utils.service';
import { UserService } from 'src/app/modules/core/services/user.service';
import { DomSanitizer } from '@angular/platform-browser';
import { LocalStorageService } from 'src/app/modules/core/services/local-storage.service';
import { DataService } from '../../services';
import {of} from 'rxjs'

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit {
  userDetails$ = this.store$.pipe(select(getUserDetails));
  profilePic: any;
  userInfo: any;
  ENV = environment;


  constructor(
    private store$: Store,
    private sideNav: SidenavService,
    public util: UtilsService,
    private userService: UserService,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    private menuCtrl: MenuController,
    private localStorage:LocalStorageService,
    private dataServ:DataService
  ) { }

  ngOnInit() {
    this.userService.getProfilePic().subscribe(res => {
      if (res.type == 'text/html') {
        this.profilePic = true;
      } else {
        this.profilePic = false;
      }
      let blob = new Blob([res]);
      if (this.userInfo?.profile_picture_url) {
        this.userInfo.profile_picture_url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(blob));
        this.cdr.detectChanges();
      }
    })

    this.sideNav.sidenavData.subscribe(e => {
      if (this.util.getPlatformWidth() <= 800 && e != null) {
        this.menuCtrl.toggle();
      }
    })
  }

  ionViewWillEnter() {
  }

  logout() {
    this.store$.dispatch(Logout());
    if (this.localStorage.getItem('facilityId')) {
      this.localStorage.removeItem('facilityId')
    }
    if (this.localStorage.getItem('location_Id')) {
      this.localStorage.removeItem('location_Id')
    }
    if (this.localStorage.getItem('facilityId')) {
      this.localStorage.removeItem('facilityId')
    }
    if(this.localStorage.getItem('sub_center_id')){
      this.localStorage.removeItem('sub_center_id')
    }
    if (this.localStorage.getItem('siteInfo')) {
      this.localStorage.removeItem('siteInfo')
    }
    if (this.localStorage.getItem('session_site_id')) {
      this.localStorage.removeItem('session_site_id')
    }
    this.dataServ.siteInfoDetails = of([]);
  }

  toggleSideNav() {
    if (this.util.getPlatformWidth() <= 800 && this.sideNav.isOpened) {
      this.sideNav.toggle();
    }
  }

  closeSideNav() {
    this.sideNav.toggle();
  }

  handleMissingImage(event) {
    (event.target as HTMLImageElement).src = 'assets/images/profilepic.png';
  }
}
