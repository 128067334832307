import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DataService } from 'src/app/modules/shared/services';
import { EncDecService } from 'src/app/modules/shared/services/encryption-decryption.service';
;

@Injectable({
  providedIn: 'root'
})
export class searchService {
  public idProofTypes: any = null;
  constructor(private http: HttpClient,private dataService:DataService,private encDecService:EncDecService) {

  }
  getIdProofTypes(): Observable<any> {
    if (window.navigator.onLine) {
      if (!this.idProofTypes) {
        return this.http.get<any>(
          `${environment.beneficiary_vaccinator_v3_url}/getBeneficiaryIdType`
        ).pipe(map(r => {
          if (r) {
            this.idProofTypes = r;
          }
          return r;
        }));
      } else {
        return of(this.idProofTypes);
      }
    }
  }

  searchBeneficiaryByType(params): Observable<any> {
    if (window.navigator.onLine) {
      params.search_value = this.encDecService.setWithSpace(params.search_value);
      return this.http.post(`${environment.beneficiary_vaccinator_v3_url}/getBeneficiaries`, params)
      .pipe(map((b:any)=>{
          b.map(benf=>{
            this.encDecService.decryptPhotoId(benf)
            this.encDecService.decryptMobileNo(benf)
          });
          return b;
      }));
    }
  }
}
