<mat-horizontal-stepper [linear]="false" #stepper  (selectionChange)="onTabChange($event)">
  <mat-step>
    <app-add-recipient-identification *ngIf="currentActiveStep == 'IDENTIFICATION'" [activeStepIndex]="currentActiveStep"
     [noBack]="true" (onClose)="closeDialog()" (onStepChange)="onStepChange($event)">
    </app-add-recipient-identification>
  </mat-step>
  <mat-step>
    <app-add-recipient-personal-details *ngIf="currentActiveStep == 'PERSONALDETAILS'" [activeStepIndex]="currentActiveStep" (onClose)="closeDialog()" (onStepChange)="onStepChange($event)">
    </app-add-recipient-personal-details>
  </mat-step>
  <mat-step>
    <app-add-recipient-verified-success *ngIf="currentActiveStep == 'VERIFIEDSUCCESS'" [activeStepIndex]="currentActiveStep" (onClose)="closeDialog()" (onStepChange)="onStepChange($event)">
    </app-add-recipient-verified-success>
  </mat-step>
  <mat-step *ngIf="data.session_type_name=='UIP'">
    <app-select-multi-vaccination-uip *ngIf="currentActiveStep == 'MULTIVACCUIP'" [activeStepIndex]="currentActiveStep" (onClose)="closeDialog()" (onStepChange)="onStepChange($event)">
    </app-select-multi-vaccination-uip>
  </mat-step>
  <mat-step>
    <app-vaccinate-member *ngIf="currentActiveStep == 'VACCINATEMEMBER'" [activeStepIndex]="currentActiveStep" (onClose)="closeDialog()" (onStepChange)="onStepChange($event)">
    </app-vaccinate-member>
  </mat-step>
  <mat-step>
    <app-vaccinated-rejected-status *ngIf="currentActiveStep == 'VACCINATIONREJECTED'" [activeStepIndex]="currentActiveStep" (onClose)="closeDialog()" (onStepChange)="onStepChange($event)">
    </app-vaccinated-rejected-status>
  </mat-step>
</mat-horizontal-stepper>