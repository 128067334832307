import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { EncDecService } from 'src/app/modules/shared/services/encryption-decryption.service';
import { DataService } from 'src/app/modules/shared/services';
import * as StringCompare from 'string-similarity';
import * as _ from 'lodash';
import { UtilsService } from 'src/app/modules/shared/services/utils.service';
import { LookUpService } from 'src/app/modules/shared/services/look-up.service';
import { ReplaySubject } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class AddRecipientService {
  private beneficiaryDataSubject: BehaviorSubject<any>;
  private benSuccessDataSubject: BehaviorSubject<any>;
  private childBeneficaryDataSubject: BehaviorSubject<any>;
  public beneficiaryData: Observable<any>;
  public benSuccessData: Observable<any>;
  public childBeneficaryData: Observable<any>;
  private previousUrl: string = undefined;
  private currentUrl: string = undefined;
  sendYear = new EventEmitter<any>();
  stepHistory: any = [];
  aadharData: any = {};
  genderList: any = [{ "gender": "Male", "id": 1 }, { "gender": "Female", "id": 2 }, { "gender": "Others", "id": 3 }];
  yobList: any = [];
  coordinates: any;
  sendFalse = new ReplaySubject(1);

  constructor(
    private http: HttpClient,
    private dataService: DataService,
    private encDecService: EncDecService,
    private util: UtilsService,
    private lookup: LookUpService,
    private router: Router
  ) {
    this.beneficiaryDataSubject = new BehaviorSubject<any>(null);
    this.benSuccessDataSubject = new BehaviorSubject<any>(null);
    this.beneficiaryData = this.beneficiaryDataSubject.asObservable();
    this.benSuccessData = this.benSuccessDataSubject.asObservable();
    this.childBeneficaryDataSubject = new BehaviorSubject<any>(null);
    this.childBeneficaryData = this.childBeneficaryDataSubject.asObservable();

    this.lookup.getYob().subscribe(r => { this.yobList = r });
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      };
    });
  }
  public getPreviousUrl() {
    return this.previousUrl;
  }
  getBeneficiaryTypes(): Observable<any> {
    if (window.navigator.onLine) {
      return this.http.get<any>(
        `${environment.beneficiary_registration_prefix_url}/beneficiary/getBeneficiaryType`
      ).pipe(map(r => {
        // if(r) {
        // return  _.uniqBy(r, 'category_id');
        // }
        return r;
      }));
    }
  }

  set beneficiaryDetails(benfDetails) {
    this.beneficiaryDataSubject.next(benfDetails);
  }

  set benSuccessDetails(benSuccess) {
    this.benSuccessDataSubject.next(benSuccess)
  }
  set childBenficiaryDetails(childDetails) {
    this.childBeneficaryDataSubject.next(childDetails)
  }

  getBeneficiariesByMobileNo(params): Observable<any> {
    if (window.navigator.onLine) {
      return this.http.post(`${environment.beneficiary_vaccinator_v3_url}/getBeneficiariesByMobileNo`, params)
      .pipe(map((b:any)=>{
        b.map(benf=>{
          this.encDecService.decryptPhotoId(benf);
          this.encDecService.decryptMobileNo(benf);
        });
        return b;
      }));
    }
  }

  getBeneficiariesWithMobileNo(params): Observable<any> {
    params['ben_mobile_number'] = this.encDecService.setWithSpace(params['ben_mobile_number'])
    if (window.navigator.onLine) {
      return this.http.post(`${environment.beneficiary_vaccinator_v3_url}/getBeneficiariesWithMobileNo`, params)
      .pipe(map((b:any)=>{
        b.map(benf=>{
          this.encDecService.decryptPhotoId(benf)
          this.encDecService.decryptMobileNo(benf);
        });
        return b;
      }));
    }
  }

  onspotAddVerify(params) {
    if (window.navigator.onLine) {
      return this.http.post(`${environment.beneficiary_vaccinator_v3_url}/addAndVerify`, params); // addAndVerifyBeneficiary
    }
  }

  downloadFile(params) {
    return this.http.post(`${environment.vaccinator_url}/downloadFile`, params);
  }

  // getMaterialByDistrictId() {
  //   const stateId = this.dataService.userDetails.state_id;
  //   const districtId = this.dataService.userDetails.district_id;
  //   return this.http.get(`${environment.admin_prefix_url}/material/getAllMaterial`)
  // }
  getStateEligibilityTypes() {
    return this.http.get(`${environment.beneficiary_vaccinator_v3_url}/getStateEligibilityTypes`);
  }
  namesLastletLowerCase(name){
    name=name?.toLowerCase().split(' ')
     if(name){
       for (let i = 0; i < name.length; i++) {
         name[i] = name[i].charAt(0).toUpperCase() + name[i].substring(1);
       }
     }
     return name.toString().replaceAll(',', ' ')
   }
  addNewBeneficiary() {
    let allStepsData = null;
    this.beneficiaryData.subscribe(res => {
      allStepsData = res;
    });
    const submitPayLoad = allStepsData;
    const formData = new FormData();
    formData.append('name', this.namesLastletLowerCase(submitPayLoad.ben_name));
    formData.append('birth_year', moment(submitPayLoad.dob).format('YYYY'));
    formData.append('dob', moment(submitPayLoad.dob).format('YYYY-MM-DD'));
    formData.append('gender', this.genderList.find(e => submitPayLoad.gender == e.id)?.gender);

    if (submitPayLoad.special_session_type_id == 1) {
      formData.append('photo_id_number', 'Not Applicable');
      formData.append('photo_id_type', 'NO ID');
      formData.append('beneficiary_type_category_id', '3');
      formData.append('beneficiary_type_category_name', 'Citizen');
      formData.append('mobile_number', submitPayLoad.contact_person_mobile);
      formData.append('ben_type_id', '17');
      formData.append('beneficiary_type_name', 'Citizen');

    } else {
      if(this.calAgeByDob(submitPayLoad) < 12 && submitPayLoad.photo_id_type=='Student Photo Id Card'){
      formData.append('photo_id_number', submitPayLoad.photo_id_number.substring(4));
      }else{
        formData.append('photo_id_number', submitPayLoad.photo_id_number);
      }
      formData.append('photo_id_type', submitPayLoad.photo_id_type);
      formData.append('beneficiary_type_category_id', submitPayLoad.beneficiary_type_category_id);
      formData.append('beneficiary_type_category_name', submitPayLoad.beneficiary_type_category_name);
      formData.append('mobile_number', submitPayLoad.mobile_number);
      formData.append('ben_type_id', submitPayLoad.ben_type_id);
      formData.append('beneficiary_type_name', submitPayLoad.beneficiary_type_name);
    }

    // formData.append('beneficiary_type_category_id', submitPayLoad.beneficiary_type_category_id);
    // formData.append('beneficiary_type_category_name', submitPayLoad.beneficiary_type_category_name);

    // formData.append('vaccinated_outside_india', "");
    formData.append('beneficiary_type_name_other', submitPayLoad.beneficiary_type_name_other ? submitPayLoad?.beneficiary_type_name_other : '');
    // formData.append('beneficiary_type_name', submitPayLoad.beneficiary_type_name);
    if (submitPayLoad.session_id) {
      formData.append('session_id', submitPayLoad.session_id);
    }
    formData.append('state_eligibility_type_id', submitPayLoad.state_eligibility_type_id ? submitPayLoad.state_eligibility_type_id : '');
    formData.append('comorbidity_ind', null);
    formData.append('state_id', this.dataService.userDetails.state_id);
    formData.append('district_id', this.dataService.userDetails.district_id);
    formData.append('verifiedBy', submitPayLoad.verifiedBy == 'Aadhar' ? 'Aadhaar Card' : 'BASIC');
    formData.append('verifiedType', submitPayLoad.verifiedType ? submitPayLoad.verifiedType : '');
    formData.append('district_name', submitPayLoad?.district_name);
    formData.append('state_name', submitPayLoad?.state_name);
    formData.append('facility_name', submitPayLoad?.facility_name);
    if(submitPayLoad?.facility_id){formData.append('facility_id', submitPayLoad?.facility_id);}
    formData.append('site_id', this.encDecService.setWithSpace(this.dataService.userDetails.session_site_id));
    formData.append('site_name', this.dataService.userDetails.session_site_name);
    // formData.append('referenceId', submitPayLoad.referenceId);
    if (submitPayLoad.special_session_type_id != 1) {
      formData.append('verifiedUsing', submitPayLoad.verifiedUsing);
      if (allStepsData.verifiedBy == 'Manual' && allStepsData?.fileInManualverification != null && allStepsData?.fileInManualverification != undefined) {
        formData.append('file', allStepsData?.fileInManualverification?.fileObject, `photo-${new Date().getTime()}.${allStepsData?.fileInManualverification?.fileObject?.type}`);
      }
      if (allStepsData.verifiedBy == 'Aadhar' && !allStepsData.guardian_ben_id 
      && submitPayLoad.aadharData.healthIdNumber && submitPayLoad.aadharData.benefitId) {
        formData.append('health_id', this.encDecService.setWithSpace(submitPayLoad.aadharData.healthIdNumber)),
          formData.append('benefit_id', this.encDecService.setWithSpace(submitPayLoad.aadharData.benefitId))
      }
      if (Object.keys(allStepsData?.employment_certificate_File).length > 0) {
        formData.append('employment_certificate', allStepsData?.employment_certificate_File?.fileObject, `photoEmpCert-${new Date().getTime()}.${allStepsData?.employment_certificate_File?.fileObject?.type}`);
      }
    }
    if (this.calAgeByDob(submitPayLoad) < 12 || submitPayLoad?.ben_type_id == environment.static_ids.Infant_ID) {
      formData.append('guardian_ben_id', submitPayLoad?.guardian_ben_id);
      formData.append('guardian_name', this.namesLastletLowerCase(submitPayLoad?.guardian_name));
    }
    if (submitPayLoad?.ben_type_id == environment.static_ids.PregnantWomen) {
      formData.append('lmc_date', moment(submitPayLoad?.lmp_date).format('YYYY-MM-DD'));
      formData.append('blood_group', submitPayLoad?.blood_group);
      const addedFrom = (this.dataService.userDetails.role == 'Vaccinator'  || this.dataService.userDetails.role == 'ANM') ? 'vaccinator' : (this.dataService.userDetails.role == environment.static_ids.DeliveryManagerKey || this.dataService.userDetails.role == environment.static_ids.BothplanningUnitAndDelivery) ? 'dpm' : ''
      formData.append('added_from', addedFrom);
      if(submitPayLoad?.previous_pregnancy_year){
        formData.append('previous_pregnancy_year',moment(submitPayLoad?.previous_pregnancy_year).format('YYYY-MM-DD'))
      }
    }
    formData.append('verifier_lat', submitPayLoad?.verifier_lat),
      formData.append('verifier_long', submitPayLoad?.verifier_long),
      formData.append('vaccinator_lat', submitPayLoad?.vaccinator_lat),
      formData.append('vaccinator_long', submitPayLoad?.vaccinator_long),
      formData.append('verifier_device', submitPayLoad.verifier_device)
    if (submitPayLoad.verifiedType == 'facial' || submitPayLoad.verifiedType == 'qr') {
      formData.append('suid', localStorage.getItem('suid'));
      formData.append('time_took', submitPayLoad?.time_took ? submitPayLoad?.time_took : '');
    }
    if (submitPayLoad?.ben_type_id == environment.static_ids.Infant_ID || submitPayLoad?.ben_type_id == environment.static_ids.NewBornBaby) {
      if(submitPayLoad?.child_weight && submitPayLoad?.child_weight !='' && submitPayLoad?.child_weight != 'NULL'){ 
        formData.append('child_weight', submitPayLoad?.child_weight);
      }
      formData.append('father_name', this.namesLastletLowerCase(submitPayLoad?.father_name));
    }
    return this.onspotAddVerify(formData)
  }

  calAgeByDob(payload) {
    return Math.round(moment().diff(moment(payload?.dob), 'years', true));
  }

  addBeneficieryToOngoing(params) {
    return this.http.post(`${environment.vaccinator_url}/addBeneficiaryToOngoingSession`, params);

  }
  addBenefToOngoingSessionCommonMethod(benfPayload) {
    const payload = {
      beneficiary_id: benfPayload.beneficiary_id,
      sessionId: benfPayload.session_id,
      districtId: benfPayload.district_id,
      is_vaccinated: benfPayload.dose,
      dose_date: benfPayload.dose1_date ? moment(benfPayload.dose1_date).format('DD-MM-YYYY') : '',
      dose_date_2: benfPayload.dose2_date ? moment(benfPayload.dose2_date).format('DD-MM-YYYY') : '',
      state_id: benfPayload.session_id,
      facility_id: benfPayload.facility_id,
      facility_name: benfPayload.facility_name,
      site_id: benfPayload?.session_site_id,
      site_name: benfPayload?.session_site_name,
      photo_id_type: benfPayload.photo_id_type,
      verifiedBy: benfPayload.verifiedBy == 'Aadhar' ? 'Aadhaar Card' : 'BASIC',
      verifiedType: benfPayload.verifiedType,
      verifier_lat: benfPayload?.verifier_lat,
      verifier_long: benfPayload?.verifier_long,
      vaccinator_lat: benfPayload?.vaccinator_lat,
      vaccinator_long: benfPayload?.vaccinator_long,
      verifier_device: benfPayload?.verifier_device,
      suid: (benfPayload?.verifiedType == 'facial' || benfPayload?.verifiedType == 'qr') ? localStorage.getItem('suid') : undefined,
      time_took: (benfPayload?.verifiedType == 'facial' || benfPayload?.verifiedType == 'qr') ? benfPayload?.time_took : undefined,
      vaccinated_outside_india: benfPayload.vaccinated_outside_india ? 1 : undefined,
      dose: benfPayload.dose + 1,
      village_id: benfPayload.village_id ? benfPayload.village_id : undefined
    }
    // return of(null);
    return this.addBeneficieryToOngoing(payload)
  }
  matchWithAadharData(benfDetails) {
    let dataMatch = true;
    let misMatchData = { gender: true, name: true, dob: true };
    const yob = benfDetails.aadharData.yearOfBirth || benfDetails.aadharData.yob;
    const mob = benfDetails.aadharData.monthOfBirth;
    let mobId;
    let dobId;
    const dob = benfDetails.aadharData.dayOfBirth;
    let gender = benfDetails.aadharData.gender;
    const name = benfDetails.aadharData.name;
    if (yob) {
      const yobObj = this.yobList.filter(obj => obj.year === Number.parseInt(yob, 10));
      if (yobObj) {
        const obj = yobObj[0];
      }
    }

    switch (gender) {
      case 'M':
        gender = 1;
        if (benfDetails?.beneficiary_gender && benfDetails?.beneficiary_gender != "Male") {
          dataMatch = false;
          misMatchData.gender = false;
        }
        break;
      case 'F':
        gender = 2;
        if (benfDetails?.beneficiary_gender && benfDetails.beneficiary_gender != "Female") {
          dataMatch = false;
          misMatchData.gender = false;
        }
        break;
      case 'O':
        gender = 3;
        if (benfDetails?.beneficiary_gender && benfDetails.beneficiary_gender != "Gender") {
          dataMatch = false;
          misMatchData.gender = false;
        }
        break;
    }

    let result = StringCompare.compareTwoStrings(benfDetails.ben_name.trim().toLowerCase(), name.trim().toLowerCase());

    if (result < 0.9) {
      dataMatch = false;
      misMatchData.name = false;
    }

    if (benfDetails.year != yob) {
      dataMatch = false;
      misMatchData.dob = false;
    }
    return {
      dataMatch,
      ...misMatchData
    };
  }
  getBeneficieryTypeBasedOnAge(payload) {
    return this.http.post(`${environment.beneficiary_vaccinator_v3_url}/getBeneficiaryTypeBasedOnAge`, payload)
  }
  getuipMaterialdosedetails(params) {
    params['beneficiary_id'] = this.encDecService.setWithSpace(params.beneficiary_id);
    return this.http.post(`${environment.vaccinator_url}/getuipMaterialdosedetails`, params)
  }

  getUipMaterialDoseDetailsByAge(params) {
    params['beneficiary_id'] = this.encDecService.setWithSpace(params.beneficiary_id);
    return this.http.post(`${environment.vaccinator_url}/getUipMaterialDoseDetailsByAge`, params)
  }

  

  getBeneficairyGuardianDetails(beneficiaryID): Observable<any> {
    if (window.navigator.onLine) {
      const payload = {
        beneficiaryId : this.encDecService.setWithSpace(beneficiaryID)
      }
      return this.http.post(`${environment.vaccinator_url}/getbeneficiary_details`, payload)
        .pipe(map((r: any) => {
          r = this.encDecService.decryptPhotoId(r); 
          r = this.encDecService.decryptMobileNo(r); 
          r.linkedmembers.map(l=>{
              l = this.encDecService.decryptPhotoId(l);
              l = this.encDecService.decryptMobileNo(l);
              return l; 
          });
          return r;
        }));
    } else {
      return of(null);
    }
  }
  addToUWIN(params){
    params.beneficiary_id = this.encDecService.setWithSpace(params.beneficiary_id)
    return this.http.post(`${environment.vaccinator_url}/addInUWIN`, params)
  }



}
