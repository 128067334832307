import { FormGroup } from '@angular/forms';
import { Component, Input, OnInit, AfterContentChecked, Output, EventEmitter, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { DataService } from 'src/app/modules/shared/services/data.service';
import { LocationService } from 'src/app/modules/shared/services/location.service';
import { Observable, Subscription, takeWhile, tap, timer } from 'rxjs';
import { AddRecipientService } from 'src/app/screens/add-recipient/services/add-recipient.service';
import moment from 'moment';
import { LookUpService } from 'src/app/modules/shared/services/look-up.service';
import { UtilsService } from 'src/app/modules/shared/services/utils.service';
import { environment } from 'src/environments/environment';
import * as config from 'src/environments/config'
import { UserService } from 'src/app/modules/core/services/user.service';
@Component({
  selector: 'app-recipient-basic-summary',
  templateUrl: './basic-summary.component.html',
  styleUrls: ['./basic-summary.component.scss'],
})
export class BasicSummaryComponent implements OnInit, OnDestroy {
  benData: any = {};
  @Input() adhaarDataMatch: any = null;
  @Input() parentForm: FormGroup;
  minDob;
  maxDob;
  environment;
  maxLMPDate = new Date();
  minLMPDate;
  minDate;
  maxPreviousYear
  timer: number;
  showResend = false;
  bloodGroupArray = [{ id: 1, groupType: 'A+ve' }, { id: 2, groupType: 'B+ve' }, { id: 3, groupType: 'AB+ve' }, { id: 4, groupType: 'O+ve' }, { id: 5, groupType: 'O-ve' }, { id: 6, groupType: 'A-ve' }, { id: 7, groupType: 'B-ve' }, { id: 8, groupType: 'AB-ve' }]
  otpInProgress = false;
  txnId = null;
  isOTPSent = false;
  benfSubscription: Subscription;
  showReferenceId = false;
  resendCount: number = 0;

  constructor(
    public util: UtilsService,
    private lookupService: LookUpService,
    private addRecipientService: AddRecipientService,
    private cdr: ChangeDetectorRef,
    private userServ: UserService,
  ) {}

  ngOnInit() {
    this.environment = environment;
    
    this.benfSubscription = this.addRecipientService.beneficiaryData.subscribe(benf => {
      this.benData = benf;
      let yob = null;
    if(this.benData.year) {
      yob =  this.benData.year
    }else if(this.benData.aadharData && (this.benData.aadharData.yearOfBirth)){
      yob =  this.benData.aadharData.yearOfBirth
    }else if(this.benData.aadharData && (this.benData.aadharData.yob)){
      yob =  this.benData.aadharData.yob
    }
    if(yob){
      this.minDob = new Date(`01-01-${yob}`);
      this.maxDob = new Date(`12-31-${yob}`);
    }
    })
    this.maxPreviousYear = moment().format('YYYY-MM-DD')
    if(this.benData && this.benData?.beneficiary_id){
      this.minLMPDate = this.benData.lmp_date;
    }
     this.minDate = moment(this.benData.dob).add(12,'years').format('YYYY-MM-DD');
    if(this.benData && this.benData?.ben_type_id == environment.static_ids.PregnantWomen){
      this.maxDob = new Date(moment().subtract('years', 10).format('MM-DD-YYYY'));
      this.minLMPDate = new Date(moment().subtract(12,'months',).format('MM-DD-YYYY'));
      this.cdr.detectChanges();
    }
    if(this.benData.verifiedBy == 'Manual'){
      this.generateOTP()
    }
  }
  lmpDateEvent(event){
    var max = moment(event.value,'DD/MM/YYYY').subtract(1,'months').format('YYYY-MM-DD');
    this.maxPreviousYear = max;
  }

  resendOtp() {
    this.otpInProgress = true;
    this.resendCount++;
    this.generateOTP()
  }

  generateOTP() {
    this.userServ.generateBenOtp(this.benData.ben_mobile_number).subscribe({
      next: res => {
        this.otpInProgress = false;
        if (res && res?.txnId) {
          this.userServ.txnId = res?.txnId;
        }
        this.isOTPSent = true;
        this.startTimer();
      },
      error: err => {
        this.otpInProgress = false;
        if (err?.error?.errorCode === 'USRAUT0011') {
          this.userServ.txnId = err?.error?.txnId;
          this.isOTPSent = true;
          this.startTimer();
        }
        this.startTimer();
      }
    });
  }

  startTimer() {
    this.showReferenceId = false;
    let counter = config.ENV.otpTimer;
    this.timer = config.ENV.otpTimer;

    timer(1000, 1000) // Initial delay 1 second, interval countdown 1 second
      .pipe(
        takeWhile(() => counter > 0),
        tap(() => counter--)
      )
      .subscribe(() => {
        this.timer = counter; 
        this.cdr.detectChanges();
        if (counter === 0 && this.resendCount > 0) {
          this.showReferenceId = true;
        }
      });
  }

  ngOnDestroy(): void {
      this.benfSubscription.unsubscribe();
  }
}
