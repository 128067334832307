import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { ActionSheetController, Platform } from '@ionic/angular';
import { UtilsService } from './utils.service';
import { NgxImageCompressService } from 'ngx-image-compress';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { TranslateService } from '@ngx-translate/core';

export interface ImageModel {
  _id: string;
  name: string;
  createdAt: Date;
  url: string;
}

@Injectable({
  providedIn: 'root'
})
export class ImageUploadService {
  fileNameValidation = /^[ A-Za-z0-9_\-]*$/;
  imgResultAfterCompress;
  compressedFile;
  fileObjforCompression;
  imageCaptureType = 'attach'
  imageInfo: any = {};
  signatures = {
    '/9j/4AAQS': 'image/jpg',
    '/9j/4Qxi': 'image/jpg',
    iVBORw0KGgo: 'image/png'
  };


  constructor(
    private plt: Platform,
    private sanitization: DomSanitizer,
    private actionSheetCtrl: ActionSheetController,
    private util: UtilsService,
    private ngxImageCompressService: NgxImageCompressService,
    private translate: TranslateService
  ) { }


  async selectImageSource(fileInput) {
    if (!window.navigator.onLine) {
      this.util.presentToastError(('OFFLIN_NETWORK_OPERATION_NOT_ALLOWED'));
      return;
    }

    let buttons = [];
    this.imageCaptureType = 'attach';
    if (!this.plt.is('desktop')) {
      buttons = [
        {
          text: 'Take Photo',
          icon: 'camera',
          handler: () => {
            this.imageCaptureType = 'takePhoto';
            // console.log('this.imageUploadService.imageCaptureTypethis.imageUploadService.imageCaptureType')
            // return this.addImage(CameraSource.Camera).toPromise();
          }
        },

        {
          text: 'Choose From Photos',
          icon: 'image',
          handler: () => {
            // this.imageCaptureType = 'attach';
            fileInput.nativeElement.click();

            // return this.addImage(CameraSource.Photos).toPromise();
          }
        }
      ];
    }

    // Only allow file selection inside a browser
    if (this.plt.is('desktop')) {
      fileInput.nativeElement.click();
      return;
    }

    const actionSheet = await this.actionSheetCtrl.create({
      header: 'Select Image Source',
      buttons
    });;
    await actionSheet.present();
    const { role, data } = await actionSheet.onDidDismiss();
    return role;
  }

  addImage(source: CameraSource = CameraSource.Camera) {
    return new Observable((observer) => {
      const image = Camera.getPhoto({
        quality: 60,
        allowEditing: source === CameraSource.Photos ? false : true,
        resultType: CameraResultType.Base64,
        source,
      })
        .then((imageData) => {
          const blobData = this.util.b64toBlob(
            imageData.base64String,
            `image/${imageData.format}`
          );

          const imageName = new Date().getTime();
          let fileObj = blobData;
          let fileExt = imageData.format;
          const imageUrl =
            "data:" +
            `image/${imageData.format};base64,` +
            imageData.base64String;
          const fileName = " ";
          let url = window.URL.createObjectURL(blobData);
          let imageInfo = {
            imageUrl: imageUrl,
            fileExt: imageData.format,
            documentUploaded: fileObj,
            selectedFileBLOB: this.sanitization.bypassSecurityTrustUrl(url)
          };
          observer.next(
            {
              // fileObject: fileObj,//normal file object
              fileExt,
              fileName: `photo-${new Date().getTime()}.${fileExt}`,
              selectedFileBLOB: imageInfo.selectedFileBLOB,
              documentUploaded: imageInfo.documentUploaded,
              fileObject: fileObj,//after compression file
              fileObjectWithoutCompress: fileObj, //sending normal file because getting error in API
            }
          );
        })
        .catch((e) => {
          observer.error(e)
          console.log("Error in file attachment", e);
        });
    })
  }

  detectMimeType(b64) {
    for (const s in this.signatures) {
      if (b64.indexOf(s) > 0) {
        return this.signatures[s];
      }
    }
  }

  compressImage(image, fileName) {
    return new Observable((observer) => {
      var orientation = -1;
      let sizeOfOriginalImage = this.ngxImageCompressService.byteCount(image) / (1024 * 1024);
      this.ngxImageCompressService.compressFile(image, orientation, 25, 25).then(
        result => {
          this.imgResultAfterCompress = result;
          //  this.localCompressedURl = result;
          let sizeOFCompressedImage = this.ngxImageCompressService.byteCount(result) / (1024 * 1024)
          //  this.compressedFile;
          if (sizeOFCompressedImage > 0.1) {
            this.compressedFile = this.dataURItoBlob(this.imgResultAfterCompress.split(',')[1]);

            this.ngxImageCompressService.compressFile(image, orientation, 15, 15).then(
              result2 => {
                sizeOFCompressedImage = this.ngxImageCompressService.byteCount(result2) / (1024 * 1024)
                const imageName = fileName;
                this.compressedFile = this.dataURItoBlob(result2.split(',')[1]);
                // return this.compressedFile
                observer.next(this.compressedFile ? this.compressedFile : image)
              });
          } else {
            const imageName = fileName;
            this.compressedFile = this.dataURItoBlob(this.imgResultAfterCompress.split(',')[1]);
            // return this.compressedFile
            observer.next(this.compressedFile ? this.compressedFile : image)
          }
        });

    })
  }

  uploadFile(event?): Observable<any> {
    if (this.imageCaptureType == 'takePhoto') {
      return this.addImage();
    }

    return this.attachFile(event)
  }

  attachFile(event): Observable<any> {
    return new Observable((observer) => {

      // const eventObj: MSInputMethodContext = event as MSInputMethodContext;
      // const target: HTMLInputElement = eventObj.target as HTMLInputElement;
      // let eventObj: MSInputMethodContext = <MSInputMethodContext> event;
      let target: HTMLInputElement = <HTMLInputElement>event.target
      let fileObj: File = target.files[0];
      this.fileObjforCompression = target.files[0];
      let selectedFileName = fileObj.name.split('.');
      let fileExt = fileObj.name.split('.').pop();
      if (event.target.files && event.target.files[0]) {
        var fileName: any;
        fileName = fileObj['name'];
        // let FileNameSelected = fileName;
        let reader = new FileReader();
        if (fileObj instanceof Blob) {
          const realFileReader = (reader as any)._realReader;
          if (realFileReader) {
            reader = realFileReader;
          }
        }
        reader.readAsDataURL(fileObj);
        // reader.readAsArrayBuffer(fileObj);

        reader.onload = (e: any) => {

          const mimeType = this.detectMimeType(reader.result);
          // if (mimeType) { //removed for checking pdf file also
          let localUrl = e.target.result;
          const file = event.target.files[0];
          let targetFileTypes = [];
          targetFileTypes = event.target.accept.split(',')
          if (targetFileTypes.includes(event.target.files[0]['type'])) { //checkes which type of file
            // if (event.target.files[0]['type'] == "image/jpeg" || event.target.files[0]['type'] == "image/jpg" || event.target.files[0]['type'] == "image/png" || event.target.files[0]['type'] == "application/pdf") {

            const name = file.name;
            const fileNameWithOutExtension = name.substring(0, name.lastIndexOf('.'));
            if (this.fileNameValidation.test(fileNameWithOutExtension)) {
              let blob = new Blob(event.target.files, { type: event.target.files[0].type });
              let url = window.URL.createObjectURL(blob);
              let documentUploaded = target.files[0];
              let selectedFileBLOB = this.sanitization.bypassSecurityTrustUrl(url);

              if (event.target.files[0]['type'] == 'application/pdf') {
                observer.next(
                  {
                    // fileObject: fileObj,//normal file object
                    fileName: fileObj.name,
                    selectedFileBLOB,
                    fileExt,
                    documentUploaded,
                    fileObject: fileObj,//after compression file
                  }
                )
              }
              else {
                this.compressImage(localUrl, fileName).subscribe((compressedFile) => {
                  observer.next(
                    {
                      // fileObject: fileObj,//normal file object
                      fileName: fileObj.name,
                      selectedFileBLOB,
                      fileExt,
                      documentUploaded: null,
                      fileObject: compressedFile ? this.compressedFile : fileObj,//after compression file
                      fileObjectWithoutCompress: fileObj, //sending normal file because getting error in API
                    }
                  )

                })
              }
            } else {
              event.target.files = null;
              const translatedMessage = this.translate.instant('Invalid file name');
              observer.error(translatedMessage);
            }
          } else {
            event.target.files = null;
            // this.onLoad();
            let errorText = 'Invalid file type. Allowed extensions are PNG, JPEG'
            targetFileTypes.includes('application/pdf') ? observer.error(errorText + ',PDF.') : observer.error(errorText + '.')
          }
          // } else {
          //   target.files = null;
          //   observer.error('Invalid file type. Allowed extensions are PNG, JPEG.');
          // }
        }
      }
    });
  }



  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: this.fileObjforCompression['type'] });
    return blob;
  }
}
