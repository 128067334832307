import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'linelistingstatus'
})
export class LinelistingstatusPipe implements PipeTransform {

  transform(statusId: number): string {
    let status = 'Pending';
    if (statusId === 0) {
      status = 'Pending';
    } else if (statusId === 1 || statusId === 2) {
      status = 'Approved';
    }
    return status;
  }

}
