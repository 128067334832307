import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'locations',
  pure: false
})
export class LocationsPipe implements PipeTransform {
    transform(id: number, data: any[], label: string): string {
        let name = 'Pending';
        if (data && data.length > 0) {
            switch (label) {
                case 'state_name':
                    const stateObj = _.find(data, {state_id: id});
                    name = stateObj.state_name;
                    break;
                case 'district_name':
                    const districtObj = _.find(data, {district_id: id});
                    name = districtObj.district_name;
                    break;
                case 'block_name':
                    const blockObj = _.find(data, {block_id: id});
                    name = blockObj.block_name;
                    break;
            }
        }
        return name;
      }
}
