import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { EncDecService } from 'src/app/modules/shared/services/encryption-decryption.service';
import { DataService } from 'src/app/modules/shared/services';
import * as _ from 'lodash';
import { UtilsService } from 'src/app/modules/shared/services/utils.service';
import { LookUpService } from 'src/app/modules/shared/services/look-up.service';
import { ReplaySubject } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AddLinkingService {

  constructor(
    private http: HttpClient,
    private dataService: DataService,
    private encDecService: EncDecService,
    private util: UtilsService,
    private lookup: LookUpService,
    private router: Router
  ) { }

  searchHprId(payload) {
    payload.hprId = this.encDecService.setWithSpace(payload.hprId)
    return this.http.post(`${environment.auth_prefix_url}/searchHprId`, payload);
  }

  MinisryHPRLogin(payload) {
    return this.http.post(`${environment.auth_prefix_url}/MinisryHPRLogin`, payload);
  }

  getabdmotp(payload) {
    return this.http.post(`${environment.auth_prefix_url}/getabdmotp`, payload);
  }
}
