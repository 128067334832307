import { NgModule, Directive, ElementRef } from '@angular/core';
import { Platform } from '@ionic/angular';


@Directive({
  selector: '[appScrollbarTheme]'
})
export class ScrollbarThemeDirective {
  constructor(
    el: ElementRef,
    private platform: Platform,
  ) {
    if(!this.platform.is('desktop')) {return;}   
    const stylesheet = `
      ::-webkit-scrollbar {
      width: 8px;
      }
      ::-webkit-scrollbar-track {
      background: #fff;
      }
      ::-webkit-scrollbar-thumb {
      border-radius: 1rem;
      background: linear-gradient(var(--ion-color-light-tint), var(--ion-color-light));
      border: 3px solid #888;
      }
      ::-webkit-scrollbar-thumb:hover {
      }
    `;

    const styleElmt = el.nativeElement.shadowRoot.querySelector('style');

    if (styleElmt) {
      styleElmt.append(stylesheet);
    } else {
      const barStyle = document.createElement('style');
      barStyle.append(stylesheet);
      el.nativeElement.shadowRoot.appendChild(barStyle);
    }

  }
}