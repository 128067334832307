import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { CmsComponent } from './components/cms/cms.component';
import { CommonLocationFieldsComponent } from './components/common-location-fields/common-location-fields.component';
import { NotificationAlertsComponent } from './components/notification-alerts/notification-alerts.component';
import { LanguageComponent } from './components/language/language.component';
import { MomentModule } from 'ngx-moment';

import { ZeroNotallowedDirective } from './directives/zero-notallowed.directive';
import { MinDirective } from './directives/min.directive';
import { MaxDirective } from './directives/max.directive';
import { InputCharDirective } from './directives/input-char.directive';
import { MobileNumberDirective } from './directives/mobile-number.directive';
import { RangeLengthDirective } from './directives/range-length.directive';
import { AadharNumberDirective } from './directives/aadhar-number.directive';
import { RangeDirective } from './directives/range.directive';
import { OnlynumberDirective } from './directives/numbersOnly.directive';
import { InputTrimDirective } from './directives/input-trim.directive';
import { AadhaarNumberValidationDirective } from './directives/aadhaar-validation.directive';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import { LinelistingstatusPipe } from './pipes/linelistingstatus.pipe';
import { LocationsPipe } from './pipes/locations.pipe';
import { ScrollbarThemeDirective } from './directives/custom-scroll';
import { LimitToDirective } from './directives/limit-to.directive';
import { ShortNumberPipe } from './pipes/short-number.pipe';
import { TimeFormatPipe } from './pipes/time-format.pipe';
import { IntegerInputDirective } from './directives/input-integer.directive';
import { IonCustomSelectComponent } from './components/ion-custom-select/ion-custom-select.component';
import { LoaderComponent } from './components/loader/loader.component';
import { OtpNumberDirective } from './directives/otp-number.directive';
import { MaterialModule } from './material.module';
import { PhotoIdSelectComponent } from './components/photo-id-select/photo-id-select.component';

import { ConvertToUpperCaseDirective } from './directives/camel-case-text.directive';
import { CommonRecipientFieldsComponent } from './components/common-recipient-fields/common-recipient-fields.component';
import { BeneficiaryNumberFormatPipe } from './pipes/beneficiary-number-format.pipe';
import { WebSearchComponent } from 'src/app/screens/search-beneficary/components/web-search/web-search.component';
import { NgxImageCompressService } from 'ngx-image-compress';
import { AadhaarConcentComponent } from './components/aadhaar-concent/aadhaar-concent.component'
import { IonCustomDatepickerComponent } from './components/ion-custom-datepicker/ion-custom-datepicker.component';
import { AgeFormatPipe } from './pipes/age-format.pipe';
import { NotificationDialog } from './components/notification-alerts/dialog/notification-dialog/notification-dialog';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { CustomDateFormatPipe } from './pipes/custom-date.pipe'

import { BeneficiaryCategoryFieldsComponent } from './components/beneficiary-category-fields/beneficiary-category-fields.component';
import { AddLinkingComponent } from 'src/app/screens/add-linking/add-linking.component';
import { UipVaccinationDetailsComponent } from './components/uip-vaccination-details/uip-vaccination-details.component';
import { SessionSiteDetailsComponent } from './components/session-site-details/session-site-details.component';
import { AshaVillagesFilterComponent } from './components/asha-villages-filter/asha-villages-filter.component';
import { PhoneNumberPipe } from './pipes/phonenumber-formater.pipe';
import { CommonFacilitySiteLocationFilterComponent } from './components/common-facility-site-location-filter/common-facility-site-location-filter.component';
import { PreviousVaccinationComponent } from './components/previous-vaccination/previous-vaccination.component';
import { OrderByDatePipe } from './pipes/order-by-date.pipe';
import { UnlinkHprIdComponent } from './unlink-hpr-id/unlink-hpr-id.component';
import { UpdateBeneficiaryVaccinationComponent } from './components/update-beneficiary-vaccination/update-beneficiary-vaccination.component';
import { SortByPipe } from './pipes/sort-by.pipe';
import { AbhaConsentComponent } from './components/abha-consent/abha-consent.component';
import { GuardianAbhaConsentComponent } from './components/guardian-abha-consent/guardian-abha-consent.component';
import { DisplayDateByAgePipe } from './pipes/display-date-by-age.pipe';
import { LastFourDigitsPipe } from './pipes/last-four-digit.pipe';
import { CustomMatLangPaginatorService } from '../shared/services/custom-mat-lang-paginator.service';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';


const exportComponents = [
  CmsComponent,
  SideBarComponent,
  HeaderComponent,
  FooterComponent,
  CommonLocationFieldsComponent,
  NotificationAlertsComponent,
  NotificationDialog,
  LanguageComponent,
  IonCustomSelectComponent,
  LoaderComponent,
  PhotoIdSelectComponent,
  CommonRecipientFieldsComponent,
  WebSearchComponent,
  AadhaarConcentComponent,
  IonCustomDatepickerComponent,
  BeneficiaryCategoryFieldsComponent,
  AddLinkingComponent,
  UipVaccinationDetailsComponent,
  SessionSiteDetailsComponent,
  AshaVillagesFilterComponent,
  CommonFacilitySiteLocationFilterComponent,
  PreviousVaccinationComponent,
  UnlinkHprIdComponent,
  UpdateBeneficiaryVaccinationComponent,
  AbhaConsentComponent,
  GuardianAbhaConsentComponent,
];

const exportDirectives = [
  ScrollbarThemeDirective,
  RangeDirective,
  RangeLengthDirective,
  MobileNumberDirective,
  InputCharDirective,
  MaxDirective,
  MinDirective,
  ZeroNotallowedDirective,
  AadharNumberDirective,
  InputTrimDirective,
  OnlynumberDirective,
  LimitToDirective,
  AadhaarNumberValidationDirective,
  IntegerInputDirective,
  OtpNumberDirective,
  ConvertToUpperCaseDirective,
];

const exportPipes = [
  LinelistingstatusPipe,
  LocationsPipe,
  ShortNumberPipe,
  TimeFormatPipe,
  BeneficiaryNumberFormatPipe,
  AgeFormatPipe,
  CustomDateFormatPipe,
  PhoneNumberPipe,
  OrderByDatePipe,
  SortByPipe,
  DisplayDateByAgePipe,
  LastFourDigitsPipe,
];

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
export const MY_MOMENT_FORMATS = {
  parseInput: 'DD/MM/YYYY hh:mm',
  fullPickerInput: 'DD/MM/YYYY HH:mm',
  datePickerInput: 'DD/MM/YYYY HH:MM',
  timePickerInput: 'hh:mm',
  monthYearLabel: 'MMM-YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM-YYYY'
};


@NgModule({
  declarations: [
    ...exportComponents,
    ...exportDirectives,
    ...exportPipes
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule,
    ReactiveFormsModule,
    TranslateModule,
    MaterialModule,
    NgxMaterialTimepickerModule,
    MomentModule
  ],
  exports: [
    ...exportComponents,
    ...exportDirectives,
    ...exportPipes,
    ReactiveFormsModule,
    TranslateModule,
    MaterialModule,
    NgxMaterialTimepickerModule,
    MomentModule
  ],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    NgxImageCompressService,
    { provide: MatPaginatorIntl, useClass: CustomMatLangPaginatorService }
  ],

})
export class SharedModule { }
