import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddRecipinentPage } from './add-recipient.page';
import { SelectMultiVaccinationUipComponent } from './components/select-multi-vaccination-uip/select-multi-vaccination-uip.component';
import { MemberCategoryInfoPageComponent } from './pages/member-category-info/member-category-info.page';
import { MemberIdentificationPageComponent } from './pages/member-identification/member-identification.page';
import { MemberPersonalDetailsPageComponent } from './pages/member-personal-details/member-personal-details.page';
import { MemberVaccinationInfoPageComponent } from './pages/member-vaccination-info/member-vaccination-info.page';
import { MemberVerifiedPageComponent } from './pages/member-verified/member-verified.page';
import { VaccinateMemberPageComponent } from './pages/vaccinate-member/vaccinate-member.page';
import { VaccinationRejectedPageComponent } from './pages/vaccination-rejected/vaccination-rejected.page';
import { VerifyVaccinatePageComponent } from './pages/verify-vaccinate/verify-vaccinate.page';

const childRoutes = [
  { path: 'member-basic-info', component: MemberCategoryInfoPageComponent},
  { path: 'member-identification', component: MemberIdentificationPageComponent},
  { path: 'member-identification/:benID/:sessionID', component: MemberIdentificationPageComponent},
  { path: 'member-personal-details', component: MemberPersonalDetailsPageComponent},
  { path: 'member-personal-details/:benID/:sessionID', component: MemberPersonalDetailsPageComponent},
  { path: 'member-verified', component: MemberVerifiedPageComponent},
  { path: 'member-verified/:benID/:sessionID', component: MemberVerifiedPageComponent},
  { path: 'member-vaccination-info', component: MemberVaccinationInfoPageComponent},
  { path: 'member-vaccination-info/:benID/:sessionID', component: MemberVaccinationInfoPageComponent},
  { path: 'vaccinate-member', component: VaccinateMemberPageComponent},
  { path: 'vaccinate-member/:benID/:sessionID', component: VaccinateMemberPageComponent},
  { path: 'vaccination-rejected',component: VaccinationRejectedPageComponent},
  { path: 'vaccination-rejected/:benID/:sessionID',component: VaccinationRejectedPageComponent},
  { path: 'verify-vaccinate', component: VerifyVaccinatePageComponent},
  { path: 'vaccinate-recipient', component: VaccinationRejectedPageComponent},
  { path: 'member-vaccination-info/:benID/:sessionID', component: MemberVaccinationInfoPageComponent},
  // {path:'multi-vacc-uip',component: SelectMultiVaccinationUipComponent}
]

const routes: Routes = [
  { path:'',
    component:AddRecipinentPage,
    children: [
      { path:'',redirectTo:'member-basic-info', pathMatch: 'full'},
      ...childRoutes
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AddRecipinentPageRoutingModule {}
