import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map, publishReplay, refCount } from 'rxjs/operators';
import { EncDecService } from './encryption-decryption.service';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  appVersionObservable$: Observable<any>;
  configObservables = new Object();
  appConfig = new ReplaySubject(1);

  constructor(private http: HttpClient, private encDecServ: EncDecService,
    private dataService: DataService) { }

  getVersionDetails(): Observable<any> {
    if (!this.appVersionObservable$) {

      this.appVersionObservable$ = this.http.get(
        `${environment.vaccinator_url}/mobile/appVersion`).pipe(
          map(res => { this.appConfig.next(res);  return res}),
          publishReplay(1),
          refCount()
        );
    }
    return this.appVersionObservable$;
  }

  setVersionDetails(){
    return new Observable((subscriber) =>{ 
      this.getVersionDetails().subscribe(res => {
            if(res){ 
                this.dataService.appVersion = res.app_version;
                this.encDecServ.encDetails({value: res.ydata, timestamp: res.xdata});
            }
            subscriber.complete();
      });    
    });
  }

  getConfig(key): Observable<any> {
    if (!this.configObservables.hasOwnProperty(key)) {
      this.configObservables[key] = this.http.get<any>(
        `${environment.vaccinator_url}/getConfig/${key}`
      ).pipe(
        map(res => res),
        publishReplay(1),
        refCount()
      );
    }
    return this.configObservables[key];
  }

  checkApkVersion() {
    return this.http.get(`${environment.vaccinator_url}/mobile/appVersion`);
  }

  saveLatLngDetails(payload) {
    let session_site_id = this.dataService.userDetails?.session_site_id
    payload['session_site_id'] = this.encDecServ.setWithSpace(session_site_id)
    return this.http.post<any>(`${environment.vaccinator_url}/updateLatLong`, payload)
  }

  getSettings() {
    return this.http.get(`${environment.vaccinator_url}/getSettings`);
  }
}
