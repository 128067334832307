import * as config from './config';
// const packageJson = require('../../../../package.json');



const CDN_BASE_URL = config.ENV.SIT.API_URLS.API_CDN_URL;
const DIRECT_BASE_URL = config.ENV.SIT.API_URLS.API_URL;
const API_ADMIN_PORT = config.ENV.SIT.API_URLS.API_ADMIN_PORT;
const API_AUTH_PORT = config.ENV.SIT.API_URLS.API_AUTH_PORT;
const API_USER_PORT = config.ENV.SIT.API_URLS.API_USER_PORT;
const API_REGISTRATION_PORT = config.ENV.SIT.API_URLS.API_REGISTRATION_PORT;
const API_SESSION_PORT = config.ENV.SIT.API_URLS.API_SESSION_PORT;
const API_REPORTS_PORT = config.ENV.SIT.API_URLS.API_REPORTS_PORT;

export const environment = {
  appName: 'U-WIN Vaccinator',
  envName: 'PROD',
  production: false,
  defaultLanguage: config.ENV.defaultLanguage,
  supportedLanguages: config.ENV.supportedLanguages,
  AppRedirectUrls: config.ENV.SIT.APP_URLS,
  admin_prefix_url_port: `${CDN_BASE_URL}${API_ADMIN_PORT}`,
  session_prefix_url: `${CDN_BASE_URL}${API_SESSION_PORT}/api/v1/session`,
  admin_prefix_url: `${CDN_BASE_URL}${API_ADMIN_PORT}/api/v1/admin`,
  auth_prefix_url: `${CDN_BASE_URL}${API_AUTH_PORT}/api/v3/auth`,
  auth_prefix_url_v2: `${CDN_BASE_URL}${API_AUTH_PORT}/api/v3/auth`,
  user_prefix_url: `${CDN_BASE_URL}${API_USER_PORT}/api/v3/admin/user`,
  beneficiary_registration_prefix_url: `${CDN_BASE_URL}${API_REGISTRATION_PORT}/api/v1/registration`,
  vaccinator_url: `${CDN_BASE_URL}${API_SESSION_PORT}/api/v3/vaccinator`,
  vaccinator_url_without_cdn: `${DIRECT_BASE_URL}${API_SESSION_PORT}/api/v3/vaccinator`,
  beneficiary_vaccinator_v3_url:`${CDN_BASE_URL}/api/v3/vaccinator/beneficiary`,
  admin_prefix_v3_url: `${CDN_BASE_URL}${API_ADMIN_PORT}/api/v3/admin`,
  aadhar_vaccinator_url: `${CDN_BASE_URL}/api/v3/vaccinator/aadhaar`,
  vaccination_status_url:`${CDN_BASE_URL}${API_ADMIN_PORT}/api/v3/appreciation-letter`,
  hip_port_url:`${CDN_BASE_URL}${API_SESSION_PORT}/v0.5`,
  rd_device_prefix_url: `https://localhost:`,
  recaptcha_site_key: '6LdNBNUZAAAAAAYITU9accPJNhMSzHMsuqc99RPE',
  chatboturl: 'https://covid19-staging.nhp.gov.in:80/vaccinator/',
  isProduction: false,
  isUat: false,
  isChildABHAenabled: true,
  isM2Enabled: true,
  env: 'OtherEnv',
  remoteAppIP: config.ENV.remoteIP,
  authKey: config.ENV.authSecKey,
  userKey: config.ENV.benSecKey,
  remoteAPIKey: config.ENV.remoteApiKey,
  appVersion: '7',
  static_ids: config.ENV.STATIC_IDs_USED,
  display_date_format: config.ENV.displayDateFormat,
  HPR_URL: 'https://hspsbx.abdm.gov.in/',
  pvtg_enabled: true,
  MIN_DAYS_DIFF_LAST_PREG_OUTCOME: 42,
  i18_enabled: true,
  ARMED_FORCES_ID: 17
};
