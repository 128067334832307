import { ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { LocalStorageService } from 'src/app/modules/core/services/local-storage.service';
import { UserService } from 'src/app/modules/core/services/user.service';
import { getUserDetails } from 'src/app/modules/core/store/selectors/user.selectors';
import { DataService } from '../../services';
import { EncDecService } from '../../services/encryption-decryption.service';
import { UtilsService } from '../../services/utils.service';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { tap } from 'rxjs/operators';
@Component({
  selector: 'app-session-site-details',
  templateUrl: './session-site-details.component.html',
  styleUrls: ['./session-site-details.component.scss']
})

export class SessionSiteDetailsComponent implements OnInit {
  siteDetailsForm: FormGroup
  siteDetails: any = [];
  facilities: any = [];
  allSiteDetails: any = [];
  selectedSessionId: any;
  currentRoute: any = null;
  userInfo: any;
  environment;

  constructor(public dataService: DataService, public util: UtilsService, public localStorage: LocalStorageService,
    private store$: Store, public encDecService: EncDecService, private userService: UserService,
    private router: Router, private loc: Location, public cdr: ChangeDetectorRef
  ) {
    this.store$.pipe(select(getUserDetails))
      .subscribe(r => {
        this.userInfo = r;
      })
  }


  ngOnInit() {
    this.environment = environment;
    this.siteDetailsForm = new FormGroup({
      session_site_id: new FormControl(''),
      facility_id: new FormControl('')
    })
    this.getAllSites();
    let id = this.encDecService.setWithSpace(this.userInfo.session_site_id)
    if (this.localStorage.getItem('facilityId')) {
      this.siteDetailsForm.get('facility_id').setValue(this.localStorage.getItem('facilityId'));
    }
    // if (this.userInfo.session_site_id) {
    //   this.siteDetailsForm.get('session_site_id').setValue(this.userInfo.session_site_id.toString());
    // }
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
      }
    });
    this.cdr.detectChanges();
  }
  facilityChange(event) {
    this.localStorage.setItem('facilityId', event);
    this.siteDetails = this.allSiteDetails.filter(ele => ele.facility_id == event)
  }

  getAllSites() {
    this.dataService.getAllSites().pipe(
      tap((res) => {
        if (res) {
          this.allSiteDetails = res;
          // this.facilities = _.uniqBy(res, e => e.facility_id);
          this.facilities = _.uniqBy(res, function (e) {
            return e.facility_id;
          });

        }
      })
    ).subscribe(() => {
      const facilityId = this.localStorage.getItem('facilityId');
      this.siteDetails = this.allSiteDetails.filter(ele => ele.facility_id === facilityId);
      this.cdr.detectChanges();
    });
  }

  onSelectionChange(event) {
    this.sessionSiteChange(event.value);
  }

  sessionSiteChange(sessionSite) {
    // this.selectedSessionId = this.encDecService.get(sessionSite.session_site_id)
    let selectedSessionSite
    selectedSessionSite = this.siteDetails.find(ele => ele.session_site_id == sessionSite);
    if(selectedSessionSite){
      // selectedSessionSite.session_site_id = this.encDecService.get(selectedSessionSite.session_site_id);
    }
    this.selectedSessionId = this.encDecService.get(selectedSessionSite.session_site_id);
    this.localStorage.setItem('session_site_id', selectedSessionSite.session_site_id);
    // this.localStorage.setItem('siteInfo', {...selectedSessionSite, session_site_id:this.encDecService.get(selectedSessionSite.session_site_id)});
    // this.dataService.siteInfoDetails = {...selectedSessionSite, session_site_id:this.encDecService.get(selectedSessionSite.session_site_id)};
  }

  storeSessionSiteInUserDetails(siteDetails) {
    this.dataService.setDefaultSite(siteDetails.session_site_id).subscribe(res => {
      const currentRoute = this.loc.path();
      if (res) {
        if (currentRoute.match(/dashboard/g) || currentRoute == '/' || currentRoute == '') {
          window.location.reload();
        } else {
          window.location.href = '/dashboard';
        }
        // this.userService.getUserDetails().subscribe(userInfo=>{
        //   UserActions.SetUser(userInfo);
        //   this.store$.dispatch(GetProfile());
        // });
      }
    })
    // this.localStorage.setItem('session_site_id',this.encDecService.get(siteDetails.session_site_id)),
    // this.localStorage.setItem('session_site_name',siteDetails.session_site_name)

  }

}
