<form [formGroup]="deathForm" class="autoScroll">
  <ion-grid>
    <ion-icon (click)="close()" class="custom-close"></ion-icon>
    <app-ion-custom-datepicker style="width: 100%;" [label]="'Date of Death' | translate" formKey='dateOfDeath' [parentForm]="deathForm"
      [minDate]="minDate" [maxDate]="maxDate" [class]="'noclass'" [placeholder]="'DD/MM/YYYY' | translate"
      (dateValue)="declareDeathValue($event)">
    </app-ion-custom-datepicker>
    <ion-row>
      <ion-col size="12" class="ion-text-end">
        <ion-button [disabled]="!deathForm.get('dateOfDeath').value" class="primaryBtn" (click)="declareDeath()">{{'Confirm Declare Death' | translate}}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</form>