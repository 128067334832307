<form [formGroup]='aadhareKYC' autocomplete="off" (ngSubmit)='onSubmit()'>
    <ion-grid>
        <ion-row>
            <ion-col size="12">
                <ion-label class="required">{{'Aadhaar UID Number' | translate}}</ion-label>
                <h2 *ngIf="AlreadyRegistered">{{AadharNumber | benfNumberFormat}}</h2>
                <ion-item class="item-border ion-no-padding" lines="none" *ngIf="!AlreadyRegistered">
                    <ion-input [placeholder]="'Enter 12 digit Aadhaar number' | translate" type="text" numbersOnly aadhaarValid appIntegerInput (keyup)="restrictUserToTypeMoreThanLength($event, 12)" [appLimitTo]="12" appAadharNumber [appRange]="[200000000000, 999999999999]" formControlName='photo_id_number'
                        type="tel" errorText=" ">
                    </ion-input>
                </ion-item>
                <ion-label *ngIf="!aadhareKYC.controls.photo_id_number.valid  && (aadhareKYC.controls.photo_id_number.hasError('range'))" class="error-validation ">
                    <p>{{'Aadhaar Number must be 12 digits' | translate}}</p>
                </ion-label>
                <ion-label *ngIf="!(aadhareKYC.controls.photo_id_number.hasError('range')) &&(aadhareKYC.controls.photo_id_number.hasError('notValidAadhaar'))" class="error-validation ">
                    <p>{{'Aadhaar Number is Invalid' | translate}}</p>
                </ion-label>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col size="12" class="authenticItem">
                <ion-label class="required">{{'Select Authentication Type' | translate}}</ion-label>
                <ion-list>
                    <ion-radio-group formControlName="eKYC_by" (ionChange)="onSelecteKYCType()">
                        <ion-item lines="none" class="item-border" *ngIf="(plt.is('desktop') || plt.is('cordova'))" >
                            <ion-label>
                                {{'Facial authentication' | translate}}
                            </ion-label>
                            <ion-icon slot="start" class="custom-facial"></ion-icon>
                            <ion-radio [disabled]="!aadhareKYC.controls.photo_id_number.valid" slot="end" value="Facial"></ion-radio>
                        </ion-item>
                        <ion-item lines="none"  class="item-border" *ngIf="(plt.is('desktop') || plt.is('cordova'))">
                            <ion-label>
                                {{'Demographic Input' | translate}}
                            </ion-label>
                            <ion-icon slot="start" class="custom-qr-code"></ion-icon>
                            <ion-radio [disabled]="!aadhareKYC.controls.photo_id_number.valid" slot="end" value="Demographic">
                            </ion-radio>
                        </ion-item>
                        <ion-item class="item-border" lines="none" *ngIf="(plt.is('desktop') || plt.is('cordova'))" >
                            <ion-label>
                                {{'Biometric Scan' | translate}}
                            </ion-label>
                            <ion-icon slot="start" class="custom-biometric"></ion-icon>
                            <ion-radio [disabled]="!aadhareKYC.controls.photo_id_number.valid" slot="end" value="Biometric">
                            </ion-radio>
                        </ion-item>
                        <ion-item lines="none" class="item-border">
                            <ion-label>
                                {{'OTP on Registered Number' | translate}}
                            </ion-label>
                            <ion-icon slot="start" class="custom-sms"></ion-icon>
                            <ion-radio [disabled]="!aadhareKYC.controls.photo_id_number.valid" slot="end" value="OTP"></ion-radio>
                        </ion-item>
                    </ion-radio-group>
                </ion-list>
            </ion-col>
        </ion-row>
    </ion-grid>
    <ion-row>
        <ion-col size-md="12" class="ion-text-end">
            <ion-button [disabled]="!aadhareKYC.get('eKYC_by').value" class="primaryBtn" shape="round" type="submit">
                {{'Start Verification' | translate}} </ion-button>
        </ion-col>
    </ion-row>
</form>
