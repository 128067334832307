import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
@Injectable({
  providedIn: 'root'
})
export class SurveyorService {
  beneficiaries: any = [];
  constructor(private http: HttpClient) { }

  getTotalBeneficiaryBySurveyor(): Observable<any> {
    if (window.navigator.onLine) {
      const postData = {
        start_date : moment().format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD')
      }
      return this.http.post(`${environment.beneficiary_registration_prefix_url}/beneficiary/v2/getTotalBeneficiaryBySurveyor`, postData);
    }else{
      return of(null);
    }
  }

  getBeneficiaryCountBySurveyor(): Observable<any> {
    if (window.navigator.onLine) {
      return this.http.get(`${environment.beneficiary_registration_prefix_url}/beneficiary/v2/getBeneficiaryCountBySurveyor`)
    }else{
      return of(null);
    }
  }

  getBeneficiaryBySurveyor(formData?): Observable<any> {
    if (window.navigator.onLine) {
      this.beneficiaries = [];
      let data = formData || {};
      return this.http.post(`${environment.beneficiary_registration_prefix_url}/beneficiary/v2/getBeneficiaryBySurveyor`, data)
      .pipe(map(r => {
        this.beneficiaries = r;
        return r;
      }));
    }else{
      return of(null);
    }
  }
}
