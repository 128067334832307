import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { Observable, BehaviorSubject } from 'rxjs';

const { Network } = Plugins;

@Injectable({ providedIn: 'root' })
export class ConnectivityProvider {

  public appIsOnline$: Observable<boolean>;
  private appIsOnlineSubject = new BehaviorSubject<boolean>(true);

  constructor(private platform: Platform) {
    this.appIsOnline$ = this.appIsOnlineSubject.asObservable();
    if (platform.is('cordova') || platform.is('hybrid')) {
      this.initConnectivityMonitoring();
    }
  }

  private initConnectivityMonitoring() {
    Network.addListener('networkStatusChange', (status) => {
      this.appIsOnlineSubject.next(status.connected);
    });

  }

}