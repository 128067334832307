<!-- <ion-content appScrollbarTheme  fullscreen class="aadhar-concen-wrap"> -->
  <ion-grid appScrollbarTheme fullscreen class="aadhar-concen-wrap">
    <ion-row>
        <ion-col size="12" class="ion-text-end">
            <ion-icon class="custom-close" (click)="dismissModal()"></ion-icon>
        </ion-col>
    </ion-row>
    <ion-row>
        <ion-col size-lg="12" size-md="12">
            <div class="aadhar-concent" *ngIf="data.benCat">
                <ul>
                  <p class="ion-margin-top">
                    {{'I hereby declare that' | translate }}:<br/>
                    1. {{'I am applying on behalf of this child, as I am their guardian, for the purpose of creating a Child ABHA on their behalf, where “child” shall have the meaning ascribed to it under Paragraph 4(b) of the ABDM Health Data Management Policy.' | translate}}
                  </p>
                  <p>
                    2. {{'I understand that participation in the National Digital Health Ecosystem (“NDHE”) is voluntary as set out in Paragraph 16.1 of the ABDM Health Data Management Policy.' | translate}}
                  </p>
                  <p>
                    3. {{'I understand that I can withdraw my consent for the creation of a Child ABHA at any point in time and that I have the right to request for deletion of personal data that has been submitted in this regard as set out in Paragraph 16.2 of the ABDM Health Data Management Policy.' | translate}}
                  </p>
                  <p>
                    4. {{'I understand that a Child ABHA, so created, can be used, and shared for purposes as may be notified by Ayushman Bharat Digital Mission (“ABDM”) from time to time, and as per the ABDM Health Data Management Policy.Such uses may include the provision of healthcare services.' | translate}}
                  </p>
                  <p>
                      <!-- {{'5. I understand and consent to provide the following personal and demographic data in relation to the child:' | translate}}
                      {{'(a) Name of Child;' | translate}}
                      {{'(b) Sex of Child;' | translate}}
                      {{'(c) Date of Birth of Child' | translate}}
                      {{'I understand that this personal and demographic data will be used only for the purpose of
                      creating a Child ABHA, Child ABHA Address and for the purposes set out in Paragraph 6
                      below.' | translate}} -->
                      5. {{'I understand and consent to provide the following personal and demographic data in relation to the child' | translate}}
                      (a) {{'Name of Child' | translate}};
                      (b) {{'Sex of Child' | translate}};
                      (c) {{'Date of Birth of Child' | translate}}
                      {{'I understand that this personal and demographic data will be used only for the purpose of creating a Child ABHA, Child ABHA Address and for the purposes set out in Paragraph 6 below.' | translate}}
                  </p>
                  <p>
                    6. {{'I am aware that the personal data of the child (Name, Address, Age, Date of Birth, Sex) may be made available to the entities working in the NDHE which inter alia may include entities such as healthcare professionals (e.g. doctors), healthcare facilities (e.g.hospitals, laboratories) and data fiduciaries (e.g. healthcare programmes), which are registered with, or linked to, the ABDM, and various processes thereunder.' | translate}}
                  </p>
                  <p>
                    7. {{'I understand that I may be required to submit certain documents as set out under Paragraph 3(3) and 3(f) of the Child ABHA Guidelines to act as Proof of Relationship and Proof of Identity between myself and the child.' | translate}}
                  </p>
                  <p>
                    8. {{'I understand that these documents will be separately, physically verified by a participating ABDM health facility in the event that I choose to create a Child ABHA at such ABDM health facility.' | translate}}
                  </p>
                </ul>
            </div>
            <div class="aadhar-concent" *ngIf="!data.benCat">
              <ul>
                <p class="ion-margin-top">
                  {{'I hereby declare that:' | translate}}<br/>
                
                  {{'I am voluntarily sharing my Aadhaar Number / Virtual ID issued by the Unique Identification Authority of India (“UIDAI”), and my demographic information for the purpose of creating an Ayushman Bharat Health Account number (“ABHA number”) and Ayushman Bharat Health Account address (“ABHA Address”). I authorize NHA to use my Aadhaar number / Virtual ID for performing Aadhaar based authentication with UIDAI as per the provisions of the Aadhaar (Targeted Delivery of Financial and other Subsidies, Benefits and Services) Act, 2016 for the aforesaid purpose. I understand that UIDAI will share my e-KYC details, or response of “Yes” with NHA upon successful authentication.' | translate}}
                </p>
                <p>
{{'I intend to create Ayushman Bharat Health Account Number (“ABHA number”) and Ayushman Bharat Health Account address (“ABHA Address”) using document other than Aadhaar. (Click here to proceed further).' | translate}}</p>
                  <p>
{{'I consent to usage of my ABHA address and ABHA number for linking of my legacy (past) government health records and those which will be generated during this encounter.' | translate}}</p>

<p>{{'I authorize the sharing of all my health records with healthcare provider(s) for the purpose of providing healthcare services to me during this encounter.' | translate}}</p>

<p>{{'I consent to the anonymization and subsequent use of my government health records for public health purposes.' | translate}}</p>
<p>{{'I, (name of healthcare worker- depending on the username used for logging in into the system), confirm that I have duly informed and explained the beneficiary of the contents of consent for aforementioned purposes.' | translate}}</p>
 
<p>{{'I, (beneficiary name), have been explained about the consent as stated above and hereby provide my consent for the aforementioned purposes.' | translate}}</p>
 
              </ul>
          </div>
        </ion-col>
    </ion-row>
</ion-grid>
<!-- </ion-content>      -->
