import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  selectIsAuthenticated,
} from './modules/core/core.module';
import { SplashScreen } from '@capacitor/splash-screen';

import { LocalStorageService } from './modules/core/services/local-storage.service';
import { GetProfile } from './modules/core/store/actions/user.actions';
import { StatusBar } from '@capacitor/status-bar';
import { NotificationDialog } from './screens/notifications/dialog/notification-dialog/notification-dialog';
import { MatDialog } from '@angular/material/dialog';
import { UtilsService } from './modules/shared/services/utils.service';
import { Platform } from '@ionic/angular';
import { ConfigService, DataService } from './modules/shared/services';
import { getUserDetails } from './modules/core/store/selectors/user.selectors';
import { EncDecService } from './modules/shared/services/encryption-decryption.service';
import { register } from 'swiper/element/bundle';
import CustomRootDetection, {CustomRootDetectionPlugin} from './custom-plugins/android-native-plugin/custom-root-detection-plugin';
import { App } from '@capacitor/app';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';
import { LanguageService } from './modules/shared/components/language/language.service';
import { I18nService } from './modules/i18n/i18n.service';

// import { PrivacyScreen } from '@capacitor-community/privacy-screen';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  isAuthenticated$: Observable<boolean>;
  dark = false;
  currLang = 'en';  
  constructor(
    private store$: Store, 
    private localStorage: LocalStorageService,
    private dialog: MatDialog,
    private utilsService: UtilsService,
    private platform: Platform,
    private dataService:DataService,
    public encDecService:EncDecService,
    private configService: ConfigService,
    private translateServ:TranslateService,
    private titleService: Title,
    private i18nService: I18nService,
  ) {
    this.initializeApp();
        // let status bar overlay webview
  }

  rootedRslt = null;

  initializeApp() {
    this.platform.ready().then(async () => {
      if(this.utilsService.getIsMobileDevice()){ 
        StatusBar.setOverlaysWebView({
          overlay: false
        });
        StatusBar.setBackgroundColor({
          color:'#8A8D90'
        });
        await SplashScreen.show({
          showDuration: 3000,
          autoHide: true,
        });
        CustomRootDetection.isRooted().then(r=>{
          if(r.value){
            App.exitApp();
          }
        });
      }
      });
  }

  ngOnInit() {
    // this.configService.getVersionDetails().subscribe(res => {
    //   if(res){ 
    //     this.dataService.appVersion = res.app_version;
    //     this.encDecService.encDetails({value: res.ydata, timestamp: res.xdata});
    //   }
    // });

    this.isAuthenticated$ = this.store$.pipe(select(selectIsAuthenticated));
    this.isAuthenticated$.subscribe(r => {
      if(r){
        this.getSettings();
        this.setUserDetails();
        if(this.localStorage.getItem('siteInfo')){
          this.storeSessionSiteInUserDetails(this.localStorage.getItem('siteInfo'));
        }
        // this.getAllSites()
      }
    });
    this.setCurrLanguage();
  }

  setUserDetails() {
    if(!this.localStorage.getItem('userToken')){ return; }
    this.store$.dispatch(GetProfile());
  }
  storeSessionSiteInUserDetails(siteDetails){
    this.store$.pipe(select(getUserDetails)).subscribe(userInfo => {
      if(userInfo && siteDetails && siteDetails.session_site_id){
        userInfo['session_site_id'] = siteDetails.session_site_id;
      }
    });
  }

  getSettings() {
    this.configService.getSettings().subscribe(s=>{
      this.dataService.settings = s;
    });
  }
  getAllSites(){
    this.dataService.getAllSites().subscribe(res=>{
      if(res){
        // if(!this.localStorage.getItem('session_site_id')){
        this.storeSessionSiteInUserDetails(res[0])
      // }
    }
    })
  }

  setCurrLanguage(){
    if(environment.i18_enabled){ 
        this.currLang = this.i18nService.language;
        this.translateServ.onLangChange.subscribe( (r: any) => {
          this.currLang = this.translateServ.currentLang;
          this.titleService.setTitle(this.translateServ.instant('TITLE'));
        });
      }
    }
}//add space
