import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { i18nTranslateLoader } from './i18n-loader';
import { I18nService } from './i18n.service';
import { environment } from 'src/environments/environment';


@NgModule({
  imports: [
    HttpClientModule,
    TranslateModule.forRoot()
    // TranslateModule.forRoot({
    //   loader: {
    //     provide: TranslateLoader,
    //     useFactory: i18nTranslateLoader,
    //     deps: [HttpClient]
    //   }
    // }),
  ],
  exports: [TranslateModule]
})
export class I18nModule {
  constructor(
    @Optional() @SkipSelf() parentModule: I18nModule,
    translate: TranslateService,
    i18nService: I18nService,
  ) {
    i18nService.init(environment.defaultLanguage, environment.supportedLanguages);
  }
}

