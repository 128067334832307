<form [formGroup]="siteDetailsForm">
  <div *ngIf="util.getPlatformWidth() < environment?.static_ids?.TabResoltion">
    <ion-row>
      <ion-col>
        <!-- <ion-label>Select Facility {{siteDetailsForm.get('facility_id').value}}</ion-label>
        <ion-select class="sessionSite" interface="action-sheet" (ionChange)="facilityChange($event.detail.value)"
          formControlName="facility_id">
          <ion-select-option *ngFor="let facility of facilities"
            [value]="facility.facility_id">{{facility.facilty_name}}</ion-select-option>
        </ion-select> -->
        <ion-label>Select Facility</ion-label>
        <mat-form-field appearance="outline" class="ion-margin-vertical matDropdown">
          <mat-select placeholder="Select from the list" formControlName="facility_id"
            (selectionChange)="facilityChange($event.value)">
            <mat-option *ngFor="let facility of facilities" [value]="facility.facility_id">
              {{facility.facilty_name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ion-col>
    </ion-row>
  </div>
  <div *ngIf="util.getPlatformWidth() < environment?.static_ids?.TabResoltion">
    <ion-row>
      <ion-col>
        <!-- <ion-label>Select Session</ion-label>
        <ion-select class="sessionSite" interface="action-sheet" (ionChange)="sessionSiteChange($event)"
          formControlName="session_site_id">
          <ion-select-option *ngFor="let site of siteDetails"
            [value]="encDecService.get(site.session_site_id)">{{site.session_site_name}}</ion-select-option>
        </ion-select> -->
        <ion-text>Current UIP Vaccination Session Site</ion-text>
        <mat-form-field appearance="outline" class="ion-margin-vertical matDropdown">
          <mat-select placeholder="Select from the list" formControlName="session_site_id"
            (selectionChange)="onSelectionChange($event)">
            <mat-option *ngFor="let site of siteDetails" [value]="site.session_site_id">
              {{site.session_site_name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ion-col>
    </ion-row>
  </div>
  <ng-container *ngIf="util.getPlatformWidth() >= environment?.static_ids?.TabResoltion">
    <ion-row size="6">
      <ion-col size="6">
        <ion-text>Select Facility</ion-text>
        <mat-form-field appearance="outline" class="ion-margin-vertical matDropdown">
          <mat-select placeholder="Select from the list" formControlName="facility_id"
            (selectionChange)="facilityChange($event.value)">
            <mat-option *ngFor="let facility of facilities" [value]="facility.facility_id">
              {{facility.facilty_name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ion-col>
    </ion-row>
    <ion-row size="6">
      <ion-col size="6">
        <ion-text>Current UIP Vaccination Session Site</ion-text>
        <mat-form-field appearance="outline" class="ion-margin-vertical matDropdown">
          <mat-select placeholder="Select from the list" formControlName="session_site_id"
            (selectionChange)="onSelectionChange($event)">
            <mat-option *ngFor="let site of siteDetails" [value]="site.session_site_id">
              {{site.session_site_name}}
            </mat-option> 
          </mat-select>
        </mat-form-field>
      </ion-col>
    </ion-row>
  </ng-container>
</form>