import { FormGroup } from '@angular/forms';
import { Component, Input, OnInit, AfterContentChecked, Output, EventEmitter } from '@angular/core';
import { DataService } from 'src/app/modules/shared/services/data.service';
import { LocationService } from 'src/app/modules/shared/services/location.service';
import { UserLevel } from './user-level.model';

@Component({
  selector: 'app-common-location-fields',
  templateUrl: './common-location-fields.component.html',
  styleUrls: ['./common-location-fields.component.scss'],
})
export class CommonLocationFieldsComponent implements OnInit, AfterContentChecked {

  @Input() parentForm: FormGroup;
  @Input() isDisabled: boolean = false;
  @Input() stateId: any = null;
  @Input() districtId: any = null;
  @Input() blockId: any = null;
  stateList;
  districtList;
  blockList;
  userLevel;
  userStateId;
  userDistrictId;
  userBlockId;
  @Output() blockSelected = new EventEmitter();

  constructor(private locationsService: LocationService,
              private dataService: DataService) {
    this.userLevel = this.dataService.userDetails.user_level;
    this.userStateId = this.dataService.userDetails.state_id;
    this.userDistrictId = this.dataService.userDetails.district_id;
    this.userBlockId  = this.dataService.userDetails.block_id;
    this.getStateList();
  }

  ngOnInit() {
    this.setDataInLocation();
    // this.enableDisable();
  }

  ngAfterContentChecked(): void {
    if ((this.userLevel === UserLevel.STATE) ||
    (this.userLevel === UserLevel.DISTRICT) ||
    (this.userLevel === UserLevel.BLOCK)) {
      if (this.userStateId) {
        this.parentForm.get('stateId').disable();
        this.parentForm.get('stateId').setValue(this.userStateId);
      }

      if (this.userDistrictId) {
        this.parentForm.get('districtId').disable();
        this.parentForm.get('districtId').setValue(this.userDistrictId);
      }

      if (this.userBlockId) {
        this.parentForm.get('blockId').disable();
        this.parentForm.get('blockId').setValue(this.userBlockId);
      }
    }
  }

  getStateList() {
    if ((this.userLevel === UserLevel.STATE) ||
        (this.userLevel === UserLevel.DISTRICT) ||
        (this.userLevel === UserLevel.BLOCK) ||
        (this.userLevel === UserLevel.FACILITY)) {
          this.getDistrictList(this.userStateId);
    }
    this.locationsService.getStates().subscribe((res) => this.stateList = res);
  }

  getDistrictList(stateId) {
    if ((this.userLevel === UserLevel.DISTRICT) ||
        (this.userLevel === UserLevel.BLOCK) ||
        (this.userLevel === UserLevel.FACILITY)) {
          this.getBlockList(this.userDistrictId);
    }
    this.locationsService.getDistricts(stateId).subscribe((res) => this.districtList = res);
  }

  getBlockList(districtId) {
      this.locationsService.getBlocks(districtId).subscribe((res) => this.blockList = res);
  }

  blockSelectedEmit() {
    this.blockSelected.emit(true);
  }

  setDataInLocation() {
    if (this.stateId) {
      this.getDistrictList(this.stateId);
    }

    if (this.districtId) {
      this.getBlockList(this.districtId);
    }
  }

}
