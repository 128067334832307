export const ENV = {
  "GOVT_PROD": {
    "API_URLS": {
      "API_CDN_URL": "https://cdn-api.co-vin.in",
      "API_URL": "https://api.cowin.gov.in",
      // "API_CDN_URL": "https://uwinapi-lb.mohfw.gov.in",
      // "API_URL": "https://uwinapi-cdn.mohfw.gov.in",
      "API_ADMIN_PORT": "",
      "API_AUTH_PORT": "",
      "API_USER_PORT": "",
      "API_REGISTRATION_PORT": "",
      "API_SESSION_PORT": "",
      "API_REPORTS_PORT": "",
      "APPOINTMENT_PORT": "",
    },
    "APP_URLS": {
      "admin": "https://uwinadmin.mohfw.gov.in",
      "webApp": "https://uwinvaccinator.mohfw.gov.in",
      "selfReg": "https://uwinselfregistration.mohfw.gov.in",
      "mainPage": "https://uwin.mohfw.gov.in"
    }
  },
  "SIT": {
    "API_URLS": {
      // "API_CDN_URL": "https://cdn-api.co-vin.in",
      // "API_URL": "https://api.cowin.gov.in",
      "API_CDN_URL": "https://cdndemo-api.co-vin.in",
      "API_URL": "https://cdndemo-api.co-vin.in",
      // "API_CDN_URL": "https://uwinuat-api.co-vin.in",
      // "API_URL": "https://uwinuat-api.co-vin.in",
      "API_ADMIN_PORT": "",
      "API_AUTH_PORT": "",
      "API_USER_PORT": "",
      "API_REGISTRATION_PORT": "",
      "API_SESSION_PORT": "",
      "API_REPORTS_PORT": "",
      "APPOINTMENT_PORT": "",
    },
    "APP_URLS": {
      "admin": "https://administration.demo.co-vin.in",
      "webApp": "https://vaccinator.demo.co-vin.in",
      "selfReg": "https://register.demo.co-vin.in",
      "mainPage": "https://uwin.demo.co-vin.in"
    }
  },
  "UAT": {
    "API_URLS": {
      "API_CDN_URL": "https://uwinuat-api.co-vin.in",
      "API_URL": "https://uwinuat-api.co-vin.in",
      "API_ADMIN_PORT": "",
      "API_AUTH_PORT": "",
      "API_USER_PORT": "",
      "API_REGISTRATION_PORT": "",
      "API_SESSION_PORT": "",
      "API_REPORTS_PORT": "",
      "APPOINTMENT_PORT": "",
    },
    "APP_URLS": {
      "admin": "https://uwinadminuat.co-vin.in",
      "webApp": "https://uwinvaccinatoruat.co-vin.in",
      "selfReg": "https://uwinselfregistrationuat.co-vin.in",
      "mainPage": "https://uwinuat.co-vin.in"
    }
  },
  "STATIC_IDs_USED": {
    "TD_Vaccine": 101,
    "TD_Booster": 100,
    "Infant_ID": 23,
    "NewBornBaby": 7007,
    "PregnantWomen": 19,
    "LactatingMother": 20,
    "OPV_Vaccine": 103,
    'ASHAROLE': 125,
    'DPT_vaccine': 224,
    'DPT_BOOSTER' : 258,
    "MR": 109,
    "MMR": 234,
    'Pentavalent_Vaccine': 105,
    'DeliveryManagerKey': 'Delivery Point Manager',
    'BothplanningUnitAndDelivery': 'Both(Health Facility & Delivery Manager)',
    'BothVaccinatorAndDeliveryManager': 'Both(Vaccinator & Delivery Manager)',
    'PregnancyMinAgeDays': 4383,
    'PregnancyMaxAgeDays': 17898,
    'GuardianMinAgeDays': 6570,
    'TabResoltion': 768,
    'SESSION_PREGNANT_CATEGORY': ['UIP - Pregnant Women', 'Pregnant Women', 'RI - Pregnant Women']
  },
  "defaultLanguage": "en",
  "supportedLanguages": [
    { "id": "en", "name": "English", "regional": "English" },
    { "id": "hi", "name": "Hindi", "regional": "हिंदी" },
    { "id": "mr", "name": "Marathi", "regional": "मराठी" },
    { "id": "ml", "name": "Malayalam", "regional": "മലയാളം" },
    { "id": "pb", "name": "Punjabi", "regional": "ਪੰਜਾਬੀ" },
    { "id": "te", "name": "Telugu", "regional": "తెలుగు" },
    { "id": "as", "name": "Assamese", "regional": "অসমীয়া" },
    { "id": "ka", "name": "Kannada", "regional": "ಕನ್ನಡ" },
    { "id": "ory", "name": "Odia", "regional": "ଓଡିଆ" },
    { "id": "ta", "name": "Tamil", "regional": "தமிழ்" },
    { "id": "gu", "name": "Gujarati", "regional": "ગુજરાતી" }
  ],
  "remoteIP": 'U2FsdGVkX1+PNBr+u8ublj1UmgGK/NA7qYXnL0wg50Q=',
  "otpTimer": 180,
  "precautionDoseDays": 182,
  "displayDateFormat": 'DD/MM/YYYY',
  'authSecKey': 'U2FsdGVkX19s/E/rwdrMKhzNdaT6LuNyoUvdQuXmJl7Z57naldyKjGoJP2Wze10gmw3M72/t5pIPrITMmBAZyg==',
  'benSecKey': 'U2FsdGVkX18HdWQ1/gucdqpSkSOb57nZhGiwIsEZHoQguLgMnXY0z0d38+CVfuih5tQdZLgRNUNWV5WNOU7fyA==',
  'remoteApiKey': 'U2FsdGVkX1+aRydcSK4OwynpPLIBYngxMChH5DBptRxqNFHHAIS+RH4dkBnHW0N0',
};
