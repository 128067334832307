<ion-grid appScrollbarTheme fullscreen class="aadhar-concen-wrap">
  <ion-row *ngIf="isBottomSheet">
      <ion-col size="12" class="ion-text-end">
          <ion-icon class="custom-close" (click)="dismissModal()"></ion-icon>
      </ion-col>
  </ion-row>

<ion-row class="abhaBody">
  <ion-col size="12" size-md="12" class="ion-no-padding ion-margin-bottom">
    <span class="checkboxSpan">
          <mat-checkbox color="primary" [checked]="isChecked()" [indeterminate]="isIndeterminate()" (change)="$event ? toggleAll($event) : null">{{'Select All' | translate}}​</mat-checkbox>
    </span>
  </ion-col>
  <ion-col size="12" size-md="12" class="ion-no-padding ion-margin-bottom">
    <span class="checkboxSpan">
          <mat-checkbox  color="primary" (click)="$event.stopPropagation()"
          (change)="$event ? toggle(1, $event) : null"
          [checked]="exists(1)">
          {{'I am voluntarily sharing my Aadhaar Number / Virtual ID issued by the Unique Identification Authority of India (“UIDAI”), and my demographic information for the purpose of creating an Ayushman Bharat Health Account number (“ABHA number”) and Ayushman Bharat Health Account address (“ABHA Address”).' | translate}}
          {{'I authorize NHA to use my Aadhaar number / Virtual ID for performing Aadhaar based authentication with UIDAI as per the provisions of the Aadhaar (Targeted Delivery of Financial and other Subsidies, Benefits and Services) Act, 2016 for the aforesaid purpose. I understand that UIDAI will share my e-KYC details, or response of “Yes” with NHA upon successful authentication.' | translate}} 
           
          </mat-checkbox>
    </span>
  </ion-col> 
  <ion-col size="12" size-md="12" class="ion-no-padding ion-margin-bottom">
    <span class="checkboxSpan">
          <mat-checkbox  color="primary" (click)="$event.stopPropagation()"
          (change)="$event ? toggle(2, $event) : null"
          [checked]="exists(2)">
          {{'I intend to create Ayushman Bharat Health Account Number (“ABHA number”) and Ayushman Bharat Health Account address (“ABHA Address”) using document other than Aadhaar. (Click here to proceed further).' | translate}}
            </mat-checkbox>
    </span>
  </ion-col> 
  <ion-col size="12" size-md="12" class="ion-no-padding ion-margin-bottom">
    <span class="checkboxSpan">
          <mat-checkbox color="primary"  (click)="$event.stopPropagation()"
          (change)="$event ? toggle(3, $event) : null"
          [checked]="exists(3)">
          {{'I consent to usage of my ABHA address and ABHA number for linking of my legacy (past) government health records and those which will be generated during this encounter.I authorize the sharing of all my health records with healthcare provider(s)for the purpose of providing healthcare services to me during this encounter.' | translate}}
          </mat-checkbox>
    </span>
  </ion-col> 
  <!-- <ion-col size="12" size-md="12" class="ion-no-padding ion-margin-bottom">
    <span class="checkboxSpan">
          <mat-checkbox color="primary"  (click)="$event.stopPropagation()"
          (change)="$event ? toggle(4, $event) : null"
          [checked]="exists(4)">
          {{'I authorize the sharing of all my health records with healthcare provider(s) for the purpose of providing healthcare services to me during this encounter.' | translate}}
           </mat-checkbox>
    </span>
  </ion-col>  -->
  <ion-col size="12" size-md="12" class="ion-no-padding ion-margin-bottom">
    <span class="checkboxSpan">
          <mat-checkbox  color="primary" (click)="$event.stopPropagation()"
          (change)="$event ? toggle(5, $event) : null"
          [checked]="exists(5)">
          {{'I consent to the anonymization and subsequent use of my government health records for public health purposes.' | translate}} 
           </mat-checkbox>
    </span>
    <ion-row>
      <ion-col size="12" size-md="12" class="ion-margin-top ion-padding-start">
        <span class="checkboxSpan">
          <mat-checkbox color="primary"  (click)="$event.stopPropagation()"
          (change)="$event ? toggle(6, $event) : null"
          [checked]="exists(6)">
           {{'I, (name of healthcare worker- depending on the username used for logging in into the system), confirm that I have duly informed and explained the beneficiary of the contents of consent for aforementioned purposes.'| translate:{healthCareWorker: dataServ?.userDetails?.display_name } }} </mat-checkbox>
        </span>
      </ion-col>
      <ion-col size="12" size-md="12" class="ion-margin-top ion-padding-start">
        <span class="checkboxSpan">
          <mat-checkbox color="primary"  (click)="$event.stopPropagation()"
          (change)="$event ? toggle(7, $event) : null"
          [checked]="exists(7)"> {{ 'I, (beneficiary name), have been explained about the consent as stated above and hereby provide my consent for the aforementioned purposes.' | translate:{beneficiaryName: (benfData?.ben_name ? benfData.ben_name :  benfData.name) } }}
        </mat-checkbox>
        </span>
      </ion-col>
    </ion-row>
  </ion-col> 
</ion-row>
</ion-grid>