import { Optional, Inject, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { switchMap, takeWhile, tap } from 'rxjs/operators';
import { UtilsService } from './../../../../modules/shared/services/utils.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { of, timer } from 'rxjs';
import * as config from 'src/environments/config';
import { AadharService } from '../../services/aadhar.service';
import { AddRecipientService } from 'src/app/screens/add-recipient/services/add-recipient.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AadhaarConcentComponent } from 'src/app/modules/shared/components/aadhaar-concent/aadhaar-concent.component';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-aadhar-otp',
  styleUrls: ['./aadhar-otp.scss'],
  templateUrl: './aadhar-otp.html',
})
export class AadharOtpComponent {
  @Output() gotoStep: EventEmitter<any> = new EventEmitter();
  beneficiaryData: any = {};
  personalDetForm: FormGroup;
  formSubmitted = false;
  verificationInprogress = false;
  otpGenerateInProgress = false;
  isOTPSent = false;
  txnId = null;
  showLoader = false;
  timer: number;
  showResend = false;
  otpForm: FormGroup;
  scannedData = false;
  environment;
  constructor(
    private aadharService: AadharService,
    public addRecipientService: AddRecipientService,
    public util: UtilsService,
    private _bottomSheet: MatBottomSheet,
    private cdr:ChangeDetectorRef,
    private translate: TranslateService
  ){
    this.otpForm = new FormGroup({
      otp: new FormControl('', [Validators.required]),
      consent: new FormControl('', [Validators.required]),
      female_consent: new FormControl(null),
    });
    this.personalDetForm = this.aadharService.buildForm();

  }

  ngOnInit(): void {
    this.environment = environment
    this.addRecipientService.beneficiaryData.subscribe(benf =>{
      this.beneficiaryData = benf;
    });
    this.generateAadhaarOTP();

  }

  startTimer() {
    this.showResend = false;
    let counter = config.ENV.otpTimer;
    this.timer =  config.ENV.otpTimer;
    timer(1000, 1000) //Initial delay 1 seconds and interval countdown also 1 second
      .pipe(
        takeWhile( () => counter > 0 ),
        tap(() => counter--)
      )
      .subscribe( () => {this.timer = counter; 
        this.cdr.detectChanges();
      });
  }

  async generateAadhaarOTP() {
    let aadharNo = this.beneficiaryData.aadhaarNumber;
    this.otpGenerateInProgress = true;
    this.aadharService.sendOtpToRegisteredAadharMobileNo(aadharNo)
    .subscribe(res => {
      let aadharOTP = this.translate.instant('Aadhaar OTP Sent To Registered Mobile Number')
      this.util.presentToastSuccess(aadharOTP);
      if (res && res?.txnId) {
        this.txnId = res?.txnId;
      }
      this.isOTPSent = true;
      this.otpGenerateInProgress = false;
      // this.otpForm.get('mobilenumber').setValue(loginData.mobileNo);
      this.startTimer();
    }, err => {
      this.otpGenerateInProgress = false;
    });
  }
  openBottomSheet() {
    this._bottomSheet.open(AadhaarConcentComponent);
  }
  async verifyOTP() {
    if(this.beneficiaryData!=null && this.beneficiaryData!=undefined){
      if(this.beneficiaryData.ben_type_id == environment.static_ids.PregnantWomen || this.beneficiaryData.ben_type_id == environment.static_ids.LactatingMother){
        this.otpForm.get('female_consent').setValidators([Validators.required]);
      }
      else{
        this.otpForm.get('female_consent').clearValidators();
        this.otpForm.get('female_consent').reset();
      }
    }
    this.otpForm.markAllAsTouched();
    if(!this.otpForm.valid){return;}
    this.verificationInprogress = true;
    const formData = this.otpForm.value;

    this.aadharService.verifyOTP({...formData, ...{txnId: this.txnId}})
    .subscribe(res => {
      // this.scannedData = res;
      // this.aadharService.setDataInFormNewQRDeskTop(res, this.personalDetForm);
      let beneficiaryData = {
        ...this.beneficiaryData,
        ...{
          verifiedBy: 'Aadhar',
          verifiedType: 'otp',
          aadharData: res,
        },
      };
      this.verificationInprogress = false;
      this.addRecipientService.beneficiaryDetails = beneficiaryData;
      this.gotoStep.emit('next');

    }, err => {
      this.verificationInprogress = false;
    });
  }

  continue(){

  }


}
