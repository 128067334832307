<div class="sidebar-header noMobile">
  <h1>
    <ion-icon (click)="gotoStep('prev')" class="custom-arrow-left"></ion-icon>Add Member
    <ion-icon (click)="closeDialog()" class="custom-close"></ion-icon>
  </h1>
</div>
<ion-header class="noDesktop mobile-header-white">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button (click)="gotoStep('prev')" text="" icon="arrow-back-outline"></ion-back-button>
    </ion-buttons>
    <h4 class="heading">Add Member</h4>
  </ion-toolbar>
</ion-header>
<form [formGroup]='vaccDetForm' autocomplete="off" (ngSubmit)='onSubmit()'>
  <div class="sidebar-content inputFields">
    <ion-grid class="progressHeader">
      <ion-row>
        <ion-col size="3">
          <div class="progress--circle progress--100">
            <div class="progress__number">4/4</div>
          </div>
        </ion-col>
        <ion-col size="9">
          <h1>Vaccination Details</h1>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-grid class="memberDetails ion-margin ion-no-padding">
      <ion-row>
        <ion-col class="ion-no-padding">
          <ion-accordion-group>
            <ion-accordion value="first">
              <ion-item slot="header" color="light">
                <h1 class="pageSubTitle">{{allStepsData?.ben_name}}
                <p><ion-text>{{allStepsData?.beneficiary_gender}}</ion-text><ion-text>{{allStepsData?.dob | age}}</ion-text><ion-text>{{allStepsData?.dob}}</ion-text></p>
              </h1>
              </ion-item>
              <div class="ion-padding" slot="content">
                <ion-grid class="memberInfo">
                  <ion-row>
                    <ion-col size="6">
                      <h5>MOBILE</h5>
                      <h3>{{util.mask(allStepsData?.ben_mobile_number)}}</h3>
                    </ion-col>
                    <ion-col size="6" class="ion-text-end">
                      <h5>IDENTIFICATION TYPE</h5>
                      <h3>{{allStepsData?.photo_id_type}} </h3>
                    </ion-col>
                    <ion-col size="6">
                      <h5>IDENTIFICATION NO</h5>
                      <h3>{{util.mask(allStepsData?.photo_id_number)}}</h3>
                    </ion-col>
                    <ion-col size="6" class="ion-text-end" *ngIf="allStepsData?.blood_group">
                      <h5>BLOOD GROUP</h5>
                      <h3>{{allStepsData?.blood_group}}</h3>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </div>
            </ion-accordion>
          </ion-accordion-group>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-grid class="sidebar-inner-content  multi-vaccination">
          <ng-container
          *ngIf="(this.session?.session_type_name == 'UIP' ||allStepsData?.session_type_name == 'UIP') && (villageDetails?.length > 0) && (loadingPrevVaccines || duplicateVaccinesEligible.length > 0)">
          <ion-row>
            <ion-col size-md="12">
              <ion-label>LGD Village</ion-label>
              <mat-form-field appearance="outline" class="ion-margin-vertical matDropdown">
                <mat-select placeholder="Select from the list" formControlName="village_id">
                  <mat-option *ngFor="let village of villageDetails" [value]="village.village_id">{{village.village_name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="healthVillagesDetails?.length > 0">
            <ion-col size-md="12" class="ion-no-padding">
              <ion-label>Health Village</ion-label>
              <mat-form-field appearance="outline" class="ion-margin-vertical matDropdown">
                <mat-select placeholder="Select from the list" formControlName="health_village_id">
                  <mat-option *ngFor="let healthVillage of healthVillagesDetails " [value]="healthVillage.village_id">
                    {{healthVillage.village_name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="wardDetails?.length > 0">
            <ion-col size-md="12" class="ion-no-padding">
              <ion-label>LGD Ward</ion-label>
              <mat-form-field appearance="outline" class="ion-margin-vertical matDropdown">
                <mat-select placeholder="Select from the list" formControlName="ward_id">
                  <mat-option *ngFor="let w of wardDetails " [value]="w.ward_id">
                    {{w.ward_name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="healthWardDetails?.length > 0">
            <ion-col size-md="12" class="ion-no-padding">
              <ion-label>Health Ward</ion-label>
              <mat-form-field appearance="outline" class="ion-margin-vertical matDropdown">
                <mat-select placeholder="Select from the list" formControlName="health_ward_id">
                  <mat-option *ngFor="let hw of healthWardDetails " [value]="hw.add_ward_id">
                    {{hw.add_ward_name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ion-col>
          </ion-row>
        </ng-container>
        <mat-tab-group mat-align-tabs="start" [selectedIndex]="0" class="vaccinationMatGridTab"  (selectedIndexChange)="onTabChange($event)" >
          <mat-tab label="Current Vaccination">
        <ng-container
          *ngIf="!showPreviousVaccination && (this.session?.session_type_name == 'UIP' ||allStepsData?.session_type_name == 'UIP')">
          <ng-container *ngIf="!loadingPrevVaccines && duplicateVaccinesEligible.length == 0">
            <ion-row class="ion-margin-vertical">
              <ion-col>
                <img src="assets/images/aefireport.svg" class="verified-logo">
                <p class="ion-text-center">Vaccines are not available for this member</p>
              </ion-col>
            </ion-row>
          </ng-container>
          <ion-grid *ngIf="duplicateVaccinesEligible.length > 0" class="vaccines-grid currentVaccinationBg">
            <ng-container *ngFor="let material of duplicateVaccinesEligible;let i = index;">
              <ion-row class="vaccine-names">
                <ion-col size="12" size-lg="12">
                  <mat-checkbox [ngClass]="{'active': material.checked}" (click)="showVaccineInfoByBenf(material)"
                    [disabled]="isFullyVaccinatedByMaterial(material)">{{material.material_name}}</mat-checkbox>
                </ion-col>
              </ion-row>
              <!-- <ion-row class="vaccine-names">
                            <ion-col size="12" size-lg="12">
                                <mat-checkbox [hidden]="true" class="example-margin" [value]="material.material_name" (change)="onMaterialCheckSelection(material,$event)">{{material.material_name}}
                                </mat-checkbox>
                                <ion-chip #c [ngClass]="{'active': material.checked}" [disabled]="isFullyVaccinatedByMaterial(material)" (click)="showVaccineInfoByBenf(material)">
                                    <ion-icon name="checkmark-circle"></ion-icon>
                                    {{material.material_name}}
                                </ion-chip>
                            </ion-col>
                        </ion-row> -->
              <ion-row class="fade-in" *ngIf="material.no_of_doses > 1 && material.checked == true">
                <ion-col size="12" size-lg="12">
                  <ion-card class="detailsCard">
                    <mat-radio-group id="mat{{material.material_id}}" name="mat{{material.material_id}}">
                      <ng-container *ngFor="let d of material.dose_array">
                        <ng-container *ngIf="isDoseEligibleForMinAge(material,d)">
                          <ng-container *ngIf="!d.doseReceivedDate && !d.isVaccinatedOutside && !isDoseEligibleForCurrentAge(material,d)">
                            <!-- Due -->
                            <ion-item class="due">
                              <!-- <ion-label>{{d.material_id == environment.static_ids.OPV_Vaccine ? 'OPV' : 'Dose'}}
                                {{d.material_id == environment.static_ids.OPV_Vaccine ? d.dose - 1 : d.dose }}</ion-label> -->
                                <ion-label>{{labelName(d)}}</ion-label>
                              <ion-note slot="end">Not Given</ion-note>
                            </ion-item>
                            <!-- Due -->
                          </ng-container>
                        <ng-container *ngIf="!d.doseReceivedDate && !d.isVaccinatedOutside && isDoseEligibleForCurrentAge(material,d)">
                          <ng-container *ngIf="d.due_date">
                            <!-- Due -->
                            <ion-item class="due">
                              <!-- <ion-label>{{d.material_id == environment.static_ids.OPV_Vaccine ? 'OPV' : 'Dose'}}
                                {{d.material_id == environment.static_ids.OPV_Vaccine ? d.dose - 1 :
                                d.dose }}</ion-label> -->
                                <ion-label>{{labelName(d)}}</ion-label>
                              <ion-note slot="end">{{d.due_date}}</ion-note>
                            </ion-item>
                            <!-- Due -->
                          </ng-container>
                          <ng-container *ngIf="d.isEligible">
                            <!-- eligible -->
                            <ion-item class="pending">
                              <!-- <mat-radio-button class="example-margin" (change)="onselectedDoseEvent(material,$event)"
                                [value]="d.dose">{{d.material_id == environment.static_ids.OPV_Vaccine ? 'OPV' : 'Dose'}}
                                {{d.material_id == environment.static_ids.OPV_Vaccine ? d.dose
                                - 1 : d.dose }}
                              </mat-radio-button> -->
                              <mat-radio-button class="example-margin" (change)="onselectedDoseEvent(material,d,$event)"
                                [value]="d.dose">
                                {{labelName(d)}}
                              </mat-radio-button>
                              <ion-note slot="end">{{'Eligible'}}</ion-note>
                            </ion-item>
                            <!-- eligible -->
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="d.doseReceivedDate || d.isVaccinatedOutside">
                          <!-- completed -->
                          <ion-item class="completed">
                            <!-- <ion-label>{{d.material_id == environment.static_ids.OPV_Vaccine ? 'OPV' : 'Dose'}}
                              {{d.material_id == environment.static_ids.OPV_Vaccine ? d.dose - 1 :
                              d.dose }}</ion-label> -->
                              <ion-label>{{labelName(d)}}</ion-label>
                            <ion-note *ngIf="d.doseReceivedDate" slot="end">{{d.doseReceivedDate | date : 'mediumDate'}}
                            </ion-note>
                            <ion-note *ngIf="!d.doseReceivedDate" slot="end">{{'N/A'}}</ion-note>
                          </ion-item>
                          <!-- completed -->
                        </ng-container>
                        </ng-container>
                      </ng-container>
                    </mat-radio-group>
                  </ion-card>
                </ion-col>
              </ion-row>
  
            </ng-container>
          </ion-grid>
  
  
        </ng-container>
        </mat-tab>
        <mat-tab label="Previous Vaccination">
          <ng-container
          *ngIf="showPreviousVaccination &&  (this.session?.session_type_name == 'UIP'  ||allStepsData?.session_type_name == 'UIP')">
          <ng-container *ngIf="!loadingPrevVaccines && prevVaccinesByAge.length == 0">
            <ion-row class="ion-margin-vertical">
              <ion-col>
                <img src="assets/images/aefireport.svg" class="verified-logo">
                <h1 class="pageTitle"> Previous Vaccines are not available for this member</h1>
                <!-- <ion-button class="primaryBtn" expand="full" (click)="loadCurrentVaccines()">Go Back</ion-button> -->
                <!-- <h3>Please .</h3> -->
              </ion-col>
            </ion-row>
          </ng-container>
          <ion-grid *ngIf="prevVaccinesByAge.length > 0" class="vaccines-grid previousVaccinationBg">
            <ng-container *ngFor="let material of prevVaccinesByAge;let i = index;">
              <ion-row class="vaccine-names">
                <ion-col size="12" size-lg="12">
                  <!-- <mat-radio-group (change)="showVaccineInfoByBenf(material,i,$event)">
                                      <mat-radio-button  value="material.material_name">{{material.material_name}} </mat-radio-button>
                                  </mat-radio-group> -->
                  <mat-checkbox (change)="showVaccineInfoByBenf(material)" [ngClass]="{'active': material.checked}"
                    [disabled]="isFullyVaccinatedByMaterial(material)" class="example-margin">{{material.material_name}}
                  </mat-checkbox>
  
                </ion-col>
              </ion-row>
              <ion-row class="fade-in" *ngIf="material.no_of_doses > 1 && material.checked == true">
                <ion-col size="12" size-lg="12">
                  <mat-radio-group id="mat{{material.material_id}}" name="mat{{material.material_id}}">
                    <ng-container *ngFor="let d of material.dose_array">
                      <!-- <ng-container *ngIf="isDoseEligibleForMinAge(material,d)"> -->
                      <ion-card class="detailsCard">
                        <ng-container *ngIf="!d.doseReceivedDate && !d.isVaccinatedOutside">
                          <!-- <ng-container *ngIf="d.due_date"> -->
                          <!-- Due -->
                          <!-- <ion-item class="due">
                              <ion-label>Dose {{d.dose}}</ion-label>
                              <ion-note slot="end">{{d.due_date}}</ion-note>
                            </ion-item> -->
                          <!-- Due -->
                          <!-- </ng-container> -->
                          <!-- <ng-container *ngIf="d.isEligible"> -->
                          <!-- eligible -->
                          <!-- <ion-item class="pending"> -->
                            <!-- <mat-checkbox class="example-margin" (change)="onSelectByDose(material,d.dose,$event)"
                              [value]="d.dose">{{d.material_id == environment.static_ids.OPV_Vaccine ? 'OPV' : 'Dose'}}
                              {{d.material_id == environment.static_ids.OPV_Vaccine ? d.dose -
                              1 : d.dose }}
                            </mat-checkbox> -->
                            <!-- <mat-checkbox class="example-margin" 
                            [checked]="isDoseChecked(material, d)"
                            (change)="onSelectByDose(material,d,$event)"
                            [disabled]="!isDoseEligibleForMinAge(material,d) || isLastDoseChecked(material, d) || isDoseEligibleByLastDose(material, d)"  
                            [value]="d.dose">{{labelName(d)}}
                            </mat-checkbox>
                            <ion-note slot="end">{{''}}</ion-note>
                          </ion-item> -->
                          <ion-row class="dose-labels">
                            <ion-col size="5">
                              <mat-checkbox class="example-margin" 
                              [checked]="isDoseChecked(material, d)"
                              (change)="onSelectByDose(material,d,$event)"
                              [disabled]="!isDoseEligibleForMinAge(material,d) || isLastDoseChecked(material, d) || isDoseEligibleByLastDose(material, d)"   
                                [value]="d.dose">{{labelName(d)}}
                              </mat-checkbox>
                            </ion-col>
                            <ion-col size="7" *ngIf="isDoseChecked(material, d)">
                                <mat-form-field appearance="outline" class="matDropdown">
                                <input matInput readonly [matDatepicker]="dp" [max]="getMaxDoseDate(material,d)"
                                  [min]="getMinDoseDate(material, d)"  [disabled]="!isDoseEligibleForMinAge(material,d) || isLastDoseChecked(material, d) || isDoseEligibleByLastDose(material, d)"
                                  (dateChange)="onSelectByDoseDate(material, d, $event)">
                                <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                                <mat-datepicker #dp></mat-datepicker>
                              </mat-form-field>
                            </ion-col>
                          </ion-row>
                          <!-- <ion-row *ngIf="isDoseChecked(material, d)">
                            <ion-col size="12" class="ion-padding-horizontal">
                              <ion-label>Dose Date</ion-label>
                              <mat-form-field appearance="outline" class="matDropdown ion-margin-vertical">
                                <input readonly matInput [matDatepicker]="dp" [max]="getMaxDoseDate(material,d)"
                                  [min]="getMinDoseDate(material, d)"
                                  (dateChange)="onSelectByDoseDate(material, d, $event)">
                                <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                                <mat-datepicker #dp></mat-datepicker>
                              </mat-form-field>
                            </ion-col>
                          </ion-row> -->
                          <!-- eligible -->
                          <!-- </ng-container> -->
                        </ng-container>
                        <ng-container *ngIf="d.doseReceivedDate || d.isVaccinatedOutside">
                          <!-- completed -->
                          <ion-item class="completed">
                            <!-- <ion-label>{{d.material_id == environment.static_ids.OPV_Vaccine ? 'OPV' : 'Dose'}}
                              {{d.material_id == environment.static_ids.OPV_Vaccine ? d.dose - 1 :
                              d.dose }}</ion-label> -->
                              <ion-label>
                                {{labelName(d)}}
                              </ion-label>
                            <ion-note *ngIf="d.doseReceivedDate" slot="end">{{d.doseReceivedDate | date : 'mediumDate'}}
                            </ion-note>
                            <ion-note *ngIf="!d.doseReceivedDate" slot="end">{{'N/A'}}</ion-note>
                          </ion-item>
                          <!-- completed -->
                        </ng-container>
                      </ion-card>
                      <!-- </ng-container> -->
                    </ng-container>
                  </mat-radio-group>
                </ion-col>
              </ion-row>
              <ion-row class="ion-margin-vertical" *ngIf="material.no_of_doses == 1 && material.checked == true">
                <ion-col>
                  <ion-label>Dose Date</ion-label>
                  <mat-form-field appearance="outline" class="matDropdown ion-margin-vertical">
                    <input matInput readonly [matDatepicker]="dp" [max]="getMaxDoseDate(material,0)"
                      [min]="getMinDoseDate(material, 0)" (dateChange)="onselectedDoseDateEvent(material, $event)">
                    <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                    <mat-datepicker #dp></mat-datepicker>
                  </mat-form-field>
                </ion-col>
              </ion-row>
            </ng-container>
          </ion-grid>
          <!-- <ion-button class="primaryBtn" expand="full" (click)="loadCurrentVaccines()">Go Back</ion-button> -->
        </ng-container>
        </mat-tab>
      </mat-tab-group>
      <!-- <ion-row>
        <ion-col size="12">
          <h3 class="pageSubTitle ion-no-margin">{{!showPreviousVaccination ? 'Current Vaccination' : 'Previous Vaccination'}}</h3>
        </ion-col>
      </ion-row> -->
    
      <!--new design for previous vaccinations-->
    


      <ion-row *ngIf="sessionCapacity">
        <ion-label class="error-validation">
          <p>On-spot appointment capacity of selected dose for this session is full</p>
        </ion-label>
      </ion-row>
      <ion-row
        *ngIf="(allStepsData?.session_id || this.session?.session_id) &&
            ((allStepsData?.session_type_name == 'UIP' || this.session?.session_type_name == 'UIP' )  && !showPreviousVaccination)">
        <ion-col size-md="12">
          <!-- <ion-button *ngIf="showPrevVaccBtn" color="medium" class="linkBtn" expand="full" fill="outline" (click)="addPrevVaccination()">Add
            Previous Vaccination
          </ion-button> -->
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size-md="12" class="ion-text-end">
          <ion-button
            [disabled]="(duplicateVaccinesEligible.length == 0 && !showPreviousVaccination && (allStepsData?.session_type_name == 'UIP' || this.session?.session_type_name == 'UIP')) ||
                (prevVaccinesByAge.length == 0 && showPreviousVaccination &&   (allStepsData?.session_type_name == 'UIP' || this.session?.session_type_name == 'UIP'))|| (isMaterialDueDateAvailable && (allStepsData?.session_type_name == 'UIP' || this.session?.session_type_name == 'UIP'))"
            class="primaryBtn" expand="full" shape="round" type="submit">{{this.allStepsData?.isBenfAddedToSession ?
            'Continue' :'Continue'}}
            <ion-spinner *ngIf="Inprogress" name="lines-small"></ion-spinner>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
  <!-- <div class="sidebar-footer">
        <ion-row *ngIf="(allStepsData?.session_id || this.session?.session_id) &&
    ((allStepsData?.session_type_name == 'UIP' || this.session?.session_type_name == 'UIP' )  && !showPreviousVaccination)">
            <ion-col size-md="12">
                <ion-button color="medium" class="linkBtn" expand="full" fill="outline" (click)="addPrevVaccination()">Add Previous Vaccination
                </ion-button>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col size-md="12" class="ion-text-end">
                <ion-button [disabled]="(duplicateVaccinesEligible.length == 0 && !showPreviousVaccination &&  this.session?.session_type_name == 'UIP') ||
        (prevVaccinesByAge.length == 0 && showPreviousVaccination &&  this.session?.session_type_name == 'UIP')" class="primaryBtn" expand="full" shape="round" type="submit">{{this.allStepsData?.isBenfAddedToSession ? 'Continue' :'Add To Session'}}
                    <ion-spinner *ngIf="Inprogress" name="lines-small"></ion-spinner>
                </ion-button>
            </ion-col>
        </ion-row>
    </div> -->
</form>