import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';


export function validatorsID(inputCharVal): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const pancard = /^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/;
    const drivingLicence = /^(([A-Za-z]{2}[0-9]{2})()|([A-Za-z]{2}[0-9]{2}))((19|20)[0-9][0-9])[0-9]{6,7}$/;;
    const passportOrPassbook = /^[A-Za-z0-9]*$/;
    // const passbook = /^[A-Z0-9]*$/;
    const nprSmartCardOrvoterId = /^[ A-Za-z0-9/.,\-()]*$/;
    // const voterId = /^[ A-Za-z0-9/.,\-()]*$/;
    const uniqueDisability = /^[A-Za-z]{2}[0-9]{16}$/;
    const alphaNumRegex = /^[ A-Za-z0-9]*$/;


    if (control && control.value) {
      let validate
      if (inputCharVal === 'PAN Card') {
        validate = pancard.test(control.value)
        
      } else if (inputCharVal === 'Driving License') {
        validate = drivingLicence.test(control.value);
      } else if (inputCharVal === 'Passport' || inputCharVal === 'Pension Passbook') {
        validate = passportOrPassbook.test(control.value)
      } 
       else if (inputCharVal === 'NPR Smart Card' || 'Voter ID' ) {
        validate = nprSmartCardOrvoterId.test(control.value)
      } 
      else if (inputCharVal === 'Unique Disability ID (Unique ID for Persons with Disabilities)') {
        validate = uniqueDisability.test(control.value)
      } else if (inputCharVal === 'Ration Card with Photo') {
        validate = alphaNumRegex.test(control.value)
      }
      else {
        validate = false;
      }
      return !validate ? { validatorsID: { value: control.value } } : null
    }
  }
}

