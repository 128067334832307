import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { ROUTE_ANIMATIONS_ELEMENTS } from 'src/app/modules/core/services/route.animations';
import * as _ from 'lodash';
import { LookUpService } from 'src/app/modules/shared/services/look-up.service';
import * as moment from 'moment';
import { DownloadFileService } from 'src/app/modules/shared/services/download-file.service';
import { BeneficiaryService } from 'src/app/screens/beneficiary/services/beneficiary.service';
import { AddRecipientService } from 'src/app/screens/add-recipient/services/add-recipient.service';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { RecipientService } from 'src/app/screens/recipient/services/recipient.service';
import { UtilsService } from '../../services/utils.service';
import { environment } from 'src/environments/environment';
import { PregnancyService } from 'src/app/screens/beneficiary/pregnancy/services/pregnancy.service';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { getUserDetails } from 'src/app/modules/core/store/selectors/user.selectors';
import { MatDialog } from '@angular/material/dialog';
import { LinkHipCareContextComponent } from 'src/app/screens/beneficiary/components/link-hip-care-context/link-hip-care-context.component';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-beneficiary-uip-vaccination-details',
  templateUrl: './uip-vaccination-details.component.html',
  styleUrls: ['./uip-vaccination-details.component.scss'],
})
export class UipVaccinationDetailsComponent implements OnInit {
  env= environment;
  @Input() pregNumber = null;
  @Input() recordPrev = false;
  @Input() lmcDate = null;
  @Input() materialType: string = 'COVID-19';
  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
  @Input() device = 'web';
  @Input() isDataLoading;
  @Input() benfSource = [
    { 'material_name': 'COVAXIN', 'dose': 1, 'date': new Date(), 'session_site_name': 'test site', 'vaccination_status': 'complete' },
    { 'material_name': 'COVAXIN', 'dose': 1, 'date': new Date(), 'session_site_name': 'test site', 'vaccination_status': 'Reject' }];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() options: any = {};
  declareDeath = false;
  // pageSize = 10;
  // currentPage = 1;
  mbViewData: any = [];
  currentTab = 0;
  genderList;
  dataSourceList = new MatTableDataSource<any>([]);
  vaccinationData: any = [];
  materialArr: any;
  fileDownloadInProgress = false;
  showRecordPreviousVaccination: boolean = false;
  PreviouslyTookvaccines: any = [];
  vaccinationDetailsForm: FormGroup;
  maxDoseDate = new Date();
  minDoseDate = new Date();
  lmcDatefromVaccinationChart: any;
  medicalCondtionData: any;
  userRole:any
  isVaccinatedToday: boolean = false
  columnsToDisplay = [
    { fieldName: 'Age', fieldId: 'displayAge', fieldType: 'displayAge', enableSort: true },
    { fieldName: 'Vaccine Name', fieldId: 'Vaccine', fieldType: 'string', enableSort: false },
    { fieldName: 'Due Date', fieldId: 'due_date', fieldType: 'string', enableSort: true },
    { fieldName: 'Vaccination Date', fieldId: 'vaccination_date', fieldType: 'string', enableSort: true },
    { fieldName: 'Status', fieldId: 'msg', fieldType: 'vaccination_status', enableSort: false },
  ];
  displayedColumns: string[] = ['displayAge', 'Vaccine', 'due_date', 'vaccination_date', 'msg'];

  benDataSubscribe: Subscription;
  careContext: any = null;
  contextSubscription: Observable<boolean>
  benData: any = {};
  prevBenData: any = {};
  linkStatus: boolean

  constructor(
    private benficiaryService: BeneficiaryService,
    private lookup: LookUpService,
    private cdr: ChangeDetectorRef,
    private downloadFile: DownloadFileService,
    private dialog: MatDialog,
    private fb: FormBuilder, private recipientService: RecipientService,
    public util: UtilsService, public pregnanacyService: PregnancyService,
    public router: Router,
    private store$: Store,
    private modalController: ModalController,
  ) { }

  async ngOnInit() {
    this.vaccinationDetailsForm = new FormGroup({
      materialDetails: this.fb.array([])
    })
    this.isDataLoading = false;
    
    this.benDataSubscribe = this.benficiaryService.beneficiaryData.subscribe(r => {
      this.benData = r;
      if (this.prevBenData.lmcDate && (this.benData.is_pregnant_woman && this.benData?.lmc_date != this.prevBenData.lmcDate)) {
        this.minDoseDate = new Date(moment(this.benData?.lmc_date).format('YYYY-MM-DD'))
        this.pregnantDisplayedColumns();
      }
      this.prevBenData = r;
    });

    const materialArr = await this.lookup.getMaterials().toPromise();
    this.materialArr = this.lookup.getMaterialsByType(materialArr, this.materialType);

    this.loadVaccinationCard();

    this.benficiaryService.declaredDeathReplaySubject.subscribe(r => {
      if (r) {
        this.isDataLoading = false;
        this.displayedColumns = []
        this.vaccinationData = []
        this.declareDeath = true;
        this.cdr.detectChanges();
      }
    })
    this.pregnanacyService.medicalCondtionData.subscribe(res => {
      this.medicalCondtionData = res;
    })
    this.store$.pipe(select(getUserDetails)).subscribe(userInfo => {
      this.userRole = userInfo?.role;
    });
    this.reloadCareContext();
    this.benficiaryService.loadVaccinationchart.subscribe(r => {
      if(r == 'vaccination-card') {
        this.loadVaccinationCard();
        this.cdr.detectChanges()
      }
    })
  }

  isPregnancyOutcomeDone() {
    var isFromNewBornPage:boolean = false;
    this.pregnanacyService.isFromNewBornPage.subscribe(res=>{
      isFromNewBornPage = !!res;
    })
    if(isFromNewBornPage) return false;
    if (this.medicalCondtionData?.pregnancyOutcomeDetails && Object.keys(this.medicalCondtionData.pregnancyOutcomeDetails).length > 0 ) {
      return true
    }
    return false
  }
  isCurrentVaccinationTaken(){
    let value = this.vaccinationData.findIndex(ele => !ele.is_past_dated && ele.vaccination_date != "")
    return value != -1  ? true : false
  }
  pregnantDisplayedColumns() {
    this.displayedColumns = ['Vaccine', 'due_date', 'vaccination_date', 'msg'];
    this.loadVaccinationCard();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if ((this.benData.is_pregnant_woman && changes.lmcDate?.currentValue) || (changes.lmcDate?.currentValue)) {
      this.loadVaccinationCard();
      // this.pregnantDisplayedColumns()
    }
    if ((!this.benData.is_pregnant_woman && this.benData?.pregnancy_number)) {
      this.loadVaccinationCard();
    }
  }

  loadVaccinationCard() {
    this.isDataLoading = true;
    this.benficiaryService.getVaccinationChart(this.benData.beneficiary_reference_id, (this.benData.lmc_date || this.lmcDate), (!this.benData.is_pregnant_woman ? this.pregNumber : undefined))
      .subscribe((r: any) => {
        if (r.length == 0) {
          this.vaccinationData = [];
          this.dataSourceList.data = [];
          this.cdr.detectChanges();
        }
        if (r && r?.vaccinationDetails) {

          let data = r;
          this.lmcDatefromVaccinationChart = r?.lmc_date;
          this.vaccinationData = r?.vaccinationDetails.map(e => {
            e.vaccination_status = e.status;
            return e;
          });
          this.dataSourceList = new MatTableDataSource(this.vaccinationData);
          this.dataSourceList.data = this.vaccinationData;
          this.cdr.detectChanges();
        }

        this.isDataLoading = false;

      });
  }

  loadVaccinationData() {
    this.isDataLoading = true;
    this.benficiaryService.getVaccinationDetailsOfBeneficiary(this.benData.beneficiary_id, this.materialType)
      .subscribe(r => {
        let data = r;
        this.isDataLoading = false;
        let vaccinationData = data.filter(this.filterSuccessAndRejected).map(e => {
          let rejRegex = new RegExp('VACCINATION_UNSUCCESSFUL');
          e.vaccination_status = rejRegex.test(e.key) ? 'rejected' : 'Vaccinated';
          return e;
        });
        vaccinationData = _.orderBy(vaccinationData, ['vaccination_date'], ['desc']);
        const grpdVaccines = _.groupBy(vaccinationData, 'material_name');

        Object.keys(grpdVaccines).forEach(element => {
          const nxtDoseData = this.checkeligibleForDose(grpdVaccines[element]);
          if (nxtDoseData) {
            this.vaccinationData = [...this.vaccinationData, ...nxtDoseData, ...grpdVaccines[element]];
          }
          else { this.vaccinationData = [...this.vaccinationData, ...grpdVaccines[element]]; }
        });
        this.dataSourceList = new MatTableDataSource(this.vaccinationData);
        this.dataSourceList.data = this.vaccinationData;
        this.cdr.detectChanges();

      }, e => {
        this.isDataLoading = false;
      });
  }

  checkeligibleForDose(elementArr) {
    const lastVaccinatedDetails = _.maxBy(elementArr, 'vaccination_date');
    return this.doseDuration(lastVaccinatedDetails);
  }
  isNewBornChild() {
    const currentDate = moment().format('DD-MMM-YYYY')
    if (this.benData?.dob == currentDate) {
      return true
    }
    return false
  }

  calAgeByDob() {
    return Math.round(moment().diff(moment(this.benData.dob), 'years', true));
  }


  materialIfoByMaterialName(name) {
    return this.materialArr.find(e => e.material_name.toUpperCase() == name.toUpperCase());
  }

  doseDuration(doseInfo) {
    const materialDoseInfo = this.materialIfoByMaterialName(doseInfo?.material_name);
    if (materialDoseInfo) {
      if (materialDoseInfo && doseInfo.material_name && materialDoseInfo.no_of_doses <= doseInfo.dose) { return null; } // commented for time being
      let dose = 1;
      if (doseInfo.key == 'VACCINATED') {
        dose = doseInfo.dose;
      } else {
        dose = parseInt(doseInfo.key.replace('VACCINATION_SUCCESSFUL_DOSE_', ''));
      }
      let nextDose = (dose) + 1;
      const dayDuration = materialDoseInfo.dose_frequencies[dose];
      const lastVaccinatedDate = moment(doseInfo.vaccination_date).startOf('day');
      const dayDiffOfLastVaccine = moment().diff(lastVaccinatedDate, 'days');
      const nxtdoseDate = lastVaccinatedDate.add(dayDuration, 'days');
      if (dayDuration > dayDiffOfLastVaccine) {
        let daysLeftForVaccination = dayDuration - dayDiffOfLastVaccine;
        return [{ 'material_name': doseInfo.material_name, dose: nextDose, vaccination_date: nxtdoseDate, venue: '', vaccination_status: 'Upcoming' }];
      } else {
        return [{ 'material_name': doseInfo.material_name, dose: nextDose, vaccination_date: nxtdoseDate, venue: '', vaccination_status: 'Pending' }];
      }
    }
  }



  filterSuccessAndRejected(arr) {
    let succRegex = new RegExp('VACCINATION_SUCCESSFUL');
    let succRegexothr = new RegExp('VACCINATED');
    // let rejRegex = new RegExp('VACCINATION_UNSUCCESSFUL');
    return succRegex.test(arr.key) || succRegexothr.test(arr.key); // || rejRegex.test(arr.key);
  }


  onTabChange(event) {
  }

  ngOnDestroy(): void {
    // this.benDataSubscribe.unsubscribe();
  }
  downloadCertificate() {
    if (!this.benData?.beneficiary_reference_id) { return; }
    this.fileDownloadInProgress = true;
    const payload = {
      beneficiary_reference_id: '' + this.benData.beneficiary_reference_id,
      // vaccination_status: this.benData.vaccination_status,
      beneficiary_name: this.benData.ben_name,
      lmcDate: this.lmcDate
    }

    this.downloadFile.downloadUIPVaccinationCertificate(payload)
      .subscribe(r => {
        this.fileDownloadInProgress = false;
        this.cdr.detectChanges();
      }, er => {
        this.fileDownloadInProgress = false;
        this.cdr.detectChanges();
      });
  }
  recordPreviousVaccination() {
    for (let i = 0; i < this.vaccinationData.length; i++) {
      this.vaccinationData[i]['checked'] = false
    }
    // this.showRecordPreviousVaccination = true;
    let params = {
      beneficiary_id: this.benData.beneficiary_id,
    }

    this.PreviouslyTookvaccines = this.vaccinationData.filter(v => !v.vaccination_date || v.vaccination_date == 'N/A');
    this.cdr.detectChanges();
  }
  isCurrentVaccinationDone(){ 
    return this.vaccinationData.find(v=>!v.is_past_dated && v.status == 'completed');
  }
  recordPreviousVaccinationExistingBen() {
    if(!this.isCurrentVaccinationDone()){ 
      this.router.navigate([`beneficiary/${this.benData.beneficiary_id}/update-beneficiary-vaccination`]);
    }else{
      this.router.navigate([`beneficiary/${this.benData.beneficiary_id}/record-previous-vaccination`]);
    }
  }

  isTDVaccineCompleted(ele) {
    const currentDate = moment()
    if (ele.material_id == environment.static_ids.TD_Booster || ele.material_id == environment.static_ids.TD_Vaccine) { // && this.verifiedData?.is_pregnant_woman
      const previousTdDate = moment(ele.vaccination_date);
      // if(this.verifiedData.previous_pregnancy_year){
      // const previousTdDate = moment(this.verifiedData.previous_pregnancy_year)
      const diff = currentDate.diff(previousTdDate, 'years')
      return diff < 3
      // }
    }
    return true;
  }
  showVaccineInfoByBenf(material) {
    if (!material.checked) { material.checked = true }
    else { material.checked = false; }
    this.getMaterialsChecked(material, { checked: material.checked })
  }
  getMaterialsChecked(material, event?) {
    const formArray: FormArray = this.vaccinationDetailsForm.get('materialDetails') as FormArray;
    if (event.checked) {
      material['checked'] = true;
      formArray.push(new FormControl({
        material_id: material.material_id,
        material_name: material?.material_name,
        material_dose: material.dose_interval_id,
        prev_vaccination_date: undefined
      }));
    } else {
      let i: number = 0;
      const indexes = formArray.controls.map((ctrl: FormControl, index) => {
        if (ctrl.value.material_id == material.material_id && ctrl.value.material_dose == material.dose_interval_id) {
          return index;
        }
      }).filter(element => element >= 0);
      for (let i = indexes.length - 1; i >= 0; i--) {
        formArray.removeAt(indexes[i]);
      }
      material['checked'] = false;
      return
    }
  }
  isSaveEnabled() {
    const matDetails = this.vaccinationDetailsForm.get('materialDetails').value;
    // return !matDetails.find(e=>e.prev_vaccination_date);
    return matDetails.length == 0
  }
  onselectedDoseDateEvent(material, event) {
    this.vaccinationDetailsForm.get('materialDetails').value.forEach(element => {
      if (element.material_id == material.material_id && element.material_dose == material.dose_interval_id) {
        element['prev_vaccination_date'] = moment(event.value).format('YYYY-MM-DD');
      }
    });
  }
  updatePreviousVaccination() {
    const matDetails = this.vaccinationDetailsForm.get('materialDetails').value;
    let isError = false;
    matDetails.forEach(element => {

      if (element.prev_vaccination_date == moment().format('YYYY-MM-DD')) {
        element['vaccination_date'] = element.prev_vaccination_date;
        delete element.prev_vaccination_date
        this.isVaccinatedToday = true;
      }
      else {
        this.isVaccinatedToday = false

      }
    });
    const payload = {
      "vaccination_status": 1,
      // "session_id":"6ea26723-8a59-47ee-a665-c16e43a8e1f4",
      // "session_site_id":64, // no session so not getting data
      // "session_state_id":2,
      // "session_district_id":7,
      "is_past_dated": this.isVaccinatedToday ? false : true,
      "materialDetails": matDetails,
      // "village_id":82, //village exists for session
      "beneficiaryId": this.benData.beneficiary_id,
      "stateID": this.benData.state_id,
    };
    // if(!isError){
    this.recipientService.updateMultiUipSessionVaccination(payload).subscribe(res => {
      this.util.presentToastSuccess("Updated Successfully")
      this.showRecordPreviousVaccination = false
      this.loadVaccinationCard();
      this.clearMaterialFormCtrlArr()
    })
    // }
  }
  clearMaterialFormCtrlArr() {
    const formArray = this.vaccinationDetailsForm.get('materialDetails') as FormArray
    while (formArray.length !== 0) {
      formArray.removeAt(0)
    }
    this.recordPreviousVaccination();
    this.cdr.detectChanges();

  }
  onBack() {
    this.showRecordPreviousVaccination = false;
    this.clearMaterialFormCtrlArr()
  }
  getMinDoseDate(material, dose) {
    var selectedDose1Date;

    this.vaccinationDetailsForm.get('materialDetails').value.forEach(element => {
      if (element.material_dose == 1 && element['prev_vaccination_date'] != undefined) {
        selectedDose1Date = new Date(moment(element['prev_vaccination_date']).format('YYYY-MM-DD'));
      }
    })
    if (dose == material.dose_interval_id) {
      let currentDate = new Date();
      if (selectedDose1Date && dose > 1) {
        return moment(selectedDose1Date).add(material.dose_frequency, 'days').format('YYYY-MM-DD')
      }
      else if (material.due_date) {
        return moment(material.due_date_iso).format('YYYY-MM-DD') //add(material.dose_frequency,'days')
      }
      else {
        return this.minDoseDate;
      }
    }
  }
  getMaxDoseDate() {
    let eddDate = new Date(moment(this.lmcDatefromVaccinationChart).add(9, 'months').add(7, 'days').format('YYYY-MM-DD'));
    if (this.benData?.date_of_abortion && !this.benData?.is_pregnant_woman) {
      eddDate = new Date(moment(this.benData?.date_of_abortion).format('YYYY-MM-DD'))
    }
    else if (this.benData?.date_of_delivery && !this.benData?.is_pregnant_woman) {
      eddDate = new Date(moment(this.benData?.date_of_delivery).format('YYYY-MM-DD'))
    }
    if (eddDate < this.maxDoseDate) {
      return eddDate
    } else {
      // return moment(this.maxDoseDate).subtract(1,'days').format('YYYY-MM-DD')
      return moment(this.maxDoseDate).format('YYYY-MM-DD')
    }
  }
  formatVaccinationDate(value: string) {
    return value.replace(/<sup>\*<\/sup>/g, '');
  }


  async linkCareContext() {
    if (this.util.getPlatformWidth() > 575) {
      const dialogRef = this.dialog.open(LinkHipCareContextComponent, {
        height: "auto",
        width: "500px",
        hasBackdrop: true,
        disableClose: false,
        data: { ben_data: this.benData, isFromBenPage: true },
      })
      dialogRef.afterClosed().subscribe(result => {
        this.reloadCareContext();
      });
    } else {
      const modal = await this.modalController.create({
        component: LinkHipCareContextComponent,
        cssClass: 'create-abha-modal',
        componentProps: {
          isFromBenPage: true,
          benData: this.benData,

        }
      });
      await modal.present();
      const { data } = await modal.onWillDismiss();
      if(data){
        this.reloadCareContext();
      }
    }
  }

  reloadCareContext(){
    this.careContext = null;
    this.benficiaryService.benVaccineLinkStatus(this.benData.beneficiary_reference_id)
    .subscribe(r=>{
      this.careContext = r;
      this.cdr.detectChanges();
    })
  }

}
