import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './modules/core/guards/auth.guard';
import { IsAuthenticatedGuard } from './modules/core/guards/is-authenticated';
import { UserResolverService } from './modules/core/services/user-resolver.service';
import { ForbiddenComponent } from './screens/forbidden/forbidden.component';
import { NotFoundComponent } from './screens/not-found/not-found.component';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./screens/auth/auth.module').then( m => m.AuthPageModule),
    canActivate: [IsAuthenticatedGuard],
  },
  {
    path: '',
    loadChildren: () => import('./screens/home/home.module').then( m => m.HomePageModule),
    canActivate: [AuthGuard],
    resolve: {
      user: UserResolverService
    }
  },
  // { path: 'notfound', component: NotFoundComponent },
  // { path: '**', redirectTo: 'notfound' }


  
];

@NgModule({
  imports: [
    // RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: PreloadAllModules,
      // relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
