import { Optional, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { switchMap } from 'rxjs/operators';
import { UtilsService } from './../../../../modules/shared/services/utils.service';
import { RemoteInterfaceService } from '../../service/remote-address.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { of } from 'rxjs';
@Component({
  selector: 'app-pair-device-dialog',
  templateUrl: './pair-device-dialog.html',
})
export class PairDeviceDialog {
  showPairBtn = false;
  dataRecieved: any = {};
  constructor(
    private util: UtilsService,
    public dialogRef: MatDialogRef<PairDeviceDialog>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private remoteService: RemoteInterfaceService
  ) {
  }
  ngOnInit() {
    this.showPairBtn = this.remoteService.enableFaceRdService();
  }

  onClose() {
    this.dialogRef.close(0);
  }

  onSessionCreate(data) {
    this.dataRecieved = data;
    this.showPairBtn = true;
  }

  pairDevice() {
    const suid = this.dataRecieved.suid;
    this.remoteService.pairDevice(this.dataRecieved).subscribe((res: any) => {
      if (res) {
        this.dialogRef.close(1);
      }
    }, err => {
      this.dialogRef.close(1);
    })
  }

}
