import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from '../../shared/services/loader.service';
import { environment } from 'src/environments/environment';
const baseUrl = 'https://rdserverprod.co-vin.in/CoWinRdServer/webresources/Cowin/';
// const baseUrl = 'https://34.123.111.153:8443/CoWinRdServer/webresources/Cowin/';
const noLoaderApis = [
`${environment.beneficiary_registration_prefix_url}/beneficiary/getGender`,
`${environment.beneficiary_registration_prefix_url}/beneficiary/v2/getBeneficiariesByMobileNo`,
`${environment.beneficiary_registration_prefix_url}/beneficiary/v2/getBeneficiariesByMobileNo`,
`${baseUrl}1`,
`${baseUrl}5`,
`${baseUrl}7`,
`${baseUrl}3`,
`${environment.vaccinator_url}/mobile/appVersion`,
`${environment.user_prefix_url}/getDetails`,

];
const loaderRequireGetApi = [
    `${environment.session_prefix_url}/bulkSesssion/v2/getSessionCounts/`
];

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    private requests: HttpRequest<any>[] = [];

    constructor(private loaderService: LoaderService) { }

    removeRequest(req: HttpRequest<any>) {
        const i = this.requests.indexOf(req);
        if (i >= 0) {
            this.requests.splice(i, 1);
        }
        this.loaderService.isLoading.next(this.requests.length > 0);
    }


    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.method === 'GET') { 
            const matches = loaderRequireGetApi.filter((pattern) => new RegExp(pattern).test(req.url));
            if (matches) {return next.handle(req); } 
        }
        // else {if (req.method === 'GET') { return next.handle(req); }}
        if (noLoaderApis.includes(req.url)) { return next.handle(req); }
        if(req.url.includes('import-export/downloadfile')){
            return next.handle(req);
        }

        this.requests.push(req);
        this.loaderService.isLoading.next(true);
        return Observable.create(observer => {
            const subscription = next.handle(req)
                .subscribe(
                    event => {
                        if (event instanceof HttpResponse) {
                            this.removeRequest(req);
                            observer.next(event);
                        }
                    },
                    err => {
                        this.removeRequest(req);
                        observer.error(err);
                    },
                    () => {
                        this.removeRequest(req);
                        observer.complete();
                    });
            // remove request from queue when cancelled
            return () => {
                this.removeRequest(req);
                subscription.unsubscribe();
            };
        });
    }
}
