import { Component, OnInit, ViewChild, Input, OnChanges, SimpleChanges, AfterViewInit, Output, EventEmitter, ElementRef, ChangeDetectorRef, OnDestroy, } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/modules/shared/services';
import { UtilsService } from 'src/app/modules/shared/services/utils.service';
import * as _ from 'lodash';
import * as config from 'src/environments/config';
import { Observable, Subscription, timer } from 'rxjs';
import { ROUTE_ANIMATIONS_ELEMENTS } from 'src/app/modules/core/services/route.animations';
import { Device } from '@capacitor/device';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AddRecipientService } from '../../services/add-recipient.service';
import { RecipientService } from 'src/app/screens/recipient/services/recipient.service';
import { takeWhile, tap } from 'rxjs/operators';
import { ImageUploadService } from 'src/app/modules/shared/services/image-upload.service';
import * as regActions from 'src/app/modules/core/store/actions/registration.actions';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { Location } from '@angular/common';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-add-recipient-member-category-info',
  templateUrl: './member-category-info.component.html',
  styleUrls: ['./member-category-info.component.scss'],
})
export class MemberCategoryInfoComponent implements OnInit, OnDestroy {
  benfSubscription: Subscription;
  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
  @Input() activeStepIndex: any;
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  @Output() onStepChange: EventEmitter<any> = new EventEmitter();
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  beneficiaryModel: any = {};

  existingMembers = [];
  basicDetForm: FormGroup;
  otpForm: FormGroup;
  showOTPForm = false;
  otpformSubmitted = false;
  inProgress = false
  formSubmitted = false;
  isOTPSent = false;
  txnId = null;
  timer: number;
  skipTimer;
  showResend = false;
  beneficiaryTypes;
  selectedCategory;
  categoryType = '';
  sessionId;
  subscription: Subscription;
  skipSubscription: Subscription;
  filtered_eligible_types: any = [];
  validatorColor = false;
  fileTxt;
  sessionCategoryType;
  session_site_type;
  otpCount = 0;
  beneficiaryData: any = {};
  minDob = new Date('01-01-1920');
  maxDob: any
  birthYear: any;
  environment;
  UWINBeneficiaries:any = [];

  constructor(
    public addrecipientService: AddRecipientService,
    public imageUploadService: ImageUploadService,
    public recipientService: RecipientService,
    public util: UtilsService,
    private store$: Store,
    private cdr: ChangeDetectorRef,
    public dataService: DataService,
    private loc: Location,
    private platform: Platform,
  ) {
    this.basicDetForm = new FormGroup({
      beneficiary_type_category_id: new FormControl('', [Validators.required]),
      mobile_number: new FormControl('', [Validators.required, Validators.pattern(/[6-9]{1}[0-9]{9}/)]),
      ben_type_id: new FormControl('', Validators.required),
      beneficiary_type_category_name: new FormControl(''),
      employment_certificate: new FormControl(''),
      uploadedEmploymentFileDetails: new FormControl(''),
      beneficiary_type_name_other: new FormControl(''),
      state_eligibility_type_id: new FormControl(''),
      beneficiary_type_name: new FormControl(''),
      dob: new FormControl('', [Validators.required])
    });
    this.otpForm = new FormGroup({
      otp: new FormControl('', [Validators.required]),
      txnId: new FormControl('', [Validators.required]),
      guardian_ben_id: new FormControl('')
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.activeStepIndex) {
      if (this.activeStepIndex == 'CATEGORYINFO') {
        this.reloadComponent();
      } else {
        this.unSubscribe();
      }
    }
  }

  ngOnDestroy(): void {
    // this.unSubscribe();
  }

  ngOnInit(): void {
    // this.reloadComponent();
  }

  unSubscribe() {
    this.benfSubscription.unsubscribe();
  }

  reloadComponent() {
    this.environment = environment;
    this.session_site_type = this.dataService.loggedUserSessionType;
    this.inProgress = false;
    this.formSubmitted = false;
    this.isOTPSent = false;
    this.showOTPForm = false;
    this.benfSubscription = this.addrecipientService.beneficiaryData.subscribe(benf => {
      if (benf) {
        this.sessionId = benf;
        this.beneficiaryData = benf;
        this.setMinAndMaxDOB();
      }
    })
  }

  setMinAndMaxDOB() {
    if (this.beneficiaryData.session_id && this.beneficiaryData.session_type_id != 4) {
      const presentYear = +moment().format('YYYY');
      const MinimumAge = moment.utc().subtract(`${this.beneficiaryData.min_age}`, 'days').startOf('day');
      const MaximumAge = moment.utc().subtract(`${this.beneficiaryData.max_age}`, 'days').startOf('day');
      this.maxDob = new Date(`${MinimumAge}`);
      this.minDob = new Date(`${MaximumAge}`);
    }
    else if (environment.static_ids.SESSION_PREGNANT_CATEGORY.includes(this.beneficiaryData.uip_session_category)) {
      this.minDob = new Date(moment(new Date).subtract(49, 'years').startOf('day').format());
      this.maxDob = new Date(moment(new Date).subtract(12, 'years').startOf('day').format());
    }
    else if (this.beneficiaryData.session_type_id == 4) {
      this.minDob = new Date(moment(new Date).subtract(17885, 'days').startOf('day').format()); 
      // this.maxDob = new Date(moment(new Date).add(-1, 'days').startOf('day').format());
      this.maxDob = new Date(moment(`${this.beneficiaryData.date_of_session}`).startOf('day').format('YYYY-MM-DD'))
    } else {
      this.maxDob = new Date(moment(new Date).add(-1, 'days').startOf('day').format());
    }
  }

  calAgeByDob() {
    return Math.round(moment().diff(moment(this.basicDetForm.get('dob').value), 'years', true));
  }

  getBeneficiaryTypeonDoB(event) {
    let DOB;

    DOB = moment(event.value).format("YYYY-MM-DD");
    let value = this.basicDetForm.get('dob').value;
    this.birthYear = moment(value).format("YYYY-MM-DD");

  }

  gotoStep(val) {
    this.onStepChange.emit(val);
  }

  onLoad() { }

  closeDialog() {
    this.onClose.emit()
  }


  onSubmit() {
    this.formSubmitted = true;
    this.basicDetForm.markAllAsTouched();
    if (!this.basicDetForm.valid) { return; }
    this.otpForm.get('otp').reset();
    this.beneficiaryModel = this.basicDetForm.getRawValue();
    this.inProgress = true;
    this.addrecipientService
      .getBeneficiariesWithMobileNo({ ben_mobile_number: this.beneficiaryModel.mobile_number })
      .subscribe(r => {
        this.existingMembers = r;
        this.UWINBeneficiaries = r.filter(ele=>{
          return ele.from == 'UWIN'
        })
        let guardianMinAgeYear = parseInt(moment().subtract('years', 12).format('YYYY'));
        if (this.UWINBeneficiaries.length < parseInt(this.dataService.getConfigValue('ben_limit'))) {
          if (this.isTypeInfant()) {
            if (this.existingMembers.length == 0) {
              // let message = this.beneficiaryModel.ben_type_id == environment.static_ids.Infant_ID ? "Please Add Guardian First To Add Infant" : "Please Add Guardian First To Add New Born Baby"
              let message = "Please Add Guardian First To Add Child"
              this.util.presentToastError(message);
              this.showOTPForm = false;
            } else if (this.existingMembers.length > 0) {
              this.existingMembers = this.existingMembers.filter(e => (parseInt(e.year) <= guardianMinAgeYear && !e.hasOwnProperty('ben_death')));
            }
          }
        } else {
          this.formSubmitted = true;
          this.inProgress = false;
          this.util.presentToastError(`Already registered or cannot register more than ${this.dataService.getConfigValue('ben_limit')} individuals with one mobile number.`);
        }
        this.cdr.detectChanges();
      });

    // mark mandatory for type INFANT
    if (this.isTypeInfant()) {
      this.otpForm.get('guardian_ben_id').setValidators([Validators.required])
    } else {
      this.otpForm.get('guardian_ben_id').setValidators([]);
    }

    this.recipientService.sendOtpToBeneficiary({ mobile: this.beneficiaryModel.mobile_number, exp_sec: 30 })
      .subscribe(res => {
        this.inProgress = false;
        this.showOTPForm = true;
        if (res && res?.txnId) {
          this.txnId = res?.txnId;
          this.otpForm.get('txnId').setValue(res?.txnId);
        }
        this.isOTPSent = true;
        // this.otpCount = this.otpCount+1;
        this.otpformSubmitted = false;
        this.startTimer();
      }, err => {
        this.inProgress = false;
        this.showOTPForm = true;
        if (err?.error?.errorCode === 'USRAUT0011') {
          this.txnId = err?.error?.txnId;
          this.isOTPSent = true;
          this.otpformSubmitted = false;
          this.startTimer();
        }
      });
  }

  startTimer() {
    this.showResend = false;
    let counter = config.ENV.otpTimer;
    // let counter = 30;
    this.timer = config.ENV.otpTimer;
    // this.timer = 30;
    this.skipTimer = 5;
    let skipCounter = this.skipTimer
    this.subscription = timer(1000, 1000) //Initial delay 1 seconds and interval countdown also 1 second
      .pipe(
        takeWhile(() => counter > 0),
        tap(() => counter--),
      )
      .subscribe(() => {
        this.timer = counter;
        // if(!this.timer && this.otpCount == 2){ this.onInvalidSecondOtp();}
        this.cdr.detectChanges();
      });
    this.skipSubscription = timer(1000, 1000)
      .pipe(
        takeWhile(() => skipCounter > 0),
        tap(() => skipCounter--),
      )
      .subscribe(() => {
        this.skipTimer = skipCounter; this.cdr.detectChanges();
      });
  }
  
  verifyOTP() {
    this.otpformSubmitted = true;
    this.otpForm.markAllAsTouched();
    if (!this.otpForm.valid) {
      if (this.isTypeInfant() && !this.otpForm.get('guardian_ben_id').value) {
        this.util.presentToastError('Please select guardian');
        return;
      }
      return;
    }

    this.recipientService.verifyOTP(this.otpForm.value)
      .subscribe((r: any) => {
        this.otpformSubmitted = false;
        if (r?.verified) {
          let guardian = {};
          if (this.isTypeInfant()) {
            const parentInfo = this.existingMembers.find(e => e.beneficiary_id == this.otpForm.get('guardian_ben_id').value);
            guardian = {
              guardian_ben_id: this.otpForm.get('guardian_ben_id').value,
              guardian_name: parentInfo.ben_name,
              guardian_yob: parentInfo.year,
              guardian_photo_id_type: this.matchAndReplacePhotoIdType(parentInfo),
              guardian_photo_id_number: this.matchAndReplacePhotoIdTypeNumber(parentInfo)

            };
          }
          let otp;
          let beneficiaryData = {
            ...{ mobileVerified: true },
            ...{ verifiedUsing: 'OTP' },
            // ...otp,
            ...guardian,
            ...{ employment_certificate_File: this.basicDetForm.get('uploadedEmploymentFileDetails').value },
            ...this.sessionId,
            ...this.basicDetForm.getRawValue(),
          };
          this.addrecipientService.beneficiaryDetails = beneficiaryData;
          this.addrecipientService.stepHistory = ['first-step'];
          this.gotoStep('next');
        }
        if (r["verified"] == false) {
          this.util.presentToastError('Invalid OTP');
        }
      }, (e) => {
        this.otpformSubmitted = false;
      });
  }
  SkipOtp() {
    if (this.isTypeInfant() && !this.otpForm.get('guardian_ben_id').value) {
      this.util.presentToastError('Please select guardian');
      return;
    }
    let guardian = {};
    if (this.isTypeInfant()) {
      const parentInfo = this.existingMembers.find(e => e.beneficiary_id == this.otpForm.get('guardian_ben_id').value);
      guardian = {
        guardian_ben_id: this.otpForm.get('guardian_ben_id').value,
        guardian_name: parentInfo.ben_name,
        guardian_yob: parentInfo.year,
        guardian_photo_id_type: this.matchAndReplacePhotoIdType(parentInfo),
        guardian_photo_id_number: this.matchAndReplacePhotoIdTypeNumber(parentInfo)

      };
    }
    let beneficiaryData = {
      ...{ verifiedUsing: 'ConfirmMobile' },
      ...guardian,
      ...{ employment_certificate_File: this.basicDetForm.get('uploadedEmploymentFileDetails').value },
      ...this.sessionId,
      ...this.basicDetForm.getRawValue(),
    };
    this.addrecipientService.beneficiaryDetails = beneficiaryData;
    this.addrecipientService.stepHistory = ['first-step'];
    this.gotoStep('next');
  }

  matchAndReplacePhotoIdType(parentObj){
    if(parentObj.photo_id_number.match(/[***]/g)){
      return null;
    }
    return parentObj.photo_id_type == 1 ? parentObj.photo_id_type = "Aadhaar Card" : parentObj.photo_id_type;
  }

  matchAndReplacePhotoIdTypeNumber(parentObj){
    if(parentObj.photo_id_number.match(/[***]/g)){
      return null;
    }
    return parentObj.photo_id_number;
  }
  goBack() {
    if (this.util.getPlatformWidth() < 575) {
      this.loc.back();
    }
    if (this.formSubmitted) {
      this.formSubmitted = false;
      this.showOTPForm = false;
      this.beneficiaryModel = null;
      this.subscription.unsubscribe();
      this.skipSubscription.unsubscribe();
    } else {
      this.closeDialog()
      // this.gotoStep('prev');
    }
  }

  isTypeInfant() {
    return this.calAgeByDob() < 12 || this.beneficiaryModel.ben_type_id == environment.static_ids.Infant_ID || this.beneficiaryModel.ben_type_id == environment.static_ids.NewBornBaby;
  }
}


