import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ElementRef, ChangeDetectorRef, } from '@angular/core';
import { Platform } from '@ionic/angular';
import { UtilsService } from 'src/app/modules/shared/services/utils.service';
import * as _ from 'lodash';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ImageUploadService } from 'src/app/modules/shared/services/image-upload.service';
import { AddRecipientService } from '../../services/add-recipient.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AadhaarConcentComponent } from 'src/app/modules/shared/components/aadhaar-concent/aadhaar-concent.component';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-recipient-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss'],
})
export class VerificationComponent implements OnInit {
  selectedIndex = 0;
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  @Input() beneficiaryDetails: any = null;
  yearOfBirth: any = null;
  verificationForm: FormGroup;
  uploadedFileDetails: any = {};
  formSubmitted = false;
  aadharVerificationType = null;
  benData: any;
  guadianObservable: Observable<any>;
  segment = 1;
  environment;
  phIdNumber = null;
  @Output() onStepChange: EventEmitter<any> = new EventEmitter();
  slideOpts = {
    initialSlide: 1,
    speed: 400
  };


  constructor(public util: UtilsService, public platform: Platform,
    public imageUploadService: ImageUploadService,
    public addRecipientService: AddRecipientService,
    private _bottomSheet: MatBottomSheet,
    private store$: Store,
    public cdr: ChangeDetectorRef
  ) { this.buildForm(); }

  buildForm() {
    this.verificationForm = new FormGroup({
      photo_id_type: new FormControl(null, [Validators.required]),
      photo_id_number: new FormControl(null, [Validators.required]),
      photo_value: new FormControl(null),
      consent: new FormControl(null),
      female_consent: new FormControl(null),
    });
  }

  resetFile() {
    this.fileInput.nativeElement.value = null;
  }


  resetAll() {
    // if(!this.benData.beneficiary_id){
    //   this.benData.photo_id_number = null;
    //   this.benData.photo_id_type = null;
    // }

    // if(this.benData.eKYC_by)this.benData.eKYC_by = null;
    // if(this.benData.employment_certificate)this.benData.employment_certificate = null;
    // if(this.benData.verifiedBy)this.benData.verifiedBy = null;
    // if(this.benData.verifiedType)this.benData.verifiedType = null;

    this.aadharVerificationType = null;
    this.formSubmitted = false;
    this.verificationForm.reset();
    this.util.markFormGroupTouched(this.verificationForm);
    this.buildForm();
    if (this.benData.beneficiary_id) {
      this.verificationForm.patchValue(this.benData);
      if (this.benData.photo_id_type) {
        this.verificationForm.get('photo_id_type').disable();
        this.verificationForm.get('photo_id_number').disable();
      }
    }
  }

  ngOnInit() {
    this.environment = environment;
    this.addRecipientService.beneficiaryData.subscribe(async (r) => {
      this.benData = r;
      this.phIdNumber = null;
      if (this.benData?.dob) {
        this.yearOfBirth = moment(this.benData?.dob).format('YYYY');
      }
      if (this.benData.year) {
        this.yearOfBirth = this.benData.year;
      }
      if (this.benData.guardian_ben_id && !this.benData.guardian_yob) {
        this.guadianObservable = this.addRecipientService.getBeneficairyGuardianDetails(this.benData.guardian_ben_id);
        // this.benData.guardian_yob = gurdianData.year;
      }
      this.verificationForm.patchValue(this.benData);
      if (this.benData?.beneficiary_id && this.benData?.photo_id_type) {
        this.verificationForm.get('photo_id_type').disable();
        this.verificationForm.get('photo_id_number').disable();
        this.phIdNumber = this.verificationForm.get('photo_id_number').value
        let photo_id_number_mask = this.util.mask(this.verificationForm.get('photo_id_number').value);
        this.verificationForm.get('photo_id_number').setValue(photo_id_number_mask);
        if (this.benData?.beneficiary_id_type_name != 'Aadhaar Card' ||
          this.benData?.photo_id_type != 'Aadhaar Card') {
          this.selectedIndex = 0;
          setTimeout(() => { this.segment = 0; this.segmentChanged(); }, 500);
        }
      }
      if (this.benData?.added_by_photo_id) {
        this.verificationForm.get('photo_value').setValidators([Validators.required]);
        this.verificationForm.get('photo_value').updateValueAndValidity();
      } else {
        this.verificationForm.get('photo_value').setValidators([]);
        this.verificationForm.get('photo_value').updateValueAndValidity();
      }
      if (this.benData?.guardian_photo_id_type) {
        this.verificationForm.get('photo_id_type').setValue(this.benData?.guardian_photo_id_type);
        this.verificationForm.get('photo_id_type').disable();
      }
      if (this.benData?.guardian_photo_id_number) {
        this.verificationForm.get('photo_id_number').setValue(this.benData?.guardian_photo_id_number);
        this.verificationForm.get('photo_id_number').disable();
      }

    })
  }

  gotoStep(val) {

    this.onStepChange.emit(val);
    this.selectedIndex = 1;
    this.verificationForm.get('consent').reset();
    this.verificationForm.get('female_consent').reset();

  }
  openBottomSheet() {
    this._bottomSheet.open(AadhaarConcentComponent);
  }

  checkValue(change) {
    if (change.checked == false) {
      this.verificationForm.get('female_consent').reset();
    }
  }
  onSubmit() {
    this.formSubmitted = true;
    this.verificationForm.markAllAsTouched();
    if (!this.verificationForm.valid) { return; }
    if (this.benData != null && this.benData != undefined) {
      if (this.benData.ben_type_id == environment.static_ids.PregnantWomen || this.benData.ben_type_id == environment.static_ids.LactatingMother) {
        this.verificationForm.get('female_consent').setValidators([Validators.required]);
      }
      else {
        this.verificationForm.get('female_consent').clearValidators();
        this.verificationForm.get('female_consent').reset();
      }
    }
    if (!this.verificationForm.valid) { return; }
    this.benData.verifiedBy = "Manual"
    this.benData.verifiedType = 'NONE';
    const frmVal = this.verificationForm.getRawValue();
    if(this.phIdNumber){frmVal.photo_id_number = this.phIdNumber;}
    let beneficiaryData = {
      ...this.benData,
      ...frmVal,
    };
    this.addRecipientService.beneficiaryDetails = beneficiaryData;
    // if (this.verificationForm.get('consent').value == true) {
    this.gotoStep('next');
    // }
    // if (this.verificationForm.get('consent').value == null) {
    //   this.verificationForm.get('consent').setValidators([Validators.required]);
    // }
  }

  removeImage() {
    this.uploadedFileDetails = null;
    this.verificationForm.get('photo_value').setValue(null);
  }


  uploadFile(event?) {
    this.imageUploadService.uploadFile(event).subscribe(res => {
      this.uploadedFileDetails = res;
      this.verificationForm.get('photo_value').setValue(res.fileName);
      let beneficiaryData = {
        ...this.benData,
        ...{ fileInManualverification: res }
      };
      this.addRecipientService.beneficiaryDetails = beneficiaryData;
    },
      err => {
        this.util.presentToastError(err);
      });
  }

  async selectImageSource() {
    if (!this.platform.is('desktop')) {
      this.imageUploadService.selectImageSource(this.fileInput).then(r => {
        if (this.imageUploadService.imageCaptureType == 'takePhoto') {
          this.uploadFile();
        }
      });
    } else {
      this.imageUploadService.selectImageSource(this.fileInput);
    }
  }

  onSelectVerificationType(type) {
    this.aadharVerificationType = type;
    this.verificationForm.get('consent').setValidators([Validators.required]);
    if (this.benData != null && this.benData != undefined) {
      if (this.benData.ben_type_id == environment.static_ids.PregnantWomen || this.benData.ben_type_id == environment.static_ids.LactatingMother) {
        this.verificationForm.get('female_consent').setValidators([Validators.required]);
      }
      else {
        this.verificationForm.get('female_consent').clearValidators();
        this.verificationForm.get('female_consent').reset();
      }
      this.addRecipientService.stepHistory = ['first-step', 'verification'];
    }
  }

  changeTab(index) {
    if (index !== this.selectedIndex) {
      this.selectedIndex = index;
    }
  }

  segmentChanged(e?) {
    if(e){ 
    this.segment = e?.detail?.value;
    }
    // this.slides.getActiveIndex().then(selectedIndex => {
    //   if (e?.detail?.value != selectedIndex) { this.slides.slideTo(this.segment); }
    // });
  }

  slideChanged(e) {
    // this.slides.getActiveIndex().then(selectedIndex => {
    //   this.segment = selectedIndex;
    //   this.cdr.detectChanges();
    // });
  }
}


