import { registerPlugin } from '@capacitor/core';

export interface AadhaarCallsPlugin {
  captureFace(): Promise<any>;
  getRDdeviceInfo(): Promise<any>;
  captureFingerPrint(): Promise<any>;
}

const AadhaarCalls = registerPlugin<AadhaarCallsPlugin>('AadhaarCalls');

export default AadhaarCalls;
