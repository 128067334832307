<!-- <app-header class="header" [template]="'new-member'" [pageData]="{title: 'Add Member'}"></app-header> -->
<!-- <div class="contentContainer"> -->
  <ng-container  *ngIf="util.getPlatformWidth() < 575">
   
      <ng-container *ngTemplateOutlet="tpl"></ng-container>
  
    </ng-container>
    <ng-container  *ngIf="util.getPlatformWidth() >= 575">
        <ng-container *ngTemplateOutlet="tpl"></ng-container>
    </ng-container>
  <ng-template #tpl>

  <app-add-recipient-member-category-info
  [activeStepIndex]="'CATEGORYINFO'" (onClose)="closeDialog()" (onStepChange)="onStepChange($event)"

  ></app-add-recipient-member-category-info>
  </ng-template>
<!-- </div> -->
